var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content"},[_c('div',{staticClass:"pool-header flex-c"},[_c('img',{staticClass:"grade-log mr5",attrs:{"src":require("@/assets/release.png"),"alt":""}}),(_vm.identity == 4)?_c('div',{staticClass:"flex-c"},_vm._l((_vm.navType),function(item,index){return _c('div',{key:index,staticClass:"flex-c-c btn mr30",staticStyle:{"flex-direction":"column"},on:{"click":function($event){return _vm.changeType(index)}}},[_c('div',{staticClass:"nav-text",class:_vm.active1 == index ? 'navActive' : ''},[_vm._v(" "+_vm._s(item)+" ")]),_c('div',{staticClass:"default mt5",class:_vm.active1 == index ? 'navActiveBottom' : ''})])}),0):_c('div',{staticClass:"nav-text"},[_vm._v("任务池")])]),_c('div',{staticClass:"pool-content"},[(_vm.taskList.length > 0)?[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了","offset":"500"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.taskList),function(item){return _c('div',{key:item.id,staticClass:"task-content",on:{"click":function($event){return _vm.$router.push({
                path: '/taskPoolDetail',
                query: { id: item.id },
              })}}},[_c('div',{staticClass:"task-content-top flex-c-b"},[_c('div',{staticClass:"task-title"},[_vm._v(_vm._s(item.title))]),_c('van-rate',{staticClass:"mt5",attrs:{"size":"15px","color":"#D71518","void-color":"#FFF","count":Number(item.difficulty_text),"value":Number(item.difficulty_text),"readonly":""}})],1),_c('div',{staticClass:"task-content-content"},[_c('div',{staticClass:"flex-c-b"},[_c('div',{staticClass:"label"},[_vm._v("归属项目：")]),_c('div',{staticClass:"value line blue",on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                      path: '/projectDetail',
                      query: { id: item.project_id },
                    })}}},[_vm._v(" "+_vm._s(item.project_name)+" ")])]),_c('div',{staticClass:"flex-c-b mt10"},[_c('div',{staticClass:"label"},[_vm._v("发布人：")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(item.create_name))])]),_c('div',{staticClass:"flex-c-b mt10"},[_c('div',{staticClass:"label"},[_vm._v("发布时间：")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(item.createtime_text))])])])])}),0)]:_c('van-empty',{attrs:{"description":"暂无任务"}})],2)]),_c('div',{staticClass:"style"})])
}
var staticRenderFns = []

export { render, staticRenderFns }