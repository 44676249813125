<!--
 * @Author: Mr.Cheng
 * @Date: 2023-09-01 14:31:55
 * @LastEditTime: 2023-10-20 17:09:32
 * @FilePath: \octopus-factory-map\src\App.vue
-->
<template>
  <div id="app">
    <keep-alive :include="keepList">
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
// import headers from './components/homeHeader.vue'

export default {
  data() {
    return {
      keepList: ["task", "acceptanceTask","solveBug"],//需要保活的页面
    };
  },
  created() {
    this.$utils.setBodyFontSize();
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
body {
  height: 100%;
  width: 100%;
}
</style>
