<template>
    <div class="user_card">
        <div class="user_car_content">
            <div class="user_card_content_data">
                <div class="user_card_background" @click="routerclcik">
                    <div class="user_image">
                        <img :src="content.image" alt="">
                    </div>
                    <div class="user_content_data" v-if="token">
                        <div class="user_content_name">
                            <span>{{ content.name ? content.name : '' }}</span>
                        </div>
                        <div class="user_content_grade" v-if="!mystatus">
                            <div class="grade_box flex-c" @click="integralGrade">
                                <div class="grade_text">
                                    <span>积分等级Lv.{{content.grade }}</span>
                                </div>
                                <div class="grade_image">
                                    <img src="../../assets/arrowright.png" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="user_undertekst" v-else>
                            <span>ID：{{content.id_card}}</span>
                            <span>积分：{{ content.score }}</span>
                        </div>
                    </div>
                    <div v-else class="no_token">
                        <div class="no_tkens" @click="noTokenClick">
                            <span>登录/注册</span>
                        </div>
                    </div>
                </div>
                <div class="user_right_setting" v-if="!mystatus">
                    <div class="user_setting" @click="settingacount">
                        <img src="../../assets/setting.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        content:Object,
        default:{} || Object,
        mystatus:Number,
        token:String
    },
    data(){
        return{

        }
    },
    methods:{
        integralGrade(){
            this.$emit('integralGrade')
        },
        noTokenClick(){
            this.$emit('noTokenClick')
        },
        routerclcik(){
            this.$emit('routerclcik')
        },
        settingacount(){
            this.$emit('settingacount')
        }
    }
}


</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter{
    display: flex;
    align-items: center;
}


// 计算函数
@function calcFunction($number,$default:16){
    @return calc($number * 2 / 100) + rem;
}


.user_card{
    margin-top: -1rem;
    .user_car_content{
        margin: 0 .2rem;
        .user_card_content_data{
            background: white;
            padding: .4rem;
            @extend .alignItemsCenter;
            justify-content: space-between;
            border-radius: .16rem;
            .user_card_background{
                @extend .alignItemsCenter;
                .user_image{
                    @extend .alignItemsCenter;
                    img{
                        width: 1.2rem;
                        height: 1.2rem;
                        border-radius: 50%;
                    }
                }
                .user_content_data{
                    margin-left: .2rem;
                    .user_content_name{
                        span{
                            font-size: .38rem;
                        }
                    }
                    .user_content_grade{
                        margin-top: .2rem;
                        .grade_box{
                            @extend .alignItemsCenter;
                            border: 1px solid rgba(0, 13, 27, .1);
                            border-radius: 100px;
                            justify-content: center;
                            padding:.09rem .2rem;
                            .grade_text{
                                @extend .alignItemsCenter;
                                span{
                                    font-size: .24rem;
                                    color: #D71518;
                                }
                            }
                            .grade_image{
                                @extend .alignItemsCenter;
                                margin-left: calcFunction(2);
                                img{
                                    @extend .alignItemsCenter;
                                    width: .08rem;
                                    height: .09rem;
                                }
                            }
                        }
                    }
                }
            }
        }
        .user_right_setting{
            .user_setting{
                img{
                    width: .36rem;
                    height: .34rem;
                }
            }
        }
    }
}

.user_undertekst{
    margin-top: .23rem;
    span{
        color: #999999;
        font-size: .24rem;
    }
    span:nth-last-child(1){
        margin-left: .3rem;
    }
}

.no_token{
    margin-left: calcFunction(10);
    .no_tkens{
        span{
            color: #111111;
            font-weight: bold;
            font-size: calcFunction(19);
        }
    }
}

</style>