<template>
  <div
    v-if="userLog.length"
    class="user_log"
    :style="userLog.length > 0 ? '' : 'padding:0'"
  >
    <div class="user_log_box" :style="offsets ? { margin: 0 } : ''">
      <div class="user_log_content" v-if="!offsets">
        <div class="user_log_header">
          <div class="user_log_left_image_of_text">
            <img src="@/assets/spring.png" alt="" />
            <span>任务日志</span>
          </div>
          <div class="user_log_button">
            <div class="user_log_click">
              <span @click="selectTimeChcnage">日期 {{ date }}</span>
              <img
                v-if="!date"
                @click="selectTimeChcnage"
                src="@/assets/bottom.png"
                alt=""
              />
              <img
                v-if="date"
                @click="deletTimeChange"
                src="@/assets/delete.png"
                class="deleteImage"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <!-- <taskLogCard :offsets="offsets" :userLog="userLog" /> -->
      <taskLogCard
        :offsets="offsets"
        :userLog="logList"
        :isSelect="isSelect"
        :isTask="isTask"
      />
    </div>
  </div>
</template>
<script>
import taskLogCard from "./taskLog.vue";
export default {
  props: {
    userLog: Array,
    isTask: Boolean, //是否是执行任务进去的
    offsets: Number || "",
    date: String || "" || Number,
    isSelect: Boolean, //是否能选择任务
  },
  data() {
    return {
      logList: [], //任务列表
    };
  },
  components: {
    taskLogCard,
  },
  watch: {
    userLog(newVal) {
      var arrayTwo = Object.values(
        newVal.reduce((res, item) => {
          res[item.dailyplantime_text]
            ? res[item.dailyplantime_text].push(item)
            : (res[item.dailyplantime_text] = [item]);
          return res;
        }, {})
      );
      this.logList = arrayTwo;
    },
  },
  methods: {
    selectTimeChcnage() {
      this.$emit("selectTimeChcnage");
    },
    deletTimeChange() {
      this.$emit("deletTimeChange");
    },
  },
};
</script>
<style lang="scss" scoped>
i {
  font-style: normal;
}
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.deleteImage {
  height: calcFunction(16) !important;
}

.user_log {
  background: #f1f0f5;
  padding-bottom: calcFunction(10);
  .user_log_box {
    margin: calcFunction(20) calcFunction(10);
    .user_log_content {
      .user_log_header {
        @extend .alignItemsCenter;
        justify-content: space-between;
        .user_log_left_image_of_text {
          @extend .alignItemsCenter;
          img {
            width: calcFunction(16);
            height: calcFunction(16);
          }
          span {
            font-size: calcFunction(15);
            color: #111111;
          }
        }
        .user_log_button {
          .user_log_click {
            @extend .alignItemsCenter;
            background: white;
            padding: calcFunction(5.5) calcFunction(8);
            justify-content: center;
            border-radius: calcFunction(5);
            span {
              font-size: calcFunction(13);
              color: #333333;
            }
            img {
              margin-left: calcFunction(10);
              width: calcFunction(15);
              height: calcFunction(8);
            }
          }
        }
      }
    }
  }
}
</style>