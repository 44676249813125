<template>
    <div class="cp_exchange">
  
      <div class="cp_exchange_content_header">
        <publicNavigation :data="navigation" />
      </div>
      
      <div class="cp_exchange_card">
        
        <van-list v-if="exchangeData.length > 0" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
  
          <div class="cp_exchange_list" v-for="(s,k) in exchangeData" :key="k">
            <div class="cp_exchange_list_header_title">
              <span>{{ s.name }}</span>
              <button @click="exchangeEvent(s)" v-if="s.status == 1">兑换</button>
              <span class="status" v-else>{{ s.status == 2 ? "分红中" : "已结束" }}</span>
            </div>
            <div class="cp_exchange_list_content_list">
              <div class="cp_exchange_children_for" v-for="(j,l) in s.children" :key="l">
                <span>{{ j.name }}</span>
                <span>{{ j.text }}</span>
              </div>
            </div>
          </div>
          
        </van-list>
  
        <van-empty v-else description="暂无数据" />
        
      </div>
      
      <van-popup round v-model="show">
          <div class="exchange_popup">
              <div class="exchange_popup_content">
                  <div class="exchange_popup_content_box">
                      <div class="exchange_popup_content_header">
                          <span>兑换CP值</span>
                      </div>
                      <div class="exchange_popup_content_project_name">
                          <div class="exchange_project_title">
                              <span>{{ productObject.name }}</span>
                          </div>
                          <div class="exchange_input">
                              <input type="number" v-model="inputCP" placeholder="输入要兑换的CP值">
                          </div>
                          <div class="exchange_description_describe">
                              <span>您目前的奖励点可兑换{{ Number(reward / productObject.exchange).toFixed(2) }}个CP值</span>
                              <span>(1CP值={{ productObject.exchange }}奖励点)</span>
                          </div>
                          <div class="exhcange_button_classfiy">
                              <button @click="closeEvent">取消</button>
                              <button @click="sureOnchange">确定</button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </van-popup>
    </div>
</template>
<script>
import publicNavigation from '@/publicNavigation/publicNavigation.vue';
import * as API from '@/api.ts'
export default {
    components:{
        publicNavigation
    },
    data(){
      return{
        loading: false,
        finished: false,
        navigation: {
          image:require('@/assets/return.png'),
          name:'兑换',
          close:''
        },
        exchangeData:[] || Array,
        show:false,
        page:1,
        productObject:{},
        reward:'',
        inputCP:''
      }
    },
    watch:{
        inputCP(e){
            console.log(e)
            if(e < (this.reward / this.productObject.exchange)){
                this.inputCP = e
            }
        }
    },
    mounted(){
        this.reward = JSON.parse(window.sessionStorage.getItem('userInfo')).reward
        this.Requset()
    },
    methods:{
        exchangeEvent(s){
          this.productObject = s
          this.show = !this.show
        },
        closeEvent(){
          this.show = !this.show
        },
      //加载更多
      onLoad() {
        if (this.exchangeData.length > 0) {
          this.Requset();
        } else {
          this.loading = false;
        }
      },
      
      Requset(){
        const parms = {
          page: this.page,
          limit: 5
        }
        API.listCp(parms).then(async e=>{
          if(e.code == 1){
            var list = e.data.rows;
            if(list.length > 0){
              var newArr = [];
              list.map((item) => {
                newArr.push({
                  name:'I型打印机一期项目',
                  children:[{
                    name:'当前已兑换：',
                    text: item.total || 0
                  },{
                    name:'兑换人数：',
                    text: item.person_num + '人'
                  },{
                    name:'兑换截止期：',
                    text: item.exchange_time_text ? item.exchange_time_text.split('.').join('-') : ''
                  }],
                  ...item
                });
              });
              if(this.page == 1){
                this.exchangeData = newArr;
              }else{
                this.exchangeData = this.exchangeData.concat(newArr);
              }
              this.page++;
            }else{
              this.finished = true;
            }
            this.loading = false;
          }
        })
      },
      
      sureOnchange(){
        const parms = {
          id: this.productObject.id,
          cp: this.inputCP
        }
        if(this.inputCP < (this.reward / this.productObject.exchange) && this.inputCP !== ''){
            API.exchangeCp(parms).then(async e=>{
                console.log(e)
                this.$toast({
                    type:e.code == 1 ? 'success' : 'fail',
                    message:e.msg
                })
                if(e.code == 1){
                    this.exchangeData = []
                    this.show = !this.show
        this.$router.back(-1)
                }
            })
        }else{
          if(this.inputCP == ''){
              this.$toast.fail('不能为空')
          }else{
              this.$toast.fail('不能超过可兑换点')
          }
        }
      }
    }
}
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter{
    display: flex;
    align-items: center;
}

// 计算函数
@function calcFunction($number,$default:16){
  @return calc($number * 2 / 100) + rem;
}

.cp_exchange{
    min-height: 100vh;
    background: #F1F0F5;
  .cp_exchange_content_header{
    background: white;
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 999;
  }
  .cp_exchange_card{
    margin: 0 calcFunction(10) 0.2rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    .cp_exchange_list{
      background-color: white;
      padding: calcFunction(15) calcFunction(20);
      border-radius: calcFunction(8);
      margin-bottom: calcFunction(10);
      .cp_exchange_list_header_title{
        @extend .alignItemsCenter;
        justify-content: space-between;
        border-bottom: 1px solid #EEEEEE;
        padding-bottom: calcFunction(12);
        span{
          font-size: calcFunction(16);
          color: #111111;
          font-weight: bold;
        }
        .status{
          font-size: calcFunction(14);
          color: #999999;
        }
        button{
          padding: calcFunction(8) calcFunction(21);
          background: #D71518;
          color: white;
          border: none;
          outline: none;
          font-size: calcFunction(14);
          border-radius: calcFunction(4);
        }
      }
      .cp_exchange_list_content_list{
        margin-top: calcFunction(15);
        .cp_exchange_children_for{
          margin-bottom: calcFunction(10);
          @extend .alignItemsCenter;
          justify-content: space-between;
          span{
            font-size: calcFunction(13);
            color: #999999;
          }
          span:nth-last-child(1){
            color: #333333;
          }
        }
        .cp_exchange_children_for:nth-last-child(1){
          margin-bottom: calcFunction(5);
        }
      }
    }
  }
}


.exchange_popup{
    width: calcFunction(305);
    .exchange_popup_content{
        padding-top: calcFunction(25);
        .exchange_popup_content_box{
            .exchange_popup_content_header{
                text-align: center;
                border-bottom: 1px solid #EEEEEE;
                padding-bottom: calcFunction(20);
                span{
                    font-weight: bold;
                    font-size: calcFunction(17);
                }
            }
            .exchange_popup_content_project_name{
                padding: calcFunction(20);
                padding-bottom: calcFunction(30);
                .exchange_project_title{
                    span{

                    }
                }
                .exchange_input{
                    @extend .alignItemsCenter;
                    margin-top: calcFunction(15);
                    input{
                        flex: 1;
                        padding: calcFunction(14) calcFunction(0);
                        padding-left: calcFunction(10);
                        background: #F1F1F1;
                        border: none;
                        font-size: calcFunction(15);
                    }
                }
                .exchange_description_describe{
                    margin-top: calcFunction(15);
                    @extend .alignItemsCenter;
                    flex-direction: column;
                    align-items: flex-start;
                    span{
                        color: #FF6769;
                        font-size: calcFunction(12);
                    }
                }
                .exhcange_button_classfiy{
                    margin-top: calcFunction(20);
                    @extend .alignItemsCenter;
                    justify-content: space-between;
                    button{
                        width: 48%;
                        padding: calcFunction(12) 0;
                        background: #D71518;
                        color: white;
                        font-size: calcFunction(17);
                        border-radius: calcFunction(100);
                        outline: none;
                        border: none;
                    }
                    button:nth-child(1){
                        background: transparent;
                        color: #131313;
                        border: 1px solid #E5E5E5;
                    }
                }
            }
        }
    }
}


</style>
