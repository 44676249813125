<template>
  <div class="public_navigation">
    <div class="public_navigation_content">
      <!--左侧返回按钮-->
      <div class="public_left_image" @click="navigationClick(returnNumer)">
        <img v-if="!navigationColor" :src="returnImage" alt="" />
        <img v-else :src="returnWhiteImage" alt="" />
      </div>

      <!--页面title-->
      <div
        class="public_header_center_text"
        :class="data.sure && data.search ? 'public_classfiy' : ''"
      >
        <span
          v-if="!navigationCurrent && searchUdvide == false"
          :style="navigationColor ? 'color:white;' : ''"
        >
          {{ data.name }}</span
        >
        <span v-else @click="navigationCurrentChange"
          >{{ navigationCurrent }}
        </span>
        <img
          v-if="data.rightFill"
          :src="data.rightFill"
          style="width: 20px; height: 20px; transform: rotate(90deg)"
          alt=""
        />
        <div v-if="searchUdvide" class="search_inputs">
          <input
            type="text"
            v-model="createInput"
            @input="createInputSearch"
            placeholder="请输入您要搜索的纪录"
          />
        </div>
      </div>

      <!--右侧按钮-->
      <div
        :class="data.sure && data.search ? 'sure_search' : ''"
        class="public_left_image custom_position"
        :style="data.sure || position || data.search ? '' : 'opacity: 0;'"
      >
        <img
          v-if="data.sure && data.search"
          class="sure_and_search"
          :src="data.search"
          alt=""
        />

        <img
          v-if="!data.sure && !searchUdvide"
          @click="shareEvent"
          :class="custom_search"
          :style="share"
          :src="data.search"
          alt=""
        />

        <img
          v-if="searchUdvide"
          @click="closeSearchEvent"
          :style="share"
          :src="data.close"
          alt=""
        />

        <span v-else-if="!data.toggle" @click="sureEvenet">{{
          data.sure
        }}</span>
        <div class="toggle" v-if="position">
          <div class="toggle_classfiy">
            <div class="toggle_classfiy_content">
              <button
                :class="currentIndex == k ? 'active' : ''"
                v-for="(s, k) in position"
                @click="currentClick(s, k)"
                :key="k"
              >
                {{ s }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: function (e) {
        return e;
      },
    },
    position: Array,
    navigationColor: String,
    navigationCurrent: String,
    share: Object,
    searchUdvide: Boolean || true,
    custom_search: Object,
    returnNumer: Number,
  },
  data() {
    return {
      returnImage: require("@/assets/return.png"),
      returnWhiteImage: require("@/assets/whitereturn.png"),

      height: 0,
      currentIndex: 0,
      createInput: "",
    };
  },
  mounted() {
    // system window height size
    const height = window.innerHeight;
    const width = window.innerWidth;
    this.height = (height / width) * 10 * 2 + 40;
  },
  methods: {
    navigationClick(s) {
      if (s) {
        this.$emit("navigationClick");
      } else {
        this.$router.back(-1);
      }
    },
    sureEvenet() {
      this.$emit("sureEvenet");
    },
    currentClick(s, k) {
      this.currentIndex = k;
      this.$emit("currentClick", k);
    },
    navigationCurrentChange() {
      this.$emit("navigationCurrentChange");
    },
    shareEvent() {
      this.$emit("shareEvent");
    },
    closeSearchEvent() {
      this.$emit("closeSearchEvent");
    },
    createInputSearch() {
      this.$emit("createInputSearch", this.createInput);
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.public_navigation {
  // background: #fff;

  .public_navigation_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 0.88rem;
    .public_left_image {
      margin-right: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 0.88rem;
      height: 0.88rem;
      img {
        display: block;
        width: 0.19rem;
        height: 0.34rem;
      }

      span {
        color: #d71518;
        font-size: 0.28rem;
      }
    }

    .public_header_center_text {
      @extend .alignItemsCenter;
      span {
        font-size: 0.34rem;
      }
    }
  }
}

.toggle {
  position: absolute;
  right: calcFunction(-5);
  z-index: 1;

  .toggle_classfiy {
    .toggle_classfiy_content {
      @extend .alignItemsCenter;

      button {
        border: 1px solid #dddddd;
        background: white;
        border-radius: 0;
        padding: calcFunction(6) calcFunction(7);
        font-size: calcFunction(12);
        width: calcFunction(60);
      }

      button:nth-child(1) {
        border-top-left-radius: calcFunction(4);
        border-bottom-left-radius: calcFunction(4);
        border-right: none;
      }

      button:nth-last-child(1) {
        border-top-right-radius: calcFunction(4);
        border-bottom-right-radius: calcFunction(4);
      }
    }
  }
}

.active {
  border: 1px solid #d71518 !important;
  background: #d71518 !important;
  color: white !important;
}

.custom_position {
  position: relative;
}

// .whiteImage{
//     width: calcFunction(16)!important;
// }

.sure_and_search {
  width: 0.344rem !important;
  height: 0.291rem !important;
}

.sure_search {
  @extend .alignItemsCenter;

  img {
    margin-right: 0.16rem;
  }

  span {
    color: #111111 !important;
  }
}

.public_classfiy {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.search_inputs {
  input {
    border: none;
    background: #efefef;
    border-radius: calcFunction(100);
    font-size: calcFunction(14);
    padding: calcFunction(12) calcFunction(30);
  }
}
</style>
