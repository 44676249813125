<template>
  <div class="pieCharts" style="width: 100%">
    <div
      :id="wrapId ? wrapId : elId"
      :style="`width: ${width}; height: ${height}`"
    ></div>
  </div>
</template>
  
  <script>
import * as echarts from "echarts";
export default {
  name: "pieCharts",
  /**
   * width 图表容器宽度
   * height 图表容器高度
   * seriesData 图表展示数据
   * title 图表标题
   * xTitle 标题左边距离
   * yTitle 标题上边距离
   * legendLeft 图例位置左边距离
   * legendTop 图例位置上边距离
   * legendRight 图例位置右边距离
   * legendBottom 图例位置下边距离
   * wrapId 自定义容器id
   */
  props: [
    "width",
    "height",
    "wrapId",
    "seriesData",
    "title",
    "xTitle",
    "yTitle",
    "legendLeft",
    "legendTop",
    "legendRight",
    "legendBottom",
    "color",
  ],
  data() {
    return {
      elId: "pie-charts-" + Date.now(),
      series: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initCharts();
    });
  },
  computed: {
    option() {
      // 指定图表的配置项和数据
      var option = {
        title: {
          show: !!this.title,
          text: this.title,
          x: this.xTitle ? this.xTitle : 30,
          y: this.yTitle ? this.yTitle : 20,
          left: "center",
        },
        color: this.color,
        // 这个里面指定图标的样式和数据
        tooltip: {
          show: true,
          trigger: "item",
          formatter: function (params) {
            return (
              "<div>" +
              params.marker +
              params.name +
              '<span style="display: inline-block;margin-right:40px"></span>' +
              params.percent +
              "%" +
              "</div>"
            );
          },
        },
        legend: {
          left: this.legendLeft ? this.legendLeft : "center", //图例位置
          top: this.legendTop ? this.legendTop : 20, //图例位置
          right: this.legendRight ? this.legendRight : "right", //图例位置
          bottom: this.legendBottom ? this.legendBottom : "bottom", //图例位置
          textStyle: {
            //图例文字的样式
            color: "#333",
            fontSize: 16,
          },
        },
        series: this.series,
      };
      return option;
    },
  },
  methods: {
    initCharts() {
      var _id = this.wrapId || this.elId;
      var myChart = echarts.init(document.getElementById(_id));
      // 饼图配置
      var _seriesData = [
        {
          name: "",
          type: "pie", // 设置图表类型为饼图
          radius: ["50%", "70%"], // 饼图的半径，外半径为可视区尺寸（容器高宽中较小一项）的 55% 长度。
          center: ["25%", "50%"], // 圆心横坐标、纵坐标
          // 数据数组，name 为数据项名称，value 为数据项值
          data: this.seriesData,
          label: {
            show: false,
            position: "right",
          },
        },
      ];
      this.series = _seriesData;
      // 使用刚指定的配置项和数据显示图表。
      // console.log('饼状图配置');
      // console.log(this.option);
      myChart.setOption(JSON.parse(JSON.stringify(this.option)), true);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>
  
  <style>
</style>