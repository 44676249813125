<template>
	<div class="lancering">
		<div class="langcering_content">
			<div class="langcering_content_box">
				<div class="langcering_content_header">
					<publicNavigation navigationColor="white" :data="navigation" />
				</div>
				<div class="select_project">
					<div class="select_project_content">
						<div class="select_project_content_box">
							<div class="select_project_card_white">
								<div class="select_name_select_text_select_arrow">
									<div class="select_left_content_box">
										<div class="select_name">
											<span>bug标题</span>
										</div>
										<div class="select_input">
											<input type="text" v-model="total.tilskrivning" placeholder="请输入bug标题">
										</div>
									</div>
								</div>
								<div class="select_name_select_text_select_arrow" @click="vanskelighedSelect">
									<div class="select_left_content_box">
										<div class="select_name">
											<span>bug等级</span>
										</div>
										<div class="select_input custom_start"
											:style="total.vanskelighed ? 'padding-left:42%;' : ''">
											<div v-if="total.vanskelighed" class="image_start">
												<img :src="require('@/assets/start.png')"
													v-for="s,k in total.vanskelighed" :key="k" alt="">
											</div>
											<input v-if="!total.vanskelighed" disabled type="text"
												placeholder="请选择bug等级">
										</div>
									</div>
									<div class="select_arrow">
										<img src="@/assets/write.png" alt="" />
									</div>
								</div>
								<div class="select_name_select_text_select_arrow" @click="rewardPoints">
									<div class="select_left_content_box">
										<div class="select_name">
											<span>归属任务</span>
										</div>
										<div class="select_input">
											<input disabled type="text" v-model="total.bonuspoint"
												placeholder="请选择归属的任务">
										</div>
									</div>
									<div class="select_arrow">
										<img src="@/assets/write.png" alt="" />
									</div>
								</div>
								<div class="select_name_select_text_select_arrow" @click="overdragetPerson">
									<div class="select_left_content_box">
										<div class="select_name">
											<span>指派给</span>
										</div>
										<div class="select_input">
											<input disabled type="text" v-model="total.tildele" placeholder="请选择要指派的人">
										</div>
									</div>
									<div class="select_arrow">
										<img src="@/assets/write.png" alt="" />
									</div>
								</div>
								<div class="select_name_select_text_select_arrow">
									<div class="select_left_content_box">
										<div class="select_name">
											<span>bug内容</span>
										</div>
										<div class="select_input">
											<input type="text" v-model="total.bugcontent" placeholder="请输入bug内容">
										</div>
									</div>
								</div>
								<div style="border-bottom: 1px solid #efefef;padding-bottom: 20px;">
									<div class="select_name_select_text_select_arrow"
										style="border:none;padding-bottom: 0;">
										<div class="select_left_content_box">
											<div class="fileuploading_box">
												<div class="select_name" style="padding-right: 50%;">
													<span>附件</span>
												</div>
												<div class="select_input">
													<van-uploader :before-read="beforeRead" result-type="file"
														:after-read="uploadingEvent">
														<van-button class="uploading_position"
															type="primary"></van-button>
													</van-uploader>
													<div class="uploading_text">
														<span>请上传附件</span>
													</div>
												</div>
											</div>
										</div>
										<div class="select_arrow">
											<img src="@/assets/write.png" alt="" />
										</div>
									</div>
									<div class="text_list_box" v-show="filkeList.length > 0">
										<div class="list_text_fors" v-for="s,k in filkeList" :key="k">
											<span>{{s}}</span>
											<img :src="require('@/assets/redoffsetIocn.png')"
												@click="deleteClickEvent(s,k)" alt="" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="submit_task">
			<div class="submit_task_box">
				<button @click="submitEvent">提交</button>
			</div>
		</div>
		<van-popup v-model="popupShow" position="bottom"
			:style="{ height: '40%','border-top-left-radius': 10 + 'px','border-top-right-radius':10 + 'px' }">
			<van-picker title="标题" show-toolbar :columns="columns" @confirm="onConfirm" @cancel="onCancel" />
		</van-popup>
	</div>
</template>
<script>
	import publicNavigation from '@/publicNavigation/publicNavigation'
	import * as API from '@/api.ts'
	import axios from 'axios'
	export default {
		components: {
			publicNavigation
		},
		data() {
			return {
				navigation: {},
				total: {
					tilskrivning: '',
					vanskelighed: '',
					bonuspoint: '',
					tildele: '',
					Acceptor: '',
					fileList: [],
					description: '',
					title: '',
					projectId: '',
					additional_number: '',
					tildeleId: '',
					rewardTextFunction: '',
					pseronID: '',
					AcceptorID: '',
					bugcontent: '',
					solve_id:''
				},
				filkeList: [],
				uploadingImage: [],
				popupShow: false,
				columns: [],
				originData: [],
				originDataId: '',
				uploadingurl:''

			}
		},
		mounted() {
			this.Rquset()
			const data = window.localStorage.getItem('sure')
			let newObject;
			if(data){
				newObject = JSON.parse(data)
			}
			this.navigation = {
				image: require('@/assets/return.png'),
				name: '提出bug',
			}
			if (newObject) {
				this.total = {
					vanskelighed: newObject.start,
					tildele: newObject.personName.name,
					solve_id: newObject.personName.id,
					task:this.originDataId,
					level:newObject.start,
					content:this.total.bugcontent,
					annex:this.filkeList.join(',')
				}
			}
		},
		methods: {
			Rquset() {
				API.TaskLists().then(async e => {
					if (e.code == 1) {
						console.log(e)
						e.data.rows.map(items => {
							this.columns.push(items.title)
						})
						this.originData = e.data.rows
					}
				})
			},
			rewardPoints() {
				this.popupShow = !this.popupShow
			},
			onConfirm(value, index) {
				if (this.originData[index].title == value) {
					this.total.bonuspoint = value
					this.originDataId = this.originData[index].id
				}
				this.rewardPoints()
			},
			onCancel() {
				this.rewardPoints()
			},
			overdragetPerson() {
				const parms = {
					name: 'overdragetPerson',
					path: '/overdragetPerson'
				}
				this.pathRquest(parms)
			},
			vanskelighedSelect() {
				const parms = {
					name: 'vanskelighedSelect',
					path: '/vanskelighedSelect'
				}
				this.pathRquest(parms)
			},
			beforeRead(file) {
				if (!file.type) {
					this.$toast.fail('请上传文件');
					return false;
				}
				return true;
			},
			uploadingEvent(file) {
				this.filkeList.push(file.file.name)
				let data = new FormData();
				data.append("file", file.file);
				let config = {
					//添加请求头 
					headers: {
						"Content-Type": "multipart/form-data"
					},
				};
				axios.post('/admin/ajax/upload', data, config).then(async e => {
					if (e.data.status == 203) {
						this.$notfiy.confirm({
							title: '请重新登录',
							message: '登录已失效，请重新登录'
						}).then(() => {
							setTimeout(() => {
								window.location.href = '/'
							}, 800);
						}).catch(() => {
							this.$toast({
								type: 'fail',
								message: '取消登录'
							})
						})
					}
					if (e.data.status == 200) {
						this.uploadingurl = e.data.data.fullurl
						this.$toast({
							type: 'success',
							message: '上传成功'
						})
					}
				})
			},
			pathRquest(parms) {
				this.$router.push(parms)
			},
			submitEvent() {
				const parms = {
					title: this.total.title,
					solve_id: this.total.solve_id,
					task:this.originDataId,
					level:this.total.vanskelighed,
					content:this.total.bugcontent,
					annex:this.uploadingurl
				}
				API.taskbugAdd(parms).then(async e => {
					if (e.code == 1) {
						this.$toast.success('bug提交成功')
						window.localStorage.setItem('sure','')
						this.$router.back(-1)
					}
				})
			},
			deleteClickEvent(s, k) {
				this.filkeList.splice(k, 1)
			}
		}
	}
</script>
<style lang="scss" scoped>
	// 继承变量
	.alignItemsCenter {
		display: flex;
		align-items: center;
	}

	// 计算函数
	@function calcFunction($number, $default: 16) {
		@return calc($number * 2 / 100) + rem;
	}

	.lancering {
		height: 100vh;
		background: #F1F0F5;

		.langcering_content {
			.langcering_content_box {
				.langcering_content_header {
					background-image: url('@/assets/background.png');
					background-size: cover;
					width: 100%;
					height: calcFunction(150);
				}

				.select_project {
					margin-top: calcFunction(-80);

					.select_project_content {
						.select_project_content_box {
							.select_project_card_white {
								background: white;
								border-radius: calcFunction(8);
								margin: calcFunction(10);

								.select_name_select_text_select_arrow {
									@extend .alignItemsCenter;
									justify-content: space-between;
									padding: calcFunction(20) calcFunction(15);
									border-bottom: 1px solid #DCDCDC;
									position: relative;
									widht: 100%;

									.select_left_content_box {
										@extend .alignItemsCenter;

										.select_name {
											width: 40%;

											span {
												font-size: calcFunction(14);
												white-space: nowrap;
											}
										}

										.select_input {
											input {
												width: 100%;
												border: none;
												outline: none;
												font-size: calcFunction(14);
												background: transparent;

												&::placeholder {
													color: #999999;
												}
											}

											span {
												white-space: nowrap;
												font-size: calcFunction(14);
												color: #999999;
											}

										}
									}

									.select_arrow {
										img {
											width: calcFunction(6);
											height: calcFunction(12);
										}
									}
								}

								.select_name_select_text_select_arrow:nth-last-child(1) {
									.select_name {
										width: 60%;
									}
								}

								.select_name_select_text_select_arrow:nth-last-child(3) {
									.select_name {
										width: 60%;
									}
								}

								.select_name_select_text_select_arrow:nth-child(3) {
									.select_name {
										width: 37%;
									}
								}

								.select_name_select_text_select_arrow:nth-child(4) {
									.select_name {
										width: 45%;
									}
								}


							}
						}
					}
				}
			}
		}

		.fileuploading_box {
			@extend .alignItemsCenter;
		}
	}

	.submit_task {
		position: fixed;
		bottom: 0;
		width: 100%;
		background: white;

		.submit_task_box {
			padding: calcFunction(7.5) calcFunction(10);

			button {
				width: 100%;
				background: #FFB5B6;
				color: white;
				padding: calcFunction(12) 0;
				border: none;
				outline: none;
				font-size: calcFunction(14);
				border-radius: calcFunction(8);
			}
		}
	}

	.image_start {
		@extend .alignItemsCenter;
		width: 100%;

		img {
			flex: 1;
			width: calcFunction(14);
			height: calcFunction(14);
		}
	}

	.custom_start {
		// padding-left: 58%;
		justify-content: flex-end;
	}

	.postions_file {
		position: absolute;
		left: 0;
		width: 100%;
		opacity: 0;
	}

	.uploading_position {
		width: 70vw;
		height: 100%;
		background: transparent;
		border: none;
		padding-left: 10%;
		@extend .alignItemsCenter;
		justify-content: flex-start;
	}

	.text_list_box {
		margin: 0 calcFunction(15);
		margin-top: 10px;

		.list_text_fors {
			background: #FFEAEA;
			margin-bottom: 10px;
			padding: calcFunction(15) calcFunction(10);
			border-radius: calcFunction(4);
			@extend .alignItemsCenter;
			justify-content: space-between;

			span {
				font-size: calcFunction(14);
				color: #D71518;
			}

			img {
				width: calcFunction(10);
				height: calcFunction(10);
			}
		}

		.list_text_fors:nth-last-child(1) {
			margin-bottom: 0;
		}
	}

	.select_input {
		textarea {
			padding-top: calcFunction(10);
			font-size: calcFunction(14);
			border: none;
			width: 99%;
			height: calcFunction(40);
		}
	}
</style>
<style lang="scss">
	.lancering {
		.van-uploader {
			width: 20px;
			height: 20px;
			position: absolute;
			right: 10px;

			.van-uploader__wrapper {
				height: 20px;
			}

			.uploading_position {
				width: 20px;
			}
		}
	}
</style>