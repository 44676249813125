<template>
  <div class="header">
    <div class="custom_header">
      <div class="custom_header_background_image">
        <div class="custom_header_content">
          <div class="custom_header_left_title">
            <span>首页</span>
          </div>
          <!-- <div class="custom_header_right_messageIcon">
						<img src="../assets/notice.png" alt="">
					</div> -->
        </div>
      </div>
    </div>
    <!-- 用户信息卡片 -->
    <!-- @noTokenClick="noTokenClick" -->
    <newUserCard
      @integralGrade="integralGrade"
      @settingacount="settingacount"
      :content="content"
      :token="token"
    />
    <!-- 消息通知 -->
    <newMessage @messageEvent="messageEvent" :messageContent="messages" />
    <!-- 执行任务卡片 -->
    <newCardList :cardListData="newCardList" @eventClick="eventClick" />
    <!-- 待办工作 非总经理端-->
    <waitHandle v-if="identity != 4" :waitData="waitData" />

    <!-- 今日计划  非总经理端-->
    <todayCard v-if="identity != 4" :toDayMessageList="toDayMessageList" />

    <!-- 总经理端 -->
    <template v-if="identity == 4">
      <!-- 进行中的任务 -->
      <div class="progress-box">
        <div class="flex-c progress-box-top">
          <img
            class="mr5"
            style="width: 0.31rem"
            src="@/assets/progress.png"
            alt=""
          />
          <div>进行中的任务</div>
        </div>
        <PieChart
          :key="num"
          width="100%"
          :color="['#999', '#00BAFF', '#FFAE00']"
          legendLeft="50%"
          legendTop="40%"
          height="200px"
          :seriesData="task_total"
        />
      </div>
      <!-- 完成的项目 -->
      <div class="progress-box">
        <div class="flex-c-b progress-box-top">
          <div class="flex-c">
            <img
              class="mr5"
              style="width: 0.31rem"
              src="@/assets/Pie.png"
              alt=""
            />
            <div>完成的项目</div>
          </div>
          <div class="flex-c dateSelect">
            <div
              @click="activeHandle(1)"
              class="dateType btn"
              :class="active == 1 ? 'active' : ''"
            >
              月
            </div>
            <div
              @click="activeHandle(2)"
              class="dateType quarter btn"
              :class="active == 2 ? 'active' : ''"
            >
              季
            </div>
            <div
              @click="activeHandle(3)"
              class="dateType btn"
              :class="active == 3 ? 'active' : ''"
            >
              年
            </div>
          </div>
        </div>
        <PieChart
          :key="n"
          width="100% "
          :color="['#999', '#FF5558']"
          legendLeft="50%"
          legendTop="40%"
          height="200px"
          :seriesData="finishall"
        />
      </div>
      <!-- 奖励点 -->
      <div class="progress-box">
        <div class="flex-c-b progress-box-top">
          <div class="flex-c">
            <img
              class="mr5"
              style="width: 0.31rem"
              src="@/assets/grade.png"
              alt=""
            />
            <div>奖励点</div>
          </div>
        </div>
        <Histogram
          :key="index"
          style="margin: auto; padding: 0 0.2rem; box-sizing: border-box"
          :color="['#EEEEEE', '#FF5558']"
          height="260px"
          :seriesData="rewardAll"
        />
      </div>
    </template>
    <!-- 员工|主管 -->
    <template v-else>
      <!-- 完成情况 -->
      <div class="progress-box">
        <div class="flex-c-b progress-box-top">
          <div class="flex-c">
            <img
              class="mr5"
              style="width: 0.31rem"
              src="@/assets/Pie.png"
              alt=""
            />
            <div>完成情况</div>
          </div>
          <div class="flex-c dateSelect">
            <div
              @click="activeHandle(1)"
              class="dateType btn"
              :class="active == 1 ? 'active' : ''"
            >
              月
            </div>
            <div
              @click="activeHandle(2)"
              class="dateType quarter btn"
              :class="active == 2 ? 'active' : ''"
            >
              季
            </div>
            <div
              @click="activeHandle(3)"
              class="dateType btn"
              :class="active == 3 ? 'active' : ''"
            >
              年
            </div>
          </div>
        </div>
        <PieChart
          :key="num"
          width="100%"
          :color="['#51ec58', '#FE9C02']"
          legendLeft="50%"
          legendTop="40%"
          height="200px"
          :seriesData="completData"
        />
      </div>
    </template>
    <!-- 完成情况 -->
    <!-- <completionStauts /> -->
    <!-- 奖励点 非总经理端-->
    <template v-if="identity != 4">
      <div class="progress-box">
        <div class="flex-c-b progress-box-top">
          <div class="flex-c">
            <img
              class="mr5"
              style="width: 0.31rem"
              src="@/assets/Pie.png"
              alt=""
            />
            <div>奖励点</div>
          </div>
          <div class="flex-c dateSelect">
            <div
              @click="setActivePerson(1)"
              class="dateType btn"
              :class="activePerson == 1 ? 'active' : ''"
            >
              月
            </div>
            <div
              @click="setActivePerson(2)"
              class="dateType btn"
              :class="activePerson == 2 ? 'active' : ''"
            >
              年
            </div>
          </div>
        </div>
        <!-- 折线图表 -->
        <rewardPoints
          :key="lineIndex"
          width="100%"
          style="margin: auto; padding: 0.2rem; box-sizing: border-box"
          height="200px"
          :unit="rewardPoints.unit"
          :xAxisData="rewardPoints.date"
          :seriesData="rewardPoints.value"
          wrapId="mallOrderSeriesDataNumber"
          ref="mallOrderSeriesDataNumber"
        />
      </div>
    </template>
    <!-- 任务池 -->
    <taskPool :identity="identity" />
    <!-- 底部导航 -->
    <div class="bottom_navigation">
      <div class="navigation_image">
        <img
          src="@/assets/bottomnavigation.png"
          class="background_toggle"
          alt=""
        />
        <div class="background_white"></div>

        <div class="home_list">
          <ul>
            <li
              @click="currrentIndex(s, k)"
              v-for="(s, k) in navigationData"
              :key="k"
            >
              <img
                :src="toggleCurrentIndex == k ? s.statusImage : s.image"
                alt=""
              />
              <span
                :style="
                  toggleCurrentIndex == k ? 'color:#D71518' : 'color:#999999'
                "
                >{{ s.name }}</span
              >
            </li>
          </ul>

          <!--发布任务-->
          <img
            src="@/assets/addbutton.png"
            @click="addTask"
            class="addbuttons"
            alt=""
          />
        </div>
      </div>
    </div>
    <div v-if="identity == 4">
      <van-popup v-model="projectShow" round position="bottom">
        <div class="popup_task">
          <div class="offset_image">
            <img @click="addTask" src="@/assets/offset.png" alt="" />
          </div>
          <div class="plan_list custom_plan_list">
            <ul>
              <li @click="writetask(1)">
                <img :src="require('@/assets/releaseTask.png')" alt="" /><span
                  >发布任务</span
                >
              </li>
              <li @click="writetask(2)">
                <img :src="require('@/assets/createProject.png')" alt="" /><span
                  >发布项目</span
                >
              </li>
            </ul>
          </div>
        </div>
      </van-popup>
    </div>
    <!-- 弹起任务 -->
    <van-popup v-else round position="bottom" v-model="show">
      <div class="popup_task">
        <div class="offset_image">
          <img @click="addTask" src="@/assets/offset.png" alt="" />
        </div>
        <div class="plan_list">
          <ul>
            <li @click="writeLogEvent(0)">
              <img src="@/assets/writelog.png" alt="" /><span>写日志</span>
            </li>
            <li @click="writeLogEvent(1)">
              <img src="@/assets/writeplan.png" alt="" /><span>写计划</span>
            </li>
            <li @click="writetask(1)">
              <img src="@/assets/writetask.png" alt="" /><span>创建任务</span>
            </li>
          </ul>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import newUserCard from "@/component/home/newCard.vue";
import PieChart from "@/component/PieChart/PieChart.vue";
import Histogram from "@/component/Histogram/Histogram.vue";
import newMessage from "@/component/home/newMessage.vue";
import newCardList from "@/component/cardList/cardList.vue";
import waitHandle from "@/component/waitHandle/waitHandle.vue";
import todayCard from "@/component/todayCard/todayCard.vue";
import completionStauts from "@/component/completionStauts/completionStauts.vue";
import rewardPoints from "@/component/rewardPoints/rewardPoints.vue";
import taskPool from "@/component/taskPool/taskPool.vue";

// 数据
import * as API from "@/api.ts";

export default {
  components: {
    newUserCard,
    newMessage,
    PieChart,
    Histogram,
    newCardList,
    waitHandle,
    todayCard,
    completionStauts,
    rewardPoints,
    taskPool,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      lineIndex:1,//奖励点刷新时的key
      rewardPoints: {
        date: [],
        value: [],
        unit: "", //单位
      }, //奖励点
      identity: "", //身份：1员工，2主管，3人事财务，4总经理
      task_total: [], //进行中任务统计
      finishall: [], //完成的任务统计
      rewardAll: [], //奖励点数据
      completData: [], //完成情况列表
      num: 0, //饼图更新
      active: 1, //1:月,2:季,3:年
      n: 0, //更新完成的任务统计
      index: 0, //更新奖励点统计
      index: 0, //更新奖励点统计

      task_total: [], //进行中任务统计

      content: {},
      messages: [],
      cardListData: [],
      newCardList: [],
      waitData: [
        { number: 0, name: "待完成任务" },
        { number: 0, name: "待验收bug" },
        { number: 0, name: "待解决bug" },
      ],
      navigationData: [
        {
          image: require("@/assets/garyhome.png"),
          name: "首页",
          statusImage: require("@/assets/homered.png"),
        },
        {
          image: require("@/assets/garyuser.png"),
          name: "我的",
          statusImage: require("@/assets/userred.png"),
        },
      ],
      toggleCurrentIndex: 0,
      token: "",
      key: "",
      user: "",
      toDayMessageList: [],
      projectShow: false,
      show: false,
      messgaeList: [],
      type: 1,
      activePerson: 1, //选择的奖励点类别（非总经理）
    };
  },
  created() {
    //获取用户信息（包含：获取进行中任务统计，获取完成的任务统计，获取奖励点）
    this.getUserInfo();
  },
  mounted() {
    //获取奖励点
    this.getReward();
    // 个人信息
    API.userInfo().then(async (e) => {
      if (e) {
        this.identity = e.data.identity;
        this.content = {
          name: e.data.nickname,
          image: e.data.avatar,
          grade: e.data.level,
        };
        window.sessionStorage.setItem("userInfo", JSON.stringify(e.data));
      }
    });
    if (
      window.sessionStorage.getItem("userInfo") &&
      window.sessionStorage.getItem("usertoken")
    ) {
      this.key = JSON.parse(window.sessionStorage.getItem("userInfo")).identity;
      this.user = JSON.parse(window.sessionStorage.getItem("userInfo"));
      this.token = JSON.parse(window.sessionStorage.getItem("usertoken"));
    }
    if (this.content) {
      this.content = {
        name: this.user.nickname,
        image: this.user.avatar,
        grade: this.user.level,
      };
    }

    // 用户权限
    window.sessionStorage.setItem("key", 1);
    // 消息通知
    this.messages = [];
    // 消息通知
    API.bulletinList().then(async (e) => {
      console.log(e);
      if (e.code == 1) {
        e.data.rows.map((item) => {
          this.messages.push(item.title);
        });
        this.messgaeList = e.data.rows;
      }
    });
    // 循环卡片
    API.menubar({
      port: 2,
    }).then(async (e) => {
      if (e.code == 1) {
        let data = e.data;
        data.map(() => {
          // 分配切割已知数据数量分配独立数组
          this.newCardList.push(data.splice(0, 8));
        });
        console.log("功能栏目：", this.newCardList);
      }
    });
    // 待办工作
    API.todo().then(async (e) => {
      if (e.code == 1) {
        // 代办工作统计表
        this.waitData[0].number = e.data.info;
        this.waitData[1].number = e.data.bug;
        this.waitData[2].number = e.data.solve;
      }
    });

    API.plann({}).then(async (e) => {
      if (e.code == 1) {
        this.toDayMessageList = e.data.rows;
      }
    });
  },
  methods: {
    //获取奖励点
    async getReward() {
      let _this = this;
      let { type } = this;
      let params = { type };
      let { code, data } = await API.reward(params);
      if (code == 1) {
        let list = JSON.parse(JSON.stringify(data.rows));
        let date = [];
        let value = [];
        for (let k of list) {
          date.push(k.month);
          value.push(k.reward);
        }
        this.lineIndex++;
        _this.rewardPoints.date = date;
        _this.rewardPoints.value = value;
        _this.rewardPoints.unit = data.unit;
      }
    },
    //获取用户信息
    async getUserInfo() {
      let { code, data } = await API.userInfo();
      if (code == 1) {
        this.identity = data.identity;
        //获取进行中任务统计
        this.getTask_total();
        //获取完成的任务统计
        this.getFinishall();
        //获取奖励点
        this.getRewardAll();
        //获取完成情况
        this.getFinishData();
      }
    },
    //选择完成情况日期
    activeHandle(val) {
      this.active = val;
      // 总经理
      if (this.identity == 4) {
        //获取完成的任务统计
        this.getFinishall();
      }
      // 非总经理
      else {
        //获取完成情况
        this.getFinishData();
      }
    },
    //选择非总经理端奖励点日期
    setActivePerson(val) {
      this.activePerson = val;
      this.type = val;
      //获取奖励点
      this.getReward();
    },
    //获取进行中任务统计
    async getTask_total() {
      let { code, data } = await API.task_total();
      if (code == 1) {
        let task_total = data.rows;
        task_total.forEach((item) => {
          item.name = item.name + " " + item.value + "个";
        });
        this.task_total = task_total;
        this.num++;
      }
    },
    //获取完成的任务统计
    async getFinishall() {
      let params = {
        type: this.active,
      };
      let { code, data } = await API.finishall(params);
      if (code == 1) {
        let finishall = data.rows;
        finishall.forEach((item) => {
          item.name = item.name + " " + item.value + "个";
        });
        this.finishall = finishall;
        this.n++;
      }
    },
    //获取完成情况
    async getFinishData() {
      let { active } = this;
      let params = {
        type: active,
      };
      let { code, data } = await API.finish(params);
      if (code == 1) {
        let completData = data.rows;
        completData.forEach((item) => {
          item.name = item.name + ": " + item.value + "个";
        });
        this.completData = completData;
        this.num++;
      }
    },
    //获取奖励点
    async getRewardAll() {
      let { code, data } = await API.rewardAll();
      if (code == 1) {
        this.rewardAll = data.rows;
        this.index++;
      }
    },
    noTokenClick() {
      //
    },
    cardLIst(number) {
      let name;
      let image;
      switch (number) {
        case 0:
          (name = "执行任务"), (image = require("../assets/execute.png"));
          break;
        case 1:
          (name = "发起任务"), (image = require("../assets/initiate.png"));
          break;
        case 2:
          (name = "验收任务"), (image = require("../assets/accept.png"));
          break;
        case 3:
          (name = "解决bug"), (image = require("../assets/bug.png"));
          break;
        case 4:
          (name = "排行榜"), (image = require("../assets/ranking.png"));
          break;
        case 5:
          (name = "任务池"), (image = require("../assets/pond.png"));
          break;
        case 6:
          (name = "CP值列表"), (image = require("../assets/cp.png"));
          break;
        case 7:
          (name = "兑换管理"), (image = require("../assets/system.png"));
          break;
        case 8:
          (name = "人事管理"), (image = require("../assets/execute.png"));
          break;
        case 9:
          (name = "项目管理"), (image = require("../assets/list.png"));
          break;
        case 10:
          (name = "我的团队"), (image = require("../assets/team.png"));
          break;
        case 11:
          (name = "审核任务"), (image = require("../assets/examine.png"));
          break;
        default:
          break;
      }
      return {
        name: name,
        image: image,
      };
    },
    dataFor() {
      let number = 16;
      let array = [];
      for (let k = 0; k < number; k++) {
        array.push({
          image: this.cardLIst(k).image,
          name: this.cardLIst(k).name,
        });
      }
      return array;
    },
    eventClick(e) {
      var identity = this.user.identity;
      console.log(e);
      switch (e.title) {
        case "人事管理":
          this.$router.push({
            name: "person",
            path: "/person",
          });
          break;
        case "排行榜":
          this.$router.push({
            name: "userleaderboard",
            path: "/userleaderboard",
          });
          break;
        case "项目管理":
          this.$router.push({
            name: "project",
            path: "/project",
          });
          break;
        case "兑换管理":
          this.$router.push({
            name: "udveksle",
            path: "/udveksle",
          });
          break;
        case "CP值列表":
          this.$router.push({
            name: "priscpVrdi",
            path: "/priscpVrdi",
          });
          break;
        case "我的团队":
          this.$router.push({
            path: "/myTeam",
          });
          break;
        case "审核列表":
          this.$router.push({
            name: "auditList",
            path: "/auditList",
          });
          break;
        case "执行任务":
          this.$router.push({
            name: "perfrom",
            path: "/perfrom",
            query: identity != 3 || identity != 4 ? { name: "执行任务" } : "",
          });
          break;
        case " 验收任务":
          this.$router.push({
            name: "acceptanceTask",
            path: "/acceptanceTask",
          });
          break;
        case "任务池":
          this.$router.push({
            name: "taskPool",
            path: "/taskPool",
          });
          break;
        case "解决bug":
          this.$router.push({
            name: "solveBug",
            path: "/solveBug",
            query: {
              bugs: 1,
            },
          });
          break;
        case "项目列表":
          this.$router.push({
            name: "project",
            path: "/project",
          });
          break;
        case "礼品管理":
          this.$router.push({
            name: "giftManage",
            path: "/giftManage",
          });
          break;
        case "提出bug":
          this.$router.push({
            name: "propose",
            path: "/propose",
          });
          break;
        case "发起任务":
          this.$router.push({
            name: "initTask",
            path: "/initTask",
          });
          break;
        case "发布公告":
          this.$router.push({
            name: "Announce",
            path: "/Announce",
          });
          break;
        // case '发起任务':
        // 	this.$router.push({
        // 		name: 'lancering',
        // 		path: '/lancering',
        // 	})
        // 	break;
        default:
          break;
      }
    },
    messageEvent() {
      this.$router.push({
        name: "message",
        path: "/message",
      });
    },
    integralGrade() {
      this.$router.push({
        name: "integral",
        path: "/integral",
      });
    },
    currrentIndex(s, k) {
      this.toggleCurrentIndex = k;
      k == 1
        ? this.$router.push({
            name: "myUserInform",
            path: "/myUserInform",
          })
        : "";
    },
    projectShowEvent() {
      this.projectShow = !this.projectShow;
      this.show = !this.show;
    },
    addTask() {
      this.projectShowEvent();
    },
    writetask(e) {
      if (e == 1) {
        this.$router.push({
          name: "task",
          path: "/task",
        });
      } else {
        this.$router.push({
          name: "createProject",
          path: "/createProject",
        });
      }
    },
    settingacount() {
      this.$router.push({
        name: "settingacount",
        path: "/settingacount",
      });
    },
    writeLogEvent(s) {
      this.$router.push({
        name: "inputLog",
        path: "/inputLog",
        query:
          s > 0
            ? {
                planShow: 1,
              }
            : "",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.header {
  background: #f1f0f5;
  // overflow: auto !important;
  .custom_header {
    .custom_header_background_image {
      background-image: url("../assets/background.png");
      background-repeat: no-repeat;
      background-size: cover;
      height: 2.12rem;

      .custom_header_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.15rem;
        padding-top: 0.4rem;

        .custom_header_left_title {
          span {
            color: white;
            font-size: 0.38rem;
          }
        }

        .custom_header_right_messageIcon {
          display: flex;
          align-items: center;
          justify-content: center;

          // img {
          //   width: calcFunction(20);
          //   height: calcFunction(20);
          // }
        }
      }
    }
  }
}

.bottom_navigation {
  position: fixed;
  bottom: 0;
  width: 100vw;

  .navigation_image {
    @extend .alignItemsCenter;
    position: relative;
    width: 100%;

    .background_toggle {
      position: absolute;
      width: 100%;
      height: 1.7rem;
      z-index: 2;
    }
    .background_white {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 0.2rem;
      background: #fff;
      z-index: 2;
    }
    .home_list {
      position: relative;
      z-index: 99;
      width: 100%;
      ul {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        li {
          @extend .alignItemsCenter;
          flex-direction: column;
          justify-content: center;
          width: 40%;
          padding-top: 0.2rem;
          padding-bottom: 0.1rem;

          img {
            width: 0.479rem;
            height: 0.479rem;
          }
          span {
            font-size: 0.22rem;
            margin-top: calcFunction(2);
          }
        }
      }
    }

    .addbuttons {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1.39rem;
      height: 1.39rem;
      top: -0.4rem;
      z-index: 1;
    }
  }
}

.popup_task {
  margin: 0.29rem;

  .offset_image {
    @extend .alignItemsCenter;
    justify-content: flex-end;

    img {
      width: 0.29rem;
      height: 0.29rem;
    }
  }
}

.plan_list {
  @extend .alignItemsCenter;
  width: 100%;
  height: 70%;
  margin: 1rem 0;

  ul {
    @extend .alignItemsCenter;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    li {
      width: 33%;
      @extend .alignItemsCenter;
      flex-direction: column;

      img {
        width: 1.3rem;
        height: 1.3rem;
      }

      span {
        margin-top: 0.36rem;
        font-size: 0.32rem;
        font-weight: 500;
      }
    }
  }
}

.custom_plan_list {
  ul {
    li {
      width: 50% !important;
    }
  }
}

// 新
// 进行中的任务 盒子
.progress-box {
  margin: 0.2rem;
  // height: 3.5rem;
  background: #ffffff;
  border-radius: 0.16rem;

  // 顶部
  .progress-box-top {
    padding: 0.3rem;
    box-sizing: border-box;
    border-bottom: 0.01rem solid #dddddd;
  }
}
// 完成的项目
.dateSelect {
  border: 0.01rem solid rgb(211, 211, 211);
  border-radius: 0.08rem;
  overflow: hidden;
}
.dateType {
  padding: 0.03rem 0.12rem;
}
.quarter {
  border-left: 0.01rem solid rgb(211, 211, 211);
  border-right: 0.01rem solid rgb(211, 211, 211);
}
.active {
  background: rgb(215, 21, 25);
  color: #fff;
}
.type {
  background: rgb(215, 21, 25);
  color: #fff;
}
</style>
