<template>
  <div class="skilt">
    <div class="skilt_content_headers">
      <publicNavigation :data="navigation"/>
    </div>
    <div class="skilt_textarea">
      <div class="skilt_textarea_box">
        <!--<textarea v-model="inputText" name="" placeholder="个性签名..." id="" cols="30" rows="10"></textarea>-->
        <van-field
            v-model="inputText"
            rows="1"
            type="textarea"
            maxlength="50"
            :autosize=" { maxHeight: 100, minHeight: 100 }"
            placeholder="个性签名..."
        />
        <span>{{ inputText.length }}/50</span>
      </div>
    </div>
    <div class="skilt_button">
      <button @click="sureEditInformation">保存</button>
    </div>
  </div>
</template>
<script>
import publicNavigation from '@/publicNavigation/publicNavigation.vue';
import * as API from '@/api.ts'

export default {
  components: {
    publicNavigation
  },
  data() {
    return {
      navigation: {
        image: require('@/assets/return.png'),
        name: '个性签名'
      },
      fileList: [],
      inputText: ''
    }
  },
  watch: {
    inputText(s) {
      
      this.textNumber = s.split('').length
    }
  },
  mounted() {
    
    if (this.$route.query) {
      this.inputText = this.$route.query.e.Remark
    }
  },
  methods: {
    sureEditInformation() {
      const parms = this.$route.query.e
      const newparms = {
        nickname: parms.name,
        signature: this.inputText,
        ids: window.sessionStorage.getItem('userInfo') ? JSON.parse(window.sessionStorage.getItem('userInfo')).id : ''
      }
      if (newparms.ids) {
        API.adminedit(newparms).then(async e => {
          console.log(e)
          if (e.code == 1) {
            this.$toast({
              type: 'success',
              message: e.msg
            })
            setTimeout(() => {
              this.$router.back(-1)
            }, 1000);
          }else{
            this.$toast({
              message: e.msg
            })
          }
        })
      } else {
        this.$toast.fail('请仔细检查内容是否有空')
      }
    }
  }
}

</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100 ) + rem;
}

.skilt {
  background: #fff;
  min-height: 100vh;
  
  .skilt_textarea {
    
    margin: calcFunction(15);
    
    .skilt_textarea_box {
      background: #F5F5F5;
      width: 100%;
      position: relative;
      display: flex;
      height: 3rem;
      
      .van-field {
        background: transparent;
      }
      
      textarea {
        flex: 1;
        background: transparent;
        border: none;
        padding-top: calcFunction(16);
        padding-left: calcFunction(11);
        font-size: calcFunction(13);
        height: 100%;
        padding-right: calcFunction(11);
      }
      
      span {
        position: absolute;
        bottom: calcFunction(10);
        right: calcFunction(10);
        color: #999999;
        font-size: calcFunction(13);
      }
    }
  }
}

.skilt_button {
  margin: 1rem 0.3rem;
  
  button {
    width: 100%;
    height: 0.9rem;
    color: white;
    background: #D71518;
    border: none;
    outline: none;
    font-size: 0.32rem;
    border-radius: 0.1rem;
  }
}

</style>
