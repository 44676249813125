<template>
  <div
    v-if="userLog.length > 0"
    class="task_log"
    :style="offsets ? { 'margin-top': 0 } : ''"
  >
    <!-- 任务日志 -->
    <div
      v-if="type == 1"
      class="task_log_box"
      :style="
        userLog.length <= 0 ? { 'padding-top': 0, 'padding-bottom': 0 } : ''
      "
    >
      <div class="task_log_content" v-for="(s, k) of userLog.rows" :key="k">
        <div class="task_log_content_data">
          <div class="task_log_image">
            <img src="@/assets/calendar.png" alt="" />
            <span>{{ s.time_text }}</span>
          </div>
        </div>
        <div>
          <div class="task_log_list">
            <div
              class="task_log_list_for"
              v-if="s.daily.length > 0 || s.plan.length > 0"
            >
              <div class="list_for" v-for="(i, l) of s.plan" :key="l">
                <div class="for">
                  <div class="for_name">
                    <div class="name flex-c">
                      <img
                        style="width: 10px"
                        src="@/assets/person.png"
                        alt=""
                        class="mr10"
                      />
                      <span>{{ l == 0 ? s.nickname : "" }}</span>
                    </div>
                  </div>
                  <div class="for_list">
                    <div class="list_header">
                      <div class="header_image_and_title">
                        <img src="@/assets/taskplan.png" alt="" />
                        <span>计划</span>
                        <!-- <div>{{ i }}</div> -->
                      </div>
                      <div class="list_content">
                        <!-- i.lists.split('\n') v-for="p,l in i.content.split('\n')" :key="l" -->
                        <p>
                          {{ i }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="list_for" v-for="(i, l) of s.daily" :key="l">
                <div class="for">
                  <div
                    v-if="!(s.daily.length > 0 && s.plan.length > 0)"
                    class="for_name"
                  >
                    <div class="name flex-c">
                      <img
                        style="width: 10px"
                        src="@/assets/person.png"
                        alt=""
                        class="mr10"
                      />
                      <span>{{ l == 0 ? s.nickname : "" }}</span>
                    </div>
                  </div>
                  <div class="for_list">
                    <div class="list_header">
                      <div class="header_image_and_title">
                        <img src="@/assets/log.png" alt="" />
                        <span>日志</span>
                        <!-- <div>{{ i }}</div> -->
                      </div>

                      <div class="project_task" v-if="i.task_title">
                        <div class="project_header">
                          <span>项目任务：</span>
                          <div class="project_right">
                            <span>{{ i.task_title }}</span>
                            <!-- <img src="@/assets/red.png" alt="" /> -->
                          </div>
                        </div>
                      </div>
                      <div class="list_content">
                        <!-- i.lists.split('\n') v-for="p,l in i.content.split('\n')" :key="l" -->
                        <p v-for="(text, index) of i.daily" :key="index">
                          {{ ++index }}、{{ text }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="task_log_box"
      :style="
        userLog.length <= 0 ? { 'padding-top': 0, 'padding-bottom': 0 } : ''
      "
    >
      <div class="task_log_content" v-for="(s, k) in userLog" :key="k">
        <div class="task_log_content_data">
          <div class="task_log_image">
            <img src="@/assets/calendar.png" alt="" />
            <span>{{
              TimeFunction(s[0].dailyplantime || s[0].time_text)
            }}</span>
          </div>
        </div>
        <div v-for="(item, index) of s" :key="index">
          <div class="task_log_list">
            <div class="task_log_list_for" v-if="item.lists.length > 0">
              <div class="list_for" v-for="(i, l) in item.lists" :key="l">
                <div class="for">
                  <div class="for_name">
                    <div class="name flex-c">
                      <img
                        style="width: 10px"
                        src="@/assets/person.png"
                        alt=""
                        class="mr10"
                      />
                      <span>{{ l == 0 ? item.nickname : "" }}</span>
                    </div>
                  </div>
                  <div class="for_list">
                    <div class="list_header">
                      <div v-if="!isTask" class="header_image_and_title">
                        <img
                          v-if="i.type !== 2"
                          src="@/assets/taskplan.png"
                          alt=""
                        />
                        <img v-else src="@/assets/log.png" alt="" />
                        <span>{{
                          i.type == 1
                            ? "计划"
                            : i.type_text == "日志"
                            ? i.type_text
                            : "日志"
                        }}</span>
                        <!-- <div>{{ i }}</div> -->
                      </div>

                      <div class="project_task" v-if="i.title">
                        <div class="project_header">
                          <span>任务项目：</span>
                          <div class="project_right">
                            <span>{{ i.title }}</span>
                            <!-- <img src="@/assets/red.png" alt="" /> -->
                          </div>
                        </div>
                      </div>
                      <div class="list_content">
                        <!-- i.lists.split('\n') v-for="p,l in i.content.split('\n')" :key="l" -->
                        <p>
                          {{
                            i.content
                              ? i.content
                              : i.daily
                              ? i.daily.length > 0
                                ? i.daily.join("")
                                : ""
                              : ""
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isTask: Boolean, //是否是执行任务进去的
    userLog: Array | Object, //任务日志数据
    offsets: Number || "",
    type: String | Number, //1:任务日志
  },
  mounted() {
    console.log(this.userLog);
  },
  methods: {
    TimeFunction(e) {
      if (typeof e == "number") {
        const date = new Date(e * 1000);
        let Y = date.getFullYear();
        let M = date.getMonth() + 1;
        let R = date.getDate();
        console.log(Number(M) + 1 < 10);
        return Y + "-" + (M < 10 ? "0" + M : M) + "-" + (R < 10 ? "0" + R : R);
      } else {
        return e;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
i {
  font-style: normal;
}

// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.task_log {
  background-color: white;
  padding: calcFunction(15);
  margin-top: calcFunction(15);
  border-radius: calcFunction(8);

  .task_log_box {
    .task_log_content {
      margin-bottom: calcFunction(22);

      .task_log_content_data {
        .task_log_image {
          @extend .alignItemsCenter;

          img {
            width: calcFunction(15);
            height: calcFunction(15);
          }

          span {
            font-size: calcFunction(15);
            margin-left: calcFunction(5);
          }
        }
      }

      .task_log_list {
        background: #fafafa;
        margin-top: calcFunction(15);
        border-radius: calcFunction(4);
        border: 1px solid #ddd;

        .task_log_list_for {
          .list_for {
            .for {
              .for_name {
                padding-top: calcFunction(15);
                padding-left: calcFunction(10);
                border-bottom: 1px solid #eeeeee;

                .name {
                  span {
                    font-size: calcFunction(14);
                    color: #111111;
                  }
                  padding-bottom: 10px;
                  font-size: 28px;
                  font-family: PingFang SC;
                  font-weight: bold;
                  color: #111111;
                }
              }

              .for_list {
                padding: calcFunction(10);
                box-sizing: border-box;

                .list_header {
                  .header_image_and_title {
                    @extend .alignItemsCenter;
                    margin-bottom: calcFunction(15);
                    border-bottom: 1px solid #eeeeee;
                    padding-bottom: calcFunction(10);

                    img {
                      width: calcFunction(13);
                      height: calcFunction(16);
                    }

                    span {
                      font-size: calcFunction(14);
                      color: #111111;
                      margin-left: calcFunction(10);
                    }
                  }

                  .project_task {
                    margin-bottom: calcFunction(15);

                    .project_header {
                      @extend .alignItemsCenter;

                      span {
                        font-size: calcFunction(13);
                        color: #666666;
                      }

                      .project_right {
                        background: #ffe8e8;
                        padding: calcFunction(3) calcFunction(10);

                        span {
                          font-size: calcFunction(12);
                          color: #d71518;
                        }

                        img {
                          width: calcFunction(5);
                          height: calcFunction(8);
                        }
                      }
                    }
                  }

                  .list_content {
                    // padding-bottom: calcFunction(15);

                    p {
                      font-size: calcFunction(12);
                      color: #333333;
                      margin-bottom: calcFunction(4);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>