<!--
 * @Author: Mr.Cheng
 * @Date: 2023-10-12 14:24:25
 * @LastEditTime: 2023-10-31 11:52:41
 * @FilePath: \octopus-factory-map\src\new\components\singleRewardDetail.vue
-->
<template>
  <div>
    <!-- 顶部标题 -->
    <div class="top-bg">
      <div class="flex-c-c navigate-box">
        <!-- 返回按钮 -->
        <img
          @click="$router.go(-1)"
          class="left-back"
          src="@/assets/return.png"
          alt=""
        />
        <div>任务奖励详情</div>
      </div>
      <div class="basic-info">
        <!-- 任务名称 -->
        <div style="align-items: flex-start" class="flex-c">
          <div class="label">任务名称：</div>
          <div class="value">{{ detail.title }}</div>
        </div>
        <!-- 姓名&难度 -->
        <div class="flex-c mt10">
          <div style="width: 50%" class="flex-c">
            <div class="label">姓名：</div>
            <div class="value">{{ detail.user_nickname }}</div>
          </div>
          <div style="width: 50%" class="flex-c">
            <div class="label">难度：</div>
            <!-- 难度指数 -->
            <van-rate
              size="15px"
              color="#D71518"
              void-color="#FFF"
              :value="Number(detail.difficulty_text)"
              readonly
            />
          </div>
        </div>
        <!-- 合计奖励点 -->
        <div style="align-items: flex-start" class="flex-c mt10">
          <div class="label">合计奖励点：</div>
          <!-- 执行人 -->
          <div class="value" v-if="$route.query.type == 1">
            <!-- 无奖励 -->
            <div v-if="detail.reward_method == 1">0</div>
            <!-- 标准奖励 -->
            <template v-if="detail.reward_method == 2">
              <div>
                {{ Number(reward) + Number(detail.other_reward) }}
              </div>
            </template>
            <!-- 自定义 -->
            <div v-if="detail.reward_method == 3">
              {{ Number(detail.reward) + Number(detail.other_reward)+Number(detail.edit_reward) }}
            </div>
          </div>

          <!-- 验收人 -->
          <div class="value" v-if="$route.query.type == 2">
              <span v-if="detail.reward_method == 1">0</span>
              <span v-else>
                {{
                  detail.coefficient * detail.accept * detail.duration +
                  Number(detail.edit_reward) +
                  Number(detail.other_reward)
                }}
              </span>
            </div>
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <div class="form-box">
      <!-- 表格信息 执行人-->
      <div v-if="$route.query.type == 1" class="mt60">
        <!-- 上边表格 -->
        <table>
          <tr class="flex-c">
            <td class="label-box">任务基础值</td>
            <td class="label-value">{{ detail.basic }}</td>
          </tr>
          <tr class="flex-c">
            <td class="label-box">奖励系数</td>
            <td class="label-value">{{ detail.coefficient }}</td>
          </tr>
          <tr class="flex-c">
            <td class="label-box">任务时长</td>
            <td class="label-value">{{ detail.workload }}</td>
          </tr>
          <tr class="flex-c">
            <td class="label-box">实际工期</td>
            <td class="label-value">{{ detail.real_workload }}</td>
          </tr>
          <tr class="flex-c">
            <td class="label-box">工期差额</td>
            <td class="label-value">{{ detail.difference }}</td>
          </tr>
          <tr class="flex-c">
            <td class="label-box">超时系数</td>
            <td class="label-value">{{ detail.timeout_basic }}</td>
          </tr>
          <tr class="flex-c">
            <td class="label-box">提前奖励系数</td>
            <td class="label-value">{{ detail.in_advance }}</td>
          </tr>
        </table>
        <!-- 下边表格 -->
        <table class="mt10">
          <!-- 无奖励 -->
          <template v-if="detail.reward_method == 1">
            <tr class="flex-c">
              <td class="label-box1">任务基础值</td>
              <td class="label-num1">--</td>
              <td class="label-tips1 flex-c">
                <div class="mr10">--</div>
              </td>
            </tr>

            <tr class="flex-c">
              <td class="label-box1">额外奖励</td>
              <td class="label-num1">--</td>
              <td class="label-tips1">--</td>
            </tr>
            <tr class="flex-c">
              <td class="label-box">修正奖励点</td>
              <td class="label-num">--</td>
              <td class="label-tips flex-c" style="flex-direction: column">
                <div>--</div>
              </td>
            </tr>
            <tr class="flex-c">
              <td class="label-box">奖励点</td>
              <td class="label-num">--</td>
              <td class="label-tips flex-c" style="flex-direction: column">
                <div>--</div>
              </td>
            </tr>

            <tr class="flex-c">
              <td class="label-box">额外奖励点</td>
              <td class="label-num">--</td>
              <td class="label-tips">--</td>
            </tr>
          </template>

          <!-- 标准奖励 -->
          <template v-if="detail.reward_method == 2">
            <tr class="flex-c">
              <td class="label-box1">基础奖励</td>
              <td class="label-num1">
                {{ basic_reward }}
              </td>
              <td class="label-tips1 flex-c">
                <div class="flex-c mr10" style="flex-direction: column">
                  <div class="mr10">{{ detail.basic }}</div>
                  <div>任务基础值</div>
                </div>
                <div class="mr10">+</div>
                <div class="flex-c mr10" style="flex-direction: column">
                  <div class="mr10">{{ detail.coefficient }}</div>
                  <div>奖励系数</div>
                </div>
                <div class="mr10">x</div>

                <div class="flex-c" style="flex-direction: column">
                  <div>{{ detail.workload }}</div>
                  <div>任务时长</div>
                </div>
              </td>
            </tr>
            <tr class="flex-c">
              <td class="label-box1">额外奖励</td>
              <!-- 超时 -->
              <td class="label-num1" v-if="detail.timeout > 0">
                {{ additional_reward_c }}
              </td>
              <!-- 提前 -->
              <td
                class="label-num1 flex-c"
                style="flex-direction: column"
                v-if="detail.timeout < 0"
              >
                <div>
                  {{ additional_reward_t }}
                </div>
              </td>
              <!-- 按时 -->
              <template v-if="detail.timeout == 0">
                <td class="label-num1">
                  <!-- {{ detail.difference * detail.timeout_basic }} -->
                  --
                </td>
                <td class="label-tips1 flex-c">
                  <!-- <div class="flex-c mr10" style="flex-direction: column">
                      <div class="mr10">{{ detail.difference }}</div>
                      <div>工期差额</div>
                    </div>
                    <div class="mr10">x</div>
                    <div class="flex-c mr10" style="flex-direction: column">
                      <div class="mr10">{{ detail.timeout_basic }}</div>
                      <div>超时系数</div>
                    </div> -->
                  --
                </td>
              </template>

              <!-- 超 -->
              <template v-if="detail.timeout > 0">
                <td class="label-tips1 flex-c">
                  <div class="flex-c mr10" style="flex-direction: column">
                    <div class="mr10">{{ detail.difference }}</div>
                    <div>工期差额</div>
                  </div>
                  <div class="mr10">x</div>
                  <div class="flex-c mr10" style="flex-direction: column">
                    <div class="mr10">{{ detail.timeout_basic }}</div>
                    <div>超时系数</div>
                  </div>
                </td>
              </template>
              <!-- 提前 -->
              <template v-if="detail.timeout < 0">
                <td class="label-tips1 flex-c">
                  <div class="flex-c mr10" style="flex-direction: column">
                    <div class="mr10">{{ detail.difference }}</div>
                    <div>工期差额</div>
                  </div>
                  <div class="mr10">x</div>
                  <div class="flex-c mr10" style="flex-direction: column">
                    <div class="mr10">{{ detail.in_advance }}</div>
                    <div>提前奖励系数</div>
                  </div>
                </td>
              </template>

              <!-- 按时 -->
              <!-- <template v-if="detail.timeout == 0">
                  <td class="label-tips1">0</td>
                </template> -->
            </tr>
            <tr class="flex-c">
              <td class="label-box">修正奖励点</td>
              <td class="label-num">
                {{ detail.edit_reward || 0 }}
              </td>
              <td class="label-tips">修正奖励点</td>
            </tr>
            <tr class="flex-c">
              <td class="label-box">奖励点</td>
              <td class="label-num">
                {{
                  Number(detail.basic) +
                  detail.coefficient * detail.workload +
                  coefficient() +
                  Number(detail.edit_reward)
                }}
              </td>
              <td class="label-tips flex-c">
                <div class="flex-c mr10" style="flex-direction: column">
                  <div class="mr10">
                    {{
                      Number(detail.basic) +
                      detail.coefficient * detail.workload
                    }}
                  </div>
                  <div>基础奖励</div>
                </div>
                <div class="mr10">+</div>
                <div class="flex-c mr10" style="flex-direction: column">
                  <div class="mr10">{{ coefficient() }}</div>
                  <div>额外奖励</div>
                </div>
                <div class="mr10">+</div>

                <div class="flex-c" style="flex-direction: column">
                  <div>{{ detail.edit_reward || 0 }}</div>
                  <div>修正奖励</div>
                </div>
              </td>
            </tr>
            <tr class="flex-c">
              <td class="label-box">额外奖励点</td>
              <td class="label-num">{{ detail.other_reward }}</td>
              <td class="label-tips">总经理额外奖励或扣减值</td>
            </tr>
          </template>

          <!-- 自定义奖励 -->
          <template v-if="detail.reward_method == 3">
            <tr class="flex-c">
              <td class="label-box1">基础奖励</td>
              <td class="label-num1">--</td>
              <td class="label-tips1">--</td>
            </tr>

            <tr class="flex-c">
              <td class="label-box1">额外奖励</td>
              <td class="label-num1">--</td>
              <td class="label-tips1">--</td>
            </tr>
            <tr class="flex-c">
              <td class="label-box">修正奖励点</td>
              <td class="label-num">{{ detail.edit_reward || 0 }}</td>
              <td class="label-tips flex-c" style="flex-direction: column">
                <div>{{ detail.edit_reward || 0 }}</div>
                <div>修正奖励点</div>
              </td>
            </tr>
            <tr class="flex-c">
              <td class="label-box">奖励点</td>
              <td class="label-num">{{ detail.reward }}</td>
              <td class="label-tips flex-c" style="flex-direction: column">
                <div>{{ detail.reward }}</div>
                <div>奖励点</div>
              </td>
            </tr>

            <tr class="flex-c">
              <td class="label-box">额外奖励点</td>
              <td class="label-num">{{ detail.other_reward }}</td>
              <td class="label-tips">总经理额外奖励或扣减值</td>
            </tr>
          </template>
        </table>
      </div>

      <!-- 表格信息 验收人-->
      <div v-if="$route.query.type == 2" class="mt60">
        <!-- 上边表格 -->
        <table>
          <tr class="flex-c">
            <td class="label-box">奖励系数</td>
            <td class="label-value">{{ detail.coefficient }}</td>
          </tr>
          <tr class="flex-c">
            <td class="label-box">任务执行时长</td>
            <td class="label-value">{{ detail.duration || "--" }}</td>
          </tr>
          <tr class="flex-c">
            <td class="label-box">验收系数</td>
            <td class="label-value">{{ detail.accept || "--" }}</td>
          </tr>
        </table>
        <!-- 下边表格 -->
        <table class="mt10">
          <template v-if="[2,3].includes(detail.reward_method)">
            <tr class="flex-c">
              <td class="label-box">奖励点</td>
              <td class="label-num">
                {{ detail.coefficient * detail.accept * detail.duration }}
              </td>
              <td class="label-tips flex-c">
                <div class="flex-c mr10" style="flex-direction: column">
                  <div>{{ detail.coefficient }}</div>
                  <div>奖励系数</div>
                </div>
                <div class="mr10">x</div>
                <div class="flex-c mr10" style="flex-direction: column">
                  <div>{{ detail.duration }}</div>
                  <div>任务执行时长</div>
                </div>
                <div class="mr10">x</div>

                <div class="flex-c" style="flex-direction: column">
                  <div>{{ detail.accept }}</div>
                  <div>验收系数</div>
                </div>
              </td>
            </tr>
            <tr class="flex-c">
              <td class="label-box">修正奖励点</td>
              <td class="label-num">{{ detail.edit_reward || 0 }}</td>
              <td class="label-tips">修正奖励点</td>
            </tr>
            <tr class="flex-c">
              <td class="label-box">额外奖励点</td>
              <td class="label-num">{{ detail.other_reward || 0 }}</td>
              <td class="label-tips">总经理额外奖励或扣减值</td>
            </tr>
          </template>
          <template v-else>
            <tr class="flex-c">
              <td class="label-box">奖励点</td>
              <td class="label-num">
                --
              </td>
              <td class="label-tips flex-c">
                <div class="flex-c mr10" style="flex-direction: column">
                  <div>--</div>
                  <div>奖励系数</div>
                </div>
                <div class="mr10">x</div>
                <div class="flex-c mr10" style="flex-direction: column">
                  <div>--</div>
                  <div>任务执行时长</div>
                </div>
                <div class="mr10">x</div>

                <div class="flex-c" style="flex-direction: column">
                  <div>--</div>
                  <div>验收系数</div>
                </div>
              </td>
            </tr>
            <tr class="flex-c">
              <td class="label-box">修正奖励点</td>
              <td class="label-num">--</td>
              <td class="label-tips">修正奖励点</td>
            </tr>
            <tr class="flex-c">
              <td class="label-box">额外奖励点</td>
              <td class="label-num">--</td>
              <td class="label-tips">总经理额外奖励或扣减值</td>
            </tr>
          </template>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import * as API from "@/api.ts";

export default {
  name: "singleRewardDetail",
  data() {
    return {
      detail: {},
      basic_reward: "", //基础奖励点
      reward: "", //奖励点
      additional_reward_c: "", //超 额外奖励
      additional_reward_t: "", //提 额外奖励
    };
  },
  mounted() {
    // 获取详情页数据
    this.getData();
  },
  methods: {
    coefficient() {
      if (this.detail.timeout < 0) {
        return Number(this.detail.difference) * Number(this.detail.in_advance);
      } else if (this.detail.timeout > 0) {
        return (
          Number(this.detail.difference) * Number(this.detail.timeout_basic)
        );
      } else {
        return 0;
      }
    },
    // 获取详情页数据
    async getData() {
      let { id, type, auid } = this.$route.query;
      let params =
        type == 1
          ? {
              id,
              type,
            }
          : {
              id,
              type,
              user_id: auid,
            };
      let { code, data } = await API.rewardDetail(params);
      if (code == 1) {
        this.detail = data;
        //基础奖励点
        this.basic_reward =
          Number(this.detail.basic) +
          this.detail.coefficient * this.detail.workload;
        //超 额外奖励
        this.additional_reward_c =
          Number(this.detail.difference) * Number(this.detail.timeout_basic);
        //提 额外奖励
        this.additional_reward_t =
          Number(this.detail.difference) * Number(this.detail.in_advance);
        //奖励点
        this.reward =
          Number(this.basic_reward) +
          this.coefficient() +
          Number(this.detail.edit_reward);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 0.28rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
}
.value {
  font-size: 0.28rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333;
}
// 顶部背景
.top-bg {
  width: 100%;
  height: 1.88rem;
  background: linear-gradient(0deg, #feefef 50%, #ffffff 87%);
}
// 顶部标题
.navigate-box {
  width: 100%;
  height: 0.88rem;
  font-size: 0.34rem;
  font-family: PingFang SC;
  color: #111;
}
.left-back {
  width: 0.19rem;
  // height: 0.339rem;
  position: absolute;
  left: 0.4rem;
}

// 基础信息
.basic-info {
  margin-top: 0.2rem;
  width: 100%;
  background: #ffffff;
  box-shadow: 0rem -0.05rem 0.08rem 0rem rgba(0, 0, 0, 0.03);
  border-radius: 0.32rem 0.32rem 0rem 0rem;
  padding: 0.4rem;
  box-sizing: border-box;
  border-bottom: 0.01rem solid #dddddd;
}

// 表格
.form-box {
  width: 100%;
  padding: 0.28rem 0.4rem;
  box-sizing: border-box;
  background: #ffffff;

  .label-box {
    background: #eeeeee;
    width: 2.2rem;
    height: 0.8rem;
    font-size: 0.28rem;
    font-family: PingFang SC;
    font-weight: 800;
    color: #111111;
  }
  .label-box1 {
    background: #eeeeee;
    width: 2.2rem;
    height: 1.6rem;
    font-size: 0.28rem;
    font-family: PingFang SC;
    font-weight: 800;
    color: #111111;
  }
  .label-value {
    background: #ffffff;
    width: 4.45rem;
    height: 0.8rem;
    font-size: 0.28rem;
    font-family: PingFang SC;
    color: #111111;
  }
  .label-value1 {
    background: #ffffff;
    width: 4.45rem;
    height: 1.6rem;
    font-size: 0.28rem;
    font-family: PingFang SC;
    color: #111111;
  }

  .label-tips1 {
    background: #ffffff;
    width: 3.55rem;
    height: 1.6rem;
    font-size: 0.19rem;
    font-family: PingFang SC;
    color: #111111;
  }
  .label-tips {
    background: #ffffff;
    width: 3.55rem;
    height: 0.8rem;
    font-size: 0.19rem;
    font-family: PingFang SC;
    color: #111111;
  }
  .label-num {
    width: 0.9rem;
    height: 0.8rem;
    background: #ffffff;
    font-size: 0.19rem;
  }
  .label-num1 {
    width: 0.9rem;
    height: 1.6rem;
    font-size: 0.19rem;
    background: #ffffff;
  }
}

td {
  border: .01rem solid #dddddd;
  display: flex;
  justify-content: center;
  align-items: center;
}
table {
  border-collapse: collapse;
  border: .01rem solid #dddddd;
}
</style>