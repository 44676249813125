<template>
  <div class="priscpværdi">
    <div class="priscpværdi_card">
      <div class="priscpværdi_card_header">
        <publicNavigation :data="navigation" />
      </div>
      <div class="priscpværdi_card_box">
        <classfiyContent
          @eventClick="eventClick"
          :userNumbers="userNumbers"
          :Statistikker="Statistikker"
          :myinfoDesc="desc"
        />
      </div>
      <div class="detaljer">
        <div class="detaljer_content">
          <div class="detaljer_header">
            <div class="detaljer_title">
              <span>员工明细</span>
            </div>
          </div>
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            offset="500"
            @load="onLoad"
          >
            <personCard
              :vrdi="vrdi"
              :fontSize="'customSize'"
              :personCard="personCard"
              @moreClick="moreClick"
            />
          </van-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import publicNavigation from "@/publicNavigation/publicNavigation.vue";
import classfiyContent from "./compoments/classfiyContent.vue";
import personCard from "@/person/components/personCard/personCard.vue";
import { priscpVrdi } from "./priscpVrdi.ts";
import * as API from "@/api.ts";
export default {
  components: {
    publicNavigation,
    classfiyContent,
    personCard,
  },
  data() {
    return {
      navigation: {},
      personCard: [],
      vrdi: 1,
      desc: {},
      Statistikker: [],
      page: 1,
      limit: 10,
      loading: false, //列表是否正在加载
      finished: false, //列表是否全部加载完成
      userNumbers: "",
    };
  },
  mounted() {
    this.navigation = {
      image: require("../assets/return.png"),
      name: "奖励&cp值",
    };
    const parms = new priscpVrdi().init();
    this.personCard = parms.personCard;
    this.Requset();
    this.RequsetData();
  },
  methods: {
    //加载更多
    onLoad() {
      if (!this.finished) {
        this.page++;
        // 获取计划列表
        this.RequsetData();
      }
    },
    Requset() {
      API.myInfo().then(async (e) => {
        if (e.code == 1) {
          this.desc = e.data.rows;
        }
      });
    },
    RequsetData() {
      this.loading = true;
      let { page, limit, personCard } = this;
      API.staffDetail({
        page,
        limit,
      }).then(async (e) => {
        if (e.code == 1) {
          this.desc.reward = e.data.reward.all_reward;
          const all = e.data.reward.generate_reward_all,
            month = e.data.reward.generate_reward_month,
            year = e.data.reward.generate_reward_year;
          this.userNumbers = e.data.reward.cp_num;
          this.Statistikker.push(month, year, all);
          let _list = e.data.user_info;
          if (page == 1) {
            _list.forEach((item) => {
               Object.assign(item, {
                name: item.nickname,
                desc: [
                  {
                    name: "奖励点余额：",
                    text: item.reward,
                  },
                  {
                    name: "总共获得奖励：",
                    text: item.reward_all,
                  },
                  {
                    name: "cp值余额：",
                    text: item.cp,
                  },
                  {
                    name: "当月获得奖励：",
                    text: item.reward_month,
                  },
                  {
                    name: "分红总数：",
                    text: item.divvy,
                  },
                  {
                    name: "兑换现金数：",
                    text: "￥" + Number(item.money).toFixed(2),
                  },
                ],
              });
            });
            personCard = _list;
          } else {
            _list.forEach((item) => {
               Object.assign(item, {
                name: item.nickname,
                desc: [
                  {
                    name: "奖励点余额：",
                    text: item.reward,
                  },
                  {
                    name: "总共获得奖励：",
                    text: item.reward_all,
                  },
                  {
                    name: "cp值余额：",
                    text: item.cp,
                  },
                  {
                    name: "当月获得奖励：",
                    text: item.reward_month,
                  },
                  {
                    name: "分红总数：",
                    text: item.divvy,
                  },
                  {
                    name: "兑换现金数：",
                    text: "￥" + Number(item.money).toFixed(2),
                  },
                ],
              });
            });

            personCard.push(..._list);
          }
          this.loading = false;
          this.personCard = personCard;

          if (_list.length == 0) {
            this.finished = true;
          }
        }
      });
    },
    eventClick(e) {
      this.$router.push({
        name: "cpProject",
        path: "/cpProject",
      });
    },
    moreClick() {
      this.page += 1;
      this.$toast.loading("加载中...");
      setTimeout(() => {
        this.RequsetData();
      }, 1000);
    },
  },
};
</script>
<style lang="scss">
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.priscpværdi {
  background: #f1f0f5;
  padding-bottom: calcFunction(2);
  .priscpværdi_card_header {
    background: white;
  }

  .detaljer {
    .detaljer_content {
      background: white;
      padding: calcFunction(15);
      height: 57vh;
      overflow-y: scroll;

      .detaljer_header {
        padding-bottom: calcFunction(5);

        .detaljer_title {
          span {
            color: #111111;
            font-size: calcFunction(14);
            font-weight: bold;
          }
        }
      }
    }

    .customfontSize {
      span {
        font-size: calcFunction(14) !important;
      }
    }
  }
}
</style>