<template>
    <div class="cp_description">
        <div class="cp_description_content">
            <div class="cp_description_header">
                <publicNavigation :data="navigation"/>
            </div>
            <div class="background_color">
                <div class="background_colors"></div>
            </div>
            <div class="cp_card_description">
                <div class="cp_card_description_box">
                    <div class="description_content">
                        <cpvalueList :updata="cardData" />
                    </div>
                </div>
            </div>
            <div class="cp_dividends_record">
                <div class="cp_dividends_record_content">
                    <div class="cp_dividends_record_header">
                        <span>分红记录</span>
                    </div>
                    <div class="cp_dividends_record_content_data">
                        <div class="cp_dividends_record_content_list">
                            <div class="content_list" v-for="(s,k) in userObjects.rows" :key="k">
                                <div class="header_record">
                                    <div class="left_header_title">
                                        <span>第{{s.periodization}}分红</span>
                                    </div>
                                    <div class="right_header_title">
                                        <span>Ta的分红：</span>
                                        <span>{{ s.mydivvy }}</span>
                                    </div>
                                </div>
                                <div class="buttom_content">
                                    <div class="money">
                                        <span>奖池：</span>
                                        <span>{{ s.divvy }}</span>
                                    </div>
                                    <div class="time">
                                        <span>分红时间：</span>
                                        <span>{{ s.divvytime_text }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as API from '@/api.ts'
import publicNavigation from '@/publicNavigation/publicNavigation.vue';
import cpvalueList from '../cpValue/compoment/cpvalueCard.vue';
// import {cpDesc} from './cpDesc.ts'
export default {
    components:{
        publicNavigation,
        cpvalueList
    },
    data(){
        return{
            navigation:Object,
            cardData:'',
            userObjects:''
        }
    },
    mounted(){
        // const parms = new cpDesc().init()
        // this.cardData = parms.cpdata
        this.navigation = {
            image:require('../../assets/return.png'),
            name:'CP值详情',
			      close:''
        }
        this.requset()
    },
    methods:{
        requset(){
            API.cpDetail({
                id: this.$route.query.id,
                user_id: this.$route.query.user_id
            }).then(async e=>{
                if(e.code == 1){
                    this.cardData = new Array(e.data)
                    this.userObjects = e.data
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter{
    display: flex;
    align-items: center;
}

// 计算函数
@function calcFunction($number,$default:16){
  @return calc($number * 2 / 100) + rem;
}

.cp_description{
    height: 100vh;
    // background: #F1F0F5;
    .cp_description_header{
        z-index: 2;
    }
    .background_color{
        .background_colors{
            height: calcFunction(190);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: -1;
            background: linear-gradient(0deg, #FEEFEF 0%, #FFFFFF 100%);
        }
    }
}


.cp_dividends_record{
    background: #f1f0f5;
    .cp_dividends_record_content{
        margin: 0 calcFunction(10);
        padding-top: calcFunction(20);
        .cp_dividends_record_header{
            margin-bottom: calcFunction(15);
            span{
                color: #111111;
                font-size: calcFunction(14);
            }
        }
        .cp_dividends_record_content_data{
            height: 69vh;
            overflow-y: scroll;
            .cp_dividends_record_content_list{
                .content_list{
                    background: white;
                    padding: calcFunction(15);
                    border-radius: calcFunction(8);
                    margin-bottom: calcFunction(10);
                    .header_record{
                        @extend .alignItemsCenter;
                        justify-content: space-between;
                        padding-bottom: calcFunction(15);
                        border-bottom: 1px solid #EEEEEE;
                        .left_header_title{
                            span{
                                color: #111111;
                            }
                        }
                        .right_header_title{
                            span{
                                color: #333333;
                            }
                            span:nth-child(2){
                                color: #D71518;
                            }
                        }
                    }
                    .buttom_content{
                        @extend .alignItemsCenter;
                        justify-content: space-between;
                        margin-top: calcFunction(15);
                        .money{
                            span{
                                color: #333333;
                                font-size: calcFunction(14);
                            }
                            span:nth-child(1){
                                color: #999999;
                            }
                        }
                        .time{
                            span{
                                color: #333333;
                                font-size: calcFunction(14);
                            }
                            span:nth-child(1){
                                color: #999999;
                            }
                        }
                    }
                }
            }
        }
    }
}

::-webkit-scrollbar{
    display: none;
}
</style>
