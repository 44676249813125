<template>
    <div class="cp_value">
        <div class="cp_values">
            <div class="cp_header">
                <publicNavigation @sureEvenet="sureEvenet" :data="navigation"/>
            </div>
            <!-- cp 列表 -->
            <div class="cp_value_list">
                <cpValueList :arrayList="arrayList" />
            </div>
        </div>
    </div>
</template>
<script>
import publicNavigation from '@/publicNavigation/publicNavigation.vue';
import cpValueList from './compoments/cpvalueList.vue'
import * as API from '@/api.ts'
export default {
    components:{
        publicNavigation,
        cpValueList
    },
    data(){
        return{
            navigation:{},
			arrayList:[],
			mydescID:'',
			user_id:""
        }
    },
    mounted(){
        this.navigation = {
            image:require('../../assets/return.png'),
            name:'Ta的CP值',
			close:''
        }
		this.Requset()
    },
    methods:{
        sureEvenet(){
			this.$router.push({
				name:'cpexchange',
				path:'/cpexchange'
			})
        },
		Requset(){
			if(window.localStorage.getItem('mydesc')){
				this.mydescID = JSON.parse(window.localStorage.getItem('mydesc'))
				this.user_id = JSON.parse(window.localStorage.getItem('mydesc')).id
			}
			API.myCp({
				id:this.mydescID.id ? this.mydescID.id : this.$route.query.personInfromation?.id ? this.$route.query.personInfromation?.id : ''
			}).then(async e=>{
				if(e.code == 1){
					e.data.rows.map(item=>{
						this.arrayList.push({
							title:item.project_name,
							number:item.periodization,
							...item,
							user_id:this.user_id,
							money:item.divvy,
							children:[{
								title:'Ta的CP值：',
								text:item.cost_performance
							},{
								title:'占比：',
								text:item.proportion,
							},{
								title:'奖池：',
								text:item.prize_pool != '--' ? item.prize_pool : '未公布'
							},{
								title:'分红时间：',
								text:item.divvytime_text
							}]
						})
					})
				}
			})
			console.log(this.arrayList)
		}
    }
}


</script>
<style lang="scss" scoped>
.cp_value{
    height: 100vh;
    background: #F1F0F5;
    .cp_header{
        background: white;
    }
}
</style>