<!--
 * @Author: Mr.Cheng
 * @Date: 2023-10-27 10:32:06
 * @LastEditTime: 2023-10-30 20:48:36
 * @FilePath: \octopus-factory-map\src\new\myTeam\myTeam.vue
-->
<template>
  <div>
    <!-- 顶部标题 -->
    <div class="flex-c-c task-navigate-box">
      <!-- 返回按钮 -->
      <img
        @click="navBack"
        class="task-left-back"
        src="@/assets/return.png"
        alt=""
      />
      <div>我的团队</div>
      <div class="nav-right flex-c">
        <div
          @click="selectType(1)"
          :class="[active == 1 ? 'active' : 'default', 'flex-c-c']"
        >
          按岗位
        </div>
        <div
          @click="selectType(2)"
          :class="[active == 1 ? 'default' : 'active', 'flex-c-c']"
        >
          按排行
        </div>
      </div>
    </div>
    <!-- 按岗位 -->
    <template v-if="active == 1">
      <!-- 搜索框 -->
      <van-search
        v-model="search"
        @search="searchEvent"
        @clear="searchEvent"
        shape="round"
        background="#FFF"
        placeholder="搜索名称"
      />

      <div v-if="allTeam.length > 0" class="list">
        <div v-for="item of allTeam" :key="item.id">
          <!-- 部门名 -->
          <div class="department">{{ item.title }}</div>
          <!-- 部门员工 列表-->
          <div class="staff-box">
            <!-- 部门员工 -->
            <div
              v-for="list of item.person"
              :key="list.id"
              @click="toPerson(list)"
              class="staff flex-c-b"
            >
              <div class="flex-c">
                <!-- 头像 -->
                <img class="avatar mr10" :src="list.avatar" alt="" />
                <div class="info">
                  <!-- 昵称&岗位 -->
                  <div class="flex-c">
                    <div class="name">{{ list.nickname }}</div>
                    <div class="post">
                      {{ identity == 4 ? list.section_name : list.section }}
                    </div>
                  </div>
                  <!-- 等级&排名&任务数 -->
                  <div class="flex-c mt10">
                    <div class="label">
                      等级：<span class="value">{{ list.level }}</span>
                    </div>
                    <div class="label">
                      排名：<span class="value">{{ list.ranking }}</span>
                    </div>
                    <div class="label">
                      任务数：<span class="value">{{ list.task_num }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <img style="height: 0.5rem" src="@/assets/drop.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <van-empty v-else description="暂无团队" />
    </template>
    <!-- 按排行 -->
    <template v-else>
      <div class="ranking-box">
        <div class="ranking">
          <van-row gutter="10">
            <van-col span="8">
              <div
                @click="toPerson({ id: allData.first[1].id })"
                class="default-box flex-c-c gray-box mt20"
              >
                <!-- 数字logo -->
                <img class="num-log" src="@/assets/two.png" alt="" />
                <!-- 头像 -->
                <img class="top-avatar" :src="allData.first[1].avatar" alt="" />
                <!-- 昵称&等级 -->
                <div class="flex-c mt40">
                  <div class="nickname mr5 textOver1">
                    {{ allData.first[1].nickname }}
                  </div>
                  <div class="level">Lv.{{ allData.first[1].level }}</div>
                </div>
                <!-- 岗位 -->
                <div class="nickname mt5">{{ allData.first[1].section }}</div>
                <!-- 积分 -->
                <div class="score mt5">
                  当前积分：{{ allData.first[1].score }}
                </div>
              </div>
            </van-col>
            <van-col span="8">
              <div
                @click="toPerson({ id: allData.first[0].id })"
                class="default-box flex-c-c yellow"
              >
                <!-- 数字logo -->
                <img class="num-log" src="@/assets/one.png" alt="" />
                <!-- 头像 -->
                <img class="top-avatar" :src="allData.first[0].avatar" alt="" />
                <!-- 昵称&等级 -->
                <div class="flex-c mt40">
                  <div class="nickname mr5 textOver1">
                    {{ allData.first[0].nickname }}
                  </div>
                  <div class="level">Lv.{{ allData.first[0].level }}</div>
                </div>
                <!-- 岗位 -->
                <div class="nickname mt5">{{ allData.first[0].section }}</div>
                <!-- 积分 -->
                <div class="score mt5">
                  当前积分：{{ allData.first[0].score }}
                </div>
              </div>
            </van-col>
            <van-col span="8">
              <div
                @click="toPerson({ id: allData.first[2].id })"
                class="default-box flex-c-c red mt25"
              >
                <!-- 数字logo -->
                <img class="num-log" src="@/assets/three.png" alt="" />
                <!-- 头像 -->
                <img class="top-avatar" :src="allData.first[2].avatar" alt="" />
                <!-- 昵称&等级 -->
                <div class="flex-c mt40">
                  <div class="nickname mr5 textOver1">
                    {{ allData.first[2].nickname }}
                  </div>
                  <div class="level">Lv.{{ allData.first[2].level }}</div>
                </div>
                <!-- 岗位 -->
                <div class="nickname mt5">{{ allData.first[2].section }}</div>
                <!-- 积分 -->
                <div class="score mt5">
                  当前积分：{{ allData.first[2].score }}
                </div>
              </div>
            </van-col>
          </van-row>
        </div>
        <div class="ranking-list-box">
          <van-list
            v-if="list.length > 0"
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div
              v-for="item of list"
              :key="item.id"
              @click="toPerson(item)"
              class="ranking-list flex-c-b"
            >
              <div class="flex-c">
                <div class="serial-number">
                  {{ identity == 4 ? item.ranking : item.rank }}
                </div>
                <img class="list-avatar mr10" :src="item.avatar" />
                <div>
                  <div class="flex-c">
                    <!-- 昵称 -->
                    <div class="nickname">{{ item.nickname }}</div>
                    <!-- 岗位 -->
                    <div class="sect">{{ item.section }}</div>
                  </div>
                  <div class="score">当前积分：{{ item.score }}</div>
                </div>
              </div>
              <div class="level mr20">Lv.{{ item.level }}</div>
            </div>
          </van-list>
          <!-- 空列表 -->
          <van-empty v-else description="暂无数据" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import * as API from "@/api.ts";

export default {
  data() {
    return {
      active: 1,
      search: "", //搜索框
      allData: {}, //页面所有数据
      allTeam: [], //按岗位的部门列表
      list: [],
      page: 1,
      limit: 10,
      loading: false, //列表接口是否正在请求
      finished: false, //是否全部加载完
      month: "", //日期
      type: 1,
      identity: "", //身份
    };
  },
  mounted() {
    this.active = sessionStorage.getItem("typeActive");
    //获取用户信息
    this.getUserInfo();
  },
  methods: {
    // 返回上一页
    navBack() {
      sessionStorage.setItem("typeActive", 1);
      this.$router.go(-1);
    },
    // 选择显示类型
    selectType(type) {
      this.active = type;
      sessionStorage.setItem("typeActive", type);
    },
    //获取用户信息
    async getUserInfo() {
      let { code, data } = await API.userInfo();
      if (code == 1) {
        this.identity = data.identity;
        // 获取按岗位的部门列表
        this.getData();
        // 获取排行的部门列表
        this.getList();
      }
    },
    // 上拉加载更多
    onLoad() {
      if (!this.finished) {
        this.page++;
        this.getList();
      }
    },
    // 搜索
    searchEvent() {
      // 获取按岗位的部门列表
      this.getData();
    },
    // 获取按岗位的部门列表
    async getData() {
      // 总经理
      if (this.identity == 4) {
        let params = {
          search: this.search,
        };
        let { code, data } = await API.allteam(params);
        if (code == 1) {
          this.allTeam = data.rows;
        }
      } else {
        let params = {
          search: this.search,
        };
        let { code, data } = await API.team(params);
        if (code == 1) {
          this.allTeam = [{ title: data.section, person: data.rows }];
        }
      }
    },
    // 获取排行的部门列表
    async getList() {
      // 总经理
      if (this.identity == 4) {
        let { page, limit, list, month, type } = this;
        let params = {
          page,
          limit,
          month,
          type,
        };
        let { code, data } = await API.rankingList(params);
        if (code == 1) {
          this.allData = data;
          let _list = data.rows;
          if (page == 1) {
            list = _list;
            list.splice(0, 3);
          } else {
            list.push(..._list);
          }
          if (_list.length == 0) {
            this.finished = true;
          }
          this.list = list;
          this.loading = false;
        }
      } else {
        let { page, limit, list, month, type } = this;
        let params = {
          page,
          limit,
          month,
          type,
        };
        let { code, data } = await API.rankingList(params);
        if (code == 1) {
          this.allData = data;
          let _list = data.rows;
          if (page == 1) {
            list = _list;
            list.splice(0, 3);
          } else {
            list.push(..._list);
          }
          if (_list.length == 0) {
            this.finished = true;
          }
          this.list = list;
          this.loading = false;
        }
      }
    },
    // 跳转员工详情
    toPerson(item) {
      this.$router.push({
        name: "mydesc",
        path: "/mydesc",
        query: {
          description: item.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-right {
  width: 2rem;
  height: 0.48rem;
  border: 0.01rem solid #dddddd;
  border-radius: 0.08rem;
  position: absolute;
  right: 0.2rem;
  overflow: hidden;
  font-size: 0.24rem;
}
.task-navigate-box {
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 200;
}
.default {
  width: 1rem;
  height: 0.48rem;
  background: #ffffff;
  border-radius: 0 0.08rem 0.08rem 0;
  font-size: 0.24rem;
  font-weight: 500;
  color: #111111;
}
.active {
  width: 1rem;
  height: 0.48rem;
  background: #d71518;
  border-radius: 0 0.08rem 0.08rem 0;
  font-size: 0.24rem;
  font-weight: 500;
  color: #ffffff;
}
.label {
  font-size: 0.24rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
  margin-right: 0.2rem;
}
.value {
  font-size: 0.24rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #111;
}
.gray-box {
  background: linear-gradient(
    0deg,
    rgba(246, 247, 251, 1) 0%,
    rgba(235, 238, 246, 1) 100%
  );
}
.yellow {
  background: linear-gradient(
    0deg,
    rgba(255, 254, 251, 1) 0%,
    rgba(255, 251, 249, 1) 100%
  );
}
.red {
  background: linear-gradient(
    0deg,
    rgba(255, 250, 247, 1) 0%,
    rgba(255, 244, 239, 1) 100%
  );
}
.nickname {
  font-size: 0.28rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #111111;
}
.score {
  font-size: 0.22rem;
  font-weight: 500;
  color: #666666;
}
.level {
  background: #ffe7e7;
  border-radius: 0.13rem;
  font-size: 0.22rem;
  font-weight: 500;
  color: #d71518;
  padding: 0.05rem 0.1rem;
  box-sizing: border-box;
  white-space: nowrap;
}
// 按岗位
.list {
  // 部门名
  .department {
    font-size: 0.28rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #111111;
    padding: 0.25rem 0.2rem;
    box-sizing: border-box;
  }
  //   部门员工列表
  .staff-box {
    width: 100%;
    background: #fff;
    // 部门员工
    .staff {
      padding: 0.3rem 0.4rem;
      box-sizing: border-box;
      border-bottom: 0.01rem solid #f1f0f5;
      //   头像
      .avatar {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
      }
      .info {
        // 昵称
        .name {
          font-size: 0.3rem;
          font-weight: bold;
          color: #111111;
          margin-right: 0.2rem;
        }
        // 岗位
        .post {
          background: rgba(0, 142, 230, 0);
          border: 0.02rem solid #008ee6;
          border-radius: 1rem;
          padding: 0.05rem 0.15rem;
          font-size: 0.24rem;
          font-weight: 500;
          color: #008ee6;
        }
      }
    }
  }
}
// 按排行
.ranking-box {
  width: 100%;
  height: calc(100vh - 1rem);
  background: #fff;
  .ranking {
    padding: 0.2rem;
    box-sizing: border-box;
    padding-top: 1rem;
    .default-box {
      border-radius: 0.1rem;
      width: 100%;
      padding: 0.2rem;
      box-sizing: border-box;
      position: relative;
      flex-direction: column;
      .num-log {
        height: 0.59rem;
        position: absolute;
        right: 0.2rem;
        top: 0.2rem;
      }
      .top-avatar {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        position: absolute;
        top: -25%;
        left: 50%;
        transform: translate(-50%);
      }
    }
  }
  .ranking-list-box {
    width: 100%;
    background: #ffffff;
    border: 0.01rem solid #dcdcdc;
    .ranking-list {
      border-bottom: 0.01rem solid #dcdcdc;
      width: 100%;
      height: 1.4rem;
      //   序号
      .serial-number {
        margin-left: 0.3rem;
        font-size: 0.28rem;
        font-weight: bold;
        color: #666666;
      }
      //   头像
      .list-avatar {
        width: 0.67rem;
        height: 0.67rem;
        border-radius: 50%;
        margin-left: 0.4rem;
      }
      .sect {
        font-size: 0.24rem;
        font-weight: 500;
        color: #111111;
        margin-left: 0.2rem;
      }
    }
  }
}
</style>