<template>
  <div class="integral">
    <div class="integral_content">
      <div class="integral_content_data">
        <div class="integral_content_header">
          <div class="integral_header">
            <publicNavigation :data="navigation" />
          </div>
          <div
            class="background_color"
            :style="{ height: windowheight + 'px' }"
          >
            <div class="background_colors"></div>
          </div>
          <div class="grade_background_red">
            <div class="grade_background_red_content">
              <div class="slots">
                <div class="grade_content">
                  <div class="grade_title">
                    <span>当前等级：</span>
                    <span>Lv.{{ person.level }}</span>
                  </div>
                  <div class="grade_progress">
                    <div class="progress_content">
                      <div class="user_count_line">
                        <div class="user_count_line_count_text">
                          <div
                            class="user_count_number"
                            :style="{
                              width:
                                (100 / person.text_points) * 1000 + 20 + '%',
                            }"
                          >
                            <span
                              >{{ person.score }}/{{ person.text_points }}</span
                            >
                          </div>
                          <div class="user_count_lines">
                            <div
                              class="line_bcakground"
                              :style="{
                                width: (100 / person.text_points) * 1000 + '%',
                              }"
                            ></div>
                          </div>
                        </div>
                        <div class="user_need_experience">
                          <div class="user_need_experience_text">
                            <span
                              >升级还差{{ person.text_points }}积分<i
                                >LV.{{ person.max_level }}</i
                              ></span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="grade_right_background_image">
                  <div class="grade_image">
                    <img src="@/assets/gold.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="points">
                <div class="points_content_list">
                  <div class="points_list">
                    <div class="points_title">
                      <span>赚积分</span>
                    </div>
                    <div class="points_list_content">
                      <div
                        class="points_list_icon"
                        v-for="(s, k) in childrenData"
                        :key="k"
                      >
                        <div class="points_list_icon_bcakground">
                          <img src="@/assets/mingold.png" alt="" />
                          <span>+{{ s.score }}</span>
                        </div>
                        <div class="points_list_text">
                          <span>完成执行的任务</span>
                          <span>{{ s.memo }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import publicNavigation from "@/publicNavigation/publicNavigation.vue";
import * as API from "@/api.ts";
export default {
  components: {
    publicNavigation,
  },
  data() {
    return {
      navigation: {},
      windowheight: Number,
      childrenData: [],
      person: {
        level: "",
        score: "",
        text_points: "",
        max_level: "",
      },
    };
  },
  mounted() {
    const height = window.innerHeight;
    const width = window.innerWidth;
    this.windowheight = (height / width) * 10 * 2 + 40;
    this.navigation = {
      image: require("../assets/return.png"),
      name: "积分等级",
      close: "",
    };
    this.Requset();
  },
  methods: {
    Requset() {
      API.score_record().then(async (e) => {
        console.log(e);
        if (e.code == 1) {
          e.data.rows.map((item) => {
            this.childrenData.push({
              score: item.score,
              memo: item.memo,
            });
          });
        }
      });
      API.Admin().then(async (e) => {
        console.log(e);
        if (e.code == 1) {
          (this.person.level = e.data.level),
            (this.person.score = e.data.score),
            (this.person.text_points = e.data.text_points),
            (this.person.max_level = e.data.max_level);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.integral {
  height: 100vh;
  background: #f1f0f5;

  .integral_content {
    .integral_content_data {
      .integral_content_header {
        .integral_header {
          z-index: 3;
          position: absolute;
          width: 100%;
        }

        .background_color {
          .background_colors {
            height: calcFunction(190);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background: linear-gradient(196deg, #ffd4d4 0%, #ffffff 67%);
          }
        }

        .grade_background_red {
          position: absolute;
          z-index: 1;
          width: 100%;

          .grade_background_red_content {
            .slots {
              background: #d71518;
              padding: calcFunction(25) calcFunction(15);
              margin: 0 calcFunction(10);
              @extend .alignItemsCenter;
              justify-content: space-between;
              border-radius: calcFunction(8);

              .grade_content {
                width: 60%;

                .grade_title {
                  span {
                    color: white;
                    font-size: calcFunction(14);
                  }

                  span:nth-last-child(1) {
                    font-size: calcFunction(25);
                  }
                }

                .grade_progress {
                  .progress_content {
                  }
                }
              }

              .grade_right_background_image {
                .grade_image {
                  img {
                    width: calcFunction(100);
                    height: calcFunction(100);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.user_count_line {
  .user_count_line_count_text {
    .user_count_number {
      white-space: nowrap;
      display: flex;
      justify-content: flex-end;

      span {
        color: #d71518;
        font-size: calcFunction(12);
        text-align: right;
        padding: calcFunction(3) calcFunction(4);
        background: #ffcccc;
        margin-bottom: calcFunction(8);
        border-radius: calcFunction(5);
      }
    }

    .user_count_lines {
      height: calcFunction(2);
      width: 100%;
      background: #eeeeee;

      .line_bcakground {
        background: #fff000;
        height: 100%;
        position: relative;

        &::after {
          content: "";
          width: calcFunction(10);
          height: calcFunction(10);
          border-radius: calcFunction(1000);
          position: absolute;
          right: 0;
          background: #fff000;
          z-index: 1;
          top: calcFunction(-4);
        }
      }
    }
  }

  .user_need_experience {
    margin-top: calcFunction(5);
    padding-bottom: calcFunction(15);

    .user_need_experience_text {
      span {
        color: #ffffff;
        font-size: calcFunction(12);

        i {
          color: #fff000;
          font-style: normal;
        }
      }
    }
  }
}

.points {
  margin-top: calcFunction(10);

  .points_content_list {
    margin: 0 calcFunction(10);

    .points_list {
      background: white;
      border-radius: calcFunction(8);

      .points_title {
        padding-top: calcFunction(20);
        padding-left: calcFunction(15);
        border-bottom: 1px solid #eeeeee;
        padding-bottom: calcFunction(15);

        span {
          font-size: calcFunction(16);
          font-weight: bold;
        }
      }

      .points_list_content {
        padding: calcFunction(15);
        padding-bottom: calcFunction(20);

        .points_list_icon {
          @extend .alignItemsCenter;
          margin-bottom: calcFunction(20);

          .points_list_icon_bcakground {
            @extend .alignItemsCenter;
            flex-direction: column;
            background: #ffe0df;
            justify-content: center;
            height: calcFunction(50);
            padding: calcFunction(8) calcFunction(10);
            border-radius: calcFunction(4);

            img {
              width: calcFunction(20);
              height: calcFunction(20);
            }

            span {
              font-size: calcFunction(14);
              color: #d71518;
            }
          }

          .points_list_text {
            @extend .alignItemsCenter;
            flex-direction: column;
            align-items: flex-start;
            margin-left: calcFunction(10);

            span {
              color: #999999;
              font-size: calcFunction(12);
              font-family: PingFang SC;
            }

            span:nth-child(1) {
              font-size: calcFunction(15);
              font-weight: bold;
              color: #111111;
              margin-bottom: calcFunction(10);
            }
          }
        }

        .points_list_icon:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>