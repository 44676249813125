<template>
  <div class="cdDescription">
    <div class="cdDescription_content">
      <div class="position_absolute">
        <publicNavigation @sureEvenet="sureEvenet" :data="navigation" />
      </div>
    </div>
    <div class="background_color">
      <div class="background_colors"></div>
    </div>
    <div class="text_description">
      <div class="text_descriptions">
        <div class="text_position_absolute">
          <div class="description_header">
            <span>{{ textData.title }}</span>
          </div>
          <div class="description_text_for">
            <div class="text_for">
              <div class="text_list" v-for="(s, k) in textData.children" :key="k">
                <span>{{ s.name }}</span>
                <span>{{ s.text }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cpprojektCard">
      <div class="position_absolute_description_box">
        <div class="cpprojektCard_content">
          <cpprojektCard
            @editEvent="editEvent"
            @sureEvent="sureEvent"
            :recorData="recorData"
            :descriptionNumber="1"
            :cardData="cardData"
          />
        </div>
      </div>
    </div>
    <div class="buttom_description" v-if="projectData.status != 3">
      <div class="buttom_descriptions">
        <!-- show = true -->
        <button @click="submit">新建分红</button>
      </div>
    </div>
    <!-- 编辑分红金额|分红日期 弹窗 -->
    <van-popup v-model="show" round position="bottom" style="height: auto">
      <div class="bouns_input">
        <div class="bouns_text">
          <div>{{ title }}</div>
          <img
            @click="show = false"
            style="width: 1rem; height: 1rem"
            src="../assets/clear.png"
            alt=""
          />
        </div>
        <div v-if="type == 1" class="input" style="margin-bottom: 50px">
          <span>分红金额</span>
          <input
            type="number"
            v-model="udbytteMoney"
            placeholder="输入分红金额"
          />
        </div>

        <div
          v-if="type == 2"
          class="input"
          style="margin-bottom: 50px"
          @click="holdOpFunction()"
        >
          <span>分红日期</span>
          <div class="wInput" :style="udbytteTime ? 'color:#333' : ''">
            {{ udbytteTime ? udbytteTime : "选择分红日期" }}
          </div>
          <!-- <input
            type="text"
            v-model="udbytteTime"
            disabled
            placeholder="输选择分红日期"
          /> -->
        </div>
        <div class="bouns_button">
          <div class="bouns_buttons">
            <button
              @click="editSubmit"
              :style="isSb ? 'background: #d71518;' : ''"
              class="button"
            >
              提交
            </button>
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="showDate" position="bottom" :style="{ height: '40%' }">
      <!-- v-model="currentDate" -->

      <van-datetime-picker
        type="date"
        title="选择年月日"
        @cancel="showDate = false"
        @confirm="onChange"
        :min-date="minDate"
      />
    </van-popup>
  </div>
</template>
<script>
import publicNavigation from "@/publicNavigation/publicNavigation.vue";
import cpprojektCard from "@/cpprojekt/compoments/cpprojektCard.vue";
// import {
// 	descriptionData
// } from "./cpDescription.ts";
import * as API from "@/api.ts";
export default {
  components: {
    publicNavigation,
    cpprojektCard,
  },
  data() {
    return {
      navigation: {
        image: require("../assets/return.png"),
        name: "",
        sure: "",
        close: "",
      },
      cardData: [],
      projectID: "",
      recorData: [],
      textData: "",
      projectData: "",
      show: false, //新建分红弹窗是否显示
      showDate: false, //日期选择弹窗显隐
      udbytteMoney: "", //分红金额
      udbytteTime: "", //分红日期
      minDate: new Date(),
      isSb: false, //当前是否可以创建分红
      title: "", //分红标题
      type: "", //1:修改分红金额，2:修改分红日期
      id: "", //修改分红时选中的id
    };
  },
  watch: {
    udbytteMoney() {
      this.isSubmit();
    },
    udbytteTime() {
      this.isSubmit();
    },
  },
  mounted() {
    if (this.$route?.query.description) {
      const data = JSON.parse(this.$route.query.description);
      this.projectID = data.id;
      this.projectData = data;
    }
    this.navigation = {
      image: require("../assets/return.png"),
      name: "CP值详情",
      sure: this.projectData.status != 3 ? "结束分红" : "",
      close: "",
    };

    this.Request();
  },
  methods: {
    //修改分红金额或日期
    editSubmit() {
      if (!this.isSb) {
        return;
      }
      const parms =
        this.type == 1
          ? {
              id: this.id,
              divvy: this.udbytteMoney,
            }
          : {
              id: this.id,
              divvytime: this.originDate.valueOf() / 1000,
            };
      API.divvyEdit(parms).then(async (e) => {
        this.$toast({
          type: e.code == 0 ? "fail" : "success",
          message: e.msg,
        });
        if (e.code == 1) {
          this.udbytteMoney = "";
          this.originDate = "";
          this.show = false;
          setTimeout(() => {
            this.cardData = [];
            this.Request();
          }, 400);
        }
      });
    },
    //修改分红金额或日期（弹窗）
    editEvent(s, k, type) {
      this.title = s.title;
      this.id = s.id;
      this.type = type;
      this.show = true;
    },
    //判断当前是否可以创建分红
    isSubmit() {
      let { udbytteMoney, originDate, type } = this;
      console.log(udbytteMoney && type == 1, originDate && type == 2);
      if ((udbytteMoney && type == 1) || (originDate && type == 2)) {
        this.isSb = true;
      } else {
        this.isSb = false;
      }
    },
    // 新建分红提交
    submit() {
      const parms = {
        id: this.projectID,
      };
      API.divvyAdd(parms).then(async (e) => {
        this.$toast({
          type: e.code == 0 ? "fail" : "success",
          message: e.msg,
        });
        if (e.code == 1) {
          this.udbytteMoney = "";
          this.originDate = "";
          this.show = false;
          setTimeout(() => {
            this.cardData = [];
            this.Request();
          }, 400);
        }
      });
    },
    formatDate(date) {
      const year = date.getFullYear();
      const mont =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      const monteh =
        date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return `${year + "-" + mont + "-" + monteh}`;
    },
    onChange(date) {
      const times = this.formatDate(date);
      this.udbytteTime = times;
      this.originDate = date;

      this.holdOpFunction();
    },
    holdOpFunction() {
      this.showDate = !this.showDate;
    },
    addbonusEvenent() {
      this.$router.push({
        name: "addbonus",
        path: "/addbonus",
        query: {
          addUdbytte: 1,
          projectID: this.projectID,
        },
      });
    },
    sureEvent(s, k) {
      console.log(s, k);
      this.$router.push({
        path: "/bounsdescription",
        query: {
          e: s,
        },
      });
    },
    Request() {
      API.CpDetail({
        id: this.projectID,
      }).then(async (e) => {
        console.log(e);
        if (e.code == 1) {
          this.textData = {
            title: e.data.name,
            status: 5,
            children: [
              {
                name: "兑换CP值：",
                text: e.data.total,
              },
              {
                name: "参与人：",
                text: e.data.play_count,
              },
              {
                name: "已分红：",
                text: e.data.dividend_paid,
              },
              {
                name: "分红截止日期：",
                text: e.data.divvy_time_text,
              },
              {
                name: "兑换截止日期：",
                text: e.data.exchange_time_text,
              },
            ],
          };
          e.data.divvy.map((item) => {
            this.cardData.push({
              title: `第${item.periodization}期分红`,
              status: item.periodization,
              ...item,
              children: [
                {
                  name: "分红人数：",
                  text: item.play_count,
                },
                {
                  name: "分红金额：",
                  text: item.divvy ? item.divvy : "--",
                },
                {
                  name: "分红日期：",
                  text: item.divvytime_text ? item.divvytime_text : "--",
                },
              ],
            });
          });
        }
      });
    },
    sureEvenet() {
      if (this.projectData.status != 3) {
        API.divvyEnd({
          id: this.projectID,
        }).then(async (e) => {
          this.$toast({
            type: e.code == 1 ? "success" : "fail",
            message: e.msg,
          });
          if (e.code == 1) {
            setTimeout(() => {
              this.$router.back(-1);
            }, 1000);
          }
        });
      } else {
        this.$toast.fail("已结束分红");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}
.bouns_input {
  margin: 0 calcFunction(15);
  padding: 1.5rem 1rem 3rem 1rem;
  box-sizing: border-box;
  .bouns_text {
    display: flex;
    justify-content: space-between;
    margin-bottom: calcFunction(50);
    color: #d71518;
  }
  .input {
    font-size: 1rem;
    color: #333;
    @extend .alignItemsCenter;
    padding-bottom: calcFunction(18);
    margin-bottom: calcFunction(18);
    border-bottom: 1px solid rgba(220, 220, 220, 0.6);

    span {
      width: 30%;
      text-align: left;
      font-size: calcFunction(15);
    }

    input {
      margin-left: calcFunction(10);
      border: none;
      outline: none;
      font-size: calcFunction(14);
    }
    .wInput {
      margin-left: calcFunction(10);
      border: none;
      outline: none;
      color: rgb(126, 126, 126);
      font-size: calcFunction(14);
    }
  }
}
.bouns_button {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;

  .bouns_buttons {
    width: 96%;

    padding: calcFunction(20);

    .button {
      width: 100%;
      background: #ffb5b6;
      color: white;
      padding: calcFunction(10);
      border: none;
      outline: none;
      border-radius: calcFunction(8);
    }
  }
}
.cdDescription {
  background: #f1f0f5;
  height: 100vh;

  .background_color {
    .background_colors {
      height: calcFunction(300);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(0deg, #feefef 0%, #ffffff 100%);
    }
  }

  .cpprojektCard {
    padding: calcFunction(15);
    background: white;
    height: 58vh;
    position: relative;
    border-top-left-radius: calcFunction(16);
    border-top-right-radius: calcFunction(16);
    margin-top: calcFunction(16);

    .position_absolute_description_box {
      .cpprojektCard_content {
        border-radius: calcFunction(4);

        .cpprojektCard_header {
          .cpprojektCard_title {
            padding-bottom: calcFunction(15);

            span {
              font-size: calcFunction(14);
            }
          }
        }

        .card_list {
          margin-top: calcFunction(10);
          height: 53vh !important;
        }
      }
    }
  }

  .list_each:nth-child(2) {
    span:nth-last-child(1) {
      color: #d71518 !important;
    }
  }

  .list_each:nth-last-child(1) {
    span:nth-last-child(1) {
      color: black !important;
    }
  }

  .buttom_description {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: white;
    height: calcFunction(70);

    .buttom_descriptions {
      margin: 0 calcFunction(10);

      button {
        width: 100%;
        background: #d71518;
        border-radius: calcFunction(6);
        height: calcFunction(44);
        margin-top: calcFunction(7.5);
        color: white;
        border: none;
        font-size: calcFunction(17);
      }
    }
  }

  .text_description {
    position: relative;
    margin-bottom: calcFunction(10);
    height: calcFunction(110);

    .text_descriptions {
      position: absolute;
      top: 0;
      z-index: 1;

      .text_position_absolute {
        margin: 0 calcFunction(20);

        .description_header {
          span {
            font-size: calcFunction(15);
            color: #111111;
            font-weight: bold;
          }
        }

        .description_text_for {
          .text_for {
            @extend .alignItemsCenter;
            flex-wrap: wrap;
            justify-content: space-between;

            .text_list {
              width: 50%;
              margin-top: calcFunction(12);

              span {
                font-size: calcFunction(12);
                color: #111111;
              }

              span:nth-child(1) {
                color: #666666;
              }
            }

            .text_list:nth-child(3) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.cdDescription_content {
  height: 83.3px;

  .position_absolute {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 1;
  }
}
</style>
