export class rewardsContent{
    init(){
        return{
            rewardsContent:[
                {
                    name: "王舞",
                    person:1,
                    desc: [
                        {
                            name: "工作量：",
                            text: "20天",
                        },
                        {
                            name: "奖励点：",
                            text: "20",
                        },
                        {
                            name: "工作量：",
                            text: "延期3天",
                        },
                        {
                            name: "额外奖励：",
                            text: "30",
                        },{
                            name: "难度：",
                            text: 3,
                            startNumber:3
                        }
                    ],
                },
                {
                    name: "李思",
                    person:1,
                    desc: [
                        {
                            name: "工作量：",
                            text: "20天",
                        },
                        {
                            name: "奖励点：",
                            text: "20",
                        },
                        {
                            name: "工作量：",
                            text: "延期3天",
                        },
                        {
                            name: "额外奖励：",
                            text: "30",
                        },{
                            name: "难度：",
                            text: 3,
                        }
                    ],
                },
            ]
        }
    }
}