/*
 * @Author: Mr.Cheng
 * @Date: 2023-09-05 14:08:53
 * @LastEditTime: 2023-10-17 10:49:01
 * @FilePath: \octopus-factory-map\src\utils\utils.js
 */
import $ from 'jquery'
import axios from "axios";

export default {
    // 防抖
    debounce(fn, delay, immediate) {
        let timer = null;
        return function () {
            let that = this;
            let arg = arguments;
            if (timer) {
                clearTimeout(timer);
            }
            if (immediate) {
                let bool = !timer;
                timer = setTimeout(() => {
                    timer = null;
                }, delay);
                if (bool) {
                    fn.apply(that, arg);
                }
            } else {
                timer = setTimeout(() => {
                    timer = null;
                    fn.apply(that, arg);
                }, delay)
            }
        }
    },
    //设置手机适配
    setBodyFontSize() {
        $(function () {
            var ww = $(window).width();
            console.log(ww);
            if (ww <= 768) {
                console.log('这是wap端~');
                window['adaptive'].desinWidth = 750;//设计稿宽度
                window['adaptive'].baseFont = 28;//默认字体大小
                window['adaptive'].maxWidth = 750;//最大宽度
                window['adaptive'].init();
            } else {
                console.log('这是pc端~');
                window['adaptive'].desinWidth = 1340;//设计稿宽度
                window['adaptive'].baseFont = 14;//默认字体大小
                window['adaptive'].maxWidth = 1340;//最大宽度
                window['adaptive'].init();
            }
        });

        $(window).resize(function () {          //当浏览器大小变化时
            // console.log($(window).width());          //浏览器时下窗口可视区域高度
            // console.log($(document).width());        //浏览器时下窗口文档的高度
            // console.log($(document.body).width());   //浏览器时下窗口文档body的高度
            // console.log($(document.body).outerWidth(true)); //浏览器时下窗口文档body的总高度 包括border padding margin
            var ww = $(window).width();
            if (ww <= 768) {
                console.log('变成wap端啦~');
                window['adaptive'].desinWidth = 750;//设计稿宽度
                window['adaptive'].baseFont = 28;//默认字体大小
                window['adaptive'].maxWidth = 750;//最大宽度
                window['adaptive'].init();
            } else {
                console.log('变成pc端啦~');
                window['adaptive'].desinWidth = 1340;//设计稿宽度
                window['adaptive'].baseFont = 14;//默认字体大小
                window['adaptive'].maxWidth = 1340;//最大宽度
                window['adaptive'].init();
            }
        });
    },
    // 日期格式转：年-月-日
    getDate(date) {
        var now = date
        var year = now.getFullYear() // 得到年份
        var month = now.getMonth() // 得到月份
        var date = now.getDate() // 得到日期
        month = month + 1
        month = month.toString().padStart(2, '0')
        date = date.toString().padStart(2, '0')
        var defaultDate = `${year}-${month}-${date}`
        return defaultDate
    },
    //小于10的拼接上0字符串
    addZero(s) {
        return s < 10 ? "0" + s : s;
    },
    getNowTime() {
        return new Promise((resolve, reject) => {
            var date = new Date();
            //年 getFullYear(): 四位数字返回年份
            var year = date.getFullYear(); //getFullYear()代替getYear()
            //月 getMonth():  ~11
            var month = date.getMonth() + 1;
            //日 getDate():(1 ~ 31)
            var day = date.getDate();
            //时 getHours(): ( ~ 23)
            var hour = date.getHours();
            //分 getMinutes(): (0 ~ 59)
            var minute = date.getMinutes();
            //秒 getSeconds(): ( ~ 59)
            var second = date.getSeconds();
            var time =
                year +
                "-" +
                this.addZero(month) +
                "-" +
                this.addZero(day) +
                " " +
                this.addZero(hour) +
                ":" +
                this.addZero(minute) +
                ":" +
                this.addZero(second);
            resolve(time)
        })

    },
}