<!--
 * @Author: Mr.Cheng
 * @Date: 2023-10-10 16:02:38
 * @LastEditTime: 2023-10-25 19:44:10
 * @FilePath: \octopus-factory-map\src\new\taskPool\components\task.vue
-->
<template>
  <div>
    <!-- 任务/步骤列表盒子 -->
    <div class="list-box">
      <!-- 列表 -->
      <div v-for="item of list" :key="item.id" class="list">
        <!-- 任务名&状态&接单按钮 -->
        <div style="align-items: flex-start" class="task-list-top flex-c-b">
          <div class="task-name">{{ item.title }}</div>
          <div class="flex-c">
            <div>
              <!-- 状态 -->
              <div
                :style="{ color: statusColor(item.status) }"
                class="status_text"
              >
                {{ item.status_text }}
              </div>
              <!-- 待操作人 -->
              <template>
                <div @click="navTo(item.task_id)" class="blue underline btn">
                  {{ item.verify_text }}
                </div>
                <div style="color: #999; font-size: 14px">
                  <div
                    style="color: #999; font-size: 14px"
                    v-for="(person, index) of item.review_person"
                    :key="index"
                  >
                    ({{ person }})
                    <span
                      v-if="
                        index !=
                        (item.review_person && item.review_person.length - 1)
                      "
                      >,</span
                    >
                  </div>
                </div>
              </template>
            </div>
            <div
              v-if="
                (section_id == item.section_id ||
                identity == 4 ||
                item.catch_modality == 1)&&item.status==1
              "
              @click="receivingOrders(item.id)"
              class="receivingOrders ml10"
            >
              接单
            </div>
          </div>
        </div>

        <!-- 执行人&工作量&开始时间&完成时间 -->
        <template>
          <div style="align-items: flex-start" class="flex-c mt20">
            <div class="flex-c" style="width: 50%">
              <div class="label">执行人：</div>
              <div class="value">
                <div v-if="item.catch_modality == 1">公共接单</div>
                <div v-else-if="item.catch_modality == 2">
                  {{ item.section_name }}
                </div>
                <div v-else-if="item.catch_modality == 3">
                  {{ item.user_nickname }}
                </div>
              </div>
            </div>
            <div class="flex-c" style="width: 50%">
              <div class="label">工作量：</div>
              <div class="value flex-c">
                <div style="white-space: nowrap">
                  {{ item.workload || "-" }}
                </div>
              </div>
            </div>
          </div>
          <div style="align-items: flex-start" class="flex-c mt20 mb20">
            <div class="flex-c" style="width: 50%; align-items: flex-start">
              <div class="label">开始时间：</div>
              <div class="value">
                <template>
                  <div v-if="[3, 4, 9].includes(item.status)">
                    <span>{{ item.starttime_text || "--" }}</span>
                    <span style="white-space: nowrap">(预)</span>
                  </div>
                  <div v-else>
                    {{ item.real_starttime_text || "--" }}
                  </div>
                </template>
              </div>
            </div>
            <div class="flex-c" style="width: 50%; align-items: flex-start">
              <div class="label">完成时间：</div>
              <div class="value flex-c">
                <template>
                  <div v-if="![6, 7, 8].includes(item.status)">
                    <span>
                      {{ item.endtime_text || "--" }}
                    </span>
                    <span
                      v-if="!item.real_endtime_text && item.endtime_text"
                      style="white-space: nowrap"
                      >(预){{ item.real_endtime_text }}</span
                    >
                  </div>
                  <div v-else>
                    {{ item.real_endtime_text }}

                    <span
                      v-if="item.timeout != 0"
                      :style="{
                        color: statusTextColor(item.timeout > 0 ? 2 : 1),
                        whiteSpace: 'nowrap',
                      }"
                      >({{ item.timeout_text }})</span
                    >
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import * as API from "@/api.ts";
export default {
  props: ["list", "status"],
  name: "task",
  data() {
    return {
      identity: "", //身份：1员工，2主管，3人事财务，4总经理
      userId: "", //本人id
      section_id: "", //部门id
      height: "0", //步骤盒子高度
    };
  },
  computed: {
    // 任务状态文字颜色
    statusColor() {
      return (e) => {
        let color;
        switch (e) {
          case 0:
            break;
          case 1:
            color = "#D71518";
            break;
          case 2:
            break;
          case 3:
            break;
          case 4:
            color = "#D71518";
            break;
          case 5:
            color = "#00A2FF";
            break;
          case 6:
            color = "#FF4200";
            break;
          case 7:
            color = "#666666";
            break;
          case 8:
            color = "#FF4200";
            break;

          default:
            break;
        }
        return color;
      };
    },
    // 提|超文字颜色
    statusTextColor() {
      return (type) => {
        return type == 1 ? "green" : "#d71518";
      };
    },
    // 是否显示开始按钮
    isStart() {
      return (process) => {
        let arr = [];
        process.forEach((item) => {
          if (!arr.includes(item.status)) {
            arr.push(item.status);
          }
        });
        let val = process.some((item) => {
          return item.status == 2;
        });
        return val;
      };
    },
  },
  mounted() {
    //获取用户信息
    this.getUserInfo();
  },
  methods: {
    //获取用户信息
    async getUserInfo() {
      let { code, data } = await API.userInfo();
      if (code == 1) {
        this.identity = data.identity;
        this.userId = data.id;
        this.section_id = data.section_id;
      }
    },
    // 接单
    receivingOrders(ids) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确认接单吗？",
        })
        .then(async () => {
          let params = {
            ids,
          };
          let { code } = await API.OrderTaking(params);
          if (code == 1) {
            this.$toast("接单成功");
            setTimeout(() => {
              this.$router.go(-1);
            }, 500);
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    // 展开&收起
    changeHeight() {
      this.height = this.height == "auto" ? 0 : "auto";
    },
    //步骤操作
    stepBtn(id, type, fType = 1) {
      let request;
      let prompt = false;
      switch (type) {
        //开始
        case 1:
          request = API.pauseStart;
          break;
        //暂停
        case 2:
          request = API.Processpause;
          prompt = true;
          break;
        //取消
        case 3:
          request = API.pauseCancel;
          break;

        default:
          break;
      }

      if (prompt) {
        this.$utils.getNowTime().then((date) => {
          this.$emit("showStopFrom", { date, id, fType });
        });
      } else {
        // fType 1:步骤暂停，2：任务暂停
        request({ id, type: fType }).then((res) => {
          if (res.code == 1) {
            this.$toast(res.msg);
            this.$emit("getDetail");
          }
        });
      }
    },
    // 任务操作
    startTask(id, manner, status) {
      let params = {
        ids: id,
        manner,
        status,
      };
      API.ProcessEdit(params).then((res) => {
        if (res.code == 1) {
          this.$toast.success(res.msg || "成功");
          this.$emit("getDetail");
        }
      });
    },
    //关闭任务
    close_task(id, status) {
      this.$emit("closeTask", id, status);
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 0.24rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
  white-space: nowrap;
}
.value {
  font-size: 0.24rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333;
}
// 步骤（更多logo）
.more {
  width: 0.16rem;
  transform: rotate(90deg);
  margin-left: 0.2rem;
}
// 步骤|任务按钮
.btnStyRed {
  background: rgba(215, 21, 24, 0);
  border: 0.01rem solid #d71518;
  border-radius: 0.04rem;
  font-size: 0.26rem;
  font-family: PingFang SC;
  color: #d71518;
  padding: 0.1rem 0.25rem;
  box-sizing: border-box;
  white-space: nowrap;
}
.btnStyGray {
  background: rgba(215, 21, 24, 0);
  border: 0.01rem solid #999;
  border-radius: 0.04rem;
  font-size: 0.26rem;
  font-family: PingFang SC;
  color: #999;
  padding: 0.1rem 0.25rem;
  box-sizing: border-box;
  white-space: nowrap;
}
.btnStyGreen {
  background: rgba(215, 21, 24, 0);
  border: 0.01rem solid #00ba0c;
  border-radius: 0.04rem;
  font-size: 0.26rem;
  font-family: PingFang SC;
  color: #00ba0c;
  padding: 0.1rem 0.25rem;
  box-sizing: border-box;
  white-space: nowrap;
}
// 任务/步骤列表盒子
.list-box {
  padding: 0.2rem;
  box-sizing: border-box;
  //列表
  .list {
    padding: 0.3rem 0.3rem 0 0.3rem;
    // box-sizing: border-box;
    width: 100%;
    background: #ffffff;
    border-radius: 0.16rem;
    margin-bottom: 0.2rem;
    overflow: hidden;
    margin-bottom: 0.3rem;

    // 任务列表顶部
    .task-list-top {
      border-bottom: 0.01rem solid #eeeeee;
      padding-bottom: 0.2rem;
      box-sizing: border-box;
      // 任务名称
      .task-name {
        font-size: 0.28rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #111111;
      }
      // 任务状态
      .status_text {
        font-size: 0.26rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #d71518;
      }
      //接单按钮
      .receivingOrders {
        background: #d71518;
        border-radius: 0.04rem;
        padding: 0.12rem 0.35rem;
        box-sizing: border-box;
        white-space: nowrap;
        font-size: 0.26rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
    }
    //步骤列表盒子
    .step-list-box {
      border-top: 0.01rem solid #eeeeee;
      margin-top: 0.3rem;

      //红点
      .round {
        width: 0.16rem;
        height: 0.16rem;
        background: #d71518;
        border-radius: 50%;
        margin-right: 0.2rem;
      }
      //第几步
      .index {
        font-size: 0.24rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #666666;
      }

      //   步骤内容
      .setp-content {
        width: 100%;
        padding: 0.28rem 0.2rem;
        box-sizing: border-box;
        background: #fafafa;
        border: 0.01rem solid #dddddd;
        border-radius: 0.08rem;
        // 步骤名称
        .step-name {
          font-size: 0.26rem;
          font-family: PingFang SC;
          font-weight: bold;
          color: #111111;
        }
        // 步骤状态
        .step-status {
          font-size: 0.26rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
        }
      }

      .setp-box {
        background: #fff;
        height: 1rem;
        position: relative;
        z-index: 300;
      }
    }
  }
}
</style>