<!--
 * @Author: Mr.Cheng
 * @Date: 2023-10-23 11:40:09
 * @LastEditTime: 2023-10-31 11:05:20
 * @FilePath: \octopus-factory-map\src\new\project\components\task.vue
-->
<template>
  <div class="content">
    <!-- 任务列表盒子 -->
    <div class="list-box">
      <!-- 列表 -->
      <template v-if="list.length > 0">
        <div v-for="(item, i) of list" :key="item.id" class="list">
          <!-- 任务名&状态&接单按钮 -->
          <div style="align-items: flex-start" class="task-list-top flex-c-b">
            <div class="task-name">{{ item.title }}</div>
            <div class="flex-c">
              <div>
                <!-- 状态 -->
                <div
                  :style="{ color: statusColor(item.status) }"
                  class="status_text"
                >
                  {{ item.status_text }}
                </div>
              </div>
            </div>
          </div>

          <!-- 执行人&工作量&开始时间&完成时间 -->
          <template>
            <div style="align-items: flex-start" class="flex-c mt20">
              <div class="flex-c" style="width: 50%">
                <div class="label">执行人：</div>
                <div class="value">
                  <div v-if="item.catch_modality == 1">公共接单</div>
                  <div v-else-if="item.catch_modality == 2">
                    {{ item.section_name }}
                  </div>
                  <div v-else-if="item.catch_modality == 3">
                    {{ item.user_nickname }}
                  </div>
                </div>
              </div>
              <div class="flex-c" style="width: 50%">
                <div class="label">工作量：</div>
                <div class="value flex-c">
                  <div style="white-space: nowrap">
                    {{ item.workload || "-" }}
                  </div>
                </div>
              </div>
            </div>
            <div style="align-items: flex-start" class="flex-c mt20 mb20">
              <div class="flex-c" style="width: 50%; align-items: flex-start">
                <div class="label">开始时间：</div>
                <div class="value">
                  <template>
                    <div v-if="[3, 4].includes(item.status)">
                      <span>{{ item.starttime_text || "--" }}</span>
                      <span style="white-space: nowrap">(预)</span>
                    </div>
                    <div v-else>
                      {{ item.real_starttime_text || "--" }}
                    </div>
                  </template>
                </div>
              </div>
              <div class="flex-c" style="width: 50%; align-items: flex-start">
                <div class="label">完成时间：</div>
                <div class="value flex-c">
                  <template>
                    <div v-if="![6, 7, 8].includes(item.status)">
                      <span>
                        {{ item.endtime_text || "--" }}
                      </span>
                      <span
                        v-if="!item.real_endtime_text && item.endtime_text"
                        style="white-space: nowrap"
                        >(预){{ item.real_endtime_text }}</span
                      >
                    </div>
                    <div v-else>
                      {{ item.real_endtime_text }}

                      <span
                        v-if="item.timeout != 0"
                        :style="{
                          color: statusTextColor(item.timeout > 0 ? 2 : 1),
                          whiteSpace: 'nowrap',
                        }"
                        >({{ item.timeout_text }})</span
                      >
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </template>

          <!-- 步骤 -->
          <div class="step-list-box" v-if="item.progress.length > 0">
            <div :style="{ height: item.isOpen ? 'auto' : 0 }">
              <div v-for="(step, index) of item.progress" :key="index">
                <!-- 步骤顶部 -->
                <div class="flex-c mt10 mb10">
                  <div class="round"></div>
                  <div class="index">第{{ ++index }}步</div>
                </div>

                <!-- 步骤内容 -->
                <div class="setp-content">
                  <!-- 步骤名称&步骤状态 -->
                  <div class="flex-c-b">
                    <div class="step-name">{{ step.title }}</div>
                    <div class="flex-c">
                      <div
                        :style="{ color: statusColor(item.status) }"
                        class="step-status"
                      >
                        {{ step.status_text }}
                      </div>
                    </div>
                  </div>
                  <!-- 开始时间&完成时间 -->
                  <div style="align-items: flex-start" class="flex-c mt10">
                    <div
                      style="width: 50%; align-items: flex-start"
                      class="flex-c"
                    >
                      <div class="label">开始时间：</div>
                      <div class="value">
                        <div v-if="step.status == 1">
                          <span>{{ step.starttime_text || "--" }}</span>
                          <span style="white-space: nowrap">(预)</span>
                        </div>
                        <div v-else>
                          {{ step.real_starttime_text || "--" }}
                        </div>
                      </div>
                    </div>
                    <div
                      style="width: 50%; align-items: flex-start"
                      class="flex-c"
                    >
                      <div class="label">完成时间：</div>
                      <div class="value">
                        <div v-if="step.status == 1">
                          <span>
                            {{ step.endtime_text || "--" }}
                          </span>
                          <span style="white-space: nowrap">(预)</span>
                        </div>
                        <div v-else>
                          {{ step.real_endtime_text || step.endtime_text }}
                          <span
                            v-if="
                              step.status < 3 ||
                              step.status == 4 ||
                              step.status == 5
                            "
                            style="white-space: nowrap"
                            >(预)</span
                          >
                          <span
                            v-else-if="step.status == 3 && step.type != 3"
                            :style="{
                              color: statusTextColor(step.type),
                              whiteSpace: 'nowrap',
                            }"
                            >({{ step.ago }})</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 工作量 -->
                  <div
                    style="width: 50%; align-items: flex-start"
                    class="flex-c mt10"
                  >
                    <div class="label">工作量：</div>
                    <div class="value flex-c">
                      <div style="white-space: nowrap">
                        {{ step.real_workload || step.workload || "-" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div @click="changeHeight(i)" class="setp-box flex-c-c">
              <div class="blue">
                {{ item.isOpen ? "收起步骤" : "展开步骤" }}
              </div>
              <img
                :style="item.isOpen ? 'transform: rotate(270deg);' : ''"
                class="more"
                src="@/assets/bulearrow.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </template>
      <van-empty v-else class="custom-image" description="暂无任务">
        <template #image>
          <img class="null-img" src="@/assets/nulldata.png" alt="" />
        </template>
      </van-empty>
    </div>
  </div>
</template>

<script>
import * as API from "@/api.ts";

export default {
  props: ["list", "total"],
  data() {
    return {};
  },
  computed: {
    // 任务状态文字颜色
    statusColor() {
      return (e) => {
        let color;
        switch (e) {
          case 0:
            break;
          case 1:
            color = "#D71518";
            break;
          case 2:
            break;
          case 3:
            break;
          case 4:
            color = "#D71518";
            break;
          case 5:
            color = "#00A2FF";
            break;
          case 6:
            color = "#FF4200";
            break;
          case 7:
            color = "#666666";
            break;
          case 8:
            color = "#FF4200";
            break;

          default:
            break;
        }
        return color;
      };
    },
    // 提|超文字颜色
    statusTextColor() {
      return (type) => {
        return type == 1 ? "green" : "#d71518";
      };
    },
  },
  mounted() {
    console.log(this.list);
  },
  methods: {
    // 展开&收起
    changeHeight(index) {
      if (this.list[index].isOpen) {
        this.list.forEach((item) => {
          item.isOpen = false;
        });
      } else {
        this.list[index].isOpen = true;
      }
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 0.24rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
  white-space: nowrap;
}
.value {
  font-size: 0.24rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333;
}
// 步骤（更多logo）
.more {
  width: 0.16rem;
  transform: rotate(90deg);
  margin-left: 0.2rem;
}
// 步骤|任务按钮
.btnStyRed {
  background: rgba(215, 21, 24, 0);
  border: 0.01rem solid #d71518;
  border-radius: 0.04rem;
  font-size: 0.26rem;
  font-family: PingFang SC;
  color: #d71518;
  padding: 0.1rem 0.25rem;
  box-sizing: border-box;
  white-space: nowrap;
}
.btnStyGray {
  background: rgba(215, 21, 24, 0);
  border: 0.01rem solid #999;
  border-radius: 0.04rem;
  font-size: 0.26rem;
  font-family: PingFang SC;
  color: #999;
  padding: 0.1rem 0.25rem;
  box-sizing: border-box;
  white-space: nowrap;
}
.btnStyGreen {
  background: rgba(215, 21, 24, 0);
  border: 0.01rem solid #00ba0c;
  border-radius: 0.04rem;
  font-size: 0.26rem;
  font-family: PingFang SC;
  color: #00ba0c;
  padding: 0.1rem 0.25rem;
  box-sizing: border-box;
  white-space: nowrap;
}
.content {
  padding: 0.2rem;
  box-sizing: border-box;
  // 任务/步骤列表盒子
  .list-box {
    //列表
    .list {
      padding: 0.3rem 0.3rem 0 0.3rem;
      // box-sizing: border-box;
      width: 100%;
      background: #ffffff;
      border-radius: 0.16rem;
      margin-bottom: 0.2rem;
      overflow: hidden;
      margin-bottom: 0.3rem;

      // 任务列表顶部
      .task-list-top {
        border-bottom: 0.01rem solid #eeeeee;
        padding-bottom: 0.2rem;
        box-sizing: border-box;
        // 任务名称
        .task-name {
          font-size: 0.28rem;
          font-family: PingFang SC;
          font-weight: bold;
          color: #111111;
        }
        // 任务状态
        .status_text {
          font-size: 0.26rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #d71518;
        }
        //接单按钮
        .receivingOrders {
          background: #d71518;
          border-radius: 0.04rem;
          padding: 0.12rem 0.35rem;
          box-sizing: border-box;
          white-space: nowrap;
          font-size: 0.26rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }
      }
      //步骤列表盒子
      .step-list-box {
        border-top: 0.01rem solid #eeeeee;
        margin-top: 0.3rem;

        //红点
        .round {
          width: 0.16rem;
          height: 0.16rem;
          background: #d71518;
          border-radius: 50%;
          margin-right: 0.2rem;
        }
        //第几步
        .index {
          font-size: 0.24rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
        }

        //   步骤内容
        .setp-content {
          width: 100%;
          padding: 0.28rem 0.2rem;
          box-sizing: border-box;
          background: #fafafa;
          border: 0.01rem solid #dddddd;
          border-radius: 0.08rem;
          // 步骤名称
          .step-name {
            font-size: 0.26rem;
            font-family: PingFang SC;
            font-weight: bold;
            color: #111111;
          }
          // 步骤状态
          .step-status {
            font-size: 0.26rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
          }
        }

        .setp-box {
          background: #fff;
          height: 1rem;
          position: relative;
          z-index: 300;
        }
      }
    }
  }
}
</style>