<template>
  <div
    class="lancering"
    :style="
      scrollTop == undefined || scrollHeight > scrollTop
        ? 'height:100vh;padding-bottom:0;'
        : 'padding-bottom:3vh;'
    "
  >
    <div class="langcering_content">
      <div class="langcering_content_box">
        <div class="langcering_content_header">
          <publicNavigation
            navigationColor="white"
            @navigationClick="navigationClick"
            :returnNumer="1"
            :data="navigation"
          />
        </div>
        <div class="select_project">
          <div class="select_project_content">
            <div class="select_project_content_box">
              <div class="select_project_card_white">
                <div class="select_name_select_text_select_arrow">
                  <div class="select_left_content_box">
                    <div class="select_name">
                      <span>{{
                        personUser == 8 ? "任务标题" : "项目标题"
                      }}</span>
                    </div>
                    <div class="select_input" style="flex: 1">
                      <input
                        type="text"
                        @blur="blurChange"
                        v-model="title"
                        :placeholder="`请输入${
                          personUser == 8 ? '任务标题' : '项目标题'
                        }`"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="select_name_select_text_select_arrow"
                  @click="selectTask"
                >
                  <div class="select_left_content_box">
                    <div class="select_name">
                      <span>{{
                        personUser == 8 ? "选择项目" : "项目类型"
                      }}</span>
                    </div>
                    <div class="select_input">
                      <input
                        readonly
                        type="text"
                        v-model="tilskrivning"
                        :placeholder="
                          personUser == 8
                            ? '请选择任务归属项目'
                            : '请选择任务项目类型'
                        "
                      />
                    </div>
                  </div>
                  <div class="select_arrow">
                    <img src="@/assets/write.png" alt="" />
                  </div>
                </div>
                <div
                  class="select_name_select_text_select_arrow"
                  v-if="personUser == 8"
                  @click="taskTypesFunction"
                >
                  <div class="select_left_content_box">
                    <div class="select_name">
                      <span>任务类型</span>
                    </div>
                    <div class="select_input">
                      <input
                        readonly
                        type="text"
                        v-model="total.taskTypes"
                        placeholder="请选择任务类型"
                      />
                    </div>
                  </div>
                  <div class="select_arrow">
                    <img src="@/assets/write.png" alt="" />
                  </div>
                </div>
                <div
                  class="select_name_select_text_select_arrow"
                  v-if="personUser == 8"
                  @click="vanskelighedSelect"
                >
                  <div class="select_left_content_box">
                    <div class="select_name">
                      <span>任务难度</span>
                    </div>
                    <div
                      class="select_input custom_start"
                      :style="total.selectInputs ? '' : ''"
                    >
                      <div v-if="total.selectInputs" class="image_start">
                        <img
                          :src="require('@/assets/start.png')"
                          v-for="(s, k) in total.selectInputs"
                          :key="k"
                          alt=""
                        />
                      </div>
                      <input
                        v-if="!total.selectInputs"
                        readonly
                        type="text"
                        placeholder="请选择任务难度"
                      />
                    </div>
                  </div>
                  <div class="select_arrow">
                    <img src="@/assets/write.png" alt="" />
                  </div>
                </div>
                <div
                  class="select_name_select_text_select_arrow"
                  v-if="personUser == 8"
                  @click="rewardPoints"
                >
                  <div class="select_left_content_box">
                    <div class="select_name">
                      <span>任务奖励点</span>
                    </div>
                    <div class="select_input">
                      <input
                        readonly
                        type="text"
                        v-model="total.bonuspoint"
                        placeholder="请设置奖励点"
                      />
                    </div>
                  </div>
                  <div class="select_arrow">
                    <img src="@/assets/write.png" alt="" />
                  </div>
                </div>
                <div
                  class="select_name_select_text_select_arrow"
                  v-if="personUser == 8"
                  @click="overdragetPerson"
                >
                  <div class="select_left_content_box">
                    <div class="select_name">
                      <span>指派给</span>
                    </div>
                    <div class="select_input">
                      <input
                        readonly
                        type="text"
                        v-model="total.tildele"
                        placeholder="请选择要指派的人"
                      />
                    </div>
                  </div>
                  <div class="select_arrow">
                    <img src="@/assets/write.png" alt="" />
                  </div>
                </div>
                <div
                  class="select_name_select_text_select_arrow"
                  v-if="personUser == 8"
                  @click="acceptorPerson"
                >
                  <div class="select_left_content_box" style="width: 100%">
                    <div class="select_name">
                      <span>验收人</span>
                    </div>
                    <div class="select_input" style="margin-left: 14%">
                      <input
                        v-if="total.Acceptor.length <= 0"
                        readonly
                        type="text"
                        v-model="total.Acceptor"
                        placeholder="请选择验收任务的人"
                      />
                      <div
                        class="array_nickname"
                        style="width: 100%"
                        v-if="total.Acceptor.length > 0"
                      >
                        <span v-for="(item, k) in total.Acceptor" :key="k">
                          {{ item.check == true ? item.nickname : "" }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="select_arrow">
                    <img src="@/assets/write.png" alt="" />
                  </div>
                </div>
                <div
                  @click.stop=""
                  style="border-bottom: 1px solid #efefef; padding-bottom: 20px"
                  class="uploading_file_style"
                >
                  <div
                    class="select_name_select_text_select_arrow"
                    style="border: none; padding-bottom: 0"
                  >
                    <div class="select_left_content_box">
                      <div class="fileuploading_box">
                        <div class="select_name">
                          <span>{{
                            personUser == 8 ? "附件" : "项目附件"
                          }}</span>
                        </div>
                        <div
                          class="select_input"
                          :style="
                            personUser == 8 ? '' : 'margin-left:28%!important;'
                          "
                        >
                          <van-uploader
                            :before-read="beforeRead"
                            accept="file"
                            result-type="file"
                            :after-read="uploadingEvent"
                          >
                            <van-button
                              class="uploading_position"
                              type="primary"
                            >
                            </van-button>
                          </van-uploader>
                          <div
                            class="uploading_text"
                            v-show="filkeList.length <= 0"
                          >
                            <span>请添加附件</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="select_arrow addbuttonsimage">
                      <img src="@/assets/addbuttons.png" alt="" />
                    </div>
                  </div>
                  <div class="text_list_box" v-show="filkeList.length > 0">
                    <div
                      class="list_text_fors"
                      v-for="(s, k) in filkeList"
                      :key="k"
                    >
                      <div class="loading" v-if="s.message == '上传中'">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <span>{{ s.url }}</span>
                      <img
                        :src="require('@/assets/redoffsetIocn.png')"
                        @click="deleteClickEvent(s, k)"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div
                  style="border-bottom: 1px solid #efefef; padding-bottom: 20px"
                  class="uploading_file_style"
                >
                  <div
                    class="select_name_select_text_select_arrow"
                    style="border: none; padding-bottom: 0"
                  >
                    <div class="select_left_content_box">
                      <div class="fileuploading_box">
                        <div class="select_name">
                          <span :style="personUser == 8 ? '' : ''">{{
                            personUser == 8 ? "图片" : "项目图片"
                          }}</span>
                        </div>
                        <div
                          class="select_input"
                          :style="
                            personUser == 8 ? '' : 'margin-left:28%!important;'
                          "
                        >
                          <van-uploader
                            :before-read="beforeRead"
                            result-type="file"
                            :after-read="uploadingImageEevent"
                          >
                            <van-button
                              class="uploading_position"
                              type="primary"
                            >
                            </van-button>
                          </van-uploader>
                          <div
                            class="uploading_text"
                            v-show="uploadingImage.length <= 0"
                          >
                            <span>请添加图片</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="select_arrow addbuttonsimage">
                      <img src="@/assets/addbuttons.png" alt="" />
                    </div>
                  </div>
                  <div class="text_list_box" v-show="uploadingImage.length > 0">
                    <div
                      class="list_text_fors"
                      v-for="(s, k) in uploadingImage"
                      :key="k"
                    >
                      <div class="loading" v-if="s.message == '上传中'">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <span>{{ s.url }}</span>
                      <img
                        :src="require('@/assets/redoffsetIocn.png')"
                        @click="deleteClickEvents(s, k)"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="select_name_select_text_select_arrow mb30">
                  <div class="select_left_content_box" style="display: block">
                    <div class="select_name">
                      <span>简介</span>
                    </div>
                    <div class="select_input" style="width: 100%">
                      <!-- total.description -->
                      <textarea
                        style="width: 100%"
                        @blur="descriptionsFunction"
                        v-model="descriptions"
                        type="text"
                        placeholder="请输入简介"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-popup
      v-model="projectShows"
      round
      position="bottom"
      :style="{ height: '40%' }"
    >
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
    <van-popup
      v-model="taskTypesShow"
      round
      position="bottom"
      :style="{ height: '40%' }"
    >
      <van-picker
        show-toolbar
        :columns="columns1"
        @confirm="onConfirm1"
        @cancel="onCancel1"
      />
    </van-popup>
    <div class="submit_task">
      <div class="submit_task_box">
        <!--  -->
        <button
          @click="submitEvent"
          :disabled="showButton ? false : true"
          :style="showButton ? 'background:#D71518;color:white;' : ''"
        >
          提交
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import publicNavigation from "@/publicNavigation/publicNavigation";
import * as API from "@/api.ts";
import axios from "axios";
export default {
  components: {
    publicNavigation,
  },
  data() {
    return {
      navigation: {},
      total: {
        tilskrivning: "",
        vanskelighed: "",
        bonuspoint: "",
        tildele: "",
        Acceptor: "",
        fileList: [],
        description: "",
        title: "",
        projectId: "",
        additional_number: "",
        tildeleId: "",
        rewardTextFunction: "",
        pseronID: "",
        selectInputs: "",
        AcceptorID: "",
        taskTypes: "",
        typeValueId: "",
        acceptPersonID: "",
      },
      filkeList: [],
      uploadingImage: [],
      uploadingImageShow: false,
      personUser: "",
      fileListData: [],
      uploadingImagesList: [],
      projectShows: false,
      columns: [],
      columns1: [],
      PreliminaryData: [],
      PreliminaryID: "",
      scrollHeight: "",
      scrollTop: "",
      taskTypesShow: false,
      taskTypeValue: [],
      typeValueId: "",
      showButton: false,
      // 监听
      title: "",
      tilskrivning: "",
      // 动画加载
      filkeListShow: false,
      // 文字保存
      descriptions: "",
    };
  },
  watch: {
    title() {
      if (
        this.title &&
        this.tilskrivning &&
        this.total.taskTypes &&
        this.total.selectInputs &&
        this.total.bonuspoint &&
        this.total.tildele &&
        this.total.Acceptor
      ) {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
    },
  },
  mounted() {
    const data = window.localStorage.getItem("sure");
    const user = window.sessionStorage.getItem("userInfo");
    this.scrollHeight = window.screen.height;
    window.addEventListener("scroll", this.scrollFunction);
    let newObject, newUser;
    if (!!data && data != "undefined") {
      newObject = JSON.parse(data);
    }
    if (!!user && user != "undefined") {
      newUser = JSON.parse(user);
    }

    const routername = this.$route.query.e;
    this.personUser = routername == "创建任务" ? 8 : newUser;
    console.log(this.personUser);
    // {
    // 	identity: 4
    // }
    // newUser
    console.log(newUser);
    this.navigation = {
      whiteImage: require("@/assets/whiteleft.png"),
      name: routername != "创建任务" ? "发布项目" : "发布任务",
    };
    if (newObject) {
      console.log(newObject, "newObject");
      this.tilskrivning = newObject.project;
      this.title = newObject.title;
      if (this.tilskrivning && this.title) {
        this.showButton = true;
      }
      this.total = {
        tilskrivning: newObject.project
          ? newObject.project
          : newObject.value
          ? newObject.value
          : newObject.project,
        vanskelighed: newObject.start,
        selectInputs: newObject.selectInputs,
        bonuspoint: newObject.rewardText
          ? newObject.rewardText.value !== "3"
            ? newObject.rewardText.title
            : newObject.additional_number + "奖励点"
          : "",
        rewardTextFunction: newObject?.stolpe ? newObject.stolpe : "",
        Acceptor:
          newObject.acceptPerson?.length > 0
            ? newObject.acceptPerson
              ? newObject.acceptPerson
              : ""
            : "",
        // 验收人ID集合
        AcceptorID: newObject.acceptPerson
          ? newObject.acceptPerson.map((I) => {
              return I.id;
            })
            ? newObject.acceptPerson.map((I) => {
                return I.id;
              })
            : ""
          : "",
        projectId: newObject.projectId,
        additional_number: newObject.additional_number,
        // 接单人名称
        tildele: newObject.personName
          ? newObject.personName?.name
          : newObject.publicStolpe?.title
          ? newObject.publicStolpe?.title
          : newObject.publicStolpe == 1
          ? "公共接单"
          : "",
        // 接单人id
        tildeleId: newObject.publicStolpe
          ? newObject.publicStolpe?.id
          : newObject.projectId,
        // 用户ID
        pseronID: JSON.parse(window.sessionStorage.getItem("userInfo")).id,
        // 验收人ID集合
        acceptPersonID: newObject.acceptPerson
          ? newObject.acceptPerson.map((I) => {
              if (I.check == true) {
                return I.id;
              }
            })
            ? newObject.acceptPerson.map((I) => {
                if (I.check == true) {
                  return I.id;
                }
              })
            : ""
          : "",
        title: newObject.title ? newObject.title : "",
        taskTypes: newObject.taskTypes ? newObject.taskTypes : "",
        typeValueId: newObject.typeValueId,
        id: newObject.personName && newObject.personName.id,
        description: this.descriptions
          ? this.descriptions
          : newObject.description,
      };
      this.descriptions = newObject.description;

      // 提交路径
      this.fileListData = newObject.ListData ? newObject.ListData : [];
      this.uploadingImagesList = newObject.ImageList ? newObject.ImageList : [];
      // 显示路径
      this.uploadingImage = newObject.uploadingImagesList
        ? newObject.uploadingImagesList
        : [];
      this.filkeList = newObject.fileListData ? newObject.fileListData : [];
    }
    this.typeList();
    if (
      this.title &&
      this.tilskrivning &&
      this.total.taskTypes &&
      this.total.selectInputs &&
      this.total.bonuspoint &&
      this.total.tildele &&
      this.total.Acceptor
    ) {
      this.showButton = true;
    } else {
      this.showButton = false;
    }
    // this.taskFunctionRequset();
  },
  // destroyed() {
  // 	window.localStorage.setItem('sure','')
  // },

  methods: {
    descriptionsFunction() {
      console.log(this.descriptions);
      this.total.description = this.descriptions;
      if (window.localStorage.getItem("sure")) {
        let data = Object.assign(
          JSON.parse(window.localStorage.getItem("sure")),
          {
            description: this.descriptions,
          }
        );
        window.localStorage.setItem("sure", JSON.stringify(data));
      } else {
        window.localStorage.setItem(
          "sure",
          JSON.stringify({
            description: this.descriptions,
          })
        );
      }
    },
    scrollFunction(e) {
      this.scrollTop = e.timeStamp;
    },
    selectTask() {
      if (this.personUser != 8) {
        this.projectShowsFunction();
      } else {
        const parms = {
          name: "publishProject",
          path: "/publishProject",
          query: {
            e: this.personUser,
          },
        };
        this.pathRquest(parms);
      }
    },
    // taskFunctionRequset() {},
    projectShowsFunction() {
      this.projectShows = !this.projectShows;
    },
    taskTypesFunction() {
      this.taskTypesShow = !this.taskTypesShow;
    },
    onConfirm(value, index) {
      const data = this.total.title;
      window.localStorage.setItem(
        "sure",
        JSON.stringify({
          title: data,
          value: value,
        })
      );
      if (this.title && value) {
        this.showButton = true;
      }
      this.PreliminaryData[index].name == value
        ? ((this.PreliminaryID = this.PreliminaryData[index].id),
          (this.total.tilskrivning = value),
          (this.tilskrivning = value))
        : "";
      this.projectShowsFunction();
    },
    blurChange(e) {
      if (window.localStorage.getItem("sure")) {
        const objects = Object.assign(
          JSON.parse(window.localStorage.getItem("sure")),
          {
            title: e.target._value,
          }
        );
        window.localStorage.setItem("sure", JSON.stringify(objects));
        console.log(2222);
      } else {
        window.localStorage.setItem(
          "sure",
          JSON.stringify({
            title: e.target._value,
          })
        );
        console.log(33333);
      }
    },
    onCancel() {
      this.projectShowsFunction();
    },
    onConfirm1(value) {
      console.log(this.taskTypeValue);
      this.taskTypeValue.map((item) => {
        if (value == item.title) {
          this.typeValueId = item.id;
          this.total.taskTypes = value;
          this.taskTypesShow = false;
          if (window.localStorage.getItem("sure")) {
            const objects = Object.assign(
              JSON.parse(window.localStorage.getItem("sure")),
              {
                taskTypes: value,
                typeValueId: item.id,
              }
            );
            window.localStorage.setItem("sure", JSON.stringify(objects));
            console.log(44444);
          } else {
            window.localStorage.setItem(
              "sure",
              JSON.stringify({
                taskTypes: value,
                typeValueId: item.id,
              })
            );
            console.log(5555);
          }
        }
      });
    },
    onCancel1() {
      this.taskTypesFunction();
    },
    typeList() {
      API.typeList().then(async (e) => {
        if (e.code == 1) {
          e.data.rows.map((item) => {
            this.columns.push(item.name);
          });
          this.PreliminaryData = e.data.rows;
        }
      });
      API.task_type().then(async (e) => {
        if (e.code == 1) {
          e.data.map((item) => {
            this.columns1.push(item.title);
          });
          this.taskTypeValue = e.data;
        }
      });
    },
    rewardPoints() {
      const parms = {
        name: "rewardPoints",
        path: "/rewardPoints",
      };
      this.pathRquest(parms);
    },
    overdragetPerson() {
      const parms = {
        name: "overdragetPerson",
        path: "/overdragetPerson",
      };
      this.pathRquest(parms);
    },
    vanskelighedSelect() {
      const parms = {
        name: "vanskelighedSelect",
        path: "/vanskelighedSelect",
      };
      this.pathRquest(parms);
    },
    acceptorPerson() {
      const parms = {
        name: "acceptorPerson",
        path: "/acceptorPerson",
      };
      this.pathRquest(parms);
    },
    pathRquest(parms) {
      this.$router.push(parms);
    },
    beforeRead(file) {
      if (!file.type) {
        this.$toast.fail("请上传文件");
        return false;
      }
      return true;
    },
    uploadingEvent(file) {
      this.filkeList.push({
        url: file.file.name,
        message: "上传中",
      });
      let data = new FormData();
      data.append("file", file.file);
      let config = {
        //添加请求头
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios.post("/admin/ajax/upload", data, config).then(async (e) => {
        if (e) {
          if (e.data.status == 203) {
            this.$notfiy
              .confirm({
                title: "请重新登录",
                message: "登录已失效，请重新登录",
              })
              .then(() => {
                setTimeout(() => {
                  window.location.href = "/";
                }, 800);
              })
              .catch(() => {
                this.$toast({
                  type: "fail",
                  message: "取消登录",
                });
              });
          }
          if (e.data.code == 1) {
            this.filkeListShow = true;
            console.log(e.data.data.url, e.data);
            this.filkeList.map((items) => {
              items.message = "上传成功";
            });
            this.fileListData.push(e.data.data.url);
            if (window.localStorage.getItem("sure")) {
              const objects = Object.assign(
                JSON.parse(window.localStorage.getItem("sure")),
                {
                  // 显示路径
                  fileListData: this.filkeList,
                  // 提交路径
                  ListData: this.fileListData,
                }
              );
              window.localStorage.setItem("sure", JSON.stringify(objects));
            } else {
              window.localStorage.setItem(
                "sure",
                JSON.stringify({
                  // 显示路径
                  fileListData: this.filkeList,
                  // 提交路径
                  ListData: this.fileListData,
                })
              );
            }
          }
          if (e.data.code == 0) {
            this.$toast.fail("上传失败，附件超过最大10MB，请重新选择");
          }
        }
      });
    },
    uploadingImageEevent(file) {
      this.uploadingImage.push({
        url: file.file.name,
        status: "uploading",
        message: "上传中",
      });
      // {
      // 	image:file.file.name,
      // 	check:false
      // }
      let data = new FormData();
      data.append("file", file.file);
      let config = {
        //添加请求头
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios.post("/admin/ajax/upload", data, config).then(async (e) => {
        if (e) {
          if (e.data.status == 203) {
            this.$notfiy
              .confirm({
                title: "请重新登录",
                message: "登录已失效，请重新登录",
              })
              .then(() => {
                setTimeout(() => {
                  window.location.href = "/";
                }, 800);
              })
              .catch(() => {
                this.$toast({
                  type: "fail",
                  message: "取消登录",
                });
              });
          }
          if (e.data.code == 1) {
            this.uploadingImage.map((items) => {
              items.status = "success";
              items.message = "上传成功";
            });
            this.uploadingImagesList.push(e.data.data.url);
            this.uploadingImageShow = true;
            if (window.localStorage.getItem("sure")) {
              const objects = Object.assign(
                JSON.parse(window.localStorage.getItem("sure")),
                {
                  // 显示文件路径
                  uploadingImagesList: this.uploadingImage,
                  // 提交路径文件
                  ImageList: this.uploadingImagesList,
                }
              );
              window.localStorage.setItem("sure", JSON.stringify(objects));
            } else {
              window.localStorage.setItem(
                "sure",
                JSON.stringify({
                  // 显示文件路径
                  uploadingImagesList: this.uploadingImage,
                  // 提交路径文件
                  ImageList: this.uploadingImagesList,
                })
              );
            }
          }
          if (e.data.code == 0) {
            this.$toast.fail("上传失败，图片超过最大10MB，请重新选择");
          }
        }
      });
    },
    submitEvent() {
      console.log(this.fileListData);
      const parms = {
        title: this.total.title,
        introduce: this.total.description
          ? this.total.description
          : window.localStorage.getItem("sure")
          ? JSON.parse(window.localStorage.getItem("sure")).description
          : "",
        project_id: this.total.projectId
          ? this.total.projectId
          : this.PreliminaryData[0].id,
        section_id: this.total.tildeleId,
        inspect_ids: this.total.acceptPersonID
          ? this.total.acceptPersonID.toString()
          : "",
        reward_method: this.total.rewardTextFunction,
        // additional_number: this.total.selectInputs,
        additional_number: this.total.additional_number,
        catch_modality: this.total.rewardTextFunction,
        user_id: this.total.id,
        annex: this.fileListData
          ? this.fileListData.toString()
          : window.localStorage.getItem("sure")
          ? JSON.parse(window.localStorage.getItem("sure")).ListData.toString()
          : "",
        taskimg: this.uploadingImagesList
          ? this.uploadingImagesList.toString()
          : "",
        difficulty: this.total.vanskelighed,
        task_type: this.total.typeValueId,
      };
      console.log(parms);
      if (this.personUser == 8) {
        if (
          parms.title ??
          parms.introduce ??
          parms.project_id ??
          parms.section_id ??
          parms.reward_method ??
          parms.additional_number ??
          parms.catch_modality ??
          parms.user_id ??
          parms.attachment_ids ??
          parms.taskimg ??
          parms.difficulty
        ) {
          API.oprette(parms).then(async (e) => {
            this.$toast({
              type: e.code == 0 ? "fail" : "success",
              message: e.code == 1 ? "发布任务成功" : e.msg,
            });
            if (e.code == 1) {
              window.localStorage.setItem("sure", "");
              console.log(6666);

              setTimeout(() => {
                this.$router.back(-1);
              }, 1000);
            }
          });
        } else {
          this.$toast.fail("请仔细检查发布任务信息，不能为空");
        }
      } else {
        const parmss = {
          title: this.title,
          introduce: this.total.description,
          project_type: this.PreliminaryID
            ? this.PreliminaryID
            : this.PreliminaryData[0].id,
          attachment: this.fileListData ? this.fileListData.join(",") : "",
          img: this.uploadingImagesList
            ? this.uploadingImagesList.join(",")
            : "",
        };
        if (parmss.title && parmss.project_type) {
          API.projectAdd(parmss).then(async (e) => {
            this.$toast({
              type: e.code == 0 ? "fail" : "success",
              message: e.code == 1 ? "发布项目成功" : e.msg,
            });
            if (e.code == 1) {
              window.localStorage.setItem("sure", "");
              console.log(7777);
              setTimeout(() => {
                this.$router.back(-1);
              }, 1000);
            }
          });
        } else {
          this.$toast.fail("请仔细检查信息不能为空");
        }
      }
    },
    // 附件删除
    deleteClickEvent(s, k) {
      console.log(s, k);
      this.filkeList.splice(k, 1);
      this.fileListData.splice(k, 1);
      if (window.localStorage.getItem("sure")) {
        window.localStorage.setItem(
          "sure",
          JSON.stringify({
            fileListData: this.filkeList,
            ImageList: this.fileListData,
            uploadingImagesList: this.uploadingImage,
            ListData: this.uploadingImagesList,
          })
        );
      }
    },

    // 图片删除
    deleteClickEvents(s, k) {
      this.uploadingImage.splice(k, 1);
      this.uploadingImagesList.splice(k, 1);
      if (window.localStorage.getItem("sure")) {
        window.localStorage.setItem(
          "sure",
          JSON.stringify({
            uploadingImagesList: this.uploadingImage,
            ListData: this.uploadingImagesList,
            fileListData: this.filkeList,
            ImageList: this.fileListData,
          })
        );
      }
    },
    // 回退
    navigationClick() {
      window.localStorage.setItem("sure", "");
      this.$router.back(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.lancering {
  background: #f1f0f5;

  .langcering_content {
    .langcering_content_box {
      .langcering_content_header {
        background: #d71518;
        width: 100%;
        height: calcFunction(150);
        .public_navigation {
          position: sticky;
          top: 0;
          background: #d71518 !important;
          z-index: 800;
        }
      }

      .select_project {
        margin-top: calcFunction(-80);
        margin-bottom: calcFunction(60);

        .select_project_content {
          .select_project_content_box {
            .select_project_card_white {
              background: white;
              border-radius: calcFunction(8);
              margin: calcFunction(10);
              //   padding-bottom: calcFunction(40);

              .select_name_select_text_select_arrow {
                @extend .alignItemsCenter;
                justify-content: space-between;
                padding: calcFunction(20) calcFunction(15);
                border-bottom: 1px solid #dcdcdc;
                position: relative;

                .select_left_content_box {
                  @extend .alignItemsCenter;

                  .select_name {
                    // width: 36%;
                    // display: block;
                    span {
                      font-size: calcFunction(14);
                      white-space: nowrap;
                    }
                  }

                  .select_input {
                    width: 50%;
                    margin-left: 50%;

                    input {
                      border: none;
                      outline: none;
                      font-size: calcFunction(14);
                      background: transparent;

                      &::placeholder {
                        color: #999999;
                      }
                    }

                    span {
                      white-space: nowrap;
                      font-size: calcFunction(14);
                      color: #999999;
                    }
                  }
                }

                .select_arrow {
                  img {
                    width: calcFunction(6);
                    height: calcFunction(10);
                  }
                }
              }

              .select_name_select_text_select_arrow:nth-child(1) {
                .select_input {
                  margin-left: 10%;
                }
              }

              .select_name_select_text_select_arrow:nth-child(4) {
                .select_input {
                  margin-left: 15%;
                }
              }

              .select_name_select_text_select_arrow:nth-child(2),
              .select_name_select_text_select_arrow:nth-child(3) {
                .select_input {
                  margin-left: 15%;
                }
              }

              .select_name_select_text_select_arrow:nth-child(5) {
                .select_input {
                  margin-left: 9%;
                }
              }

              .select_name_select_text_select_arrow:nth-child(6),
              .select_name_select_text_select_arrow:nth-child(7) {
                .select_input {
                  margin-left: 22%;
                }
              }

              .select_name_select_text_select_arrow:nth-last-child(1) {
                border: none;

                .select_input {
                  margin-left: 0;
                }
              }

              .uploading_file_style {
                .select_input {
                  margin-left: 62% !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .fileuploading_box {
    @extend .alignItemsCenter;
  }
}

.submit_task {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;

  .submit_task_box {
    padding: calcFunction(7.5) calcFunction(10);

    button {
      width: 100%;
      background: #ffb5b6;
      color: white;
      padding: calcFunction(12) 0;
      border: none;
      outline: none;
      font-size: calcFunction(14);
      border-radius: calcFunction(8);
    }
  }
}

.image_start {
  @extend .alignItemsCenter;
  width: 50%;
  flex: 1;

  img {
    flex: 1;
    width: calcFunction(14);
    height: calcFunction(14);
  }
}

.custom_start {
  // padding-left: 58%;
  justify-content: flex-end;
}

.postions_file {
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 0;
}

.uploading_position {
  width: 70vw;
  height: 100%;
  background: transparent;
  border: none;
  padding-left: 10%;
  @extend .alignItemsCenter;
  justify-content: flex-start;
}

.text_list_box {
  margin: 0 calcFunction(15);
  margin-top: 10px;

  .list_text_fors {
    background: #ffeaea;
    margin-bottom: 10px;
    padding: calcFunction(15) calcFunction(10);
    border-radius: calcFunction(4);
    @extend .alignItemsCenter;
    justify-content: space-between;

    span {
      font-size: calcFunction(14);
      color: #d71518;
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img {
      width: calcFunction(10);
      height: calcFunction(10);
    }
  }

  .list_text_fors:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.select_input {
  textarea {
    width: 100%;
    padding-top: calcFunction(10);
    font-size: calcFunction(14);
    border: none;
    height: calcFunction(100);
  }
}

.addbuttonsimage {
  margin-right: calcFunction(-3);

  img {
    height: calcFunction(15) !important;
    width: calcFunction(15) !important;
  }
}

.select_left_content_box:nth-last-child(1) {
  width: 100%;
}

.array_nickname {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  span {
    margin-right: 5px;
    color: black !important;
  }
}

.loading,
.loading > div {
  position: relative;
  box-sizing: border-box;
}

.loading {
  display: block;
  font-size: 0;
  color: #d71518;
  margin-left: 10px;
}

.loading.la-dark {
  color: #e74346;
}

.loading > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.loading {
  width: 8px;
  height: 8px;
}

.loading > div {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  animation: ball-fussion-ball1 1s 0s ease infinite;
}

.loading > div:nth-child(1) {
  top: 0;
  left: 50%;
  z-index: 1;
}

.loading > div:nth-child(2) {
  top: 50%;
  left: 100%;
  z-index: 2;
  animation-name: ball-fussion-ball2;
}

.loading > div:nth-child(3) {
  top: 100%;
  left: 50%;
  z-index: 1;
  animation-name: ball-fussion-ball3;
}

.loading > div:nth-child(4) {
  top: 50%;
  left: 0;
  z-index: 2;
  animation-name: ball-fussion-ball4;
}

.loading.la-sm {
  width: 4px;
  height: 4px;
}

.loading.la-sm > div {
  width: 6px;
  height: 6px;
}

.loading.la-2x {
  width: 16px;
  height: 16px;
}

.loading.la-2x > div {
  width: 24px;
  height: 24px;
}

.loading.la-3x {
  width: 24px;
  height: 24px;
}

.loading.la-3x > div {
  width: 36px;
  height: 36px;
}

@keyframes ball-fussion-ball2 {
  0% {
    opacity: 0.35;
  }

  50% {
    top: 200%;
    left: 200%;
    opacity: 1;
  }

  100% {
    top: 100%;
    left: 50%;
    z-index: 1;
    opacity: 0.35;
  }
}

@keyframes ball-fussion-ball1 {
  0% {
    opacity: 0.35;
  }

  50% {
    top: -100%;
    left: 200%;
    opacity: 1;
  }

  100% {
    top: 50%;
    left: 100%;
    z-index: 2;
    opacity: 0.35;
  }
}

@keyframes ball-fussion-ball3 {
  0% {
    opacity: 0.35;
  }

  50% {
    top: 200%;
    left: -100%;
    opacity: 1;
  }

  100% {
    top: 50%;
    left: 0;
    z-index: 2;
    opacity: 0.35;
  }
}

@keyframes ball-fussion-ball4 {
  0% {
    opacity: 0.35;
  }

  50% {
    top: -100%;
    left: -100%;
    opacity: 1;
  }

  100% {
    top: 0;
    left: 50%;
    z-index: 1;
    opacity: 0.35;
  }
}
</style>
<style lang="scss">
.lancering {
  .van-uploader {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;

    .van-uploader__wrapper {
      height: 20px;
    }

    .uploading_position {
      width: 20px;
    }
  }
}
</style>
