<template>
  <div class="user_infromation">
    <div class="user_infromation_content">
      <div class="user_infromation_content_data">
        <div class="user_infromation_header">
          <publicNavigation :data="navigation" />
        </div>
        <div class="user_information">
  
          <div class="avatar">
            <span>头像</span>
            <div class="avatar_image_arrow">
              <van-uploader max-count="1" :deletable="false" class="imageroud" :after-read="confirmChange">
                <!-- avatar[0]?.url ||  -->
                <van-image fit="cover" class="imageroud" :src="userinfoimage" alt="" />
              </van-uploader>
              <img src="@/assets/readright.png" alt="" />
            </div>
          </div>
          <div class="avatar custom_avatar custom_margin">
            <span>姓名</span>
            <div class="avatar_image_arrow">
              <input type="text" placeholder="请输入您的昵称" @blur="bulurChange" v-model="personName" />
            </div>
          </div>
          <div class="avatar custom_avatar" style="align-items: flex-start" @click="skiltEventClick">
            <span style="padding: 0.2rem 0;">个性签名</span>
            <div class="avatar_image_arrow flex-c">
              <span class="avatar_image_arrow_text">
                {{Remark || "未填写"}}
              </span>
              <img src="@/assets/readright.png" alt="" />
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import publicNavigation from "@/publicNavigation/publicNavigation.vue";
import axios from "axios";
import * as API from "@/api.ts";
export default {
  components: {
    publicNavigation,
  },
  data() {
    return {
      navigation: {
        image: require("@/assets/return.png"),
        name: "个人信息",
        close: "",
      },
      avatar: [],
      personName: "",
      Remark: "",
      userinfoimage:''
    };
  },
  mounted() {
   
    //获取用户信息
    this.getInfo();
  },
  methods: {
    //获取用户信息
    getInfo() {
      this.avatar = [];
      API.userInfo().then(async (e) => {
        if (e.code == 1) {
          this.personName = e.data.nickname;
          this.avatar.push({
            url: e.data.avatar,
          });
          this.userinfoimage = e.data.avatar
          this.Remark = e.data.signature;
        }
      });
    },
    bulurChange(e) {
      const parms = {
        ids: window.sessionStorage.getItem("userInfo")
          ? JSON.parse(window.sessionStorage.getItem("userInfo")).id
          : "",
        nickname: e.target._value,
      };
      API.adminedit(parms).then(async (e) => {
        if (e.code == 1) {
          API.userInfo().then(async (e) => {
            if (e.code == 1) {
              this.personName = e.data.nickname;
            }
          });
        }
      });
    },
    skiltEventClick() {
      const parms = {
        name: this.personName,
        Remark: this.Remark,
      };
      this.$router.push({
        naem: "userSkilt",
        path: "/userSkilt",
        query: {
          e: parms,
        },
      });
    },
    confirmChange(file) {
      this.userinfoimage = file.content
      this.avatar = [];
      let data = new FormData();
      data.append("file", file.file);
      let config = {
        //添加请求头
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios.post("/admin/ajax/upload", data, config).then(async (e) => {
        if (e.data.status == 203) {
          this.$notfiy
            .confirm({
              title: "请重新登录",
              message: "登录已失效，请重新登录",
            })
            .then(() => {
              setTimeout(() => {
                window.location.href = "/";
              }, 800);
            })
            .catch(() => {
              this.$toast({
                type: "fail",
                message: "取消登录",
              });
            });
        }
        if (e.data.status == 200) {
          window.sessionStorage.setItem("avatar", e.data.data.url);
          const parms = {
            ids: window.sessionStorage.getItem("userInfo")
              ? JSON.parse(window.sessionStorage.getItem("userInfo")).id
              : "",
            avatar: e.data.data.url,
          };
          console.log(parms);
          API.adminedit(parms).then(async (e) => {
            if (e.code == 1) {
              API.userInfo().then(async (e) => {
                if (e.code == 1) {
                  // this.avatar.push({
                  //   url: e.data.avatar,
                  // });
                  this.userinfoimage = e.data.avatar
                }
              });
            }
          });
          this.$toast({
            type: "success",
            message: "上传成功",
          });
          //获取用户信息
          this.getInfo();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.user_infromation {
  height: 100vh;
  background: #f1f2f6;

  .user_infromation_content {
    .user_infromation_content_data {
      .user_infromation_header {
        background: white;
      }

      .user_information {
        .avatar {
          @extend .alignItemsCenter;
          justify-content: space-between;
          padding: 0 calcFunction(20);
          padding-right: calcFunction(10);
          margin-top: calcFunction(1);
          background: white;
    
          span {
            font-size: calcFunction(14);
          }
    
          .avatar_image_arrow {
            display: flex;
            align-items: center;
            margin-right: 10px;
            justify-content: flex-end;
            padding: 0.2rem 0;
            flex: 1;
            margin-left: 0.3rem;
      
            .imageroud {
              width: 1.2rem;
              height: 1.2rem;
              border-radius: 50%;
              margin-right: 0.3rem;
              overflow: hidden;
            }
      
            img {
              width: calcFunction(7);
              height: calcFunction(10);
            }
      
            input {
              border: none;
              outline: none;
              text-align: right;
              font-size: calcFunction(12.5);
              letter-spacing: 1px;
              height: 0.5rem;
            }
      
            input::placeholder {
              color: #999999;
            }
          }
        }
      }
    }
  }
}

.custom_avatar {
  padding: calcFunction(10) calcFunction(20) !important;
  padding-right: calcFunction(10) !important;

  .avatar_image_arrow {
    @extend .alignItemsCenter;

    img {
      margin-left: calcFunction(10);
    }

    span {
      color: #999999;
      font-size: calcFunction(14);
      margin-top: calcFunction(-2);
    }
  }
}

.custom_avatar:nth-last-child(1) {
  >span {
    display: block;
    width: 30%;
    white-space: nowrap;
  }

  .avatar_image_arrow {
    >span {
      width: 260px;
      text-align: left;
    }
  }
}

.custom_margin {
  margin-top: calcFunction(5) !important;
}

.avatar_image_arrow_text {
  // white-space: nowrap;
  width: 200px;
  // overflow: hidden;
  // text-overflow: ellipsis;
  display: flex;
  justify-content: flex-end;
}
</style>
<style lang="scss">
.user_infromation {
  .van-uploader__wrapper {
    align-items: center;
  }

  .van-uploader__upload {
    width: 60px !important;
    height: 60px !important;
    border-radius: 100px;
    align-items: center;
    margin-bottom: 0 !important;
    overflow: hidden;
  }

  .van-uploader__preview-image {
    height: 60px !important;
    width: 60px !important;
    border-radius: 100px;
    align-items: center;
  }
}
</style>
