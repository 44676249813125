<template>
  <div>
    <publicNavigation
      @currentClick="currentClick"
      :data="navigation"
      :position="position"
    />
    <gradeCard
      @descriptionClick="descriptionClick"
      :rankingList="rankingList"
      :threeList="threeList"
      :mypersoninfomation="mypersoninfomation"
      v-if="currentIndex == 1"
    />
    <classfiyContent
      v-else
      @descriptionEvent="descriptionEvent"
      :deparmentData="deparmentData"
    />
  </div>
</template>
<script>
import publicNavigation from "@/publicNavigation/publicNavigation.vue";
import gradeCard from "@/leaderboard/components/gradeCard/gradeCard.vue";
import classfiyContent from "@/leaderboard/components/classfiyContent/classfiyContent.vue";
import { leaderboardData } from "./newLeaderboard.ts";
import * as API from "@/api.ts";
export default {
  components: {
    publicNavigation,
    gradeCard,
    classfiyContent,
  },
  data() {
    return {
      navigation: {},
      position: [],
      currentIndex: 0,
      deparmentData: [],
      rankingList: [],
      mypersoninfomation: [],
      threeList: [],
    };
  },
  mounted() {
    const parms = new leaderboardData().init();
    this.navigation = {
      image: require("../assets/return.png"),
      name: "团队",
      toggle: 1,
      close: "",
    };
    this.position = parms.position;
    // this.deparmentData = parms.deparmentData
    this.Request();
  },
  methods: {
    Request() {
      API.allteam().then(async (e) => {
        if (e.code == 1) {
          e.data.rows.map((item) => {
            this.deparmentData.push({
              name: item.title,
              children: item.person.map((s) => {
                return {
                  ...s,
                  image: s.avatar,
                  name: s.nickname,
                  icon: s.section_name,
                  intergral: s.ranking,
                  totalNumber: s.level,
                  number: s.task_num,
                };
              }),
            });
          });
        }
      });
    },
    currentClick(e) {
      this.currentIndex = e;
      if (this.mypersoninfomation.length <= 0 || this.rankingList.length <= 0) {
        API.rankingList().then(async (e) => {
          if (e.code == 1) {
            this.mypersoninfomation = e.data.admin;
            e.data.first.map((items) => {
              this.threeList.push({
                ...items,
                name: items.nickname,
                image: items.avatar,
                position: items.section,
                intergral: items.score,
                number: items.level,
              });
            });
            let data = this.threeList[0];
            this.threeList[0] = this.threeList[1];
            this.threeList[1] = data;
            e.data.rows.map((item) => {
              if (item.ranking < 4) {
                console.log(1123);
              } else {
                this.rankingList.push({
                  ...item,
                  name: item.nickname,
                  image: item.avatar,
                  number: item.task_count,
                  grade: item.level,
                  ranking: item.ranking,
                });
              }
            });
          }
        });
      }
    },
    // 按排行详情点击
    descriptionClick(s) {
      window.localStorage.setItem("mydesc", JSON.stringify(s));
      this.$router.push({
        name: "mydesc",
        path: "/mydesc",
        query: {
          description: s,
        },
      });
    },
    // 按岗位详情点击
    descriptionEvent(s) {
      window.localStorage.setItem("mydesc", JSON.stringify(s));
      this.$router.push({
        name: "mydesc",
        path: "/mydesc",
        query: {
          description: s,
          id: s.id,
        },
      });
    },
  },
};
</script>
