<template>
    <div class="waitHandle">
        <div class="waitHandle_box">
            <publicCardHeader :toDayText="toDayText"/>
            <div class="waitHandle_list">
                <div class="waitHandle_list_twodo">
                    <div class="wait_list" v-for="(s,k) in waitData" :key="k">
                        <div class="wait_list_data">
                            <div class="wait_number">
                                <span>{{ s.number }}</span>
                            </div>
                            <div class="wait_description">
                                <span>{{ s.name }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import publicCardHeader from '@/component/publicCard/publicCard.vue'

export default {
    components:{
        publicCardHeader
    },
    props:{
        waitData: Array,
    },
    data(){
        return{
            toDayText:{}
        }
    },
    mounted(){
        this.toDayText = {
            name:'',
            image:require('../../assets/waitTime.png')
        }
    }
}
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter{
    display: flex;
    align-items: center;
}


// 计算函数
@function calcFunction($number,$default:16){
    @return calc($number * 2 / 100) + rem;
}
.waitHandle{
    margin:.2rem;
    background: white;
    border-radius: .16rem;
    .waitHandle_box{
        
        .waitHandle_list{
            .waitHandle_list_twodo{
                @extend .alignItemsCenter;
                justify-content: space-between;
                padding: .5rem .56rem;
                box-sizing: border-box;
                .wait_list{
                    width: 33%;
                    position: relative;
                    .wait_list_data{
                        @extend .alignItemsCenter;
                        justify-content: center;
                        flex-direction: column;
                        .wait_number{
                            @extend .alignItemsCenter;
                            span{
                                color: #007EFF;
                                font-size: .6rem;
                            }
                        }
                        .wait_description{
                            @extend .alignItemsCenter;
                            margin-top: .3rem;
                            span{
                                color: #333333;
                                font-size: .22rem;
                            }
                        }
                    }
                    &::after{
                        content: '';
                        position: absolute;
                        width: 1px;
                        height: 50%;
                        background: #DDDDDD;
                        right: 0;
                        top: 30%;
                    }
                }
                .wait_list:nth-child(2){
                    .wait_number{
                        span{
                            color: #FF9600;
                        }
                    }
                }
                .wait_list:nth-child(3){
                    .wait_number{
                        span{
                            color: #00D00A;
                        }
                    }
                    &::after{
                        display: none;
                    }
                }
            }
        }
    }
}
</style>
