<template>
  <div class="input_log">
    <div class="input_log_data">
      <div class="input_log_contents">
        <div class="input_log_header">
          <publicNavigation navigationColor="white" :data="navigation" />
          <div class="log_from_submit">
            <div class="from_submit">
              <div class="from_submit_card">
                <div class="submit_list">
                  <div class="title_select" @click="dateTimeSelect">
                    <span>选择日期</span>
                    <span>{{
                      timeShowtext || `请选择${planShow ? "计划" : "日志"}日期`
                    }}</span>
                  </div>
                  <div class="right_title">
                    <img src="@/assets/blackarrow.png" alt="" />
                  </div>
                </div>
                <div
                  :class="['submit_list' ,!mateProject.mateTitle?'':'grayBg']"
                  v-if="!planShow"
                  @click="associateEvent"
                >
                  <div class="title_select">
                    <span>关联任务</span>
                    <span>{{
                      mateProject.mateTitle
                        ? mateProject.mateTitle
                        : "请选择关联任务"
                    }}</span>
                  </div>
                  <div class="right_title">
                    <img src="@/assets/blackarrow.png" alt="" />
                  </div>
                </div>
                <div class="log_content">
                  <div class="log_content_data">
                    <div class="log_content_title">
                      <span>日志内容</span>
                    </div>
                    <div class="log_content_textarea">
                      <textarea
                        v-model="textarea"
                        maxlength="200"
                        name=""
                        :placeholder="
                          '请填写' +
                          (planShow == 1 ? '计划' : '日志') +
                          '内容...'
                        "
                        id=""
                        cols="30"
                        rows="10"
                      ></textarea>
                    </div>
                  </div>
                  <div class="font_size">
                    <span>{{ fontsize }}/200</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="date_time_piker">
      <van-popup
        v-model="showPopup"
        position="bottom"
        :style="{ height: '40%' }"
      >
        <!-- {{currentDate}} -->
        <!-- v-model="currentDate" -->
        <van-datetime-picker
          v-model="currentDate"
          :min-date="minDate"
          type="date"
          title="选择年月日"
          @confirm="confirmEvent"
          @cancel="cancelEvent"
          :max-date="maxDate"
        />
      </van-popup>
      <van-popup
        v-model="changeShow"
        position="bottom"
        :style="{ height: '40%' }"
      >
        <van-picker
          title="标题"
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="onCancel"
        />
      </van-popup>
    </div>
    <div class="bottom_button">
      <div class="bottom">
        <button
          @click="submitClick"
          :disabled="buttonStatus == true ? true : false"
          :style="
            buttonStatus == false
              ? 'background: #D71518 !important;color: white !important;'
              : ''
          "
        >
          提交
        </button>
      </div>
    </div>
    <van-dialog
      v-model="dialogShow"
      @confirm="planEvent"
      class="dialogshowstyle"
      confirmButtonColor="#D71518"
      title="提交成功"
      show-cancel-button
    >
      <span>日志提交成功，是否填写 </span>
      <span>明日计划？</span>
    </van-dialog>
  </div>
</template>
<script>
import publicNavigation from "@/publicNavigation/publicNavigation";
// import {Fucntions} from './inputLog.ts'
import * as API from "@/api.ts";
export default {
  components: {
    publicNavigation,
  },
  props: { isSelect: Boolean }, //是否禁止选择任务,
  data() {
    return {
      navigation: {},
      showPopup: false,
      changeShow: false,
      fontsize: 0,
      textarea: "",
      columns: [],
      dialogShow: false,
      planShow: false,
      timeShowtext: "",
      dailyplantime: 0,
      mateProject: {},
      taskDescritpion: {},
      statictime: "",
      buttonStatus: true,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2099, 10, 1),
      currentDate: new Date(),
    };
  },
  watch: {
    textarea(e) {
      const textsize = e.split("");
      this.fontsize = textsize.length;
      if (this.statictime || (e && this.timeShowtext)) {
        this.buttonStatus = false;
      } else {
        this.buttonStatus = true;
      }
    },
    timeShowtext(e) {
      console.log(e);
      if ((this.textarea && e) || this.statictime) {
        this.buttonStatus = false;
      } else {
        this.buttonStatus = true;
      }
    },
    statictime(e) {
      if ((this.textarea && e) || this.timeShowtext) {
        this.buttonStatus = false;
      } else {
        this.buttonStatus = true;
      }
    },
  },
  mounted() {
    this.navigation = {
      whiteImage: require("../assets/whitereturn.png"),
      name: this.$route.query.planShow ? "填写计划" : "填写日志",
      close: "",
    };
    this.planShow = this.$route.query.planShow ? true : false;
    try {
      this.taskDescritpion = JSON.parse(this.$route.query.data);
      if (!this.taskDescritpion) {
        this.Request();
      } else {
        console.log(this.taskDescritpion);
        this.columns = [
          this.taskDescritpion.children[0] &&
            this.taskDescritpion.children[0].title,
        ];
        if (this.mateProject.mateTitle) {
          this.mateProject.mateTitle = this.taskDescritpion.children[0].title;
          this.mateProject.mateId = this.taskDescritpion.children[0].id;
          this.statictime = this.mateProject.mateTitle;
        } else {
          this.mateProject = {
            mateTitle: this.taskDescritpion.children[0].title,
            mateId: this.taskDescritpion.children[0].id,
          };
          this.statictime = this.mateProject.mateTitle;
        }
      }
    } catch (error) {
      this.taskDescritpion = this.$route.query.data;
      if (!this.taskDescritpion) {
        this.Request();
      } else {
        console.log(this.taskDescritpion);
        this.columns = [
          this.taskDescritpion.children[0] &&
            this.taskDescritpion.children[0].title,
        ];
        if (this.mateProject.mateTitle) {
          this.mateProject.mateTitle = this.taskDescritpion.children[0].title;
          this.mateProject.mateId = this.taskDescritpion.children[0].id;
          this.statictime = this.mateProject.mateTitle;
        } else {
          this.mateProject = {
            mateTitle: this.taskDescritpion.children[0].title,
            mateId: this.taskDescritpion.children[0].id,
          };
          this.statictime = this.mateProject.mateTitle;
        }
      }
    }
  },
  methods: {
    Request() {
      API.self_task().then(async (e) => {
        if (e.code == 1) {
          this.mateProjectTitle = e.data.rows;
          e.data.rows.map((items) => {
            this.columns.push(items.title);
          });
        }
      });
    },
    dateTimeSelect() {
      this.showPopup = !this.showPopup;
    },
    confirmEvent(e) {
      console.log(e);
      this.dailyplantime = e.valueOf(".");
      this.timeShowtext = this.timeFunction(e.valueOf("."));
      this.dateTimeSelect();
    },
    cancelEvent() {
      this.dateTimeSelect();
    },
    associateEvent() {
      console.log(this.isSelect);
      if (this.$route.query.isSelect) {
        return;
      }
      if (!this.taskDescritpion) {
        if (!this.mateProject.mateTitle) {
          this.changeShow = !this.changeShow;
        }
      } else {
        this.changeShow = !this.changeShow;
      }
    },
    onConfirm(value) {
      if (this.taskDescritpion) {
        this.mateProject = {
          mateTitle: value,
          mateId: this.taskDescritpion.children[0].id,
          mateChildrenText: this.taskDescritpion.children[0].title,
        };
      } else {
        this.mateProjectTitle.map((items) => {
          console.log(items);
          if (value == items.title) {
            this.mateProject = {
              mateTitle: value,
              mateId: items.id,
              mateChildrenText: items.title,
            };
            this.statictime = value;
            this.changeShow = !this.changeShow;
          }
        });
      }
      this.associateEvent();
    },
    onCancel() {},
    timeFunction(e) {
      const date = new Date(e),
        Y = date.getFullYear(),
        M = Number(date.getMonth()) + 1,
        R = date.getDate();
      return (
        Y + "-" + (M < 10 ? "0" + M : M) + "-" + (R < 10 ? "0" + R : R)
      );
    },
    submitClick() {
      const parms = {
        dailyplantime: this.dailyplantime / 1000,
        content: this.textarea,
        type: this.planShow == true ? 1 : 2,
        task: this.mateProject.mateId
          ? this.mateProject.mateId
          : this.$route.query.taskid,
      };
      console.log(parms);
      if (parms.dailyplantime ?? parms.type ?? parms.task) {
        API.Dailyplan(parms).then(async (e) => {
          this.$toast({
            type: e.code == 1 ? "success" : "fail",
            message: e.msg,
          });
          if (e.code == 1) {
            this.dailyplantime = "";
            this.textarea = "";
            this.mateProject.mateId = "";
            setTimeout(() => {
              this.$router.back(-1);
              this.planShow = false;
            }, 800);
            // if (!this.planShow) {
            // 	this.dialogShow = !this.dialogShow
            // } else {
            // 	setTimeout(() => {
            // 		this.$router.back(-1)
            // 		this.planShow = false
            // 	}, 1000)
            // }
          }
        });
      } else {
        this.$toast.fail("请仔细检查有空内容");
      }
    },
    planEvent() {
      this.planShow = !this.planShow;
    },
  },
};
</script>
<style lang="scss">
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.input_log {
  height: 100vh;
  background: #f1f0f5;

  .input_log_data {
    .input_log_contents {
      .input_log_header {
        background-image: url("../assets/background.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: 8.5rem;
      }
    }

    .log_from_submit {
      .from_submit {
        margin: calcFunction(10);

        .from_submit_card {
          background: white;
          border-radius: calcFunction(8);

          .submit_list {
            padding: calcFunction(20);
            @extend .alignItemsCenter;
            justify-content: space-between;
            border-bottom: 1px solid #dcdcdc;

            .title_select {
              span {
                font-size: calcFunction(14);
                color: #111111;
                font-weight: bold;
              }

              span:nth-last-child(1) {
                margin-left: calcFunction(20);
                font-weight: 400;
                color: #999999;
              }
            }

            .right_title {
              img {
                width: calcFunction(8);
                height: calcFunction(12);
              }
            }
          }
          .grayBg{
            background: #f0f0f0;
          }

          .log_content {
            padding: calcFunction(20);

            .log_content_data {
              .log_content_title {
                span {
                  font-size: calcFunction(14);
                  color: #111111;
                  font-weight: bold;
                }
              }

              .log_content_textarea {
                margin-top: calcFunction(3);

                textarea {
                  padding-top: calcFunction(4);
                  border: none;
                  width: 100%;
                  font-size: calcFunction(13);
                }
              }
            }

            .font_size {
              text-align: right;

              span {
                font-size: calcFunction(14);
                color: #999999;
              }
            }
          }
        }
      }
    }
  }
}

.bottom_button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;

  .bottom {
    padding: calcFunction(10) calcFunction(15);

    button {
      height: calcFunction(45);
      width: 100%;
      background: #ffb5b6;
      color: white;
      border: none;
      outline: none;
      border-radius: calcFunction(8);
    }
  }
}

.dialogshowstyle {
  .van-dialog__content {
    @extend .alignItemsCenter;
    flex-direction: column;
    padding: calcFunction(10);

    span {
      color: #666666;
      font-size: calcFunction(15);
      margin-bottom: calcFunction(5);
    }
  }

  .van-button--default:nth-last-child(1) {
    .van-button__content {
      background: #d71518;

      .van-button__text {
        color: white;
      }
    }
  }

  .active {
    background: #d71518 !important;
    color: white !important;
  }
}
</style>