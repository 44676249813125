<!--
 * @Author: Mr.Cheng
 * @Date: 2023-09-01 14:31:55
 * @LastEditTime: 2023-10-08 09:44:55
 * @FilePath: \octopus-factory-map\src\myTeam\mydescription\myDescription.vue
-->
<template>
  <div class="my_description">
    <div class="my_description_box">
      <div class="my_description_content">
        <div class="my_description_header">
          <publicNavigation :data="navigation" />
        </div>
        <div class="my_description_bcakground_image">
          <div class="my_description_image">
            <img src="../../assets/userbcakground.png" alt="" />
          </div>
        </div>
        <div class="my_description_data">
          <div class="my_description_data_content">
            <newDescription
              type="1"
              :id="$route.query.description"
              :descriptionObject="descriptionObject"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import publicNavigation from "@/publicNavigation/publicNavigation.vue";
import newDescription from "./compoments/newDescription.vue";
export default {
  components: {
    publicNavigation,
    newDescription,
  },
  data() {
    return {
      navigation: {},
      descriptionObject: {},
    };
  },
  mounted() {
    this.navigation = {
      image: require("../../assets/return.png"),
      name: "个人详情",
      close: "",
    };
    // this.descriptionObject = this.$route.query.description
    //   ? this.$route.query.description
    //   : window.localStorage.getItem("mydesc")
    //   ? JSON.parse(window.localStorage.getItem("mydesc"))
    //   : "";
    // console.log(this.descriptionObject);
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}
.my_description {
  height: 100vh;
  background: #f1f0f5;
  .my_description_box {
    .my_description_content {
      .my_description_header {
        position: absolute;
        width: 100%;
        z-index: 1;
      }
    }
    .my_description_bcakground_image {
      z-index: 2;
      .my_description_image {
        position: absolute;
        width: 100%;
        top: 0;
        img {
          width: 100%;
          height: calcFunction(150);
        }
      }
    }

    .my_description_data_content {
      position: absolute;
      z-index: 1;
      margin-top: calcFunction(80);
      width: 100%;
    }
  }
}
</style>