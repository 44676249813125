<template>
    <div class="cp_value_list">
        <div class="cp_value_list_content">
            <div class="cp_list_content">
                <div class="cp_for" @click="descriptionEvent(s,k)" v-for="s,k in arrayList" :key="k">
                    <div class="cp_list_header" >
                        <div class="header_title_and_each">
                            <div class="header_title">
                                <span>{{ s.title }}</span>
                            </div>
                            <div class="header_each_number">
                                <span>{{ s.number ? '第' + (s.number )+ '次分红' : ''}}</span>
                            </div>
                        </div>
                        <div class="each_value">
                            <div class="each_for_data">
                                <div class="each_for_new_data">
                                    <div class="each_data" v-for="i,l in s.children" :key="l">
                                        <span>{{ i.title }}</span>
                                        <span>{{ i.text }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dividends">
                            <div class="dividends_header">
                                <span>Ta的分红：</span>
                                <span>￥{{s.money || '未公布'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
	props:{
		arrayList:Array
	},
    methods:{
        descriptionEvent(s,k){
            console.log(s,k)
            this.$router.push({
                name:'cpdesc',
                path:'/cpdesc',
                query:{
                    id:s.project_id,
					user_id:s.user_id
                }
            })
        }
    },  
}

</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter{
    display: flex;
    align-items: center;
}
  
// 计算函数
@function calcFunction($number,$default:16){
  @return calc($number * 2 / 100) + rem;
}
.cp_value_list{
    margin: 0 calcFunction(10);
    margin-top: calcFunction(10);
    .cp_value_list_content{
        .cp_list_content{
            height: 88vh;
            overflow-y: scroll;
            .cp_for{
                background: white;
                padding: 0 calcFunction(15);
                padding-top: calcFunction(15);
                border-radius: calcFunction(8);
                margin-bottom: calcFunction(10);
                .cp_list_header{
                    .header_title_and_each{
                        @extend .alignItemsCenter;
                        justify-content: space-between;
                        border-bottom: 1px solid #EEEEEE;
                        padding-bottom: calcFunction(15);
                        margin-bottom: calcFunction(15);
                        .header_title{
                            span{
                                color: #111111;
                                font-size: calcFunction(14);
                            }
                        }
                        .header_each_number{
                            span{
                                color: #666666;
                                font-size: calcFunction(14);
                            }
                        }
                    }
                    .each_value{
                        padding-bottom: calcFunction(10);
                        margin-bottom: calcFunction(15);
                        border-bottom: 1px solid #EEEEEE;
                        .each_for_data{
                            .each_for_new_data{
                                @extend .alignItemsCenter;
                                flex-wrap: wrap;
                                justify-content: space-between;
                                .each_data{
                                    padding-bottom: calcFunction(8);
                                    width: 33%;
                                    display: flex;
                                    align-items: center;
                                    span{
                                        color: #999999;
                                        font-size: calcFunction(14);

                                    }
                                    span:nth-last-child(1){
                                        color: #111;
                                    }
                                }
                                .each_data:nth-last-child(1){
                                    width: 50%;
                                }
                            }
                        }
                    }
                    .dividends{
                        padding-bottom: calcFunction(20);
                        .dividends_header{
                            @extend .alignItemsCenter;
                            justify-content: flex-end;
                            span{
                                color: #D71518;
                                font-size: calcFunction(15);
                            }
                        }
                    }
                }
            }
        }
    }
}

::-webkit-scrollbar{
    display: none;
}
</style>