<template>
	<div class="bouns">
		<div class="bouns_box">
			<publicNavigation :data="navigation" />
		</div>
		<div class="bouns_input_list">
			<div class="bouns_input" v-if="!addUdbytte">
				<div class="input">
					<span>CP值项目标题</span>
					<input type="text" v-model="inputTitle" placeholder="输入标题" />
				</div>
				<div class="input">
					<span>CP值兑换额</span>
					<input type="text" v-model="ombytningsbeløb" placeholder="输入兑换额" />
				</div>
				<div class="input" @click="holdOpFunction">
					<span>兑换截止日期</span>
					<input style="background:#FFF" type="text" v-model="holdUdveksleTime" readonly placeholder="输选择兑换截止日期" />
				</div>
				<div class="input" @click="holdOpFunction(1)">
					<span>分红截止日期</span>
					<input style="background:#FFF" type="text" v-model="udbytteTime" readonly placeholder="输选择分红截止日期" />
				</div>
			</div>
			<div class="bouns_input1" v-if="addUdbytte">
				<div class="input">
					<span>分红金额</span>
					<input type="text" v-model="udbytteMoney" placeholder="输入分红金额" />
				</div>
				<div class="input" @click="holdOpFunction(1)">
					<span>分红日期</span>
					<input type="text" v-model="udbytteTime" readonly placeholder="输选择分红日期" />
				</div>
			</div>
		</div>
		<div class="bouns_button">
			<div class="bouns_buttons">
				<button @click="submit" :disabled="colorRed ? false : true" :style="colorRed ? 'background:#D71518;color:white;' : ''">提交</button>
			</div>
		</div>
		<van-popup v-model="show" position="bottom" :style="{ height: '40%' }">
			<van-datetime-picker v-model="currentDate" type="date" title="选择年月日" @confirm="onChange" :min-date="minDate" />
		</van-popup>
	</div>
</template>
<script>
import publicNavigation from "@/publicNavigation/publicNavigation.vue";
import * as API from '@/api.ts'
export default {
	components: {
		publicNavigation,
	},
	data() {
		return {
			navigation: {},
			minDate: new Date(),
			show: false,
			holdUdveksleTime: '',
			udbytteTime: '',
			currentIndex: 0,
			inputTitle: '',
			ombytningsbeløb: '',
			originDate: '',
			udbytteOriginDate: '',
			projectID: '',
			colorRed:false
		};
	},
	watch: {
		inputTitle(e) {
			if (this.ombytningsbeløb && this.holdUdveksleTime && this.udbytteTime && e) {
				this.colorRed = true
			} else {
				this.colorRed = false
			}
		},
		ombytningsbeløb(e) {
			if (this.inputTitle && this.holdUdveksleTime && this.udbytteTime && e) {
				this.colorRed = true
			} else {
				this.colorRed = false
			}
		},
		holdUdveksleTime(e) {
			if (this.inputTitle && this.ombytningsbeløb && this.udbytteTime && e) {
				this.colorRed = true
			} else {
				this.colorRed = false
			}
		},
		udbytteTime(e) {
			if(!this.addUdbytte){
				if (this.inputTitle && this.ombytningsbeløb && this.holdUdveksleTime && e) {
					this.colorRed = true
				} else {
					this.colorRed = false
				}
			}else if(this.udbytteMoney && e){
				this.colorRed = true
			}else{
				this.colorRed = false
			}
			
		},
		udbytteMoney(e){
			if(this.udbytteTime && e){
				this.colorRed = true
			}else{
				this.colorRed = false
			}
		}
	},
	mounted() {
		this.navigation = {
			image: require("../assets/return.png"),
			name: this.addUdbytte ? "新建分红" : "添加PC值项目",
			close: ''
		};
		this.addUdbytte = this.$route.query.addUdbytte
		this.projectID = this.$route.query.projectID
		console.log(this.$route.query.s)
		if(this.$route.query.s){
			this.inputTitle = this.$route.query.s.title
			this.holdUdveksleTime = this.$route.query.s.divvy_time_text
			this.udbytteTime = this.$route.query.s.exchange_time_text
		}
	},
	methods: {
		submit() {
			if (!this.addUdbytte) {
				const parms = {
					name: this.inputTitle,
					exchange: this.ombytningsbeløb,
					exchange_time: this.originDate.valueOf() / 1000,
					divvy_time: this.udbytteOriginDate.valueOf() / 1000
				}
				if (parms.name && parms.exchange && parms.exchange_time && parms.divvy_time) {
					API.addCp(parms).then(async e => {
						this.$toast({
							type: e.code == 1 ? 'success' : 'fail',
							message: e.msg
						})
						if (e.code == 1) {
							setTimeout(() => { this.$router.back(-1) },800)
						}
					})
				} else {
					this.$toast({
						type: 'fail',
						message: '信息不能为空'
					})
				}
			} else {
				const parms = {
					id: this.projectID,
					divvy: this.udbytteMoney,
					divvy_time: this.originDate.valueOf() / 1000
				}
				API.divvyAdd(parms).then(async e => {
					this.$toast({
						type: e.code == 0 ? 'fail' : 'success',
						message: e.msg
					})
					if (e.code == 1) {
						this.udbytteMoney = ''
						this.originDate = ''
						setTimeout(() => { this.$router.back(-1) },800)
					}
				})
			}

		},
		holdOpFunction(e) {
			this.show = !this.show
			this.currentIndex = e
		},
		onChange(date) {
			const times = this.formatDate(date)
			if (this.currentIndex == 1) {
				this.udbytteTime = times
				this.originDate = date
			} else {
				this.holdUdveksleTime = times
				this.udbytteOriginDate = date
			}
			this.holdOpFunction()
		},
		formatDate(date) {
			const year = date.getFullYear()
			const mont = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
			const monteh = (date.getDate()) < 10 ? '0' + date.getDate() : date.getDate()
			return `${year + '-' + mont + '-' + monteh}`;
		},
	}
};
</script>
<style lang="scss" scoped>
.bouns{
	background: #FFF;
	min-height: 100vh;
}
// 继承变量
.alignItemsCenter {
	display: flex;
	align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
	@return calc($number * 2 / 100) + rem;
}

.bouns_input_list {
	margin-top: calcFunction(10);

	.bouns_input {
		margin: 0 calcFunction(15);

		.input {
			@extend .alignItemsCenter;
			padding-bottom: calcFunction(18);
			margin-bottom: calcFunction(18);
			background: #FFF !important;
			border-bottom: 1px solid rgba(220, 220, 220, .6);

			span {
				width: 30%;
				text-align: left;
				font-size: calcFunction(15);
			}

			input {
				margin-left: calcFunction(10);
				border: none;
				outline: none;
				font-size: calcFunction(14);
			}
		}
	}
	.bouns_input1 {
		margin: 0 calcFunction(15);

		.input {
			@extend .alignItemsCenter;
			padding-bottom: calcFunction(18);
			margin-bottom: calcFunction(18);
			border-bottom: 1px solid rgba(220, 220, 220, .6);

			span {
				width: 30%;
				text-align: left;
				font-size: calcFunction(15);
			}

			input {
				margin-left: calcFunction(10);
				border: none;
				outline: none;
				font-size: calcFunction(14);
			}
		}
	}
}

.bouns_button {
	position: fixed;
	bottom: 0;
	width: 100%;

	.bouns_buttons {
		padding: calcFunction(20);

		button {
			width: 100%;
			background: #FFB5B6;
			color: white;
			padding: calcFunction(10);
			border: none;
			outline: none;
			border-radius: calcFunction(8);
		}
	}
}
</style>