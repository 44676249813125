<template>
  <div class="pris_detaljer_content_list">
    <div class="pris_detaljer_fordata">
      <ul>
        <li v-for="(s, k) in detaljerData" :key="k">
          <div class="pris_detaljer_header_titles">
            <span>{{ s.memo }}</span>
          </div>
          <div class="pris_detaljer_header_description">
            <span class="time">{{ s.createtime_text }}</span>
            <span class="money" :style="s.type == 1 ? '' : 'color:#666666;'">
              {{s.type == 1 ? "+" + s.reward : "-" + s.reward }}
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    detaljerData: Array,
    height: Number,
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}
.pris_detaljer_content_list {
  padding: 0 0.3rem 0.3rem;
  .pris_detaljer_fordata {
    background: #fff;
    ul {
      li {
        padding: 0.25rem 0.3rem;
        border-bottom: 1px solid #eeeeee;
        .pris_detaljer_header_titles {
          @extend .alignItemsCenter;
          span {
            color: #333333;
            font-size: 0.28rem;
          }
        }
        .pris_detaljer_header_description {
          margin-top: 0.2rem;
          @extend .alignItemsCenter;
          justify-content: space-between;
          .time {
            color: #999999;
            font-size: 0.24rem;
          }
          .money {
            font-size: 0.28rem;
            color: #ff0004;
          }
        }
      }
    }
  }
}
</style>
