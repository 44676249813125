import { render, staticRenderFns } from "./reviewClick.vue?vue&type=template&id=5efea555&scoped=true&"
import script from "./reviewClick.vue?vue&type=script&lang=js&"
export * from "./reviewClick.vue?vue&type=script&lang=js&"
import style0 from "./reviewClick.vue?vue&type=style&index=0&id=5efea555&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5efea555",
  null
  
)

export default component.exports