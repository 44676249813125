<!--
 * @Author: Mr.Cheng
 * @Date: 2023-10-25 09:49:42
 * @LastEditTime: 2023-10-25 18:05:50
 * @FilePath: \octopus-factory-map\src\new\createProject\createProject.vue
-->
<template>
  <div>
    <div class="top-bg-box">
      <!-- 顶部标题 -->
      <div class="flex-c-c log-navigate-box">
        <!-- 返回按钮 -->
        <img
          @click="$router.go(-1)"
          class="log-left-back"
          src="@/assets/whitereturn.png"
          alt=""
        />
        <div>发布项目</div>
      </div>
      <!-- 编辑项目表单 -->
      <div class="log-form">
        <!-- 项目标题 -->
        <van-cell-group>
          <van-field
            v-model="title"
            size="large"
            type="text"
            label="项目标题"
            placeholder="请输入项目标题"
          >
          </van-field>
        </van-cell-group>
        <!-- 项目类型 -->
        <van-cell-group class="flex-c-b">
          <van-field
            readonly
            @click="projectTypesShow = true"
            v-model="project_type_text"
            size="large"
            type="text"
            label="项目类型"
            placeholder="请选择项目类型"
          >
          </van-field>
          <img class="rightFill" src="@/assets/rightFill.png" alt="" />
        </van-cell-group>
        <!-- 项目附件 -->
        <template>
          <van-cell-group
            v-if="filkeList.length == 0"
            class="flex-c-b"
            style="align-items: flex-start"
          >
            <van-uploader
              accept="file"
              result-type="file"
              :after-read="uploadingEvent"
              :max-count="4"
              multiple
            >
              <template #default>
                <van-field
                  size="large"
                  label="项目附件"
                  placeholder="请添加附件"
                >
                </van-field>
              </template>
            </van-uploader>

            <img
              class="addbuttons mr10 mt15"
              src="@/assets/addbuttons.png"
              alt=""
            />
          </van-cell-group>
          <van-cell-group v-else>
            <template>
              <van-uploader
                accept="file"
                result-type="file"
                :after-read="uploadingEvent"
                :max-count="4"
                multiple
              >
                <template #default>
                  <div class="flex-c-b">
                    <van-field size="large" label="项目附件"> </van-field>
                    <img
                      class="addbuttons mr10"
                      src="@/assets/addbuttons.png"
                      alt=""
                    />
                  </div>
                </template>
              </van-uploader>
              <div
                class="fieldListSty flex-c-b"
                v-for="(item, index) of filkeList"
                :key="index"
              >
                <div class="textOver1" style="width: 80%">{{ item.url }}</div>
                <img
                  @click.stop="deleteFile(index)"
                  class="redoffsetIocn"
                  src="@/assets/redoffsetIocn.png"
                  alt=""
                />
              </div>
            </template>
          </van-cell-group>
        </template>
        <!-- 项目图片 -->
        <template>
          <van-cell-group
            v-if="imgList.length == 0"
            class="flex-c-b"
            style="align-items: flex-start"
          >
            <van-uploader
              accept=".jpg,.jpeg,.png"
              result-type="file"
              :after-read="uploadingEventFile"
              :max-count="4"
              multiple
            >
              <template #default>
                <van-field
                  size="large"
                  label="项目图片"
                  placeholder="请添加图片"
                >
                </van-field>
              </template>
            </van-uploader>

            <img
              class="addbuttons mt15 mr10"
              src="@/assets/addbuttons.png"
              alt=""
            />
          </van-cell-group>
          <van-cell-group v-else>
            <template>
              <van-uploader
                accept="file"
                result-type="file"
                :after-read="uploadingEventFile"
                :max-count="4"
                multiple
              >
                <template #default>
                  <div class="flex-c-b">
                    <van-field size="large" label="项目图片"> </van-field>
                    <img
                      class="addbuttons mr10"
                      src="@/assets/addbuttons.png"
                      alt=""
                    />
                  </div>
                </template>
              </van-uploader>
              <div
                class="fieldListSty flex-c-b"
                v-for="(item, index) of imgList"
                :key="index"
              >
                <div class="textOver1" style="width: 80%">{{ item.url }}</div>
                <img
                  @click.stop="deleteImage(index)"
                  class="redoffsetIocn"
                  src="@/assets/redoffsetIocn.png"
                  alt=""
                />
              </div>
            </template>
          </van-cell-group>
        </template>
        <!-- 简介 -->
        <van-cell-group>
          <van-field
            type="textarea"
            v-model="introduce"
            size="large"
            label="简介"
            placeholder="请输入简介"
          >
          </van-field>
        </van-cell-group>
      </div>
      <div class="style"></div>
    </div>

    <div class="bottom-box">
      <div
        @click="submit"
        :style="isSubmit ? '' : 'background:#FFB5B6'"
        class="submit flex-c-c"
      >
        提交
      </div>
    </div>

    <!-- 项目类型弹窗 -->
    <van-popup
      v-model="projectTypesShow"
      round
      position="bottom"
      :style="{ height: '40%' }"
    >
      <van-picker
        show-toolbar
        :columns="projectTypeList"
        value-key="name"
        @confirm="onConfirm"
        @cancel="projectTypesShow = false"
      />
    </van-popup>
  </div>
</template>
  
  <script>
import axios from "axios";

import * as API from "@/api.ts";
export default {
  name: "createProject",
  data() {
    return {
      project_title: "", //项目名称
      project_id: "", //项目id
      title: "", //任务标题
      projectTypeList: [], //项目类型列表
      projectTypesShow: false, //项目类型弹窗显隐
      project_type_text: "", //项目类型显示
      project_type: "", //项目类型id
      introduce: "", //简介
      filkeList: [],
      fileList: [], //附件 参数
      annex: [],
      imgList: [], //图片显示列表
      images: [], //图片参数
      details: {}, //详情数据

      isSubmit: false, //是否能提交
    };
  },
  watch: {
    title() {
      //校验必填项是否填写完整
      this.asyncValidator();
    },
    project_type() {
      //校验必填项是否填写完整
      this.asyncValidator();
    },
  },
  mounted() {
    // 获取项目类型列表
    this.getProjectTypeList();
  },
  activated() {
    //校验必填项是否填写完整
    this.asyncValidator();
    //校验必填项是否填写完整
    this.asyncValidator();
  },
  deactivated() {
    localStorage.clear("sure");
  },
  methods: {
    // 确认项目类型
    onConfirm(e) {
      this.project_type = e.id;
      this.project_type_text = e.name;
      this.projectTypesShow = false;
    }, // 提交
    async submit() {
      if (!this.isSubmit) {
        return;
      }
      let { title, fileList, images, introduce, project_type } = this;
      // 发布
      let params = {
        title,
        project_type,
        attachment:
          (Array.isArray(fileList) && fileList.join(",")) || fileList || "",
        img: (Array.isArray(images) && images.join(",")) || images || "",
        introduce,
      };

      let { code, msg } = await API.projectadd(params);
      this.$toast(msg);
      if (code == 1) {
        this.$router.go(-1);
      }
    },
    //校验必填项是否填写完整
    asyncValidator() {
      let { title, project_type } = this;
      if (title && project_type) {
        this.isSubmit = true;
      } else {
        this.isSubmit = false;
      }
    },
    // 获取项目类型列表
    async getProjectTypeList() {
      let { code, data } = await API.typeList();
      if (code == 1) {
        this.projectTypeList = data.rows;
      }
    },
    // 上传文件
    async uploadFile(i, params) {
      if (i == params.length) {
        return;
      }
      let data = new FormData();
      let config = {
        //添加请求头
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      data.set("file", params[i].file);
      const res = await axios.post("/admin/ajax/upload", data, config);
      i++;
      console.log(this.fileList, 1111111);
      this.fileList.push(res.data.data.url);
      this.uploadFile(i, params);
      return res;
    },
    // 上传图片
    async uploadImage(i, params) {
      if (i == params.length) {
        return;
      }
      let data = new FormData();
      let config = {
        //添加请求头
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      data.set("file", params[i].file);
      const res = await axios.post("/admin/ajax/upload", data, config);
      i++;
      this.images.push(res.data.data.url);
      this.uploadImage(i, params);
      return res;
    },
    // 添加附件
    uploadingEvent(file) {
      if (Array.isArray(file)) {
        file.forEach((item) => {
          this.filkeList.push({ url: item.file.name });
        });
        this.uploadFile(0, file);
      } else {
        let list = [file];
        list.forEach((item) => {
          this.filkeList.push({ url: item.file.name });
        });
        this.uploadFile(0, list);
      }
    },
    // 添加图片
    uploadingEventFile(file) {
      if (Array.isArray(file)) {
        file.forEach((item) => {
          this.imgList.push({ url: item.file.name });
        });
        this.uploadImage(0, file);
      } else {
        let list = [file];
        list.forEach((item) => {
          this.imgList.push({ url: item.file.name });
        });
        this.uploadImage(0, list);
      }
    },
    // 删除附件
    deleteFile(index) {
      this.filkeList.splice(index, 1);
      this.fileList.splice(index, 1);
    },
    // 删除图片
    deleteImage(index) {
      this.imgList.splice(index, 1);
      this.images.splice(index, 1);
    },
  },
};
</script>
  
  <style lang="scss" scoped>
::v-deep .van-uploader {
  width: 100%;
}
::v-deep .van-uploader__input-wrapper {
  width: 100%;
}
.rightFill {
  width: 0.4rem;
  margin-right: 0.1rem;
}
.label {
  font-size: 0.28rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #111111;
}
.fieldListSty {
  height: 0.9rem;
  background: #ffeaea;
  border-radius: 0.08rem;
  font-size: 0.28rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #d71518;
  padding: 0.3rem 0.2rem;
  box-sizing: border-box;
  margin: 0.2rem;
}
.style {
  width: 100%;
  height: 2rem;
}
.bottom-box {
  width: 100%;
  height: 1.2rem;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  padding: 0.15rem 0.2rem;
  box-sizing: border-box;
  .submit {
    width: 100%;
    height: 0.9rem;
    background: #d71518;
    border-radius: 0.16rem;
    font-size: 0.34rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
}
.redoffsetIocn {
  width: 0.2rem;
}
.addbuttons {
  width: 0.29rem;
}
.top-bg-box {
  background: url("@/assets/background.png");
  background-size: 100% 100%;
  width: 100vw;
  height: 3rem;

  // 顶部标题
  .log-navigate-box {
    width: 100%;
    height: 0.88rem;
    font-size: 0.34rem;
    font-family: PingFang SC;
    color: #fff;
  }
  .log-left-back {
    width: 0.19rem;
    // height: 0.339rem;
    position: absolute;
    left: 0.4rem;
  }

  // 日志表单
  .log-form {
    margin: 0.2rem;
    background: #ffffff;
    border-radius: 0.16rem;
    overflow: hidden;

    .blackarrow {
      width: 0.08rem;
      height: 0.16rem;
    }
  }
}
</style>