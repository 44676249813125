<!--
 * @Author: Mr.Cheng
 * @Date: 2023-09-01 14:31:55
 * @LastEditTime: 2023-09-26 16:47:32
 * @FilePath: \octopus-factory-map\src\myuserinform\compoments\myToggleList.vue
-->
<template>
  <div class="toggle">
    <div class="toggle_list">
      <div class="toggle_list_check">
        <ul>
          <li
            v-for="(s, k) in toggleList"
            @click="toggleCurrentIndexClick(s, k)"
            :key="k"
          >
            <span>{{ s }}</span>
            <img src="@/assets/myarrow.png" alt="" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    toggleList: Array,
  },
  methods: {
    toggleCurrentIndexClick(s, k) {
      this.$emit("toggleCurrentIndexClick", s, k);
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.toggle {
  margin: 0.2rem;
  .toggle_list {
    .toggle_list_check {
      ul {
        li {
          @extend .alignItemsCenter;
          background-color: white;
          padding: 0.46rem 0.4rem;
          box-sizing: border-box;
          justify-content: space-between;
          span {
            font-size: .3rem;
            color: #333333;
          }
          img {
            width: .104rem;
            height: .178rem;
          }
        }
        li:nth-child(3) {
          margin: .178rem 0;
          background: white;
          padding: 0.46rem 0.4rem;
          box-sizing: border-box;
          border-radius: 0.16rem;
        }
        li:nth-child(1),
        li:nth-child(4) {
          border-top-left-radius: 0.16rem;
          border-top-right-radius: 0.16rem;
          border-bottom: 1px solid #eeeeee;
        }
        li:nth-child(2),
        li:nth-child(5) {
          border-bottom-left-radius: 0.16rem;
          border-bottom-right-radius: 0.16rem;
        }
      }
    }
  }
}
</style>