<template>
  <div class="person_card">
    <div
      class="person_card_data"
      @click="descriptionEvent(s, k)"
      :class="forskellen ? 'maxCustom' : ''"
      v-for="(s, k) in personCard"
      :key="k"
    >
      <div class="person_card_header">
        <div class="person_card_header_left_name">
          <span>{{ s.title ? s.title : s.name }}</span>
          <!-- v-if="s.status == 2 || s.status == 3" -->
          <span>{{ s.status_text }}</span>
        </div>
        <div class="person_card_header_right_button_list">
          <div
            class="person_list"
            :class="personNumbers ? 'personNumbers' : ''"
            v-if="forskellen != 0 || !offset"
          >
            <button v-if="!vrdi" @click="editPersonFunction(s)">编辑</button>
            <button v-if="s.status == 'normal'" @click="offPersonFunction(s)">
              禁用
            </button>
            <button
              v-if="s.status == 'hidden'"
              @click="offPersonFunction(s, 1)"
            >
              启用
            </button>
          </div>
        </div>
        <!--  <div v-if="forskellen > 0" class="is_ok">
                    <span>已完成</span>
                </div> -->
        <div v-if="offset > 0 && s.return" class="is_ok">
          <span>{{ s.return }}</span>
        </div>
      </div>
      <div class="person_card_content">
        <div class="person_card_content_data">
          <div
            class="preson_list_data"
            :class="forskellen != 0 ? 'custom' : ''"
          >
            <div
              class="person_for_data"
              :style="i.name == '邮箱：' ? 'width:100%' : ''"
              :class="
                currentIndex == 2 ? 'minText' : fontSize ? 'customfontSize' : ''
              "
              v-for="(i, l) in s.desc"
              :key="l"
            >
              <span v-if="(l != 6 && l != 5) || i.name == '兑换现金数：'">{{
                i.name
              }}</span>
              <span
                v-if="(l != 6 && l != 5) || i.name == '兑换现金数：'"
                :class="i.name == '兑换现金数：' ? 'fremhæve' : ''"
                >{{ i.text }} <i v-if="i.number">{{ i.number }}</i></span
              >
            </div>
          </div>
          <div class="tilbage">
            <!-- 现金兑换记录 -->
            <div v-show="currentIndex == 0" class="tilbage_button">
              <button v-if="s.status == 1" @click="returnChaneg(s, k)">
                退回
              </button>
              <button @click="Rewardexchange(s, k)" v-if="s.status == 1">
                兑换
              </button>
            </div>
            <!-- 礼品兑换记录 -->
            <div v-show="currentIndex == 1" class="tilbage_button">
              <button v-if="s.status == 1" @click="returnChaneg(s, k)">
                退回
              </button>
              <button
                v-show="currentIndex != 2"
                @click="Rewardexchange(s, k)"
                v-if="s.status == 1"
              >
                兑换
              </button>
            </div>
            <!-- CP值兑换记录 -->
            <div v-show="currentIndex == 2" class="tilbage_button">
              <button
                v-if="s.status == 1 && s.project_status == 1"
                @click="returnChaneg(s, k)"
              >
                退回
              </button>
              <button
                v-show="currentIndex != 2"
                @click="Rewardexchange(s, k)"
                v-if="s.status_text != '已兑换'"
              >
                兑换
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="progress_status" v-if="s.progress && s.progress.length > 0">
        <div class="progress_status_content">
          <div
            class="progress_title"
            style="display: flex; justify-content: center; padding-bottom: 10px"
          >
            <span @click="stepClick(s.check)" style="font-size: 14px"
              >暂开步骤</span
            >
            {{ expandShow }}
            <van-icon
              color="#375394"
              :class="expandShow ? 'active' : 'orgin'"
              name="arrow-down"
            />
          </div>
          <div class="expand" v-show="check">
            <div class="expand_description_content_for">
              <div class="expand_for" v-for="(n, m) in s.progress" :key="m">
                <div class="expand_step_number">
                  <div class="expand_step_number_title">
                    <span>{{ `第${m + 1} 步` }}</span>
                  </div>
                  <div
                    class="expand_step_number_text"
                    style="display: flex; flex-direction: column"
                  >
                    <div
                      class="expadns_setp"
                      style="
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                      "
                    >
                      <div
                        class="expand_step_text_title"
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                        "
                      >
                        <span>{{ n.title }}</span>
                        <span>{{ n.status_text }}</span>
                      </div>
                    </div>
                    <!-- v-if="n.status == 3 || s.status == 2" -->
                    <div class="expadn_step_startTime expadns_step">
                      <div class="time_list">
                        <span>开始时间：</span>
                        <!-- timeFunction(n.createtime) -->
                        <span>{{
                          n.real_starttime_text
                            ? n.real_starttime_text
                            : n.starttime
                            ? n.starttime + "预"
                            : "-"
                        }}</span>
                      </div>
                      <div class="time_list">
                        <span>完成时间：</span>
                        <span>
                          {{
                            n.real_endtime_text
                              ? n.real_endtime_text
                              : n.endtime
                              ? n.endtime + "预"
                              : "-"
                          }}
                          <!-- {{ timeFunction(n.endtime) }} -->
                          <i
                            v-if="n.real_endtime_text"
                            :style="
                              n.ago
                                ? n.ago.split('')[0] == '提'
                                  ? 'color:green;'
                                  : 'color:red;'
                                : ''
                            "
                            >{{ n.ago ? "(" + n.ago + ")" : n.ago }}</i
                          >
                        </span>
                      </div>
                      <div class="time_list">
                        <span>工作量：{{ n.workday ? n.workday : "-" }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="loading_more" v-if="more">
			<div class="loding_more_data">
				<span @click="moreClick">点击加载更多</span>
			</div>
		</div> -->
  </div>
</template>
<script>
export default {
  props: {
    personCard: Array || [],
    forskellen: Number || String,
    offset: String,
    currentIndex: Number,
    fontSize: String,
    vrdi: Number,
    more: Number,
    personNumbers: Number,
  },
  data() {
    return {
      check: false,
    };
  },
  methods: {
    descriptionEvent(s, k) {
      this.$emit("descriptionEvent", s, k);
    },
    Rewardexchange(s, k) {
      this.$emit("Rewardexchange", s, k);
    },
    moreClick() {
      this.$emit("moreClick");
    },
    editPersonFunction(s) {
      this.$emit("editPersonFunction", s);
    },
    offPersonFunction(s, c) {
      this.$emit("offPersonFunction", s, c);
    },
    returnChaneg(s) {
      this.$emit("returnChaneg", s);
    },
    stepClick() {
      this.check = !this.check;
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.person_card {
  .person_card_data {
    border: 1px solid #dddddd;
    background: #fafafa;
    padding: 0 calcFunction(15);
    padding-top: calcFunction(11);
    margin-top: calcFunction(10);
    border-radius: calcFunction(4);

    .person_card_header {
      border-bottom: 1px solid #dddddd;
      padding-bottom: calcFunction(10);
      @extend .alignItemsCenter;
      justify-content: space-between;

      .person_card_header_left_name {
        @extend .alignItemsCenter;
        justify-content: space-between;
        width: 100%;

        span {
          color: #333333;
          font-size: calcFunction(14);
          font-weight: bold;
          white-space: nowrap;
        }

        span:nth-child(2) {
          font-size: calcFunction(12);
          font-weight: 400;
        }
      }

      .person_card_header_right_button_list {
        .person_list {
          button {
            font-size: calcFunction(13);
            padding: calcFunction(4.5) calcFunction(13);
            box-sizing: border-box;
            border: 1px solid #999999;
            background: transparent;
            border-radius: calcFunction(2);
            color: #666666;
          }

          button:nth-child(1) {
            margin-right: calcFunction(10);
            color: #000000;
          }
        }
      }
    }

    .person_card_content {
      padding-bottom: calcFunction(14.5);

      .person_card_content_data {
        .preson_list_data {
          // @extend .alignItemsCenter;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-wrap: wrap;

          .person_for_data {
            margin-top: calcFunction(15);

            span:nth-child(1) {
              color: #999999;
              font-size: calcFunction(14);
            }

            span {
              color: #111111;
              font-size: calcFunction(13);
            }
          }
        }
      }
    }
  }
}

.loading_more {
  padding-top: calcFunction(16);

  .loding_more_data {
    @extend .alignItemsCenter;
    justify-content: center;

    span {
      font-size: calcFunction(14);
      color: #999;
    }
  }
}

.fremhæve {
  color: #d71518 !important;
}

.personNumbers {
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    white-space: nowrap;
    font-size: calcFunction(10) !important;
  }
}

.arrow-down,
.active {
  transform: rotate(90deg);
  transition-duration: 0.5s;
}

.expand_description_content_for {
  .expand_for {
    margin-top: calcFunction(15);

    .expand_step_number {
      .expand_step_number_title {
        position: relative;
        margin-bottom: calcFunction(10);

        span {
          font-size: calcFunction(14);
          color: #666666;
          padding-left: calcFunction(20);
        }

        &::after {
          width: calcFunction(10);
          height: calcFunction(10);
          background: #d71518;
          content: "";
          position: absolute;
          border-radius: calcFunction(100);
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .expand_step_number_text {
        padding: calcFunction(14) calcFunction(15);
        border: 1px solid #dddddd;
        background: #fafafa;
        border-radius: calcFunction(4);
        @extend .alignItemsCenter;
        justify-content: space-between;

        span {
          font-size: calcFunction(13);
          font-weight: bold;
          color: #111111;
        }

        button {
          border: 1px solid #d71518;
          padding: calcFunction(4) calcFunction(10);
          font-size: calcFunction(13);
          color: #d71518;
          border-radius: calcFunction(4);
          background: white;
        }
      }
    }
  }
  .expand_for:nth-last-child(1) {
    padding-bottom: 10px;
  }
}

.expadns_step {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;

  div {
    width: 50%;
    margin-bottom: 10px;

    span {
      font-weight: 400 !important;
      font-size: 14px;
    }
  }
}

.complete {
  flex-direction: column;
  align-items: flex-start !important;

  .expadn_step_startTime {
    @extend .alignItemsCenter;
    justify-content: space-between;
    width: 100%;
    margin-top: calcFunction(20);

    span {
      font-weight: 500 !important;
      color: #999999 !important;
    }

    span:nth-last-child(1) {
      color: black !important;
    }
  }
}
</style>
