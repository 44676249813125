<template>
  <div>
    <div class="top-box">
      <!-- 顶部标题 -->
      <div class="flex-c-c task-navigate-box">
        <!-- 返回按钮 -->
        <img
          @click="$router.go(-1)"
          class="task-left-back"
          src="@/assets/return.png"
          alt=""
        />
        <div>项目列表</div>
      </div>

      <!-- 搜索框-->
      <van-search
        v-model="search"
        @clear="clear"
        @search="onSearch"
        shape="round"
        background="#FFF"
        placeholder="输入项目关键字查询"
      />
      <!-- 筛选框 -->
      <van-dropdown-menu custom-class="menu-box">
        <!-- 时间 -->
        <van-dropdown-item ref="dateDom">
          <template #title>
            <div v-if="start_time && start_time != end_time">
              <div>{{ start_time }} ~</div>
              <div>{{ end_time }}</div>
            </div>
            <div v-else-if="start_time && start_time == end_time">
              <div>{{ start_time }}</div>
            </div>
            <div v-else>选择时间</div>
          </template>
          <div>
            <van-calendar
              @confirm="confirmDate"
              :show-title="false"
              type="range"
              :poppable="false"
              :min-date="new Date(2023, 0, 1)"
              :max-date="new Date()"
              :allow-same-day="true"
              :show-confirm="false"
              style="height: 40vh"
            >
              <template #footer>
                <div @click="clearDate" class="clear flex-c-c">清除</div>
              </template>
            </van-calendar>
          </div>
        </van-dropdown-item>
        <!-- 项目类型 -->
        <van-dropdown-item ref="typeDom">
          <template #title>
            <div>{{ type_text || "项目类型" }}</div>
          </template>
          <div>
            <div
              @click="onConfirmType(item)"
              :class="['list', 'flex-c-c', item.id == type ? 'highlight' : '']"
              v-for="item of typeList"
              :key="item.id"
            >
              {{ item.name }}
            </div>
          </div>
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>

    <!-- 列表 -->
    <van-list
      v-if="list.length > 0"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="project-list-box">
        <div
          @click="
            $router.push({ path: '/projectDetail', query: { id: item.id } })
          "
          class="project-list mb10"
          v-for="item of list"
          :key="item.id"
        >
          <!-- 任务顶部 -->
          <div class="project-list-top">
            <!-- 任务名称&状态 -->
            <div style="align-items: flex-start">
              <div class="task-name mr20">
                {{ item.title }}
              </div>
            </div>
          </div>
          <!-- 任务内容 -->
          <div class="project-list-content">
            <div class="flex-c-b">
              <div class="label">项目类型：</div>
              <div class="value">
                <div>{{ item.project_type_text }}</div>
              </div>
            </div>
            <div class="flex-c-b mt10">
              <div class="label">创建时间：</div>
              <div class="value">{{ item.createtime_text || "--" }}</div>
            </div>
          </div>
        </div>
      </div>
    </van-list>

    <!-- 空列表 -->
    <van-empty v-else description="暂无数据" />
  </div>
</template>

<script>
import * as API from "@/api.ts";

export default {
  data() {
    return {
      search: "", //项目搜索关键字
      list: [], //项目列表
      page: 1,
      limit: 10,
      loading: false, //项目列表接口是否正在请求
      finished: false, //项目列表是否全部加载完
      start_time: "", //日期筛选(开始)显示
      start_text: "", //日期筛选(开始)参数
      end_time: "", //日期筛选(结束)显示
      end_text: "", //日期筛选(结束)参数
      typeList: [], //类型列表
      type: "", //项目类型id
      type_text: "", //项目类型文字
    };
  },

  mounted() {
    // 获取项目列表
    this.getList();
    // 获取项目筛选列表
    this.getTypeList();
  },
  methods: {
    // 获取项目列表
    async getList() {
      this.loading = true;
      let {
        page,
        limit,
        list,
        type,
        start_text: start_time,
        end_text: end_time,
        search,
      } = this;
      let params = {
        page,
        limit,
        type,
        start_time,
        end_time,
        search,
      };
      let { code, data } = await API.projectIndex(params);
      if (code == 1) {
        let _list = data.rows;
        if (page == 1) {
          list = _list;
        } else {
          list.push(..._list);
        }
        if (_list.length == 0) {
          this.finished = true;
        }
        this.list = list;
        console.log(this.list);
        this.loading = false;
      }
    },
    // 获取项目筛选列表
    async getTypeList() {
      let { code, data } = await API.typeList();
      if (code == 1) {
        this.typeList = [{ id: "", name: "全部" }, ...data.rows];
      }
    },
    // 选择时间
    confirmDate(e) {
      let [start, end] = e;
      this.start_time = this.$utils.getDate(start);
      this.end_time = this.$utils.getDate(end);
      this.start_text = Date.parse(start) / 1000;
      this.end_text = Date.parse(end) / 1000 + 86399;
      // 初始化执行任务
      this.init();
      // 获取执行任务列表
      this.getList();
      // 关闭该弹窗
      this.$refs.dateDom.toggle();
    },

    // 初始化执行任务
    init() {
      this.page = 1;
      this.list = [];
      this.loading = false;
      this.finished = false;
    },
    // 清除搜索框
    clear() {
      this.search = "";
      // 初始化执行任务
      this.init();
      // 获取执行任务列表
      this.getList();
    },
    // 清除时间选择
    clearDate() {
      this.start_time = "";
      this.end_time = "";
      this.start_text = "";
      this.end_text = "";
      // 初始化执行任务
      this.init();
      // 获取执行任务列表
      this.getList();
      // 关闭该弹窗
      this.$refs.dateDom.toggle();
    },

    // 搜索
    onSearch(e) {
      this.init();
      this.getList();
      console.log(e);
    },
    // 选择项目类型
    onConfirmType(e) {
      this.type = e.id;
      this.type_text = e.name;
      // 初始化执行任务
      this.init();
      // 获取执行任务列表
      this.getList();
      // 关闭该弹窗
      this.$refs.typeDom.toggle();
    },
    // 上拉加载更多
    onLoad() {
      if (!this.finished) {
        this.page++;
        this.getList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-dropdown-menu__item {
  max-width: unset !important;
}
.top-box {
  position: sticky;
  top: 0;
  z-index: 500;
}
// 筛选选中高亮
.highlight {
  color: #d71518;
}
// 日期清除按钮
.clear {
  color: #fff;
  background-color: #ee0a24;
  border: 1px solid #ee0a24;
  border-radius: 50px;
  height: 0.7rem;
  margin: 0.3rem 0;
}
// 筛选框列表
.list {
  height: 0.8rem;
  border-bottom: 0.01rem solid #f1f1f1;
}

// 项目列表
.project-list-box {
  padding: 0.2rem;
  box-sizing: border-box;

  .project-list {
    width: 100%;
    background: #ffffff;
    border-radius: 0.16rem;
    // 列表盒子顶部
    .project-list-top {
      padding: 0.3rem;
      box-sizing: border-box;
      border-bottom: 0.01rem solid #dddddd;
    }
    // 列表盒子内容
    .project-list-content {
      padding: 0.3rem;
      box-sizing: border-box;
    }
  }
}
</style>