<template>
    <div class="settingRewards" style="background:white;height:100vh;">
        <div class="settingRewards_box">
            <div class="settingRewards_header">
                <publicNavigation navigation-color="white" :data="navigation"/>
            </div>
            <div class="setting_header_bcakground_white">
                <div class="white_header">
                    <img src="../assets/background.png" alt="">
                </div>
            </div>
            <settingCard :cardListData="cardListData" @submitOnchange="submitOnchange" />
        </div>
    </div>
</template>
<script>
import publicNavigation from '@/publicNavigation/publicNavigation.vue';
import settingCard from './compoments/settingCard.vue'
import {settingRewards} from './settingRewards.ts'
import * as API from '@/api.ts'
export default {
    components:{
        publicNavigation,
        settingCard
    },
    data(){
        return{
            navigation:{},
            cardListData:Array,
			newData:''
        }
    },
    mounted(){
        const parms = new settingRewards().init()
        this.cardListData = parms.cardListData
        this.navigation = {
            whiteImage: require("../assets/whiteleft.png"),
            name: "设置奖励点",
			close:''
        };
		if(this.$route?.query){
			this.newData = this.$route.query.e
			this.cardListData = [{
					name:'姓名：',
					text:this.newData.user_nickname
				},{
					name:'工作量：',
					text:this.newData.workload + '天'
				},{
					name:'难度：',
					number:this.newData.difficulty
				},{
					name:'标准奖励点：',
					text:this.newData.reward
				},{
					name:'工期：',
					text:this.newData.timeout_text
				},{
					name:'绩效奖励点：',
					text:this.newData.additional_reward
				},{
					name:'天数：',
					text:this.newData.workload + '天'
				}]
		}
    },
	methods:{
		submitOnchange(e){
			const parms  =  {
				ids:this.newData.id,
				...e
			}
			API.correct_reward(parms).then(async e=>{
				this.$toast({
					type:e.code == 1 ? 'success' : 'fail',
					message:e.msg
				})
				if(e.code == 1){
					this.$router.back(-1)
				}
			})
		}
	}
}

</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter{
    display: flex;
    align-items: center;
}
  
// 计算函数
@function calcFunction($number,$default:16){
  @return calc($number * 2 / 100) + rem;
}

.settingRewards{
    height: 100vh;
    background: #F1F0F5;
    .settingRewards_box{
        .settingRewards_header{
            position: absolute;
            z-index: 2;
            width: 100%;
        }
        .setting_header_bcakground_white{
            width: 100%;
            .white_header{
                position: absolute;
                top: 0;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

</style>