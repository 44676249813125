<template>
  <div>
    <div class="top-bg-box">
      <!-- 顶部标题 -->
      <div class="flex-c-c log-navigate-box">
        <!-- 返回按钮 -->
        <img
          @click="$router.go(-1)"
          class="log-left-back"
          src="@/assets/whitereturn.png"
          alt=""
        />
        <div>填写日志</div>
      </div>
      <!-- 日志表单 -->
      <div class="log-form">
        <!-- <div class="label">选择日期</div>
        <input type="text" placeholder="请选择日志日期" />
        <img class="blackarrow" src="@/assets/blackarrow.png" alt="" /> -->
        <van-cell-group>
          <van-field
            @click="showDate = true"
            v-model="date"
            label="选择日期"
            readonly
            placeholder="请选择日志日期"
          >
            <template #right-icon>
              <img class="blackarrow" src="@/assets/blackarrow.png" alt="" />
            </template>
          </van-field>
        </van-cell-group>
        <van-cell-group>
          <van-field
            @click="isProject ? (showTask = true) : ''"
            :style="!isProject ? 'background: #f5f5f5 !important' : ''"
            v-model="task_text"
            label="关联任务"
            readonly
            placeholder="请选择关联任务"
          >
            <template #right-icon>
              <img class="blackarrow" src="@/assets/blackarrow.png" alt="" />
            </template>
          </van-field>
        </van-cell-group>
        <van-field
          v-model="content"
          rows="8"
          autosize
          label="日志内容"
          type="textarea"
          maxlength="200"
          placeholder="请填写日志内容..."
          show-word-limit
        />
      </div>
    </div>

    <div class="bottom-box">
      <van-button
        @click="submit"
        round
        :color="isSubmit ? '#D71518' : '#FFB5B6'"
        size="large"
        :loading="loading"
        type="info"
        loading-text="提交中..."
        >提交</van-button
      >
    </div>
    <!-- 日期弹窗 -->
    <van-calendar v-model="showDate" :min-date="new Date(2023, 0, 1)" @confirm="onConfirmDate" />
    <van-popup
      v-model="showTask"
      round
      closeable
      position="bottom"
      :style="{ height: 'auto' }"
    >
      <div class="taskList">
        <div class="title">任务列表</div>
        <van-search
          v-model="search"
          @input="taskSearch"
          placeholder="请输入任务名称"
        />
        <div class="list">
          <div
            @click="selectTask(item)"
            class="list-content"
            v-for="item of list"
            :key="item.id"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import * as API from "@/api.ts";
import utils from "../../utils/utils";

export default {
  data() {
    return {
      task_text: "", //任务标题
      content: "", //日志内容
      date: "", //日期（显隐）
      dailyplantime: "", //日期（参数）
      type: 2, //类型 1计划 2日志
      showDate: false, //日期弹窗显隐
      loading: false, //是否正在提交
      isSubmit: false, //是否将信息填写完整
      isProject: false, //是否是项目
      showTask: false, //任务选择弹窗显隐
      list: [], //任务列表
      search: "", //任务搜索关键词
      isLoading: false, //任务列表是否正在加载
    };
  },
  watch: {
    date(newVal) {
      if (newVal && this.content) {
        this.isSubmit = true;
      } else {
        this.isSubmit = false;
      }
    },
    content(newVal) {
      if (newVal && this.date) {
        this.isSubmit = true;
      } else {
        this.isSubmit = false;
      }
    },
  },
  mounted() {
    let { task, isProject } = this.$route.query;
    if (!isProject) {
      this.task_text = task;
    }
    this.isProject = isProject;
    // 获取任务列表
    this.getTaskList();
  },
  methods: {
    // 确认日期
    onConfirmDate(value) {
      this.date = this.$utils.getDate(value);
      this.dailyplantime = value.setHours(0, 0, 0, 0) / 1000;
      this.showDate = false;
    },
    selectTask(item) {
      let { id, title } = item;
      this.task = id;
      this.task_text = title;
      this.showTask = false;
    },
    //搜索
    taskSearch: utils.debounce(
      function () {
        this.page = 1;
        // 获取任务列表
        this.getTaskList();
      },
      400,
      false
    ),
    // 获取任务列表
    async getTaskList() {
      let { search } = this;
      let params = {
        id: this.$route.query.id,
        search,
      };
      let { code, data } = await API.projectTask(params);
      if (code == 1) {
        this.list = data.rows;
      }
    },
    // 提交
    async submit() {
      if (!this.isSubmit) {
        return;
      }
      this.loading = true;
      let { dailyplantime, content, isProject, task } = this;
      let params = {
        task: !isProject ? this.$route.query.id : task,
        content,
        dailyplantime,
        type: 2, //类型 1计划 2日志
      };
      let { code } = await API.Dailyplan(params);
      if (code == 1) {
        this.loading = false;
        this.$toast.success("提交成功");
        this.$router.go(-1);
      } else {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 0.28rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #111111;
}
.top-bg-box {
  background: url("@/assets/background.png");
  background-size: 100% 100%;
  width: 100vw;
  height: 3rem;

  // 顶部标题
  .log-navigate-box {
    width: 100%;
    height: 0.88rem;
    font-size: 0.34rem;
    font-family: PingFang SC;
    color: #fff;
  }
  .log-left-back {
    width: 0.19rem;
    // height: 0.339rem;
    position: absolute;
    left: 0.4rem;
  }

  // 日志表单
  .log-form {
    margin: 0.2rem;
    background: #ffffff;
    border-radius: 0.16rem;
    overflow: hidden;

    .blackarrow {
      width: 0.08rem;
      height: 0.16rem;
    }
  }
}

// 任务列表
.taskList {
  padding: 0.2rem;
  box-sizing: border-box;
  // 列表标题
  .title {
    padding: 0.2rem 0;
    box-sizing: border-box;
    font-size: 0.32rem;
  }
  .list {
    height: 40vh;
    overflow: auto;
    padding: 0 0.2rem;
    box-sizing: border-box;
    .list-content {
      padding: 0.2rem 0;
      box-sizing: border-box;
      font-size: 0.3rem;
      border-bottom: 0.01rem solid #fafafa;
    }
  }
}

// 底部提交盒子
.bottom-box {
  width: 100vw;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  padding: 0.15rem 0.2rem;
  box-sizing: border-box;
}
</style>