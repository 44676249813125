<!--
 * @Author: Mr.Cheng
 * @Date: 2023-09-01 14:31:55
 * @LastEditTime: 2023-09-08 12:07:17
 * @FilePath: \octopus-factory-map\src\isPerson\isPerson.vue
-->
<template>
  <div class="setting_account" style="background:white;height:100vh;">
    <div class="setting_account_content">
      <div class="setting_account_content_box">
        <div class="setting_account_content_header">
          <publicNavigation :data="navigation" />
        </div>
        <div class="tetx_box" style="margin: 20px">
          <span v-if="iconNumber" v-html="content.user_agreement"></span>
          <span v-else v-html="content.privacy_policy"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import publicNavigation from "@/publicNavigation/publicNavigation.vue";
import * as API from "@/api.ts";
export default {
  components: {
    publicNavigation,
  },
  data() {
    return {
      navigation: {},
      iconNumber: "",
      content: {}, //页面数据
    };
  },
  methods: {
    //获取页面数据
    getData() {
      API.introduceList().then((res) => {
        if (res.code == 1) {
          this.content = res.data;
          this.content.user_agreement = this.content.user_agreement.replace(
            "<img",
            "<img style='height: auto;width: 100%;'"
          );
          this.content.privacy_policy = this.content.privacy_policy.replace(
            "<img",
            "<img style='height: auto;width: 100%;'"
          );
          console.log(this.content);
        }
      });
    },
  },
  mounted() {
    this.iconNumber = this.$route.query.numbers
      ? this.$route.query.numbers
      : "";

    this.navigation = {
      image: require("@/assets/return.png"),
      name: !this.iconNumber ? "隐私政策" : "用户协议",
      close: "",
    };
    if (window.sessionStorage.getItem("userInfo")) {
      this.person_mobile = JSON.parse(
        window.sessionStorage.getItem("userInfo")
      ).mobile;
    }
    //获取页面数据
    this.getData();
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}
.setting_account_content_header {
  position: sticky;
  top: 0;
  background: #FFF;
}
</style>
