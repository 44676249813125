<!--
 * @Author: Mr.Cheng
 * @Date: 2023-10-10 14:25:06
 * @LastEditTime: 2023-10-31 09:57:47
 * @FilePath: \octopus-factory-map\src\new\project\projectDetail.vue
-->
<template>
  <div>
    <!-- 顶部背景 -->
    <div class="top-bg">
      <!-- 顶部标题 -->
      <div
        style="background: unset; border-bottom: unset"
        class="flex-c-c task-navigate-box"
      >
        <!-- 返回按钮 -->
        <img
          @click="navigateBack"
          class="task-left-back"
          src="@/assets/return.png"
          alt=""
        />
        <div>项目详情</div>
      </div>
      <!-- 项目名 -->
      <div style="align-items: flex-start" class="flex-c-b mt20">
        <div class="task-name">{{ details.title }}</div>
      </div>
      <!-- 类型 -->
      <div class="flex-c mt10">
        <!-- 类型 -->
        <div class="mr40">
          <span class="label">类型：</span>
          <span class="type_text">{{ details.project_type_text }}</span>
        </div>
        <!-- 创建时间 -->
        <div class="mr40">
          <span class="label">创建时间：</span>
          <span class="type_text">{{ details.create_time_text }}</span>
        </div>
      </div>
    </div>

    <!-- 任务基本信息 归属项目~任务附件 -->
    <div class="basicInfo">
      <!-- 项目简介 -->
      <div style="align-items: flex-start" class="flex-c">
        <div class="label">项目简介：</div>
        <div style="white-space: pre-line" class="value flex-c">
          {{ details.introduce || "无" }}
          <img
            @click="
              introduce = details.introduce;
              editIntroduceShow = true;
            "
            class="ml5"
            style="width: 0.26rem"
            src="@/assets/edit.png"
            alt=""
          />
        </div>
      </div>
      <!-- 项目附件 -->
      <div style="align-items: flex-start" class="flex-c mt10">
        <div class="label">项目附件：</div>
        <div>
          <div v-if="details.attachment_text.length > 0">
            <div
              class="flex-c mb5"
              v-for="item of details.attachment_text"
              :key="item.id"
            >
              <a
                :href="item.url"
                target="blank"
                style="max-width: 5rem; display: block"
                class="line blue textOver1"
              >
                {{ item.name }}
              </a>
              <img
                @click="deleteFile(item, 1)"
                class="reddelete"
                src="@/assets/reddelete.png"
                alt=""
              />
            </div>
          </div>
          <div v-else class="value">无</div>
          <van-uploader
            accept="file"
            result-type="file"
            :after-read="uploadingEvent"
            :max-count="1"
            multiple
          >
            <template #default>
              <div class="flex-c">
                <img class="red-add" src="@/assets/appendix.png" alt="" />
                <div class="add-text">添加附件</div>
              </div>
            </template>
          </van-uploader>
        </div>
      </div>
      <!-- 项目图片 -->
      <div style="align-items: flex-start" class="flex-c mt10">
        <div class="label">项目图片：</div>
        <div>
          <div v-if="details.img_text.length > 0">
            <div
              class="flex-c mb5"
              v-for="item of details.img_text"
              :key="item.id"
            >
              <a
                :href="item.url"
                target="blank"
                style="max-width: 5rem; display: block"
                class="line blue textOver1"
              >
                {{ item.name }}
              </a>
              <img
                @click="deleteFile(item, 2)"
                class="reddelete"
                src="@/assets/reddelete.png"
                alt=""
              />
            </div>
          </div>
          <div v-else class="value">无</div>
          <van-uploader
            accept=".jpg,.jpeg,.png"
            result-type="file"
            :after-read="uploadingEventFile"
            :max-count="1"
            multiple
          >
            <template #default>
              <div class="flex-c">
                <img class="red-add" src="@/assets/appendix.png" alt="" />
                <div class="add-text">添加图片</div>
              </div>
            </template>
          </van-uploader>
        </div>
      </div>
      <!-- 关联任务 -->
      <div style="align-items: flex-start" class="flex-c mt10">
        <div class="label">关联任务：</div>
        <div style="white-space: pre-line" class="value">
          {{ details.correlation_count || 0 }}个
        </div>
      </div>
    </div>

    <van-tabs
      @change="changeNav"
      v-model="active"
      animated
      title-active-color="#D71518"
    >
      <van-tab :title="`任务(${totalTask})`">
        <van-list
          v-model="loadingTask"
          :finished="finishedTask"
          :finished-text="list.length > 0 ? '没有更多了' : ''"
          @load="onLoadTask"
        >
          <task :list="list" :total="totalTask" />
        </van-list>
      </van-tab>
      <van-tab title="项目日志">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="logList.length > 0 ? '没有更多了' : ''"
          offset="150"
          @load="onLoad"
        >
          <!-- writePersonShow 填写人筛选弹窗显隐 -->
          <!-- showDate 日期筛选弹窗显隐 -->
          <!-- clearWrite 清除填写人筛选 -->
          <!-- clearDate 清除日期筛选 -->
          <!-- toWriteLog 跳转填写日志页面 -->
          <log
            @writePersonShow="writePersonShow = true"
            @showDate="showDate = true"
            @clearWrite="clearWrite"
            @clearDate="clearDate"
            @toWriteLog="toWriteLog"
            :date="date"
            :list="logList"
            :writePerson="writePerson"
          />
        </van-list>
      </van-tab>
      <van-tab title="项目奖励">
        <singleReward
          ref="singleRewardDOM"
          @correctShow="correctEvent"
          :id="$route.query.id"
        />
      </van-tab>
    </van-tabs>

    <!-- 详情页 -->
    <template>
      <!-- 任务介绍 -->
      <van-dialog
        v-model="editIntroduceShow"
        title="任务介绍"
        show-cancel-button
        @confirm="editIntroduceConfirm"
      >
        <div class="process_pause_title">
          <input type="text" v-model="introduce" placeholder="请输入任务介绍" />
        </div>
      </van-dialog>
    </template>

    <!-- 任务日志 -->
    <template>
      <!-- 填写人 -->
      <van-popup
        v-model="writePersonShow"
        position="bottom"
        round
        :style="{ height: '30%' }"
      >
        <van-picker
          title="填写人"
          show-toolbar
          :columns="inputPersonTotaslArray"
          value-key="nickname"
          @confirm="onWriteConfirm"
          @cancel="writePersonShow = false"
        />
      </van-popup>

      <!-- 日期弹窗 -->
      <van-calendar
        :min-date="new Date(2023, 0, 1)"
        :max-date="new Date()"
        v-model="showDate"
        @confirm="onDateConfirm"
      />
    </template>
  </div>
</template>
  
<script>
import * as API from "@/api.ts";
import axios from "axios";
import task from "./components/task.vue"; //任务
import log from "./components/log.vue"; //日志
import singleReward from "./components/singleReward.vue"; //奖励
export default {
  name: "projectDetail",
  components: {
    task,
    log,
    singleReward,
  },
  data() {
    return {
      /**
       * 共用
       */
      details: {
        attachment_text: [],
        img_text: [],
      }, //详情数据
      active: 0, //当前选中栏下标
      editIntroduceShow: false, //修改任务介绍弹窗显隐
      introduce: "", //任务介绍内容

      //   任务
      limit: 10,
      page: 1,
      list: [], //项目任务列表
      loadingTask: false, //执行任务列表接口是否正在请求
      finishedTask: false, //执行任务是否全部加载完
      totalTask: 0, //任务数量

      /**
       * 项目日志
       * */
      writePersonShow: false, //填写人弹窗显隐
      showDate: false, //日期弹窗显隐
      inputPersonTotaslArray: [], //填写人弹窗列表
      writePerson: "", //选中的填写人
      user_id: "", //选中的填写人id
      date: "", //选中的日期(页面显示)
      search_time: "", //选中的日期(请求参数)
      loading: false,
      finished: false,
      logPage: 1,
      logLimit: 10,
      logList: [], //日志列表
    };
  },
  mounted() {
    this.active = Number(sessionStorage.getItem("newProjectActive"));
    //获取项目详情
    this.getDetail();
    // 获取项目日志
    this.getProjectLog();
    //获取填写人列表数据
    this.taskLogUser();
    // 获取项目任务
    this.getProjectTask();
  },
  methods: {
    // 返回上一页
    navigateBack() {
      this.$router.go(-1);
      sessionStorage.setItem("newProjectActive", "");
    },
    // 修改项目简介
    async editIntroduceConfirm() {
      let params = {
        ids: this.details.id,
        introduce: this.introduce,
      };
      let { code, msg } = await API.projectdEdit(params);
      this.$toast(msg);
      if (code == 1) {
        this.editIntroduceShow = false;
        // 获取详情数据
        this.getDetail();
      }
    },
    // 修正奖励点弹窗
    correctEvent(item) {
      this.correctShow = true;
      this.correctData = item;
    },
    // 获取项目任务
    async getProjectTask() {
      this.loadingTask = true;
      let { page, limit, list } = this;
      let { id } = this.$route.query;
      let params = {
        id,
        page,
        limit,
      };
      let { code, data } = await API.projectTask(params);
      if (code == 1) {
        let _list = data.rows;
        this.totalTask = data.total;
        if (page == 1) {
          list = _list;
        } else {
          list.push(..._list);
        }
        if (_list.length == 0) {
          this.finishedTask = true;
        }
        list.forEach((item) => {
          item.isOpen = false;
        });
        this.list = list;
        this.loadingTask = false;
      } else {
        this.loadingTask = false;
      }
      console.log(this.loadingTask);
    },
    // 上拉加载更多(任务)
    onLoadTask() {
      this.page++;
      this.getProjectTask();
    },
    //获取填写人列表数据
    taskLogUser() {
      API.taskLogUser({
        id: this.$route.query.id,
        type: 2,
      }).then(async (e) => {
        if (e.code == 1) {
          this.inputPersonTotaslArray = e.data;
          this.inputPersonTotaslArray.unshift({ nickname: "全部", id: "" });
        }
      });
    },
    // 确定填写人
    onWriteConfirm(value) {
      this.user_id = value.id;
      this.writePerson = value;
      this.writePersonShow = false;
      this.logPage = 1;
      this.loading = false;
      this.finished = false;
      this.logList = [];
      this.getProjectLog();
    },
    // 确定日期
    onDateConfirm(value) {
      this.date = this.$utils.getDate(value);
      this.search_time = value.setHours(0, 0, 0, 0) / 1000;
      this.showDate = false;
      this.logPage = 1;
      this.loading = false;
      this.finished = false;
      this.logList = [];
      this.getProjectLog();
    },
    // 清除填写人
    clearWrite() {
      this.writePerson = "";
      this.logPage = 1;
      this.loading = false;
      this.finished = false;
      this.logList = [];
      this.getProjectLog();
    },
    // 清除日期
    clearDate() {
      this.date = "";
      this.search_time = "";
      this.logPage = 1;
      this.loading = false;
      this.finished = false;
      this.logList = [];
      this.getProjectLog();
    },
    //加载更多（日志）
    onLoad() {
      if (!this.finished) {
        this.logPage++;
        // 获取计划列表
        this.getProjectLog();
      }
    },
    // 跳转至填写日志页
    toWriteLog() {
      sessionStorage.setItem("newProjectActive", this.active);
      this.$router.push({
        path: "/writeLog",
        query: {
          id: this.$route.query.id,
          task: this.details.title,
          isProject: true, //是否是项目
        },
      });
    },
    // 栏目切换
    changeNav(value) {
      switch (value) {
        case 0:
          this.page = 1;
          this.list = [];
          this.loadingTask = false;
          this.finishedTask = false;
          // 获取项目任务
          this.getProjectTask();
          break;
        case 1:
          this.logPage = 1;
          this.logList = [];
          this.loading = false;
          this.finished = false;
          // 获取项目日志
          this.getProjectLog();
          break;
        case 2:
          // 获取任务奖励
          this.$refs.singleRewardDOM && this.$refs.singleRewardDOM.getList();
          break;
        default:
          break;
      }
    },
    // 删除附件或图片
    deleteFile(e, type) {
      // type:  1：附件，2：图片
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否确认删除？",
        })
        .then(async () => {
          let { id } = e;
          let params = {
            id: this.details.id,
            type,
            img_id: id,
          };
          let { code, msg } = await API.projectdeleteImg(params);
          this.$toast(msg);
          if (code == 1) {
            // 获取详情数据
            this.getDetail();
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    // 添加附件
    uploadingEvent(file) {
      this.uploadFile(file, 1);
    },
    // 添加图片
    uploadingEventFile(file) {
      this.uploadFile(file, 2);
    },
    // 上传文件
    async uploadFile(params, type) {
      let data = new FormData();
      let config = {
        //添加请求头
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      data.set("file", params.file);
      let res = await axios.post("/admin/ajax/upload", data, config);
      if (res.data.code == 1) {
        let url =
          type == 1
            ? [...this.details.attachment, res.data.data.url]
            : [...this.details.img, res.data.data.url];
        let data = {
          attachment: url.join(","),
          type,
          id: this.details.id,
        };
        let { code, msg } = await API.projectAddImg(data);
        this.$toast(msg);
        if (code) {
          // 获取详情数据
          this.getDetail();
        }
      }
    },
    //   获取项目详情
    async getDetail() {
      let params = {
        id: this.$route.query.id,
      };
      let { code, data } = await API.detail(params);
      if (code == 1) {
        this.details = data;
      }
    },
    // 获取项目日志
    async getProjectLog() {
      this.loading = true;
      let {
        logPage: page,
        logLimit: limit,
        user_id,
        search_time,
        logList: list,
      } = this;
      let params = {
        id: this.$route.query.id,
        page,
        limit,
        user_id,
        search_time,
      };
      let { code, data } = await API.projectdaily(params);
      if (code == 1) {
        let _list = data.list;
        if (page == 1) {
          list = _list;
        } else {
          list.push(..._list);
        }
        if (_list.length == 0) {
          this.finished = true;
        }
        this.logList = list;
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
  },
};
</script>
  
  <style lang="scss" scoped>
::v-deep .van-tabs__wrap {
  position: sticky !important;
  top: 0 !important;
  z-index: 500 !important;
}
// 删除按钮
.reddelete {
  height: 0.32rem;
  margin-left: 0.1rem;
}
// 添加按钮
.red-add {
  height: 0.32rem;
  margin-right: 0.1rem;
}
// 添加附件|添加图片
.add-text {
  font-size: 0.24rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #d71518;
}
// 顶部背景
.top-bg {
  padding: 0 0.4rem;
  box-sizing: border-box;
  width: 100vw;
  height: 3.8rem;
  background: linear-gradient(0deg, #feefef 10%, #ffffff 87%);
  // 任务名称
  .task-name {
    font-size: 0.3rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #111111;
  }
  // 任务状态文字
  .status_text {
    font-size: 0.28rem;
    font-family: PingFang SC;
    font-weight: 500;
    white-space: nowrap;
    line-height: 0.5rem;
  }
}

.label {
  font-size: 0.28rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  white-space: nowrap;
}
.value {
  font-size: 0.28rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333;
}
// 下划线
.line {
  text-decoration: underline;
}
.blue {
  color: #375394;
}
// 任务类型值
.type_text {
  font-size: 0.28rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #111111;
}
// 任务基本信息 归属项目~任务附件
.basicInfo {
  padding: 0.4rem 0.38rem;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0 -0.05rem 0.08rem 0 rgba(0, 0, 0, 0.03);
  border-radius: 0.32rem 0.32rem 0 0;
  margin-top: -1rem;
}
// 暂停任务详情标题
.stop-detail-title {
  font-size: 0.35rem;
  font-family: PingFang SC;
  color: #111111;
  margin-top: 0.3rem;
}
.stop-box {
  padding: 0.3rem;
  box-sizing: border-box;
}

// 暂停原因弹窗盒子
.stop-reason-box {
  padding: 0.3rem;
  box-sizing: border-box;
  .stop-reason {
    width: 100%;
    height: 3rem;
    padding: 0.2rem;
    box-sizing: border-box;
  }
}
</style>