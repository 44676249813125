import { render, staticRenderFns } from "./messageList.vue?vue&type=template&id=77421501&scoped=true&"
import script from "./messageList.vue?vue&type=script&lang=js&"
export * from "./messageList.vue?vue&type=script&lang=js&"
import style0 from "./messageList.vue?vue&type=style&index=0&id=77421501&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77421501",
  null
  
)

export default component.exports