<template>
  <div class="message" v-if="messageContent.length > 0">
    <div class="message_data">
      <div class="message_background" @click="messageEvent">
        <van-notice-bar
          color="#111111"
          background="#FFFFFF"
          :left-icon="require('../../assets/error.png')"
          :scrollable="false"
        >
          <van-swipe
            vertical
            class="notice-swipe"
            :autoplay="3000"
            :show-indicators="false"
          >
            <van-swipe-item v-for="(s, k) in messageContent" :key="k">
              {{ s }}
            </van-swipe-item>
          </van-swipe>
        </van-notice-bar>
        <img src="@/assets/arrowgray.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    messageContent: Array,
  },
  methods: {
    messageEvent() {
      this.$emit("messageEvent");
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.message {
  margin: 0.2rem;
  .message_data {
    background: white;
    padding: 0.24rem 0.3rem;
    border-radius: 0.16rem;
    .message_background {
      display: flex;
      align-items: center;
      width: 100%;
      > img {
        height: 0.23rem;
        width: 0.12rem;
      }
    }
  }
}
</style>
<style lang="scss">
.message {
  .van-notice-bar {
    width: 100%;
    padding: 0;
  }
  .van-notice-bar__wrap {
    height: 100%;
    width: 80%;
    .van-swipe-item {
      display: flex;
    }
    .van-swipe-item:nth-child(1) {
      margin-top: 8px;
    }
  }
  .van-notice-bar__content {
    height: 100%;
  }
  .van-swipe__track {
    height: 100%;
  }
}
</style>
<!-- .message_background{
    //@extend .alignItemsCenter;
    //justify-content: space-between;
    width: 100%;
    .message_list_text{
        @extend .alignItemsCenter;
        .message_image{
            @extend .alignItemsCenter;
            img{
                width: calcFunction(16);
                height: calcFunction(16);
            }
        }
        .message_list{
            @extend .alignItemsCenter;
            margin-left: calcFunction(10);
            span{
                font-size: calcFunction(14);
                color: #111;
            }
        }
    }
    .message_right_arrow{
        img{
            width: calcFunction(6);
            height: calcFunction(12);
        }
    }
} -->