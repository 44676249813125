<template>
    <div class="review_navigation" style="background:white;height:100vh;">
        <div class="review_navigation_content">
            <publicNavigation :data="navigation" />
        </div>
        <div class="review_content">
            <div class="review_content_data" style="justify-content:space-between;">
                <div class="review_person_name">
                    <span>姓名：</span>
                    <span>{{ reviewDescription.user_nickname }}</span>
                </div>
                <div class="review_person_name">
                    <span>难度：</span>
                    <div class="review_person_image">
                        <img src="../assets/start.png" v-for="c, l in reviewDescription.difficulty_text" :key="l" alt="" />
                    </div>
                </div>
                <div class="review_person_name" style="width:25%;">
                    <span>工作量：</span>
                    <span>{{ reviewDescription.workload }}</span>
                </div>
                <div class="review_person_name">
                    <span>标准奖励点：</span>
                    <span>{{ reviewDescription.add_reward }}</span>
                </div>
                <div class="review_person_name" style="width:25%;">
                    <span>工期：</span>
                    <span>{{ reviewDescription.timeout_text }}</span>
                </div>
                <div class="review_person_name">
                    <span>绩效奖励点：</span>
                    <span>{{ reviewDescription.other_reward }}</span>
                </div>
            </div>
            <div class="review_edit_contenr">
                <div class="review_edit_content_data">
                    <span>绩效奖励点修正：</span>
                    <div class="write_input">
                        <input type="number" v-model="wirteReview" placeholder="输入奖励点">
                        <input type="text" v-model="wirteReviewInfo" placeholder="请填写修正原因">
                    </div>
                </div>
                <div class="review_edit_content_data">
                    <span>额外奖励点：</span>
                    <div class="write_input">
                        <input type="number" v-model="addition" placeholder="输入奖励点">
                        <input type="text" v-model="additionInfo" placeholder="请填写额外奖励点原因">
                    </div>
                </div>
                <div class="review_edit_content_data">
                    <span>修正合计：</span>
                    <span>{{ ((Number(reviewDescription.add_reward) + Number(reviewDescription.other_reward)) + Number(wirteReview) + Number(addition))  }}个奖励点</span>
                </div>
            </div>
            <div class="submit_button">
                <div class="submit_button_content">
                    <button @click="closeChange">取消</button>
                    <button @click="sureChange">确认审核</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import publicNavigation from "@/publicNavigation/publicNavigation.vue";
import * as API from '@/api.ts'
export default {
    components: {
        publicNavigation
    },
    data() {
        return {
            navigation: {},
            // 设置奖励点
            reviewDescription: '',
            // 设置奖励点
            wirteReview: '',
            // 另外奖励点
            addition: '',
            // 总共奖励点
            totalNumebr: '',
            // 路由参数
            routerinfomation: '',
            // 修正奖励点信息
            wirteReviewInfo: '',
            // 额外奖励点信息
            additionInfo: ''
        }
    },
    watch: {
        wirteReview() {
            this.totalNumebr = Number(this.wirteReview) + Number(this.addition) + Number(this.reviewDescription.add_reward) + Number(this.reviewDescription.other_reward)
        },
        addition() {
            this.totalNumebr = Number(this.wirteReview) + Number(this.addition) + Number(this.reviewDescription.add_reward) + Number(this.reviewDescription.other_reward)
        }
    },
    mounted() {
        this.navigation = {
            image: require("../assets/return.png"),
            name: "设置奖励点",
        };
        // 奖励点详情
        this.reviewDescription = this.$route.query.reviewDescriptionsClick
        // 路由
        this.routerinfomation = this.$route.query.reviewDescriptionsClick
    },
    methods: {
        sureChange() {
            API.release_review_reward({
                id: this.routerinfomation.id,
                // 1为执行  2为验收
                type: 1,
                other_reward: this.addition,
                reward_reason: this.additionInfo,
                additional_reward: this.wirteReview,
                additional_reward_reason: this.wirteReviewInfo,
                user_id: this.routerinfomation.user_id,
            }).then(async e => {
                this.$toast({
                    type: e.code == 1 ? 'success' : 'fail',
                    message: e.msg
                })

                if (e.code == 1) {
                    setTimeout(() => {
                        this.$router.go(-2)
                    }, 800);
                }
            })
        },
        closeChange(){
            this.$router.back(-1)
        }
    }
}

</script>
<style lang="sass" scoped>
@import './reviewClick.sass'
</style>
