<template>
  <div
    class="classfiyContent"
    :style="windowHight > 375 ? { height: '100vh' } : { height: '100%' }"
  >
    <div class="classfiy_content_search">
      <div class="classfiy_content_team" id="scolltop">
        <van-search
          @search="onSearch()"
          @clear="clear()"
          v-model="search"
          shape="round"
          placeholder="请输入搜索关键词"
        />
        <div class="deparment_list">
          <div class="deparment_list_content">
            <div class="deparment_card_for">
              <div
                class="deparment_for"
                v-for="(s, k) in deparmentData"
                :key="k"
              >
                <div class="deparment_header_title">
                  <div class="deparment_title">
                    <span>{{ s.name }}</span>
                  </div>
                  <div class="deparment_content">
                    <div
                      class="deparment_content_for"
                      @click="descriptionEvent(i)"
                      v-for="(i, l) in s.children"
                      :key="l"
                    >
                      <div class="header_content">
                        <div class="content_image">
                          <img :src="i.image" alt="" />
                        </div>
                        <div class="content_title_of_number">
                          <div class="content_title">
                            <span>{{ i.name }}</span>
                            <span>{{ i.icon }}</span>
                          </div>
                          <div class="number_total">
                            <div class="number_list">
                              <span>等级：</span>
                              <span>Lv.{{ i.intergral }}</span>
                            </div>
                            <div class="number_list">
                              <span>排名：</span>
                              <span>{{ i.totalNumber }}</span>
                            </div>
                            <div class="number_list">
                              <span>任务数：</span>
                              <span>{{ i.number }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="deparment_right_arrow">
                        <div class="deparment_right_arrow_image">
                          <img src="../../../assets/drop.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as API from "@/api.ts";
export default {
  props: {
    // deparmentData: [],
  },
  data() {
    return {
      search: "",
      windowHight: 0,
      deparmentData: [],
    };
  },
  watch: {
    search(e) {
      this.$emit("addEventListener", e);
    },
  },
  mounted() {
    this.Request();
    const parms = window;
    this.windowHight = parms.outerHeight;
  },
  methods: {
    //清除搜索框
    clear(){
      this.onSearch()
    },
    // 搜索
    onSearch() {
      this.deparmentData = [];
      this.Request();
    },
    // 获取团队列表
    Request() {
      let { search } = this;
      let params = {
        search,
      };
      API.allteam(params).then(async (e) => {
        if (e.code == 1) {
          e.data.rows.map((item) => {
            this.deparmentData.push({
              name: item.title,
              children: item.person.map((s) => {
                return {
                  ...s,
                  image: s.avatar,
                  name: s.nickname,
                  icon: s.section_name,
                  intergral: s.ranking,
                  totalNumber: s.level,
                  number: s.task_num,
                };
              }),
            });
          });
        }
      });
    },
    descriptionEvent(s) {
      this.$emit("descriptionEvent", s);
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.van-search {
  padding: 12px;
}

.classfiyContent {
  height: 100vh;
  background: #f1f0f5;
}

.classfiy_content_search {
  .classfiy_content_team {
    .deparment_list {
      padding-bottom: 20px;
      .deparment_list_content {
        .deparment_card_for {
          .deparment_for {
            .deparment_header_title {
              .deparment_title {
                margin-left: calcFunction(10);
                margin-top: calcFunction(15);
                margin-bottom: calcFunction(12);

                span {
                }
              }

              .deparment_content {
                background: white;

                .deparment_content_for {
                  @extend .alignItemsCenter;
                  justify-content: space-between;
                  padding: calcFunction(15) calcFunction(20);
                  border-bottom: 1px solid #f1f0f5;

                  .header_content {
                    @extend .alignItemsCenter;

                    .content_image {
                      img {
                        width: calcFunction(50);
                        height: calcFunction(50);
                        border-radius: calcFunction(1000);
                      }
                    }

                    .content_title_of_number {
                      @extend .alignItemsCenter;
                      flex-direction: column;
                      align-items: flex-start;
                      margin-left: calcFunction(10);

                      .content_title {
                        margin-bottom: calcFunction(6);
                        @extend .alignItemsCenter;

                        span {
                          font-size: calcFunction(15);
                          color: #111111;
                        }

                        span:nth-last-child(1) {
                          margin-left: calcFunction(10);
                          border: 1px solid #008ee6;
                          border-radius: calcFunction(100);
                          color: #008ee6;
                          font-size: calcFunction(12);
                          padding: calcFunction(1) calcFunction(5);
                        }
                      }

                      .number_total {
                        @extend .alignItemsCenter;

                        .number_list {
                          margin-right: calcFunction(10);

                          span {
                            font-size: calcFunction(12);
                            color: #999999;
                          }

                          span:nth-last-child(1) {
                            color: #111111;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .deparment_right_arrow {
              .deparment_right_arrow_image {
                margin-right: calcFunction(-15);

                img {
                  width: calcFunction(30);
                  height: calcFunction(30);
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
