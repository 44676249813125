<template>
    <div class="message">
        <div class="message_content">
            <div class="message_content_data">
                <div class="message_header">
                    <publicNavigation @sureEvenet="sureEvenet" :data="navigation" />
                </div>
                <div class="message_list">
                    <div class="message_content">
                        <keep-alive>
                            <messagelist :messgaedata="messgaedata" />
                        </keep-alive>
                    </div>
                </div>
            </div>
        </div>
      
    </div>
</template>
<script>
import publicNavigation from '@/publicNavigation/publicNavigation'
import messagelist from './compoment/messageListData.vue'

import * as API from '@/api.ts'
export default {
    components: {
        publicNavigation,
        messagelist
    },
    data() {
        return {
          navigation: {},
          messgaedata: [],
  
         
        }
    },
    mounted() {
        API.bulletinList().then(async e => {
            console.log(e)
            if (e.code == 1) {
              e.data.rows.map(item => {
                  Object.assign(item, {
                      name: item.title,
                      text: item.text,
                      id: item.id
                  })
              })
              this.messgaedata = e.data.rows
            }
        })
        this.navigation = {
            image: require("../assets/return.png"),
            name: "公告通知",
            // sure:'全部已读',
            // search: require("../assets/messgae.png"),
            close: ''
        };
    },
    methods: {
    
    }
}

</script>
<style lang="scss" scoped>
.message {
    height: 100vh;
    background: #F1F0F5;
}

</style>
