<!--
 * @Author: Mr.Cheng
 * @Date: 2023-10-27 09:36:36
 * @LastEditTime: 2023-10-27 10:23:50
 * @FilePath: \octopus-factory-map\src\new\cpProject\cpDetail.vue
-->
<template>
  <div>
    <!-- 顶部标题 -->
    <div class="flex-c-c task-navigate-box">
      <!-- 返回按钮 -->
      <img
        @click="$router.go(-1)"
        class="task-left-back"
        src="@/assets/return.png"
        alt=""
      />
      <div>{{ title || "cp值详情" }}</div>
    </div>
    <!-- cp值详情内容 -->
    <!-- 列表信息 -->
    <div class="list">
      <div class="mb10">列表</div>
      <div style="overflow: auto" v-if="list.length > 0">
        <van-list
          v-if="list.length > 0"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div v-for="item of list" :key="item.id" class="list-content">
            <!-- 列表信息顶部 -->
            <div class="list-content-top flex-c-b">
              <div class="list-name">{{ item.user_name }}</div>
            </div>
            <!-- 列表信息内容 -->
            <div class="list-content-content">
              <!-- cp值余额&cp值占比 -->
              <div class="flex-c-b">
                <div class="flex-c" style="width: 50%">
                  <div class="label">cp值余额：</div>
                  <div class="value">{{ item.cp }}</div>
                </div>
                <div class="flex-c" style="width: 50%">
                  <div class="label">cp值占比：</div>
                  <div class="value red">{{ item.proportion || "-" }}</div>
                </div>
              </div>
              <!-- 分红金额 -->
              <div class="flex-c mt10">
                <div class="label">分红金额（税前）</div>
                <div class="value mr20">
                  {{ item.divvy || "-" }}
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
      <van-empty v-else description="暂无数据" />
    </div>
  </div>
</template>

<script>
import * as API from "@/api.ts";

export default {
  data() {
    return {
      list: [],
      page: 1,
      limit: 10,
      loading: false, //列表接口是否正在请求
      finished: false, //是否全部加载完
      title: "", //顶部标题
    };
  },
  mounted() {
    this.title = "第" + this.$route.query.num + "期分红";
    this.getData();
  },
  methods: {
    // 上拉加载更多
    onLoad() {
      if (!this.finished) {
        this.page++;
        this.getData();
      }
    },
    async getData() {
      let { page, limit, list } = this;
      let params = {
        page,
        limit,
        id: this.$route.query.id,
      };
      let { code, data } = await API.divvyDetail(params);
      if (code == 1) {
        this.allData = data;
        let _list = data.rows;
        if (page == 1) {
          list = _list;
        } else {
          list.push(..._list);
        }
        if (_list.length == 0) {
          this.finished = true;
        }
        this.list = list;
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.task-navigate-box {
  background: #f1f0f5 !important;
}
.label {
  font-size: 0.24rem;
  font-weight: 500;
  color: #999999;
}
.value {
  font-size: 0.24rem;
  font-weight: 500;
  color: #000000;
}
// 列表信息
.list {
  width: 100%;
  background: #ffffff;
  box-shadow: 0 -0.05rem 0.08rem 0 rgba(0, 0, 0, 0.03) !important;
  border-radius: 0.32rem 0.32rem 0 0 !important;
  padding: 0.3rem;
  box-sizing: border-box;
  overflow: auto;
  .list-content {
    width: 100%;
    background: #fafafa;
    border: 0.01rem solid #dddddd;
    border-radius: 0.08rem;
    margin-bottom: 0.2rem;
    .list-content-top {
      padding: 0.2rem 0.22rem;
      box-sizing: border-box;
      border-bottom: 0.01rem solid #dddddd;
    }
    .list-content-content {
      padding: 0.3rem 0.55rem;
      box-sizing: border-box;
    }
  }
}
</style>