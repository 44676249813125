<template>
  <div>
    <div class="top-bg-box">
      <!-- 顶部标题 -->
      <div class="flex-c-c log-navigate-box">
        <!-- 返回按钮 -->
        <img
          @click="$router.go(-1)"
          class="log-left-back"
          src="@/assets/whitereturn.png"
          alt=""
        />
        <div>{{ $route.query.id ? "修改任务" : "发布任务" }}</div>
      </div>
      <!-- 编辑任务表单 -->
      <div class="log-form">
        <!-- 选择项目 -->
        <van-cell-group>
          <van-field
            @click="$router.push({ path: '/publishProject', query: { e: 8 } })"
            size="large"
            :value="project_title"
            label="选择项目"
            readonly
            placeholder="请选择任务归属项目"
          >
            <template #right-icon>
              <img class="blackarrow" src="@/assets/blackarrow.png" alt="" />
            </template>
          </van-field>
        </van-cell-group>
        <!-- 任务标题 -->
        <van-cell-group>
          <van-field
            v-model="title"
            size="large"
            type="text"
            label="任务标题"
            placeholder="请输入任务标题"
          >
          </van-field>
        </van-cell-group>
        <!-- 任务类型 -->
        <van-cell-group>
          <van-field
            readonly
            @click="taskTypesShow = true"
            v-model="task_type_text"
            size="large"
            type="text"
            label="任务类型"
            placeholder="请选择任务类型"
          >
          </van-field>
        </van-cell-group>
        <!-- 任务难度 -->
        <van-cell-group>
          <van-field
            v-if="selectInputs"
            @click="$router.push({ path: '/vanskelighedSelect' })"
            size="large"
            label="任务难度"
            readonly
            placeholder="请选择任务难度"
          >
            <template #input>
              <!-- 难度指数 -->

              <van-rate
                class="mt5"
                size="15px"
                color="#D71518"
                void-color="#FFF"
                :value="Number(selectInputs)"
                readonly
              />
            </template>
            <template #right-icon>
              <img class="blackarrow" src="@/assets/blackarrow.png" alt="" />
            </template>
          </van-field>
          <van-field
            v-else
            @click="$router.push({ path: '/vanskelighedSelect' })"
            size="large"
            label="任务难度"
            readonly
            placeholder="请选择任务难度"
          >
            <template #right-icon>
              <img class="blackarrow" src="@/assets/blackarrow.png" alt="" />
            </template>
          </van-field>
        </van-cell-group>
        <!-- 任务奖励点 -->
        <van-cell-group>
          <van-field
            @click="$router.push({ path: '/rewardPoints' })"
            size="large"
            label="任务奖励点"
            readonly
            :value="
              reward_method != 3 ? reward_title : additional_number + '奖励点'
            "
            placeholder="请设置奖励点"
          >
            <template #right-icon>
              <img class="blackarrow" src="@/assets/blackarrow.png" alt="" />
            </template>
          </van-field>
        </van-cell-group>
        <!-- 指派给 -->
        <van-cell-group>
          <van-field
            @click="$router.push({ path: '/overdragetPerson' })"
            size="large"
            label="指派给"
            :value="user"
            readonly
            placeholder="请选择要指派的人"
          >
            <template #right-icon>
              <img class="blackarrow" src="@/assets/blackarrow.png" alt="" />
            </template>
          </van-field>
        </van-cell-group>
        <!-- 验收人 -->

        <van-cell-group>
          <van-field
            autosize
            type="textarea"
            @click="$router.push({ path: '/acceptorPerson' })"
            size="large"
            label="验收人"
            readonly
            :value="acceptPerson"
            placeholder="请选择验收任务的人"
          >
            <template #right-icon>
              <img class="blackarrow" src="@/assets/blackarrow.png" alt="" />
            </template>
          </van-field>
        </van-cell-group>
        <!-- 验收标准 -->
        <van-cell-group>
          <van-field
            v-model="acceptance_criteria"
            size="large"
            label="验收标准"
            placeholder="请输入验收标准"
          >
          </van-field>
        </van-cell-group>
        <!-- 附件 -->
        <template>
          <van-cell-group
            v-if="filkeList.length == 0"
            class="flex-c-b"
            style="align-items: flex-start"
          >
            <van-uploader
              accept="file"
              result-type="file"
              :after-read="uploadingEvent"
              :max-count="4"
              multiple
            >
              <template #default>
                <van-field size="large" label="附件" placeholder="请添加附件">
                </van-field>
              </template>
            </van-uploader>

            <img
              class="addbuttons mr10 mt15"
              src="@/assets/addbuttons.png"
              alt=""
            />
          </van-cell-group>
          <van-cell-group v-else>
            <template>
              <van-uploader
                accept="file"
                result-type="file"
                :after-read="uploadingEvent"
                :max-count="4"
                multiple
              >
                <template #default>
                  <div class="flex-c-b">
                    <van-field size="large" label="附件"> </van-field>
                    <img
                      class="addbuttons mr10"
                      src="@/assets/addbuttons.png"
                      alt=""
                    />
                  </div>
                </template>
              </van-uploader>
              <div
                class="fieldListSty flex-c-b"
                v-for="(item, index) of filkeList"
                :key="index"
              >
                <div class="textOver1" style="width: 80%">{{ item.url }}</div>
                <img
                  @click.stop="deleteFile(index)"
                  class="redoffsetIocn"
                  src="@/assets/redoffsetIocn.png"
                  alt=""
                />
              </div>
            </template>
          </van-cell-group>
        </template>
        <!-- 图片 -->
        <template>
          <van-cell-group
            v-if="imgList.length == 0"
            class="flex-c-b"
            style="align-items: flex-start"
          >
            <van-uploader
              accept=".jpg,.jpeg,.png"
              result-type="file"
              :after-read="uploadingEventFile"
              :max-count="4"
              multiple
            >
              <template #default>
                <van-field size="large" label="图片" placeholder="请添加图片">
                </van-field>
              </template>
            </van-uploader>

            <img
              class="addbuttons mt15 mr10"
              src="@/assets/addbuttons.png"
              alt=""
            />
          </van-cell-group>
          <van-cell-group v-else>
            <template>
              <van-uploader
                accept="file"
                result-type="file"
                :after-read="uploadingEventFile"
                :max-count="4"
                multiple
              >
                <template #default>
                  <div class="flex-c-b">
                    <van-field size="large" label="图片"> </van-field>
                    <img
                      class="addbuttons mr10"
                      src="@/assets/addbuttons.png"
                      alt=""
                    />
                  </div>
                </template>
              </van-uploader>
              <div
                class="fieldListSty flex-c-b"
                v-for="(item, index) of imgList"
                :key="index"
              >
                <div class="textOver1" style="width: 80%">{{ item.url }}</div>
                <img
                  @click.stop="deleteImage(index)"
                  class="redoffsetIocn"
                  src="@/assets/redoffsetIocn.png"
                  alt=""
                />
              </div>
            </template>
          </van-cell-group>
        </template>
        <!-- 简介 -->
        <van-cell-group>
          <van-field
            type="textarea"
            v-model="introduce"
            size="large"
            label="简介"
            placeholder="请输入简介"
          >
          </van-field>
        </van-cell-group>
      </div>
      <div class="style"></div>
    </div>

    <div class="bottom-box">
      <div
        @click="submit"
        :style="isSubmit ? '' : 'background:#FFB5B6'"
        class="submit flex-c-c"
      >
        提交
      </div>
    </div>

    <!-- 任务类型弹窗 -->
    <van-popup
      v-model="taskTypesShow"
      round
      position="bottom"
      :style="{ height: '40%' }"
    >
      <van-picker
        show-toolbar
        :columns="taskTypeList"
        value-key="title"
        @confirm="onConfirm"
        @cancel="taskTypesShow = false"
      />
    </van-popup>
  </div>
</template>

<script>
import axios from "axios";

import * as API from "@/api.ts";
export default {
  name: "task",
  data() {
    return {
      project_title: "", //项目名称
      project_id: "", //项目id
      title: "", //任务标题
      reward_method: "", //奖励点类型（无，标准，自定义）
      reward_title: "", //奖励点类型（无，标准，自定义） （显示）
      additional_number: "", //自定义奖励点数量
      selectInputs: "", //难度（显示）
      difficulty: "", //难度（参数）
      catch_modality: "", //指派类别
      user: "", //接单人
      section_id: "", //部门id
      user_id: "", //用户id
      inspect_ids: "", //验收人id
      acceptPerson: "", //验收人列表
      acceptance_criteria: "", //验收标准
      taskTypeList: [], //任务类型列表
      taskTypesShow: false, //任务类型弹窗显隐
      task_type_text: "", //任务类型显示
      task_type: "", //任务类型id
      introduce: "", //简介
      filkeList: [],
      fileList: [], //附件 参数
      annex: [],
      imgList: [], //图片显示列表
      images: [], //图片参数
      details: {}, //详情数据

      isSubmit: false, //是否能提交
    };
  },
  watch: {
    title() {
      //校验必填项是否填写完整
      this.asyncValidator();
    },
    project_id() {
      //校验必填项是否填写完整
      this.asyncValidator();
    },
    difficulty() {
      //校验必填项是否填写完整
      this.asyncValidator();
    },
    reward_method() {
      //校验必填项是否填写完整
      this.asyncValidator();
    },
    additional_number() {
      //校验必填项是否填写完整
      this.asyncValidator();
    },
    catch_modality() {
      //校验必填项是否填写完整
      this.asyncValidator();
    },
  },
  mounted() {
    // 获取任务类型列表
    this.getTaskTypeList();
    // 获取详情数据
    this.getDetail();
  },
  activated() {
    if (this.$route.params.type == 1) {
      // 获取详情数据
      this.getDetail();
    }
    //校验必填项是否填写完整
    this.asyncValidator();
    // 获取奖励点
    this.getRewardPoints();
    // 获取选中项目
    this.getProject();
    // 获取难度
    this.getDifficulty();
    // 获取指派人
    this.getUser();
    // 获取验收人
    this.getAccept();
    //校验必填项是否填写完整
    this.asyncValidator();
  },
  deactivated() {
    localStorage.clear("sure");
  },
  methods: {
    // 确认任务类型
    onConfirm(e) {
      this.task_type = e.id;
      this.task_type_text = e.title;
      this.taskTypesShow = false;
    }, // 提交
    async submit() {
      if (!this.isSubmit) {
        return;
      }
      let {
        title,
        project_id,
        user_id,
        section_id,
        difficulty,
        reward_method,
        catch_modality,
        inspect_ids,
        fileList,
        images,
        additional_number,
        acceptance_criteria,
        introduce,
        task_type,
      } = this;
      // 修改
      if (this.$route.query.id) {
        let params = {
          ids: this.$route.query.id,
          project_id,
          title,
          reward_method,
          catch_modality,
          user_id,
          task_type,
          section_id,
          inspect_ids:
            (Array.isArray(inspect_ids) && inspect_ids.join(",")) ||
            inspect_ids ||
            "",
          annex:
            (Array.isArray(fileList) && fileList.join(",")) || fileList || "",
          taskimg: (Array.isArray(images) && images.join(",")) || images || "",
          difficulty,
          additional_number,
          acceptance_criteria,
          introduce,
        };
        let { code, msg } = await API.editTask(params);
        this.$toast(msg);
        if (code == 1) {
          this.$router.go(-1);
        }
      }
      // 发布
      else {
        let params = {
          project_id,
          title,
          reward_method,
          catch_modality,
          user_id,
          task_type,
          section_id,
          inspect_ids:
            (Array.isArray(inspect_ids) && inspect_ids.join(",")) ||
            inspect_ids ||
            "",
          annex:
            (Array.isArray(fileList) && fileList.join(",")) || fileList || "",
          taskimg: (Array.isArray(images) && images.join(",")) || images || "",
          difficulty,
          additional_number,
          acceptance_criteria,
          introduce,
        };
        let { code, msg } = await API.oprette(params);
        this.$toast(msg);
        if (code == 1) {
          this.$router.go(-1);
        }
      }
    },
    // 获取详情数据
    async getDetail() {
      let { id } = this.$route.query;
      if (!id) {
        return;
      }

      let params = {
        ids: id,
      };
      let { code, data } = await API.details(params);
      if (code == 1) {
        this.details = data;

        this.project_id = data.PidInfo.project_id;
        this.catch_modality = data.PidInfo.catch_modality;
        console.log(this.catch_modality);
        switch (this.catch_modality) {
          case 1:
            this.user = "公共接单";

            break;
          case 2:
            this.user = data.rows[0].section_name;
            break;
          case 3:
            this.user = data.rows[0].user_nickname;
            break;

          default:
            break;
        }
        this.project_title = data.project.title;
        this.title = data.PidInfo.title;
        this.difficulty = data.PidInfo.difficulty;
        this.task_type = data.PidInfo.task_type;
        this.task_type_text = data.PidInfo.task_type_text;
        this.selectInputs = data.PidInfo.difficulty_text;
        this.reward_method = data.PidInfo.reward_method;

        switch (this.reward_method) {
          case 0:
            this.reward_title = "无奖励点";
            break;

          case 1:
            this.reward_title = "标准奖励点";
            break;

          case 2:
            this.reward_title = "自定义奖励点";
            break;

          default:
            break;
        }
        this.inspect_ids = data.PidInfo.inspect_ids.split(",").map(Number);
        this.user_id = data.PidInfo.user_id || "";
        this.section_id = data.PidInfo.section_id;
        this.additional_number = data.PidInfo.additional_number;
        this.introduce = data.PidInfo.introduce;
        this.annex = data.PidInfo.attachmentUrl_text || [];
        this.taskimg = data.PidInfo.AttachmentImgUrl_text || [];
        this.acceptance_criteria = data.PidInfo.acceptance_criteria;
        this.acceptPerson = data.PidInfo.inspect.join(",");
        this.catch_modality = "" + data.PidInfo.catch_modality || "";
        if (this.fileList.length == 0) {
          this.fileList = data.PidInfo.attachmentUrl_text||[];
          data.PidInfo.attachment_info.forEach((item) => {
            this.filkeList.push({
              url: item.filename,
            });
          });
        }

        if (this.imgList.length == 0) {
          this.images = data.PidInfo.AttachmentImgUrl_text||[];
          data.PidInfo.taskimg_info.forEach((item) => {
            this.imgList.push({
              url: item.filename,
            });
            this.images.push(item.url);
          });
        }
      }
    },
    //校验必填项是否填写完整
    asyncValidator() {
      let { title, project_id, difficulty, reward_method, catch_modality } =
        this;
      if (
        title &&
        project_id &&
        difficulty &&
        [0, 1, 2, 3].includes(Number(reward_method)) &&
        catch_modality
      ) {
        this.isSubmit = true;
      } else {
        this.isSubmit = false;
      }
    },
    // 获取奖励点
    getRewardPoints() {
      if (!localStorage.getItem("sure")) {
        return;
      }
      let data = JSON.parse(localStorage.getItem("sure"));
      if (data.rewardText && data.rewardText.value) {
        this.reward_method = data.rewardText && data.rewardText.value;
        this.reward_title = data.rewardText && data.rewardText.title;
        this.additional_number =
          data.additional_number && data.additional_number;
        localStorage.clear("sure");
      }
    },
    // 获取选中项目
    getProject() {
      if (!localStorage.getItem("sure")) {
        return;
      }
      let data = JSON.parse(localStorage.getItem("sure"));
      if (data.project) {
        this.project_title = data.project && data.project;
        this.project_id = data.projectId && data.projectId;
        localStorage.clear("sure");
      }
    },
    // 获取难度
    getDifficulty() {
      if (!localStorage.getItem("sure")) {
        return;
      }
      let data = JSON.parse(localStorage.getItem("sure"));
      if (data.selectInputs) {
        this.selectInputs = data.selectInputs;
        this.difficulty = data.start;
        localStorage.clear("sure");
      }
    },
    // 获取指派人
    getUser() {
      if (!localStorage.getItem("sure")) {
        return;
      }
      let data = JSON.parse(localStorage.getItem("sure"));
      if (data.stolpe) {
        this.catch_modality = data.stolpe;
        switch (this.catch_modality) {
          case "1":
            this.user = "公共接单";

            break;
          case "2":
            this.user = data.publicStolpe.title;
            this.section_id = data.publicStolpe.id;
            break;
          case "3":
            this.user = data.personName.name;
            this.user_id = data.personName.id;
            break;

          default:
            break;
        }
        localStorage.clear("sure");
      }
    },
    // 获取验收人
    getAccept() {
      if (!localStorage.getItem("sure")) {
        return;
      }
      let data = JSON.parse(localStorage.getItem("sure"));
      if (data.acceptPerson) {
        let acceptPerson = data.acceptPerson.map((item) => {
          return item.nickname;
        });
        this.acceptPerson = acceptPerson.join(",");
        let inspect_ids = data.acceptPerson.map((item) => {
          return item.id;
        });
        this.inspect_ids = inspect_ids.join(",");
        localStorage.clear("sure");
      }
    },
    // 获取任务类型列表
    async getTaskTypeList() {
      let { code, data } = await API.task_type();
      if (code == 1) {
        this.taskTypeList = data;
      }
    },
    // 上传文件
    async uploadFile(i, params) {
      if (i == params.length) {
        return;
      }
      let data = new FormData();
      let config = {
        //添加请求头
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      data.set("file", params[i].file);
      const res = await axios.post("/admin/ajax/upload", data, config);
      i++;
      console.log(this.fileList,1111111);
      this.fileList.push(res.data.data.url);
      this.uploadFile(i, params);
      return res;
    },
    // 上传图片
    async uploadImage(i, params) {
      if (i == params.length) {
        return;
      }
      let data = new FormData();
      let config = {
        //添加请求头
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      data.set("file", params[i].file);
      const res = await axios.post("/admin/ajax/upload", data, config);
      i++;
      this.images.push(res.data.data.url);
      this.uploadImage(i, params);
      return res;
    },
    // 添加附件
    uploadingEvent(file) {
      if (Array.isArray(file)) {
        file.forEach((item) => {
          this.filkeList.push({ url: item.file.name });
        });
        this.uploadFile(0, file);
      } else {
        let list = [file];
        list.forEach((item) => {
          this.filkeList.push({ url: item.file.name });
        });
        this.uploadFile(0, list);
      }
    },
    // 添加图片
    uploadingEventFile(file) {
      if (Array.isArray(file)) {
        file.forEach((item) => {
          this.imgList.push({ url: item.file.name });
        });
        this.uploadImage(0, file);
      } else {
        let list = [file];
        list.forEach((item) => {
          this.imgList.push({ url: item.file.name });
        });
        this.uploadImage(0, list);
      }
    },
    // 删除附件
    deleteFile(index) {
      this.filkeList.splice(index, 1);
      this.fileList.splice(index, 1);
    },
    // 删除图片
    deleteImage(index) {
      this.imgList.splice(index, 1);
      this.images.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-uploader {
  width: 100%;
}
::v-deep .van-uploader__input-wrapper {
  width: 100%;
}
.label {
  font-size: 0.28rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #111111;
}
.fieldListSty {
  height: 0.9rem;
  background: #ffeaea;
  border-radius: 0.08rem;
  font-size: 0.28rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #d71518;
  padding: 0.3rem 0.2rem;
  box-sizing: border-box;
  margin: 0.2rem;
}
.style {
  width: 100%;
  height: 2rem;
}
.bottom-box {
  width: 100%;
  height: 1.2rem;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  padding: 0.15rem 0.2rem;
  box-sizing: border-box;
  .submit {
    width: 100%;
    height: 0.9rem;
    background: #d71518;
    border-radius: 0.16rem;
    font-size: 0.34rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
}
.redoffsetIocn {
  width: 0.2rem;
}
.addbuttons {
  width: 0.29rem;
}
.top-bg-box {
  background: url("@/assets/background.png");
  background-size: 100% 100%;
  width: 100vw;
  height: 3rem;

  // 顶部标题
  .log-navigate-box {
    width: 100%;
    height: 0.88rem;
    font-size: 0.34rem;
    font-family: PingFang SC;
    color: #fff;
  }
  .log-left-back {
    width: 0.19rem;
    // height: 0.339rem;
    position: absolute;
    left: 0.4rem;
  }

  // 日志表单
  .log-form {
    margin: 0.2rem;
    background: #ffffff;
    border-radius: 0.16rem;
    overflow: hidden;

    .blackarrow {
      width: 0.08rem;
      height: 0.16rem;
    }
  }
}
</style>