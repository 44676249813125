<template>
  <div class="waitHandle_header">
    <div class="waitHandle_title_and_icon">
      <div class="waitHandle_content_data">
        <div class="waitHandle_icon">
          <img :src="`${toDayText.image}`" alt="" />
        </div>
        <div class="waitHandle_title">
          {{ toDayText.name ? toDayText.name : "待办工作" }}
        </div>
      </div>
      <div class="waitHandle_header_right_classfiy" v-if="toggle && toggle > 0">
        <div class="waitHandle_classfiy">
          <div class="waitHandle_classfiy_list">
            <div
              class="waitHandle_list"
              :class="currentIndex == k ? 'active' : ''"
              @click="currentIndexFunction(k)"
              v-for="(s, k) in toggleArray"
              :key="k"
            >
              <span>{{ s }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 右边显示 -->
      <div
        @click="rightEvent"
        v-if="isShowRight"
        class="waitHandle_header_right_classfiy"
      >
        {{ rightText }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    toDayText: Object,
    toggle: Number,
    toggleArray: Array,
    isShowRight: Boolean,
    rightText: String,
    type: Number,
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  mounted() {},
  methods: {
    // 顶部右按钮点击
    rightEvent() {
      let { type } = this;
      switch (type) {
        //查看全部
        case 1:
          this.$router.push("/allPlans");
          break;

        default:
          break;
      }
    },
    currentIndexFunction(e) {
      this.currentIndex = e;
      this.$emit("currentIndexFunction", e);
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}
.waitHandle_title_and_icon {
  padding: 0.2rem;
  border-bottom: 1px solid #dddddd;
  @extend .alignItemsCenter;
  justify-content: space-between;
  .waitHandle_content_data {
    @extend .alignItemsCenter;
    .waitHandle_icon {
      @extend .alignItemsCenter;
      img {
        width: 0.277rem;
        height: 0.303rem;
      }
    }
    .waitHandle_title {
      margin-left: 0.22rem;
      span {
        font-size: 0.32rem;
      }
    }
  }
}

.waitHandle_header_right_classfiy {
  // padding-right: calcFunction(20);
  .waitHandle_classfiy {
    .waitHandle_classfiy_list {
      @extend .alignItemsCenter;
      .waitHandle_list {
        padding: 0.03rem 0.12rem;
        box-sizing: border-box;
        @extend .alignItemsCenter;
        justify-content: center;
        border: 1px solid #999999;
        border-radius: 0.08rem;
        span {
          font-size: 0.28rem;
        }
      }
      .waitHandle_list:nth-child(1) {
        border-right: 1px solid #999999;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      .waitHandle_list:nth-child(2) {
        border-left: none;
        border-radius: 0;
      }
      .waitHandle_list:nth-child(3) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
      }
    }
  }
}

.active {
  background: #d71518 !important;
  color: white !important;
  border: 1px solid #d71518 !important;
}
</style>