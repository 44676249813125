<template>
  <div class="duration">
    <div class="duration_content">
      <div class="duration_content_list">
        <div class="duration_list" @scroll.passive="scollBottom">
          <div class="duration_header_title" @click="description(s)" v-for="(s, k) in perfromData" :key="k">
            <div class="duration_header_title_text_and_duration">
              <div class="duration_header_title_and_start">
                <div class="duration_header_title">
                  <span>{{s.pid > 0 ? s.pid_title + '--' : ''}}{{ s.name }}</span>
                </div>
                <div class="duration_header_start">
                  <img v-for="(i, l) in s.number" :key="l" src="@/assets/start.png" alt="">
                </div>
              </div>
              <div class="duration_header_duration">
                <button>
                  {{ s.type_text ? s.type_text == '新任务' ? s.type_text + '审核' : s.type_text : '' }}
                </button>
              </div>
            </div>
            <div class="duration_list_contents">
              <div class="duration_children_list">
                <div class="children_list" v-for="(n, m) in s.children" :key="m">
                  <span>{{ n.name }}</span>
                  <span>{{ n.text }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    perfromData: Array
  },
  methods: {
    switchEvent(v) {
      let name;
      switch (v) {
        case 1:
          name = '未审核'
          break;
        case 2:
          name = '审核通过'
          break;
        case 3:
          name = '审核驳回'
          break;
      }
      return name;
    },
    description(e) {
      this.$emit('description', e)
    },
    moreClick() {
      this.$emit('moreClick')
    },
    scollBottom(e) {
      this.$emit('scollBottom', e)
    }
  }
}

</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.duration {
  margin: calcFunction(10);
  margin-bottom: 0;
  
  .duration_content {
    .duration_content_list {
      .duration_list {
        .duration_header_title {
          background: white;
          border-radius: calcFunction(8);
          margin-bottom: calcFunction(10);
          
          .duration_header_title_text_and_duration {
            @extend .alignItemsCenter;
            justify-content: space-between;
            padding: calcFunction(15);
            border-bottom: 1px solid #DDDDDD;
            
            .duration_header_title_and_start {
              .duration_header_title {
                span {
                  font-size: calcFunction(14);
                }
              }
              
              .duration_header_start {
                margin-top: calcFunction(4);
                @extend .alignItemsCenter;
                
                img {
                  width: calcFunction(15);
                  height: calcFunction(15);
                }
              }
            }
            
            .duration_header_duration {
              button {
                color: #D71518;
                border: 1px solid #D71518;
                padding: calcFunction(5) calcFunction(10);
                background: transparent;
                border-radius: calcFunction(4);
                font-size: calcFunction(13);
              }
            }
          }
          
          .duration_list_contents {
            margin-top: calcFunction(20);
            padding-left: calcFunction(15);
            padding-right: calcFunction(15);
            padding-bottom: calcFunction(15);
            
            .duration_children_list {
              @extend .alignItemsCenter;
              flex-wrap: wrap;
              justify-content: space-between;
              
              .children_list {
                width: 50%;
                margin-bottom: calcFunction(14);
                
                span {
                  color: #999999;
                  font-size: calcFunction(13);
                }
                
                span:nth-last-child(1) {
                  color: #333333;
                }
              }
              
              .children_list:nth-child(2n) {
                width: 40%;
              }
              
              .children_list:nth-child(1) {
                span:nth-last-child(1) {
                  color: #375394;
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
}

.more_button {
  .more_button_click {
    @extend .alignItemsCenter;
    justify-content: center;
    padding: calcFunction(20) 0;
    
    span {
      padding-bottom: calcFunction(20);
      font-size: calcFunction(14);
      color: #999;
    }
  }
}


.node_updata {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  img {
    width: 30%;
  }
  
  span {
    color: #999;
    font-size: 15px;
  }
}
</style>
