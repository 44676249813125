<template>
  <div class="review_description">
    <div class="review_description_content">
      <div class="review_description_content_header">
        <div class="navigation_header">
          <publicNavigation
            @navigationClick="navigationClick(1)"
            :data="navigation"
          />
        </div>
        <div class="background_color">
          <div class="background_colors">
            <div class="review_header_content_title">
              <div class="review_header_content">
                <div class="review_title">
                  <span>{{ HeadersDescription.title }}</span>
                  <!-- statusFunction(HeadersDescription.status) -->
                  <span>{{ HeadersDescription.status_text }}</span>
                </div>
                <div class="review_difficulty">
                  <div class="review_difficulty_type">
                    <span>类型：</span>
                    <span>{{ HeadersDescription.task_type_text || "" }}</span>
                  </div>
                  <div class="review_difficulty_content">
                    <span>难度：</span>
                    <img
                      src="@/assets/start.png"
                      v-for="(s, k) in HeadersDescription.difficulty_text"
                      :key="k"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- @originevent="originevent" -->
            <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="userLog.length > 0 ? '没有更多了' : ''"
              @load="onLoad"
            >
              <description
                :acceptance_personnel="acceptance_personnel"
                :buttonClassfiy="buttonClassfiy"
                :details="details"
                :cardType="cardType"
                :statsus="HeadersDescription.status"
                :personAccept="personAccept"
                :children="children"
                @moreClickFunction="moreClickFunction"
                :userLog="userLog"
                :isTask="true"
                :isSelect="true"
                :personcarddate="personcarddate"
                :acceptance_personnelList="acceptance_personnelList"
                @tabbarClick="tabbarClick"
                :data="descriptionData"
                :createtime_text="createtime_text"
                :inputPersonTotaslArray="inputPersonTotaslArray"
                :currentIndex="currentIndex"
                @Refresh="Refresh"
                @release_reviewDescription="release_reviewDescription"
              />
            </van-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import publicNavigation from "@/publicNavigation/publicNavigation.vue";
import description from "../compoment/descriptionCard.vue";
import * as API from "@/api.ts";
export default {
  components: {
    publicNavigation,
    description,
  },
  data() {
    return {
      navigation: {},
      descriptionData: {},
      buttonClassfiy: "",
      HeadersDescription: {},
      page: 1,
      children: [],
      personAccept: [],
      cardType: "",
      details: "",
      userinfoId: "",
      createtime_text: "",
      acceptance_personnelList: [],
      personcarddate: [],
      userLog: [],
      currentIndex: 0,
      //填写人
      inputPersonTotaslArray: [],
      // 加载
      loading: false,
      finished: false, //是否加载完成
      value: false, //是否请求接口
    };
  },
  mounted() {
    // // 任务详情
    this.release_reviewDescription();

    // .then(async e=>{
    // this.createtime_text = e.createtime_text
    // 审核任务
    // if (this.$route.query.cardType == '审核任务') {
    // 	this.descriptionData = {
    // 		projects: objectEach.project_title,
    // 		taskNumber: e.workload,
    // 		date: this.createtime_text,
    // 		release: objectEach.create_name,
    // 		acceptance: objectEach.inspect_name ? objectEach.inspect_name.join('、') : '',
    // 		introduce: objectEach.introduce,
    // 		appendix: objectEach.attachmentInfo,
    // 		id: objectEach.id,
    // 		status_text: objectEach.status_text,
    // 		children: [],
    // 		inspect:objectEach.inspect,
    // 		step: [],
    // 		totalNumber: 0
    // 	}
    // }
    // })
    if (this.$route.query.buttonClassfiy != " 执行任务") {
      // this.Requset()
    }
    if (this.$route.query.buttonClassfiy == "发起任务") {
      this.cardType = "发起任务";
    }
    // 本人验收的任务
    this.initiate_list();
    this.acceptance_personnel();
    this.userinfoId = window.sessionStorage.getItem("userInfo")
      ? JSON.parse(window.sessionStorage.getItem("userInfo")).identity
      : "";
    this.buttonClassfiy = this.$route.query.buttonClassfiy;
    this.navigation = {
      image: require("../../assets/return.png"),
      name: "任务详情",
    };
    const localStorage = window.localStorage.getItem("taskDescription");
    const objectEach = JSON.parse(localStorage);
    console.log(objectEach);
    this.HeadersDescription = JSON.parse(localStorage);
    this.cardType = this.$route.query.cardType;
    this.acceptorPersonIds();
  },
  methods: {
    navigationClick() {
      this.$router.back(-1);
      sessionStorage.setItem("active", 0);
    },
    onLoad() {
      if (!this.finished) {
        this.loading = false;
        this.page++;
        this.Requset();
      } else {
        this.loading = false;
      }
    },
    taskLogUser(ids) {
      API.taskLogUser({
        id: ids,
        type: 1,
      }).then(async (e) => {
        if (e.code == 1) {
          this.inputPersonTotaslArray = e.data.map((item) => {
            return item.nickname;
          });
        }
      });
    },
    acceptance_personnel() {
      API.acceptance_personnel({
        ids:
          this.$route.query.id ||
          this.$route.query.taskID ||
          this.$route.query.rid,
      }).then(async (e) => {
        console.log(e);
        this.acceptance_personnel = e.data.rows;
      });
    },
    initiate_list() {
      API.self_check().then(async (e) => {
        if (e.code == 1) {
          this.personAccept = e.data.rows;
        }
      });
    },
    Requset() {
      API.self_task({
        page: this.page,
      }).then(async (e) => {
        if (e.code == 1) {
          if (e.data.lists) {
            if (e.data.lists.rows.length <= 0) {
              this.loading = true;
            }
          }
          this.descriptionData.totalNumber = e.data.rows;
          console.log(e.data.rows.length > 0);
          if (e.data.rows && e.data.rows.length > 0) {
            e.data.rows.map((item) => {
              if (
                item.status == this.HeadersDescription.status &&
                item.id == this.HeadersDescription.id
              ) {
                this.descriptionData.children.push({
                  projectName: item.project_title ? item.project_title : "",
                  ...item,
                  children: [
                    {
                      name: "执行人：",
                      text: item.nickname,
                    },
                    {
                      name: "工作量：",
                      text:
                        this.userinfoId != 4 && item.status_text == "审核中"
                          ? "-"
                          : item.workload + "天(预)",
                    },
                    {
                      name: "开始时间：",
                      text:
                        this.userinfoId != 4 && item.status_text == "审核中"
                          ? "-"
                          : item.real_starttime != null ||
                            item.real_starttime != ""
                          ? this.TimeFunction(item.real_starttime) + "(预)"
                          : "",
                    },
                    {
                      name: "完成时间：",
                      text:
                        this.userinfoId != 4 && item.status_text == "审核中"
                          ? "-"
                          : item.real_endtime != null || item.real_endtime != ""
                          ? this.TimeFunction(item.real_endtime) + "(预)"
                          : "",
                    },
                  ],
                  step: this.processFunction(item.process),
                });
              }
            });
          } else {
            this.finished = true;
          }
        }
      });
    },
    tabbarClick(e, start, ends, user_name) {
      if (!this.value) {
        this.page = 1;
        this.acceptance_personnelList = [];
        // this.release_reviewDescription();
        this.userLog = [];
        if (e == 1) {
          this.projectlists();
          this.currentIndex = 1;
          this.value = false;
        } else if (e == 3) {
          this.currentIndex = 3;
          // 奖励点
          this.personcarddate = [];
          API.single_reward({
            ids: window.localStorage.getItem("taskDescription")
              ? JSON.parse(window.localStorage.getItem("taskDescription")).id
              : this.$route.query.id ||
                this.$route.query.taskID ||
                this.$route.query.rid,
          }).then(async (e) => {
            if (e.code == 1) {
              this.personcarddate = e.data.map((item) => {
                return Object.assign(item, {
                  desc: [
                    {
                      name: "工作量：",
                      text: item.workload,
                    },
                    {
                      name: "奖励点：",
                      text: item.add_reward ? item.add_reward : 0,
                    },
                    {
                      name: "工期：",
                      text: item.timeout_text ? item.timeout_text : 0,
                    },
                    {
                      name: "额外奖励：",
                      text: item.other_reward ? item.other_reward : 0,
                    },
                    {
                      name: "难度：",
                      startNumber: item.difficulty_text,
                    },
                  ],
                });
              });
              this.value = false;
            }
          });
        } else if (e == 2) {
          this.value = true;
          this.inputPersonTotaslArray = [];
          this.userLog = [];
          this.taskLogUser(
            this.$route.query.taskID ||
              this.$route.query.id ||
              this.$route.query.rid
          );
          API.taskLog({
            id:
              this.$route.query.taskID ||
              this.$route.query.id ||
              this.$route.query.rid,
            page: this.page,
            limit: 10,
            start_time: start,
            end_time: ends,
            user_name: user_name,
          }).then(async (e) => {
            if (e.code == 1) {
              if (e.data.lists.rows.length > 0) {
                e.data.lists.rows.map((item) => {
                  // this.userLog.push({
                  //   ...item,
                  //   lists: [item],
                  // });
                  this.userLog.push({
                    ...item,
                    lists: [item],
                  });
                });
                this.value = false;
                // this.userLog = [...this.userLog, e.data.lists.rows.map(item=>{
                //   return{
                //     lists:[item]
                //   }
                // })];
              } else {
                this.value = false;
                // this.$toast.fail('暂无数据')
              }
            }
          });
        }
      }
    },
    projectlists() {
      API.projectlists({
        task_id:
          this.$route.query.taskID ||
          this.$route.query.id ||
          this.$route.query.rid,
      }).then(async (e) => {
        if (e) {
          console.log(e);
          this.acceptance_personnelList = e.data.rows;
        }
      });
    },
    release_reviewDescription() {
      // return new Promise((resolve)=>{
      // console.log(this.$route.query)
      // if (this.$route.query.cardType == '审核任务') {
      // 	API.release_reviewDescription({
      // 		ids: this.$route.query.rid
      // 	}).then(async e => {
      // 		if (e.code == 1) {
      // 			console.log(e.data,'=======================================================================')
      // 			this.details = e.data
      // 			return resolve(e.data)
      // 			// 审核详情
      // 			// e.data.rows.map(item => {
      // 			// 	this.descriptionData.children.push({
      // 			// 		projectName: item.title,
      // 			// 		...item,
      // 			// 		children: [{
      // 			// 			name: '执行人：',
      // 			// 			text: item.user_nickname
      // 			// 		}, {
      // 			// 			name: '工作量：',
      // 			// 			text: item.workload + '天(预)'
      // 			// 		}, {
      // 			// 			name: '开始时间：',
      // 			// 			text: item.starttime_text || item.starttime ? item.starttime_text + '(预)' || this.TimeFunction(item.starttime) + '(预)' : ''
      // 			// 		}, {
      // 			// 			name: '完成时间：',
      // 			// 			text: item.endtime_text || item.updatetime ? item.endtime_text + '(预)' || this.TimeFunction(item.updatetime) + '(预)' : ''
      // 			// 		}],
      // 			// 		step: this.processFunction(item.process)
      // 			// 	})
      // 			// })
      // 		}
      // 	})
      // } else {
      API.details({
        ids:
          this.$route.query.taskID ||
          this.$route.query.id ||
          this.$route.query.rid,
      }).then(async (e) => {
        if (e.code == 1) {
          window.localStorage.setItem(
            "taskDescription",
            JSON.stringify(e.data.PidInfo)
          );
          // ======================
          this.details = e.data;
          this.createtime_text = e.data.PidInfo.createtime_text;
          this.HeadersDescription.status_text = e.data.PidInfo.status_text;
          this.HeadersDescription.task_type_text =
            e.data.PidInfo.task_type_text;
          // 任务详情
          // 执行任务
          // if (this.$route.query.buttonClassfiy) {
          // this.objectEach = objectEach
          this.descriptionData = {
            projects: e.data.project.title,
            taskNumber: e.data.project.correlation_count,
            date: e.data.PidInfo.createtime_text,
            release: e.data.PidInfo.create_nickname,
            // acceptance: objectEach.inspect_name ? objectEach.inspect_name.join('、') : '',
            introduce: e.data.PidInfo.introduce,
            appendix: e.data.PidInfo.attachmentInfo
              ? e.data.PidInfo.attachmentInfo
              : e.data.PidInfo.attachment_info,
            id: e.data.PidInfo.id,
            inspect: e.data.PidInfo.inspect,
            children: [],
            user_id: e.data.PidInfo.user_id,
            step: [],
            totalNumber: 0,
            taskimg_info: e.data.PidInfo.taskimg_info,
            status_text: e.data.PidInfo.status_text,
            // status:e.PidInfo.status
          };
          // }
          e.data.rows.map((item) => {
            this.descriptionData.children.push({
              projectName: item.title,
              ...item,
              step: this.processFunction(item.process),
              children: [
                {
                  name: "执行人：",
                  text:
                    item.catch_modality == 1
                      ? "公共接单"
                      : item.catch_modality == 2
                      ? item.section_name
                      : item.catch_modality == 3
                      ? item.user_nickname
                      : "",
                },
                {
                  name: "工作量：",
                  text: item.workload,
                  // item.catch_modality + ' ' +
                  // !item.workload ? "-" : item.process.length <= 0 ? item.workload + "天(预)" : item.timeout_text + "天"
                },
                {
                  name: "开始时间：",
                  text:
                    !item.starttime_text && !item.real_starttime_text
                      ? "-"
                      : item.process.length > 0
                      ? item.process[item.process.length - 1]
                          .real_starttime_text
                        ? item.process[item.process.length - 1]
                            .real_starttime_text
                        : item.real_starttime_text
                        ? item.real_starttime_text
                        : item.starttime_text + "(预)"
                      : item.starttime_text
                      ? item.starttime_text
                      : "",
                  // || this.TimeFunction(item.starttime) + '(预)' : '': item.real_starttime_text || item.starttime_text + "(预)"
                  // (this.userinfoId != 4 && this.statusFunction(item.status) == "待接单") || (this.userinfoId != 4 && item.status_text == "审核中")
                },
                {
                  name: "完成时间：",
                  text:
                    !item.starttime_text && !item.real_starttime_text
                      ? "-"
                      : item.process.length > 0
                      ? item.process[item.process.length - 1].real_endtime_text
                        ? item.process[item.process.length - 1]
                            .real_endtime_text
                        : item.endtime_text || item.updatetime
                        ? item.endtime_text
                        : ""
                      : item.endtime_text,
                  timeout_text: item.status == 5 ? "预" : item.timeout_text,
                },
                ...item.suspend,
              ],
            });
          });
        }
      });
      // }
      // })
    },
    Refresh() {
      this.release_reviewDescription();
    },
    calculate() {},
    TimeFunction(e) {
      console.log(e);
      const date = new Date(e * 1000),
        Y = date.getFullYear(),
        M = date.getMonth(),
        R = date.getDate();
      return (
        Y + "-" + (M < 10 ? "0" + (M + 1) : M) + "-" + (R < 10 ? "0" + R : R)
      );
    },
    processFunction(e) {
      console.log(e);
      let data = [];
      if (e.length > 0) {
        e.map((items) => {
          data.push({
            name: items.sequence,
            text: items.title,
            ...items,
          });
        });
      }
      return data;
    },
    statusFunction(e) {
      let name;
      switch (e) {
        case 1:
          if (e && this.HeadersDescription.type == 1) {
            name = "待审核";
          } else if (e && this.HeadersDescription.type == 2) {
            name = "工期审核";
          } else {
            name = "待接单";
          }
          break;
        case 2:
          name = "待拆解";
          break;
        case 3:
          if (e && this.HeadersDescription.type == 1) {
            name = "待审核";
          } else if (e && this.HeadersDescription.type == 2) {
            name = "工期审核";
          } else {
            name = "待审核";
          }
          break;
        case 4:
          name = "未开始";
          break;
        case 5:
          name = "审核中";
          break;
        case 6:
          name = "验收中";
          break;
        case 7:
          name = "已完成";
          break;
      }
      return name;
    },
    moreClickFunction() {
      this.page += 1;
      this.$toast({
        type: "loading",
        message: "加载中...",
      });
      setTimeout(() => {
        this.Requset();
      }, 1000);
    },
    acceptorPersonIds() {
      let person = window.localStorage.getItem("acceptorPersonIds")
        ? JSON.parse(window.localStorage.getItem("acceptorPersonIds"))
            .acceptPerson
        : "";
      let newperson = [];
      console.log(person);
      if (person.length > 0) {
        person.map((item) => {
          newperson.push(item.id);
        });
      }
      if (person && newperson.length > 0) {
        API.modified({
          ids: window.localStorage.getItem("taskDescription")
            ? JSON.parse(window.localStorage.getItem("taskDescription")).id
            : this.$route.query.id,
          inspect_ids: newperson.join(","),
        }).then(async (e) => {
          if (e.code == 1) {
            this.release_reviewDescription();
            // 本人验收的任务
            this.initiate_list();
            this.acceptance_personnel();
          }
          window.localStorage.setItem("acceptorPersonIds", "");
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.background_colors {
  height: calcFunction(190);
  // position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(0deg, #feefef 0%, #ffffff 100%);
}

.review_description {
  //height: 100vh;
  // background: #F1F0F5;
  .navigation_header {
    z-index: 2;
  }

  .review_description_content {
    .review_description_content_header {
      .review_header_content_title {
        padding-top: 0.4rem;
        .review_header_content {
          margin: 0 calcFunction(20);

          .review_title {
            @extend .alignItemsCenter;
            justify-content: space-between;

            span {
              color: #111111;
              font-size: calcFunction(15);
            }

            span:nth-last-child(1) {
              color: #ff4200;
            }
          }

          .review_difficulty {
            margin-top: calcFunction(10);
            @extend .alignItemsCenter;

            .review_difficulty_type {
              span {
                font-size: calcFunction(14);
                color: #111111;
              }

              span:nth-child(1) {
                color: #666666;
              }
            }

            .review_difficulty_content {
              @extend .alignItemsCenter;
              margin-left: calcFunction(40);

              span {
                font-size: calcFunction(13);
                color: #666666;
              }

              img {
                width: calcFunction(14);
                height: calcFunction(14);
                margin-right: calcFunction(4);
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.review_description_classfiy {
  height: 100%;
  /* position: fixed; */
  width: 100%;
}
</style>