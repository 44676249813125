<template>
  <div
    class="overdraget_person"
    style="background: white; padding-bottom: 1rem"
  >
    <div class="overdraget_person_content">
      <div class="overdraget_person_content_box">
        <div class="overdr_content">
          <div class="overdrage_person_content_header">
            <publicNavigation @sureEvenet="sureEvenet" :data="navigation" />
          </div>
          <van-search
            v-model="value"
            @clear="clear"
            placeholder="请输入搜索关键词"
          />
        </div>
        <div class="overdrage_content_list">
          <div class="overdrage_stolp">
            <div
              class="oberdrage_stolpe_list"
              @click="personClick(s, k)"
              v-for="(s, k) in personData"
              :key="k"
            >
              <span>{{ s.nickname }}</span>
              <img v-if="s.check == true" :src="icon" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import publicNavigation from "@/publicNavigation/publicNavigation.vue";
import * as API from "@/api.ts";
export default {
  components: {
    publicNavigation,
  },
  data() {
    return {
      navigation: {},
      icon: require("@/assets/redsave.png"),
      personIndex: 0,
      personData: [],
      personName: "",
      origin: window.localStorage.getItem("sure"),
      // 多人选择
      personSelect: [],
      // 搜索
      value: "",
    };
  },
  watch: {
    value(e) {
      let data = [];
      if (e) {
        this.personData.map((items) => {
          data.push(items.nickname);
          const newText = data.filter((wordl) => wordl.includes(e));
          newText.map((i) => {
            console.log(i, items.nickname);
            if (i == items.nickname) {
              this.personData = [];
              setTimeout(() => {
                this.personData.push(
                  Object.assign(items, {
                    check: false,
                  })
                );
              }, 800);
              console.log(this.personData);
            }
          });
        });
      } else {
        setTimeout(() => {
          this.personData = [];
          this.Request();
        }, 800);
      }
    },
  },
  mounted() {
    this.navigation = {
      image: require("@/assets/return.png"),
      name: "验收人",
      sure: "确定",
      close: "",
    };
    this.Request();
  },
  methods: {
    Request() {
      API.userList().then(async (e) => {
        if (e.code == 1) {
          this.personData = e.data.rows.map((items) => {
            return Object.assign(items, {
              check: false,
            });
          });
        }
      });
    },
    personClick(s, k) {
      this.personData[k].check = !this.personData[k].check;
      if (s.check == true) {
        if (!this.personSelect.includes(this.personData[k])) {
          this.personSelect.push(this.personData[k]);
        }
      } else {
        let index = this.personSelect.indexOf(s);
        this.personSelect.splice(index, 1);
      }
    },
    async sureEvenet() {
      this.personSelect.map((items, index) => {
        if (items.id == this.personSelect[index].id) {
          return items;
        }
      });
      if (this.personSelect.length > 0) {
        const parms = {
          acceptPerson: this.personSelect,
        };
        let newObject;
        if (this.origin) {
          newObject = Object.assign(JSON.parse(this.origin), parms);
        } else {
          newObject = Object.assign(parms);
        }
        if (parms.acceptPerson) {
          if (this.$route.query.acceptorPerson == 1) {
            window.localStorage.setItem(
              "acceptorPersonIds",
              JSON.stringify(newObject)
            );
          } else {
            window.localStorage.setItem("sure", JSON.stringify(newObject));
          }
          let { id } = this.$route.query;
          if (id) {
            let _ids = newObject.acceptPerson.map((item) => {
              return item.id;
            });
            let params = {
              ids: id,
              inspect_ids: _ids.join(","),
            };
            let { code, msg } = await API.modified(params);
            if (code == 1) {
              this.$toast(msg);
              setTimeout(() => {
                this.$router.back(-1);
              }, 500);
            } else {
              this.$toast(msg);
            }
          } else {
            this.$router.back(-1);
          }
        }
      } else {
        this.$toast.fail("请选择验收人");
      }
    },
    clear() {
      this.personData = [];
      this.Request();
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.overdraget_person {
  .overdraget_person_content {
    .overdraget_person_content_box {
      .overdrage_person_content_header {
      }

      .overdrage_content_list {
        margin: calcFunction(10);

        .overdrage_content_data {
          @extend .alignItemsCenter;
          justify-content: space-between;

          button {
            width: 32%;
            padding: calcFunction(12) 0;
            font-size: calcFunction(13);
            background: #f1f0f5;
            border: none;
            outline: none;
            border-radius: calcFunction(4);
          }
        }

        .overdrage_stolp {
          margin-top: calcFunction(30);

          .oberdrage_stolpe_list {
            margin-bottom: calcFunction(14);
            padding-bottom: calcFunction(14);
            @extend .alignItemsCenter;
            justify-content: space-between;
            border-bottom: 1px solid #eee;

            span {
              font-size: calcFunction(14);
              color: #111111;
            }

            img {
              width: calcFunction(13);
              height: calcFunction(11);
            }
          }
        }
      }
    }
  }
}

.active {
  background: #ffeaea !important;
  border: 0.5px solid #d71518 !important;
  color: #d71518;
  border-radius: calcFunction(4);
}

.overdr_content {
  position: sticky;
  top: 0;
  background: white;
}
</style>