<!--
 * @Author: Mr.Cheng
 * @Date: 2023-09-01 14:31:55
 * @LastEditTime: 2023-09-08 09:46:10
 * @FilePath: \octopus-factory-map\src\projectList\compoments\descriptionCard.vue
-->
<template>
    <div class="projekt">
        <div class="projekt_name">
            <div class="projekt_title">
                <span>{{ projekt.name }}</span>
            </div>
            <div class="projekt_desc">
                <div class="desc" v-for="s,k in projekt.children" :key="k">
                    <span>{{ s.name }}</span>
                    <span>{{ s.text }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        projekt:Object
    }
}
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter{
    display: flex;
    align-items: center;
}
  
// 计算函数
@function calcFunction($number,$default:16){
  @return calc($number * 2 / 100) + rem;
}
.projekt{
    margin: 0 calcFunction(20);
    .projekt_name{
        .projekt_title{
            span{
                font-size: calcFunction(15);
                color: #111111;
                font-weight: bold;
            }
        }
        .projekt_desc{
            @extend .alignItemsCenter;
            margin-top: calcFunction(10);
			flex-wrap: wrap;
			justify-content: space-between;
            .desc{
                @extend .alignItemsCenter;
                margin-right: calcFunction(10);
				margin-bottom: calcFunction(10);
                span{
                    font-size: calcFunction(14);
                    color: #666666;
                }
                span:nth-last-child(1){
                    color: #111111;
                }
            }
			.desc{}
        }
    }
}
</style>