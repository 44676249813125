var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"top-box"},[_c('div',{staticClass:"flex-c-c task-navigate-box"},[_c('img',{staticClass:"task-left-back",attrs:{"src":require("@/assets/return.png"),"alt":""},on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('div',[_vm._v("审核任务")])]),_c('van-search',{attrs:{"shape":"round","background":"#FFF","placeholder":"输入项目关键字查询"},on:{"search":_vm.onSearch,"clear":_vm.clear},model:{value:(_vm.project_title),callback:function ($$v) {_vm.project_title=$$v},expression:"project_title"}}),_c('van-dropdown-menu',[_c('van-dropdown-item',{ref:"statusDom",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_vm._v(_vm._s(_vm.status_text || "审核类型"))])]},proxy:true}])},[_c('div',_vm._l((_vm.typeList),function(item){return _c('div',{key:item.status,class:[
              'list',
              'flex-c-c',
              item.status == _vm.status ? 'highlight' : '',
            ],on:{"click":function($event){return _vm.onConfirmStatus(item)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0)]),_c('van-dropdown-item',{ref:"dateDom",scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.start_time && _vm.start_time != _vm.end_time)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.start_time)+" ~")]),_c('div',[_vm._v(_vm._s(_vm.end_time))])]):(_vm.start_time && _vm.start_time == _vm.end_time)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.start_time))])]):_c('div',[_vm._v("选择时间")])]},proxy:true}])},[_c('div',[_c('van-calendar',{style:({ height: '8rem' }),attrs:{"show-title":false,"type":"range","poppable":false,"min-date":new Date(2023, 0, 1),"max-date":new Date(),"allow-same-day":true,"show-confirm":false},on:{"confirm":_vm.confirmDate},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"clear flex-c-c",on:{"click":_vm.clearDate}},[_vm._v("清除")])]},proxy:true}])})],1)]),_c('van-dropdown-item',{ref:"createDom",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_vm._v(_vm._s(_vm.create_text || "发布人"))])]},proxy:true}])},[_c('div',_vm._l((_vm.createList),function(item,index){return _c('div',{key:index,class:[
              'list',
              'flex-c-c',
              item.id == _vm.create_id ? 'highlight' : '',
            ],on:{"click":function($event){return _vm.onConfirmCreate(item)}}},[_vm._v(" "+_vm._s(item.nickname)+" ")])}),0)])],1)],1),(_vm.list.length > 0)?_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('div',{staticClass:"task-list-box"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"task-list mb10",on:{"click":function($event){return _vm.$router.push({ path: '/auditListDetail', query: { id: item.id } })}}},[_c('div',{staticClass:"task-list-top"},[_c('div',{staticClass:"flex-c-b",staticStyle:{"align-items":"flex-start"}},[(item.review_type == 1)?_c('div',{staticClass:"task-name mr20"},[(item.pid_title)?_c('div',[_vm._v(" "+_vm._s(item.pid_title)+"——"+_vm._s(item.title)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.title)+" ")])]):_c('div',{staticClass:"task-name"},[_c('span',[_vm._v(_vm._s(item.title))]),(item.inspect_name)?_c('span',[_vm._v("——"+_vm._s(item.inspect_name))]):_vm._e()]),_c('div',{staticClass:"status_text"},[_vm._v(" "+_vm._s(item.type_text)+" ")])]),_c('van-rate',{staticClass:"mt5",attrs:{"size":"15px","color":"#D71518","void-color":"#FFF","value":Number(item.difficulty_text),"readonly":""}})],1),_c('div',{class:['task-list-content', item.status == 7 ? 'gray' : '']},[_c('div',{staticClass:"flex-c-b"},[_c('div',{staticClass:"label"},[_vm._v("归属项目：")]),_c('div',{class:['value', 'line', item.status == 7 ? 'gray' : 'blue'],on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                  path: '/projectDetail',
                  query: { id: item.project_id },
                })}}},[_c('div',[_vm._v(_vm._s(item.project_title))])])]),_c('div',{staticClass:"flex-c-b mt10"},[_c('div',{staticClass:"label"},[_vm._v("发布人：")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(item.create_name))])]),_c('div',{staticClass:"flex-c-b mt10"},[_c('div',{staticClass:"label"},[_vm._v("开始时间：")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(item.starttime_text || "--"))])]),_c('div',{staticClass:"flex-c-b mt10"},[_c('div',{staticClass:"label"},[_vm._v("完成时间：")]),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(item.real_endtime_text || item.endtime_text || "--")),(item.timeout_text && item.endtime_text)?_c('span',{class:[item.status == 7 ? 'gray' : ''],style:(item.status == 7 ? '' : 'color: #d71518')},[_vm._v("("+_vm._s(item.timeout_text)+")")]):_vm._e()])])])])}),0)]):_c('van-empty',{attrs:{"description":"暂无数据"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }