<template>
  <van-swipe
    class="my-swipe card_list_data"
    :loop="false"
    indicator-color="rgba(215, 21, 24, 1)"
  >
    <van-swipe-item v-for="(s, k) in cardListData" :key="k">
      <div
        class="list_data"
        @click="eventClick(j, l)"
        v-for="(j, l) in s"
        :key="l"
      >
        <div class="list_data_color" v-if="j.name">
          <div class="list_image">
            <img :src="j.app_icon ? requset + j.app_icon : ''" alt="" />
          </div>
          <div class="list_span">
            <span>{{ j.title || "补充" }}</span>
          </div>
        </div>
      </div>
    </van-swipe-item>
  </van-swipe>
</template>
<script>
export default {
  props: {
    cardListData: Array,
  },
  data() {
    return {
      requset: "https://api.octopusworks.com",
      user: "",
    };
  },
  mounted() {
    if (window.sessionStorage.getItem("userinfo")) {
      this.user = JSON.parse(window.sessionStorage.getItem("userinfo"));
    }
    console.log(this);
  },
  methods: {
    eventClick(e, index) {
      this.$emit("eventClick", e, index);
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}
.card_list_data {
  height: 100%;
  margin: 0.2rem;
  background: white;
  border-radius: 0.16rem;
}
.my-swipe {
  .van-swipe-item {
    font-size: 0.22rem;
    text-align: center;
    // @extend .alignItemsCenter;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0.4rem 0.5rem;
    display: grid;
    grid-template-rows: repeat(2);
    grid-template-columns: repeat(2, 1fr 1fr);
    grid-column-gap: 10%;
    .list_data {
      //   margin-right: .759rem;

      margin-bottom: 0.3rem;
      .list_data_color {
        height: 100%;
        width: 100%;
        @extend .alignItemsCenter;
        flex-direction: column;
        .list_image {
          img {
            @extend .alignItemsCenter;
            justify-content: center;
            width: 0.9rem;
            height: 0.9rem;
          }
        }
        .list_span {
          margin-top: 0.2rem;
          span {
            font-size: 0.22rem;
          }
        }
      }
    }
    .list_data:nth-child(4n) {
      margin-right: 0;
    }
  }
}
</style>
