<template>
    <div class="header">
        <div class="header_box">
            <div class="box_card">
                <div class="card_list">
                    <div class="card_title">
                        <div class="title">
                            <span>CP值项目</span>
                        </div>
                        <div class="text">
                            <span>{{ userOrder.total }}</span>
                        </div>
                    </div>
                </div>
                <div class="card_list">
                    <div class="card_title">
                        <div class="title">
                            <span>分红金额</span>
                        </div>
                        <div class="text">
                            <span>{{ userOrder.all_divvy }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
	props:{
		cpNumber:Number,
        userOrder:Object
	},
    data(){
        return{
            cardData:Array
        }
    },
}

</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter{
    display: flex;
    align-items: center;
}
  
// 计算函数
@function calcFunction($number,$default:16){
  @return calc($number * 2 / 100) + rem;
}

.header{
    margin: calcFunction(10);
    .header_box{
        background: white;
        padding: calcFunction(17.5) calcFunction(22);
        border-radius: calcFunction(4);
        .box_card{
            @extend .alignItemsCenter;
            justify-content: space-between;
            .card_list{
                width: 50%;
                .card_title{
                    .title{
                        span{
                            font-size: calcFunction(12);
                        }
                    }
                    .text{
                        margin-top: calcFunction(5);
                        span{
                            font-size: calcFunction(25);
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}
</style>