<template>
  <div class="team">
    <div class="team_box">
      <div class="team_box_content">
        <div class="team_header">
          <publicNavigation
            :data="navigation"
            @currentClick="currentClick"
            :position="position"
          />
          <div class="team_search">
            <van-search
              shape="round"
              v-model="value"
              placeholder="请输入名称"
            />
          </div>
        </div>
        <div class="team_content">
          <div class="team_content_list">
            <!-- :mypersoninfomation="mypersoninfomation"  @descriptionClick="descriptionClick"  -->
            <deparmentData
              @userDescription="userDescription"
              :deparmentData="deparmentData"
              v-if="currentIndex == 0"
            />
            <gradeCard
              :rankingList="rankingList"
              :threeList="threeList"
              v-if="currentIndex == 1"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import publicNavigation from "@/publicNavigation/publicNavigation.vue";
import deparmentData from "./compoments/teamCard.vue";
import gradeCard from "@/leaderboard/components/gradeCard/gradeCard.vue";
import * as API from "@/api.ts";
import { number } from "echarts";
export default {
  components: {
    publicNavigation,
    deparmentData,
    gradeCard,
  },
  data() {
    return {
      navigation: {},
      value: "",
      deparmentData: [],
      page: 1,
      position: ["按岗位", "按排行"],
      currentIndex: 0,
      rankingList: [],
      threeList: [],
      mypersoninfomation: "",
    };
  },
  watch: {
    value(e) {
      this.search(e);
    },
  },
  mounted() {
    this.navigation = {
      image: require("../assets/return.png"),
      name: "我的团队",
      toggle: 1,
      close: "",
    };
    this.Request();
    // window.addEventListener("scroll", () => {
    //   const clientHeight = window.innerHeight;
    //   const scrollHeight =
    //     document.body.scrollHeight || document.documentElement.scrollHeight;
    //   const scrollTop = window.scrollY;
    //   console.log(clientHeight, scrollHeight, scrollTop);
    //   if (scrollTop + clientHeight > scrollHeight - 1) {
    //     this.page++;
    //     // this.$toast.loading({message:'加载中'})
    //     setTimeout(() => {
    //       if (this.currentIndex == 1) {
    //         this.currentIndex = 1;
    //         this.currentClick();
    //       } else {
    //         this.Request();
    //       }
    //     }, 800);
    //   }
    // });
  },
  methods: {
    Request(e) {
      API.team({
        page: this.page,
        limit: 10,
        search: e ? e : "",
      }).then(async (e) => {
        if (e.code == 1) {
          e.data.rows.map((item) => {
            this.deparmentData.push({
              name: item.nickname,
              image: item.avatar,
              // name: item.nickname,
              intergral: item.level,
              icon: item.role,
              totalNumber: item.ranking,
              number: item.task_count,
              ...item,
            });
          });
        }
      });
    },
    userDescription(s) {
      console.log(1111);
      window.localStorage.setItem("mydesc", JSON.stringify(s));
      
      if (typeof s.id == 'number') {
        this.$router.push({
          name: "mydesc",
          path: "/mydesc",
          query: {
            description: s.id,
          },
        });
      } else {
        this.$router.push({
          name: "mydesc",
          path: "/mydesc",
          query: {
            description: s.id.id,
          },
        });
      }
    },
    search(e) {
      clearTimeout(this.timeFunction);
      this.deparmentData = [];
      this.timeFunction = setTimeout(() => {
        this.Request(e);
      }, 800);
    },
    currentClick(e) {
      this.threeList = [];
      this.currentIndex = e ? e : 1;
      API.rankingList({
        page: this.page,
        limit: 10,
      }).then(async (e) => {
        if (e.code == 1) {
          this.mypersoninfomation = e.data.admin;
          e.data.first.map((items) => {
            this.threeList.push({
              ...items,
              name: items.nickname,
              image: items.avatar,
              position: items.section,
              intergral: items.score,
              number: items.level,
            });
          });
          let data = this.threeList[0];
          this.threeList[0] = this.threeList[1];
          this.threeList[0] = data;
          e.data.rows.map((item) => {
            if (item.ranking < 4) {
              console.log(1123);
            } else {
              console.log(
                this.currentIndex,
                "=========================================="
              );
              this.rankingList.push({
                ...item,
                name: item.nickname,
                image: item.avatar,
                number: item.task_count,
                grade: item.level,
                ranking: item.ranking,
              });
            }
          });
        }
      });
    },
    // },
  },
};
</script>
<style lang="scss" scoped>
.team_header {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}

// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.team {
  height: 100vh;
  // background: #f1f0f5;

  .team_box {
    background: white;

    .team_search {
      .van-search {
        padding-top: 0;
      }
    }
  }
}
</style>