<!--
 * @Author: Mr.Cheng
 * @Date: 2023-10-08 10:21:31
 * @LastEditTime: 2023-10-12 11:15:48
 * @FilePath: \octopus-factory-map\src\new\perfrom\components\taskLog.vue
-->
<template>
  <div>
    <!-- 填写人&日期&填写日志 -->
    <div class="flex-c-b log-box mt5">
      <!-- 填写人&日期 -->
      <div class="flex-c">
        <div class="flex-c select-box mr20">
          <div @click="inputPersonClicks" class="select-text mr5">
            {{ writePerson || "填写人" }}
          </div>
          <img
            @click="$emit('clearWrite')"
            class="clear-logo"
            v-show="writePerson && writePerson != '全部'"
            src="@/assets/errornumber.png"
            alt=""
          />
          <img
            v-show="!writePerson"
            class="select-logo"
            src="@/assets/bottom.png"
            alt=""
          />
        </div>
        <div class="flex-c select-box">
          <div @click="$emit('showDate')" class="select-text mr5">
            {{ date || "日期" }}
          </div>
          <img
            @click="$emit('clearDate')"
            class="clear-logo"
            v-show="date"
            src="@/assets/errornumber.png"
            alt=""
          />
          <img
            v-show="!date"
            class="select-logo"
            src="@/assets/bottom.png"
            alt=""
          />
        </div>
      </div>
      <!-- 填写日志 -->
      <div @click="$emit('toWriteLog')" class="write-log-btn">填写日志</div>
    </div>
    <!-- 日志列表 -->
    <div v-if="list.length > 0" class="log-list-box">
      <div v-for="item of logListData" :key="item.id" class="log-list mb10">
        <!-- 日志列表顶部 -->
        <div class="flex-c">
          <img class="calendar mr10" src="@/assets/calendar.png" alt="" />
          <div class="date-text">
            {{ item[0].dailyplantime_text }}
          </div>
        </div>
        <!-- 日志内容盒子 -->
        <template v-if="item.length > 0">
          <div
            v-for="logList of item"
            :key="logList.id"
            class="log-content mt15"
          >
            <!-- 日志顶部 -->
            <div class="log-content-top">
              <div class="flex-c">
                <img
                  class="log-content-user mr5"
                  src="@/assets/task-user.png"
                  alt=""
                />
                <div class="log-content-name">{{ logList.nickname }}</div>
              </div>
            </div>
            <div class="log-content-box">
              <!-- 项目任务 -->
              <div class="project-task flex-c">
                <div class="label mr5">项目任务：</div>
                <div class="value">{{ logList.title }}</div>
              </div>
              <!-- 日志内容 -->
              <div class="mt5 log-text">
                {{ logList.content }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <van-empty v-else class="custom-image" description="暂无任务日志">
      <template #image>
        <img class="null-img" src="@/assets/nulldata.png" alt="" />
      </template>
    </van-empty>
  </div>
</template>

<script>
export default {
  name: "taskLog",
  props: ["id", "writePerson", "date", "list"],
  data() {
    return {
      writePersonShow: false, //填写人弹窗显隐
      logListData: [],
    };
  },
  watch: {
    list(newVal) {
      var arrayTwo = Object.values(
        newVal.reduce((res, item) => {
          res[item.dailyplantime_text]
            ? res[item.dailyplantime_text].push(item)
            : (res[item.dailyplantime_text] = [item]);
          return res;
        }, {})
      );
      this.logListData = arrayTwo;
    },
  },
  methods: {
    // 填写人弹窗显示
    inputPersonClicks() {
      this.$emit("writePersonShow");
      this.writePersonShow = true;
    },
    
  },
};
</script>

<style lang="scss" scoped>
::v-deep .custom-image .van-empty__image {
  height: auto !important;
}
// 空状态图片
.null-img {
  width: 2.72rem;
  height: 1.96rem;
}
.label {
  font-size: 0.24rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
  white-space: nowrap;
}
.value {
  padding: 0.08rem 0.2rem;
  box-sizing: border-box;
  background: #ffe8e8;
  border-radius: 0.04rem;
  font-size: 0.24rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #d71518;
}
.clear-logo {
  width: 0.25rem;
  height: 0.25rem;
}
.log-box {
  padding: 0.2rem;
  box-sizing: border-box;
  .select-box {
    padding: 0.12rem 0.21rem;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 0.08rem;
    .select-text {
      font-size: 0.26rem;
      font-weight: 500;
      color: #333333;
    }
    .select-logo {
      width: 0.13rem;
      height: 0.07rem;
    }
  }
  // 填写日志按钮
  .write-log-btn {
    padding: 0.12rem 0.29rem;
    box-sizing: border-box;
    white-space: nowrap;
    background: #d71518;
    border-radius: 0.08rem;
    font-size: 0.26rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
}

// 任务日志列表盒子
.log-list-box {
  padding: 0.2rem;
  box-sizing: border-box;

  .log-list {
    padding: 0.2rem;
    box-sizing: border-box;
    width: 100%;
    background: #ffffff;
    border-radius: 0.16rem;

    // 日期logo
    .calendar {
      width: 0.26rem;
      height: 0.25rem;
    }

    // 日期内容
    .log-content {
      width: 100%;
      background: #fafafa;
      border: 0.01rem solid #dddddd;
      border-radius: 0.08rem;
      // 日期内容顶部
      .log-content-top {
        border-bottom: 0.01rem solid #eeeeee;
        padding: 0.2rem;
        box-sizing: border-box;

        // logo
        .log-content-user {
          width: 0.2rem;
          height: 0.26rem;
        }
        // 填写人
        .log-content-name {
          font-size: 0.28rem;
          font-family: PingFang SC;
          font-weight: bold;
          color: #111111;
        }
      }

      // 日志内容
      .log-content-box {
        padding: 0.2rem;
        box-sizing: border-box;

        .log-text {
          font-size: 0.24rem;
          font-family: PingFang SC;
          font-weight: 500;
          white-space: pre-line;
          color: #333333;
        }
      }
    }
  }
}
</style>