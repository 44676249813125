export class settingRewards{
    init(){
        return{
            cardListData:[{
                name:'姓名：',
                text:'张三'
            },{
                name:'工作量：',
                text:'20天'
            },{
                name:'难度：',
                number:3
            },{
                name:'标准奖励点：',
                text:'60'
            },{
                name:'工期：',
                text:'延期3天'
            },{
                name:'绩效奖励点：',
                text:'-10'
            },{
                name:'天数：',
                text:'2天'
            }]
        }
    }
}