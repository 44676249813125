<template>
  <div class="singleReward-box">
    <!-- 任务奖励列表 -->
    <template v-if="list.length > 0">
      <div
        v-for="(item, index) of list"
        :key="index"
        class="singleReward-list mb10"
      >
        <!-- 任务奖励列表顶部 -->
        <div class="singleReward-list-top flex-c-b">
          <div class="flex-c">
            <img
              class="task-user-logo mr5"
              src="@/assets/task-user.png"
              alt=""
            />
            <div class="task-user-name">{{ item.user_nickname }}</div>
          </div>
          <!-- 操作按钮 -->
          <div class="flex-c">
            <div
              @click="$emit('correctShow', item)"
              v-if="identity == 4"
              class="btnStyGray ml10"
            >
              修正
            </div>
            <div @click="toDetail(item)" class="btnStyGray ml10">详情</div>
          </div>
        </div>
        <!-- 任务奖励内容 -->
        <div class="singleReward-content">
          <div class="flex-c mt10">
            <div class="flex-c" style="width: 50%">
              <div class="label">工作量：</div>
              <div class="value">{{ item.workload }}</div>
            </div>
            <div class="flex-c" style="width: 50%">
              <div class="label">奖励点：</div>
              <div class="value">{{ item.add_reward || "0" }}</div>
            </div>
          </div>
          <div class="flex-c mt10">
            <div class="flex-c" style="width: 50%">
              <div class="label">工期：</div>
              <div class="value">{{ item.timeout_text }}</div>
            </div>
            <div class="flex-c" style="width: 50%">
              <div class="label">额外奖励点：</div>
              <div class="value">{{ item.other_reward || "0" }}</div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <van-empty v-else class="custom-image" description="暂无任务奖励">
      <template #image>
        <img class="null-img" src="@/assets/nulldata.png" alt="" />
      </template>
    </van-empty>
  </div>
</template>

<script>
import * as API from "@/api.ts";
export default {
  props: ["id"],
  data() {
    return {
      list: [], //任务奖励列表
      identity: "", //身份：1员工，2主管，3人事财务，4总经理
      userId: "", //用户id
    };
  },
  mounted() {
    console.log(this.id);

    //获取用户信息
    this.getUserInfo();
    this.$nextTick(() => {
      // 获取任务奖励列表
      setTimeout(() => {
        this.getList();
      }, 500);
    });
  },
  methods: {
    // 获取任务奖励列表
    async getList() {
      let _this = this;
      let params = {
        ids: this.id,
      };
      let { code, data } = await API.single_reward(params);
      if (code == 1) {
        _this.list = data;
      }
    },
    //获取用户信息
    async getUserInfo() {
      let { code, data } = await API.userInfo();
      if (code == 1) {
        this.identity = data.identity;
        this.userId = data.id;
      }
    },
    //跳转至任务奖励详情
    toDetail(item) {
      sessionStorage.setItem("newActive", 3);
      this.$router.push({
        path: "/singleRewardDetail",
        name: "singleRewardDetail",
        query: {
          id: item.id,
          type: item.person_type,
          auid: item.user_id,
          task_type: 1,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .custom-image .van-empty__image {
  height: auto !important;
}
.btnStyGray {
  background: rgba(215, 21, 24, 0);
  border: 0.01rem solid #999;
  border-radius: 0.04rem;
  font-size: 0.26rem;
  font-family: PingFang SC;
  color: #999;
  padding: 0.1rem 0.25rem;
  box-sizing: border-box;
  white-space: nowrap;
}
.label {
  font-size: 0.24rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
}
.value {
  font-size: 0.24rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333;
}
// 空状态图片
.null-img {
  width: 2.72rem;
  height: 1.96rem;
}
.singleReward-box {
  padding: 0.2rem;
  box-sizing: border-box;
  // 任务奖励列表
  .singleReward-list {
    padding: 0.3rem;
    box-sizing: border-box;
    width: 100%;
    background: #ffffff;
    border-radius: 0.16rem;
    // 任务奖励列表顶部
    .singleReward-list-top {
      border-bottom: 0.01rem solid #eeeeee;
      padding-bottom: 0.2rem;
      // 人logo
      .task-user-logo {
        width: 0.2rem;
        height: 0.26rem;
      }
      // 人昵称
      .task-user-name {
        font-size: 0.28rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #111111;
      }
    }
  }
}
</style>