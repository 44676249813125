<template>
	<div class="person_add" style="background:white;height:100vh;">
		<div class="person_add_header">
			<publicNavigation class="mb30" @sureEvenet="sureEvenet" :data="navigation" />
			<div class="person_add_list">
				<!-- v-if="number == 1" -->
				<!-- v-if="s.deparment && k == 2 || k == 3 || s.responsibility " -->
				<div class="person_add_list_for" v-if="number == 1">
					<div class="person_add_for" v-for="s,k in addPersonList" :key="k">
						<div class="person_add_total_header">
							<div class="person_title">
								<span>{{ s.name }}</span>
							</div>
							<div class="person_input" @click="personEventClick(s,k)">
								<input v-model="s.sure" type="text"
									v-if="s.text != '请选择部门' && s.text != '请选择岗位职能' && k != 2 && k != 3 && k != 4"
									:readonly="s.deparment || s.responsibility ? true : false" :placeholder="s.text"
									:style="placeholderStyle">
								<!-- placeholderFunction(s,k) -->
								<input type="text" v-if="k == 2 || k == 3 || k == 4"
									:style="k==2 && s.text != '请选择部门' || k == 3 && s.text != '请选择岗位职能' || k == 4 && s.text != '请选择岗位身份' ? 'color:black;' : 'color:#999;'"
									:value="s.text" readonly>
								<img v-if="k == 2 || k == 3 || k == 4" src="@/assets/rightFill.png" alt="">
							</div>
						</div>
					</div>
					<div class="defailut_number">
						<span>默认密码：admin123</span>
					</div>
				</div>
				<div v-else class="person_staff">
					<div class="person_staff_input">
						<input maxlength="10" v-model="deparmentName" type="text"
							:placeholder="editPotetext == 0 ? '请输入部门名称' : '请输入岗位名称'">
						<span><i>{{ textLegth }}</i>/10</span>
					</div>
					<!-- 	<div class="person_type_list">
						<span>选择部门</span>
						<div class="type_box">
							<span>请选择部门</span>
							<img :src="require('@/assets/myarrow.png')" alt="" />
						</div>
					</div> -->
				</div>
			</div>
			<div class="showMode">
				<van-popup v-model="showPicker" round position="bottom">
					<van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm" />
				</van-popup>
			</div>
			<div class="showMode">
				<van-popup v-model="stolpeShow" round position="bottom">
					<van-picker show-toolbar :columns="stolpeColumns" @cancel="stolpeShow = false"
						@confirm="stolpeConfirm" />
				</van-popup>
			</div>
			<div class="showMode">
				<van-popup v-model="legemeShow" round position="bottom">
					<van-picker show-toolbar :columns="legemeColumns" @cancel="legemeShow = false"
						@confirm="legemeChange" />
				</van-popup>
			</div>
			<div class="showMode">
				<van-popup v-model="CARDIDFUNCTIONCLICKSSHOW" round position="bottom">
					<van-picker show-toolbar :columns="CARDIDFUNCTIONCLICKSPERSONLIST"
						@cancel="CARDIDFUNCTIONCLICKS = false" @confirm="CARDIDFUNCTIONCONFIRM" />
				</van-popup>
			</div>
		</div>
	</div>
</template>
<script>
	import publicNavigation from '@/publicNavigation/publicNavigation.vue';
	import {
		addPerson
	} from './addPerson.ts'
	import * as API from '@/api.ts'
	export default {
		components: {
			publicNavigation
		},
		data() {
			return {
				navigation: {},
				addPersonList: [],
				showPicker: false,
				columns: [],
				number: 0,
				deparmentName: '',
				textLegth: 0,
				selectParms: {},
				selectProduct: [],
				stolpeShow: false,
				stolpeColumns: [],
				afdelingId: '',
				stolpeID: '',
				personInformation: '',
				legemeColumns: [1, 2, 3, 4],
				legemeID: '',
				legemeShow: false,
				// 岗位区分
				editPotetext: 0,
				// 部门区分
				postEdit: 0,
				// 添加岗位
				stolpe: 0,
				// 添加ID
				numIDCard: '',
				// 身份开关
				CARDIDFUNCTIONCLICKSSHOW: false,
				// 身份默认
				CARDIDFUNCTIONCLICKSPERSONLIST: ['员工', '主管'],
				// 身份数字
				SURECARDIDPERSONIDNUMBER: '',
				// 修改id
				section_id: '',
				role_ids: '',
				// 动态修改样式
				placeholderStyle: ''
			}
		},
		watch: {
			deparmentName(s) {
				if (s) {
					const length = s.split('')
					this.textLegth = length.length
				}
			}
		},
		mounted() {
			this.deparmentName = this.$route.query.stolpe ? '' : this.$route.query?.parms ? this.$route.query.parms.title :
				''
			const parms = this.$route.query
			let data = new addPerson().init()
			this.addPersonList = this.$route.query?.parms ? this.$route.query.parms?.desc : data.addPersonList
			data.addPersonList[0].sure = parms.parms
			this.number = Number(parms.number)
			this.postEdit = this.$route.query?.postEdit ? this.$route.query.postEdit : ''
			this.stolpe = this.$route.query?.stolpe ? this.$route.query.stolpe : ''
			console.log(this.stolpe)
			this.personInformation = parms
			this.sectionIndex()
			// 判断总经理
			this.numIDCard = this.$route.query?.numIDCard ? this.$route.query?.numIDCard : ''
			if (this.numIDCard) {
				data.addPersonList[0].sure = this.numIDCard
				data.addPersonList[0].text = this.numIDCard
				data.addPersonList.splice(4, 0, {
					name: '岗位身份',
					text: '请选择岗位身份',
					CARDIDFUNCTIONCLICKS: '1',
					sure: ''
				})
			}
			console.log(this.personInformation)
			if (this.personInformation) {
				if (this.personInformation?.parms) {
					if (parms.editPersonNumber == 1) {
						this.addPersonList = [{
							name: '工号',
							text: this.personInformation.parms.id_card,
							sure: this.personInformation.parms.id_card
						}, {
							name: '员工姓名',
							text: this.personInformation.parms.nickname,
							sure: this.personInformation.parms.nickname
						}, {
							name: '所属部门',
							text: this.personInformation.parms.section_title,
							deparment: '1',
							sure: this.personInformation.parms.section_id
						}, {
							name: '岗位职能',
							text: this.personInformation.parms.role_title,
							responsibility: '1',
							sure: this.personInformation.parms.role_ids
						}, {
							name: '身份：',
							text: this.personInformation.parms.identity_text,
							deparment: true,
							sure: this.personInformation.parms.identity
						}, {
							name: '电子邮箱',
							text: '请输入邮箱',
							sure: this.personInformation.parms.email,
						}, {
							name: '联系电话',
							text: '请输入联系电话',
							sure: this.personInformation.parms.mobile,
						}]
					}
					if (this.personInformation?.parms.identity) {
						this.addPersonList[6].text == this.personInformation?.parms.identity
						this.addPersonList[6].sure == this.personInformation?.parms.identity
						this.legemeColumns = ['员工', '主管']
						this.section_id = this.personInformation.parms.section_id
						this.role_ids = this.personInformation.parms.role_ids
					}
				}
			}
			// 部门岗位名称区分
			let current;
			if (this.number >= 0 && parms.parms) {
				if (this.number == 0 && parms.parms) {
					current = '编辑岗位'
					this.editPotetext = 1
				} else if (this.number == 1 && parms.parms) {
					current = '编辑员工'
				}
			} else {
				// 区分部门
				if (!parms.parms) {
					current = parms.number != 0 && !parms.parms ? '添加员工' : '添加部门'
				}
			}
			if (this.postEdit && parms.parms) {
				current = '编辑部门'
				this.editPotetext = 1
			}
			if (this.stolpe && parms.parms) {
				current = '添加岗位'
			}
			this.navigation = {
				image: require('@/assets/return.png'),
				name: current,
				sure: '确定'
			}
			const deparment = {
				deparment: true
			}
			if (this.addPersonList) {
				if (this.addPersonList[0].text !== '请输入员工工号') {
					Object.assign(this.addPersonList[0], deparment)
				}
			}
		},
		methods: {
			placeholderFunction(s) {
				if (s.text != '请输入员工工号' && s.text != '请输入员工姓名' && s.text != '请输入邮箱' && s.text != '请输入联系电话') {
					return s.sure = s.text;
				} else {
					return s.text;
				}
			},
			sectionIndex() {
				this.columns = []
				API.sectionIndex().then(async e => {
					if (e.code == 1) {
						console.log(e.data.rows)
						e.data.rows.map(item => {
							this.columns.push(item.title)
						})
						this.selectProduct = e.data.rows
					}
				})
			},
			personEventClick(s, k) {
				this.selectParms = s
				const parms = s
				if (parms) {
					if (parms.responsibility || parms.deparment || parms.CARDIDFUNCTIONCLICKS) {
						k == 2 ? this.showPicker = !this.showPicker : k == 3 ? this.stolpeShow = !this.stolpeShow : k ==
							6 ? this.legemeShow = !this.legemeShow : k == 4 ? this.CARDIDFUNCTIONCLICKSSHOW = !this
							.CARDIDFUNCTIONCLICKSSHOW : ''
					}
					console.log(this.$route.query.addPerson)
					// 修改匹配
					if(this.personInformation && this.$route.query?.addPerson != 1){
						this.stolpeColumns = []
						if(this.personInformation.parms){
							if(k == 3){
								this.selectProduct.map(item=>{
									item.section.map(items=>{
										if(this.personInformation.parms.section_id == item.id){
											this.stolpeColumns.push(items.title)
											this.afdelingId = item.id
										}
									})
								})
							}
						}
					}
				}
			},
			onConfirm(value, index) {
				this.stolpeColumns = []
				this.showPicker = !this.showPicker
				this.addPersonList[2].text = value
				let scetion = {}
				if (this.selectProduct[index].title == value) {
					scetion = this.selectProduct[index]
					this.afdelingId = this.selectProduct[index].id
				}
				console.log(scetion.section)
				scetion.section.map(items => {
					this.stolpeColumns.push(items.title)
				})
			},
			stolpeConfirm(value, index) {
				console.log(value, index)
				this.addPersonList[3].text = value
				this.selectProduct.map(item => {
					item.section.map(items => {
						if (value == items.title) {
							this.stolpeID = items.id
						}
					})
				})
				this.stolpeShow = !this.stolpeShow
			},
			onChange(value, index) {
				this.$toast(`当前值：${value}, 当前索引：${index}`);
			},
			onCancel() {
				this.$toast('取消');
			},
			// 新增身份
			CARDIDFUNCTIONCONFIRM(e) {
				this.addPersonList[4].text = e
				this.addPersonList[4].sure = e == '员工' ? 1 : 2
				this.CARDIDFUNCTIONCLICKSSHOW = !this.CARDIDFUNCTIONCLICKSSHOW
			},
			legemeChange(e) {
				this.legemeID = e
				this.legemeShow = !this.legemeShow
				this.addPersonList[6].text = e
			},
			sureEvenet() {
				const parms = {
					pid: this.$route.query?.parms ? this.$route.query?.parms.pid == undefined ? 0 : this.$route.query
						?.parms.pid : 0,
					power: this.$route.query?.parms ? this.$route.query?.parms.power == undefined ? JSON.parse(window
							.sessionStorage.getItem('userInfo')).identity : this.$route.query?.parms.power : JSON
						.parse(window.sessionStorage.getItem('userInfo')).identity,
					ids: this.$route.query?.parms ? this.$route.query?.parms.id == undefined ? 0 : this.$route.query
						?.parms.id : 0,
					title: this.deparmentName,
				}
				if (this.$route.query.addPerson) {
					const addPerson = {
						ids: this.personInformation.parms.id == undefined ? '' : this.personInformation.parms.id,
						nickname: this.addPersonList[1].sure,
						username: this.addPersonList[0].sure,
						email: this.addPersonList[5].sure ? this.addPersonList[5].sure : this.addPersonList[5].text,
						section_id: this.afdelingId,
						role_ids: this.stolpeID,
						mobile: this.addPersonList[6]&&this.addPersonList[6].sure ? this.addPersonList[6].sure : this
							.CARDIDFUNCTIONCLICKSPERSONLIST[0] == '员工' ? 1 : 2,
						identity: this.addPersonList[4].sure ? this.addPersonList[4].sure : this.addPersonList[4].text
					}
					if (addPerson.nickname && addPerson.username && addPerson.email != '请输入邮箱' && addPerson
						.section_id && addPerson.role_ids && addPerson.mobile && addPerson.identity) {
						API.AdminAdd(addPerson).then(async e => {
							this.$toast({
								type: e.code == 1 ? 'success' : 'fail',
								message: e.msg
							})
							if (e.code == 1) {
								setTimeout(() => {
									this.$router.back(-1)
									window.localStorage.setItem('currentIndex', 1)
								}, 800)
							}
						})
					} else {
						this.$toast.fail('请仔细检查信息不能为空')
					}
				} else {
					if (this.$route.query.editPersonNumber) {
						const personEdit = {
							ids: this.personInformation.parms.id,
							nickname: this.addPersonList[1].sure ? this.addPersonList[1].sure : this.addPersonList[1]
								.text,
							username: this.addPersonList[0].text,
							email: this.addPersonList[5].sure ? this.addPersonList[5].sure : this.addPersonList[5]
								.text,
							section_id: this.afdelingId ? this.afdelingId : this.section_id,
							role_ids: this.stolpeID ? this.stolpeID : this.role_ids,
							mobile: this.addPersonList[6].sure ? this.addPersonList[6].sure : this.addPersonList[6]
								.text,
							// identity: this.legemeID,
							identity: this.addPersonList[4].sure ? this.addPersonList[4].sure : this.addPersonList[4]
								.text == '员工' ? 1 : this.addPersonList[4].text == '主管' ? 2 : this.addPersonList[4]
								.text == '财务' ? 3 : 4
						}
						if (personEdit.ids && personEdit.nickname && personEdit.id_card && personEdit.email && personEdit
							.section_id && personEdit.role_ids && personEdit.mobile && personEdit.identity) {
							API.adminEdit(personEdit).then(async e => {
								console.log(e)
								this.$toast({
									type: e.code == 0 ? 'fail' : 'success',
									message: e.msg
								})
								if (e.code == 1) {
									setTimeout(() => {
										this.$router.back(-1)
										window.localStorage.setItem('currentIndex',1)
									}, 800)
								}
							})
						} else {
							this.$toast.fail('请仔细检查信息不能为空')
						}
					} else {
						if (this.$route.query.stolpe == 1) {
							if (this.$route.query?.parms.id && this.deparmentName && this.$route.query?.parms ? this.$route
								.query?.parms.power == undefined ?
								JSON.parse(window.sessionStorage.getItem('userInfo')).identity : this.$route
								.query?.parms.power : JSON.parse(window.sessionStorage.getItem('userInfo'))
								.identity) {
								API.sectionAdd({
									pid: this.$route.query?.parms.id,
									title: this.deparmentName,
									power: this.$route.query?.parms ? this.$route.query?.parms.power == undefined ?
										JSON.parse(window.sessionStorage.getItem('userInfo')).identity : this
										.$route
										.query?.parms.power : JSON.parse(window.sessionStorage.getItem('userInfo'))
										.identity
								}).then(async e => {
									this.$toast({
										type: e.code == 0 ? 'fail' : 'success',
										message: e.msg
									})
									if (e.code == 1) {
										setTimeout(() => {
											this.$router.back(-1)
											window.localStorage.setItem('currentIndex', 0)
										})
									}
								})
							} else {
								this.$toast.fail('请仔细检查信息不能为空')
							}

						} else {
							if (this.$route.query.number == 0 && !this.$route.query.parms) {
								console.log(parms)
								if (parms.pid >= 0 && parms.power && parms.ids >= 0 && parms.title) {
									API.sectionAdd(parms).then(async e => {
										this.$toast({
											type: e.code == 0 ? 'fail' : 'success',
											message: e.msg
										})
										if (e.code == 1) {
											setTimeout(() => {
												this.$router.back(-1)
												window.localStorage.setItem('currentIndex', 0)
											})
										}
									})
								} else {
									this.$toast.fail('请仔细检查信息不能为空')
								}
							} else {
								console.log(parms)
								if (parms.pid >= 0 && parms.power && parms.ids && parms.title) {
									API.sectionEdit(parms).then(async e => {
										if (e.code == 1) {
											this.$toast('保存成功，正在返回');
											setTimeout(() => {
												this.$router.back(-1)
												window.localStorage.setItem('currentIndex', 0)
											}, 1000)
										}
									})
								} else {
									this.$toast.fail('请仔细检查信息不能为空')
								}
							}
						}
					}
				}
			}
		}
	}
</script>
<style lang="scss" scoped>
	// 继承变量
	.alignItemsCenter {
		display: flex;
		align-items: center;
	}

	// 计算函数
	@function calcFunction($number, $default: 16) {
		@return calc($number * 2 / 100) + rem;
	}

	.person_add {
		.person_add_header {
			.person_add_list {
				margin: 0 .5rem;

				.person_add_list_for {
					height: calcFunction(30);

					.person_add_for {
						height: 100%;
						border-bottom: 1px solid #DCDCDC;
						padding-bottom: calcFunction(16);
						margin-bottom: calcFunction(16);
						position: relative;

						.person_add_total_header {
							height: 100%;
							@extend .alignItemsCenter;

							.person_title {
								width: 20%;

								span {
									font-size: calcFunction(14);
									color: #111111;
								}
							}

							.person_input {
								height: 100%;
								margin-left: calcFunction(10);
								@extend .alignItemsCenter;

								input {
									border: none;
									height: 100%;
									width: 100%;
									background: transparent;
									font-size: calcFunction(14);
									color: black;
								}

								input::placeholder {
									color: #999999;
								}

								img {
									width: calcFunction(16);
									height: calcFunction(20);
									position: absolute;
									right: calcFunction(10);
									margin-top: calcFunction(1);
								}
							}
						}
					}
				}
			}
		}
	}

	.person_staff {
		margin: 0 calcFunction(10);

		.person_staff_input {
			border-bottom: 1px solid #DCDCDC;
			padding-bottom: calcFunction(20);
			@extend .alignItemsCenter;
			justify-content: space-between;

			input {
				border: none;
				width: 80%;
				height: 100%;
			}

			input::placeholder {
				color: #999999;
				font-size: calcFunction(14);
			}

			span {
				i {
					color: #D71518;
					font-style: normal;
				}
			}
		}
	}

	.person_type_list {
		@extend .alignItemsCenter;
		justify-content: space-between;
		margin-top: calcFunction(20);
		margin: calcFunction(10) 0;
		border-bottom: 1px solid #DCDCDC;
		padding-bottom: calcFunction(10);

		span {
			font-size: calcFunction(14);
			color: #999999;
		}

		.type_box {
			span {
				font-size: calcFunction(14);
				color: #999999;
				padding-right: calcFunction(10);
			}

			img {
				width: calcFunction(8);
				height: calcFunction(10);
			}
		}
	}

	.custom_placehoder::placeholder {
		color: black;
	}

	.defailut_number {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-bottom: 20px;

		span {
			font-size: 15px;
			color: #999;
		}
	}
</style>
