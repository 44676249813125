<template>
  <div>
    <!-- 任务验收列表盒子 -->
    <div v-if="list.length > 0" class="list-box">
      <template v-if="[6, 7, 10].includes(status)">
        <div v-for="item of list" :key="item.id" class="list">
          <!-- 任务内容顶部 -->
          <div class="flex-c-b task-list-top">
            <!-- 验收人 -->
            <div class="flex-c">
              <img class="task-user mr5" src="@/assets/task-user.png" alt="" />
              <div class="accept-name">{{ item.user_nickname }}</div>
            </div>
            <!-- 任务状态 -->
            <div>
              <template>
                <div
                  :style="{ color: statusColor(item.status) }"
                  class="status_text"
                >
                  {{ item.verify_text }}
                </div>
                <div style="color: #999; font-size: 14px">
                  <div
                    style="color: #999; font-size: 14px"
                    v-for="(itemData, index) of item.review_person"
                    :key="index"
                  >
                    ({{ itemData }})
                    <span v-if="index != item.review_person.length - 1">,</span>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <!-- bug数 -->
          <div class="flex-c-b mt10">
            <div class="label">bug数 (未解决/BUG总数)：</div>
            <div class="value">
              {{ item.bug_not_finish }}/{{ item.bug_total }}
            </div>
          </div>

          <!-- 开始时间 -->
          <div class="flex-c-b mt10">
            <div class="label">开始时间：</div>
            <div class="value">
              {{ item.starttime_text }}
            </div>
          </div>

          <!-- 完成时间 -->
          <div class="flex-c-b mt10">
            <div class="label">完成时间：</div>
            <div class="value">
              {{ item.completetime_text }}
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <van-empty class="custom-image" description="暂无任务验收">
          <template #image>
            <img class="null-img" src="@/assets/nulldata.png" alt="" />
          </template>
        </van-empty>
      </template>
    </div>
    <van-empty v-else class="custom-image" description="暂无任务验收">
      <template #image>
        <img class="null-img" src="@/assets/nulldata.png" alt="" />
      </template>
    </van-empty>
  </div>
</template>

<script>
export default {
  props: ["list", "status"],
  name: "taskAccept",
  computed: {
    // 任务状态文字颜色
    statusColor() {
      return (e) => {
        let color;
        switch (e) {
          case 0:
            break;
          case 1:
            color = "#FF4200";
            break;
          case 2:
            break;
          case 3:
            break;
          case 4:
            color = "#D71518";
            break;
          case 5:
            color = "#00A2FF";
            break;
          case 6:
            color = "#FF4200";
            break;
          case 7:
            color = "#666666";
            break;
          case 8:
            color = "#FF4200";
            break;

          default:
            break;
        }
        return color;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .custom-image .van-empty__image {
  height: auto !important;
}
.label {
  font-size: 0.24rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
  white-space: nowrap;
}
.value {
  font-size: 0.24rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333;
}
// 下划线
.line {
  text-decoration: underline;
}
.blue {
  color: #375394;
}
// 空状态图片
.null-img {
  width: 2.72rem;
  height: 1.96rem;
}
// 任务验收列表盒子
.list-box {
  padding: 0.2rem;
  box-sizing: border-box;
  //列表
  .list {
    padding: 0.3rem;
    box-sizing: border-box;
    width: 100%;
    background: #ffffff;
    border-radius: 0.16rem;
    margin-bottom: 0.2rem;
    overflow: hidden;
    margin-bottom: 0.3rem;
    // 任务列表顶部
    .task-list-top {
      border-bottom: 0.01rem solid #eeeeee;
      padding-bottom: 0.2rem;
      box-sizing: border-box;
      // 验收人logo
      .task-user {
        width: 0.2rem;
        height: 0.26rem;
      }
      // 验收人
      .accept-name {
        font-size: 0.28rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #111111;
      }
      // 任务状态文字
      .status_text {
        font-size: 0.26rem;
        font-family: PingFang SC;
        font-weight: 500;
      }
    }
  }
}
</style>