<template>
  <div class="team_card">
    <div class="deparment_for" v-for="(s, k) in deparmentData" :key="k">
      <div class="deparment_header_title">
        <div class="deparment_title" v-if="identity == 4">
          <span>{{ s.name }}</span>
        </div>
        <div class="deparment_content">
          <div
            class="deparment_content_for"
            @click="userDescription(s, k)"
            :key="l"
          >
            <div class="header_content">
              <div class="content_image">
                <img :src="s.image" alt="" />
              </div>
              <div class="content_title_of_number">
                <div class="content_title">
                  <span>{{ s.name }}</span>
                  <span>{{ s.icon }}</span>
                </div>
                <div class="number_total">
                  <div class="number_list">
                    <span>等级：</span>
                    <span>Lv.{{ s.intergral }}</span>
                  </div>
                  <div class="number_list">
                    <span>排名：</span>
                    <span>{{ s.totalNumber }}</span>
                  </div>
                  <div class="number_list">
                    <span>任务数：</span>
                    <span>{{ s.number }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="deparment_right_arrow">
              <div class="deparment_right_arrow_image">
                <img src="@/assets/drop.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    deparmentData: Array,
  },
  data() {
    return {
      identity: "",
    };
  },
  methods: {
    userDescription(s, k) {
      console.log(1111);
      console.log(s, k);
      this.$emit("userDescription", s, k);
    },
  },
  mounted() {
    this.identity = this.$identity;
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.deparment_for {
  .deparment_header_title {
    .deparment_title {
      margin-left: calcFunction(10);
      margin-top: calcFunction(15);
      margin-bottom: calcFunction(12);
      span {
      }
    }
    .deparment_content {
      background: white;
      .deparment_content_for {
        @extend .alignItemsCenter;
        justify-content: space-between;
        padding: calcFunction(15) calcFunction(20);
        border-bottom: 1px solid #f1f0f5;
        .header_content {
          @extend .alignItemsCenter;
          .content_image {
            img {
              width: calcFunction(50);
              height: calcFunction(50);
              border-radius: calcFunction(1000);
            }
          }
          .content_title_of_number {
            @extend .alignItemsCenter;
            flex-direction: column;
            align-items: flex-start;
            margin-left: calcFunction(10);
            .content_title {
              margin-bottom: calcFunction(6);
              @extend .alignItemsCenter;
              span {
                font-size: calcFunction(15);
                color: #111111;
              }
              span:nth-last-child(1) {
                margin-left: calcFunction(10);
                border: 1px solid #008ee6;
                border-radius: calcFunction(100);
                color: #008ee6;
                font-size: calcFunction(12);
                padding: calcFunction(1) calcFunction(5);
              }
            }
            .number_total {
              @extend .alignItemsCenter;
              .number_list {
                margin-right: calcFunction(10);
                span {
                  font-size: calcFunction(12);
                  color: #999999;
                }
                span:nth-last-child(1) {
                  color: #111111;
                }
              }
            }
          }
        }
      }
    }
  }
  .deparment_right_arrow {
    .deparment_right_arrow_image {
      margin-right: calcFunction(-15);
      img {
        width: calcFunction(24);
        height: calcFunction(24);
      }
    }
  }
}
</style>