<template>
	<div class="bouns_description">
		<div class="bouns_descriptions">
			<publicNavigation :data="navigation" />
		</div>
		<div class="cpprojektCard">
			<div class="cpprojektCard_content">
				<div class="cpprojektCard_header">
					<div class="cpprojektCard_title">
						<span>明细</span>
					</div>
				</div>
				<cpprojektCard @sureEvent="sureEvent" :descriptionNumber="1" :cardData="cardData" :vrdi="vrdi" />
			</div>
		</div>
	</div>
</template>
<script>
	import publicNavigation from "@/publicNavigation/publicNavigation.vue";
	import cpprojektCard from "@/cpprojekt/compoments/cpprojektCard.vue";
	import * as API from '@/api.ts'
	export default {
		components: {
			publicNavigation,
			cpprojektCard,
		},
		data() {
			return {
				navigation: Array,
				cardData: Array,
				vrdi: 1,
			};
		},
		mounted() {
			this.navigation = {
				image: require("../assets/return.png"),
				name: "第一期分红",
			};
			this.Requset()
		},
		methods: {
			Requset() {
				API.divvyDetail({
					id: this.$route.query.e.id
				}).then(async e => {
					if (e.code == 1) {
						e.data.rows.map(item=>{
							Object.assign(item,{
								title:item.user_name,
								status:item.periodization,
								children:[{
									name:'cp值余额：',
									text:item.cp,
								},{
									name:'cp值占比：',
									text:item.proportion,
								},{
									name:'分红金额：',
									text:item.divvy,
								}]
							})
						})
						this.cardData = e.data.rows
						this.navigation.name = `第${e.data.periodization}期分红`
					}
				})
			}
		}
	};
</script>
<style lang="scss">
	// 继承变量
	.alignItemsCenter {
		display: flex;
		align-items: center;
	}

	// 计算函数
	@function calcFunction($number, $default: 16) {
		@return calc($number * 2 / 100) + rem;
	}

	.bouns_description {
		height: 100vh;
		background: #f1f0f5;

		.cpprojektCard {
			height: 90vh;
			background: white;
			border-top-left-radius: calcFunction(16);
			border-top-right-radius: calcFunction(16);
			overflow-y: scroll;

			.cpprojektCard_content {
				border-radius: calcFunction(4);
				padding: calcFunction(15);

				.cpprojektCard_header {
					.cpprojektCard_title {
						padding-bottom: calcFunction(15);

						span {
							font-size: calcFunction(14);
						}
					}
				}
			}
		}

		.card_list {
			height: 80vh !important;
		}
	}
</style>