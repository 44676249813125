<template>
  <div class="lineCharts">
    <div
      :id="!!wrapId ? wrapId : elId"
      :style="`width: ${width}; height: ${height}`"
    ></div>
  </div>
</template>
  
  <script>
import * as echarts from "echarts";

export default {
  name: "LineCharts",
  props: [
    "width",
    "height",
    "xAxisData",
    "unit",
    "seriesData",
    "wrapId",
    "title",
  ],
  computed: {
    option() {
      // 指定图表的配置项和数据
      var option = {
        title: {
          show: !!this.title,
          text: this.title,
          left: "center",
        },
        color: ["#D8474F"],
        // 这个里面指定图标的样式和数据
        tooltip: {
          show: true,
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          toolbox: {
            feature: {
              saveAsImage: {},
            },
          },
        },
        grid: {
          left: "0",
          right: "0",
          bottom: "0",
          top: "30",
          containLabel: true,
        },
        // legend: {
        //   top: 0, //图例位置
        // },
        xAxis: [
          {
            splitLine: {
              //   show: true,
              lineStyle: {
                color: "rgba(136, 159, 204, .2)",
              },
            },
            type: "category",
            axisTick: {
              show: false,
              alignWithLabel: false, //true 的时候有效，可以保证刻度线和标签对齐
            },
            data: this.xAxisData,
          },
        ],

        yAxis: [
          {
            type: "value",
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(136, 159, 204, .2)",
              },
            },
            axisLine: {
              show: true, //是否显示轴线
              lineStyle: {
                color: "#666", //刻度线的颜色
              },
            },
            axisLabel: {
              formatter: "{value} " + this.unit, //刻度标签的内容格式器，支持字符串模板和回调函数两种形式，按照自己需求设置
            },
            axisTick: {
              show: false,
            },
          },
        ],
        //手势放大柱状图折线图
        dataZoom: {
          type: "inside",
        },
        series: this.series,
      };
      return option;
    },
  },
  data() {
    return {
      elId: "line-charts-" + Date.now(),
      series: {
        data: [],
        name: "Line 1",
        type: "line",
        stack: "Total",
        smooth: true,
        lineStyle: {
          width: 2,
        },
        showSymbol: true,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "rgb(251, 203, 210)",
            },
            {
              offset: 1,
              color: "rgb(255, 255, 255,0)",
            },
          ]),
        },
        emphasis: {
          focus: "series",
        },
      },
    };
  },
  created() {},
  mounted() {
    this.initCharts();
  },
  methods: {
    initCharts() {
      var _id = !!this.wrapId ? this.wrapId : this.elId;
      var myChart = echarts.init(document.getElementById(_id));
      // 指定图表的配置项和数据
      this.series.data = this.seriesData;
      // 使用刚指定的配置项和数据显示图表。
      // console.log('折线图');
      // console.log(this.option);
      myChart.setOption(JSON.parse(JSON.stringify(this.option)), true);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>
  
  <style scoped>
.lineCharts {
  width: 100%;
}
</style>
  