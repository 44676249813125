<template>
  <div class="today_card">
    <div class="today_card_box">
      <publicCardHeader
        rightText="查看全部"
        :isShowRight="true"
        :type="1"
        :toDayText="toDayTextData"
      />
      <div class="today_card_list">
        <div v-if="toDayMessageList.length > 0">
          <div class="list" v-for="(s, k) in toDayMessageList" :key="k">
            <span>{{ k + 1 + "、" }} {{ s.content }}</span>
          </div>
        </div>
        <div class="temporary_no_data" v-if="toDayMessageList.length <= 0">
          <img :src="require('@/assets/temporary_no_data.png')" alt="" />
          <span>暂无数据</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import publicCardHeader from "@/component/publicCard/publicCard.vue";
export default {
  components: {
    publicCardHeader,
  },
  props: {
    toDayMessageList: Array,
  },
  data() {
    return {
      toDayTextData: {},
      todayData: [],
    };
  },
  mounted() {
    this.toDayTextData = {
      name: "今日计划",
      image: require("../../assets/today.png"),
    };
    console.log(this.$props.toDayMessageList);
    if (this.$props?.toDayMessageList) {
      this.$props.toDayMessageList.map((item, index) => {
        this.todayData.push(index + 1 + "," + item.content);
      });
    }
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.today_card {
  margin: 0.2rem;
  background: white;
  border-radius: 0.16rem;

  .today_card_box {
    .today_card_list {
      padding: 0.3rem;
      box-sizing: border-box;

      .list {
        span {
          font-size: 0.26rem;
        }

        .list:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }
  }
}

.temporary_no_data {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 100px;
  }

  span {
    color: #999;
    font-size: 15px;
    margin-top: 10px;
  }
}
</style>
