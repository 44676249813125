var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"integral"},[_c('div',{staticClass:"integral_content"},[_c('div',{staticClass:"integral_content_data"},[_c('div',{staticClass:"integral_content_header"},[_c('div',{staticClass:"integral_header"},[_c('publicNavigation',{attrs:{"data":_vm.navigation}})],1),_c('div',{staticClass:"background_color",style:({ height: _vm.windowheight + 'px' })},[_c('div',{staticClass:"background_colors"})]),_c('div',{staticClass:"grade_background_red"},[_c('div',{staticClass:"grade_background_red_content"},[_c('div',{staticClass:"slots"},[_c('div',{staticClass:"grade_content"},[_c('div',{staticClass:"grade_title"},[_c('span',[_vm._v("当前等级：")]),_c('span',[_vm._v("Lv."+_vm._s(_vm.person.level))])]),_c('div',{staticClass:"grade_progress"},[_c('div',{staticClass:"progress_content"},[_c('div',{staticClass:"user_count_line"},[_c('div',{staticClass:"user_count_line_count_text"},[_c('div',{staticClass:"user_count_number",style:({
                            width:
                              (100 / _vm.person.text_points) * 1000 + 20 + '%',
                          })},[_c('span',[_vm._v(_vm._s(_vm.person.score)+"/"+_vm._s(_vm.person.text_points))])]),_c('div',{staticClass:"user_count_lines"},[_c('div',{staticClass:"line_bcakground",style:({
                              width: (100 / _vm.person.text_points) * 1000 + '%',
                            })})])]),_c('div',{staticClass:"user_need_experience"},[_c('div',{staticClass:"user_need_experience_text"},[_c('span',[_vm._v("升级还差"+_vm._s(_vm.person.text_points)+"积分"),_c('i',[_vm._v("LV."+_vm._s(_vm.person.max_level))])])])])])])])]),_vm._m(0)]),_c('div',{staticClass:"points"},[_c('div',{staticClass:"points_content_list"},[_c('div',{staticClass:"points_list"},[_vm._m(1),_c('div',{staticClass:"points_list_content"},_vm._l((_vm.childrenData),function(s,k){return _c('div',{key:k,staticClass:"points_list_icon"},[_c('div',{staticClass:"points_list_icon_bcakground"},[_c('img',{attrs:{"src":require("@/assets/mingold.png"),"alt":""}}),_c('span',[_vm._v("+"+_vm._s(s.score))])]),_c('div',{staticClass:"points_list_text"},[_c('span',[_vm._v("完成执行的任务")]),_c('span',[_vm._v(_vm._s(s.memo))])])])}),0)])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grade_right_background_image"},[_c('div',{staticClass:"grade_image"},[_c('img',{attrs:{"src":require("@/assets/gold.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"points_title"},[_c('span',[_vm._v("赚积分")])])
}]

export { render, staticRenderFns }