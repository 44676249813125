<template>
  <div class="gift_card flex-c-b">
    <div class="gift_card_each" @click="giftCardClick(s,k)" v-for="(s,k) in giftCardData" :key="k">
      <div class="gift_card_each_image">
        <van-image class="van-image" :src="s.image">
          <template v-slot:loading>
            <van-loading type="spinner" size="20"/>
          </template>
        </van-image>
      </div>
      <div class="gift_info">
        <div class="gift_card_each_title textOver1">{{ s.title }}</div>
        <div class="gift_card_each_reward flex-c">
          <span>{{ s.number }}</span>
          <span class="point" v-if="s.number">奖励点</span>
        </div>
      </div>
    
    </div>
  </div>
</template>
<script>
export default {
  props: {
    giftCardData: {
      type: Array,
      default: [],
    }
  },
  methods: {
    giftCardClick(s, k) {
      this.$emit('giftCardClick', s, k)
    }
  }
}

</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

.gift_card {
  flex-wrap: wrap;
  
  .gift_card_each {
    width: 50%;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    
    .gift_card_each_image {
      height: 3.43rem;
      width: 100%;
      padding: 0.25rem;
      
      .van-image {
        display: block;
        width: 100%;
        height: 100%;
        
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      
    }
    
    .gift_info {
      padding: 0.1rem 0.25rem;
    }
    
    .gift_card_each_title {
      font-size: 0.26rem;
      color: #3D3D3D;
    }
    
    .gift_card_each_reward {
      margin-top: 0.1rem;
      padding-bottom: 0.34rem;
      
      span {
        color: #D71518;
        font-size: 0.36rem;
        font-weight: bold;
      }
      
      span:nth-last-child(1) {
        font-size: 0.22rem;
        color: black;
        font-weight: 400;
      }
      
      .point {
        margin-left: 0.1rem;
      }
    }
  }
  
  .gift_card_each:nth-child(2n) {
    border-right: none;
  }
}
</style>
