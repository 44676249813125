<template>
    <div class="cp_value_list">
        <div class="cp_value_list_content">
            <div class="cp_list_content">
                <div class="cp_for" v-for="s,k in updata" :key="k">
                    <div class="cp_list_header">
                        <div class="header_title_and_each">
                            <div class="header_title">
                                <span>{{ s.project_name || '-' }}</span>
                            </div>
                            <div class="header_each_number">
                                <span>{{ s.divvy_num ? '第' + (s.divvy_num )+ '次分红' : ''}}</span>
                            </div>
                        </div>
                        <div class="each_value">
                            <div class="each_for_data">
                                <div class="each_for_new_data">
                                    <div class="each_data">
                                        <span>Ta的CP值：</span>
                                        <span>{{ s.cp || '-' }}</span>
                                    </div>
                                    <div class="each_data">
                                        <span>占比：</span>
                                        <span>{{ s.proportion || '-' }}</span>
                                    </div>
                                    <div class="each_data">
                                        <span>奖池：</span>
                                        <span>{{ s.prize_pool || '-' }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dividends">
                            <div class="dividends_header">
                                <span>Ta的分红：</span>
                                <span>￥{{s.my_pool || '-'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        updata:Array
    }
}
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter{
    display: flex;
    align-items: center;
}

// 计算函数
@function calcFunction($number,$default:16){
  @return calc($number * 2 / 100) + rem;
}
.cp_value_list{
    .cp_value_list_content{
        .cp_list_content{
            .cp_for{
                background: white;
                padding: 0 calcFunction(15);
                padding-top: calcFunction(15);
                border-radius: calcFunction(8);
                margin-bottom: calcFunction(10);
                border-top-left-radius: calcFunction(10);
                border-top-right-radius: calcFunction(10);
                box-shadow: 0px -5px 8px 0px rgba(0,0,0,0.03);
                .cp_list_header{
                    .header_title_and_each{
                        @extend .alignItemsCenter;
                        justify-content: space-between;
                        border-bottom: 1px solid #EEEEEE;
                        padding-bottom: calcFunction(15);
                        margin-bottom: calcFunction(15);
                        .header_title{
                            span{
                                color: #111111;
                                font-size: calcFunction(14);
                            }
                        }
                        .header_each_number{
                            span{
                                color: #666666;
                                font-size: calcFunction(14);
                            }
                        }
                    }
                    .each_value{
                        padding-bottom: calcFunction(10);
                        margin-bottom: calcFunction(15);
                        border-bottom: 1px solid #EEEEEE;
                        .each_for_data{
                            .each_for_new_data{
                                @extend .alignItemsCenter;
                                flex-wrap: wrap;
                                justify-content: space-between;
                                .each_data{
                                    padding-bottom: calcFunction(8);
                                    span{
                                        color: #999999;
                                        font-size: calcFunction(14);
                                

                                    }
                                    span:nth-last-child(1){
                                        color: #111;
                                    }
                                }
                            }
                        }
                    }
                    .dividends{
                        padding-bottom: calcFunction(20);
                        .dividends_header{
                            @extend .alignItemsCenter;
                            justify-content: flex-end;
                            span{
                                color: #D71518;
                                font-size: calcFunction(15);
                            }
                        }
                    }
                }
            }
        }
    }
}

::-webkit-scrollbar{
    display: none;
}
</style>
