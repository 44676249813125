<template>
	<div class="verify_Mobile">
		<div class="verify_Mobile_content">
			<div class="verify_Mobile_content_box">
				<div class="verify_Mobile_content_header">
					<publicNavigation :data="navigation" />
				</div>
				<div class="verify_Mobile_content_text">
					<div class="verify_Mobile_description">
						<div class="description_text">
							<span>为了保证你的账号安全，需先验证您的身份</span>
							<span>验证成功后可以进行一下步操作。</span>
						</div>
						<div class="mobile_number">
							<span>{{model(mobileNumber)}}</span>
							<span>请验证身份</span>
						</div>
					</div>
				</div>
				<div class="mobile_verify_code">
					<div class="mobile_verify_code_content">
						<div class="mobile_verify_code_input">
							<div class="mobile_verify_input">
								<img class="code" :src="codeIcon" alt="">
								<input type="text" v-model="getcode" placeholder="输入短信验证码">
							</div>
							<div class="get_mobile_code">
								<button @click="getCode">{{downdNumber > 0 ? downdNumber +  'S后重新发送' : '获取验证码'}}</button>
							</div>
						</div>
					</div>
				</div>
				<div class="submit_data">
					<div class="submit_data_box">
						<button @click="updataMobile" :disabled="mobileNumber ? false : true" :style="getcode ? 'background:#D71518;' : ''">确定</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import publicNavigation from "@/publicNavigation/publicNavigation.vue";
	import * as API from '@/api.ts'
	export default {
		components: {
			publicNavigation,
		},
		data() {
			return {
        codeIcon: require("@/assets/code.png"),
        
				navigation: {
          image: require("@/assets/return.png"),
          name: "验证手机",
        },
        mobileNumber: '',
				getcode:'',
				downdNumber:0,
				success:false
			};
		},
		mounted() {
			this.mobileNumber = this.$route.query.e
		},
		watch:{
			getcode(e){
				clearTimeout(this.outtime)
				this.outtime = setTimeout(()=>{
					API.smsCode({
						mobile:this.mobileNumber,
						event:'verify',
						captcha:e
					}).then(async e=>{
						if(e.code == 1){
							this.success = true
						}
					})
				},1000)
			}
		},
		methods: {
			updataMobile() {
				if(this.success){
					this.$router.push({
						name: 'updataMobile',
						path: '/updataMobile'
					})
				}else{
					this.getcode ? this.$toast.fail('验证码错误') : ''
				}
			},
			model(e){
				return e.replace(/(\d{3})\d{4}(\d{4})/,'$1****$2');
			},
			getCode(){
				if(this.downdNumber == 0){
					this.downdNumber = 60
					API.send({
						mobile:this.mobileNumber,
						event:'verify'
					}).then(async e=>{
						if(e.code == 1){
							clearInterval(this.timeout)
							this.timeout = setInterval(()=>{
								this.downdNumber -- ;
								if(this.downdNumber == 0 ){
									this.downdNumber = 0
									return;
								}
							},1000)
						}else{
							this.$toast.fail(e.msg)
						}
					})
				}
			}
		}
	};
</script>
<style lang="scss" scoped>
	// 继承变量
	.alignItemsCenter {
		display: flex;
		align-items: center;
	}

	// 计算函数
	@function calcFunction($number, $default: 16) {
		@return calc($number * 2 / 100) + rem;
	}

	.verify_Mobile {
    background: #fff;
    min-height: 100vh;
		.verify_Mobile_content {
			.verify_Mobile_content_box {
				.verify_Mobile_content_header {}

				.verify_Mobile_content_text {
					.verify_Mobile_description {
						.description_text {
							@extend .alignItemsCenter;
							flex-direction: column;
              padding-top: 1rem;

							span {
								color: #999999;
								font-size: 0.28rem;
								margin-bottom: 0.1rem;
							}
						}

						.mobile_number {
							@extend .alignItemsCenter;
							margin-top: calcFunction(15);
							flex-direction: column;

							span {
								font-size: calcFunction(25);
							}

							span:nth-last-child(1) {
								font-size: 0.28rem;
								color: #999999;
								margin-top: calcFunction(5);
							}
						}
					}
				}

				.mobile_verify_code {
					margin: 0.5rem 0.6rem 0;
					.mobile_verify_code_content {
						.mobile_verify_code_input {
							background: #F5F5F5;
							height: 0.9rem;
							@extend .alignItemsCenter;
							justify-content: space-between;
							padding: 0 0.3rem;
							border-radius: 0.1rem;

							.mobile_verify_input {
                display: flex;
                align-items: center;
								.code {
                  display: block;
                  width: 0.26rem;
                  height: 0.3rem;
                  margin-right: 0.2rem;
                }

								input {
									background: transparent;
									border: none;
									font-size: 0.26rem;
                  height: 0.6rem;
								}
							}

							.get_mobile_code {
								border-left: 1px solid #999999;
								padding-left: 0.2rem;

								button {
									border: none;
									outline: none;
									font-size: 0.26rem;
									color: #C32D30;
								}
							}
						}
					}
				}

				.submit_data {
					margin: 1rem 0.3rem 0;
					.submit_data_box {
						button {
							background: #F0CCCC;
							transition-duration:.5s;
							color: white;
							width: 100%;
              height: 0.9rem;
							border: none;
							outline: none;
							border-radius: 0.1rem;
							font-size: 0.32rem;
						}
					}
				}
			}
		}
	}
</style>
