<template>
    <div class="completion">
        <div class="completion_status">
            <div class="completion_header">
                <publicCardHeader :toDayText="toDayText" @currentIndexFunction="currentIndexFunction"  :toggle="1" :toggleArray="toggleArray" />
                <div class="statistics">
                    <div class="statistics_content">
                        <div class="statistics_left_content_echats">
                            <van-circle v-model="currentRate" speed="150" layer-color="#DDDDDD" stroke-linecap="square" color="#FFA200" :rate="rate" stroke-width="140" size="90px"/>
                        </div>
                        <div class="statistics_content_text">
                            <div class="statistics_content_top_text_list" v-for="s,k in statisticsData" :key="k">
                                <div class="statistics_content_top_line"></div>
                                <div class="statistics_content_top_text">
                                    <span>{{ s.name }}</span>
                                    <span>{{ s.value }}个</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import publicCardHeader from '../publicCard/publicCard.vue';
import * as API from '@/api.ts'
export default {
    components:{
        publicCardHeader
    },
    data() {
        return{
            toDayText:{},
            toggleArray:[],
            currentRate:0,
            rate:0,
            statisticsData:[]
        }
    },
    mounted(){
        this.toDayText = {
            name:'完成情况',
            image:require('../../assets/Pie.png')
        }
        this.toggleArray = ['月','季','年']
        this.request(1)
    },
    methods:{
        currentIndexFunction(e){
            this.rate = 0
            this.request(e)
        },
        request(parms){
            // 完成情况
            API.finish(parms).then(async e=>{
                if(e.code == 1){
                    this.statisticsData = e.data.rows
                    e.data.rows.map(item=>{
                        this.rate += item.value
                        this.currentRate += item.value
                    })
                }
            })
        }
    }
}

</script>

<style lang="scss" scoped>
// 继承变量
.alignItemsCenter{
    display: flex;
    align-items: center;
}

// 计算函数
@function calcFunction($number,$default:16){
    @return calc($number * 2 / 100) + rem;
}

// 颜色填充
@function colorFill($color){
    @return $color;
}

.completion{
    margin: .2rem;
    background: white;
    border-radius: .16rem;
    .completion_status{
        .completion_header{
            .statistics{
                padding: .34rem .9rem;
                box-sizing: border-box;
                .statistics_content{
                    @extend .alignItemsCenter;
                    justify-content: space-between;
                    .statistics_content_text{
                        .statistics_content_top_text_list{
                            margin-bottom: .2rem;
                            .statistics_content_top_line{
                                margin-bottom: .15rem;
                                background: colorFill(#ddd);
                            }
                        }
                        .statistics_content_bottom_text_list{
                            .statistics_content_bottom_line{
                                margin-bottom: calcFunction(4);
                                background: colorFill(#FFA200);
                            }
                        }
                    }
                }
            }
        }
    }
}

.statistics_content_top_line,
.statistics_content_bottom_line{
    width: .3rem;
    height: .1rem;
}

.statistics_content_top_text,
.statistics_content_bottom_text{
    span{
        color: colorFill(#666);
        font-size: .28rem;
    }
    span:nth-last-child(1){
        color: colorFill(#333);
    }
}
</style>