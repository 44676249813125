<template>
  <div class="user_description">
    <div class="user_description_box">
      <div class="user_description_content">
        <div class="user_description_image">
          <div class="user_image">
            <img :src="personInfromation.avatar" alt="" />
          </div>
          <div class="user_content_text">
            <div class="user_text">
              <div class="user_top_text flex-c mt5">
                <span style="line-height: 0.4rem">{{
                  personInfromation.nickname
                }}</span>
                <span>{{ personInfromation.post_text }}</span>
              </div>
              <div class="user_bottom_ranking">
                <span
                  >等级：<i>{{ personInfromation.level }}</i></span
                >
                <span
                  >排名：<i>{{ personInfromation.ranking }}</i></span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="user_count_line">
          <div class="user_count_line_count_text">
            <div
              class="user_count_number"
              :style="{
                width:
                  (personInfromation.score / personInfromation.text_points) *
                    100 +
                  10 +
                  '%',
              }"
            >
              <span style="white-space: nowrap"
                >{{ personInfromation.score }}/{{
                  personInfromation.text_points
                }}</span
              >
            </div>
            <div class="user_count_lines">
              <div
                class="line_bcakground"
                :style="{
                  width:
                    (personInfromation.score / personInfromation.text_points) *
                      100 +
                    '%',
                }"
              ></div>
            </div>
          </div>
          <div class="user_need_experience">
            <div class="user_need_experience_text">
              <span
                >升级LV.{{
                  personInfromation.level <= personInfromation.max_level
                    ? personInfromation.level + 1
                    : personInfromation.level
                }}还差{{
                  Number(personInfromation.text_points) -
                  Number(personInfromation.score)
                }}积分</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 奖励点 -->
    <div class="ranking_drop">
      <div class="ranking_drop_box">
        <div class="ranking_drop_content">
          <div class="ranking_drop_background">
            <img src="@/assets/orange.png" alt="" />
            <div class="orange_box_text">
              <div class="orange_content_boxs">
                <div class="orange_header_title">
                  <span>Ta的奖励点</span>
                </div>
                <div
                  @click="
                    $router.push({
                      path: '/optageList',
                      query: { id: personInfromation.id },
                    })
                  "
                  class="orange_number_and_button"
                >
                  <span>{{ personInfromation.reward }}</span>
                  <button>奖励点记录</button>
                </div>
              </div>
            </div>
          </div>
          <div class="ranking_drop_background">
            <img src="@/assets/deeporange.png" alt="" />
            <div class="orange_box_text">
              <div class="orange_content_boxs">
                <div class="orange_header_title">
                  <span>Ta的CP值</span>
                </div>
                <div class="orange_number_and_button">
                  <span
                    >{{ personInfromation.cp_project }}<i>个参与项目</i></span
                  >
                  <button @click="checkEvent(personInfromation)">
                    查看详情
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 统计 -->
    <div class="count_total">
      <div class="count_total_number">
        <div class="count_total_box">
          <div class="total_number">
            <p>{{ personInfromation.task_count }}</p>
            <p>执行中任务</p>
          </div>
          <div class="total_number">
            <p>{{ personInfromation.audit_task }}</p>
            <p>验收中任务</p>
          </div>
          <div class="total_number">
            <p>{{ personInfromation.bug }}</p>
            <p>解决中bug</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 任务日志 -->
    <div class="task-log">
      <!-- 任务日志文字&日期 -->
      <div class="flex-c-b">
        <!-- 任务日志标题 -->
        <div class="flex-c">
          <img class="task-title-logo" src="@/assets/spring.png" alt="" />
          <span>任务日志</span>
        </div>

        <!-- 日期 -->
        <div class="flex-c date-box">
          <div @click="selectTimeChange" class="mr10">
            {{ date ? date : "日期" }}
          </div>
          <img
            v-if="!dateLog"
            src="@/assets/bottom.png"
            class="selectImage"
            alt=""
          />
          <img
            @click="
              date = '';
              dateLog = '';
              page = 1;
              taskData = [];
              getTaskLog();
              finished = false;
            "
            v-if="dateLog"
            src="@/assets/delete.png"
            class="deleteImage"
            alt=""
          />
        </div>
      </div>

      <!-- 日志列表 -->
      <van-list
        v-if="taskData.length > 0"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        offset="150"
        @load="onLoad"
      >
        <div class="task-log-list mt20">
          <div v-for="item of taskData" :key="item.id">
            <div>
              <!-- 日期 -->
              <div class="flex-c mt10">
                <img class="calendar mr10" src="@/assets/calendar.png" alt="" />
                <div>{{ item.time_text }}</div>
              </div>

              <!-- 计划&日志 -->
              <div class="task-content mt10">
                <!-- 昵称 -->
                <div class="nickname">{{ item.nickname }}</div>

                <!-- 计划 -->
                <div v-if="item.plan.length > 0" class="mt10">
                  <div
                    class="flex-c"
                    style="
                      border-bottom: 1px solid #e4e4e4;
                      padding-bottom: 0.2rem;
                    "
                  >
                    <img
                      class="taskplan mr5"
                      src="@/assets/taskplan.png"
                      alt=""
                    />
                    <div>计划</div>
                  </div>
                  <!-- 计划内容 -->
                  <div>
                    <div
                      class="mt10"
                      v-for="(val, index) of item.plan"
                      :key="index"
                    >
                      {{ ++index }}、{{ val }}
                    </div>
                  </div>
                </div>
                <!-- 日志 -->
    
                <div class="mt10" style="border-top: .01rem solid #e4e4e4;padding-top:.2rem" v-if="item.daily.length > 0">
                  <div
                    class="flex-c"
                    style="
                      border-bottom: 1px solid #e4e4e4;
                      padding-bottom: 0.2rem;
                    "
                  >
                    <img class="log mr5" src="@/assets/log.png" alt="" />
                    <div>日志</div>
                  </div>

                  <!-- 日志内容 -->
                  <div>
                    <div
                      class="mt10"
                      v-for="(val, index) of item.daily"
                      :key="index"
                    >
                      <div class="flex-c">
                        <span class="task-text">项目任务：</span>
                        <div class="task-bg">{{ val.task_title }}</div>
                      </div>
                      <div class="mt5" v-for="(value, i) of val.daily" :key="i">
                        {{ ++i }}、{{ value }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
      <!-- 通用错误 -->
      <van-empty v-else description="暂无数据" />
    </div>
    <!-- 日期筛选 -->
    <van-calendar
      v-model="timeShow"
      :min-date="new Date(2023, 0, 1)"
      :max-date="new Date()"
      @confirm="onConfirm"
    />
  </div>
</template>
<script>
import userlog from "./userLog.vue";
import * as API from "@/api.ts";
import { number } from "echarts";
export default {
  props: {
    descriptionObject: Object,
    type: String | Number,
    id: String | Number,
  },
  components: {
    userlog,
  },
  data() {
    return {
      personInfromation: "",
      userLog: [],
      timeShow: false,
      date: "",
      timestamp: "",
      page: 1,
      limit: 5,
      dateLog: "", //筛选日期
      taskData: [], //日志列表
      loading: false,
      finished: false,
    };
  },
  mounted() {
    // 获取任务日志
    this.getTaskLog();
    this.Request(
      this.$props.descriptionObject&&this.$props.descriptionObject.id
        ? this.$props.descriptionObject.id
        : JSON.parse(window.localStorage.getItem("mydesc"))&&JSON.parse(window.localStorage.getItem("mydesc")).id
    );
    // 获取员工详情
    // this.getPerson();
  },
  methods: {
    // 获取员工详情
    // async getPerson() {
    //   let params = {
    //     id: this.$route.query.description,
    //   };
    //   let { code, data } = await API.TAperson(params);
    // },
    //加载更多
    onLoad() {
      if (!this.finished) {
        this.page++;
        console.log(123);
        // 获取计划列表
        this.getTaskLog();
      } else {
        this.loading = false;
      }
    },
    // 获取任务日志
    getTaskLog() {
      let { page, limit, dateLog } = this;
      let params = {
        id: this.id?this.id:this.$route.query.description,
        page,
        limit,
        date: dateLog,
      };
      API.personDaily(params).then(async (e) => {
        let { code, data } = e;
        if (code == 1) {
          if (page == 1) {
            this.taskData = data.rows;
            this.loading = false;
          } else {
            this.taskData.push(...data.rows);
            this.loading = false;
          }
          if (data.rows.length == 0) {
            this.finished = true;
          }
        }
      });
    },
    Request(id, timedate) {
      let _id = id?id:this.$route.query.description
      API.TAperson({ id: _id }).then(async (e) => {
        if (e.code == 1) {
          this.personInfromation = e.data.rows;
        }
      });
      API.personDaily({
        id: id,
        limit: 10,
        page: this.page,
        date: timedate,
      }).then(async (e) => {
        if (e.code == 1) {
          this.userLog = this.userLog.concat(
            e.data.rows.map((item) => {
              return {
                lists:
                  item.daily.length > 0
                    ? item.daily
                    : item.plan.length > 0
                    ? item.plan
                    : item.lists,
              };
            })
          );
        }
      });
    },
    checkEvent(s) {
      window.localStorage.setItem("personInfromation", JSON.stringify(s));
      this.$router.push({
        name: "cpValue",
        path: "/cpValue",
        query: {
          personInfromation: s,
        },
      });
    },
    selectTimeChange() {
      this.timeShow = !this.timeShow;
    },
    onConfirm(e) {
      // if (!this.date) {
      this.page = 1;
      this.taskData = [];
      this.selectTimeChange();
      this.date = this.formatDate(e);
      const timestamp = Date.parse(e)/ 1000;
      this.dateLog = timestamp;
      this.getTaskLog();
      // }
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    deletTimeChange() {
      this.page = 1;
      this.date = "";
      this.userLog = [];
      this.Request(
        this.$props.descriptionObject.id
          ? this.$props.descriptionObject.id
          : JSON.parse(window.localStorage.getItem("mydesc")).id,
        ""
      );
    },
  },
};
</script>
<style lang="scss" scoped>
i {
  font-style: normal;
}

// 任务日志
.task-log {
  padding: 0.2rem;
  box-sizing: border-box;
  .task-title-logo {
    width: 0.28rem;
    height: 0.28rem;
  }

  // 日期筛选
  .date-box {
    background: #ffffff;
    border-radius: 0.08rem;
    padding: 0.12rem 0.25rem;
    box-sizing: border-box;

    .selectImage {
      width: 0.25rem;
      height: 0.13rem;
    }
    .deleteImage {
      width: 0.25rem;
      height: 0.33rem;
    }
  }

  //   任务日志列表
  .task-log-list {
    width: 100%;
    padding: 0.28rem 0.3rem;
    box-sizing: border-box;
    background: #fff;
    border-radius: 0.16rem;

    // 日期logo
    .calendar {
      width: 0.26rem;
      height: 0.25rem;
    }

    // 日志内容
    .task-content {
      width: 100%;
      background: #fafafa;
      border: 1px solid #dddddd;
      border-radius: 0.08rem;
      padding: 0.3rem 0.2rem;
      box-sizing: border-box;
      //昵称
      .nickname {
        font-size: 0.28rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #111111;
        border-bottom: 1px solid #e4e4e4;
        padding-bottom: 0.3rem;
      }

      //计划
      .taskplan {
        width: 0.21rem;
        height: 0.25rem;
      }

      //日志
      .log {
        width: 0.25rem;
        height: 0.25rem;
      }

      .task-text {
        font-size: 0.26rem;
        font-weight: 500;
        color: #666666;
      }
      // 项目任务背景
      .task-bg {
        background: #ffe8e8;
        padding: 0.09rem 0.2rem;
        box-sizing: border-box;
        font-size: 0.24rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #d71518;
        border-radius: 0.04rem;
      }
    }
  }
}

// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.user_description {
  margin-top: calcFunction(40);

  .user_description_box {
    background: white;
    padding: 0 calcFunction(15);

    .user_description_content {
      .user_description_image {
        @extend .alignItemsCenter;

        .user_image {
          width: calcFunction(80);
          height: calcFunction(80);
          border-radius: calcFunction(1000);
          overflow: hidden;
          border: 4px solid white;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
          margin-top: calcFunction(-40);

          img {
            width: 100%;
            height: 100%;
          }
        }

        .user_content_text {
          margin-left: calcFunction(10);

          .user_text {
            .user_top_text {
              @extend .alignItemsCenter;
              // align-items: flex-end;

              span {
                font-size: calcFunction(20);
                font-weight: bold;
              }

              span:nth-last-child(1) {
                color: #008ee6;
                font-size: calcFunction(10);
                margin-left: calcFunction(5);
                border: 1px solid #008ee6;
                padding: calcFunction(1) calcFunction(5);
                border-radius: calcFunction(1000);
              }
            }

            .user_bottom_ranking {
              margin-top: calcFunction(1);

              span {
                font-size: calcFunction(12);
                color: #999999;
              }

              span:nth-last-child(1) {
                color: #111111;
                margin-left: calcFunction(10);
              }
            }
          }
        }
      }

      .user_count_line {
        .user_count_line_count_text {
          .user_count_number {
            display: flex;
            justify-content: flex-end;

            span {
              color: #d71518;
              font-size: calcFunction(12);
              text-align: right;
              padding: calcFunction(3) calcFunction(4);
              background: #ffcccc;
              margin-bottom: calcFunction(8);
              border-radius: calcFunction(5);
            }
          }

          .user_count_lines {
            height: calcFunction(2);
            width: 100%;
            background: #eeeeee;

            .line_bcakground {
              background: #d71518;
              height: 100%;
              position: relative;

              &::after {
                content: "";
                width: calcFunction(10);
                height: calcFunction(10);
                border-radius: calcFunction(1000);
                position: absolute;
                right: 0;
                background: #d71518;
                z-index: 1;
                top: calcFunction(-4);
              }
            }
          }
        }

        .user_need_experience {
          margin-top: calcFunction(5);
          padding-bottom: calcFunction(15);

          .user_need_experience_text {
            span {
              color: #ff5b1a;
              font-size: calcFunction(12);
            }
          }
        }
      }
    }
  }

  .ranking_drop {
    .ranking_drop_box {
      .ranking_drop_content {
        .ranking_drop_background {
          position: relative;
          height: calcFunction(115);
          margin: calcFunction(10);

          img {
            width: 100%;
            height: 100%;
          }

          .orange_box_text {
            position: absolute;
            top: 0;
            z-index: 1;
            width: 100%;

            .orange_content_boxs {
              padding-top: calcFunction(21);
              padding-left: calcFunction(25);

              .orange_header_title {
                padding-bottom: calcFunction(12.5);

                span {
                  color: white;
                  font-size: calcFunction(12);
                }
              }

              .orange_number_and_button {
                @extend .alignItemsCenter;
                justify-content: space-between;

                span {
                  font-size: calcFunction(40);
                  color: white;

                  i {
                    font-size: calcFunction(12);
                  }
                }

                button {
                  padding: calcFunction(7) calcFunction(12);
                  background: #e86040;
                  color: white;
                  border: none;
                  outline: none;
                  border-top-left-radius: calcFunction(1000);
                  border-bottom-left-radius: calcFunction(1000);
                  font-size: calcFunction(15);
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }

  .count_total {
    margin: calcFunction(10);

    .count_total_number {
      background: white;
      padding: calcFunction(20);

      .count_total_box {
        @extend .alignItemsCenter;
        justify-content: space-between;

        .total_number {
          @extend .alignItemsCenter;
          flex-direction: column;
          width: 25%;

          p {
            color: #375394;
            font-size: calcFunction(25);
            text-align: center;
          }

          p:nth-last-child(1) {
            font-size: calcFunction(12);
            color: #666666;
            border: none;
          }
        }

        .total_number:nth-child(2) {
          width: 50%;
          margin: 0 calcFunction(20);
          border-left: 1px solid #dddddd;
          border-right: 1px solid #dddddd;
        }
      }
    }
  }
}
</style>