<template>
	<div class="task_description">
		<div class="task_description_title">
			<div class="task_description_content">
				<div class="task_title">
					<span>任务名称：</span>
					<span>{{userdescription.title}}</span>
				</div>
				<div class="task_name_difficulty">
					<div class="task_name">
						<span>姓名：</span>
						<span>{{userdescription.user_nickname}}</span>
					</div>
					<div class="difficulty">
						<span>难度：</span>
						<div class="difficulty_start">
							<img src="@/assets/start.png" v-for="s,k in userdescription.difficulty_text" :key="k" alt="">
						</div>
					</div>
				</div>
				<div class="total_bounspoint">
					<div class="total_bounspoint_number">
						<span>合计奖励点：</span>
						<span>{{showTotal}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			userdescription:Object,
			showTotal:Number
		}
	}
</script>

<style lang="scss" scoped>
	.task_description{
		margin-top: 1rem;
		.task_description_title{
			background: white;
			padding: 1.25rem;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			box-shadow: 0px -5px 8px 0px rgba(0,0,0,0.03);
			.task_description_content{
				font-size: .875rem;
				.task_title{
					span{
						color: #666;
					}
					span:nth-last-child(1){
						color: black;
						font-weight: bold;
					}
				}
				.task_name_difficulty{
					margin: 1rem 0;
					display:flex;
					align-items: center;
					justify-content: space-between;
					.task_name{
						span{
							color: #666;
						}
						span:nth-last-child(1){
							color: black;
							font-weight: 500;
						}
					}
					.difficulty{
						width: 50%;
						display: flex;
						align-items: center;
						span{
							color: #666;
						}
						.difficulty_start{
							display: flex;
							align-items: center;
							img{
								width: 1rem;
								height: 1rem;
							}
						}
					}
				}
				.total_bounspoint{
					.total_bounspoint_number{
						span{
							color: #666;
						}
						span:nth-last-chid(1){
							color: black;
							font-weight: 500;
						}
					}
				}
			}
		}
	}
</style>