<template>
  <div class="retrieve" style="background:white;height:100vh;">
    <div class="retrieve_password">
      <div class="retrueve_password_content">
        <div class="retrieve_password_content_box">
          <div class="retrieve_password_left_image" v-if="!newpasswordStatus">
            <img @click="returnLogin" src="@/assets/offset.png" alt="" />
          </div>
          <div class="retrieve_password_left_image custom_return" v-if="newpasswordStatus">
            <img @click="returnLogin" src="@/assets/return.png" alt="" />
          </div>
          <div class="retrieve_passwords">
            <span>{{
              keycode
              ? newpassword
                ? "新密码"
                : "输入6位验证码"
              : "找回密码"
            }}
            </span>
            <span>{{
              keycode
              ? newpassword
                ? "密码由6-20个英文字母、数字或符号组成"
                : "验证码已发至：" + mobileNumber
              : "输入你注册时的手机号"
            }}
              <i v-if="keycode && !lock">{{ expiration_time }}秒后重发</i>
            </span>
          </div>
          <div class="retrieve_input_moblie_number" v-if="!keycode">
            <input type="number" v-model="mobileNumber" placeholder="输入你注册时的手机号" />
          </div>
          <div class="input_number_keyboard" v-if="keycode && !newpassword">
            <van-password-input :value="value" :mask="false" :error-info="errorInfo" :focused="showKeyboard"
              @focus="showKeyboard = true" />
            <!-- 数字键盘 -->
            <van-number-keyboard v-model="value" :show="showKeyboard" @blur="showKeyboard = false" />
          </div>
          <div class="input_new_password" v-if="newpassword">
            <div class="input_new_password_code">
              <span>新密码</span>
              <input type="text" v-model="newsPassword" minlength="6" placeholder="请输入新的密码" />
            </div>
            <div class="input_new_password_code">
              <span>确认密码</span>
              <input type="text" v-model="surePassword" minlength="6" placeholder="请确认新的密码" />
            </div>
            <div class="sure_new_password">
              <button @click="sureNewPassword" :disabled="buttonStatus ? false : true"
                :style="buttonStatus ? 'opacity:1;' : ''">
                确定
              </button>
            </div>
          </div>
          <div class="retrieve_password_end_mobile_number_code" v-if="!keycode && !newpassword">
            <button @click="sendCode">发送验证码</button>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom_fixed_choose" v-if="!keycode && !newpassword">
      <div class="fixed_choose_text">
        <div class="fixed_choose_text_image">
          <div class="fixed_choose" style="justify-content: center">
            <img v-if="!choose" src="@/assets/notchoose.png" @click="sureclcik" alt="" />
            <img v-if="choose" src="@/assets/okchoose.png" @click="sureclcik" alt="" />
            <!-- <span
              >已阅读并同意<i @click="checkclick">《中国移动认证服务协议》</i
              ><span style="display: block; text-align: center"
                >并授权和获取本机号码</span
              >
            </span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as API from "@/api.ts";
export default {
  data() {
    return {
      choose: false,
      openeyebloolen: false,
      value: "",
      showKeyboard: false,
      mobileNumber: "",
      keycode: false,
      errorInfo: "",
      newpassword: false,
      expiration_time: 0,
      newsPassword: "",
      surePassword: "",
      lock: false,
      newpasswordStatus: false,
      buttonStatus: false,
      height: 0, //屏幕高度
    };
  },
  watch: {
    value(values) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        API.smsCode({
          mobile: this.mobileNumber,
          captcha: values,
          event: "resetpwd",
        }).then(async (e) => {
          if (e.code == 1) {
            this.newpassword = true;
            this.lock = !this.lock;
            this.newpasswordStatus = !this.newpasswordStatus;
          } else {
            this.errorInfo = "验证码错误";
            this.newpassword = false;
          }
        });
      }, 1000);
    },
    mobileNumber(newVal, oldVal) {
      if (newVal.length > 11) {
        this.mobileNumber = oldVal;
      }
    },
    newsPassword(e) {
      this.newsPassword = e;
      if (this.surePassword && e) {
        this.buttonStatus = true;
      } else {
        this.buttonStatus = false;
      }
    },
    surePassword(e) {
      this.surePassword = e;
      if (this.newsPassword && e) {
        this.buttonStatus = true;
      } else {
        this.buttonStatus = false;
      }
    },
  },

  methods: {
    returnLogin() {
      if (!this.keycode && !this.newpassword) {
        this.$router.back(-1);
      } else if (this.keycode) {
        this.keycode = !this.keycode;
        this.newpassword = false;
      } else {
        this.keycode = true;
        this.newpassword = !this.newpassword;
      }
    },
    openeye() {
      this.openeyebloolen = !this.openeyebloolen;
    },
    sureclcik() {
      this.choose = !this.choose;
    },
    sendCode() {
      if (!this.keycode) {
        if (this.mobileNumber) {
          API.send({
            mobile: this.mobileNumber,
            event: "resetpwd",
          }).then(async (e) => {
            this.$toast({
              type: e.code == 1 ? "success" : "fail",
              message: e.msg,
            });
            if (e.code == 1) {
              // this.expiration_time = e.data.expiration_time
              this.keycode = !this.keycode;
              this.TimeFunction();
            }
          });
        } else {
          this.$toast.fail("手机号不能为空");
        }
      }
    },
    sureNewPassword() {
      if (this.newsPassword && this.surePassword) {
        if (this.newsPassword == this.surePassword) {
          API.resetpwd({
            mobile: this.mobileNumber,
            password: this.surePassword,
          }).then(async (e) => {
            this.$toast({
              type: e.code == 1 ? "success" : "fail",
              message: e.msg,
            });
            if (e.code == 1) {
              setTimeout(() => {
                window.location.href = "/";
              }, 1000);
            }
          });
        } else {
          this.$toast.fail("两次密码不一致");
        }
      } else {
        this.$toast.fail("密码不能为空");
      }
    },
    TimeFunction(e) {
      if (this.expiration_time == 0 && this.keycode) {
        console.log(1234564, e);
        this.expiration_time = 60;
        setInterval(() => {
          if (this.expiration_time == 0) {
            this.expiration_time = 60;
          } else {
            this.expiration_time--;
          }
        }, 1000);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.retrieve {
  padding-top: calcFunction(44);
  height: 100vh;
  box-sizing: border-box;

  .retrieve_password {
    .retrueve_password_content {
      .retrieve_password_content_box {
        .retrieve_password_left_image {
          margin-bottom: calcFunction(40);
          margin-left: calcFunction(20);

          img {
            width: calcFunction(20);
            height: calcFunction(20);
          }
        }

        .retrieve_passwords {
          @extend .alignItemsCenter;
          flex-direction: column;
          margin: 0 calcFunction(30);
          align-items: flex-start;

          span {
            color: #111111;
            font-size: calcFunction(24);
            font-weight: bold;
            font-family: "PingFang-SC-Bold";
          }

          span:nth-last-child(1) {
            color: #666666;
            font-size: 1rem;
            margin-top: calcFunction(8);
            font-weight: 400;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            i {
              font-style: normal;
            }
          }
        }

        .retrieve_input_moblie_number {
          margin: 0 calcFunction(30);
          border-bottom: 1px solid #dddddd;
          margin-top: calcFunction(30);

          input {
            padding-bottom: calcFunction(10);
            border: none;
            width: 100%;
            padding: calcFunction(10) 0;
            font-size: calcFunction(16);
            padding-bottom: calcFunction(16);
            font-weight: bold;

            &::placeholder {
              font-size: calcFunction(14);
              font-weight: 400;
            }
          }
        }

        .retrieve_password_end_mobile_number_code {
          margin: 0 calcFunction(30);
          margin-top: calcFunction(70);

          button {
            background: linear-gradient(-31deg, #e80000 0%, #ff585b 100%);
            box-shadow: 0px 10px 20px 0px rgba(255, 0, 0, 0.2);
            border: none;
            outline: none;
            color: white;
            padding: calcFunction(12) 0;
            width: 100%;
            border-radius: calcFunction(4);
            font-size: calcFunction(15);
            font-weight: bold;
          }
        }
      }
    }
  }
}

.bottom_fixed_choose {
  width: 100%;
  margin-top: 35vh;

  .fixed_choose_text {
    .fixed_choose_text_image {
      .fixed_choose {
        @extend .alignItemsCenter;
        justify-content: center;
        align-items: flex-start;

        img {
          width: calcFunction(14);
          height: calcFunction(14);
          margin-right: calcFunction(5);
          margin-top: calcFunction(3);
        }

        span {
          font-size: 0.9rem;
          color: #999999;
          line-height: calcFunction(20);
          white-space: nowrap;

          i {
            color: #0074c0;
            font-style: normal;
          }
        }
      }
    }
  }
}

.input_number_keyboard {
  margin-top: calcFunction(25);
}

.input_new_password {
  margin: 0 calcFunction(30);
  margin-top: calcFunction(40);

  .input_new_password_code {
    @extend .alignItemsCenter;
    padding-bottom: calcFunction(14);
    border-bottom: 1px solid #eeeeee;
    margin-bottom: calcFunction(10);

    span {
      color: #333333;
      font-size: calcFunction(15);
      display: block;
      width: 25%;
    }

    input {
      padding: calcFunction(10) 0;
      font-size: calcFunction(12);
      flex: 1;
      border: none;
    }
  }

  .sure_new_password {
    margin-top: calcFunction(80);

    button {
      background: linear-gradient(-31deg, #e80000 0%, #ff585b 100%);
      box-shadow: 0px 10px 20px 0px rgba(255, 0, 0, 0.2);
      width: 100%;
      padding: calcFunction(10) 0;
      color: white;
      opacity: 0.5;
      border: none;
      font-size: calcFunction(15);
      border-radius: calcFunction(4);
      transition-duration: 0.5s;
    }
  }
}

.custom_return {
  img {
    width: calcFunction(10) !important;
    height: calcFunction(16) !important;
  }
}
</style>
