<template>
    <div class="cpvalue">
      <div class="cpvalue_content_box_header">
        <publicNavigation @sureEvenet="sureEvenet" :data="navigation"/>
      </div>
      <div class="cpvalue_list" v-if="cardData.length > 0">
        <cpprojektCard  @sureEvent="sureEvent" cpvalue="1" :cardData="cardData" />
      </div>
  
      <van-empty v-else description="暂无数据" />
    </div>
</template>
<script>
import publicNavigation from '@/publicNavigation/publicNavigation.vue';
import cpprojektCard from "@/cpprojekt/compoments/cpprojektCard.vue";
// import {cpvalue} from './cpvalue.ts'
import * as API from '@/api.ts'
export default {
    components:{
        publicNavigation,
        cpprojektCard
    },
    data(){
        return{
            navigation: {
              image:require('@/assets/return.png'),
              name:'我的CP值',
              sure:'兑换',
              close:''
            },
            cardData:[]
        }
    },
    mounted(){
        this.Request()
    },
    methods:{
        sureEvent(e){
            this.$router.push({
                name:'myCpdescription',
                path:'/myCpdescription',
                query:{
                  description: JSON.stringify(e)
                }
            })
        },
        sureEvenet(){
            this.$router.push({
                name:'cpexchange',
                path:'/cpexchange'
            })
        },
        Request(){
          
          API.myCp().then(async e=>{
              if(e.code == 1){
                  e.data.rows.map(item=>{
                      this.cardData.push({
                          title:item.project_name,
                          status:item.periodization,
                          ...item,
                          children:[{
                              name:'我的CP值：',
                              text:item.cost_performance,
                          },{
                              name:'占比：',
                              text:item.proportion,
                          },{
                              name:'奖池：',
                              text:item.prize_pool,
                          },{
                              name:'分红时间：',
                              text:item.divvytime_text,
                          }]
                      })
                  })
              }
          })
        }
    }
}
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
    display: flex;
    align-items: center;
  }
  
// 计算函数
@function calcFunction($number, $default: 16) {
    @return calc($number * 2 / 100) + rem;
}

.cpvalue{
  min-height: 100vh;
  background: #F1F0F5;
  .cpvalue_content_box_header{
    background: white;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 999;
  }
  .cpvalue_list{
    padding: 0.2rem;
  }
}
</style>
