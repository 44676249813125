<template>
  <div class="team" style="background: white; margin: 0;">
    <div class="team_person">
      <div class="team_person_ranking">
        <div class="team_ranking_list">
          <div
            class="current_ranking_list"
            @click="descriptionClick(s)"
            v-for="(s, k) in threeList"
            :key="k"
          >
            <div class="current_ranking">
              <div class="ranking_image_background">
                <img v-if="k == 1" src="../../../assets/one.png" alt="" />
                <img v-else-if="k == 0" src="../../../assets/two.png" alt="" />
                <img v-else src="../../../assets/three.png" alt="" />
              </div>
              <div class="ranking_image">
                <img :src="s.image" alt="" />
              </div>
              <div class="raking_name">
                <span>{{ s.name }}</span>
                <span>LV.{{ s.number }}</span>
              </div>
              <div class="raking_position">
                <span>{{ s.position }}</span>
              </div>
              <div class="raking_current_intergral">
                <span>当前积分:{{ s.intergral }}</span>
              </div>
            </div>
          </div>
        </div>
        <gradeCardList
          :mations="mypersoninfomation"
          @descriptionClick="descriptionClick"
          :resterende="rankingList"
        />
      </div>
    </div>
  </div>
</template>
<script>
import gradeCardList from "../gradeCardList/gradeCardList.vue";
export default {
  props: {
    rankingList: Array,
    threeList: Array,
    mypersoninfomation: Object,
  },
  components: {
    gradeCardList,
  },
  data() {
    return {
      rakingData: Array,
      topThree: [],
      resterende: [],
      infromation: [],
      mations: [],
    };
  },
  mounted() {},
  methods: {
    descriptionClick(s) {
      this.$emit("descriptionClick", s);
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.team {
  margin-top: .6rem;

  .team_person {
    margin: 0 .2rem;

    .team_person_ranking {
      .team_ranking_list {
        padding-top: .3rem;
        @extend .alignItemsCenter;
        justify-content: space-between;

        .current_ranking_list {
          background: linear-gradient(-180deg, #fffbef 10%, white 100%);
          border-radius: calcFunction(5);
          width: 32%;

          .current_ranking {
            position: relative;

            .ranking_image {
              @extend .alignItemsCenter;
              justify-content: center;
              margin-top: calcFunction(-25);

              img {
                width: 1rem;
                height: 1rem;
                overflow: hidden;
                border-radius: calcFunction(100);
              }
            }

            .raking_name {
              padding-left: calcFunction(25);
              margin-top: calcFunction(10);
              @extend .alignItemsCenter;

              span {
                font-size: calcFunction(14);
                color: #111111;
                text-align: center;
              }

              span:nth-last-child(1) {
                background: #ffe7e7;
                color: #d71518;
                padding: calcFunction(0.5) calcFunction(5);
                font-size: calcFunction(11);
              }

              span:nth-child(1) {
                white-space: nowrap;
                width: 50%;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }

            .raking_position {
              @extend .alignItemsCenter;
              justify-content: center;
              margin: calcFunction(8) 0;

              span {
                font-size: calcFunction(12);
                color: #111111;
              }
            }

            .raking_current_intergral {
              padding-bottom: calcFunction(10);
              @extend .alignItemsCenter;
              justify-content: center;

              span {
                font-size: calcFunction(11);
                color: #666666;
              }
            }
          }
        }

        .current_ranking_list:nth-child(1) {
          margin-top: calcFunction(20);
          background: linear-gradient(-180deg, #ebeef6 10%, white 100%);
        }

        .current_ranking_list:nth-last-child(1) {
          margin-top: calcFunction(30);
          background: linear-gradient(-180deg, #fff4ef 20%, white 100%);
        }
      }
    }
  }
}

.ranking_image_background {
  position: absolute;
  right: calcFunction(5);
  top: calcFunction(30);

  img:nth-child(2) {
    width: calcFunction(24);
    height: calcFunction(30);
  }

  img:nth-child(1) {
    width: calcFunction(14);
    height: calcFunction(28);
  }

  img:nth-last-child(1) {
    width: calcFunction(23);
    height: calcFunction(30);
  }
}
</style>