<!--
 * @Author: Mr.Cheng
 * @Date: 2023-10-26 18:39:06
 * @LastEditTime: 2023-10-27 13:40:36
 * @FilePath: \octopus-factory-map\src\new\cpProject\cpProjectDetail.vue
-->
<template>
  <div style="overflow: hidden; height: 100vh">
    <!-- 顶部背景 -->
    <div class="top-bg">
      <!-- 顶部标题 -->
      <div
        style="background: unset; border-bottom: unset"
        class="flex-c-c task-navigate-box"
      >
        <!-- 返回按钮 -->
        <img
          @click="$router.go(-1)"
          class="task-left-back"
          src="@/assets/return.png"
          alt=""
        />
        <div>项目分红详情</div>
        <div
          v-if="detail.status != 3"
          @click="sureEvenet"
          class="red nav-right"
        >
          结束分红
        </div>
      </div>
      <!-- 基本信息 -->
      <div class="bic-content">
        <div class="name">{{ detail.name }}</div>
        <!-- cp值兑换额&参与人 -->
        <div class="flex-c-b">
          <div class="flex-c" style="width: 50%">
            <div class="list-label">兑换CP值：</div>
            <div class="list-value">{{ detail.total }}</div>
          </div>
          <div class="flex-c" style="width: 50%">
            <div class="list-label">参与人：</div>
            <div class="list-value">{{ detail.play_count }}</div>
          </div>
        </div>
        <!-- 分红期&已分红 -->
        <div class="flex-c-b mt10">
          <div class="flex-c" style="width: 50%">
            <div class="list-label">已分红：</div>
            <div class="list-value">
              {{ detail.dividend_paid }}
            </div>
          </div>
        </div>
      </div>
      <!-- 列表信息 -->
      <div class="list">
        <template v-if="detail.divvy.length > 0">
          <div
            @click="
              $router.push({
                path: '/cpDetail',
                query: { id: item.id, num: item.periodization },
              })
            "
            v-for="item of detail.divvy"
            :key="item.id"
            class="list-content"
          >
            <!-- 列表信息顶部 -->
            <div class="list-content-top flex-c-b">
              <div class="list-name">第{{ item.periodization }}期分红</div>
              <div
                :style="{ color: item.status == 1 ? '#D71518' : '' }"
                class="status"
              >
                {{ item.status_text }}
              </div>
            </div>
            <!-- 列表信息内容 -->
            <div class="list-content-content">
              <!-- 分红人数&分红金额 -->
              <div class="flex-c-b">
                <div class="flex-c" style="width: 50%">
                  <div class="label">分红人数：</div>
                  <div class="value">{{ item.play_count }}</div>
                </div>
                <div class="flex-c" style="width: 50%">
                  <div class="label">分红金额：</div>
                  <div class="value mr20 red">{{ item.divvy || "-" }}</div>
                  <img
                    v-if="!item.divvy"
                    @click.stop="
                      moneyShow = true;
                      title = item.periodization;
                      id = item.id;
                    "
                    style="width: 0.26rem"
                    src="@/assets/edit.png"
                    alt=""
                  />
                </div>
              </div>
              <!-- 分红日期 -->
              <div class="flex-c mt10">
                <div class="label">分红日期：</div>
                <div class="value mr20">
                  {{ item.divvytime_text || "-" }}
                </div>
                <img
                  v-if="!item.divvytime_text"
                  @click.stop="
                    show = true;
                    id = item.id;
                  "
                  style="width: 0.26rem"
                  src="@/assets/edit.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </template>
        <van-empty v-else description="暂无数据" />
        <!-- 占位 -->
        <div v-if="detail.status != 3" class="style"></div>
      </div>
    </div>
    <div v-if="detail.status != 3" class="bottom-button">
      <div @click="createBonus" class="create-btn flex-c-c">新建分红</div>
    </div>
    <!-- 分红日期弹窗 -->
    <van-calendar title="分红日期" v-model="show" @confirm="onConfirm" />
    <!-- 分红金额弹窗 -->
    <van-dialog
      v-model="moneyShow"
      :title="'第' + title + '期分红'"
      show-cancel-button
      @confirm="bonusMoney"
    >
      <van-field v-model="money" type="number" placeholder="请输入分红金额" />
    </van-dialog>
  </div>
</template>

<script>
import * as API from "@/api.ts";

export default {
  name: "cpProjectDetail",
  data() {
    return {
      detail: {
        divvy: [],
      }, //页面数据
      show: false, //分红日期弹窗显隐
      moneyShow: false, //分红日期弹窗显隐
      id: "", //填写分红日期的id
      title: "", //设置金额时的分红期数
      money: "", //分红金额
    };
  },
  mounted() {
    // 获取项目cp值详情
    this.getCpDetail();
  },
  methods: {
    //新建分红
    async createBonus() {
      let params = {
        id: this.$route.query.id,
      };
      let { code, msg } = await API.divvyAdd(params);
      this.$toast(msg);
      if (code == 1) {
        //获取项目cp值详情
        this.getCpDetail();
      }
    },
    //分红金额
    async bonusMoney() {
      let { id, money: divvy } = this;
      let params = {
        divvy,
        id,
      };
      let { code, msg } = await API.divvyEdit(params);
      this.$toast(msg);
      if (code == 1) {
        // 获取项目cp值详情
        this.getCpDetail();
        this.show = false;
      }
    },
    // 结束分红
    sureEvenet() {
      if (this.detail.status != 3) {
        API.divvyEnd({
          id: this.$route.query.id,
        }).then(async (e) => {
          this.$toast({
            type: e.code == 1 ? "success" : "fail",
            message: e.msg,
          });
          if (e.code == 1) {
            setTimeout(() => {
              this.$router.back(-1);
            }, 500);
          }
        });
      } else {
        this.$toast.fail("已结束分红");
      }
    },
    // 设置分红日期
    async onConfirm(e) {
      let params = {
        divvytime: Date.parse(e) / 1000,
        id: this.id,
      };
      let { code, msg } = await API.divvyEdit(params);
      this.$toast(msg);
      if (code == 1) {
        // 获取项目cp值详情
        this.getCpDetail();
        this.show = false;
      }
    },
    // 获取项目cp值详情
    async getCpDetail() {
      let params = {
        id: this.$route.query.id,
      };
      let { code, data } = await API.CpDetail(params);
      if (code == 1) {
        this.detail = data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// 项目名
.name {
  font-size: 0.3rem;
  font-weight: bold;
  color: #111111;
  margin-bottom: 0.2rem;
}
.status {
  font-size: 0.24rem;
  font-weight: 500;
}
.list-name {
  font-size: 0.28rem;
  font-weight: bold;
  color: #111111;
}
.list-value {
  font-size: 0.24rem;
  font-weight: 500;
  color: #999999;
}
.list-value {
  font-size: 0.24rem;
  font-weight: 500;
  color: #000000;
}
.red {
  color: #d71518 !important;
}
.nav-right {
  position: absolute;
  right: 0.2rem;
  font-size: 0.24rem;
}
// 顶部背景
.top-bg {
  width: 100vw;
  height: 3.8rem;
  background: linear-gradient(0deg, #feefef 10%, #ffffff 87%);
  // 任务名称
  .task-name {
    font-size: 0.3rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #111111;
  }
  // 任务状态文字
  .status_text {
    font-size: 0.28rem;
    font-family: PingFang SC;
    font-weight: 500;
    white-space: nowrap;
    line-height: 0.5rem;
  }
}
.label {
  font-size: 0.24rem;
  font-weight: 500;
  color: #999999;
}
.value {
  font-size: 0.24rem;
  font-weight: 500;
  color: #000000;
}
//列表内容
.bic-content {
  padding: 0.2rem 0.3rem;
  box-sizing: border-box;
}

// 列表信息
.list {
  width: 100%;
  height: 100%;
  height: 100vh;
  background: #ffffff;
  box-shadow: 0 -0.05rem 0.08rem 0 rgba(0, 0, 0, 0.03) !important;
  border-radius: 0.32rem 0.32rem 0 0 !important;
  padding: 0.3rem;
  box-sizing: border-box;
  overflow: auto;
  .list-content {
    width: 100%;
    background: #fafafa;
    border: 0.01rem solid #dddddd;
    border-radius: 0.08rem;
    margin-bottom: 0.2rem;
    .list-content-top {
      padding: 0.2rem 0.22rem;
      box-sizing: border-box;
      border-bottom: 0.01rem solid #dddddd;
    }
    .list-content-content {
      padding: 0.3rem 0.55rem;
      box-sizing: border-box;
    }
  }
}
// 占位
.style {
  height: 1.5rem;
}
// 底部按钮盒子
.bottom-button {
  width: 100%;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  padding: 0.3rem;
  box-sizing: border-box;
  .create-btn {
    width: 100%;
    height: 0.9rem;
    background: #d71518;
    border-radius: 0.16rem;
    font-size: 0.34rem;
    font-weight: 500;
    color: #ffffff;
  }
}
</style>