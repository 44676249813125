<!--
 * @Author: Mr.Cheng
 * @Date: 2023-09-01 14:31:55
 * @LastEditTime: 2023-10-30 09:53:24
 * @FilePath: \octopus-factory-map\src\component\taskPool\taskPool.vue
-->
<template>
  <div>
    <div class="content">
      <!-- 任务池顶部 -->
      <div class="pool-header flex-c">
        <img class="grade-log mr5" src="@/assets/release.png" alt="" />
        <div v-if="identity == 4" class="flex-c">
          <div
            class="flex-c-c btn mr30"
            @click="changeType(index)"
            style="flex-direction: column"
            v-for="(item, index) of navType"
            :key="index"
          >
            <div :class="active1 == index ? 'navActive' : ''" class="nav-text">
              {{ item }}
            </div>
            <div
              class="default mt5"
              :class="active1 == index ? 'navActiveBottom' : ''"
            ></div>
          </div>
        </div>
        <div v-else class="nav-text">任务池</div>
      </div>
      <!-- 任务内容 -->
      <div class="pool-content">
        <template v-if="taskList.length > 0">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            offset="500"
            @load="onLoad"
          >
            <div
              @click="
                $router.push({
                  path: '/taskPoolDetail',
                  query: { id: item.id },
                })
              "
              v-for="item of taskList"
              :key="item.id"
              class="task-content"
            >
              <div class="task-content-top flex-c-b">
                <div class="task-title">{{ item.title }}</div>
                <van-rate
                  class="mt5"
                  size="15px"
                  color="#D71518"
                  void-color="#FFF"
                  :count="Number(item.difficulty_text)"
                  :value="Number(item.difficulty_text)"
                  readonly
                />
              </div>
              <div class="task-content-content">
                <div class="flex-c-b">
                  <div class="label">归属项目：</div>
                  <div
                    @click.stop="
                      $router.push({
                        path: '/projectDetail',
                        query: { id: item.project_id },
                      })
                    "
                    class="value line blue"
                  >
                    {{ item.project_name }}
                  </div>
                </div>
                <div class="flex-c-b mt10">
                  <div class="label">发布人：</div>
                  <div class="value">{{ item.create_name }}</div>
                </div>
                <div class="flex-c-b mt10">
                  <div class="label">发布时间：</div>
                  <div class="value">{{ item.createtime_text }}</div>
                </div>
              </div>
            </div>
          </van-list>
        </template>

        <van-empty v-else description="暂无任务" />
        <!-- 占位 -->
      </div>
    </div>
    <div class="style"></div>
  </div>
</template>

<script>
import * as API from "@/api.ts";

export default {
  name: "taskPool",
  data() {
    return {
      active1: 0, //任务类型，全部，我发起的,任务池
      page: 1,
      limit: 10,
      loading: false, //列表是否正在加载
      finished: false, //列表是否全部加载完成
      navType: ["全部", "我发起的", "任务池"],
      taskList: [], //任务池列表
      identity: "", //身份
    };
  },
  mounted() {
    console.log(111);
    console.log(JSON.parse(sessionStorage.getItem("userInfo")));
    this.identity = JSON.parse(sessionStorage.getItem("userInfo")).identity;
    console.log(this.identity);
    if (this.identity != 4) {
      this.active1 = 2;
    } else {
      this.active1 = 0;
      console.log(this.active1);
    }
    this.getData();
  },
  methods: {
    //加载更多
    onLoad() {
      if (!this.finished) {
        this.page++;
        // 获取计划列表
        this.getData();
      }
    },
    //切换任务类型
    changeType(val) {
      this.active1 = val;
      this.page = 1;
      this.disabled = false;
      this.noMore = false;
      this.loading = false;
      this.finished = false;
      //任务池列表
      this.getData();
    },
    //任务池列表
    async getData() {
      this.loading = true;
      let { page, limit, active1, taskList } = this;
      let params = {
        page,
        limit,
        type: active1,
      };
      let { code, data } = await API.taskList(params);
      if (code == 1) {
        let _list = data.rows;
        if (page == 1) {
          taskList = _list;
        } else {
          taskList.push(..._list);
        }
        if (_list.length == 0) {
          this.finished = true;
        }
        this.taskList = taskList;
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 0.26rem;
  font-weight: 500;
  color: #999999;
}
.value {
  font-size: 0.26rem;
  font-weight: 500;
  color: #333333;
}
.blue {
  color: #375394 !important;
}
.line {
  text-decoration: underline;
}
.content {
  background: #ffffff;
  border-radius: 0.16rem;
  margin: 0.2rem;
  // 任务池顶部
  .pool-header {
    position: sticky;
    top: 0;
    padding: 0.3rem;
    box-sizing: border-box;
    align-items: flex-start;
    line-height: 0.3rem;
    background: #fff;
    border-radius: 0.16rem;
    z-index: 200;
    .grade-log {
      width: 0.31rem;
    }
    .navActive {
      font-weight: 800;
      color: #d71518 !important;
    }
    .nav-text {
      font-weight: 500;
      color: #666666;
    }
    .default {
      width: 0.63rem;
      height: 0.04rem;
      background: #fff;
      border-radius: 0.02rem;
    }
    .navActiveBottom {
      width: 0.63rem;
      height: 0.04rem;
      background: #d71518;
      border-radius: 0.02rem;
    }
  }
  // 任务池内容
  .pool-content {
    // 任务盒子
    .task-content {
      background: #fafafa;
      border: 0.01rem solid #dddddd;
      border-radius: 0.08rem;
      margin: 0 0.3rem 0.2rem 0.3rem;

      // 任务顶部
      .task-content-top {
        padding: 0.2rem 0.3rem;
        box-sizing: border-box;
        border-bottom: 0.01rem solid #dddddd;
        .task-title {
          font-size: 0.3rem;
          font-weight: bold;
          color: #111111;
        }
      }
      // 任务内容
      .task-content-content {
        padding: 0.2rem 0.3rem;
        box-sizing: border-box;
      }
    }
  }
}
.style {
  width: 100%;
  height: 1.4rem;
}
</style>