<template>
  <div class="cp_description">
    <div class="cp_description_content_header">
      <publicNavigation :data="navigation" />
    </div>
    <div class="background_color">
      <div class="background_colors"></div>
    </div>
    <div class="cp_description_content_card_header_box">
      <div class="cp_description_content_card">
        <div class="cp_description_content_header_title">
          <div class="cp_description_title">
            <span>{{ productObject.title }}</span>
            <!-- <span>第{{ productObject.periodization }}次分红</span> -->
            <span>{{ num }}</span>
          </div>
          <div class="cp_description_text_list">
            <div class="cp_list" v-for="(s, k) in cpListData" :key="k">
              <span>{{ s.name }}</span>
              <span>{{ s.text }}</span>
            </div>
          </div>
          <div class="cp_description_my_money">
            <span>我的分红：</span>
            <span v-if="mydivvy > 0">￥{{ mydivvy }}</span>
            <span v-else>未公布</span>
          </div>
        </div>
      </div>
    </div>
    <div class="cp_record">
      <div class="cp_record_content">
        <div class="cp_record_content_box">
          <div class="cp_record_title">
            <span>分红记录</span>
          </div>

          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div class="cp_record_content_list">
              <div class="cp_record_fors" v-for="(s, k) in recorData" :key="k">
                <div class="cp_fors_title">
                  <span>{{ s.name }}</span>
                  <span v-if="s.text > 0"
                    >我的分红(税前): <i>￥{{ s.text }}</i>
                  </span>
                  <span v-else>未公布</span>
                </div>
                <div class="cp_fors_texts">
                  <span
                    >奖池：<i>{{ s.money }}</i></span
                  >
                  <span
                    >分红时间：<i>{{ s.time }}</i></span
                  >
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import publicNavigation from "@/publicNavigation/publicNavigation.vue";
import * as API from "@/api.ts";
export default {
  components: {
    publicNavigation,
  },
  data() {
    return {
      loading: false,
      finished: false,
      navigation: {
        image: require("@/assets/return.png"),
        name: "CP值详情",
        close: "",
      },
      mydivvy: "", //我的分红
      cpListData: [
        {
          name: "我的CP值：",
          text: "",
        },
        {
          name: "占比：",
          text: "",
        },
        {
          name: "总分红：",
          text: "",
        },
      ],
      page: 1,
      limit: 10,
      recorData: [],
      productObject: {},
      scrollTop: 0, //滚动距离
      num: "", //分红次数
      total: 0, //列表数量
    };
  },
  mounted() {
    var description = JSON.parse(this.$route.query.description);
    this.productObject = description;
    this.cpListData[0].text = this.productObject.cost_performance;
    this.cpListData[1].text = this.productObject.proportion;
    this.cpListData[2].text = this.productObject.prize_pool;
    this.requset();
  },
  methods: {
    //加载更多
    onLoad() {
      if (this.recorData.length < this.total) {
        this.requset();
      } else {
        this.loading = false;
      }
    },

    requset() {
      this.loading = true;
      API.cpDetail({
        id: this.productObject.project_id,
        user_id: this.productObject.user_id,
        page: this.page,
        limit: this.limit,
      }).then(async (e) => {
        console.log(e);
        if (e.code == 1) {
          this.mydivvy = e.data.mydivvy;
          this.num = "第" + e.data.divvy_num + "次分红";
          this.total = e.data.total;
          var list = e.data.rows;

          if (list.length > 0) {
            var newArr = [];
            list.map((item) => {
              newArr.push({
                name: "第" + item.periodization + "次分红",
                text: item.mydivvy,
                money: item.divvy > 0 ? item.divvy : "未公布",
                time: item.divvytime_text,
              });
            });
            if (this.page == 1) {
              this.recorData = newArr;
            } else {
              this.recorData = this.recorData.concat(newArr);
            }
            this.page++;
          } else {
            this.finished = true;
          }
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}
.scrollSty {
  background: #fff;
  box-shadow: 0px 4px 7px 0px #d0d0d0;
  transition: all 0.5s;
}
.cp_description {
  .cp_description_content_header {
    position: sticky;
    top: 0;
    z-index: 99;
    background: #f1f0f5;
  }
  .background_color {
    .background_colors {
      height: calcFunction(190);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: -1;
      background: linear-gradient(0deg, #feefef 0%, #ffffff 100%);
    }
  }
  .cp_description_content_card_header_box {
    margin-top: calcFunction(10);
    .cp_description_content_card {
      background: white;
      border-top-left-radius: calcFunction(8);
      border-top-right-radius: calcFunction(8);
      padding: calcFunction(15);
      .cp_description_content_header_title {
        .cp_description_title {
          @extend .alignItemsCenter;
          justify-content: space-between;
          padding-bottom: calcFunction(15);
          border-bottom: 1px solid #eeeeee;
          span {
            color: #666666;
            font-size: calcFunction(14);
          }
          span:nth-child(1) {
            color: #111111;
            font-size: calcFunction(16);
          }
        }
        .cp_description_text_list {
          margin-top: calcFunction(15);
          @extend .alignItemsCenter;
          justify-content: space-between;
          padding-bottom: calcFunction(15);
          border-bottom: 1px solid #eee;
          .cp_list {
            span {
              color: #999999;
              font-size: calcFunction(14);
            }
            span:nth-last-child(1) {
              color: #333333;
            }
          }
        }
        .cp_description_my_money {
          margin-top: calcFunction(15);
          text-align: right;
          span {
            color: #d71518;
            font-size: calcFunction(15);
          }
          span:nth-last-child(1) {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.cp_record {
  //   height: 70vh;
  background: #f1f0f5;
  .cp_record_content {
    padding: 0 calcFunction(10);
    .cp_record_content_box {
      padding-top: calcFunction(20);
      .cp_record_title {
        padding-bottom: calcFunction(15);
        span {
          font-size: calcFunction(15);
          color: #111111;
          font-weight: bold;
        }
      }
      .cp_record_content_list {
        .cp_record_fors {
          background: white;
          padding: calcFunction(15);
          border-radius: calcFunction(8);
          margin-bottom: calcFunction(10);
          .cp_fors_title {
            @extend .alignItemsCenter;
            justify-content: space-between;
            padding-bottom: calcFunction(15);
            border-bottom: 1px solid #eeeeee;
            span {
              font-size: calcFunction(15);
              color: #111111;
            }
            span:nth-last-child(1) {
              color: #333333;
              i {
                color: #d71518;
                font-style: normal;
                font-weight: 500;
              }
            }
          }
          .cp_fors_texts {
            margin-top: calcFunction(15);
            @extend .alignItemsCenter;
            justify-content: space-between;
            span {
              font-size: calcFunction(14);
              color: #999999;
              i {
                color: #333333;
                font-style: normal;
              }
            }
          }
        }
      }
    }
  }
}
</style>
