<template>
  <div class="record_list" style="height: 100%">
    <div class="record_lict_content">
      <div class="record_content_box">
        <div class="record_content_header">
          <div class="record_headers">
            <div class="return flex-c-c">
              <img src="@/assets/return.png" @click="returnClick" alt="" />
            </div>
            <van-search
              shape="round"
              @input="searchInput"
              v-model="search"
              class="searchs"
              placeholder="搜索商品名称或兑换号"
            />
          </div>
        </div>
        <div class="product_contents">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <recordData
              @recordDescription="recordDescription"
              @Cancelexchange="Cancelexchange"
              :recordData="recordData"
            />
          </van-list>
        </div>
      </div>
    </div>

    <van-popup :round="true" v-model="show">
      <div class="record_popup_show_content">
        <div class="record_show_content">
          <div class="record_show_title flex-c-c">
            <span>提示</span>
          </div>
          <div class="record_text_description">
            <div class="record_descriptions">
              <span>撤销后，奖励点将返回您的账户。</span>
            </div>
          </div>
          <div class="record_button">
            <div class="record_button_total">
              <button @click="closeEvent">取消</button>
              <van-button
                :loading="loading"
                loading-text="撤销中"
                @click="sureClickEvent"
                >确定</van-button
              >
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import recordData from "../compoments/recordListCard.vue";
import utils from "../../utils/utils";
import { Toast } from "vant";
// import {recordList} from './recordList.ts'
import * as API from "@/api.ts";
export default {
  components: {
    recordData,
  },
  data() {
    return {
      finished: false,
      recordData: [],
      loading: false, //是否是撤销中

      chilenHeight: "",
      page: 1,
      limit: 10,
      search: "", //输入的值
      show: false,
      productObject: {},
      sureShow: false,
    };
  },
  mounted() {
    this.Requset();
  },
  methods: {
    searchInput: utils.debounce(
      function () {
        this.page = 1;
        this.recordData = [];
        this.Requset();
      },
      400,
      false
    ),
    //加载更多
    onLoad() {
      if (this.recordData.length > 0) {
        this.Requset();
      } else {
        this.loading = false;
      }
    },

    Requset() {
      this.loading = true;
      let { search, page, limit } = this;
      //   this.recordData = [];
      const parms = {
        search,
        page,
        limit,
      };
      API.exchangeGiftLog(parms).then(async (e) => {
        if (e.code == 1) {
          if (page == 1) {
            if (e.data.rows.length > 0) {
              this.recordData = e.data.rows;
              this.page++;
            } else {
              this.finished = true;
            }
          } else {
            if (e.data.rows.length > 0) {
              this.recordData.push(...e.data.rows);
              this.page++;
              console.log(this.recordData.length);
            } else {
              this.finished = true;
            }
          }
          this.loading = false;
          Toast.clear();
        }
      });
    },
    recordDescription(s) {
      this.$router.push({
        name: "recordDescription",
        path: "/recordDescription",
        query: {
          product: s.id,
        },
      });
    },
    returnClick() {
      this.$router.back(1);
    },
    // 撤销兑换
    Cancelexchange(s) {
      this.productObject = s;
      this.sureShow = true;
      this.show = !this.show;
    },
    sureClickEvent() {
      this.loading = true;
      API.GiftRevoke({
        id: this.productObject.id,
      }).then(async (e) => {
        this.$notify({
          type: e.code == 1 ? "success" : "danger",
          message: e.msg,
        });
        setTimeout(() => {
          if (e.code == 1) {
            this.sureShow = !this.sureShow;
            this.show = !this.show;
            this.loading = false;
            this.page=1
            this.Requset();
          } else {
            this.loading = false;
          }
        }, 800);
      });
    },
    closeEvent() {
      this.show = !this.show;
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.record_list {
  height: 100vh;
  background: #f2f3f5;

  .record_lict_content {
    .record_content_box {
      .record_content_header {
        padding: 0 0.1rem;
        height: 0.88rem;
        background: white;
        position: sticky;
        top: 0;
        z-index: 99;
        .record_headers {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          .return {
            width: 0.88rem;
            height: 0.88rem;
            img {
              display: block;
              width: 0.19rem;
              height: 0.36rem;
            }
          }
          .searchs {
            flex: 1;
            margin-right: 0.25rem;
            padding: 0;
          }
        }
      }
    }
  }
}

.record_popup_show_content {
  width: 6rem;

  .record_show_content {
    padding-top: 0.6rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;

    .record_show_title {
      font-size: 0.34rem;
      font-weight: bold;
    }

    .record_text_description {
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
      .record_descriptions {
        text-align: center;
        font-size: 0.28rem;
        color: #585858;
      }
    }

    .record_button {
      padding-bottom: 0.4rem;
      .record_button_total {
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
          border: none;
          outline: none;
          width: 2.4rem;
          height: 0.9rem;
          border-radius: 0.45rem;
          font-size: 0.32rem;
        }
        button:nth-last-child(1) {
          background: #d71518;
          color: white;
        }
        button:nth-child(1) {
          border: 1px solid #e5e5e5;
          background: transparent;
        }
      }
    }
  }
}
</style>
