<template>
    <div class="reward_points" style="background:white;height:100vh;">
        <div class="reward_points_content">
            <div class="reward_points_content_box">
                <div class="reward_points_header">
                    <publicNavigation @sureEvenet="sureEvenet" :data="navigation" />
                </div>
                <div class="reward_points_list">
                    <div class="reward_points_list_content">
                        <div class="reward_points_name_select" :class="currentIndex == k ? 'active' : ''"
                            @click="currentEvent(s, k)" v-for="s, k in rewardPoints" :key="k">
                            <div class="selct_name">
                                <span>{{ s.title }}</span>
                                <img :src="currentIndex == k ? require('@/assets/selected.png') : require('@/assets/notchoose.png')"
                                    alt="" />
                            </div>
                            <div class="select_input" v-if="k == 2 && currentIndex == 2">
                                <input v-model="rewardText" type="text" placeholder="请输入奖励点">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import publicNavigation from '@/publicNavigation/publicNavigation.vue';
import * as API from '@/api.ts'
export default {
    components: {
        publicNavigation
    },
    data() {
        return {
            navigation: {},
            rewardPoints: [],
            currentIndex: 0,
            rewardText: '',
            selectInputs: '',
            origin: window.localStorage.getItem('sure')
        }
    },
    mounted() {
        this.navigation = {
            image: require('@/assets/return.png'),
            name: '奖励点',
            sure: '确定',
            close: ''
        }
        this.Request()
    },
    methods: {
        Request() {
            API.checkoprette().then(async e => {
                console.log(e)
                if (e.code == 1) {
                    this.rewardPoints = e.data.reward_method
                }
            })
        },
        currentEvent(s, k) {
            this.currentIndex = k
            this.selectInputs = s
        },
        sureEvenet() {
            const json = { rewardText: this.selectInputs ? this.selectInputs : this.rewardPoints[0], additional_number: this.rewardText }
            let newdata;
            if (this.origin) {
                newdata = Object.assign(JSON.parse(this.origin), json)
            } else {
                newdata = json
            }
            const jsons = JSON.stringify(newdata)
            if (this.selectInputs !== '自定义奖励点' || this.rewardText) {
                window.localStorage.setItem('sure', jsons)
                this.$toast.success('选择成功')
                setTimeout(() => {
                    this.$router.back(-1)
                }, 800);
            }
        }
    }
}
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
    display: flex;
    align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
    @return calc($number * 2 / 100) + rem;
}

.reward_points {
    .reward_points_content {
        .reward_points_content_box {
            .reward_points_header {}

            .reward_points_list {
                margin: calcFunction(10);

                .reward_points_list_content {
                    .reward_points_name_select {
                        background: #F1F0F5;
                        padding: calcFunction(15);
                        margin-bottom: calcFunction(10);
                        border-radius: calcFunction(4);

                        .selct_name {
                            @extend .alignItemsCenter;
                            justify-content: space-between;

                            span {
                                font-size: calcFunction(15);
                            }

                            img {
                                width: calcFunction(14);
                                height: calcFunction(14);
                            }
                        }

                        .select_input {
                            display: flex;

                            input {
                                margin-top: calcFunction(10);
                                flex: 1;
                                border: none;
                                font-size: calcFunction(14);
                                padding: calcFunction(10);
                            }
                        }
                    }
                }
            }
        }
    }
}


.active {
    border: 1px solid #D71518;
    background: #FFEAEA !important;

    .selct_name {
        span {
            color: #D71518;
        }
    }
}</style>