<template>
  <div class="grade_list">
    <div class="grade_list_card">
      <div class="grade_list_card_content">
        <div class="grade_list_content_for">
          <div
            class="grade_content_for"
            @click="descriptionClick(s)"
            v-for="(s, k) in resterende"
            :key="k"
          >
            <div class="grade_content_index">
              <div class="grade_index">
                <div class="grade_name_content">
                  <div class="index">
                    <span>{{ s.ranking }}</span>
                  </div>
                  <div class="grade_name">
                    <div class="name_image">
                      <img :src="s.image" alt="" />
                    </div>
                    <div class="name_content">
                      <div class="content">
                        <div class="user_name_of_postion">
                          <div class="user_name">
                            <span>{{ s.name }}</span>
                          </div>
                          <div class="user_position">
                            <span>{{ s.postion }}</span>
                          </div>
                        </div>
                        <div class="user_number">
                          <span>任务数：{{ s.number }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="user_lv_number">
                  <div class="user_lv_grade">
                    <span>Lv.{{ s.grade }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="position_user_list" v-if="mations.id != 1">
            <div class="position_user_list_content">
              <!-- mypersoninfomation -->
              <!-- v-for="s,k in mations" :key="k" -->
              <div class="grade_content_for">
                <div class="grade_content_index">
                  <div class="grade_index">
                    <div class="grade_name_content">
                      <div class="index">
                        <span>{{ mations.ranking }}</span>
                      </div>
                      <div class="grade_name">
                        <div class="name_image">
                          <img :src="mations.avatar" alt="" />
                        </div>
                        <div class="name_content">
                          <div class="content">
                            <div class="user_name_of_postion">
                              <div class="user_name">
                                <span>{{ mations.nickname }}</span>
                              </div>
                              <div class="user_position">
                                <span>{{ mations.score }}</span>
                              </div>
                            </div>
                            <div class="user_number">
                              <span>任务数：{{ mations.task_count }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="user_lv_number">
                      <div class="user_lv_grade">
                        <span>Lv.{{ mations.level }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    resterende: Array,
    mations: Object,
  },
  methods: {
    descriptionClick(s) {
      this.$emit("descriptionClick", s);
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.grade_list {
  margin-top: calcFunction(12);
  padding-bottom: 5.25rem;
  .grade_list_card {
    .grade_list_card_content {
      .grade_list_content_for {
        .grade_content_for {
          border: 1px solid #dcdcdc;
          padding: calcFunction(18) calcFunction(15);

          .grade_index {
            @extend .alignItemsCenter;
            justify-content: space-between;

            .grade_name_content {
              @extend .alignItemsCenter;

              .index {
                span {
                }
              }

              .grade_name {
                margin-left: calcFunction(20);
                @extend .alignItemsCenter;

                .name_image {
                  @extend .alignItemsCenter;

                  img {
                    width: calcFunction(35);
                    height: calcFunction(35);
                    border-radius: calcFunction(100);
                  }
                }

                .name_content {
                  margin-left: calcFunction(10);

                  .content {
                    .user_name_of_postion {
                      @extend .alignItemsCenter;

                      .user_name {
                        @extend .alignItemsCenter;

                        span {
                          font-size: calcFunction(14);
                          color: #111111;
                        }
                      }

                      .user_position {
                        @extend .alignItemsCenter;
                        margin-left: calcFunction(10);

                        span {
                          font-size: calcFunction(12);
                          color: #111111;
                        }
                      }
                    }

                    .user_number {
                      margin-top: calcFunction(1);

                      span {
                        color: #666666;
                        font-size: calcFunction(11);
                      }
                    }
                  }
                }
              }
            }

            .user_lv_number {
              .user_lv_grade {
                span {
                  background: #ffe7e7;
                  padding: calcFunction(2) calcFunction(5);
                  border-radius: calcFunction(100);
                  color: #d71518;
                  font-size: calcFunction(11);
                }
              }
            }
          }
        }

        .grade_content_for:nth-child(1n) {
          border-top: none;
        }

        .grade_content_for:nth-child(1) {
          border: 1px solid #dcdcdc;
        }

        // 底部样式处理
        .grade_content_for:nth-last-child(1) {
          padding-bottom: 20px;
        }
      }
    }
  }
}

.position_user_list {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;

  .position_user_list_content {
    background: #fff7f3;

    .grade_content_for {
      border: none !important;
    }
  }
}
</style>
