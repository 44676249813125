var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"list-box"},_vm._l((_vm.list),function(item){return _c('div',{key:item.id,staticClass:"list"},[_c('div',{staticClass:"task-list-top flex-c-b",staticStyle:{"align-items":"flex-start"}},[_c('div',{staticClass:"task-name"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"flex-c"},[_c('div',[_c('div',{staticClass:"status_text",style:({ color: _vm.statusColor(item.status) })},[_vm._v(" "+_vm._s(item.status_text)+" ")]),[_c('div',{staticClass:"blue underline btn",on:{"click":function($event){return _vm.navTo(item.task_id)}}},[_vm._v(" "+_vm._s(item.verify_text)+" ")]),_c('div',{staticStyle:{"color":"#999","font-size":"14px"}},_vm._l((item.review_person),function(person,index){return _c('div',{key:index,staticStyle:{"color":"#999","font-size":"14px"}},[_vm._v(" ("+_vm._s(person)+") "),(
                      index !=
                      (item.review_person && item.review_person.length - 1)
                    )?_c('span',[_vm._v(",")]):_vm._e()])}),0)]],2),(
              (_vm.section_id == item.section_id ||
              _vm.identity == 4 ||
              item.catch_modality == 1)&&item.status==1
            )?_c('div',{staticClass:"receivingOrders ml10",on:{"click":function($event){return _vm.receivingOrders(item.id)}}},[_vm._v(" 接单 ")]):_vm._e()])]),[_c('div',{staticClass:"flex-c mt20",staticStyle:{"align-items":"flex-start"}},[_c('div',{staticClass:"flex-c",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"label"},[_vm._v("执行人：")]),_c('div',{staticClass:"value"},[(item.catch_modality == 1)?_c('div',[_vm._v("公共接单")]):(item.catch_modality == 2)?_c('div',[_vm._v(" "+_vm._s(item.section_name)+" ")]):(item.catch_modality == 3)?_c('div',[_vm._v(" "+_vm._s(item.user_nickname)+" ")]):_vm._e()])]),_c('div',{staticClass:"flex-c",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"label"},[_vm._v("工作量：")]),_c('div',{staticClass:"value flex-c"},[_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.workload || "-")+" ")])])])]),_c('div',{staticClass:"flex-c mt20 mb20",staticStyle:{"align-items":"flex-start"}},[_c('div',{staticClass:"flex-c",staticStyle:{"width":"50%","align-items":"flex-start"}},[_c('div',{staticClass:"label"},[_vm._v("开始时间：")]),_c('div',{staticClass:"value"},[[([3, 4, 9].includes(item.status))?_c('div',[_c('span',[_vm._v(_vm._s(item.starttime_text || "--"))]),_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("(预)")])]):_c('div',[_vm._v(" "+_vm._s(item.real_starttime_text || "--")+" ")])]],2)]),_c('div',{staticClass:"flex-c",staticStyle:{"width":"50%","align-items":"flex-start"}},[_c('div',{staticClass:"label"},[_vm._v("完成时间：")]),_c('div',{staticClass:"value flex-c"},[[(![6, 7, 8].includes(item.status))?_c('div',[_c('span',[_vm._v(" "+_vm._s(item.endtime_text || "--")+" ")]),(!item.real_endtime_text && item.endtime_text)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("(预)"+_vm._s(item.real_endtime_text))]):_vm._e()]):_c('div',[_vm._v(" "+_vm._s(item.real_endtime_text)+" "),(item.timeout != 0)?_c('span',{style:({
                      color: _vm.statusTextColor(item.timeout > 0 ? 2 : 1),
                      whiteSpace: 'nowrap',
                    })},[_vm._v("("+_vm._s(item.timeout_text)+")")]):_vm._e()])]],2)])])]],2)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }