<template>
    <div class="rewards">
        <div class="navigation_header">
            <publicNavigation  :data="navigation" />
        </div>
        <div class="background_color">
            <div class="background_colors"></div>
        </div>
        <!-- 项目名 -->
        <projekt :projekt="projekt" />
        <!-- 项目介绍 -->
        <introducere :offsetover="1" />
        <!-- 标签切换 -->
        <descriptionCardTabbar @rewardsEvent="rewardsEvent" :rewardsContent="rewardsContent" :iconData="iconData" />
    </div>
</template>
<script>
import publicNavigation from '@/publicNavigation/publicNavigation.vue';
import projekt from '@/projectList/compoments/descriptionCard.vue'
import introducere from '@/projectList/compoments/descriptionIntroducere.vue'
import descriptionCardTabbar from '@/projectList/compoments/descriptionCardTabbar.vue'
import {rewardsContent} from './rewardsContent.ts'
export default {
    components:{
        publicNavigation,
        projekt,
        introducere,
        descriptionCardTabbar
    },
    data(){
        return{
            navigation:Object,
            iconData:Array,
            rewardsContent:Array
        }
    },
    mounted(){
        this.iconData = ['任务/步骤','任务验收','任务日志','任务奖励']
        this.navigation = {
            image:require('../assets/return.png'),
            name:'章鱼云APP后台开发',
			close:''
        },
        this.projekt = {
            name:'章鱼云管理系统程序开发',
            children:[{
                name:'类型：',
                text:'开发'
            },{
                name:'创建人：',
                text:'李四'
            },{
                name:'创建时间：',
                text:'2023.05.05'
            }]
        }
        const parms = new rewardsContent().init()
        this.rewardsContent = parms.rewardsContent
    },
    methods:{
        rewardsEvent(s){
            console.log(s)
            this.$router.push({
                name:'settingRewards',
                path:'/settingRewards'
            })
        }
    }
}

</script>
<style lang="scss">
// 继承变量
.alignItemsCenter{
    display: flex;
    align-items: center;
}
  
// 计算函数
@function calcFunction($number,$default:16){
  @return calc($number * 2 / 100) + rem;
}


.rewards{
    height: 100vh;
    // background: #F1F0F5;
    .navigation_header{
        z-index: 2;
    }
    .background_color{
        .background_colors{
            height: calcFunction(190);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: -1;
            background: linear-gradient(0deg, #FEEFEF 0%, #FFFFFF 100%);
        }
    }
}
</style>