<!--
 * @Author: Mr.Cheng
 * @Date: 2023-10-10 14:25:06
 * @LastEditTime: 2023-10-30 21:42:54
 * @FilePath: \octopus-factory-map\src\new\perfrom\perFromDetail.vue
-->
<template>
  <div>
    <!-- 顶部背景 -->
    <div class="top-bg">
      <!-- 顶部标题 -->
      <div
        style="background: unset; border-bottom: unset"
        class="flex-c-c task-navigate-box"
      >
        <!-- 返回按钮 -->
        <img
          @click="navigateBack"
          class="task-left-back"
          src="@/assets/return.png"
          alt=""
        />
        <div>执行任务详情</div>
      </div>
      <!-- 任务名&状态 -->
      <div style="align-items: flex-start" class="flex-c-b mt20">
        <div class="task-name">{{ details.PidInfo.title }}</div>
        <div
          :style="{ color: statusColor(details.PidInfo.status) }"
          class="status_text"
        >
          {{ details.PidInfo.status_text }}
        </div>
      </div>
      <!-- 类型&难度指数 -->
      <div class="flex-c mt10">
        <!-- 类型 -->
        <div class="mr40">
          <span class="label">类型：</span>
          <span class="type_text">{{ details.PidInfo.task_type_text }}</span>
        </div>

        <!-- 难度指数 -->
        <div class="flex-c">
          <span class="label">难度：</span>

          <van-rate
            size="15px"
            color="#D71518"
            void-color="rgba(0,0,0,0)"
            :value="Number(details.PidInfo.difficulty_text)"
            readonly
          />
        </div>
      </div>
    </div>

    <!-- 任务基本信息 归属项目~任务附件 -->
    <div class="basicInfo">
      <!-- 归属项目 -->
      <div class="flex-c">
        <div class="label">归属项目：</div>
        <div
          @click="
            $router.push({
              path: '/projectDetail',
              query: { id: details.project.id },
            })
          "
          class="value blue line"
        >
          {{ details.project.title }}
        </div>
      </div>

      <div class="flex-c mt10">
        <!-- 关联任务 -->
        <div style="width: 50%" class="flex-c">
          <div class="label">关联任务：</div>
          <div class="value">{{ details.project.correlation_count }}个</div>
        </div>
        <!-- 指派时间 -->
        <div style="width: 50%" class="flex-c">
          <div class="label">指派时间：</div>
          <div class="value">
            {{ details.PidInfo.createtime_text }}
          </div>
        </div>
      </div>

      <div class="flex-c mt10" style="align-items: flex-start">
        <!-- 发布人 -->
        <div style="width: 50%" class="flex-c">
          <div class="label">发布人：</div>
          <div class="value ">
            {{ details.PidInfo.create_nickname }}
          </div>
        </div>
        <!-- 验收人 -->
        <div style="width: 50%; align-items: flex-start" class="flex-c">
          <div class="label">验收人：</div>
          <div style="flex-wrap: wrap" class="value flex-c">
            <div
              style="white-space: nowrap"
              v-for="(item, index) of details.PidInfo.inspect"
              :key="item"
            >
              {{ item }}
              <template v-if="index != details.PidInfo.inspect.length - 1">
                、</template
              >
            </div>
          </div>
        </div>
      </div>

      <!-- 验收标准 -->
      <div style="align-items: flex-start" class="flex-c mt10">
        <div class="label">验收标准：</div>
        <div style="white-space: pre-line" class="value">
          {{ details.PidInfo.acceptance_criteria || "无" }}
        </div>
      </div>
      <!-- 任务介绍 -->
      <div style="align-items: flex-start" class="flex-c mt10">
        <div class="label">任务介绍：</div>
        <div style="white-space: pre-line" class="value">
          {{ details.PidInfo.introduce || "无" }}
        </div>
      </div>
      <!-- 任务附件 -->
      <div style="align-items: flex-start" class="flex-c mt10">
        <div class="label">任务附件：</div>
        <div
          class="flex-c"
          style="flex-wrap: wrap"
          v-if="details.PidInfo.attachment_info.length > 0"
        >
          <a
            :href="item.url"
            v-for="item of details.PidInfo.attachment_info"
            :key="item.id"
            target="blank"
            class="line blue btn"
          >
            <div>
              {{ item.filename }}
            </div>
          </a>
        </div>
        <div v-else class="value">无</div>
      </div>
      <!-- 图片 -->
      <div style="align-items: flex-start" class="flex-c mt10">
        <div class="label">图片：</div>
        <div
          class="flex-c"
          style="flex-wrap: wrap"
          v-if="details.PidInfo.taskimg_info.length > 0"
        >
          <a
            :href="item.url"
            target="blank"
            v-for="item of details.PidInfo.taskimg_info"
            :key="item.id"
            class="line blue btn"
          >
            <div>
              {{ item.filename }}
            </div>
          </a>
        </div>
        <div v-else class="value">无</div>
      </div>
    </div>

    <van-tabs
      @change="changeNav"
      v-model="active"
      animated
      title-active-color="#D71518"
    >
      <van-tab title="任务/步骤">
        <!-- getDetail 子调用获取父任务详情 -->
        <!-- showStopStepDetailEvent 子调用显示步骤暂停弹窗 -->
        <!-- showStopDetailEvent 子调用显示任务暂停弹窗 -->
        <!-- showStopReason 子调用显示任务暂停原因弹窗 -->
        <!-- list 子获取父任务中任务/步骤列表 -->
        <!-- status 子获取父任务中任务状态 -->
        <!-- closeTask 显示关闭任务弹窗 -->
        <task
          @getDetail="getDetail"
          @showStopStepDetailEvent="showStopStepDetailEvent"
          @showStopDetailEvent="showStopDetailEvent"
          @showStopFrom="showStopReason"
          @closeTask="closeTask"
          :list="details.rows"
          :status="details.PidInfo.status"
        />
      </van-tab>
      <van-tab title="任务验收">
        <!-- list 子获取任务验收列表数据 -->
        <!-- status 子获取父任务中任务状态 -->
        <taskAccept :list="taskAcceptance" :status="details.PidInfo.status" />
      </van-tab>
      <van-tab title="任务日志">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="logList.length > 0 ? '没有更多了' : ''"
          offset="150"
          @load="onLoad"
        >
          <!-- writePersonShow 填写人筛选弹窗显隐 -->
          <!-- showDate 日期筛选弹窗显隐 -->
          <!-- clearWrite 清除填写人筛选 -->
          <!-- clearDate 清除日期筛选 -->
          <!-- toWriteLog 跳转填写日志页面 -->
          <taskLog
            @writePersonShow="writePersonShow = true"
            @showDate="showDate = true"
            @clearWrite="clearWrite"
            @clearDate="clearDate"
            @toWriteLog="toWriteLog"
            :date="date"
            :list="logList"
            :writePerson="writePerson"
          />
        </van-list>
      </van-tab>
      <van-tab title="任务奖励">
        <singleReward
          ref="singleRewardDOM"
          @correctShow="correctEvent"
          :id="details.PidInfo.id"
        />
      </van-tab>
    </van-tabs>

    <!-- 任务/步骤 -->
    <template>
      <!-- 暂停详情弹窗 任务-->
      <van-popup
        v-model="showStopDetail"
        closeable
        round
        position="bottom"
        :style="{ height: '40%' }"
      >
        <div class="flex-c-c stop-detail-title">暂停详情</div>
        <div style="align-items: flex-start" class="flex-c-b stop-box">
          <div>
            <div style="font-weight: bold">暂停时间</div>
            <div
              class="mt10"
              v-for="(item, index) of itemData.suspend"
              :key="index"
            >
              {{ item.suspendtime_starttime||"" }}
              <!-- {{ item.suspendtime_endtime||"" }} -->
            </div>
          </div>
          <div>
            <div style="font-weight: bold">开始时间</div>
            <div
              class="mt10"
              v-for="(item, index) of itemData.suspend"
              :key="index"
            >
              {{ item.suspendtime_endtime||"" }}
              <!-- {{ item.suspendtime_starttime||"" }} -->
            </div>
          </div>
          <div>
            <div style="font-weight: bold">类型</div>
            <div
              class="mt10"
              v-for="(item, index) of itemData.suspend"
              :key="index"
            >
              暂停任务
            </div>
          </div>
        </div>
      </van-popup>

      <!-- 暂停详情弹窗 步骤-->
      <van-popup
        v-model="showStopStepDetail"
        closeable
        round
        position="bottom"
        :style="{ height: '40%' }"
      >
        <div class="flex-c-c stop-detail-title">暂停详情</div>
        <div style="align-items: flex-start" class="flex-c-b stop-box">
          <div>
            <div style="font-weight: bold">暂停时间</div>
            <div
              class="mt10"
              v-for="(item, index) of itemStepData.suspend"
              :key="index"
            >
              {{ item.suspend_starttime }}
              <!-- {{ item.suspend_endtime }} -->
            </div>
          </div>
          <div>
            <div style="font-weight: bold">开始时间</div>
            <div
              class="mt10"
              v-for="(item, index) of itemStepData.suspend"
              :key="index"
            >
              {{ item.suspend_endtime }}
              <!-- {{ item.suspend_starttime }} -->
            </div>
          </div>
          <div>
            <div style="font-weight: bold">类型</div>
            <div
              class="mt10"
              v-for="(item, index) of itemStepData.suspend"
              :key="index"
            >
              暂停步骤
            </div>
          </div>
        </div>
      </van-popup>

      <!-- 暂停原因弹窗 -->
      <van-dialog
        @confirm="stopConfirm"
        v-model="showStopFrom"
        title="暂停"
        show-cancel-button
      >
        <div class="stop-reason-box">
          <div class="flex-c">
            <div>暂停时间：</div>
            <div>{{ nowDate }}</div>
          </div>
          <textarea
            class="mt10 mb10 stop-reason"
            v-model="stopReason"
            type="text"
            placeholder="请输入暂停原因"
          />
        </div>
      </van-dialog>

      <!-- 关闭任务确认弹窗 -->
      <van-dialog
        @confirm="closeTaskEvent"
        v-model="closeTaskShow"
        title="提示"
        show-cancel-button
      >
        <div v-if="type == 2" class="stop-reason-box">
          <div>
            {{
              "该任务已经执行了" +
              details.PidInfo.ago +
              "个工作日，请确定是否提前关闭，如果关闭， 奖励点结算为："
            }}
          </div>
          <van-cell-group>
            <van-field
              type="number"
              class="mt10"
              v-model="num"
              placeholder="输入奖励点数"
            />
          </van-cell-group>
        </div>
        <div
          style="padding-top: 0"
          v-if="type == 1"
          class="stop-reason-box flex-c-c"
        >
          确认关闭该订单？
        </div>
      </van-dialog>
    </template>

    <!-- 任务日志 -->
    <template>
      <!-- 填写人 -->
      <van-popup
        v-model="writePersonShow"
        position="bottom"
        round
        :style="{ height: '30%' }"
      >
        <van-picker
          title="填写人"
          show-toolbar
          :columns="inputPersonTotaslArray"
          @confirm="onWriteConfirm"
          @cancel="writePersonShow = false"
        />
      </van-popup>

      <!-- 日期弹窗 -->
      <van-calendar
        :min-date="new Date(2023, 0, 1)"
        v-model="showDate"
        @confirm="onDateConfirm"
      />
    </template>

    <!-- 任务奖励 -->
    <template>
      <van-popup
        closeable
        v-model="correctShow"
        round
        position="bottom"
        :style="{ height: 'auto' }"
      >
        <correct @getReward="getReward" :correctData="correctData" />
      </van-popup>
    </template>
  </div>
</template>

<script>
import task from "@/new/perfrom/components/task.vue"; //任务/步骤
import taskAccept from "@/new/perfrom/components/taskAccept.vue"; //验收任务
import taskLog from "@/new/perfrom/components/taskLog.vue"; //任务日志
import singleReward from "@/new/perfrom/components/singleReward.vue"; //任务奖励
import correct from "@/new/components/correct.vue"; //任务奖励点修正
import * as API from "@/api.ts";
export default {
  name: "perFromDetail",
  components: {
    task,
    taskLog,
    taskAccept,
    singleReward,
    correct,
  },
  data() {
    return {
      /**
       * 共用
       */
      details: {
        PidInfo: {
          title: "",
          taskimg_info: [],
          attachment_info: [],
        },
        project: {
          title: "",
        },
      }, //详情数据
      active: 0, //当前选中栏下标

      /**
       * 任务/步骤
       */
      showStopDetail: false, //暂停详情弹窗显隐
      showStopStepDetail: false, //暂停步骤详情弹窗显隐
      showStopFrom: false, //暂停原因弹窗显隐
      closeTaskShow: false, //关闭任务弹窗显隐
      itemData: {}, //暂停详情数据(步骤)
      itemStepData: {}, //暂停详情数据(任务)
      nowDate: "", //点击暂停按钮时的时间
      stopReason: "", //暂停原因
      id: "", //暂停时选中的id
      fType: "", //操作类型
      num: "", //输入的奖励点数
      task_id: "", //关闭任务时选中的id
      type: "", //1：不需填写奖励点，2：需填写奖励点

      /**
       * 任务验收
       * */
      taskAcceptance: [], //任务验收列表

      /**
       * 任务日志
       * */
      writePersonShow: false, //填写人弹窗显隐
      showDate: false, //日期弹窗显隐
      inputPersonTotaslArray: [], //填写人弹窗列表
      writePerson: "", //选中的填写人
      date: "", //选中的日期(页面显示)
      start_time: "", //选中的日期(请求参数)
      loading: false,
      finished: false,
      logPage: 1,
      logLimit: 5,
      logList: [], //日志列表

      /**
       * 任务奖励
       * */
      correctShow: false, //任务奖励修正弹窗显隐
      correctData: {}, //修正奖励点选中的数据
    };
  },
  computed: {
    // 任务状态文字颜色
    statusColor() {
      return (e) => {
        let color;
        switch (e) {
          case 0:
            break;
          case 1:
            color = "#D71518";
            break;
          case 2:
            break;
          case 3:
            break;
          case 4:
            color = "#D71518";
            break;
          case 5:
            color = "#00A2FF";
            break;
          case 6:
            color = "#FF4200";
            break;
          case 7:
            color = "#666666";
            break;
          case 8:
            color = "#FF4200";
            break;

          default:
            break;
        }
        return color;
      };
    },
  },
  mounted() {
    this.active = Number(sessionStorage.getItem("newActive"));
    console.log(this.active);
    // 获取详情数据
    this.getDetail();
    //获取填写人列表数据
    this.taskLogUser();
    // 获取任务日志
    this.getTaskLog();
    //获取任务验收列表
    this.getCheckLists();
  },
  methods: {
    // 返回上一页
    navigateBack() {
      this.$router.go(-1);
      sessionStorage.setItem("newActive", "");
    },
    // 获取详情数据
    async getDetail() {
      let params = {
        ids: this.$route.query.id,
      };
      let { code, data } = await API.details(params);
      if (code == 1) {
        this.details = data;
      }
    },
    //获取任务验收列表
    async getCheckLists() {
      let params = {
        task_id: this.$route.query.id,
      };
      let { code, data } = await API.projectlists(params);
      if (code == 1) {
        this.taskAcceptance = data.rows;
      }
    },
    //暂停详情弹窗显示（任务）
    showStopDetailEvent(item) {
      this.showStopDetail = true;
      this.itemData = item;
    },
    //暂停详情弹窗显示（步骤）
    showStopStepDetailEvent(item) {
      this.showStopStepDetail = true;
      this.itemStepData = item;
    },
    // 暂停原因弹窗显示
    showStopReason({ date, id, fType }) {
      this.showStopFrom = true;
      this.id = id;
      this.nowDate = date;
      this.fType = fType;
    },
    //确认暂停
    stopConfirm() {
      let { id, fType, stopReason } = this;
      let params = {
        id,
        reason: stopReason,
        type: fType, //1:步骤暂停，2：任务暂停
      };
      let _this = this;
      API.Processpause(params).then((res) => {
        if (res.code == 1) {
          _this.$toast(res.msg);
          _this.getDetail();
          //获取任务日志
          _this.getTaskLog();
        }
      });
    },

    //获取填写人列表数据
    taskLogUser() {
      API.taskLogUser({
        id: this.$route.query.id,
        type: 1,
      }).then(async (e) => {
        if (e.code == 1) {
          this.inputPersonTotaslArray = e.data.map((item) => {
            return item.nickname;
          });
          this.inputPersonTotaslArray.unshift("全部");
        }
      });
    },
    // 确定填写人
    onWriteConfirm(value) {
      this.writePerson = value;
      this.writePersonShow = false;
      this.logPage = 1;
      this.loading = false;
      this.finished = false;
      this.logList = [];
      this.getTaskLog();
    },
    // 确定日期
    onDateConfirm(value) {
      this.date = this.$utils.getDate(value);
      this.start_time = value.setHours(0, 0, 0, 0) / 1000;
      this.showDate = false;
      this.logPage = 1;
      this.loading = false;
      this.finished = false;
      this.logList = [];
      this.getTaskLog();
    },
    // 获取任务日志
    async getTaskLog() {
      this.loading = true;
      let {
        logPage: page,
        logLimit: limit,
        writePerson,
        start_time,
        logList: list,
      } = this;
      let params = {
        id: this.$route.query.id,
        page,
        limit,
        user_name: writePerson == "全部" ? "" : writePerson,
        start_time,
      };
      let { code, data } = await API.taskLog(params);
      if (code == 1) {
        let _list = data.lists.rows;
        if (page == 1) {
          list = _list;
        } else {
          list.push(..._list);
        }
        if (_list.length == 0) {
          this.finished = true;
        }
        this.logList = list;
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    // 清除填写人
    clearWrite() {
      this.writePerson = "";
      this.logPage = 1;
      this.loading = false;
      this.finished = false;
      this.logList = [];
      this.getTaskLog();
    },
    // 清除日期
    clearDate() {
      this.date = "";
      this.start_time = "";
      this.logPage = 1;
      this.loading = false;
      this.finished = false;
      this.logList = [];
      this.getTaskLog();
    },
    //加载更多
    onLoad() {
      if (!this.finished) {
        this.logPage++;
        // 获取计划列表
        this.getTaskLog();
      }
    },
    // 跳转至填写日志页
    toWriteLog() {
      sessionStorage.setItem("newActive", this.active);
      this.$router.push({
        path: "/writeLog",
        query: {
          id: this.details.PidInfo.id,
          task: this.details.PidInfo.title,
        },
      });
    },
    // 修正奖励点弹窗
    correctEvent(item) {
      this.correctShow = true;
      this.correctData = item;
    },
    // 栏目切换
    changeNav(value) {
      switch (value) {
        case 1:
          //获取任务验收列表
          this.getCheckLists();
          break;
        case 2:
          // 获取任务日志
          this.getTaskLog();
          break;
        case 3:
          // 获取任务奖励
          this.$refs.singleRewardDOM && this.$refs.singleRewardDOM.getList();
          break;

        default:
          break;
      }
    },
    // 获取任务奖励
    getReward() {
      // 获取任务奖励
      this.correctShow = false;
      console.log(this.correctShow);
      this.$refs.singleRewardDOM && this.$refs.singleRewardDOM.getList();
    },
    // 关闭任务(弹窗)
    closeTask(id, status) {
      this.closeTaskShow = true;
      this.task_id = id;
      //判断任务是否开始
      if ([5, 6, 7].includes(status)) {
        this.type = 2;
      } else {
        this.type = 1;
      }
    },
    // 关闭任务
    async closeTaskEvent() {
      let params = {
        ids: this.task_id,
        reward: this.num,
      };
      let { code, msg } = await API.close_task(params);
      this.$toast(msg);
      if (code == 1) {
        this.closeTaskShow = false;

        if (this.task_id == this.$route.query.id) {
          setTimeout(() => {
            this.$router.go(-1);
          }, 500);
        } else {
          // this.$router.replace({
          //   path: "/perFromDetail",
          //   query: { id: this.$route.query.id },
          // });
          this.getDetail();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-tabs__wrap {
  position: sticky !important;
  top: 0 !important;
  z-index: 500 !important;
}
// 顶部背景
.top-bg {
  padding: 0 0.4rem;
  box-sizing: border-box;
  width: 100vw;
  height: 3.8rem;
  background: linear-gradient(0deg, #feefef 10%, #ffffff 87%);
  // 任务名称
  .task-name {
    font-size: 0.3rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #111111;
  }
  // 任务状态文字
  .status_text {
    font-size: 0.28rem;
    font-family: PingFang SC;
    font-weight: 500;
    white-space: nowrap;
    line-height: 0.5rem;
  }
}

.label {
  font-size: 0.28rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  white-space: nowrap;
}
.value {
  font-size: 0.28rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333;
}
// 下划线
.line {
  text-decoration: underline;
}
.blue {
  color: #375394;
}
// 任务类型值
.type_text {
  font-size: 0.28rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #111111;
}
// 任务基本信息 归属项目~任务附件
.basicInfo {
  padding: 0.4rem 0.38rem;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0 -0.05rem 0.08rem 0 rgba(0, 0, 0, 0.03);
  border-radius: 0.32rem 0.32rem 0 0;
  margin-top: -1rem;
}
// 暂停任务详情标题
.stop-detail-title {
  font-size: 0.35rem;
  font-family: PingFang SC;
  color: #111111;
  margin-top: 0.3rem;
}
.stop-box {
  padding: 0.3rem;
  box-sizing: border-box;
}

// 暂停原因弹窗盒子
.stop-reason-box {
  padding: 0.3rem;
  box-sizing: border-box;
  .stop-reason {
    width: 100%;
    height: 3rem;
    padding: 0.2rem;
    box-sizing: border-box;
  }
}
</style>