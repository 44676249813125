<!--
 * @Author: Mr.Cheng
 * @Date: 2023-10-30 10:31:56
 * @LastEditTime: 2023-10-30 19:58:42
 * @FilePath: \octopus-factory-map\src\new\personnelManagement\index.vue
-->
<template>
  <div
    style="
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    "
  >
    <div class="top-box">
      <!-- 顶部标题 -->
      <div class="flex-c-c task-navigate-box">
        <!-- 返回按钮 -->
        <img
          @click="navBack"
          class="task-left-back"
          src="@/assets/return.png"
          alt=""
        />
        <div>人事管理</div>
      </div>
    </div>
    <div class="content" style="flex: 1; overflow-y: scroll">
      <!-- 部门管理&员工管理nav -->
      <div
        style="padding: 0.3rem 0.2rem 0 0.2rem; box-sizing: border-box"
        class="flex-c-b"
      >
        <div @click="navCheck(1)" :class="['nav', active == 1 ? 'active' : '']">
          部门管理
        </div>
        <div @click="navCheck(2)" :class="['nav', active == 2 ? 'active' : '']">
          员工管理
        </div>
      </div>

      <!-- 部门管理 -->

      <div
        v-show="active == 1"
        style="padding: 0 0.2rem; box-sizing: border-box"
      >
        <department />
      </div>
      <!-- 员工管理 -->
      <staff v-show="active == 2" />
    </div>
  </div>
</template>

<script>
import * as API from "@/api.ts";
import department from "./components/department.vue";
import staff from "./components/staff.vue";
export default {
  data() {
    return {
      active: 1,
    };
  },
  components: { department, staff },
  mounted() {
    this.active = sessionStorage.getItem("personnelManagementActive") || 1;
  },
  methods: {
    // 返回
    navBack() {
      sessionStorage.setItem("personnelManagementActive", "1");
      this.$router.go(-1);
    },
    navCheck(index) {
      this.active = index;
      sessionStorage.setItem("personnelManagementActive", index);
    },
  },
};
</script>

<style lang="scss" scoped>
.top-box {
  position: sticky;
  top: 0;
  z-index: 500;
}
.task-navigate-box {
  border-bottom: unset !important;
}
.content {
  background: #fff;

  .nav {
    background: #f1f0f5;
    border: 0.01rem solid #f1f0f5;
    border-radius: 0.08rem;
    padding: 0.25rem 1.1rem;
    box-sizing: border-box;
    font-size: 0.3rem;
    font-weight: 500;
    color: #111111;
  }
  .active {
    padding: 0.25rem 1.1rem;
    box-sizing: border-box;
    font-size: 0.3rem;
    font-weight: 500;
    background: #ffeaea;
    border: 0.01rem solid #d71518;
    border-radius: 0.08rem;
    font-family: PingFang SC;
    color: #d71518;
  }
}
</style>