<template>
    <div class="task_disassembly">
        <div class="task_disassembly_content">
            <div class="task_disassembly_navigation">
                <publicNavigation :data="navigation" />
            </div>
            <div class="task_button_classfiy">
                <div class="task_button_classfiy_click">
                    <button v-for="s,k in buttonClassfiy" :class="currentIndex == k ? 'active' : ''" @click="buttonClick(s,k)" :key="k">{{ s }}</button>
                </div>
            </div>
            <div class="task_content_select_time">
                <div class="task_select_time">
                    <div class="task_time_title">
                        <span>任务时间</span>
                    </div>
                    <div class="task_select_times" @click="kalender">
                        <div class="times_selct">
                            <span>{{startTimes || '开始时间'}}</span>
                            <span>—</span>
                            <span>{{endTimes || '结束时间'}}</span>
                        </div>
                    </div>
                </div>
            </div>
			<div class="trin_content" v-if="currentIndex == 1">
				<div class="trin_content_data">
					<div class="trin_content_header">
						<div class="trin_content_left_title">
							<span>执行步骤</span>
							<img :src="require('@/assets/appendix.png')" @click="addStep" alt="">
						</div>
						<!-- <div class="trin_button_uploding">
							<input type="file" name="" id="" @change="onafterprint" value="" />
							<van-uploader :after-read="onafterprint">
								<van-button icon="plus" type="primary">上传文件</van-button>
							</van-uploader>
						</div> -->
						<div v-if="fileList" class="uploading">
							<div class="uploadings" v-for="s,k in fileList" :key="k">
								<span>{{s.sequence}} {{s.title}}</span>
								<img :src="require('@/assets/reddelete.png')" @click="spliceFunction(s,k)" alt="">
							</div>
						</div>
					</div>
				</div>
			</div>
        </div>
		<div class="task_bottom_button">
			<div class="task_sure_bottom_button">
				<button @click="submitFromEvent" :style="showButton ? 'background:#D71518;color:white;' : ''">提交</button>
			</div>
		</div>
		<van-calendar v-model="show" @close="offKalender" type="range" @confirm="onConfirm" />
		<van-popup v-model="stepShow" @close="stepShow = false" round class="window_step" position="center">
			<div class="step_input">
				<div class="step_input_content">
					<div class="step_input_title">
						<span>请填写您要拆解内容</span>
					</div>
					<!-- <div class="setp_input_sequence">
						<input type="text" v-model="sequence" placeholder="请输入任务">
					</div> -->
					<div class="setp_input_title">
						<input type="text" v-model="title" placeholder="请输入任务">
					</div>
				</div>
				<div class="step_inpit_button">
					<button @click="closeButton">取消</button>
					<button @click="confirmSure">确定</button>
				</div>
			</div>
		</van-popup>
    </div>
</template>
<script>
import publicNavigation from '@/publicNavigation/publicNavigation.vue';
import * as API from '@/api.ts'
import axios from 'axios'
import { Toast,Dialog } from 'vant'
export default {
    components:{
        publicNavigation
    },
    data(){
        return{
            navigation:{},
            buttonClassfiy:['无需拆解','拆解'],
            currentIndex:0,
            date: '',
            show: false,
			startTimes:'',
			endTimes:'',
			file:'',
			fileList:[],
			stepShow:false,
			sequence:'',
			title:''
        }
    },
	watch:{
		startTimes(e){
			if(this.endTimes && e && this.fileList.length > 0){
				this.showButton  =true
			}else{
				this.showButton  =false
			}
		},
		endTimes(e){
			if(this.startTimes && e && this.fileList.length > 0){
				this.showButton  =true
			}else{
				this.showButton  =false
			}
		},
		fileList(){
			console.log(this.fileList)
			if(this.startTimes && this.fileList.length > 0 && this.endTimes){
				this.showButton  =true
			}else{
				this.showButton  =false
			}
		}
	},
    mounted(){
        this.navigation = {
            image:require('@/assets/return.png'),
            name:'任务拆解',
			close: ''
        }
    },
    methods:{
        buttonClick(s,k){
            this.currentIndex = k
        },
        formatDate(date) {
			const data = new Date()
            return `${data.getFullYear() + '-' + (date.getMonth() < 10 ? '0' + (date.getMonth() + 1) :  date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`;
        },
        onConfirm(date) {
            const [start, end] = date;
            this.show = !this.show;
            let startTime = this.formatDate(start)
			let endTime = this.formatDate(end)
			this.startTimes = startTime
			this.endTimes = endTime
        },
		kalender(){
			this.show = true
		},
		offKalender(){
			this.show = false
		},
		onafterprint(file){
			console.log(file)
			this.fileList.push(file.file.name)
			let data = new FormData();
			data.append("file", file.file);
			let config = {
				//添加请求头 
				headers: {
					"Content-Type": "multipart/form-data"
				},
			};
			axios.post('/admin/ajax/upload',data,config).then(async e=>{
				if(e.data.status == 203){
					Dialog({
						title:'请重新登录',
						message:'登录已失效，请重新登录'
					}).then(()=>{
						setTimeout(() => {
							window.location.href = '/'
						}, 800);
					}).catch(()=>{
						Toast({
							type:'fail',
							message:'取消登录'
						})
					})
				}
				if(e.data.status == 200){
					Toast({
						type:'success',
						message:'上传成功'
					})
				}
			})
		},
		addStep(){
			this.stepShow = !this.stepShow
		},
		closeButton(){
			this.addStep()
		},
		confirmSure(){
			this.addStep()
			this.fileList.push({
				sequence:this.sequence,
				title:this.title
			})
		},
		spliceFunction(s,k){
			this.$dialog.confirm({
				title:'是否删除',
				content:'您是否删除改步骤'
			}).then(()=>{
				this.fileList.splice(k,1)
				Toast({
					type:'success',
					message:'删除成功'
				})
			}).catch(()=>{
				Toast({
					type:'fail',
					message:'已取消'
				})
			})
		},
		submitFromEvent(){
			const parms = {
				starttime:this.startTimes,
				endtime:this.endTimes,
				ids:this.$route.query.parms.id,
				step:this.fileList
			}
			API.process(parms).then(async e=>{
				this.$toast({
					type:e.code == 0 ? 'fail' : 'success',
					message:e.msg
				})
				if(e.code == 1){
					console.log(e)
					setTimeout(()=>{
						this.$router.back(-1)
					},1000)
				}
			})
		}
    }
}

</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter{
    display: flex;
    align-items: center;
}
  
// 计算函数
@function calcFunction($number,$default:16){
  @return calc($number * 2 / 100) + rem;
}

.task_disassembly{
    .task_disassembly_content{
        margin: 0 calcFunction(10);
        .task_button_classfiy{
            .task_button_classfiy_click{
                @extend .alignItemsCenter;
                justify-content: space-between;
                button{
                    width: 48%;
                    padding: calcFunction(10) 0;
                    font-size: calcFunction(15);
                    border: none;
                    border-radius: calcFunction(4);
                }
            }
        }
        .task_content_select_time{
			margin-top: calcFunction(30);
			padding-bottom: calcFunction(20);
			border-bottom:1px solid #DCDCDC;
            .task_select_time{
				@extend .alignItemsCenter;
				width: 100%;
                .task_time_title{
                    span{
                        font-size: calcFunction(15);
						color: #111111;
						font-weight: bold;
                    }
                }
                .task_select_times{
					width: 80%;
					display: flex;
					justify-content: center;
                    .times_selct{
						@extend .alignItemsCenter;
                        span{
							font-size: calcFunction(14);
							color: #999999;
                        }
                        span:nth-child(2){
                            margin: 0 calcFunction(14);
							color: #111111;
							font-size: calcFunction(20);
                        }
                    }
                }
            }
        }
		.trin_content{
			margin-top: calcFunction(27);
			.trin_content_data{
				.trin_content_header{
					.trin_content_left_title{
						@extend .alignItemsCenter;
						justify-content: space-between;
						span{
							font-size: calcFunction(15);
							font-weight: bold;
						}
						img{
							width: calcFunction(15);
							height: calcFunction(15);
						}
					}
				}
			}
		}
    }
}

.task_bottom_button{
	background: white;
	position: fixed;
	bottom: 0;
	width: 100%;
	.task_sure_bottom_button{
		padding: calcFunction(14) calcFunction(10);
		button{
			width: 100%;
			color: white;
			background: #FFB5B6;
			font-size: calcFunction(15);
			border: none;
			padding: calcFunction(10) 0;
			border-radius: calcFunction(8);
		}
	}
}

.trin_content_header{
	position: relative;
}
.trin_button_uploding{
	position: absolute;
	right: 0;
	top: 0;
	opacity: 0;
	width: calcFunction(20);
	height: calcFunction(20);
}

.uploading{
	.uploadings{
		padding: calcFunction(10);
		background: #F1F0F5;
		@extend .alignItemsCenter;
		margin-top: calcFunction(10);
		justify-content: space-between;
		border-radius: calcFunction(4);
		span{
			font-size: calcFunction(14);
		}
		img{
			width: calcFunction(15);
			height: calcFunction(15);
		}
	}
}

.window_step{
	width: calcFunction(300);
	.step_input{
		padding: calcFunction(20) calcFunction(30);
		.step_input_content{
			.step_input_title{
				@extend .alignItemsCenter;
				justify-content: center;
				span{
					font-size: calcFunction(17);
					font-weight: bold;
				}
			}
			.setp_input_sequence{
				width: 100%;
				margin: calcFunction(15) 0;
				margin-top: calcFunction(30);
				input{
					padding: calcFunction(10) calcFunction(8);
					border: 1px solid #eee;
					font-size: calcFunction(14);
					width: 93%;
					border-radius: calcFunction(4);
				}
			}
			.setp_input_title{
				width: 100%;
				margin: calcFunction(15) 0;
				input{
					padding: calcFunction(10) calcFunction(8);
					border: 1px solid #eee;
					width: 93%;
					font-size: calcFunction(14);
					border-radius: calcFunction(4);
				}
			}
		}
		.step_inpit_button{
			@extend .alignItemsCenter;
			justify-content: space-between;
			button{
				width: 48%;
				background: #D71518;
				color: white;
				padding: calcFunction(9) 0;
				border: none;
				border-radius: calcFunction(4);
				font-size: calcFunction(14);
				border: 1px solid #D71518;
			}
			button:nth-child(1){
				background: transparent;
				border: 1px solid #D71518;
				color: #D71518;
			}
		}
	}
}



.active{
    background: #FFEAEA!important;
    border: 1px solid #D71518!important;
    color: #D71518!important;
}
</style>
<style lang="scss">
	.trin_button_uploding{
		.van-uploader{
			width: 100%;
			height:100%;
			.van-button--primary{
				padding: 0;
				.van-button__text{
					display:none;
				}
			}
		}
		.van-uploader__file{
			display: none;
		}
	}
</style>