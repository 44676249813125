<template>
  <div class="user_leaderboard">
    <div class="user_leaderboard_content">
      <div class="user_leaderboard_content_header">
        <div class="user_leaderboard_header">
          <publicNavigation :data="navigation" @sureEvenet="sureEvenet" />
        </div>
        <div class="user_leaderboard_background_image">
          <div class="user_leaderboard_image">
            <img style="width: 100%" src="@/assets/personranking.png" alt="" />
          </div>
        </div>
        <div class="statistics">
          <div class="monthly_statistics">
            <div class="monthly_statistics_content">
              <div class="content">
                <img src="@/assets/noticeranking.png" alt="" />
              </div>
              <div class="text_statistics">
                <span>{{ dateTime }}积分排行榜</span>
                <span>积分每月累计计算·每天10点更新排行榜每月最后一天更新</span>
              </div>
            </div>
          </div>
        </div>
        <div class="ranking-box">
          <div class="ranking">
            <van-row gutter="10">
              <van-col
                v-if="allData && allData.first && allData.first[1]"
                span="8"
              >
                <div class="default-box flex-c-c gray-box mt20">
                  <!-- 数字logo -->
                  <img class="num-log" src="@/assets/two.png" alt="" />
                  <!-- 头像 -->
                  <img
                    class="top-avatar"
                    :src="allData.first[1] && allData.first[1].avatar"
                    alt=""
                  />
                  <!-- 昵称&等级 -->
                  <div class="flex-c mt40">
                    <div class="nickname mr5 textOver1">
                      {{ allData.first[1] && allData.first[1].nickname }}
                    </div>
                    <div class="level">
                      Lv.{{ allData.first[1] && allData.first[1].level }}
                    </div>
                  </div>
                  <!-- 岗位 -->
                  <div class="nickname mt5">
                    {{ allData.first[1] && allData.first[1].section }}
                  </div>
                  <!-- 积分 -->
                  <div class="score mt5">
                    当前积分：{{ allData.first[1] && allData.first[1].score }}
                  </div>
                </div>
              </van-col>
              <van-col
                v-if="allData && allData.first && allData.first[0]"
                span="8"
              >
                <div class="default-box flex-c-c yellow">
                  <!-- 数字logo -->
                  <img class="num-log" src="@/assets/one.png" alt="" />
                  <!-- 头像 -->
                  <img
                    class="top-avatar"
                    :src="allData.first[0] && allData.first[0].avatar"
                    alt=""
                  />
                  <!-- 昵称&等级 -->
                  <div class="flex-c mt40">
                    <div class="nickname mr5 textOver1">
                      {{ allData.first[0] && allData.first[0].nickname }}
                    </div>
                    <div class="level">
                      Lv.{{ allData.first[0] && allData.first[0].level }}
                    </div>
                  </div>
                  <!-- 岗位 -->
                  <div class="nickname mt5">
                    {{ allData.first[0] && allData.first[0].section }}
                  </div>
                  <!-- 积分 -->
                  <div class="score mt5">
                    当前积分：{{ allData.first[0] && allData.first[0].score }}
                  </div>
                </div>
              </van-col>
              <van-col
                v-if="allData && allData.first && allData.first[2]"
                span="8"
              >
                <div class="default-box flex-c-c red mt25">
                  <!-- 数字logo -->
                  <img class="num-log" src="@/assets/three.png" alt="" />
                  <!-- 头像 -->
                  <img
                    class="top-avatar"
                    :src="allData.first[2] && allData.first[2].avatar"
                    alt=""
                  />
                  <!-- 昵称&等级 -->
                  <div class="flex-c mt40">
                    <div class="nickname mr5 textOver1">
                      {{ allData.first[2] && allData.first[2].nickname }}
                    </div>
                    <div class="level">
                      Lv.{{ allData.first[2] && allData.first[2].level }}
                    </div>
                  </div>
                  <!-- 岗位 -->
                  <div class="nickname mt5">
                    {{ allData.first[2] && allData.first[2].section }}
                  </div>
                  <!-- 积分 -->
                  <div class="score mt5">
                    当前积分：{{ allData.first[2] && allData.first[2].score }}
                  </div>
                </div>
              </van-col>
            </van-row>
          </div>
          <div class="ranking-list-box">
            <van-list
              v-if="list.length > 0"
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
            >
              <div
                v-for="item of list"
                :key="item.id"
                class="ranking-list flex-c-b"
              >
                <div class="flex-c">
                  <div class="serial-number">
                    {{ item.ranking}}
                  </div>
                  <img class="list-avatar mr10" :src="item.avatar" />
                  <div>
                    <div class="flex-c">
                      <!-- 昵称 -->
                      <div class="nickname">{{ item.nickname }}</div>
                      <!-- 岗位 -->
                      <div class="sect">{{ item.section }}</div>
                    </div>
                    <div class="score">当前积分：{{ item.score }}</div>
                  </div>
                </div>
                <div class="level mr20">Lv.{{ item.level }}</div>
              </div>
            </van-list>
            <!-- 空列表 -->
            <van-empty v-else description="暂无数据" />
          </div>
        </div>
      </div>
      <van-popup
        v-model="timeSelectShow"
        position="bottom"
        style="
          height: 40%;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        "
      >
        <van-picker
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="onCancel"
        />
      </van-popup>
    </div>
  </div>
</template>
<script>
import publicNavigation from "@/publicNavigation/publicNavigation.vue";
import gradeCard from "@/leaderboard/components/gradeCard/gradeCard.vue";
import * as API from "@/api.ts";
export default {
  components: {
    publicNavigation,
    gradeCard,
  },
  data() {
    return {
      navigation: {},
      rankingList: [],
      threeList: [],
      mypersoninfomation: {},
      dateTime: new Date().getMonth() + 1 + "月",
      timeSelectShow: false,
      columns: ["全部"],
      customColumnsData: [],
      list: [], //审核任务列表
      ranTop: [], //前三名
      page: 1,
      limit: 10,
      loading: false, //审核任务列表接口是否正在请求
      finished: false, //审核任务是否全部加载完
      allData: {}, //页面所有数据
      timestamp: "",
      page: 1,
      month: "", //日期
      type: 1, //1当月，2选中月
      onConfirmText: "",
      userinfo: "",
      identity: "", //身份
    };
  },
  mounted() {
    //获取用户信息
    this.getUserInfo();
    this.navigation = {
      image: require("../assets/return.png"),
      name: "排行榜",
      sure: "历史排名",
      search: require("../assets/leaderboard.png"),
      close: "",
    };
    this.Reuqset();
    this.userinfo = window.sessionStorage.getItem("userInfo")
      ? JSON.parse(window.sessionStorage.getItem("userInfo")).identity
      : "";
  },
  methods: {
    //获取用户信息
    async getUserInfo() {
      let { code, data } = await API.userInfo();
      if (code == 1) {
        this.identity = data.identity;
        this.rankingListData();
      }
    },
    Reuqset() {
      API.historyRankingList().then(async (e) => {
        if (e.code == 1) {
          this.customColumnsData = e.data.rows;
          e.data.rows.map((items) => {
            this.columns.push(items.month);
          });
        }
      });
    },
    // 上拉加载更多
    onLoad() {
      if (!this.finished) {
        this.page++;
        this.rankingListData();
      }
    },
    async rankingListData() {
      let { page, limit, list, month, type } = this;
      let params = {
        page,
        limit,
        month,
        type,
      };
      let { code, data } = await API.ranking(params);
      if (code == 1) {
        this.allData = data;
        let _list = data.rows;
        if (page == 1) {
          list = _list;
          list.splice(0, 3);
        } else {
          list.push(..._list);
        }
        if (_list.length == 0) {
          this.finished = true;
        }
        this.list = list;
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    sureEvenet() {
      this.timeSelectShow = !this.timeSelectShow;
    },
    onConfirm(value) {
      this.threeList = [];
      this.rankingList = [];
      this.finished = false;
      this.page = 1;
      if (value == "全部") {
        this.month = "";
        this.type = 1;
      } else {
        this.month = value;
        this.type = 2;
      }
      this.rankingListData();
      this.sureEvenet();
    },
    onCancel() {
      this.threeList = [];
      this.rankingList = [];
      this.page = 1;
      this.navigation.sure = "历史排名";
      this.sureEvenet();
      this.rankingListData();
    },
    descriptionClick(s) {
      console.log(s);
      window.localStorage.setItem("mydesc", JSON.stringify(s));
      this.$router.push({
        name: "mydesc",
        path: "/mydesc",
        query: {
          description: s,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.user_leaderboard {
  position: relative;

  .user_leaderboard_content {
    .user_leaderboard_content_header {
      .user_leaderboard_header {
        position: relative;
        z-index: 20;
      }

      .user_leaderboard_background_image {
        .user_leaderboard_image {
          img {
            width: 105vw !important;
            position: absolute;
            left: -5%;
            top: 0;
            z-index: 0;
          }
        }
      }

      .statistics {
        padding: 0.34rem 0.2rem;
        box-sizing: border-box;
        position: relative;

        .monthly_statistics {
          .monthly_statistics_content {
            @extend .alignItemsCenter;

            .content {
              img {
                width: 1.5rem;
                height: 1.71rem;
              }
            }

            .text_statistics {
              @extend .alignItemsCenter;
              flex-direction: column;
              align-items: flex-start;
              margin-left: 0.2rem;

              span {
                color: #111111;
                font-size: 0.4rem;
                font-weight: bold;
                margin-bottom: 0.2rem;
              }

              span:nth-last-child(1) {
                font-weight: 400;
                width: 70%;
                font-size: 0.24rem;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}

.gradeCard {
  margin-top: calcFunction(10);
}

// 按排行
.ranking-box {
  width: 100%;
  height: calc(100vh - 1rem);
  background: #fff;
  .ranking {
    padding: 0.2rem;
    box-sizing: border-box;
    padding-top: 1rem;
    .default-box {
      border-radius: 0.1rem;
      width: 100%;
      padding: 0.2rem;
      box-sizing: border-box;
      position: relative;
      flex-direction: column;
      .num-log {
        height: 0.59rem;
        position: absolute;
        right: 0.2rem;
        top: 0.2rem;
      }
      .top-avatar {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        position: absolute;
        top: -25%;
        left: 50%;
        transform: translate(-50%);
      }
    }
  }
  .ranking-list-box {
    width: 100%;
    background: #ffffff;
    border: 0.01rem solid #dcdcdc;
    .ranking-list {
      border-bottom: 0.01rem solid #dcdcdc;
      width: 100%;
      height: 1.4rem;
      //   序号
      .serial-number {
        margin-left: 0.3rem;
        font-size: 0.28rem;
        font-weight: bold;
        color: #666666;
      }
      //   头像
      .list-avatar {
        width: 0.67rem;
        height: 0.67rem;
        border-radius: 50%;
        margin-left: 0.4rem;
      }
      .sect {
        font-size: 0.24rem;
        font-weight: 500;
        color: #111111;
        margin-left: 0.2rem;
      }
    }
  }
}
.default {
  width: 1rem;
  height: 0.48rem;
  background: #ffffff;
  border-radius: 0 0.08rem 0.08rem 0;
  font-size: 0.24rem;
  font-weight: 500;
  color: #111111;
}
.active {
  width: 1rem;
  height: 0.48rem;
  background: #d71518;
  border-radius: 0 0.08rem 0.08rem 0;
  font-size: 0.24rem;
  font-weight: 500;
  color: #ffffff;
}
.label {
  font-size: 0.24rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
  margin-right: 0.2rem;
}
.value {
  font-size: 0.24rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #111;
}
.gray-box {
  background: linear-gradient(
    0deg,
    rgba(246, 247, 251, 1) 0%,
    rgba(235, 238, 246, 1) 100%
  );
}
.yellow {
  background: linear-gradient(
    0deg,
    rgba(255, 254, 251, 1) 0%,
    rgba(255, 251, 249, 1) 100%
  );
}
.red {
  background: linear-gradient(
    0deg,
    rgba(255, 250, 247, 1) 0%,
    rgba(255, 244, 239, 1) 100%
  );
}
.nickname {
  font-size: 0.28rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #111111;
}
.score {
  font-size: 0.22rem;
  font-weight: 500;
  color: #666666;
}
.level {
  background: #ffe7e7;
  border-radius: 0.13rem;
  font-size: 0.22rem;
  font-weight: 500;
  color: #d71518;
  padding: 0.05rem 0.1rem;
  box-sizing: border-box;
  white-space: nowrap;
}
</style>
