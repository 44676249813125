<template>
  <div class="solve_bug_description">
    <div class="solve_bug_description_content">
      <div class="solve_bug_description_content_header">
        <div class="solve_bug_content_header">
          <publicNavigation :data="navigation" />
        </div>
        <div class="background_color">
          <div class="background_colors"></div>
        </div>
        <div class="background_content" :style="{ top: topHeight + 'px' }">
          <div class="background_content_box">
            <div class="background_card_description">
              <div class="bug_description">
                <div class="bug_description_title">
                  <span>bug 名称：</span>
                  <span>{{ description.title }}</span>
                </div>
                <div class="bug_description_list">
                  <ul>
                    <li>
                      <span>bug 类型：</span>
                      <span>{{ description.type }}</span>
                    </li>
                    <li>
                      <span>状 态：</span>
                      <span>{{ statusFcuntion(description.status) }}</span>
                    </li>
                    <li class="flex-c">
                      <span>bug 难度：</span>
                      <span class="difficulty flex-c-c">{{
                        description.level_text
                      }}</span>
                    </li>
                    <li>
                      <span>发起人：</span>
                      <span>{{ description.user_name }}</span>
                    </li>
                    <li>
                      <span>解决人：</span>
                      <span>{{ description.solve_name }}</span>
                    </li>
                    <li>
                      <span>解决方案：</span>
                      <span>{{ description.solution || "无" }}</span>
                    </li>
                    <li>
                      <span>创建日期：</span>
                      <span>{{ description.createtime_text }}</span>
                    </li>
                    <li>
                      <span>修改日期：</span>
                      <span>{{ description.updatetime_text }}</span>
                    </li>
                    <li>
                      <span style="color: #666666; font-size: 0.28rem"
                        >附件：</span>
                      <span v-if="description.annex_text.length==0">无</span>

                    </li>
                    <template v-if="description.annex_text.length>0">
                      <li
                        style="width: 100%"
                        class="fieldListSty textOver1"
                        v-for="(item, index) of description.annex_text"
                        :key="index"
                      >
                        <a style="color: #d71518" :href="item.url">
                          {{ item.name }}
                        </a>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="bug_description_beskrive">
            <div class="bug_description_beskrive_content">
              <div class="bug_beskrive_content">
                <div class="bug_beskrive_swipers">
                  <div class="bug_beskrive_header">
                    <div class="bug_beskrive_title">
                      <div class="bug_beskrive_title_border">
                        <img src="@/assets/spring.png" alt="" />
                        <span>bug描述</span>
                      </div>
                    </div>
                    <div class="bug_beskrive_swiper">
                      <div class="bug_beskrive_swipers_lie">
                        <div
                          v-html="description.content"
                          class="swipers_bug"
                        ></div>
                      </div>
                    </div>
                    <div class="problemet">
                      <div class="problemet_header">
                        <div class="problemet_header_content">
                          <span>解决记录</span>
                        </div>
                        <div class="problemet_list">
                          <div class="problement_lists">
                            <ul>
                              <li
                                v-for="(item, index) in solution"
                                :key="index"
                              >
                                <div>
                                  {{ ++index }}、{{ item.createtime_text }}，由
                                  <span style="font-weight: bold">{{
                                    item.nickname
                                  }}</span>
                                  {{ item.type_text }}了bug。
                                </div>
                                <div
                                  v-html="item.content"
                                  class="content-bg mt20"
                                  v-if="item.content"
                                ></div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="specificere_lose">
            <div class="specificere_lose_content">
                <div class="specificere_lose_fixed">
                    <button @click="tildeleEvent">
                        <img src="" alt="" />
                        <span>指派</span>
                    </button>
                    <button @click="solveBugInput" v-if="bugs">
                        <img src="" alt="" />
                        <span>解决</span>
                    </button>
                    <button @click="offsetBugClick" v-if="!bugs">
                        <img src="" alt="" />
                        <span>关闭</span>
                    </button>
                    <button @click="deleteBugClick" v-if="!bugs">
                        <img src="" alt="" />
                        <span>删除</span>
                    </button>
                </div>
            </div>
        </div> -->
    <van-popup v-model="popupShow" round>
      <div class="vant_popup_model">
        <div class="popup_model">
          <div class="popup_model_title">
            <div class="title">
              <span>关闭</span>
            </div>
          </div>
          <div class="popup_textarea">
            <textarea
              name=""
              id=""
              v-model="textarea"
              cols="30"
              rows="10"
            ></textarea>
            <span
              ><i>{{ textNumber }}</i
              >/100</span
            >
          </div>
          <div class="button_two">
            <button>取消</button>
            <button @click="sureOffsetClick">确定</button>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import publicNavigation from "@/publicNavigation/publicNavigation.vue";
import * as API from "@/api.ts";
export default {
  components: {
    publicNavigation,
  },
  data() {
    return {
      navigation: {},
      topHeight: 0,
      interHeight: 0,
      description: {
        annex_text:[]
      },
      solution: [],
      popupShow: false,
      textNumber: "0",
      textarea: "",
      bugs: "",
    };
  },
  mounted() {
    const height = window.innerHeight;
    const width = window.innerWidth;
    this.topHeight = (height / width) * 10 * 2 + 40;
    this.interHeight = height;
    this.navigation = {
      image: require("../../assets/return.png"),
      name: "bug内容详情",
      close: "",
    };
    this.Requset();
    this.bugs = this.$route.query.bugs;
  },
  methods: {
    statusFcuntion(s) {
      let name;
      switch (s) {
        case 1:
          name = "未解决";
          break;
        case 2:
          name = "已解决";
          break;
        case 3:
          name = "激活";
          break;
        case 4:
          name = "已关闭";
          break;
      }
      return name;
    },
    Requset() {
      const parms = {
        ids: this.$route.query.id,
      };
      API.taskbugDesc(parms).then(async (e) => {
        if (e.data) {
          this.description = e.data;
          this.description.content = this.description.content.replace(
            "<img",
            '<img style="width:100%"'
          );
        } else {
          // this.$toast.fail('暂无数据')
        }
      });
      const parm = {
        id: this.$route.query.id,
      };
      API.solution(parm).then(async (e) => {
        if (e.code == 1) {
          this.solution = e.data.rows;
          this.solution.forEach((item) => {
            item.content =
              item.content &&
              item.content.replace("<img", '<img style="width:100%"');
          });
        }
      });
    },
    solveBugInput() {
      this.$router.push({
        name: "solveBugInput",
        path: "/solveBugInput",
        query: {
          id: this.$route.query.id,
        },
      });
    },
    tildeleEvent() {
      this.$router.push({
        name: "solveBugInput",
        path: "/solveBugInput",
        query: {
          tildele: "1",
          id: this.$route.query.id,
        },
      });
    },
    offsetBugClick() {
      this.popupShow = !this.popupShow;
    },
    deleteBugClick() {},
    sureOffsetClick() {
      const parms = {
        id: this.$route.query.id,
        textarea: this.textarea,
      };
      API.close(parms).then(async (e) => {
        if (e.code == 1) {
          this.offsetBugClick();
          this.$toast({
            type: e.code == 1 ? "success" : "fail",
            message: e.msg,
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.difficulty {
  border: 1px solid #d71518;
  border-radius: 50%;
  width: 0.3rem;
  height: 0.3rem;
  color: #d71518 !important;
  font-size: 0.2rem !important;
}

.solve_bug_description {
  .solve_bug_description_content {
    .solve_bug_description_content_header {
      .solve_bug_content_header {
        position: absolute;
        width: 100%;
        z-index: 2;
      }
      .background_content {
        position: absolute;
        z-index: 1;
        height: 100%;
        // padding-bottom: 16vh;
        background: #f1f0f5;
        .background_content_box {
          .background_card_description {
            background: white;
            padding: calcFunction(20);
            border-top-left-radius: calcFunction(16);
            border-top-right-radius: calcFunction(16);
            .bug_description {
              .bug_description_title {
                @extend .alignItemsCenter;
                align-items: flex-start;
                span {
                  font-size: calcFunction(14);
                }
                span:nth-last-child(1) {
                  flex: 1;
                  font-size: calcFunction(13);
                }
                span:nth-child(1) {
                  color: #666666;
                }
              }
              .bug_description_list {
                margin-top: calcFunction(10);
                ul {
                  @extend .alignItemsCenter;
                  flex-wrap: wrap;
                  li {
                    width: 50%;
                    margin-bottom: calcFunction(10);
                    span {
                      color: #666666;
                      font-size: calcFunction(14);
                    }
                    span:nth-last-child(1) {
                      color: #111111;
                    }
                  }
                  li:nth-child(5) {
                    letter-spacing: calcFunction(4.5);
                  }
                  li:nth-child(2) {
                    letter-spacing: calcFunction(7.5);
                  }
                  li:nth-child(4) {
                    letter-spacing: calcFunction(4.5);
                  }
                  li:nth-last-child(1),
                  li:nth-last-child(2) {
                    // margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
        .bug_description_beskrive {
          margin: calcFunction(10);
          .bug_description_beskrive_content {
            width: 100%;
            background: white;
            .bug_beskrive_content {
              .bug_beskrive_swipers {
                width: 100%;
                .bug_beskrive_header {
                  .bug_beskrive_title {
                    padding: calcFunction(14) calcFunction(15);
                    padding-bottom: 0;
                    .bug_beskrive_title_border {
                      border-bottom: 1px solid #eeeeee;
                      padding-bottom: calcFunction(14);
                      @extend .alignItemsCenter;
                      img {
                        width: calcFunction(20);
                        height: calcFunction(20);
                      }
                      span {
                        font-size: calcFunction(14);
                        color: #111111;
                        margin-left: calcFunction(10);
                      }
                    }
                  }
                  .bug_beskrive_swiper {
                    padding: calcFunction(14) calcFunction(15);
                    margin-bottom: calcFunction(8);
                    .bug_beskrive_swipers_lie {
                      width: 100%;
                      .swipers_bug {
                        width: 100%;
                        .my-swipe {
                          width: 86vw;
                          overflow: hidden;
                        }
                      }
                    }
                  }
                }
              }
              .problemet {
                .problemet_header {
                  .problemet_header_content {
                    background: #f5f5f5;
                    border: 1px solid #dddddd;
                    padding: calcFunction(14) calcFunction(15);
                    span {
                      color: #111111;
                      font-size: calcFunction(14);
                    }
                  }
                  .problemet_list {
                    margin-bottom: calcFunction(80);
                    .problement_lists {
                      ul {
                        padding: calcFunction(20) calcFunction(15);
                        li {
                          margin-bottom: calcFunction(14);
                          span {
                            font-size: calcFunction(13);
                            i {
                              font-style: normal;
                              font-weight: bold;
                            }
                          }
                          .textarea {
                            margin: calcFunction(10) 0;
                            background: #f9f9f9;
                            margin-left: calcFunction(17.5);
                            padding: calcFunction(10) calcFunction(14);
                            span {
                            }
                          }
                        }
                        li:nth-last-child(1) {
                          margin-bottom: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.background_color {
  .background_colors {
    height: calcFunction(120);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    background: linear-gradient(0deg, #feefef 0%, #ffffff 100%);
  }
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  height: calcFunction(200);
  line-height: calcFunction(200);
  text-align: center;
  background-color: #39a9ed;
  img {
    width: 100%;
  }
}

.specificere_lose {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
  .specificere_lose_content {
    background: white;
    padding: calcFunction(10) 0;
    .specificere_lose_fixed {
      width: 100%;
      @extend .alignItemsCenter;
      justify-content: center;
      button {
        width: 45%;
        background: #d71518;
        padding: calcFunction(12) 0;
        border: none;
        border-radius: calcFunction(8);
        img {
        }
        span {
          color: white;
          font-size: calcFunction(14);
        }
      }
      button:nth-last-child(1) {
        margin-left: calcFunction(10);
      }
    }
  }
}

.vant_popup_model {
  width: calcFunction(300);
  .popup_model {
    .popup_model_title {
      .title {
        padding-top: calcFunction(15);
        padding-bottom: calcFunction(20);
        @extend .alignItemsCenter;
        justify-content: center;
        span {
          font-size: calcFunction(17);
          font-weight: bold;
        }
      }
    }
    .popup_textarea {
      margin: 0 calcFunction(15);
      position: relative;
      padding-bottom: calcFunction(18);
      textarea {
        width: 96%;
        border: 1px solid #dcdcdc;
        padding-top: calcFunction(10);
        padding-left: calcFunction(10);
      }
      span {
        position: absolute;
        bottom: calcFunction(35);
        right: calcFunction(8);
        font-size: calcFunction(14);
        color: #999999;
        i {
          color: #d71518;
          font-style: normal;
        }
      }
    }
    .button_two {
      @extend .alignItemsCenter;
      justify-content: center;
      padding-bottom: calcFunction(20);
      button {
        width: 30%;
        color: #666666;
        border: 1px solid #999999;
        font-size: calcFunction(14);
        padding: calcFunction(8) 0;
        @extend .alignItemsCenter;
        justify-content: center;
      }
      button:nth-last-child(1) {
        background: #d71518;
        border: none;
        color: white;
        margin-left: calcFunction(20);
      }
    }
  }
}
// 回复内容背景
.content-bg {
  background: #f1f1f1;
  padding: 0.2rem;
  box-sizing: border-box;
  border-radius: 0.05rem;
}
// 附件列表样式
.fieldListSty {
  background: #ffeaea !important;
  border-radius: 0.08rem !important;
  font-size: 0.28rem !important;
  font-family: PingFang SC !important;
  font-weight: 500 !important;
  color: #d71518 !important;
  padding: 0.2rem 0.2rem !important;
  box-sizing: border-box !important;
}
</style>