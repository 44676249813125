<template>
  <div class="messgae_li">
    <div class="message_list">
      <div
        class="message_list_data"
        @click="messagedataClick(s, k)"
        v-for="(s, k) in messgaedata"
        :key="k"
      >
        <div class="messgae_left">
          <div class="message_left_box">
            <div class="message_imaeg">
              <!--已读-->
              <div v-if="s.is_see == 1">
                <img src="@/assets/garymessage.png" alt="" />
              </div>
              <!--未读-->
              <div class="redradio" v-else>
                <img src="@/assets/reedmessage.png" alt="" />
              </div>
            </div>
            <div class="message_content_box">
              <span :style="s.status == 1 ? 'color:#999999;' : ''">{{
                s.name
              }}</span>
              <span>{{ s.text }}</span>
            </div>
          </div>
          <div class="message_right">
            <img src="@/assets/readright.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <van-dialog v-model="noticeShow" :title="noticeTitle">
      <div class="content">
        {{ noticeContent }}
      </div>
    </van-dialog>
  </div>
</template>
<script>
import * as API from "@/api.ts";
export default {
  props: {
    messgaedata: Array,
  },
  data() {
    return {
      noticeShow: false,
      noticeTitle: "",
      noticeContent: "",
    };
  },
  methods: {
    messagedataClick(item, index) {
      this.noticeShow = true;
      this.noticeTitle = item.title;
      this.noticeContent = item.content;
      this.messgaedata[index]["is_see"] = 1;
      this.getMessageDetails(item.id);
    },
    getMessageDetails(id) {
      var param = {
        ids: id,
      };
      API.bulletinDetails(param).then(async (e) => {
        // console.log("通知公告详情", e);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}
.content {
  text-align: left;
  padding: 0 25px;
  font-size: 0.28rem;
  color: #646566;
  word-wrap: break-word;
  white-space: pre-wrap;
  max-height: 60vh;
  overflow-y: auto;
}

.messgae_li {
  .message_list {
    margin: calcFunction(10) calcFunction(15);
    .message_list_data {
      background: white;
      padding: calcFunction(15);
      border-radius: calcFunction(8);
      margin-bottom: calcFunction(10);
      .messgae_left {
        @extend .alignItemsCenter;
        justify-content: space-between;
        .message_left_box {
          @extend .alignItemsCenter;
          .message_imaeg {
            img {
              width: calcFunction(30);
              height: calcFunction(30);
            }
          }
          .message_content_box {
            @extend .alignItemsCenter;
            flex-direction: column;
            align-items: flex-start;
            margin-left: calcFunction(10);
            span {
              font-size: calcFunction(14);
              color: #111111;
            }
            span:nth-last-child(1) {
              color: #999999;
              margin-top: calcFunction(4);
            }
          }
        }
        .message_right {
          @extend .alignItemsCenter;
          img {
            width: calcFunction(10);
            height: calcFunction(15);
          }
        }
      }
    }
  }
}

.redradio {
  position: relative;
  &::after {
    content: "";
    width: calcFunction(10);
    height: calcFunction(10);
    background: #d71518;
    position: absolute;
    right: 0;
    top: calcFunction(-3);
    z-index: 1;
    border-radius: 50%;
  }
}
</style>
