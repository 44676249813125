<template>
    <div class="task_specify">
        <div class="task_specify_content">
            <div class="task_specify_header_content">
                <div class="task_specify_header">
                    <publicNavigation  :data="navigation" />
                </div>
                <div class="task_specify_button">
                    <div class="specify_button_classfiy">
                        <div class="button_for" @click="classEventClick(s,k)" :class="currentClick == k ? 'specifyActive' : ''" v-for="s,k in buttonData" :key="k">
                            <button>{{ s }}</button>
                        </div>
                    </div>
                </div>
                <div class="task_content_current">
                    <div class="task_content_current_list">
                        <div class="current_one_content" @click="specClick" v-show="currentClick == 0">
                            <div class="current_one_title">
                                <span>执行人</span>
                                <input type="text" v-model="bobestyrer" disabled placeholder="选择执行人" />
                            </div>
                            <div class="current_one_arra">
                                <img src="@/assets/arrowgray.png" alt="">
                            </div>
                        </div>
                        <div class="current_two_content" v-show="currentClick == 1">
                            <div class="current_two_content_header">
                                <div class="current_two_header">
                                    <div class="current_header">
                                        <span>拆分指派</span>
                                        <img src="" alt="">
                                    </div>
                                    <div class="current_content_model">
                                        <div class="current_model">
                                            <div class="model">
                                                <span>任务-</span>
                                                <input type="text" placeholder="请输入任务标题" />
                                            </div>
                                            <div class="model_tow" @click="specClick(1)">
                                                <div class="model_two_content">
                                                    <span>执行人</span>
                                                    <span>{{ rive ? rive : '请选择执行人' }}</span>
                                                </div>
                                                <div class="current_model_img">
                                                    <img src="@/assets/arrowgray.png" alt="">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fixed_button">
            <div class="fixed_button_content">
                <button @click="submitButton">确定</button>
            </div>
        </div>
    </div>
</template>
<script>
import publicNavigation from '@/publicNavigation/publicNavigation.vue';
import * as API from '@/api.ts'
export default {
    components:{
        publicNavigation
    },
    data(){
        return{
            navigation:{},
            buttonData:['直接指派','拆分指派'],
            currentClick:0,
            bobestyrer:'',
            specType:0,
            rive:'',
            taskID:''
        }
    },
    mounted(){
        // const parms = new taskPool().init()
        // this.perfromData = parms.taskPoolData
        this.navigation = {
            image:require('../../assets/return.png'),
            name:'指派任务'
        }
        const parms = window.localStorage.getItem('specifty')
        const nickname = JSON.parse(parms).publicStolpe ? JSON.parse(parms).publicStolpe.title : JSON.parse(parms).personName.name
        this.taskID = JSON.parse(parms).publicStolpe ? JSON.parse(parms).publicStolpe.id : JSON.parse(parms).personName.id
        if(JSON.parse(parms).specType == 1){
            this.rive = nickname
            this.currentClick = JSON.parse(parms).specType
        }else{
            this.bobestyrer = nickname
        }
    },
    methods:{
        classEventClick(s,k){
            this.currentClick = k
        },
        specClick(e){
            if(e == 1){
                this.specType = 1
            }
            this.$router.push({
                name:'overdragetPerson',
                path:'/overdragetPerson',
                query:{
                    specify:'1',
                    specType:this.specType
                }
            })
        },
        submitButton(){
            const parms = {
                ids:this.taskID,
                type:this.currentClick == 0 ? 1 : this.currentClick,
                user_id:JSON.parse(window.sessionStorage.getItem('userInfo')).id
            }
            API.add_subtask(parms).then(async e=>{
                console.log(e)
                if(e.code == 1){
                    this.$notify({
                        type:'success',
                        message:'提交成功'
                    })
                    setTimeout(() => {
                        this.$router.back(-1)
                    }, 1000);
                }
            })
        }
    }
}

</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter{
    display: flex;
    align-items: center;
}
  
// 计算函数
@function calcFunction($number,$default:16){
  @return calc($number * 2 / 100) + rem;
}

.task_specify{
    .task_specify_content{
        .task_specify_header_content{
            .task_specify_header{

            }
            .task_specify_button{
                margin: 0 calcFunction(10);
                .specify_button_classfiy{
                    @extend .alignItemsCenter;
                    width: 100%;
                    justify-content: space-between;
                    .button_for{
                        width: 48%;
                        button{
                            width: 100%;
                            padding: calcFunction(10) 0;
                            background: #F1F0F5;
                            outline: none;
                            border: none;
                            font-size: calcFunction(15);
                            border-radius: calcFunction(4);
                        }
                    }
                }
            }
            .task_content_current{
                margin: 0 calcFunction(10);
                margin-top: calcFunction(30);
                .task_content_current_list{
                    .current_one_content{
                        @extend .alignItemsCenter;
                        justify-content: space-between;
                        padding-bottom: calcFunction(10);
                        border-bottom: 1px solid #DCDCDC;
                        .current_one_title{
                            span{
                                color: #333333;
                                font-size: calcFunction(14);
                                font-weight: bold;
                            }
                            input{
                                margin-left: calcFunction(30);
                                border: none;
                                outline: none;
                                height: calcFunction(30);
                                width: 70%;
                                font-size: calcFunction(14);
                                background: transparent;
                            }
                        }
                        .current_one_arra{
                            img{
                                width: calcFunction(10);
                                height: calcFunction(17);
                            }
                        }
                    }
                    .current_two_content{
                        .current_two_content_header{
                            .current_two_header{
                                .current_header{
                                    @extend .alignItemsCenter;
                                    justify-content: space-between;
                                    margin-bottom: calcFunction(11);
                                    span{
                                        font-size: calcFunction(15);
                                        font-weight: bold;
                                    }
                                    img{

                                    }
                                }
                                .current_content_model{
                                    background: #F7F8FA;
                                    border-radius: calcFunction(8);
                                    .current_model{
                                        .model{
                                            @extend .alignItemsCenter;
                                            padding-top: calcFunction(15);
                                            padding-left: calcFunction(10);
                                            border-bottom: 1px solid #DDDDDD;
                                            padding-bottom: calcFunction(15);
                                            span{
                                                color: #333333;
                                                width: 15%;
                                                font-size: calcFunction(14);
                                                font-weight: bold;
                                            }
                                            input{
                                                width: 100%;
                                                border: none;
                                                outline: none;
                                                background: transparent;
                                                height: 100%;
                                                font-size: calcFunction(14);
                                            }
                                        }
                                        .model_tow{
                                            @extend .alignItemsCenter;
                                            justify-content: space-between;
                                            padding: calcFunction(15) calcFunction(10);
                                            .model_two_content{
                                                width: 100%;
                                                @extend .alignItemsCenter;
                                                span:nth-child(1){
                                                    width: 15%;
                                                    display: block;
                                                    font-size: calcFunction(14);
                                                    color: #333333;
                                                    font-weight: bold;
                                                }
                                                span:nth-last-child(1){
                                                    font-size: calcFunction(14);
                                                }
                                            }
                                            .current_model_img{
                                                img{
                                                    width: calcFunction(10);
                                                    height: calcFunction(17);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.specifyActive{
    button{
        background: #FFEAEA!important;
        border: 1px solid #D71518!important;
        color: #D71518!important;
    }
}

.fixed_button{
    position: fixed;
    bottom: 0;
    width: 100%;
    .fixed_button_content{
        padding: calcFunction(15);
        button{
            width: 100%;
            height: calcFunction(45);
            background: #FFB5B6;
            color: white;
            border: none;
            border-radius: calcFunction(8);
            font-size: calcFunction(17);
        }
    }
}
</style>