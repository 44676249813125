<template>
  <div class="accePtance">
    <div class="accePtance_content">
      <div class="accePtance_content_list">
        <div class="acceptance_list">
          <div
            class="acceptance_for_data"
            v-for="(s, k) in acceptance_personnel"
            :key="k"
          >
            <div class="acceptance_data_content_left_name_image">
              <div class="acceptance_image">
                <img src="@/assets/user.png" alt="" />
                <span>{{ s.user_nickname }}</span>
              </div>
              <div class="acceptance_button_right">
                <span>{{ s.status_text }}</span>
                <!-- <button v-if="routers != ' 验收任务' || routers != ' 执行任务'" @click="sureAccePersonEvent(s)">验收</button> -->
              </div>
            </div>
            <div class="Acceptance_task">
              <div class="Acceptance_task_list">
                <div class="task_list">
                  <span>bug数(未解决/总bug数)</span>
                  <span>{{ s.bug_not_finish }}/{{ s.bug_total }}</span>
                </div>
                <div class="task_list">
                  <span>开始时间</span>
                  <span>{{ s.starttime_text }}</span>
                </div>
                <div class="task_list">
                  <span>结束时间</span>
                  <span>{{ s.completetime_text }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as API from "@/api.ts";
export default {
  props: {
    acceptance_personnel: Array,
    routers: String,
  },
  methods: {
    sureAccePersonEvent(s) {
      console.log(s);
      this.$dialog
        .confirm({
          title: "验收",
          message: "您是否验收该任务",
        })
        .then(() => {
          API.sureCheck({
            ids: s.id,
          }).then(async (e) => {
            this.$toast({
              type: e.code == 0 ? "fail" : "success",
              message: e.msg,
            });
            if (e.code == 1) {
              console.log(e);
              setTimeout(() => {
                this.$router.back(-1);
              }, 1000);
            }
          });
        })
        .catch(() => {
          this.$toast({
            type: "fail",
            message: "您已取消",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.accePtance {
  .accePtance_content {
    .accePtance_content_list {
      .acceptance_list {
        .acceptance_for_data {
          background: white;
          margin-bottom: calcFunction(10);
          border-radius: calcFunction(8);

          .acceptance_data_content_left_name_image {
            @extend .alignItemsCenter;
            justify-content: space-between;
            padding: calcFunction(10) calcFunction(20);
            border-bottom: 1px solid #eee;

            .acceptance_image {
              @extend .alignItemsCenter;

              img {
                width: calcFunction(14);
                height: calcFunction(16);
              }

              span {
                font-size: calcFunction(14);
                margin-left: calcFunction(2);
              }
            }

            .acceptance_button_right {
              @extend .alignItemsCenter;

              span {
                font-size: calcFunction(14);
                color: rgba(255, 66, 0);
              }

              button {
                font-size: calcFunction(14);
                margin-left: calcFunction(8);
                color: white;
                background: rgba(215, 21, 24);
                padding: calcFunction(4) calcFunction(14);
                border: none;
                outline: none;
                border-radius: calcFunction(4);
              }
            }
          }

          .Acceptance_task {
            .Acceptance_task_list {
              padding: calcFunction(10) calcFunction(20);

              .task_list {
                @extend .alignItemsCenter;
                justify-content: space-between;
                margin-bottom: calcFunction(10);

                span {
                  font-size: calcFunction(14);
                  color: #999;
                }

                span:nth-last-child(1) {
                  color: #333;
                }
              }

              .task_list:nth-child(1) {
                span:nth-last-child(1) {
                  color: #375394;
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>