<template>
  <div class="card">
    <div class="card_list" :class="cpvalue ? 'customVh' : ''">
      <div class="card_for" v-for="(s, k) in cardData" :key="k">
        <div
          class="card_each"
          v-if="s.divvy_num !== null || s.status"
          @click="s.text == '-' ? '' : sureEvent(s, k)"
        >
          <div class="card_title" @click="sureEvent(s, k)">
            <div class="title">
              <span>{{ s.title }}</span>
              <span
                :style="
                  s.status == 6 ||
                  s.status_text == '已结束' ||
                  s.cost_performance
                    ? 'color:#999999;'
                    : ''
                "
              >
                {{
                  s.status_text
                    ? s.status_text == "已结束"
                      ? "结束分红"
                      : distinguishProject
                      ? `第${s.divvy_num}期分红`
                      : s.status_text
                    : statusEvent(s.divvy_num ? s.divvy_num : s.status)
                }}
              </span>
            </div>
          </div>
          <div class="card_list_each" :class="cpvalue ? 'cpvalueStyle' : ''">
            <div class="list_each" v-for="(i, l) in s.children" :key="l">
              <span @click="sureEvent(s, k)">{{ i.name }}</span>
              <span>{{ i.text == "分红金额：" ? "￥" + i.text : i.text }}</span>
              <!-- <img
                v-if="i.text == '--'"
                @click="
                  editEvent(
                    s,
                    k,
                    i.name == '分红金额：' ? 1 : i.name == '分红日期：' ? 2 : ''
                  )
                "
                src="../../assets/edit.png"
                alt=""
              /> -->
            </div>
          </div>
          <div v-if="cpvalue" class="my_money">
            <div class="my_money_content">
              <div class="my_money_content_box">
                <span>我的分红(税前):</span>
                <span
                  :style="s.divvy ? '' : 'font-weight:500;font-size:15px;'"
                  >{{ s.divvy ? "￥" + s.divvy : "未公布" }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    cardData: {
      type: Array,
      default: [],
    },
    descriptionNumber: Number,
    cpvalue: String,

    distinguishProject: Number || String,
  },
  methods: {
    statusEvent(k) {
      return k ? `第${k}期分红` : "";
    },
    sureEvent(s, k) {
      this.$emit("sureEvent", s, k);
    },
    moreEvenClick() {
      this.$emit("moreEventClick");
    },
    editEvent(s, k, type) {
      this.$emit("editEvent", s, k, type);
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.card {
  padding-bottom: 0.3rem;
  .card_list {
    .card_for {
      background: #fff;
      margin-bottom: calcFunction(10);
      border-radius: 0.16rem;
      .card_each {
        width: 100%;
        background: #fafafa;
        border: 0.01rem solid #dddddd;
        border-radius: 0.08rem;
        .card_title {
          padding: calcFunction(15);
          border-bottom: 1px solid #dddddd;
          .title {
            @extend .alignItemsCenter;
            justify-content: space-between;
            span {
              font-size: 0.28rem;
              color: #111111;
            }
            span:nth-last-child(1) {
              color: #d71518;
              font-size: 0.28rem;
            }
          }
        }
        .card_list_each {
          padding-top: calcFunction(15);
          padding-left: calcFunction(14);
          padding-bottom: calcFunction(13);
          @extend .alignItemsCenter;
          justify-content: space-between;
          flex-wrap: wrap;
          .list_each {
            width: 50%;
            margin-bottom: calcFunction(10);
            span {
              color: #999999;
              font-size: calcFunction(14);
            }
            span:nth-last-child(1) {
              color: black;
            }
          }

          .list_each:nth-child(3) {
            width: 100%;
          }

          .list_each:nth-last-child(1) {
            span:nth-last-child(1) {
              color: #d71518;
            }
          }
          img {
            width: calcFunction(15);
            height: calcFunction(15);
            margin-left: calcFunction(20);
            margin-bottom: calcFunction(-2);
          }
        }
      }
    }
  }
}

.cpvalueStyle {
  display: flex;
  margin-right: calcFunction(15);
  .list_each {
    width: 33.33% !important;
  }
  .list_each:nth-child(1) {
    margin-left: 0;
  }
  .list_each:nth-last-child(1) {
    width: 100% !important;
    text-align: left;
    span:nth-last-child(1) {
      color: black !important;
    }
  }
  .list_each:nth-child(2),
  .list_each:nth-child(3) {
    text-align: center;
  }
}

.my_money {
  margin: calcFunction(15);
  padding-bottom: calcFunction(15);
  margin-top: 0;
  .my_money_content {
    border-top: 1px solid #eeeeee;
    padding-top: calcFunction(15);
    .my_money_content_box {
      @extend .alignItemsCenter;
      justify-content: flex-end;
      span {
        color: #d71518;
        font-size: calcFunction(15);
      }
      span:nth-last-child(1) {
        font-weight: bold;
        font-size: calcFunction(18);
      }
    }
  }
}

.click_more_updata {
  .click_more_updata_button {
    .buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: calcFunction(14);
        color: #999;
      }
    }
  }
}
</style>
