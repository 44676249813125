<template>
  <div class="review_task">
    <div class="review_task_content">
      <div class="review_task_content_header">
        <div class="review_task_header">
          <publicNavigation :data="navigation" />
        </div>
        <!-- 搜索框 -->
        <div class="review_task_search">
          <van-search
            v-model="project_title"
            shape="round"
            @clear="clearsearchChange"
            placeholder="请输入项目关键词"
          />
        </div>

        <div class="review_task_classfiy">
          <!-- 日期选择 -->
          <div class="review_task_selectTime">
            <div class="review_task_seelctrTime">
              <div v-if="start_time">
                <div>{{ start_time }}~</div>
                <div>{{ end_time }}</div>
              </div>
              <div v-else>选择时间</div>
              <div class="review_task_time_image">
                <img
                  v-if="!start_time"
                  @click="openTimeOnchage"
                  src="../assets/rightFill.png"
                  class="rotateImage"
                  alt=""
                />
                <img
                  v-if="start_time"
                  @click="clearTime"
                  style="width: 16px; height: 16px"
                  src="../assets/clear.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <!-- 发布人选择 -->
          <van-dropdown-menu>
            <van-dropdown-item
              v-model="create_id"
              @open="openOnchangePersonEvent"
              @change="onConfirmPerson"
              @close="closeOnchangeEvent"
              :options="create_person"
            />
          </van-dropdown-menu>
          <!-- 审核类型选择 -->
          <van-dropdown-menu>
            <van-dropdown-item
              v-model="type"
              :options="audit_type"
              @open="openTypeEvent"
              @close="closeTypeEvent"
              @change="typeListConfirm"
            />
          </van-dropdown-menu>
        </div>
      </div>
      <van-list v-model="loading"
                :finished="finished"
                :finished-text="perfromData.length > 0 ? '没有更多了' : ''"
                @load="onLoad">
        <duration @description="description" :perfromData="perfromData" />
      </van-list>
      <van-empty v-if="perfromData.length == 0" description="暂无数据" />
    </div>
    <van-calendar
      v-model="timeShow"
      allow-same-day
      :max-date="new Date( new Date().getFullYear(), new Date().getMonth(), new Date().getDate())"
      :min-date="new Date(2023, 0, 1)"
      type="range"
      @confirm="onConfirm"
    />
  </div>
</template>
<script>
import publicNavigation from "@/publicNavigation/publicNavigation.vue";
import duration from "./compoment/durationList.vue";
// import {reviewData} from './reviewTask.ts'
import * as API from "@/api.ts";
export default {
  components: {
    publicNavigation,
    duration,
  },
  data() {
    return {
      // 请求参数(审核任务)
      start_time: "", //开始日期
      end_time: "", //结束日期
      project_title: "", //项目关键词
      create_id: "", //发布人id
      type: 0, //审核类型
      page: 1,
      limit: 10,

      timeShow: false, //选择日期弹窗显隐
      loading: false, // 加载
      finished: false, // 加载完成

      navigation: {
        image: require("../assets/return.png"),
        name: "审核任务",
      },
      create_person: [{ text: "发布人", value: "" }],
      audit_type: [{ text: "审核类型", value: 0 }],
      perfromData: [], //审核任务列表
      // 开始时间戳
      newdayStart: "",
      // 结束时间戳
      newdayEnds: "",
      // 储存人员
      storePersonName: "",
      // 储存类型ID
      typeConfirm: "",
    };
  },
  mounted() {
    // const parms = new reviewData().init()
    // this.perfromData = parms.reviewData
    this.getList();
    this.PersonList();
    this.release_review_Type();
  },
  methods: {
    // 初始化审核任务
    init() {
      this.page = 1;
      this.perfromData = [];
      this.loading = false;
      this.finished = false;
    },
    // 上拉加载更多
    onLoad() {
      if (!this.finished) {
        this.page++;
        this.getList();
      }
    },
    //获取审核任务列表
    getList() {
      this.loading = true;
      let {
        start_time,
        end_time,
        project_title,
        create_id,
        page,
        limit,
        type,
      } = this;
      const parms = !!type
        ? {
            page,
            limit,
            create_id,
            start_time,
            end_time,
            project_title,
            type,
          }
        : {
            page,
            limit,
            create_id,
            start_time,
            end_time,
            project_title,
          };
      API.release_review(parms).then(async (e) => {
        if (e.code == 1) {
          e.data.rows.map((item) => {
            this.perfromData.push({
              name: item.title,
              number: item.difficulty_text,
              status: item.release_review,
              ...item,
              children: [
                {
                  name: "归属项目：",
                  text: item.project_title ? item.project_title : "--",
                },
                {
                  name: "发布人：",
                  text: item.create_name ? item.create_name : "--",
                },
                {
                  name: "开始时间：",
                  text: item.starttime_text ? item.starttime_text : "--",
                },
                {
                  name: "完成时间：",
                  text: item.endtime_text ? item.endtime_text : "--",
                },
                {
                  name: "工作量：",
                  text: item.workload + "天",
                },
                {
                  name: "审核类型：",
                  text: item.type_text ? item.type_text : "--",
                },
              ],
            });
          });
          this.loading = false;
          if (e.data.rows.length <= 0) {
            this.finished = true;
          }
        }
      });
    },
    PersonList() {
      API.userList().then(async (e) => {
        if (e.code == 1) {
          e.data.rows.map((item) => {
            this.create_person.push({
              text: item.nickname,
              value: item.id,
            });
          });
        }
      });
    },
    release_review_Type() {
      API.release_review_Type().then(async (e) => {
        if (e.code == 1) {
          e.data.map((items) => {
            this.audit_type.push({
              text: items.name,
              value: items.id,
            });
          });
        }
      });
    },
    openOnchangePersonEvent() {
      this.create_person[0].text = "全部";
    },
    closeOnchangeEvent() {
      this.create_person[0].text = "发布人";
    },
    openTimeOnchage() {
      this.timeShow = !this.timeShow;
    },
    description(e) {
      window.localStorage.setItem("taskDescription", JSON.stringify(e));
      this.$router.push({
        name: "reviewDescriptionVue",
        path: "/reviewDescriptionVue",
        query: {
          cardType: "审核任务",
          id: e.id,
          rid: e.rid,
        },
      });
    },

    // 日期弹窗确认
    onConfirm(date) {
      this.start_time = this.$utils.getDate(date[0]); //开始日期
      this.end_time = this.$utils.getDate(date[1]); //结束日期
      this.timeShow = false; //关闭日期选择框
      this.init(); //初始化搜索条件
      this.getList(); //获取审核任务列表
    },
    formatDate(date) {
      const data = new Date(date);
      const year = data.getFullYear();
      const mont =
        data.getMonth() + 1 < 10
          ? "0" + (data.getMonth() + 1)
          : data.getMonth() + 1;
      const monteh =
        data.getDate() < 10 ? "0" + data.getDate() : data.getDate();
      return `${year + "-" + mont + "-" + monteh}`;
    },
    // 发布人选择
    onConfirmPerson(e) {
      this.create_id = e;
      this.init(); //初始化搜索
      this.getList(); //获取审核任务列表
    },
    // 审核类型选择
    typeListConfirm(e) {
      this.type = e;
      this.init(); //初始化搜索
      this.getList(); //获取审核任务列表
    },
    openTypeEvent() {
      this.audit_type[0].text = "全部";
    },
    closeTypeEvent() {
      this.audit_type[0].text = "审核类型";
    },
    // 清除时间
    clearTime() {
      this.init(); //初始化搜索条件
      this.start_time = "";
      this.end_time = "";
      this.getList(); //获取任务审核列表
    },
    clearsearchChange(e) {
      this.page = 1;
      console.log(e);
      this.perfromData = [];
      this.getList(
        this.newdayStart ? this.newdayStart : "",
        this.newdayEnds ? this.newdayEnds : "",
        this.storePersonName ? this.storePersonName : "",
        this.typeConfirm ? this.typeConfirm : "",
        this.value ? this.value : ""
      );
    },
  },
};
</script>
<style lang="scss">
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.review_task {
  min-height: 100vh;
  background: #f1f0f5;

  .review_task_content {
    .review_task_content_header {
      background: white;
      position: sticky;
      top: 0;

      .van-search {
        padding-top: 0;
        padding-bottom: 0;
      }

      .van-dropdown-menu__bar {
        box-shadow: none;
        padding-top: calcFunction(5);
      }

      .van-dropdown-menu__title {
        font-size: 0.28rem;

        &::after {
          border-color: transparent transparent #333333 #333333;
        }
      }

      .review_task_classfiy {
        padding-top: calcFunction(2);
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }
  }
}

.review_task_selectTime {
  .review_task_seelctrTime {
    display: flex;
    align-items: center;

    span {
      font-size: 14px;
    }

    .review_task_time_image {
      display: flex;
      align-items: center;

      img {
        width: 18px;
        height: 16px;
      }

      .rotateImage {
        transform: rotate(90deg);
      }
    }
  }
}
</style>
