<template>
  <div class="record_description">
    <div class="record_description_content">
      <div class="record_description_content_box">
        <div class="record_description_content_header">
          <publicNavigation :data="navigation" />
        </div>
        <div class="record_status">
          <div class="record_status_title">
            <span :style="productObject.status == 3 ? 'color:#999;' : ''">
              {{ productObject.status_text }}
            </span>
          </div>
        </div>

        <div class="record_gift">
          <div class="record_gift_image">
            <van-image :src="productObject.img" fit="cover"></van-image>
          </div>
          <div class="record_gift_right_text_description">
            <div class="record_gift_text">
              <span>{{ productObject.content }}</span>
            </div>
            <div class="record_gift_bottom_text">
              <div class="record_gift_left_text">
                <span>{{ productObject.reward }}</span>
                <span>奖励点</span>
              </div>
              <div class="record_gift_right_number">
                <span>x1</span>
              </div>
            </div>
          </div>
        </div>

        <div class="exchange_record_numner">
          <div class="exchange_number">
            <ul>
              <li>
                <div class="title">
                  <span>兑换编号：</span>
                </div>
                <div class="right_number">
                  <span>{{ productObject.order_no }}</span>
                  <button
                    class="copyInput"
                    @click="copy(productObject.order_no)"
                  >
                    复制
                  </button>
                </div>
              </li>
              <li>
                <div class="title">
                  <span>兑换时间：</span>
                </div>
                <div class="right_number custom_number">
                  <!-- timeFunction(productObject.time) -->
                  <span>{{ productObject.time_text }}</span>
                </div>
              </li>
              <li v-if="productObject.status == 3 || productObject.status == 2">
                <div class="title">
                  <span
                    >{{
                      productObject.status == 3
                        ? "退回"
                        : productObject.status == 2
                        ? "发放"
                        : ""
                    }}时间</span
                  >
                </div>
                <div class="right_number custom_number">
                  <span>
                    {{
                      productObject.status == 3
                        ? productObject.canceltime_text
                        : productObject.distributiontime_text
                    }}</span
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="bottom_button">
          <div class="buttons" v-if="productObject.status == 1">
            <button @click="sureRvoke">撤销兑换</button>
          </div>
        </div>
      </div>
    </div>
    <van-popup :round="true" v-model="show">
      <div class="record_popup_show_content">
        <div class="record_show_content">
          <div class="record_show_title flex-c-c">
            <span>提示</span>
          </div>
          <div class="record_text_description">
            <div class="record_descriptions">
              <span>撤销后，奖励点将返回您的账户。</span>
            </div>
          </div>
          <div class="record_button">
            <div class="record_button_total">
              <button @click="closeEvent">取消</button>
              <van-button
                :loading="loading"
                loading-text="撤销中"
                @click="sureClickEvent"
                >确定</van-button
              >
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import publicNavigation from "@/publicNavigation/publicNavigation.vue";
import recordData from "../compoments/recordListCard.vue";
import * as API from "@/api.ts";

import Clipboard from "clipboard";

export default {
  components: {
    publicNavigation,
    recordData,
  },
  data() {
    return {
      navigation: {
        image: require("@/assets/return.png"),
        name: "订单详情",
        close: "",
      },
      recordData: [],
      show: false,
      sureShow: true,
      productObject: {},
      loading: false, //是否是撤销中
    };
  },
  mounted() {
    this.Requset();
  },
  methods: {
    sureRvoke() {
      this.show = !this.show;
    },
    Requset() {
      API.exchangeGiftInfo({
        id: this.$route.query.product,
      }).then(async (e) => {
        if (e) {
          this.productObject = e.data.row;
          this.recordData = [
            {
              img: e.data.row.img.split(","),
              content: e.data.row.content,
              reward: e.data.row.reward,
            },
          ];
        }
      });
    },
    sureClickEvent() {
      this.sureShow = true;
      this.loading = true;
      API.GiftRevoke({
        id: this.productObject.id,
      }).then(async (e) => {
        this.$notify({
          type: e.code == 1 ? "success" : "danger",
          message: e.msg,
        });
        this.show = false;
        setTimeout(() => {
          if (e.code == 1) {
            this.sureShow = false;
            this.Requset();
            this.loading = false;
          } else {
            this.loading = false;
            this.show = false;
          }
        }, 800);
      });
      // API.exchangeGift({id:this.productObject.id}).then(async e=>{
      //     this.$notify({
      //             type:e.code == 1 ? 'success' : 'danger',
      //             message:e.msg
      //         })
      //     if(e.code == 1){
      //         setTimeout(() => {
      //             this.sureShow ? '' : this.sureShow = !this.sureShow
      //         }, 800);
      //     }
      // })
    },
    copy(text) {
      let clipboard = new Clipboard(".copyInput", {
        text: () => {
          return text;
        },
      });
      clipboard.on("success", () => {
        this.$toast.success("复制成功");
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        this.$toast("该浏览器不支持自动复制,请手动复制！");
        clipboard.destroy();
      });
    },
    closeEvent() {
      this.show = !this.show;
    },
    timeFunction(time) {
      const date = new Date(time * 1000),
        Y = date.getFullYear(),
        M = date.getMonth(),
        R = date.getDate(),
        S = date.getHours(),
        F = date.getMinutes(),
        m = date.getSeconds();
      return (
        Y +
        "-" +
        (M < 10 ? "0" + M : M) +
        "-" +
        (R < 10 ? "0" + R : R) +
        " " +
        S +
        "-" +
        F +
        "-" +
        m
      );
    },
    timeSplice(time) {
      if (time) {
        return time.split(".").join("-");
      }
    },
    submit() {
      this.show = !this.show;
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.record_description {
  height: 100vh;
  background: #f2f3f5;

  .record_description_content_header {
    background: white;
  }

  .record_status {
    height: 1.46rem;
    display: flex;
    align-items: center;
    padding: 0 0.4rem;
    span {
      color: #d71518;
      font-size: 0.4rem;
      font-weight: bold;
    }
  }
}

.exchange_record_numner {
  margin-top: 0.2rem;

  .exchange_number {
    ul {
      background: white;
      padding: 0.3rem;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0.25rem;

        span {
          font-size: 0.28rem;
          color: #000000;
        }
        .right_number {
          position: relative;
          span {
            margin-right: 0.2rem;
            color: #999999;
          }
          .order_no {
            padding: 0;
          }
          button {
            position: relative;
            z-index: 2;
            background: transparent;
            border: none;
            outline: none;
            padding-left: 0.16rem;
            font-size: 0.3rem;
            border-left: 1px solid black;
          }
        }
      }

      li:nth-last-child(1) {
        padding-bottom: 0;
      }
    }
  }
}

.custom_number {
  span {
    margin: 0 !important;
  }
}

.bottom_button {
  position: fixed;
  bottom: 0;
  background: white;

  .buttons {
    display: flex;
    justify-content: flex-end;
    padding: 0.2rem 0.3rem;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0.4rem;
      font-size: 0.3rem;
      outline: none;
      border-radius: 0.5rem;
      border: 1px solid #cccccc;
      background: transparent;
      height: 0.8rem;
    }
  }
}

.record_popup_show_content {
  width: 6rem;

  .record_show_content {
    padding-top: 0.6rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;

    .record_show_title {
      font-size: 0.34rem;
      font-weight: bold;
    }

    .record_text_description {
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
      .record_descriptions {
        text-align: center;
        font-size: 0.28rem;
        color: #585858;
      }
    }

    .record_button {
      padding-bottom: 0.4rem;
      .record_button_total {
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
          border: none;
          outline: none;
          width: 2.4rem;
          height: 0.9rem;
          border-radius: 0.45rem;
          font-size: 0.32rem;
        }
        button:nth-last-child(1) {
          background: #d71518;
          color: white;
        }
        button:nth-child(1) {
          border: 1px solid #e5e5e5;
          background: transparent;
        }
      }
    }
  }
}

.record_gift {
  display: flex;
  align-content: stretch;
  width: 100%;
  margin-bottom: 0.2rem;
  padding: 0.4rem 0.3rem;
  background: #fff;
  .record_gift_image {
    width: 1.6rem;
    height: 1.6rem;
    border: 1px solid #dddddd;
    justify-content: center;
    .van-image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      img {
        display: block;
        width: 80%;
        height: 80%;
      }
    }
  }

  .record_gift_right_text_description {
    margin-left: 0.2rem;
    height: 1.6rem;
    justify-content: space-between;
    @extend .alignItemsCenter;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    .record_gift_text {
      span {
        font-size: 0.26rem;
        font-weight: bold;
      }
    }

    .record_gift_bottom_text {
      @extend .alignItemsCenter;
      justify-content: space-between;
      width: 100%;

      .record_gift_left_text {
        span {
          color: #d71518;
          font-size: 0.36rem;
          font-weight: bold;
        }
        span:nth-last-child(1) {
          font-weight: 400;
          color: black;
          font-size: 0.22rem;
          margin-left: 0.1rem;
        }
      }

      .record_gift_right_number {
        span {
          color: #666666;
          font-size: 0.28rem;
        }
      }
    }
  }
}
</style>
