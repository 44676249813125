<template>
    <div class="setting_account">
      <div class="setting_account_content_header">
        <publicNavigation :data="navigation" />
      </div>
      <div class="setting_account_mobile_number">
        <div class="setting_account_mobile_number_content">
          <ul>
            <li @click="verifyMobile">
              <div class="mobile_number">
                <span>手机号</span>
                <span>{{mobile(person_mobile)}}</span>
              </div>
              <div class="mobile_number_right">
                <span>更换</span>
                <img src="@/assets/arrowgray.png" alt="">
              </div>
            </li>
            <li @click="tooglePassword">
              <span>修改密码</span>
              <img src="@/assets/arrowgray.png" alt="">
            </li>
          </ul>
        </div>
      </div>
      <div class="output_button">
        <div class="output_button_click">
          <button @click="outLogin">退出登录</button>
        </div>
      </div>
    </div>
</template>
<script>
import publicNavigation from '@/publicNavigation/publicNavigation.vue';
export default {
    components:{
      publicNavigation
    },
    data(){
      return{
        navigation: {
          image: require('@/assets/return.png'),
          name:'账户设置',
          close:''
        },
        person_mobile:''
      }
    },
    mounted(){
      if(window.sessionStorage.getItem('userInfo')){
        this.person_mobile = JSON.parse(window.sessionStorage.getItem('userInfo')).mobile
      }
    },
    methods:{
        verifyMobile(){
            this.$router.push({
                name:'verifyMobile',
                path:'/verifyMobile',
				query:{
					e:this.person_mobile
				}
            })
        },
        tooglePassword(){
            this.$router.push({
                name:'tooglePassword',
                path:'/tooglePassword'
            })
        },
        mobile(e){
          return e.replace(/(\d{3})\d{4}(\d{4})/,'$1****$2');
        },
        outLogin(){
          this.$dialog.confirm({
            title:'退出登录',
            message:'是否退出登录'
          }).then(async e=>{
            console.log(e)
            this.$toast.success('正在退出登录')
            setTimeout(()=>{
              window.location.href = '/'
            },1000)
          }).catch(async e=>{
            console.log(e)
          })
        }
    }
}


</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}


.setting_account{
    height: 100vh;
    background: #F1F2F6;
    .setting_account_content_header{
        background: white;
    }
    .setting_account_mobile_number{
        .setting_account_mobile_number_content{
            ul{
                li{
                    @extend .alignItemsCenter;
                    justify-content: space-between;
                    font-size: 0.28rem;
                    color: #333333;
                    background: white;
                    padding: 0.4rem;
                    .mobile_number{
                        span:nth-last-child(1){
                            color: #666666;
                            margin-left: 0.3rem;
                        }
                    }
                    .mobile_number_right{
                        @extend .alignItemsCenter;
                        span{
                            color: #999999;
                            margin-right: 0.1rem;
                        }
                    }
                    img{
                        width: 0.16rem;
                        height: 0.26rem;
                    }
                }
                li:nth-last-child(1){
                    margin-top: 0.1rem;
                }
            }
        }
    }
    .output_button{
        margin: 0.9rem 0.3rem 0;
        .output_button_click{
            width: 100%;
            button{
              width: 100%;
              height: 0.9rem;
              color: white;
              background: #D71518;
              border: none;
              outline: none;
              font-size: 0.3rem;
              border-radius: 0.1rem;
            }
        }
    }
}
</style>
