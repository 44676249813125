<template>
	<div class="tabbar">
		<div class="tabbar_list">
			<div class="tabbar_header" style="padding-top:1.25rem;margin-top:0;background:white;">
				<van-sticky>
					<van-tabs title-active-color="#D71518" @click="tabsClick" v-model="active">
						<van-tab v-for="(item, index) in iconData ? iconData : arrayIcon" :key="index">
							<template #title>
								<span>{{ item == "任务" ? arrayIcon[0] + "(" + taskNumbers + ")" : item }}</span>
							</template>
						</van-tab>
					</van-tabs>
				</van-sticky>
				<div class="content_data">
					<div class="content_data_box">
						<div class="data_box" v-if="currentIndex == 0">
							<presonCard :forskellen="1" :userID="userID" :personCard="personCard" @moreClick="moreClick"
								:more="1" :vrdi="1" />
						</div>
						<div class="data_box" v-if="currentIndex == 1">
							<div class="log_description_header_box" v-if="taskLogData.length > 0">
								<div class="log_header_content" v-for="s,k in taskLogData" :key="k">
									<div class="header_contents_box">
										<div class="header_title">
											<img :src="require('@/assets/calendar.png')" alt="">
											<span>{{s.rows.time}}</span>
										</div>
										<div class="person_description">
											<div class="person_description_content">
												<div class="description_name" v-for="i,l in s.list" :key="l">
													<div class="person_title">
														<img :src="require('@/assets/user.png')" alt="">
														<span>{{i.nickname}}</span>
													</div>
													<div class="project_task_description">
														<div class="project_task_header">
															<div class="project_task_header_title">
																<span>项目任务： <i class="title_header_text">{{i.title}}</i>
																</span>
															</div>
															<div class="project_task_introduce">
																<div class="project_introduce">
																	<span>{{i.content}}</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="nulldata" v-if="taskLogData.length<=0">
								<img src="@/assets/nulldata.png" alt="" />
								<span>暂无数据</span>
							</div>
						</div>
						<div class="task_award" v-if="currentIndex == 2">
							<div class="task_award_content">
								<div class="task_award_content_new">
									<div class="task_award_for_list" v-for="s,k in awardData" :key="k">
										<div class="award_list_data">
											<div class="award_list_title">
												<div class="award_user_name">
													<img :src="require('@/assets/user.png')" alt="" />
													<span>{{s.user_nickname}}</span>
												</div>
												<div class="award_create_status">
													<span>{{s.status_text}}</span>
												</div>
											</div>
											<div class="award_list_card">
												<div class="award_card">
													<div class="award_list">
														<span>项目名：</span>
														<span>{{s.title}}</span>
													</div>
													<div class="award_list">
														<span>工作天数：</span>
														<span>{{s.workload}}</span>
													</div>
													<div class="award_list">
														<span>完成进度：</span>
														<span>{{s.timeout_text}}</span>
													</div>
													<div class="award_list">
														<span>奖励点：</span>
														<span>{{s.reward}}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="nulldata" v-if="awardData.length<=0">
								<img src="@/assets/nulldata.png" alt="" />
								<span>暂无数据</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import presonCard from "@/person/components/personCard/personCard.vue";
	export default {
		props: {
			iconData: Array,
			rewardsContent: Array,
			customCorrect: Function,
			currentIndex: Number,
			personCard: Array,
			personCardDescription: Object,
			taskLogData: Array,
			awardData: Array,
			taskNumbers: Number
		},
		components: {
			presonCard,
		},
		data() {
			return {
				arrayIcon: [],
				active: 0,
				buttonData: [],
			};
		},
		mounted() {
			if (this.iconData) {
				this.arrayIcon = this.iconData;
			} else {
				this.arrayIcon = ["任务", "项目日志", "项目奖励"];
			}
		},
		methods: {
			newEvent(s) {
				this.$emit('rewardsEvent', s)
			},
			tabsClick(e) {
				this.$emit('tabsClick', e)
			},
			moreClick() {
				this.$emit('moreClick')
			}
		}
	};
</script>
<style lang="scss">
	// 继承变量
	.alignItemsCenter {
		display: flex;
		align-items: center;
	}

	// 计算函数
	@function calcFunction($number, $default: 16) {
		@return calc($number * 2 / 100) + rem;
	}
	
	.tabbar{
		flex: 1;
	}

	.tabbar_header {
		margin-top: calcFunction(20);
		padding-top: calcFunction(10);
		border-top: 1px solid #dddddd;
		height: 100%;
	}

	.content_data {
		height: 100%;
		.content_data_box {
			height: 100%;
			.data_box {
				// height: 45.8vh;
				// overflow-y: scroll;
				height: 100%;
				background: #f1f0f5;
				padding: calcFunction(10) calcFunction(10);
				padding-bottom: 0;
			}
		}
	}

	.person_card_data {
		padding-top: calcFunction(16) !important;

		.person_card_header {
			border-bottom: 1px solid #eeeeee !important;
			padding-bottom: calcFunction(15) !important;
		}
	}

	.custom {
		.person_for_data {
			width: 50%;

			span {
				font-size: calcFunction(12) !important;

				i {
					font-size: calcFunction(13);
					color: #d71518;
					font-style: normal;
				}
			}
		}

		.person_for_data:nth-last-child(1) {
			@extend .alignItemsCenter;
			margin-top: -10px;
			height: 100%;
		}
	}

	.maxCustom {
		border: none !important;
		border-radius: calcFunction(4) !important;
	}

	.is_ok {
		color: #666666;
		font-size: calcFunction(13);
	}

	.van-tab {
		padding: 0;
	}

	.log_description_header_box {
		.log_header_content {
			background: white;
			padding: calcFunction(15);
			border-radius: calcFunction(4);
			margin-top: calcFunction(10);

			.header_contents_box {
				.header_title {
					@extend .alignItemsCenter;

					img {
						width: calcFunction(20);
						height: calcFunction(20);
					}

					span {
						font-size: calcFunction(14);
						margin-left: calcFunction(8);
					}
				}

				.person_description {
					.person_description_content {
						.description_name {
							background: #fafafa;
							margin-top: calcFunction(15);
							padding: calcFunction(10);

							.person_title {
								margin-bottom: calcFunction(10);
								padding-bottom: calcFunction(10);
								border-bottom: 1px solid #ddd;
								@extend .alignItemsCenter;

								img {
									width: calcFunction(14);
									height: calcFunction(14);
								}

								span {
									font-size: calcFunction(12);
									color: #666666;
									margin-left: calcFunction(2);
								}
							}

							.project_task_description {
								.project_task_header {
									.project_task_header_title {
										margin-bottom: calcFunction(7);
										margin-top: calcFunction(15);

										span {
											font-size: calcFunction(13);
											color: #333333;
										}
									}

									.project_task_introduce {
										.project_introduce {
											span {
												font-size: calcFunction(13);
												color: #333333;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.task_award {
		// height: 45.8vh;/
		// overflow-y: scroll;
		background: #f1f0f5;
		height: 100%;
		padding: 0.625rem 0.625rem;

		.task_award_content {
			.task_award_content_new {
				.task_award_for_list {
					background: white;
					margin-bottom: calcFunction(20);
					border-radius: calcFunction(5);
					padding: calcFunction(15);

					.award_list_data {
						.award_list_title {
							.award_user_name {
								@extend .alignItemsCenter;

								img {
									width: calcFunction(18);
									height: calcFunction(18);
								}

								span {
									font-size: calcFunction(14);
								}
							}

							.award_create_status {
								span {}
							}
						}

						.award_list_card {
							.award_card {
								@extend .alignItemsCenter;
								flex-wrap: wrap;
								justify-content: space-between;

								.award_list {
									margin-bottom: calcFunction(number, default);
									width: 50%;
									margin-top: calcFunction(8);

									span {
										font-size: calcFunction(14);
									}
								}

								.award_list:nth-child(2n) {
									text-align: right;
								}
							}
						}
					}
				}
			}
		}
	}

	.title_header_text {
		font-style: normal;
		color: #375394;
		border-bottom: 1px solid #375394;
	}

	.nulldata {
		@extend .alignItemsCenter;
		flex-direction: column;
		justify-content: center;
		height: 100%;

		img {
			width: 50%;
		}

		span {
			margin-top: calcFunction(10);
			color: #999999;
			font-size: calcFunction(12);
		}
	}
	
	.tabbar_list{
		height: 100%;
	}
</style>
