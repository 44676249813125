<template>
  <div class="navigation">
    <div class="navigation_header">
      <publicNavigation :data="navigation" />
    </div>
    <div class="background_color">
      <div class="background_colors"></div>
    </div>
    <!-- 项目名 -->
    <projekt :projekt="projekt" />
    <!-- 项目介绍 -->
    <introducere
      @attachmentClick="attachmentClick"
      @spliceEvent="spliceEvent"
      :introduce="introduce"
    />
    <!-- 标签切换 -->
    <descriptionCardTabbar
      @moreClick="moreClick"
      :taskNumbers="taskNumbers"
      :awardData="awardData"
      :taskLogData="taskLogData"
      @tabsClick="tabsClick"
      :personCardDescription="personCardDescription"
      :currentIndex="currentIndex"
      :personCard="personCard"
    />
  </div>
</template>
<script>
import publicNavigation from "@/publicNavigation/publicNavigation.vue";
import projekt from "@/projectList/compoments/descriptionCard.vue";
import introducere from "@/projectList/compoments/descriptionIntroducere.vue";
import descriptionCardTabbar from "@/projectList/compoments/descriptionCardTabbar.vue";
import * as API from "@/api.ts";
export default {
	components: {
		publicNavigation,
		projekt,
		introducere,
		descriptionCardTabbar
	},
	data() {
		return {
			navigation: {},
			projekt: '',
			introduce: '',
			currentIndex: 0,
			personCard: [],
			page: 1,
			awardData: [],
			taskNumbers: '',
			userID:'',
			taskLogData:[]
		}
	},
	mounted() {
		this.Requset()
		this.navigation = {
			image: require('../../../assets/return.png'),
			name: '项目详情'
		}
		this.projectTask()
		this.projectdaily()
		this.projectreward()
		this.userID = window.sessionStorage.getItem('userInfo') ? JSON.parse(window.sessionStorage.getItem('userInfo')).identity : ''
		window.addEventListener('scroll',()=>{
			console.log(window)
			const clientHeight = window.innerHeight
			const scrollHeight = document.body.scrollHeight || document.documentElement.scrollHeight
			const scrollTop =  window.scrollY
			console.log(clientHeight,scrollHeight,scrollTop)
			if(scrollTop + clientHeight > scrollHeight - 1){
				this.page ++
				// this.$toast.loading({message:'加载中'})
				setTimeout(()=>{
					// this.personCard = []
					this.projectTask()
				},800)
			}
		})
	},
	methods: {
		Requset(e) {
			API.detail({
				id: this.$route.query.s.id,
				type: e ? e + 1 : 1
			}).then(async e => {
				if (e.code == 1) {
					this.projekt = {
						name: e.data.title,
						children: [{
							name: '类型：',
							text: e.data.project_type_text
						}, {
							name: '创建人：',
							text: e.data.user_name
						}, {
							name: '创建时间：',
							text: e.data.create_time_text
						}]
					}
					this.introduce = e.data
					this.personCardDescription = e.data

				}
			})
		},
		spliceEvent(s, e) {
			this.introduce.img_text.splice(e, 1)
		},
		attachmentClick(s, e) {
			this.introduce.attachment_text.splice(e, 1)
		},
		projectTask() {
			const that = this
			API.projectTask({
				id: this.$route.query.s.id,
				page: this.page,
				limit: 10
			}).then(async e => {
				if (e.code == 1) {
					if (e.data?.rows) {
						// if (e.data.rows.length <= 0) {
						// 	this.$toast.fail('暂无数据')
						// }
						e.data.rows.map(item => {
							that.personCard.push({
								...item,
								check:false,
								name: "章鱼云后台开发",
								desc: [{
									name: "执行人：",
									text: item.user_name ? item.user_name : item.section_name ? item.section_name :'公共接单',
								},
								{
									name: "工作量：",
									text: this.userID == 4  && item.status == 1 ? '-' : item.workload + "天",
									// number: this.userID == 4 && item.status == 1 ? '-' : item.timeout_text,
								},
								{
									name: "开始时间：",
									text: this.userID == 4 && item.status == 1 ? '-' :  item.real_starttime_text,
								},
								{
									name: "完成时间：",
									text: this.userID == 4 && item.status == 1 ? '-' :  item.real_endtime_text,
									number: this.userID == 4 && item.status == 1 ? '-' :  item.timeout_text,
								},
								],
							})
						})
						// this.personCard = this.personCard.concat(e.data.rows)
						this.taskNumbers = e.data.total
					}
				}
			})
		},
		projectdaily() {
			API.projectdaily({
				id: this.$route.query.s.id,
				page: 1,
				limit: 10,
				user_id: this.$route.query.s.user_id
			}).then(async e => {
				if (e.code == 1) {
					this.taskLogData = [e.data]
				}
			})
		},
		projectreward() {
			API.projectreward({
				id: this.$route.query.s.id,
				page: 1,
				limit: 10,
			}).then(async e => {
				if (e.code == 1) {
					this.awardData = e.data.rows
				}
			})
		},
		tabsClick(e) {
			this.currentIndex = e
			this.page = 1
			if (e == 0) {
				this.personCard = []
				this.projectTask()
			} else if (e == 1) {
				this.taskLogData = []
				this.projectdaily()
			} else {
				this.awardData = []
				this.projectreward()
			}
		},
	}
}

</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.navigation_header {
  z-index: 2;
}

.navigation {
  display: flex;
  height: 89vh;
  flex-direction: column;
}

.background_color {
  .background_colors {
    height: calcFunction(190);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    background: linear-gradient(0deg, #feefef 0%, #ffffff 100%);
  }
}
</style>