<template>
  <div class="publish_project" style="background: white; height: 100vh">
    <div class="publish_project_content">
      <div class="publish_project_content_box">
        <div class="pulish_project_content_header">
          <publicNavigation @sureEvenet="sureEvenet" :data="navigation" />
        </div>
        <div class="input_search_text">
          <div class="input_search">
            <input
              type="text"
              @input="handleInput"
              v-model="inputSearch"
              placeholder="请输入项目关键词搜索"
            />
            <span style="white-space: nowrap"
              ><i>{{ textNumber || 0 }}</i
              >/20</span
            >
          </div>
          <div class="input_search_list">
            <div class="input_search_list_title">
              <span>最近项目</span>
            </div>
            <ul>
              <li
                v-for="(s, k) in textArray"
                @click="navigationToParms(s, k)"
                :key="k"
              >
                <span>{{ s.title }}</span>
                <img
                  v-if="personIndex == k && personIndex !== ''"
                  :src="icon"
                  alt=""
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import publicNavigation from "@/publicNavigation/publicNavigation";
import * as API from "@/api.ts";
export default {
  components: {
    publicNavigation,
  },
  data() {
    return {
      navigation: {},
      inputSearch: "",
      textNumber: 0,
      textArray: [],
      icon: require("@/assets/redsave.png"),
      personIndex: "",
      selectProject: "",
      origin: window.localStorage.getItem("sure"),
      id: "",
    };
  },
  watch: {
    inputSearch(e) {
      this.textNumber = e.split("").length;
      this.inputSearch = e;
    },
  },
  mounted() {
    this.navigation = {
      image: require("@/assets/return.png"),
      name: "项目",
      sure: "确定",
      close: "",
    };
    this.requset();
  },
  methods: {
    handleInput() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.requset(this.inputSearch);
      }, 800);
    },
    navigationToParms(s, k) {
      this.personIndex = k;
      this.selectProject = s.title;
      this.id = s.id;
      console.log(s);
    },
    requset(searchText) {
      this.textArray = [];
      const parms = {
        search: searchText,
        page: 1,
        limit: 10,
      };
      if (this.$route.query.e == 8) {
        API.projectIndex(parms).then(async (e) => {
          if (e.code == 1) {
            e.data.rows.map((item) => {
              this.textArray.push({
                title: item.title,
                id: item.id,
              });
            });
          }
        });
      } else {
        API.task_type(parms).then(async (e) => {
          if (e.code == 1) {
            e.data.rows.map((item) => {
              this.textArray.push({
                title: item.title,
                id: item.id,
              });
            });
          }
        });
      }

      console.log(this.textArray);
    },
    sureEvenet() {
      const parms = {
        project: this.selectProject,
        projectId: this.id,
      };
      if (this.origin) {
        const data = Object.assign(JSON.parse(this.origin), parms);
        window.localStorage.setItem("sure", JSON.stringify(data));
      } else {
        window.localStorage.setItem("sure", JSON.stringify(parms));
      }
      this.$toast.success("选择成功");
      setTimeout(() => {
        this.$router.back(-1);
      }, 800);
    },
  },
};
</script>

<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.publish_project {
  .publish_project_content {
    .publish_project_content_box {
      .pulish_project_content_header {
      }
      .input_search_text {
        margin: 0 calcFunction(10);
        .input_search {
          @extend .alignItemsCenter;
          justify-content: space-between;
          border-bottom: 1px solid #dcdcdc;
          padding-bottom: calcFunction(20);
          input {
            border: none;
            outline: none;
            width: 100%;
            font-size: calcFunction(14);
          }
          span {
            flex: 1;
            font-size: calcFunction(14);
            i {
              font-style: normal;
              color: #d71518;
            }
          }
        }
        .input_search_list {
          .input_search_list_title,
          ul {
            li {
              border-bottom: 1px solid #eeeeee;
              padding-bottom: calcFunction(16);
            }
            span {
              font-size: calcFunction(14);
              color: #666666;
              font-weight: 500;
            }
            img {
              width: calcFunction(13);
              height: calcFunction(11);
            }
          }
          ul {
            li {
              margin-bottom: calcFunction(10);
              @extend .alignItemsCenter;
              justify-content: space-between;
              span {
                color: #111111;
              }
            }
          }
          .input_search_list_title {
            padding: calcFunction(16) 0;
          }
        }
      }
    }
  }
}
</style>