<template>
  <div class="updata_mobile">
    <div class="updata_mobile_content">
      <div class="updata_mobile_content_box">
        <div class="updata_mobile_content_header">
          <publicNavigation :data="navigation" />
        </div>
        <div class="updata_mobile_new_mobile_number_list">
          <div class="updata_mobile_list">
            <ul>
              <li>
                <div class="new_updata_mobile_number flex-c-b">
                  <div class="verify_code_text">
                    <span class="label">新手机号</span>
                    <!--<input class="input_view" type="text" v-model="newMobile" placeholder="请填写新的手机号"/>-->
                    <!-- 输入手机号，调起手机号键盘 -->
                    <van-field class="input_view" v-model="newMobile" type="tel" placeholder="请填写新的手机号" />
                  </div>
                </div>
              </li>
              <li>
                <div class="new_updata_mobile_number flex-c-b">
                  <div class="verify_code_text">
                    <span class="label">验证码</span>
                    <!--<input class="input_view" type="text" v-model="getcode" placeholder="请输入验证码"/>-->
  
                    <van-field class="input_view" v-model="getcode" type="number" placeholder="请输入验证码" />
                  </div>
                  <div class="verify_code_button">
                    <button @click="getCode">
                      {{NumberPhone > 0 ? NumberPhone + "S后重新获取" : "获取验证码"}}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="sure_button">
          <div class="sure_button_click">
            <button @click="sureCode">确定</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import publicNavigation from "@/publicNavigation/publicNavigation";
import * as API from "@/api.ts";
export default {
  components: {
    publicNavigation,
  },
  data() {
    return {
      navigation: {},
      newMobile: "",
      getcode: "",
      NumberPhone: 0,
      success: false,
    };
  },
  mounted() {
    this.navigation = {
      image: require("@/assets/return.png"),
      name: "更换手机号",
      close: "",
    };
  },
  watch: {
    newMobile(newVal, oldVal) {
      if (newVal.length > 11) {
        this.newMobile = oldVal;
      }
    },
    getcode() {
      // clearTimeout(this.outtime)
      // this.outtime = setTimeout(()=>{
      // 	API.smsCode({
      // 		mobile:this.newMobile,
      // 		event:'changemobile',
      // 		captcha:e
      // 	}).then(async e=>{
      // 		this.$toast({
      // 			type:e.code == 1 ? 'success' : 'fail',
      // 			message:e.msg
      // 		})
      // 		if(e.code == 1){
      // 			this.success = true
      // 		}
      // 	})
      // },1000)
    },
  },
  methods: {
    getCode() {
      if (this.NumberPhone != 0) {
        return;
      }
      if (this.newMobile) {
        API.send({
          mobile: this.newMobile,
          event: "changemobile",
        }).then(async (e) => {
          this.$toast({
            type: e.code == 1 ? "success" : "fail",
            message: e.msg,
          });
          if (e.code == 1) {
            this.NumberPhone = 60;
            setInterval(() => {
              this.NumberPhone--;
              if (this.NumberPhone == 0) {
                this.NumberPhone = 0;
              }
            }, 1000);
          }
        });
      } else {
        this.$toast.fail("手机号不能为空");
      }
    },
    sureCode() {
      // && this.success
      if (this.newMobile && this.getcode) {
        API.changemobile({
          mobile: this.newMobile,
          captcha: this.getcode,
        }).then(async (e) => {
          this.$toast({
            type: e.code == 0 ? "fail" : "successs",
            message: e.msg,
          });
          if (e.code == 1) {
            window.sessionStorage.clear();
            window.localStorage.clear();
            this.$toast.success("请重新登录");
            setTimeout(() => {
              window.location.href = "/";
            }, 1000);
          }
        });
      } else {
        this.$toast.fail("手机号或验证码不能为空");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}


.updata_mobile {
  min-height: 100vh;
  background: #fff;
  .updata_mobile_content {
    .updata_mobile_content_box {
      .updata_mobile_content_header {
      }

      .updata_mobile_new_mobile_number_list {
        .updata_mobile_list {
          ul {
            li {
              padding: 0.4rem 0.3rem;
              border-bottom: 1px solid #eeeeee;

              

              .new_updata_mobile_number {
                .verify_code_text{
                  display: flex;
                  align-items: center;
                  flex: 1;
                  .label{
                    display: block;
                    width: 1.5rem;
                    font-size: 0.28rem;
                    color: #333333;
                  }
  
                  input::placeholder {
                    color: #999999;
                  }
  
                  input {
                    font-size: 0.26rem;
                    height: 0.5rem;
                  }
  
                  .input_view{
                    flex: 1;
                    padding: 0;
                  }
                }

              }
  
  
              .verify_code_button {
                @extend .alignItemsCenter;
                margin-left: 0.3rem;
                button {
                  background: transparent;
                  border: none;
                  outline: none;
                  font-size: 0.26rem;
                  white-space: nowrap;
                  color: #c32d30;
                }
              }
              
            }
          }
        }
      }

      .sure_button {
        margin: 0 calcFunction(15);
        margin-top: calcFunction(50);

        .sure_button_click {
          button {
            background: #d71518;
            color: white;
            padding: calcFunction(13) 0;
            width: 100%;
            border: none;
            outline: none;
            border-radius: calcFunction(5);
            font-size: calcFunction(16);
          }
        }
      }
    }
  }
}
</style>
