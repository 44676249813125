<template>
  <div class="toogle_password">
    <div class="toogle_password_content_header">
      <publicNavigation :data="navigation"/>
    </div>
    <div class="toogle_descirption_text">
      <div class="descriptions">
        <span>备注：密码由6-20个英文字母、数字或符号组成</span>
      </div>
    </div>
    <div class="password_list">
      <div class="password_list_content">
        <ul>
          <li>
            <span>原密码</span>
            <!--<input type="text" v-model="originPassword" placeholder="请填写原来的密码">-->
            <van-field class="input_view" @input="textInput" v-model="originPassword" type="password" placeholder="请填写原来的密码"/>
          </li>
          <li>
            <span>新密码</span>
            <!--<input type="text" v-model="newPassword" placeholder="请输入新的密码">-->
            <van-field class="input_view" @input="textInput" v-model="newPassword" type="password" placeholder="请输入新的密码"/>
          </li>
          <li>
            <span>确认密码</span>
            <!--<input type="text" v-model="surePassword" placeholder="请确认新的密码">-->
            <van-field class="input_view" @input="textInput" v-model="surePassword" type="password" placeholder="请确认新的密码"/>
          </li>
        </ul>
      </div>
    </div>
    <div class="forget_password">
      <span @dblclick="forgetPassword">忘记原密码？</span>
    </div>
    <div class="sure_bottom_button">
      <button @click="SubmitEvent" :disabled="!isSubmit" :style="isSubmit ? {background:'#D71518'} : ''">确定</button>
    </div>
  </div>
</template>
<script>
import publicNavigation from '@/publicNavigation/publicNavigation.vue';
import * as API from '@/api.ts'

export default {
  components: {
    publicNavigation
  },
  data() {
    return {
      navigation: {
        image: require('@/assets/return.png'),
        name: '修改密码',
        close: ''
      },
      originPassword: '',
      newPassword: '',
      surePassword: '',
      isSubmit: false
    }
  },
  mounted() {
  
  },
  methods: {
    textInput(val){
      if(this.originPassword != '' && this.newPassword != '' && this.surePassword != ''){
        this.isSubmit = true;
      }else{
        this.isSubmit = false;
      }
    },
    SubmitEvent() {
      const parms = {
        password: this.originPassword,
        new_password: this.newPassword
      }
      if (parms.password && parms.new_password) {
        if (this.newPassword == this.surePassword) {
          API.editPassword(parms).then(async e => {
            console.log(e)
            if(e.code == 1){
              this.$toast({
                type: 'success',
                message: e.msg
              })
              
              setTimeout(() => {
                window.location.href = '/'
                window.localStorage.clear()
                window.sessionStorage.clear()
              }, 1000)
            }else{
              this.$toast({
                message: e.msg
              })
            }
          })
        } else {
          this.$toast.fail('新密码跟确认密码不一致')
        }
      } else {
        this.$toast.fail('密码不能为空')
      }
    },
    forgetPassword() {
      this.$router.push({
        name: 'retrievepassword',
        path: '/retrievepassword'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.toogle_password {
  background:white;
  min-height:100vh;
  .toogle_descirption_text {
    margin: 0 0.4rem;
    padding-top: 0.3rem;
    
    .descriptions {
      span {
        color: #666666;
        font-size: 0.26rem;
      }
    }
  }
  
  .password_list {
    .password_list_content {
      ul {
        li {
          padding: 0.4rem;
          border-bottom: 1px solid #EEEEEE;
          @extend .alignItemsCenter;
          
          span {
            display: block;
            width: 1.5rem;
            color: #333333;
            font-size: 0.28rem;
          }
          
          .input_view {
            flex: 1;
            border: none;
            background: transparent;
            font-size: 0.28rem;
            padding: 0;
          }
          
          input::placeholder {
            color: #999999;
          }
        }
        
        li:nth-child(1) {
          margin-top: 0.2rem;
        }
      }
    }
  }
  
  .forget_password {
    margin: 0.3rem 0.4rem 0;
    
    span {
      color: #297DB2;
      font-size: 0.26rem;
    }
  }
  
  .sure_bottom_button {
    margin: 0.8rem 0.3rem;
    
    button {
      background: #F0CCCC;
      transition-duration: .5s;
      color: white;
      padding: 0.16rem 0;
      width: 100%;
      border: none;
      outline: none;
      border-radius: 0.1rem;
      font-size: 0.32rem;
      height: 0.9rem;
    }
  }
}
</style>
