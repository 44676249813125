<template>
	<div class="gift_card_content">
		<div class="gift_card_content_list">
			<div class="gift_card_content_for">
				<div class="gift_card_fors">
					<div class="card_for" v-for="s,k in giftCard" :key="k">
						<div class="card_status">
							<div class="status_title">
								<span>{{s.status}}</span>
							</div>
							<div class="card_gift_project">
								<div class="gift_image">
									<img :src="s.image" alt="">
								</div>
								<div class="gift_right_text">
									<div class="gift_right_title">
										<div class="gift_title">
											<span>{{s.title}}</span>
										</div>
										<div class="gift_money_number">
											<div class="money_number">
												<span>{{s.number}}</span>
												<span>奖励点</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="button_status_box">
								<div class="button_list_box">
									<button @click="checkOrder(s)">查看订单</button>
									<!-- <button>关闭兑换</button> -->
									<button @click="productend(s)">下架</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			giftCard: Array
		},
		methods:{
			checkOrder(e){
				this.$router.push({
					name:'checkorderlist',
					path:'/checkorderlist',
					query:{
						e:e
					}
				})
			},
			productend(e){
				this.$emit('productend',e)
			}
		}
	}
</script>

<style lang="scss" scoped>
	// 继承变量
	.alignItemsCenter {
		display: flex;
		align-items: center;
	}

	// 计算函数
	@function calcFunction($number, $default: 16) {
		@return calc($number * 2 / 100) + rem;
	}

	.gift_card_content {
		.gift_card_content_list {
			.gift_card_content_for {
				.gift_card_fors {
					.card_for {
						background: white;
						margin-top: calcFunction(15);
						.card_status {
							.status_title {
								padding: calcFunction(10) calcFunction(15);
								margin-bottom: calcFunction(15);
								border-bottom: 1px solid #EDEDED;
								span {
									font-size: calcFunction(15);
								}
							}

							.card_gift_project {
								@extend .alignItemsCenter;
								height: 100%;
								margin-bottom: calcFunction(15);
								margin-left: calcFunction(15);
								margin-right: calcFunction(15);
								border-bottom: 1px solid #EDEDED;
								padding-bottom: calcFunction(15);
								.gift_image {
									width: calcFunction(70);
									height: calcFunction(70);
									padding: calcFunction(8);
									border: 1px solid #DDDDDD;
									@extend .alignItemsCenter;
									justify-content: center;
									img {
										width: 100%;
										height: 100%;
									}
								}

								.gift_right_text {
									height: calcFunction(88);
									margin-left: calcFunction(10);
									.gift_right_title {
										height: 100%;
										flex-direction: column;
										justify-content: space-between;
										@extend .alignItemsCenter;
										align-items: flex-start;
										.gift_title {
											span {
												font-size: calcFunction(14);
												white-space: nowrap;
												text-overflow: ellipsis;
												overflow: hidden;
												display: inline-block;
											}
										}

										.gift_money_number {
											margin-bottom: calcFunction(5);
											.money_number {
												span {
													font-size: calcFunction(18);
													color: #D71518;
												}

												span:nth-last-child(1) {
													margin-left: calcFunction(5);
													color: #000000;
													font-size: calcFunction(13);
												}
											}
										}
									}
								}
							}
							.button_status_box {
								padding-bottom: calcFunction(15);
								.button_list_box {
									@extend .alignItemsCenter;
									justify-content: flex-end;
									button {
										width: 25%;
										font-size: calcFunction(13);
										color: black;
										padding: calcFunction(7) calcFunction(14);
										background: transparent;
										border: 1px solid #CCCCCC;
										border-radius: calcFunction(10000);
										margin-right: calcFunction(10);
									}
								}
							}
						}
					}
				}
			}
		}
	}
</style>
