var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"top-box"},[_c('div',{staticClass:"flex-c-c task-navigate-box"},[_c('img',{staticClass:"task-left-back",attrs:{"src":require("@/assets/return.png"),"alt":""},on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('van-dropdown-menu',[_c('van-dropdown-item',{ref:"taskDom",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_vm._v(_vm._s(_vm.taskTitle || "验收任务"))])]},proxy:true}])},[_c('div',_vm._l((_vm.acceptanceTask),function(item){return _c('div',{key:item.id,class:[
                'list',
                'flex-c-c',
                item.id == _vm.task ? 'highlight' : '',
              ],on:{"click":function($event){return _vm.onConfirmTask(item)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0)])],1)],1),_c('van-search',{attrs:{"show-action":"","shape":"round","background":"#FFF","placeholder":"请输入bug名称"},on:{"search":_vm.onSearch},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('van-dropdown-menu',[_c('van-dropdown-item',{ref:"navClassDom",attrs:{"get-container":"body"},on:{"open":function($event){_vm.showNav = false},"close":function($event){_vm.showNav = true}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('img',{staticClass:"more",attrs:{"src":require("@/assets/more.png"),"alt":""},on:{"click":function($event){_vm.show = true}}})]},proxy:true},{key:"default",fn:function(){return [_c('van-dropdown-menu',[_c('van-dropdown-item',{ref:"bugTypeDom",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_vm._v(_vm._s(_vm.bug_type || "bug类型"))])]},proxy:true}])},[_c('div',_vm._l((_vm.bugList),function(item){return _c('div',{key:item,class:[
                        'list',
                        'flex-c-c',
                        item == _vm.bug_type ? 'highlight' : '',
                      ],on:{"click":function($event){return _vm.onConfirmBugType(item)}}},[_vm._v(" "+_vm._s(item)+" ")])}),0)]),_c('van-dropdown-item',{ref:"bugSolveDom",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_vm._v(_vm._s(_vm.bug_solve || "解决方案"))])]},proxy:true}])},[_c('div',_vm._l((_vm.solutionList),function(item){return _c('div',{key:item.status,class:[
                        'list',
                        'flex-c-c',
                        item.status == _vm.bug_solve ? 'highlight' : '',
                      ],on:{"click":function($event){return _vm.onConfirmBugSolve(item)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0)]),_c('van-dropdown-item',{ref:"bugLevelDom",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.bug_level ? _vm.bug_level != "全部" ? _vm.bug_level + "级" : "全部" : "bug等级")+" ")])]},proxy:true}])},[_c('div',_vm._l((_vm.bugLevelList),function(item){return _c('div',{key:item,class:[
                        'list',
                        'flex-c-c',
                        item == _vm.bug_level ? 'highlight' : '',
                      ],on:{"click":function($event){return _vm.onConfirmBugLevel(item)}}},[_vm._v(" "+_vm._s(item == "全部" ? item : item + "级")+" ")])}),0)])],1),_c('van-dropdown-menu',[_c('van-dropdown-item',{ref:"statusDom",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_vm._v(_vm._s(_vm.status_text || "bug状态"))])]},proxy:true}])},[_c('div',_vm._l((_vm.bugStatusList),function(item){return _c('div',{key:item.status,class:[
                        'list',
                        'flex-c-c',
                        item.status == _vm.bug_status ? 'highlight' : '',
                      ],on:{"click":function($event){return _vm.onConfirmStatus(item)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0)]),_c('van-dropdown-item',{ref:"dateDom",scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.start_time && _vm.start_time != _vm.end_time)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.start_time)+" ~")]),_c('div',[_vm._v(_vm._s(_vm.end_time))])]):(_vm.start_time && _vm.start_time == _vm.end_time)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.start_time))])]):_c('div',[_vm._v("创建时间")])]},proxy:true}])},[_c('div',[_c('van-calendar',{style:({ height: '500px' }),attrs:{"title":"选择时间","type":"range","poppable":false,"min-date":new Date(2023, 0, 1),"max-date":new Date(),"allow-same-day":true,"show-confirm":false},on:{"confirm":_vm.confirmDate},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"clear flex-c-c",on:{"click":_vm.clearDate}},[_vm._v(" 清除 ")])]},proxy:true}])})],1)])],1)]},proxy:true}])})],1)]},proxy:true}]),model:{value:(_vm.bug_name),callback:function ($$v) {_vm.bug_name=$$v},expression:"bug_name"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showNav),expression:"showNav"}],staticClass:"flex-c-b statusNav"},_vm._l((_vm.navList),function(item,index){return _c('div',{key:index,class:['nav', _vm.status == index ? 'activeNav' : ''],on:{"click":function($event){return _vm.selectNav(index)}}},[_vm._v(" "+_vm._s(item)),(_vm.status == index)?_c('span',[_vm._v("("+_vm._s(_vm.total)+")")]):_vm._e()])}),0)],1),(_vm.list.length > 0)?_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('div',{staticClass:"task-list-box"},_vm._l((_vm.list),function(item){return _c('div',{key:item.id,staticClass:"task-list mb10",on:{"click":function($event){return _vm.$router.push({
            path: '/solveBugDescription',
            query: { id: item.id, bugs: 1 },
          })}}},[_c('div',{staticClass:"task-list-top"},[_c('div',{staticClass:"flex-c-b"},[_c('div',{staticClass:"task-name blue line mr20"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"status_text",style:({ color: _vm.statusColor(item.status) })},[_vm._v(" "+_vm._s(item.status_text)+" ")])])]),_c('div',{staticClass:"task-list-content"},[_c('div',{staticClass:"flex-c"},[_c('div',{staticClass:"flex-c mt10",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"label"},[_vm._v("bug类型：")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(item.type_text))])]),_c('div',{staticClass:"flex-c mt10",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"label"},[_vm._v("指派给：")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(item.solve_name || "--"))])])]),_c('div',{staticClass:"flex-c"},[_c('div',{staticClass:"flex-c mt10",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"label"},[_vm._v("bug等级：")]),_c('div',{class:[
                  'flex-c-c',
                  item.level_text == 1
                    ? 'levelGray'
                    : item.level_text == 2
                    ? 'levelYellow'
                    : 'levelRed',
                ]},[_vm._v(" "+_vm._s(item.level_text)+" ")])]),_c('div',{staticClass:"flex-c mt10",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"label"},[_vm._v("创建日期：")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(item.createtime_text || "--"))])])])])])}),0)]):_c('van-empty',{attrs:{"description":"暂无数据"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }