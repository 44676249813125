<template>
  <div class="correct-box">
    <!-- 标题 -->
    <div class="flex-c-c">奖励点修正</div>
    <!-- 表单内容 -->
    <div class="form-box mt20">
      <div class="flex-c mb10">
        <div style="width: 50%" class="flex-c">
          <div class="label">姓名：</div>
          <div class="value">{{ correctData.user_nickname }}</div>
        </div>
        <div style="width: 50%" class="flex-c">
          <div class="label">工作量：</div>
          <div class="value">{{ correctData.workload }}</div>
        </div>
      </div>
      <div class="flex-c mb10">
        <div style="width: 50%" class="flex-c">
          <div class="label">难度：</div>
          <!-- 难度指数 -->
          <van-rate
            size="15px"
            color="#D71518"
            void-color="#FFF"
            :value="Number(correctData.difficulty_text)"
            readonly
          />
        </div>
        <div style="width: 50%" class="flex-c">
          <div class="label">标准奖励点：</div>
          <div class="value">
            <div>{{ (correctData && correctData.standard_reward) || 0 }}</div>
          </div>
        </div>
      </div>
      <div class="flex-c mb10">
        <div style="width: 50%" class="flex-c">
          <div class="label">工期：</div>
          <div class="value">{{ correctData.timeout_text }}</div>
        </div>
        <div style="width: 50%" class="flex-c">
          <div class="label">绩效奖励点：</div>

          <div class="value">
            <template v-if="correctData.is_increase == 1">
              <div v-if="correctData.person_type == 1">
                +
                <!-- Number(correctData.edit_reward) -->
                {{
                  (correctData && Number(correctData.additional_reward)) || 0
                }}
              </div>
              <div v-else>
                {{ (correctData && correctData.edit_reward) || 0 }}
              </div>
            </template>
            <template v-else>
              <div v-if="correctData.person_type == 1">
                {{ (correctData && correctData.additional_reward) || 0 }}
              </div>
              <div v-else>
                {{
                  (correctData &&
                    correctData.additional_reward + correctData.edit_reward) ||
                  0
                }}
              </div>
            </template>

            <!-- {{ correctData.additional_reward || 0 }} -->
          </div>
        </div>
      </div>
      <div class="flex-c mb30">
        <div v-if="identity == 4">
          <div class="flex-c">
            <div class="label">额外奖励点：</div>
            <div class="value">
              {{ (correctData && correctData.other_reward) || 0 }}
            </div>
          </div>
        </div>
      </div>

      <div class="mb10">
        <div class="flex-c">
          <div class="label">绩效奖励点修正：</div>
          <div class="value flex-c">
            <van-field
              type="number"
              v-model="reward"
              placeholder="输入奖励点"
            />-
            <van-field v-model="reward_reason" placeholder="填写修正原因" />
          </div>
        </div>
      </div>
      <div class="mb10">
        <div class="flex-c">
          <div class="label">额外奖励点：</div>
          <div class="value flex-c">
            <van-field
              type="number"
              v-model="additional_reward"
              placeholder="输入额外奖励点"
            />-
            <van-field
              v-model="additional_reward_reason"
              placeholder="额外奖励点原因"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 合计&保存 -->
    <div class="flex-c-b mt40">
      <div>
        <div class="label">修正后合计：</div>
        <div style="color: #d71518" class="value mt5">
          {{ allReward || 0 }}个奖励点
        </div>
      </div>
      <div @click="correct_reward" class="save">保存</div>
    </div>
  </div>
</template>

<script>
import * as API from "@/api.ts";

export default {
  // id:任务验收进来时的主任务id
  props: ["correctData", "type", "id"],
  name: "correct",
  data() {
    return {
      identity: "", //身份
      reward: "", //修正奖励点
      additional_reward: "", //额外奖励点
      reward_reason: "", //绩效奖励点修正原因
      additional_reward_reason: "", //额外奖励点原因
    };
  },
  computed: {
    allReward() {
      if (this.correctData.person_type == 2) {
        return (
          Number(this.correctData.add_reward) +
          Number(this.correctData.other_reward) +
          Number(this.reward) +
          Number(this.additional_reward)
        );
      } else {
        return (
          Number(this.reward) +
          Number(this.additional_reward) +
          Number(this.correctData.reward) +
          Number(this.correctData.other_reward) +
          Number(this.correctData.additional_reward)
        );
      }
    },
  },
  mounted() {
    //获取用户信息
    this.getUserInfo();
  },
  methods: {
    //获取用户信息
    async getUserInfo() {
      let { code, data } = await API.userInfo();
      if (code == 1) {
        this.identity = data.identity;
      }
    },
    // 修正奖励点 release_review_reward
    // type: 1奖励点审核时的修改， 其余都是任务奖励修改
    async correct_reward() {
      if (this.type == 1) {
        let {
          additional_reward,
          reward,
          additional_reward_reason,
          reward_reason,
        } = this;
        let params =
          this.correctData.person_type == 1
            ? {
                other_reward: additional_reward,
                additional_reward: reward,
                additional_reward_reason,
                reward_reason,
                id: this.id ? this.id : this.correctData.id,
                type: 1,
              }
            : {
                other_reward: additional_reward,
                additional_reward: reward,
                additional_reward_reason,
                reward_reason,
                id: this.id ? this.id : this.correctData.id,
                type: 2,
                user_id: this.correctData.user_id,
              };
        let { code, msg } = await API.release_review_reward(params);
        this.$toast(msg);
        if (code == 1) {
          //获取励列表数据
          this.$emit("getDetail");
        }
      } else {
        let {
          additional_reward,
          reward,
          additional_reward_reason,
          reward_reason,
        } = this;
        let params =
          this.correctData.person_type == 1
            ? {
                other_reward: additional_reward,
                additional_reward: reward,
                additional_reward_reason,
                reward_reason,
                ids: this.$route.query.id,
                type: 1,
              }
            : {
                other_reward: additional_reward,
                additional_reward: reward,
                additional_reward_reason,
                reward_reason,
                ids: this.$route.query.id,
                type: 2,
                user_id: this.correctData.user_id,
              };
        let { code, msg } = await API.correct_reward(params);
        this.$toast(msg);
        if (code == 1) {
          //获取励列表数据
          this.$emit("getReward");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 0.24rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
  white-space: nowrap;
}
.value {
  font-size: 0.24rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333;
}
.correct-box {
  padding: 0.3rem;
  box-sizing: border-box;
  font-size: 0.28rem;
  font-weight: bold;
}
// 保存
.save {
  padding: 0.2rem 1rem;
  box-sizing: border-box;
  font-size: 0.28rem;
  font-weight: bold;
  background: #d71518;
  border-radius: 0.1rem;
  color: #fff;
}
</style>