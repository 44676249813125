<template>
  <div class="gift_description" style="background: white">
    <!--顶部导航-->
    <div class="gift_description_content_header">
      <!-- @shareEvent="shareEvent" -->
      <publicNavigation
        :share="{ width: 20 + 'px', height: 20 + 'px' }"
        :data="navigation"
      />
    </div>
    <!--banner-->
    <div class="gift_swiper_banner">
      <van-swipe>
        <van-swipe-item v-for="(image, index) in details.img" :key="index">
          <!--<img v-lazy="image" class="detail_image" />-->
          <van-image fit="cover" class="detail_image" :src="image" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!--标题  点数-->
    <div class="gift_content_title">
      <div class="gift_content_reward">
        <div class="reward_title">
          <span>{{ details.name }}</span>
        </div>
        <div class="reward_number">
          <span>{{ details.reward }}</span>
          <span>奖励点</span>
        </div>
      </div>
    </div>

    <div class="gift_description_image_show">
      <div class="gift_description_title">
        <span>详情介绍</span>
      </div>
      <div class="gift_description_image_list">
        <van-image
          fit="contain"
          :src="s"
          v-for="(s, k) in details.detail_img"
          :key="k"
        />
      </div>
    </div>
    <div class="gift_bottom_buttn">
      <div class="gift_bottoom">
        <button @click="submit(details.id)">立即兑换</button>
      </div>
    </div>
    <van-popup :round="true" v-model="show">
      <div class="record_popup_show_content">
        <div class="record_show_content">
          <div class="record_show_title">
            <span>
              确定消耗<i>{{ details.reward }}奖励点</i>兑换
            </span>
          </div>
          <div class="record_show_content_text">
            <div class="record_content_image">
              <van-image fit="cover" :src="details.img" />
            </div>
          </div>
          <div class="record_text_description flex-c-c">
            <span>{{ details.name }}</span>
          </div>
          <div class="record_button">
            <div class="record_button_total flex-c-b">
              <button @click="submit">取消</button>
              <van-button
                :loading="loading"
                loading-text="兑换中"
                @click="sureClickEvent(details.id)"
                >确定</van-button
              >
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<!-- <script>
import DescriptionCard from "@/projectList/compoments/descriptionCard.vue";

export default {
  components: { DescriptionCard },
};
</script> -->
<script>
import publicNavigation from "@/publicNavigation/publicNavigation.vue";
import * as API from "@/api.ts";
export default {
  components: {
    publicNavigation,
  },
  data() {
    return {
      navigation: {
        image: require("@/assets/return.png"),
        name: "商品详情",
        //   search: require("@/assets/share.png"),
        close: "",
      },
      showShare: false,
      images: [],
      details: {},
      show: false,
      loading: false, //是否是兑换中
    };
  },
  mounted() {
    // 获取页面数据
    this.getData();
  },
  methods: {
    // 获取页面数据
    getData() {
      let params = {
        id: this.$route.query.parms,
      };
      API.productDetail(params).then((res) => {
        if (res.code == 1) {
          this.details = res.data;
        }
      });
    },
    shareEvent() {
      if (navigator.canShare) {
        navigator
          .share({
            title: "Pictures",
            text: "Our Pictures.",
          })
          .then(() => console.log("Share was successful."))
          .catch((error) => console.log("Sharing failed", error));
      } else {
        console.log(`Your system doesn't support sharing files.`);
      }
    },
    submit() {
      this.show = !this.show;
    },
    sureClickEvent(e) {
      this.loading = true;
      API.exchangeGift({ id: e }).then(async (e) => {
        this.$notify({
          type: e.code == 1 ? "success" : "danger",
          message: e.code == 1 ? "兑换成功" : e.msg,
        });
        if (e.code == 1) {
          setTimeout(() => {
            this.$router.push({
              name: "recordList",
              path: "/recordList",
            });
            this.loading = false;
          }, 1000);
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.gift_description {
  .gift_description_content_header {
    position: sticky;
    top: 0;
    z-index: 500;
    background: #fff;
  }
  .gift_swiper_banner {
    height: 7.5rem;
    .detail_image {
      width: 100%;
      height: 7.5rem;
      img {
        display: block;
        width: 100%;
        height: 7.5rem;
      }
    }
  }
  .gift_content_title {
    padding: 0.3rem;
    border-bottom: 0.2rem #f2f3f5 solid;
    .gift_content_reward {
      .reward_title {
        span {
          font-size: 0.36rem;
        }
      }
      .reward_number {
        margin-top: 0.2rem;
        span {
          font-size: 0.36rem;
          color: #d71518;
          font-weight: bold;
        }
        span:nth-last-child(1) {
          font-size: 0.22rem;
          font-weight: 400;
          margin-left: 0.1rem;
          color: black;
        }
      }
    }
  }
  .gift_gary_background {
    margin: calcFunction(20) 0;
    .gary_background {
      background: #f2f3f5;
      height: calcFunction(10);
      width: 100%;
    }
  }
  .gift_description_image_show {
    padding-bottom: 1.2rem;
    .gift_description_title {
      margin: 0 0.3rem;
      height: 1rem;
      display: flex;
      align-items: center;
      span {
        font-size: 0.3rem;
        font-weight: bold;
      }
    }
    .gift_description_image_list {
      .van-image {
        display: block;
        img {
          display: block;
          width: 100%;
        }
      }
    }
  }
}

.gift_bottom_buttn {
  position: fixed;
  bottom: 0;
  width: 100%;
  .gift_bottoom {
    padding: 0.2rem 0.3rem;
    background: white;
    button {
      width: 100%;
      height: 0.8rem;
      background: #d71518;
      color: white;
      border: none;
      outline: none;
      font-size: 0.3rem;
      border-radius: 0.4rem;
    }
  }
}

.record_popup_show_content {
  width: 6rem;
  .record_show_content {
    padding-top: 0.6rem;
    padding-left: 0.44rem;
    padding-right: 0.4rem;
    .record_show_title {
      justify-content: center;
      text-align: center;
      span {
        font-size: 0.34rem;
        font-weight: bold;
        i {
          font-style: normal;
          color: #d71518;
        }
      }
    }
    .record_show_content_text {
      justify-content: center;
      margin: 0.48rem 0;
      .record_content_image {
        display: block;
        margin: 0 auto;
        width: 1.8rem;
        height: 1.8rem;
        justify-content: center;
        border: 1px solid #dddddd;
        .van-image {
          width: 100%;
          height: 100%;
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .record_text_description {
      margin-bottom: 0.5rem;
      justify-content: center;
      font-size: 0.3rem;
    }
    .record_button {
      padding-bottom: 0.4rem;
      .record_button_total {
        button {
          border: none;
          outline: none;
          width: 2.4rem;
          height: 0.9rem;
          border-radius: 0.45rem;
        }
        button:nth-last-child(1) {
          background: #d71518;
          color: white;
        }
        button:nth-child(1) {
          border: 1px solid #e5e5e5;
          background: transparent;
        }
      }
    }
  }
}
</style>
