<template>
    <div class="person_card">
        <div class="person_card_data" @click="descriptionEvent(s,k)" :class="forskellen ? 'maxCustom' : ''" v-for="s,k in personCard" :key="k">
			<div class="person_card_header">
                <div class="person_card_header_left_name">
                    <img v-if="s.person" src="@/assets/user.png" alt="">
                    <span>{{ s.user_nickname }}</span>
                </div>
                <div class="person_card_header_right_button_list" >
                    <div class="person_list" v-if="forskellen != 0 || !offset">
						<button @click="customDescription(s)" style="margin-right: 10px;">详情</button>
                        <button v-if="!vrdi" @click="customCorrect(s)">修正</button>
                    </div>
                </div>
            </div>
            <div class="person_card_content">
                <div class="person_card_content_data">
                    <div class="preson_list_data" :class="forskellen != 0 ? 'custom' : ''">
                        <div class="person_for_data" :style="i.name == '邮箱：' ? 'width:100%' : ''" :class="currentIndex == 2 ? 'minText' : fontSize ? 'customfontSize' : ''"  v-for="i,l in s.desc" :key="l">
                            <span>{{ i.name }}</span>
                            <div class="image_box">
                                <img src="@/assets/start.png" alt="" v-for="s,k in i.startNumber" :key="k" />
                            </div>
							<!-- style="display: block;" -->
                            <span >{{ i.text }}</span>
                        </div>
                    </div>
                    <div class="tilbage" >
                        <div class="tilbage_button">
                            <span>合计奖励点：</span>
                            <span>{{ Number(s.add_reward) + Number(s.other_reward) }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        personCard:Array || [],
        forskellen:Number || String,
        offset:String,
        currentIndex:Number,
        fontSize:String,
        vrdi:Number,
    },
    methods:{
        descriptionEvent(s,k){
            this.$emit('descriptionEvent',s,k)
        },
        customCorrect(s){
            this.$emit('customCorrect',s)
        },
		customDescription(s){
			this.$emit('customDescription',s)
		}
    }
}

</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter{
    display: flex;
    align-items: center;
}
  
// 计算函数
@function calcFunction($number,$default:16){
  @return calc($number * 2 / 100) + rem;
}
.person_card{
    .person_card_data{
        border: 1px solid #DDDDDD;
        background: #FAFAFA;
        padding: 0 calcFunction(15);
        padding-top: calcFunction(11);
        margin-top: calcFunction(10);
        border-radius: calcFunction(4);
        .person_card_header{
            border-bottom: 1px solid #DDDDDD;
            padding-bottom: calcFunction(10);
            @extend .alignItemsCenter;
            justify-content: space-between;
            .person_card_header_left_name{
                @extend .alignItemsCenter;
                span{
                    color: #333333;
                    font-size: calcFunction(15);
                    font-weight: bold;
                }
                img{
                    margin-right: calcFunction(2);
                    width: calcFunction(13);
                    height: calcFunction(13);
                }
            }
            .person_card_header_right_button_list{
                .person_list{
                    button{
                        font-size: calcFunction(13);
                        padding: calcFunction(4.5) calcFunction(13);
                        border: 1px solid #999999;
                        background: transparent;
                        border-radius: calcFunction(2);
                        color: #666666;
                    }
                    button:nth-child(1){
                        //margin-right: calcFunction(10);
                        color: #000000;
                    }
                }
            }
        }
        .person_card_content{
            padding-bottom: calcFunction(14.5);
            .person_card_content_data{
                .preson_list_data{
                    @extend .alignItemsCenter;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    .person_for_data{
                        margin-top: calcFunction(15);
						display: flex;
						align-items:center;
                        span:nth-child(1){
                            color: #999999;
                        }
                        span{
                            color: #111111;
                            font-size: calcFunction(15);
                            img{
                                width: calcFunction(11);
                                height: calcFunction(11);
                                padding-top: calcFunction(1);
                            }
                        }
                        span:nth-last-child(1){
                            margin-top: calcFunction(2);
                        }
                    }
                }
            }
        }
    }
}


.image_box{
    img{
        width: calcFunction(12);
        height: calcFunction(12);
    }
}


.tilbage_button{
    display: flex;
    justify-content: flex-end;
    margin-top: calcFunction(15);
    border-top: 1px solid #EEEEEE;
    padding-top: calcFunction(15);
    span{
        font-size: calcFunction(14);
        color: #333333;
    }
    span:nth-last-child(1){
        color: #D71518;
    }
}
</style>