/*
 * @Author: Mr.Cheng
 * @Date: 2023-09-01 14:31:55
 * @LastEditTime: 2023-09-25 17:43:47
 * @FilePath: \octopus-factory-map\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router/router.ts'
import store from './store'
import utils from './utils/utils.js'
// 引入公用样式
import '@/assets/css/index.css'
import '@/assets/css/style.css'

import '@/utils/mobile'
import { Calendar, Uploader, Dialog, Popup, Toast, DatetimePicker, Lazyload, PasswordInput, Notify, SwipeCell } from 'vant';
if (window.sessionStorage.getItem('userInfo')) {
  const identity = JSON.parse(window.sessionStorage.getItem('userInfo')).identity
  Vue.prototype.$identity = identity
}

// 引入请求接口&方法
import * as request from './api.ts'
Vue.prototype.$utils = utils
Vue.prototype.$request = request
Vue.config.productionTip = false
Vue.use(Vant);
Vue.use(Toast);
Vue.use(Popup);
Vue.use(DatetimePicker);
Vue.use(Dialog);
Vue.use(Lazyload);
Vue.use(PasswordInput);
Vue.use(Notify);
Vue.use(Uploader);
Vue.use(Calendar);
Vue.use(SwipeCell);
new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
