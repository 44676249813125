<template>
  <div class="content">
    <!-- 列表 -->
    <van-list
      v-if="list.length > 0"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="list" v-for="item of list" :key="item.id">
        <!-- 列表顶部 -->
        <div class="list-top flex-c-b">
          <div class="title">{{ item.title }}</div>
          <div class="flex-c">
            <img
              @click="sectionEdit(item, 1)"
              class="edit-log mr20"
              src="@/assets/edit.png"
              alt=""
            />
            <img
              @click="sectionDel(item)"
              class="delete-log"
              src="@/assets/delete.png"
              alt=""
            />
          </div>
        </div>
        <!-- 列表内容 -->
        <div class="flex-c" style="flex-wrap: wrap">
          <div
            class="flex-c-b mr15 sm-list mt10"
            v-for="list of item.section"
            :key="list.id"
          >
            <div class="sm-title">{{ list.title }}</div>
            <div class="mr15 sm-list-edit-border flex-c-c">
              <img
                @click="sectionEdit(list, 2)"
                class="sm-edit"
                style="width: 0.24rem"
                src="@/assets/childedit.png"
                alt=""
              />
            </div>
            <img
              @click="sectionDel(list)"
              class="mr15"
              style="width: 0.24rem"
              src="@/assets/childdelete.png"
              alt=""
            />
          </div>
          <div @click="sectionAdd(item, 2)" class="sm-add-list mt10 flex-c">
            <div class="sm-list-edit-border">
              <img
                class="sm-addchild mr10"
                style="width: 0.24rem"
                src="@/assets/addchild.png"
                alt=""
              />
            </div>
            <div class="sm-add-text">添加岗位</div>
          </div>
        </div>
      </div>
    </van-list>

    <!-- 空列表 -->
    <van-empty v-else description="暂无数据" />
    <!-- 占位 -->
    <div style="width: 100%; height: 1.3rem"></div>
    <!-- 底部添加部门按钮 -->
    <div class="bottom-box">
      <div @click="sectionAdd(list, 1)" class="btn flex-c-c">添加部门</div>
    </div>

    <!-- 修改弹窗 -->
    <van-dialog
      v-model="editDepartmentShow"
      :title="type == 1 ? '修改部门' : '修改岗位'"
      show-cancel-button
      @confirm="editDepartmentConfirm"
    >
      <div class="process_pause_title">
        <input
          type="text"
          v-model="dtitle"
          :placeholder="type == 1 ? '请输入部门名称' : '请输入岗位名称'"
        />
      </div>
    </van-dialog>

    <!-- 添加弹窗 -->
    <van-dialog
      v-model="addShow"
      :title="type == 1 ? '添加部门' : '添加岗位'"
      show-cancel-button
      @confirm="addConfirm"
    >
      <div class="process_pause_title">
        <input
          type="text"
          v-model="addTitle"
          :placeholder="type == 1 ? '请输入部门名称' : '请输入岗位名称'"
        />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import * as API from "@/api.ts";

export default {
  data() {
    return {
      page: 1,
      limit: 10,
      loading: false, //列表接口是否正在请求
      finished: false, //是否全部加载完
      addShow: false, //添加岗位|部门弹窗显隐
      list: [], //部门列表
      editDepartmentShow: false, //修改岗位名称|部门名称弹窗显隐
      dtitle: "", //输入的部门名称
      did: "", //修改部门名称时选中的id
      pid: "", //父id
      type: "", //1修改部门|添加部门，2修改岗位|添加岗位
      addTitle: "", //添加时的部门或岗位名称
    };
  },
  mounted() {
    this.getDepartment();
  },
  methods: {
    // 上拉加载更多
    onLoad() {
      this.page++;
      this.getDepartment();
    },
    // 获取部门列表
    async getDepartment() {
      let { page, limit, list } = this;
      let params = {
        page,
        limit,
      };
      let { code, data } = await API.sectionIndex(params);
      if (code == 1) {
        let _list = data.rows;
        if (page == 1) {
          list = _list;
        } else {
          list.push(..._list);
        }
        if (_list.length == 0) {
          this.finished = true;
        }
        this.list = list;
        this.loading = false;
      }
    },
    // 确认修改
    async editDepartmentConfirm() {
      let { dtitle: dtitle, did: ids, pid } = this;
      let params = {
        title: dtitle,
        pid, //父id
        ids,
      };
      let { code, msg } = await API.sectionEdit(params);
      this.$toast(msg);
      if (code == 1) {
        this.page = 1;
        this.getDepartment();
      }
    },
    // 修改部门名称&岗位名称
    async sectionEdit(item, type) {
      let { title: dtitle, id: did, pid } = item;
      this.dtitle = dtitle;
      this.did = did;
      this.type = type;
      this.pid = pid;
      this.editDepartmentShow = true;
    },
    // 删除岗位|部门
    sectionDel(item) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否确认删除“" + item.title + "”？",
        })
        .then(async () => {
          let { id: ids } = item;
          let params = {
            ids,
          };
          let { code, msg } = await API.sectionDel(params);
          this.$toast(msg);
          if (code == 1) {
            this.page = 1;
            // 获取部门列表
            this.getDepartment();
          }
        })
        .catch(() => {});
    },
    // 添加部门&岗位弹窗
    sectionAdd(item, type) {
      this.addTitle = "";
      this.dtitle = "";
      let { id: pid } = item;
      this.pid = pid || "0";
      this.addShow = true;
      this.type = type;
    },
    // 添加部门&岗位
    async addConfirm() {
      let { pid, addTitle: title } = this;
      let params = {
        pid,
        title,
      };
      let { code, msg } = await API.sectionAdd(params);
      this.$toast(msg);

      if (code == 1) {
        this.page = 1;
        // 获取部门列表
        this.getDepartment();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  .list {
    padding: 0.3rem 0;
    box-sizing: border-box;
    .title {
      font-size: 0.32rem;
      color: #111111;
    }
    .edit-log {
      width: 0.33rem;
      height: 0.33rem;
    }
    .delete-log {
      width: 0.29rem;
      height: 0.33rem;
    }
    .sm-add-list {
      border: 0.01rem dashed #ffabab;
      border-radius: 0.08rem;
      font-size: 0.24rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ff8787;
      padding: 0.15rem 0.25rem;
    }
    .sm-list {
      background: rgba(249, 249, 249, 0);
      border: 0.01rem solid #ffabab;
      border-radius: 0.08rem;
      flex-wrap: wrap;
      .sm-title {
        font-size: 0.28rem;
        color: #111111;
        padding: 0.15rem 0.25rem;
        box-sizing: border-box;
      }

      .sm-addchild {
        width: 0.18rem;
      }
      .sm-add-text {
        font-size: 0.24rem;
        font-weight: 500;
        color: #ff8787;
      }
      .sm-list-edit-border {
        //   padding: 0.18rem  .15rem;
        //   border-right: 0.01rem solid #ffabab;
        //   border-left: 0.01rem solid #ffabab;
      }
    }
  }
}
.bottom-box {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0.2rem;
  box-sizing: border-box;
  background: #fff;
  left: 0;
  .btn {
    width: 100%;
    height: 0.9rem;
    background: #d71518;
    border-radius: 0.16rem;
    font-size: 0.34rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
}
</style>