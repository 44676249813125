<template>
  <div class="overdraget_person" style="background:white;height:100vh">
    <div class="overdraget_person_content">
      <div class="overdraget_person_content_box">
        <div class="overdrage_person_content_header">
          <publicNavigation @sureEvenet="sureEvenet" :data="navigation" />
        </div>
        <div class="overdrage_content_list" >
          <div class="overdrage_content_data" >
            <button
              :class="currentIndex == k ? 'active' : ''"
              @click="overdrageClick(s, k)"
              v-for="(s, k) in overdrageData"
              :key="k"
            >
              {{ s }}
            </button>
          </div>
          <div class="overdrage_stolp" v-if="currentIndex == 1">
            <div
              class="oberdrage_stolpe_list"
              @click="stolpeEventClick(s, k)"
              v-for="(s, k) in overdrageDataStolpe"
              :key="k"
            >
              <span>{{ s.title }}</span>
              <img v-if="stoplpeCurrentIndex == k" :src="icon" alt="" />
            </div>
          </div>
          <div class="overdrage_stolp" v-if="currentIndex == 2">
            <div
              class="oberdrage_stolpe_list"
              @click="personClick(s, k)"
              v-for="(s, k) in personData"
              :key="k"
            >
              <span>{{ s.name }}</span>
              <img v-if="personIndex == k" :src="icon" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import publicNavigation from "@/publicNavigation/publicNavigation.vue";
import * as API from "@/api.ts";
export default {
  components: {
    publicNavigation,
  },
  data() {
    return {
      navigation: {},
      overdrageData: [],
      currentIndex: 0,
      overdrageDataStolpe: [],
      stoplpeCurrentIndex: 0,
      icon: require("@/assets/redsave.png"),
      personIndex: 0,
      personData: [],
      stolpe: "",
      publicStolpe: "",
      personName: "",
      origin: "",
      classfiyData: [],
    };
  },
  mounted() {
    this.navigation = {
      image: require("@/assets/return.png"),
      name: "指派给",
      sure: "确定",
      close: "",
    };
    this.Request();
    if (window.localStorage.getItem("sure")) {
      this.origin = window.localStorage.getItem("sure");
    }
  },
  methods: {
    Request() {
      API.userList().then(async (e) => {
        e.data.rows.map((s) => {
          this.personData.push({
            name: s.nickname,
            id: s.id,
            group_ids: s.group_ids,
          });
        });
      });
      API.section().then(async (e) => {
        if (e.code == 1) {
          this.overdrageDataStolpe = e.data.rows;
        }
      });

      API.checkoprette().then(async (e) => {
        if (e.code == 1) {
          e.data.catch_modality.map((items) => {
            this.overdrageData.push(items.title);
          });
          this.classfiyData = e.data.catch_modality;
        }
      });
    },
    overdrageClick(s, k) {
      if (this.classfiyData[k].title == s) {
        this.stolpe = this.classfiyData[k].value;
        console.log(this.stolpe);
      }
      this.currentIndex = k;
    },
    stolpeEventClick(s, k) {
      this.publicStolpe = s;
      this.stoplpeCurrentIndex = k;
    },
    personClick(s, k) {
      this.personName = s;
      this.personIndex = k;
    },
    sureEvenet() {
      const newdata = {
        stolpe: this.stolpe ? this.stolpe : "1",
        publicStolpe:
          this.publicStolpe && this.currentIndex == 1
            ? this.publicStolpe
            : this.overdrageDataStolpe[0] && this.currentIndex == 1
            ? this.overdrageDataStolpe[0]
            : this.personName
            ? ""
            : "1",
        personName:
          this.personName && this.currentIndex == 2
            ? this.personName
            : this.personData[0] && this.currentIndex == 2
            ? this.personData[0]
            : "",
        specType: this.$route.query.specType ? this.$route.query.specType : "",
      };
      console.log(newdata);
      if (this.$route.query.specify || !this.origin) {
        window.localStorage.setItem("specifty", JSON.stringify(newdata));
      }
      let newObject;
      console.log(this.origin)
      if (this.origin && this.origin != "undefined") {
        newObject = Object.assign(JSON.parse(this.origin), newdata);
      }else{
        newObject = newdata
      }

      if (this.stolpe == "公开" || newdata.publicStolpe || newdata.personName) {
        console.log(newdata);
        if (!newdata.personName) {
          window.localStorage.setItem("specifty", "");
        }
        if (!this.origin) {
          window.localStorage.setItem("sure", "");
        }

        window.localStorage.setItem(
          "sure",
          !!this.origin && this.origin != "undefined"
            ? JSON.stringify(newObject)
            : JSON.stringify(newdata)
        );
        this.$toast.success("选择成功");
        setTimeout(() => {
          this.$router.back(-1);
        }, 800);
      } else {
        this.$toast.fail("请选择指派");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}
.overdraget_person {
  .overdraget_person_content {
    .overdraget_person_content_box {
      .overdrage_person_content_header {
      }
      .overdrage_content_list {
        margin: calcFunction(10);
        .overdrage_content_data {
          @extend .alignItemsCenter;
          justify-content: space-between;
          button {
            width: 32%;
            padding: calcFunction(12) 0;
            font-size: calcFunction(13);
            background: #f1f0f5;
            border: none;
            outline: none;
            border-radius: calcFunction(4);
          }
        }
        .overdrage_stolp {
          margin-top: calcFunction(30);
          .oberdrage_stolpe_list {
            margin-bottom: calcFunction(14);
            padding-bottom: calcFunction(14);
            @extend .alignItemsCenter;
            justify-content: space-between;
            border-bottom: 1px solid #eee;
            span {
              font-size: calcFunction(14);
              color: #111111;
            }
            img {
              width: calcFunction(13);
              height: calcFunction(11);
            }
          }
        }
      }
    }
  }
}

.active {
  background: #ffeaea !important;
  border: 0.5px solid #d71518 !important;
  color: #d71518;
  border-radius: calcFunction(4);
}
</style>