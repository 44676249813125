<template>
	<div class="gift_manage">
		<div class="gift_manage_content">
			<div class="gift_manage_content_box">
				<div class="gift_manage_content_header_navigation">
					<publicNavigation :data="navigation" />
				</div>
				<div class="gift_manage_content_card">
					<div class="gift_manage_card_list">
						<giftmanageCard :giftCard="giftCardData" @productend="productend" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import publicNavigation from '@/publicNavigation/publicNavigation.vue';
import giftmanageCard from './compoment/giftmanageCard.vue'
import * as API from '@/api.ts'
export default {
	components:{
		publicNavigation,
		giftmanageCard
	},
	data(){
		return{
			navigation:{},
			giftCardData:[]
		}
	},
	mounted() {
		this.navigation = {
			image:require('@/assets/return.png'),
			name:'礼品管理'
		}
		this.giftCardData = []
		this.Rquset()
	},
	methods:{
		Rquset(){
			API.Product().then(async e=>{
				if(e.code == 1){
					console.log(e)
					e.data.rows.map(items=>{
						Object.assign(items,{
							status:items.status_text,
							image:items.img,
							title:items.name,
							number:items.reward,
						})
					})
					this.giftCardData = e.data.rows
				}
			})
		},
		productend(e){
			API.productend({
				id:e.id
			}).then(async e=>{
				this.$toast({
					type: e.code == 1 ? 'success' : 'fail',
					message: e.msg
				})
				if(e.code == 1){
					setTimeout(()=>{
						this.Rquset()
					},1000)
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
	.gift_manage{
		height: 100vh;
		background: #F2F3F5;
		.gift_manage_content_header_navigation{
			background: white;
		}
	}
	
</style>