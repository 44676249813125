<!--
 * @Author: Mr.Cheng
 * @Date: 2023-10-10 14:25:06
 * @LastEditTime: 2023-10-26 09:46:08
 * @FilePath: \octopus-factory-map\src\new\auditList\auditListDetail.vue
-->
<template>
  <div>
    <!-- 顶部背景 -->
    <div class="top-bg">
      <!-- 顶部标题 -->
      <div
        style="background: unset; border-bottom: unset"
        class="flex-c-c task-navigate-box"
      >
        <!-- 返回按钮 -->
        <img
          @click="navigateBack"
          class="task-left-back"
          src="@/assets/return.png"
          alt=""
        />
        <div>任务详情</div>
      </div>
      <!-- 任务名&状态 -->
      <div style="align-items: flex-start" class="flex-c-b mt20">
        <div class="task-name">{{ details.PidInfo.title }}</div>
        <!-- <div
          :style="{ color: statusColor(details.PidInfo.status) }"
          class="status_text"
        >
          {{ details.PidInfo.status_text }}
        </div> -->
      </div>
      <!-- 类型&难度指数 -->
      <div class="flex-c mt10">
        <!-- 类型 -->
        <div class="mr40">
          <span class="label">类型：</span>
          <span class="type_text">{{ details.PidInfo.task_type_text }}</span>
        </div>

        <!-- 难度指数 -->
        <div class="flex-c">
          <span class="label">难度：</span>

          <van-rate
            size="15px"
            color="#D71518"
            void-color="rgba(0,0,0,0)"
            :value="Number(details.PidInfo.difficulty_text)"
            readonly
          />
        </div>
      </div>
    </div>

    <!-- 任务基本信息 归属项目~任务附件 -->
    <div class="basicInfo">
      <!-- 归属项目 -->
      <div class="flex-c">
        <div class="label">归属项目：</div>
        <div
          @click="
            $router.push({
              path: '/projectDetail',
              query: { id: details.project.id },
            })
          "
          class="value blue line"
        >
          {{ details.project.title }}
        </div>
      </div>

      <div class="flex-c mt10">
        <!-- 关联任务 -->
        <div style="width: 50%" class="flex-c">
          <div class="label">关联任务：</div>
          <div class="value">{{ details.project.correlation_count }}个</div>
        </div>
        <!-- 指派时间 -->
        <div style="width: 50%" class="flex-c">
          <div class="label">指派时间：</div>
          <div class="value">
            {{ details.PidInfo.createtime_text }}
          </div>
        </div>
      </div>

      <div class="flex-c mt10" style="align-items: flex-start">
        <!-- 发布人 -->
        <div style="width: 50%" class="flex-c">
          <div class="label">发布人：</div>
          <div class="value">
            {{ details.PidInfo.create_nickname }}
          </div>
        </div>
        <!-- 验收人 -->
        <div style="width: 50%; align-items: flex-start" class="flex-c">
          <div class="label">验收人：</div>
          <div style="flex-wrap: wrap" class="value flex-c">
            <div
              style="white-space: nowrap"
              v-for="(item, index) of details.PidInfo.inspect"
              :key="item"
            >
              {{ item }}
              <template v-if="index != details.PidInfo.inspect.length - 1">
                、</template
              >
            </div>
            <div
              @click="
                $router.push({
                  path: '/acceptorPerson',
                  query: {
                    id: details.PidInfo.id,
                  },
                })
              "
              class="blue"
            >
              [更换]
            </div>
          </div>
        </div>
      </div>

      <!-- 验收标准 -->
      <div style="align-items: flex-start" class="flex-c mt10">
        <div class="label">验收标准：</div>
        <div style="white-space: pre-line" class="value">
          {{ details.PidInfo.acceptance_criteria || "无" }}
        </div>
      </div>
      <!-- 任务介绍 -->
      <div style="align-items: flex-start" class="flex-c mt10">
        <div class="label">任务介绍：</div>
        <div style="white-space: pre-line" class="value flex-c">
          {{ details.PidInfo.introduce || "无" }}
          <img
            @click="
              introduce = details.PidInfo.introduce;
              editIntroduceShow = true;
            "
            class="ml5"
            style="width: 0.26rem"
            src="@/assets/edit.png"
            alt=""
          />
        </div>
      </div>
      <!-- 任务附件 -->
      <div style="align-items: flex-start" class="flex-c mt10">
        <div class="label">任务附件：</div>
        <div>
          <div v-if="details.PidInfo.attachment_info.length > 0">
            <div
              class="flex-c mb5"
              v-for="item of details.PidInfo.attachment_info"
              :key="item.id"
            >
              <a
                :href="item.url"
                style="max-width: 5rem; display: block"
                target="blank"
                class="line blue textOver1"
              >
                {{ item.filename }}
              </a>
              <img
                @click="deleteFile(item, 1)"
                class="reddelete"
                src="@/assets/reddelete.png"
                alt=""
              />
            </div>
          </div>
          <div v-else class="value">无</div>
          <van-uploader
            accept="file"
            result-type="file"
            :after-read="uploadingEvent"
            :max-count="1"
            multiple
          >
            <template #default>
              <div class="flex-c">
                <img class="red-add" src="@/assets/appendix.png" alt="" />
                <div class="add-text">添加附件</div>
              </div>
            </template>
          </van-uploader>
        </div>
      </div>
      <!-- 图片 -->
      <div style="align-items: flex-start" class="flex-c mt10">
        <div class="label">图片：</div>
        <div>
          <div v-if="details.PidInfo.taskimg_info.length > 0">
            <div
              class="flex-c mb5"
              v-for="item of details.PidInfo.taskimg_info"
              :key="item.id"
            >
              <a
                :href="item.url"
                target="blank"
                style="max-width: 5rem; display: block"
                class="line blue textOver1"
              >
                {{ item.filename }}
              </a>
              <img
                @click="deleteFile(item, 2)"
                class="reddelete"
                src="@/assets/reddelete.png"
                alt=""
              />
            </div>
          </div>
          <div v-else class="value">无</div>
          <van-uploader
            accept=".jpg,.jpeg,.png"
            result-type="file"
            :after-read="uploadingEventFile"
            :max-count="1"
            multiple
          >
            <template #default>
              <div class="flex-c">
                <img class="red-add" src="@/assets/appendix.png" alt="" />
                <div class="add-text">添加图片</div>
              </div>
            </template>
          </van-uploader>
        </div>
      </div>
    </div>

    <van-tabs
      @change="changeNav"
      v-model="active"
      animated
      title-active-color="#D71518"
    >
      <van-tab title="任务/步骤">
        <!-- getDetail 子调用获取父任务详情 -->
        <!-- list 子获取父任务中任务/步骤列表 -->
        <!-- status 子获取父任务中任务状态 -->
        <!-- dialogTableVisible 奖励点审核弹窗 -->
        <task
          @getDetail="getDetail"
          @editReward="editReward"
          @dialogTableVisible="taskDialogTableVisible"
          :list="details.rows"
          :status="details.PidInfo.status"
          :reward_method="details.PidInfo.reward_method"
        />
      </van-tab>
      <van-tab title="任务验收">
        <!-- list 子获取任务验收列表数据 -->
        <!-- status 子获取父任务中任务状态 -->
        <!-- dialogTableVisible 奖励点审核弹窗 -->
        <taskAccept
          :list="taskAcceptance"
          @dialogTableVisible="taskAcceptDialogTableVisible"
          :status="details.PidInfo.status"
        />
      </van-tab>
      <van-tab title="任务日志">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="logList.length > 0 ? '没有更多了' : ''"
          offset="150"
          @load="onLoad"
        >
          <!-- writePersonShow 填写人筛选弹窗显隐 -->
          <!-- showDate 日期筛选弹窗显隐 -->
          <!-- clearWrite 清除填写人筛选 -->
          <!-- clearDate 清除日期筛选 -->
          <!-- toWriteLog 跳转填写日志页面 -->
          <taskLog
            @writePersonShow="writePersonShow = true"
            @showDate="showDate = true"
            @clearWrite="clearWrite"
            @clearDate="clearDate"
            @toWriteLog="toWriteLog"
            :date="date"
            :list="logList"
            :writePerson="writePerson"
          />
        </van-list>
      </van-tab>
      <van-tab title="任务奖励">
        <singleReward
          ref="singleRewardDOM"
          @correctShow="correctEvent"
          :id="details.PidInfo.id"
        />
      </van-tab>
    </van-tabs>

    <!-- 详情页 -->
    <template>
      <!-- 任务介绍 -->
      <van-dialog
        v-model="editIntroduceShow"
        title="任务介绍"
        show-cancel-button
        @confirm="editIntroduceConfirm"
      >
        <div class="process_pause_title">
          <input type="text" v-model="introduce" placeholder="请输入任务介绍" />
        </div>
      </van-dialog>
    </template>

    <!-- 任务/步骤 -->
    <template>
      <!-- 奖励点审核弹窗 -->
      <van-popup
        closeable
        v-model="singleShow"
        round
        :style="{ height: 'auto' }"
      >
        <div class="review-box">
          <!-- 标题 -->
          <div class="review-box-title">任务奖励</div>
          <!-- 内容 -->
          <!-- 姓名 -->
          <div class="flex-c-b mt10">
            <div class="label">姓名</div>
            <div class="value">{{ reviewList.user_nickname }}</div>
          </div>
          <!-- 难度 -->
          <div class="flex-c-b mt10">
            <div class="label">难度</div>
            <van-rate
              :count="Number(reviewList.difficulty_text)"
              size="15px"
              color="#D71518"
              void-color="rgba(0,0,0,0)"
              :value="Number(reviewList.difficulty_text)"
              readonly
            />
          </div>
          <!-- 工作量 -->
          <div class="flex-c-b mt10">
            <div class="label">工作量</div>
            <div class="value">{{ reviewList.workload }}</div>
          </div>
          <!-- 工期 -->
          <div class="flex-c-b mt10">
            <div class="label">工期</div>
            <div class="value">{{ reviewList.timeout_text }}</div>
          </div>
          <!-- 奖励点 -->
          <div class="flex-c-b mt10">
            <div class="label">奖励点</div>
            <div class="value">
              <div v-if="reviewList.person_type == 2">
                {{ reviewList.reward || "0" }}
              </div>
              <div v-else>
                {{ reviewList.add_reward || "0" }}
              </div>
            </div>
          </div>
          <!-- 额外奖励点 -->
          <div class="flex-c-b mt10">
            <div class="label">额外奖励点</div>
            <div class="value">{{ reviewList.other_reward || 0 }}</div>
          </div>
          <!-- 合计 -->
          <div class="flex-c-b mt10">
            <div class="label">合计</div>
            <div class="value">
              <div v-if="reviewList.person_type == 2">
                {{
                  Number(reviewList.reward) + Number(reviewList.other_reward)
                }}
              </div>
              <div v-else>
                {{
                  Number(reviewList.add_reward) +
                  Number(reviewList.other_reward)
                }}
              </div>
            </div>
          </div>

          <!-- 审核按钮 -->
          <div @click="auditCorrectShow = true" class="autitBtn mt10 flex-c-c">
            审核
          </div>
        </div>
      </van-popup>

      <!-- 奖励点审核 修改-->
      <van-popup
        closeable
        v-model="auditCorrectShow"
        round
        position="bottom"
        :style="{ height: 'auto' }"
      >
        <correct
          @getDetail="
            taskAcceptType ? getCheckLists() : getDetail();
            auditCorrectShow = false;
            singleShow = false;
          "
          :id="taskAcceptType ? details.PidInfo.id : ''"
          :type="1"
          :correctData="reviewList"
        />
      </van-popup>

      <!-- 修改自定义奖励点 -->
      <van-dialog
        v-model="editRewardShow"
        title="修改自定义奖励点"
        show-cancel-button
        @confirm="editRewardConfirm"
      >
        <div class="process_pause_title">
          <input
            type="number"
            v-model="number"
            placeholder="请输入自定义奖励点"
          />
        </div>
      </van-dialog>
    </template>

    <!-- 任务日志 -->
    <template>
      <!-- 填写人 -->
      <van-popup
        v-model="writePersonShow"
        position="bottom"
        round
        :style="{ height: '30%' }"
      >
        <van-picker
          title="填写人"
          show-toolbar
          :columns="inputPersonTotaslArray"
          @confirm="onWriteConfirm"
          @cancel="writePersonShow = false"
        />
      </van-popup>

      <!-- 日期弹窗 -->
      <van-calendar
        :min-date="new Date(2023, 0, 1)"
        v-model="showDate"
        @confirm="onDateConfirm"
      />
    </template>

    <!-- 任务奖励 -->
    <template>
      <van-popup
        closeable
        v-model="correctShow"
        round
        position="bottom"
        :style="{ height: 'auto' }"
      >
        <correct @getReward="getReward" :correctData="correctData" />
      </van-popup>
    </template>
  </div>
</template>

<script>
import task from "@/new/auditList/components/task.vue"; //任务/步骤
import taskAccept from "@/new/auditList/components/taskAccept.vue"; //验收任务
import taskLog from "@/new/auditList/components/taskLog.vue"; //任务日志
import singleReward from "@/new/auditList/components/singleReward.vue"; //任务奖励
import correct from "@/new/components/correct.vue"; //任务奖励点修正
import axios from "axios";
import * as API from "@/api.ts";
export default {
  name: "auditListDetail",
  components: {
    task,
    taskLog,
    taskAccept,
    singleReward,
    correct,
  },
  data() {
    return {
      /**
       * 共用
       */
      taskAcceptType: false, //是否是验收任务的奖励点审核弹窗
      details: {
        PidInfo: {
          title: "",
          taskimg_info: [],
          attachment_info: [],
        },
        project: {
          title: "",
        },
      }, //详情数据
      active: 0, //当前选中栏下标
      editIntroduceShow: false, //修改任务介绍弹窗显隐
      introduce: "", //任务介绍内容

      /**
       * 任务/步骤
       */
      showStopFrom: false, //暂停原因弹窗显隐
      singleShow: false, //奖励点审核弹窗
      auditCorrectShow: false, //奖励点审核弹窗(修改)
      itemData: {}, //暂停详情数据(步骤)
      itemStepData: {}, //暂停详情数据(任务)
      nowDate: "", //点击暂停按钮时的时间
      stopReason: "", //暂停原因
      id: "", //暂停时选中的id
      fType: "", //操作类型
      reviewType: "", //奖励点审核类型
      reviewList: {}, //奖励点审核选中的数据
      editRewardShow: false, //修改自定义奖励点弹窗显隐
      editRewardId: "", //修改自定义奖励点时的id
      number: "", //输入的自定义奖励点

      /**
       * 任务验收
       * */
      taskAcceptance: [], //任务验收列表

      /**
       * 任务日志
       * */
      writePersonShow: false, //填写人弹窗显隐
      showDate: false, //日期弹窗显隐
      inputPersonTotaslArray: [], //填写人弹窗列表
      writePerson: "", //选中的填写人
      date: "", //选中的日期(页面显示)
      start_time: "", //选中的日期(请求参数)
      loading: false,
      finished: false,
      logPage: 1,
      logLimit: 5,
      logList: [], //日志列表

      /**
       * 任务奖励
       * */
      correctShow: false, //任务奖励修正弹窗显隐
      correctData: {}, //修正奖励点选中的数据
    };
  },
  computed: {
    // 任务状态文字颜色
    statusColor() {
      return (e) => {
        let color;
        switch (e) {
          case 0:
            break;
          case 1:
            color = "#D71518";
            break;
          case 2:
            break;
          case 3:
            break;
          case 4:
            color = "#D71518";
            break;
          case 5:
            color = "#00A2FF";
            break;
          case 6:
            color = "#FF4200";
            break;
          case 7:
            color = "#666666";
            break;
          case 8:
            color = "#FF4200";
            break;

          default:
            break;
        }
        return color;
      };
    },
  },
  mounted() {
    this.active = Number(sessionStorage.getItem("newActive"));
    console.log(this.active);
    // 获取详情数据
    this.getDetail();
    //获取填写人列表数据
    this.taskLogUser();
    // 获取任务日志
    this.getTaskLog();
    //获取任务验收列表
    this.getCheckLists();
  },
  methods: {
    // 返回上一页
    navigateBack() {
      this.$router.go(-1);
      sessionStorage.setItem("newActive", "");
    },
    // 修改自定义奖励点(弹窗)
    editReward(e) {
      this.editRewardShow = e.editRewardShow;
      this.editRewardId = e.editRewardId;
    },
    // 修改任务介绍
    async editIntroduceConfirm() {
      let params = {
        ids: this.details.PidInfo.id,
        introduce: this.introduce,
      };
      let { code, msg } = await API.editTask(params);
      this.$toast(msg);
      if (code == 1) {
        this.editIntroduceShow = false;
        // 获取详情数据
        this.getDetail();
      }
    },
    // 修改自定义奖励点
    async editRewardConfirm() {
      let { editRewardId: ids, number: additional_number } = this;
      let params = {
        ids,
        additional_number,
      };
      let { code, msg } = await API.editTask(params);
      this.$toast(msg);
      if (code == 1) {
        // 获取详情数据
        this.getDetail();
      }
    },
    // 删除附件或图片
    deleteFile(e, type) {
      // type:  1：附件，2：图片
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否确认删除？",
        })
        .then(async () => {
          let { id } = e;
          let params = {
            id: this.details.PidInfo.id,
            type,
            img_id: id,
          };
          let { code, msg } = await API.deleteImg(params);
          this.$toast(msg);
          if (code == 1) {
            // 获取详情数据
            this.getDetail();
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    // 添加附件
    uploadingEvent(file) {
      this.uploadFile(file, 1);
    },
    // 添加图片
    uploadingEventFile(file) {
      this.uploadFile(file, 2);
    },
    // 上传文件
    async uploadFile(params, type) {
      let data = new FormData();
      let config = {
        //添加请求头
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      data.set("file", params.file);
      let res = await axios.post("/admin/ajax/upload", data, config);
      if (res.data.code == 1) {
        let url =
          type == 1
            ? [...this.details.PidInfo.attachmentUrl_text, res.data.data.url]
            : [
                ...this.details.PidInfo.AttachmentImgUrl_text,
                res.data.data.url,
              ];
        let data = {
          attachment: url.join(","),
          type,
          id: this.details.PidInfo.id,
        };
        let { code, msg } = await API.addImage(data);
        this.$toast(msg);
        if (code) {
          // 获取详情数据
          this.getDetail();
        }
      }
    },
    // 获取详情数据
    async getDetail() {
      let params = {
        ids: this.$route.query.id,
      };
      let { code, data } = await API.details(params);
      if (code == 1) {
        this.details = data;
      }
    },
    //获取任务验收列表
    async getCheckLists() {
      let params = {
        task_id: this.$route.query.id,
      };
      let { code, data } = await API.projectlists(params);
      if (code == 1) {
        this.taskAcceptance = data.rows;
      }
    },

    //获取填写人列表数据
    taskLogUser() {
      API.taskLogUser({
        id: this.$route.query.id,
        type: 1,
      }).then(async (e) => {
        if (e.code == 1) {
          this.inputPersonTotaslArray = e.data.map((item) => {
            return item.nickname;
          });
          this.inputPersonTotaslArray.unshift("全部");
        }
      });
    },
    // 确定填写人
    onWriteConfirm(value) {
      this.writePerson = value;
      this.writePersonShow = false;
      this.logPage = 1;
      this.loading = false;
      this.finished = false;
      this.logList = [];
      this.getTaskLog();
    },
    // 确定日期
    onDateConfirm(value) {
      this.date = this.$utils.getDate(value);
      this.start_time = value.setHours(0, 0, 0, 0) / 1000;
      this.showDate = false;
      this.logPage = 1;
      this.loading = false;
      this.finished = false;
      this.logList = [];
      this.getTaskLog();
    },
    // 获取任务日志
    async getTaskLog() {
      this.loading = true;
      let {
        logPage: page,
        logLimit: limit,
        writePerson,
        start_time,
        logList: list,
      } = this;
      let params = {
        id: this.$route.query.id,
        page,
        limit,
        user_name: writePerson == "全部" ? "" : writePerson,
        start_time,
      };
      let { code, data } = await API.taskLog(params);
      if (code == 1) {
        let _list = data.lists.rows;
        if (page == 1) {
          list = _list;
        } else {
          list.push(..._list);
        }
        if (_list.length == 0) {
          this.finished = true;
        }
        this.logList = list;
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    // 清除填写人
    clearWrite() {
      this.writePerson = "";
      this.logPage = 1;
      this.loading = false;
      this.finished = false;
      this.logList = [];
      this.getTaskLog();
    },
    // 清除日期
    clearDate() {
      this.date = "";
      this.start_time = "";
      this.logPage = 1;
      this.loading = false;
      this.finished = false;
      this.logList = [];
      this.getTaskLog();
    },
    //加载更多
    onLoad() {
      if (!this.finished) {
        this.logPage++;
        // 获取计划列表
        this.getTaskLog();
      }
    },
    // 跳转至填写日志页
    toWriteLog() {
      sessionStorage.setItem("newActive", this.active);
      this.$router.push({
        path: "/writeLog",
        query: {
          id: this.details.PidInfo.id,
          task: this.details.PidInfo.title,
        },
      });
    },
    // 修正奖励点弹窗
    correctEvent(item) {
      this.correctShow = true;
      this.correctData = item;
    },
    // 栏目切换
    changeNav(value) {
      switch (value) {
        case 1:
          //获取任务验收列表
          this.getCheckLists();
          break;
        case 2:
          // 获取任务日志
          this.getTaskLog();
          break;
        case 3:
          // 获取任务奖励
          this.$refs.singleRewardDOM && this.$refs.singleRewardDOM.getList();
          break;

        default:
          break;
      }
    },
    // 获取任务奖励
    getReward() {
      // 获取任务奖励
      this.correctShow = false;
      console.log(this.correctShow);
      this.$refs.singleRewardDOM && this.$refs.singleRewardDOM.getList();
    },
    // 奖励点审核（任务/步骤）
    taskDialogTableVisible(item) {
      this.singleShow = true;
      let { type, rewardList } = item;
      this.reviewType = type;
      this.reviewList = rewardList[0];
      this.taskAcceptType = false;
    },
    // 奖励点审核（任务验收）
    taskAcceptDialogTableVisible(item) {
      this.singleShow = true;
      let { type, rewardList } = item;
      this.reviewType = type;
      this.reviewList = rewardList[0];
      this.taskAcceptType = true;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-tabs__wrap {
  position: sticky !important;
  top: 0 !important;
  z-index: 500 !important;
}
// 删除按钮
.reddelete {
  height: 0.32rem;
  margin-left: 0.1rem;
}
// 添加按钮
.red-add {
  height: 0.32rem;
  margin-right: 0.1rem;
}
// 添加附件|添加图片
.add-text {
  font-size: 0.24rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #d71518;
}
// 顶部背景
.top-bg {
  padding: 0 0.4rem;
  box-sizing: border-box;
  width: 100vw;
  height: 3.8rem;
  background: linear-gradient(0deg, #feefef 10%, #ffffff 87%);
  // 任务名称
  .task-name {
    font-size: 0.3rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #111111;
  }
  // 任务状态文字
  .status_text {
    font-size: 0.28rem;
    font-family: PingFang SC;
    font-weight: 500;
    white-space: nowrap;
    line-height: 0.5rem;
  }
}

.label {
  font-size: 0.28rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  white-space: nowrap;
}
.value {
  font-size: 0.28rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333;
}
// 下划线
.line {
  text-decoration: underline;
}
.blue {
  color: #375394;
}
// 任务类型值
.type_text {
  font-size: 0.28rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #111111;
}
// 任务基本信息 归属项目~任务附件
.basicInfo {
  padding: 0.4rem 0.38rem;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0 -0.05rem 0.08rem 0 rgba(0, 0, 0, 0.03);
  border-radius: 0.32rem 0.32rem 0 0;
  margin-top: -1rem;
}
// 奖励点审核弹框
.review-box {
  padding: 0.3rem;
  width: 5rem;
  box-sizing: border-box;
  background: #fff;
  // 标题
  .review-box-title {
    font-size: 0.28rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333;
    padding-bottom: 0.2rem;
    border-bottom: 0.01rem solid #feefef;
  }
  // 审核按钮
  .autitBtn {
    width: 100%;
    height: 0.7rem;
    background: #d71518;
    font-size: 0.26rem;
    font-family: PingFang SC;
    color: #ffffff;
    border-radius: 0.04rem;
  }
}
</style>