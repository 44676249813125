<template>
	<div class="introducere">
		<div class="introducere_description">
			<div class="introducere_description_title">
				<div class="description_title">
					<div class="title">
						<span>项目简介：</span>
					</div>
					<div class="description">
						<div class="description_list">
							<span>{{introduce.introduce}}</span>
							<img src="../../assets/edit.png" v-if="!offsetover" alt="" />
						</div>

					</div>
				</div>
				<div class="description_projekt_list">
					<div class="description_projekt">
						<div class="projekt_box">
							<div class="projekt_title">
								<span>项目附件：</span>
							</div>
							<div class="projekt_list">
								<div class="projekt_each_box" v-for="s,k in fileList" :key="k">
									<span>{{s.name}}</span>
									<img src="../../assets/delete.png" @click="attachmentClick(s,k)" v-if="!offsetover" alt="" />
								</div>
								<div class="projekt_add_file" v-if="!offsetover">
									<div class="trin_button_uploding">
										<div class="trin_button_uplodings">
											<van-uploader :after-read="onafterprint">
												<van-button icon="plus" type="primary">上传文件</van-button>
											</van-uploader>
										</div>
									</div>
									<img src="../../assets/error.png" alt="" />
									<span>添加附件</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="description_projekt_list">
					<div class="description_projekt">
						<div class="projekt_box">
							<div class="projekt_title">
								<span>项目图片：</span>
							</div>
							<div class="projekt_list">
								<div class="projekt_each_box" v-for="s,k in files" :key="k">
									<span>{{s.name}}</span>
									<img src="../../assets/delete.png" @click="spliceEvent(s,k)" v-if="!offsetover" alt="" />
								</div>
								<div class="projekt_add_file" v-if="!offsetover">
									<div class="trin_button_uploding">
										<div class="trin_button_uplodings">
											<van-uploader :after-read="onfile">
												<van-button icon="plus" type="primary">上传文件</van-button>
											</van-uploader>
										</div>
									</div>
									<img src="../../assets/error.png" alt="" />
									<span>添加附件</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="description_projekt_list">
					<div class="description_projekt">
						<div class="projekt_box">
							<div class="projekt_title">
								<span>项目任务：</span>
							</div>
							<div class="projekt_list">
								<div class="projekt_each_box custom_color">
									<span>{{introduce.correlation_count}}个</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import axios from 'axios'
	export default {
		props: {
			offsetover: Number,
			introduce: Object,
		},
		data(){
			return{
				fileList:[],
				files:[]
			}
		},
		mounted() {
			if(this.$props?.introduce){
				this.fileList = this.$props.introduce.attachment_text;
				this.files = this.$props.introduce.img_text;
			}
			
		},
		methods:{
			spliceEvent(e,s){
				this.$emit('spliceEvent',e,s)
			},
			attachmentClick(e,s){
				this.$emit('attachmentClick',e,s)
			},
			onafterprint(file) {
				this.fileList.push(file.file.name)
				let data = new FormData();
				data.append("file", file.file);
				let config = {
					//添加请求头 
					headers: {
						"Content-Type": "multipart/form-data"
					},
				};
				axios.post('/admin/ajax/upload', data, config).then(async e => {
					if (e.data.status == 203) {
						this.$notfiy.confirm({
							title: '请重新登录',
							message: '登录已失效，请重新登录'
						}).then(() => {
							setTimeout(() => {
								window.location.href = '/'
							}, 800);
						}).catch(() => {
							this.$toast({
								type: 'fail',
								message: '取消登录'
							})
						})
					}
					if (e.data.status == 200) {
						this.$toast({
							type: 'success',
							message: '上传成功'
						})
					}
				})
				console.log(this.fileList)
			},
			onfile(file){
				this.files.push(file.file.name)
				let data = new FormData();
				data.append("file", file.file);
				let config = {
					//添加请求头 
					headers: {
						"Content-Type": "multipart/form-data"
					},
				};
				axios.post('/admin/ajax/upload', data, config).then(async e => {
					if (e.data.status == 203) {
						this.$notfiy.confirm({
							title: '请重新登录',
							message: '登录已失效，请重新登录'
						}).then(() => {
							setTimeout(() => {
								window.location.href = '/'
							}, 800);
						}).catch(() => {
							this.$toast({
								type: 'fail',
								message: '取消登录'
							})
						})
					}
					if (e.data.status == 200) {
						this.$toast({
							type: 'success',
							message: '上传成功'
						})
					}
				})
			}
		}
	}
</script>
<style lang="scss" scoped>
	// 继承变量
	.alignItemsCenter {
		display: flex;
		align-items: center;
	}

	// 计算函数
	@function calcFunction($number, $default: 16) {
		@return calc($number * 2 / 100) + rem;
	}

	.introducere {
		background: white;
		border-top-left-radius: calcFunction(10);
		border-top-right-radius: calcFunction(10);
		margin-top: calcFunction(20);
		padding-top: calcFunction(20);
		padding-left: calcFunction(20);
		padding-right: calcFunction(20);

		.introducere_description {
			.introducere_description_title {
				.description_title {
					@extend .alignItemsCenter;
					align-items: flex-start;
					width: 100%;

					.title {
						span {
							color: #666666;
							font-size: calcFunction(14);
						}
					}

					.description {
						margin-left: calcFunction(2);
						@extend .alignItemsCenter;
						flex: 1;
						.description_list {
							@extend .alignItemsCenter;
							justify-content: space-between;
							width: 100%;
							span {
								color: #333333;
								font-size: calcFunction(14);
							}
							img {
								width: calcFunction(15);
								height: calcFunction(15);
							}
						}
					}
				}

				.description_projekt_list {
					width: 100%;
					margin-top: calcFunction(20);

					.description_projekt {
						.projekt_box {
							@extend .alignItemsCenter;
							align-items: flex-start;
							justify-content: space-between;

							.projekt_title {
								span {
									color: #666666;
									font-size: calcFunction(14);
								}
							}

							.projekt_list {
								width: 78.2%;

								.projekt_each_box {
									@extend .alignItemsCenter;
									justify-content: space-between;
									width: 100%;
									margin-bottom: calcFunction(10);
									span {
										font-size: calcFunction(14);
										color: #375394;
										text-decoration: underline;
									}

									img {
										width: calcFunction(15);
										height: calcFunction(20);
									}
								}

								.projekt_add_file {
									@extend .alignItemsCenter;
									img {
										width: calcFunction(14);
										height: calcFunction(14);
									}

									span {
										font-size: calcFunction(14);
										margin-left: calcFunction(5);
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.custom_color {
		span {
			color: #333333 !important;
			text-decoration: none !important;
		}
	}
	.projekt_add_file{
		position: relative;
	}
	
	.trin_button_uploding{
		.trin_button_uplodings{
			position: absolute;
			opacity: 0;
		}
	}
</style>