<template>
	<div class="check_order_list">
		<div class="check_order_list_description">
			<div class="order_list_description">
				<div class="order_list_navigation">
					<div class="order_list_navigation_box">
						<publicNavigationVue :data="navigation" />
					</div>
					<div class="order_list_for">
						<div class="check_order_for" v-for="s,k in checkOrderData" :key="k">
							<div class="check_order_number">
								<div class="order_no">
									<span><i>订单号:</i> {{s.order_no}}</span>
									<span :style="s.status_text == '已完成' ? 'color:#999;' : ''">{{s.status}}</span>
								</div>
								<div class="order_user_infomation">
									<div class="order_image">
										<div class="image">
											<img :src="s.image" alt="">
										</div>
										<div class="text_description">
											<div class="descirpiton_person">
												<div class="person_check">
													<div class="projec_name">
														<span>{{s.project}}</span>
													</div>
													<div class="person_name">
														<span>兑换人：{{s.name}}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import publicNavigationVue from "@/publicNavigation/publicNavigation.vue"
import * as API from '@/api.ts'
export default {
	components:{
		publicNavigationVue
	},
	data(){
		return{
			navigation:{},
			checkOrderData:[]
		}
	},
	mounted() {
		this.navigation = {
			image:require('@/assets/return.png'),
			name:'订单列表'
		}
		this.Rquset()
	},
	methods:{
		Rquset(){
			API.Adminorder({
				id:this.$route.query.e.id
			}).then(async e=>{
				if(e.code == 1){
					console.log(e)
					e.data.rows.map(items=>{
						Object.assign(items,{
							order_no:items.order_no,
							image:this.$route.query.e.img,
							name:items.user_name,
							status:items.status_text,
							project:items.content
						})
					})
					this.checkOrderData = e.data.rows
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
	// 继承变量
	.alignItemsCenter {
		display: flex;
		align-items: center;
	}
	
	// 计算函数
	@function calcFunction($number, $default: 16) {
		@return calc($number * 2 / 100) + rem;
	}
	.check_order_list{
		background: #fafafa;
		.check_order_list_description{
			.order_list_description{
				.order_list_navigation{
					.order_list_navigation_box{
						position: fixed;
						background: white;
						top: 0;
						width: 100%;
					}
					.order_list_for{
						padding-top: calcFunction(85);
						.check_order_for{
							background: white;
							margin: calcFunction(10);
							padding: calcFunction(10);
							.check_order_number{
								.order_no{
									@extend .alignItemsCenter;
									justify-content: space-between;
									padding-bottom: calcFunction(10);
									span{
										font-size: calcFunction(14);
										color: #999;
									}
									span:nth-last-child(1){
										color: #D71518;
									}
								}
								.order_user_infomation{
									.order_image{
										@extend .alignItemsCenter;
										.image{
											width: calcFunction(70);
											height: calcFunction(70);
											border: 1px solid #ddd;
											padding: calcFunction(10);
											border-radius: calcFunction(4);
											img{
												width: 100%;
												height: 100%;
												border-radius: calcFunction(4)
											}
										}
										.text_description{
											margin-left: calcFunction(5);
											.descirpiton_person{
												.person_check{
													@extend .alignItemsCenter;
													flex-direction: column;
													height: calcFunction(88);
													justify-content: space-between;
													align-items: flex-start;
													.projec_name{
														span{
															font-size: calcFunction(15);
														}
													}
													.person_name{
														margin-bottom: calcFunction(8);
														span{
															font-size: calcFunction(14);
															color: #333;
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	
	i{
		font-style: normal;
		color: black;
	}
</style>