<template>
    <div class="solve_bug_input">
        <div class="solve_bug_input_content">
            <div class="solve_bug_input_content_header">
                <div class="solve_input_header">
                    <publicNavigation :data="navigation" />
                </div>
                <div class="solve_select_list">
                    <div class="solve_select">
                        <div class="solve_select_list_content">
                            <div class="select_content" v-if="!tildele" @click="solveFunction">
                                <div class="select_text">
                                    <span>解决方案</span>
                                    <span>{{functions || '请选择解决方案'}}</span>
                                </div>
                                <div class="select_right">
                                    <img src="@/assets/blackarrow.png" alt="" />
                                </div>
                            </div>
                            <div class="select_content" @click="timeFunction" v-if="!tildele">
                                <div class="select_text">
                                    <span>解决日期</span>
                                    <span>{{ timeShowText || '请选择日期' }}</span>
                                </div>
                            </div>
                            <div class="select_content" @click="assignFunction">
                                <div class="select_text">
                                    <span>指派给</span>
                                    <span>{{assignPerson || '请选择指派给谁'}}</span>
                                </div>
                                <div class="select_right">
                                    <img src="@/assets/blackarrow.png" alt="" />
                                </div>
                            </div>
                            <div class="select_content custom_select_style">
                                <div class="select_text custom_textarea">
                                    <span>备注</span>
                                    <textarea name="" maxlength="200" v-model="inputNumbers" placeholder="请输入备注" id="" cols="30" rows="10"></textarea>
                                </div>
                                <div class="input_number">
                                    <span><i>{{ inputNumber }}</i>/200</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fixed_buttom">
            <div class="fixed_button_buttom">
                <button @click="SubmitEvent">提交</button>
            </div>
        </div>
        <div class="select_show_popup_model">
            <van-popup v-model="popupShow" position="bottom" :style="{ height: '40%' }" > 
                <van-picker
                    show-toolbar
                    :columns="columns"
                    @confirm="onConfirm"
                    @cancel="onCancel"
                    />
            </van-popup>
            <van-popup v-model="timeShow" position="bottom" :style="{ height: '40%' }" > 
                <van-datetime-picker
                    v-model="timeInput"
                    type="date"
                    title="选择年月日"
                    :min-date="minDate"
                    @confirm="confirm"
                    />
            </van-popup>
            
        </div>
    </div>
</template>
<script>
import publicNavigation from '@/publicNavigation/publicNavigation.vue';
import * as API from '@/api.ts'
export default {
    components:{
        publicNavigation
    },
    data(){
        return{
            navigation:Object,
            columns: [],
            popupShow:false,
            inputNumber:0,
            inputNumbers:'',
            tildele:'',
			assignPerson:'',
			assignPeronObjcet:{},
            timeInput:'',
            timeShow:false,
            minDate: new Date(),
            currentDate: new Date(),
            timeStamp:0,
            functions:'',
            timeShowText:'',
            taskID:''
        }
    },
    watch:{
        inputNumbers(e){
            this.inputNumber = e.split('').length
        }
    },
    mounted(){
        this.tildele = this.$route.query.tildele
        this.taskID = this.$route.query.id
        this.navigation = {
            image:require('../../assets/return.png'),
            name:!this.tildele ? '解决' : '指派',
			close:''
        }
        this.Request()
        if(window.localStorage.getItem('sure') || window.localStorage.getItem('specifty')){
            this.assignPeronObjcet = window.localStorage.getItem('specifty') ? JSON.parse(window.localStorage.getItem('specifty')) : JSON.parse(window.localStorage.getItem('sure'));
            this.assignPerson = JSON.parse(window.localStorage.getItem('sure')).publicStolpe ? JSON.parse(window.localStorage.getItem('sure')).publicStolpe.title : JSON.parse(window.localStorage.getItem('sure')).personName.name;
            console.log(this.assignPeronObjcet,window.localStorage.getItem('sure',window.localStorage.getItem('specifty')))
        }
    },
    methods:{
        Request(){
            API.solutionList().then(async e=>{
                console.log(e)
                if(e.code == 1){
                    e.data.map(item=>{
                        this.columns.push(item.title)
                    })
                }
            })
        },
        timeFunction(){
            this.timeShow = !this.timeShow
        },
        solveFunction(){
            this.popupShow = !this.popupShow
        },
        onConfirm(e){
            this.functions = e
            this.solveFunction()
        },
        confirm(e){
            this.timeStamp = new Date(e)*1
            this.timeFunctions(this.timeStamp)
            this.timeFunction()
        },
        onCancel(){
            this.solveFunction()
        },
        timeFunctions(e){
            const date = new Date(e),
                Y = date.getFullYear(),
                M = date.getMonth(),
                R = date.getDate();
            this.timeShowText = Y + '-' + (M < 10 ? '0' + (M + 1) : M + 1) + '-' + (R < 10 ? '0' + R : R);
        },
		assignFunction(){
			this.$router.push({
				name:'overdragetPerson',
				path:'/overdragetPerson'
			})
		},
		SubmitEvent(){
			const parms = {
				id:this.taskID,
				assign_id:this.assignPeronObjcet.personName ? this.assignPeronObjcet.personName.id : this.assignPeronObjcet.publicStolpe.id,
                solution:this.functions ? this.functions : '',
                solutiontime:this.timeStamp ? this.timeStamp : '',
                notes:this.inputNumbers
			}
			if(this.tildele == 1){
				API.appoint(parms).then(async e=>{
					this.$toast({
						type:e.code == 0 ? 'dange':'success',
						message:e.msg
					})
					if(e.code == 1){
						setTimeout(()=>{
							this.$router.back(-1)
						}, 1000);
					}
				})
			}else{
                if(parms?.id && parms?.assign_id && parms?.solution && parms?.solutiontime){
                    API.solve(parms).then(async e=>{
                        if(e.code == 1){
                            console.log(e)
                            this.$toast({
                                type:e.code == 0 ? 'fail' : 'success',
                                message:e.msg
                            })
                        }
                    })
                }else{
                    this.$toast.fail('请注意信息不能为空')
                }
            }
		}
    }

}
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter{
    display: flex;
    align-items: center;
}
  
// 计算函数
@function calcFunction($number,$default:16){
  @return calc($number * 2 / 100) + rem;
}


.solve_bug_input{
    .solve_bug_input_content{
        .solve_bug_input_content_header{
            .solve_input_header{

            }
            .solve_select_list{
                .solve_select{
                    padding: 0 calcFunction(10);
                    .solve_select_list_content{
                        .select_content{
                            @extend .alignItemsCenter;
                            justify-content: space-between;
                            padding: 0 calcFunction(15);
                            padding-bottom: calcFunction(20);
                            border-bottom: 1px solid #DCDCDC;
                            margin-top: calcFunction(20);
                            .select_text{
                                @extend .alignItemsCenter;
                                width: 100%;
                                span{
                                    color: #111111;
                                    font-size: calcFunction(14);
                                }
                                span:nth-last-child(1){
                                    color: #999999;
                                }
                                span:nth-child(1){
                                    width: 20%;
                                    display: block;
                                }
                            }
                            .select_right{
                                img{
                                    width: calcFunction(6);
                                    height: calcFunction(12);
                                }
                            }
                        }
                        .select_content:nth-child(1){
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}

.custom_textarea{
    align-items: flex-start!important;
    textarea{
        border: none;
        font-size: calcFunction(14);
        &::placeholder{
            color: #999999;
        }
    }
}
.input_number{
    text-align: right;
    @extend .alignItemsCenter;
    justify-content: flex-end;
    width: 100%;
    span{
        font-size: calcFunction(14);
        color: #999999;
        i{
            font-style: normal;
            color: #D71518;
        }
    }
}

.custom_select_style{
    flex-direction: column;
}


.fixed_buttom{
    position: fixed;
    bottom: 0;
    width: 100%;
    background: white;
    .fixed_button_buttom{
        padding: calcFunction(5) calcFunction(10);
        padding-bottom: calcFunction(15);
        button{
            background: #FFB5B6;
            width: 100%;
            color: white;
            border: none;
            outline: none;
            border-radius: calcFunction(6);
            padding: calcFunction(12) 0;
        }
    }
}
</style>