<template>
	<div class="basic_reward">
		<div class="basic_reward_content">
			<div class="basic_reward_content_list">
				<div class="basic_reward_for">
					<div class="basic_rewards" v-for="s,k in basicReward" :key="k">
						<div class="basic_reward_title">
							<div class="reward_title">
								<span>{{s.name}}</span>
							</div>
							<div class="reward_list_content">
								<div class="reward_content" style="width: 40px;display:flex;align-items: center;justify-content: center;">
									<span>{{s.text}}</span>
								</div>
								<div class="reward_content_official">
									<div class="official">
										<div class="official_content" v-for="i,l in s.children" :key="l">
											<div class="content">
												<span>{{i.name}}</span>
												<span>{{i.text}}</span>
											</div>
											<div v-if="l == 0 && k == 0 && typeNumber == '1' && i.name != '--'" style="margin:0 .2rem;font-size: 1.1rem;margin-top: -.5rem;">+</div>
											<div v-if="l == 1 && k == 0 || l == 0 && k == 0 && typeNumber == 2" style="margin:0 .2rem;font-size: 1.1rem;margin-top: -.5rem;">x</div>
											<div v-if="l == 0 && k == 1 && typeNumber == '1' && i.name != '--'" style="margin:0 .2rem;font-size: 1.1rem;margin-top: -.5rem;">x</div>
											<!-- <div v-if="l == 1 && k == 1" style="margin:0 .2rem;font-size: 1.1rem;margin-top: -.5rem;">/</div> -->
											<div v-if="l == 0 && k == 2 && typeNumber == '1' && i.name != '修正奖励点' || l == 0 && k == 3 && i.text != '奖励点' || l == 1 && k == 3 && i.text != '奖励点'" style="margin:0 .2rem;font-size: 1.1rem;margin-top: -.5rem;">+</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			basicReward:Array,
			userID:String,
			typeNumber:String
		}
	}
</script>

<style lang="scss" scoped>
	.basic_reward{
		margin: 0 1rem;
		margin-top: 1.25rem;
		.basic_reward_content{
			.basic_reward_content_list{
				.basic_reward_for{
					.basic_rewards{
						border-bottom: 1px solid #ddd;
						border-right: 1px solid #ddd;
						border-left: 1px solid #ddd;
						.basic_reward_title{
							display: flex;
							align-items: center;
							height: 5rem;
							.reward_title{
								background: #EEEEEE;
								height: 100%;
								width: 26%;
								display: flex;
								align-items:center;
								justify-content:center;
								span{
									font-size: .875rem;
									font-weight: 800;
								}
							}
							.reward_list_content{
								display: flex;
								flex:1;
								padding-right: 0;
								height: 100%;
								.reward_content{
									padding: 0 .7rem;
									height: 100%;
									border-right: 1px solid #ddd;
									display: flex;
									align-items: center;
									span{
										font-size: .875rem;
									}
								}
								.reward_content_official{
									width: 100%;
									height: 100%;
									.official{
										width: 100%;
										display: flex;
										align-items:center;
										justify-content: center;
										height: 100%;
										.official_content{
											display: flex;
											align-items: center;
											.content{
												span{
													display: flex;
													flex-direction: column;
													align-items:center;
													font-size: .7rem;
												}
											}
										}
									}
								}
							}
						}
					}
					.basic_rewards:nth-child(1){
						border-top: 1px solid #ddd;
					}
				}
			}
		}
	}
</style>