<template>
  <div class="udveksle">
    <div class="udveksle_header">
      <div class="bcakground">
        <publicNavigation :data="navigation" />
        <!-- 搜索框 -->
        <van-search
          v-model="order_on"
          shape="round"
          background="#FFF"
          placeholder="输入项目订单号查询"
          @search="onSearch"
          @clear="onClear"
        />
        <!-- 兑换选择 -->
        <van-tabs
          v-model="active"
          title-inactive-color="#333333"
          title-active-color="#D71518"
          @click="eventClick"
        >
          <van-tab title="现金兑换记录"></van-tab>
          <van-tab title="礼品兑换记录"></van-tab>
          <van-tab title="CP值兑换记录"></van-tab>
        </van-tabs>
        <div class="select_tabs">
          <div
            class="select_tiem"
            :style="currentIndex == 1 || currentIndex == 2 ? '' : 'width:50%'"
          >
            <div @click="timeshowFunction" v-if="start_time">
              <div>{{ $utils.getDate(new Date(start_time * 1000)) }}~</div>
              <div>{{ $utils.getDate(new Date(end_time * 1000)) }}</div>
            </div>
            <div @click="timeshowFunction" v-else>选择时间</div>
            <img
              v-if="!start_time"
              :src="require('@/assets/rightFill.png')"
              alt=""
            />
            <img
              v-if="start_time"
              @click="deleteTimeFunction"
              class="iamge_rotate"
              :src="require('@/assets/clear.png')"
              alt=""
            />
          </div>
          <div class="slect_tabs_content">
            <van-dropdown-menu active-color="#D71518">
              <van-dropdown-item
                @open="openPersonFunction"
                @close="closePersonFunction"
                v-model="user_id"
                :options="staffList"
                @change="surePersonChange"
              />
              <van-dropdown-item
                @open="openGiftFunction"
                @close="closeGiftFunction"
                v-if="currentIndex == 1"
                v-model="product_id"
                :options="productList"
                @change="sureGiftChange"
              />
              <van-dropdown-item
                @open="openProjectFunction"
                @close="closeProjectFunction"
                @change="sureProjectFunction"
                v-if="currentIndex == 2"
                v-model="project_id"
                :options="projectList"
              />
            </van-dropdown-menu>
          </div>
        </div>
      </div>
      <div class="detaljer">
        <div class="detaljer_box">
          <div class="detaljer_header">
            <span>员工明细</span>
          </div>
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            offset="150"
          >
            <presonCard
              :currentIndex="currentIndex"
              :personCard="personCard"
              :forskellen="forskellen"
              @Rewardexchange="Rewardexchange"
              @returnChaneg="returnChaneg"
              :more="more"
              :offset="offset"
            />
          </van-list>
        </div>
      </div>
    </div>
    <div class="show_calder">
      <van-calendar
        v-model="timeshow"
        :min-date="new Date(2023, 0, 1)"
        type="range"
        @confirm="onConfirm"
        :max-date="new Date()"
        allow-same-day
      />
    </div>
  </div>
</template>
<script>
import publicNavigation from "@/publicNavigation/publicNavigation.vue";
import presonCard from "@/person/components/personCard/personCard.vue";
import * as API from "@/api.ts";
import { Dialog } from "vant";
export default {
  components: {
    publicNavigation,
    presonCard,
  },
  data() {
    return {
      // 参数
      order_on: "", //项目订单号（搜索关键字）
      start_time: "", //开始时间
      end_time: "", //结束时间
      page: 1,
      limit: 15,
      project_id: "", //项目id
      product_id: "", //礼品id

      loading: false,
      finished: false,

      navigation: {
        image: require("../assets/return.png"),
        name: "兑换管理",
      },
      active: 0,
      value1: 0,
      user_id: 0,
      calenderShow: false,
      option1: [
        {
          text: "选择时间",
          value: 0,
        },
      ],
      staffList: [
        {
          text: "员工",
          value: 0,
        },
      ],
      productList: [
        {
          text: "礼品",
          value: 0,
        },
      ],
      projectList: [
        {
          text: "项目",
          value: 0,
        },
      ],
      personCard: [],
      offset: "1",
      forskellen: 0,
      currentIndex: 0,

      more: 1,
      personID: "",
      giftId: "",
      searchUdvide: false,
      createInputArray: [],
      crateNullInput: [],
      custom_search: "custom_search",
      timeshow: false,
      createInputSearchText: "",
      TimestampData: "",
    };
  },
  mounted() {
    this.Person();
    this.getProjectList();
    this.getData(); //获取页面数据（现金兑换记录，礼品兑换记录，CP值兑换记录）
    this.getProduct(); //获取礼品筛选列表
  },
  methods: {
    // 上拉加载更多
    onLoad() {
      if (!this.finished) {
        this.page++;
        this.getData(); //获取页面数据（现金兑换记录，礼品兑换记录，CP值兑换记录）
      }
    },
    //获取礼品筛选列表
    async getProduct() {
      let { code, data } = await API.Product();
      if (code == 1) {
        data.rows.forEach((item) => {
          this.productList.push({
            text: item.name,
            value: item.id,
          });
        });
      }
    },
    // 搜索
    onSearch() {
      this.personCard = [];
      this.init();
      this.getData(); //获取页面数据（现金兑换记录，礼品兑换记录，CP值兑换记录）
    },
    //获取页面数据（现金兑换记录，礼品兑换记录，CP值兑换记录）
    getData() {
      switch (this.currentIndex) {
        case 0:
          this.Requset();
          break;
        case 1:
          this.giftRquset();
          break;
        case 2:
          this.exchangeCpLog();
          break;
      }
    },
    //清除搜索关键字
    onClear() {
      this.personCard = [];
      this.init();
      this.getData(); //获取页面数据（现金兑换记录，礼品兑换记录，CP值兑换记录）
    },
    //初始化搜索条件
    init() {
      this.page = 1;
      this.finished = false;
      this.loading = false;
    },

    // 礼品
    exchangeGift() {
      API.exchangeGift({
        page: this.page,
        limit: 10,
      }).then(async (e) => {
        console.log(e);
      });
    },
    Person() {
      API.AdminList().then(async (e) => {
        if (e.code == 1) {
          e.data.rows.map((item) => {
            this.staffList.push({
              text: item.nickname,
              value: item.id,
            });
          });
        }
      });
    },

    // 现金兑换记录
    Requset() {
      this.loading = true;
      let { end_time, start_time, page, limit, order_on, user_id } = this;
      const parms = {
        start_time,
        end_time,
        page,
        limit,
        user_id: user_id || "",
        order_on,
      };
      API.exchangeLog(parms).then(async (e) => {
        if (e.code == 1) {
          e.data.rows.map((item) => {
            this.personCard.push({
              name: "订单号：" + item.order_no,
              ...item,
              desc: [
                {
                  name: "员工：",
                  text: item.user_name,
                },
                {
                  name: "奖励点：",
                  text: item.reward,
                },
                {
                  name: "现金：",
                  text: Number(item.money).toFixed(2),
                },
                {
                  name: "日期：",
                  text: item.time_text,
                },
              ],
            });
          });
          this.loading = false;
          if (e.data.rows.length == 0) {
            this.finished = true;
          }
        }
      });
    },

    // 礼品兑换记录
    giftRquset() {
      this.loading = true;
      let { start_time, end_time, page, limit, order_on, product_id, user_id } =
        this;
      const parms = {
        start_time,
        end_time,
        page,
        limit,
        product_id,
        order_on,
        user_id: user_id || "",
      };
      API.exchangeGiftLog(parms).then(async (e) => {
        if (e.code == 1) {
          e.data.rows.map((item) => {
            this.personCard.push({
              ...item,
              name: "订单号：" + item.order_no,
              desc: [
                {
                  name: "员工：",
                  text: item.user_name,
                },
                {
                  name: "奖励点：",
                  text: item.reward,
                },
                {
                  name: "礼品：",
                  text: item.content,
                },
                {
                  name: "日期：",
                  text: item.time_text,
                },
              ],
            });
          });
          this.loading = false;
          if (e.data.rows.length == 0) {
            this.finished = true;
          }
        }
      });
    },
    // 项目筛选列表
    getProjectList() {
      API.listCp().then(async (e) => {
        if (e.code == 1) {
          e.data.rows.map((item) => {
            this.projectList.push({
              text: item.name,
              value: item.id,
            });
          });
        }
      });
    },
    //CP值兑换记录
    exchangeCpLog() {
      this.loading = true;
      let { start_time, end_time, page, limit, order_on, project_id, user_id } =
        this;
      const parms = {
        start_time,
        end_time,
        page,
        limit,
        project_id: project_id || "",
        order_on,
        user_id: user_id || "",
      };
      API.exchangeCpLog(parms).then(async (e) => {
        if (e.code == 1) {
          e.data.rows.map((item) => {
            this.personCard.push({
              ...item,
              name: "订单号：" + item.order_no,
              desc: [
                {
                  name: "员工：",
                  text: item.user_name,
                },
                {
                  name: "项目：",
                  text: item.project_name,
                },
                {
                  name: "奖励点：",
                  text: item.reward,
                },
                {
                  name: "cp值：",
                  text: item.cp,
                },
                {
                  name: "日期：",
                  // text: this.timeFunction(item.createtime),//旧
                  text: item.time_text, //新
                },
              ],
            });
          });
          this.loading = false;
          if (e.data.rows == 0) {
            this.finished = true;
          }
        }
      });
    },
    eventClick(e) {
      this.init();
      this.personCard = [];
      this.currentIndex = e;
      this.user_id = 0;
      this.product_id = 0;
      this.project_id = 0;
      this.getData();
    },
    timeFunction(e) {
      const date = new Date(e * 1000),
        Y = date.getFullYear(),
        M = date.getMonth(),
        R = date.getDate();
      return (
        Y +
        "-" +
        (M < 10 ? "0" + (M + 1) : M + 1) +
        "-" +
        (R < 10 ? "0" + (R + 1) : R + 1)
      );
    },
    openTimeFunction() {
      this.timeshow = !this.timeshow;
    },
    onConfirm(date) {
      this.start_time = Date.parse(date[0]) / 1000;
      this.end_time = Date.parse(date[1]) / 1000 + 86399;
      this.personCard = [];
      this.init();
      this.getData();
      this.timeshow = false;
    },
    formatDate(date) {
      const year = date.getFullYear();
      const mont =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      const monteh =
        date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return `${year + "-" + mont + "-" + monteh}`;
    },
    Rewardexchange(s) {
      let _this = this;
      Dialog.confirm({
        title: "提示",
        message: "是否确认兑换？",
      })
        .then(() => {
          if (this.currentIndex == 0) {
            const parms = {
              // money: s.desc[2].text,
              type: 1,
              id: s.id,
            };
            API.exchangeLogHandle(parms).then(async (e) => {
              this.showToast(e);
              if (e.code == 1) {
                _this.init();
                _this.personCard = [];
                _this.getData();
              }
            });
          } else {
            const parms = {
              id: s.id,
              type: 1,
            };
            API.gifLogHandle(parms).then(async (e) => {
              this.showToast(e);
              if (e.code == 1) {
                _this.init();
                _this.personCard = [];
                _this.getData();
              }
            });
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    openPersonFunction() {
      this.staffList.length > 1
        ? (this.staffList[0].text = "全部")
        : this.staffList;
    },
    closePersonFunction() {
      this.staffList[0].text = "全部";
    },
    surePersonChange(e) {
      this.user_id = e;
      //初始化搜索条件
      this.init();
      this.personCard = [];
      this.getData(); //获取页面数据（现金兑换记录，礼品兑换记录，CP值兑换记录）
    },
    openGiftFunction() {
      this.productList.length > 1
        ? (this.productList[0].text = "全部")
        : this.productList;
    },
    closeGiftFunction() {
      this.productList[0].text = "礼品";
    },
    sureGiftChange(e) {
      this.personCard = [];
      this.giftId = e;
      this.getData(); //获取页面数据（现金兑换记录，礼品兑换记录，CP值兑换记录）
    },
    openProjectFunction() {
      this.projectList.length > 1
        ? (this.projectList[0].text = "全部")
        : this.projectList;
    },
    closeProjectFunction() {
      this.projectList[0].text = "项目";
    },
    sureProjectFunction(e) {
      this.personCard = [];
      this.init();
      this.project_id = e;
      this.getData(); //获取页面数据（现金兑换记录，礼品兑换记录，CP值兑换记录）
    },
    showToast(e) {
      this.$toast({
        type: e.code == 0 ? "fail" : "success",
        message: e.msg,
      });
    },
    shareEvent() {
      this.searchUdvide = true;
    },
    closeSearchEvent() {
      this.searchUdvide = false;
    },
    createInputSearch(e) {
      this.createInputSearchText = e;
      clearTimeout(this.createInputTime);
      this.createInputTime = setTimeout(() => {
        switch (this.currentIndex) {
          case 0:
            this.Requset(this.TimestampData, "", e);
            break;
          case 1:
            this.giftRquset(this.TimestampData, "", e);
            break;
          case 2:
            this.exchangeCpLog(this.TimestampData, "", e);
            break;
        }
      }, 1000);
    },
    // 退回
    returnChaneg(s) {
      let _this = this;
      Dialog.confirm({
        title: "提示",
        message: "是否确认退回?",
      })
        .then(() => {
          if (this.currentIndex == 0) {
            const parms = {
              id: s.id,
              type: 2,
            };
            API.exchangeLogHandle(parms).then(async (e) => {
              this.$toast({
                type: e.code == 1 ? "success" : "fail",
                message: e.msg,
              });
              if (e.code == 1) {
                _this.init();
                _this.personCard = [];
                _this.getData();
              }
            });
          } else if (this.currentIndex == 1) {
            const parms = {
              id: s.id,
              type: 2,
            };
            API.gifLogHandle(parms).then(async (e) => {
              _this.$toast({
                type: e.code == 1 ? "success" : "fail",
                message: e.msg,
              });
              if (e.code == 1) {
                _this.init();
                _this.personCard = [];
                _this.getData();
              }
            });
          } else {
            const parms = {
              id: s.id,
            };
            API.exchangeCpBack(parms).then(async (e) => {
              _this.$toast({
                type: e.code == 1 ? "success" : "fail",
                message: e.msg,
              });
              if (e.code == 1) {
                _this.init();
                _this.personCard = [];
                _this.getData();
              }
            });
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    timeshowFunction() {
      this.timeshow = true;
    },
    deleteTimeFunction() {
      this.start_time = "";
      this.end_time = "";
      this.page = 1;
      this.personCard = [];
      switch (this.currentIndex) {
        case 0:
          this.Requset();
          break;
        case 1:
          this.giftRquset();
          break;
        case 2:
          this.exchangeCpLog();
          break;
      }
    },
  },
};
</script>
<style lang="scss">
.van-dropdown-menu__item {
  max-width: 3rem;
}
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.udveksle {
  height: 100vh;
  background: #f1f0f5;

  .udveksle_header {
    .bcakground {
      background: white;

      .custom_position {
        img {
          width: 0.29rem;
        }
      }

      .van-dropdown-menu {
        .van-dropdown-menu__bar {
          box-shadow: none;
          // border-top: 1px solid #f1f0f5;

          .van-dropdown-menu__item {
            // width: 30%;

            span {
              font-size: calcFunction(13);

              &::after {
                border-color: transparent transparent #333333 #333333;
              }
            }
          }

          .van-dropdown-menu__item:nth-last-child(1) {
            // width: 40%;
          }
        }
      }
    }
  }
}

.detaljer {
  margin: calcFunction(10);

  .detaljer_box {
    background: white;
    border-radius: calcFunction(4);
    height: 70vh;
    overflow-y: scroll;

    .detaljer_header {
      padding: calcFunction(15);
      padding-bottom: 0;

      span {
        color: #111111;
        font-size: calcFunction(14);
        font-weight: bold;
      }
    }

    .person_card {
      padding: 0 calcFunction(15);
      padding-bottom: calcFunction(20);
    }

    .preson_list_data {
      .person_for_data {
        width: 50%;
      }
    }

    .tilbage {
      margin-top: calcFunction(20);

      .tilbage_button {
        @extend .alignItemsCenter;
        justify-content: flex-end;

        button {
          width: calcFunction(70);
          height: calcFunction(27);
          font-size: calcFunction(13);
          color: #666666;
          border-radius: calcFunction(2);
        }

        button:nth-child(1) {
          background: transparent;
          border: 1px solid #999999;
        }

        button:nth-child(2) {
          border: 1px solid #d71518;
          background: transparent;
          color: #d71518;
          margin-left: calcFunction(15);
        }

        span {
          font-size: calcFunction(14);
          color: #999;
        }
      }
    }
  }
}

.minText {
  span {
    font-size: calcFunction(13.8) !important;
  }
}

.custom_search {
  width: calcFunction(16) !important;
}

.select_tabs {
  // display: grid;
  // align-items: center;
  // grid-template-columns: 50% 50%;
  // grid-template-rows: 100%;
  // box-shadow: 0px 4px 6px 1px rgb(200 200 200 / 51%);
  display: flex;
  align-items: center;
  margin: 0 20px;
  justify-content: space-around;
}

.select_tiem {
  display: flex;
  align-items: center;
  width: 40%;
  justify-content: center;

  img {
    width: 0.29rem;
    transform: rotate(90deg);
    margin-left: 0.3rem;
  }

  span {
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.iamge_rotate {
  transform: rotate(0) !important;
}

.slect_tabs_content {
  flex: 1;
}
</style>