<template>
  <div class="bonuspoint" style="background: #f1f2f6;">
    
    <div class="bonuspoint_content_header">
      <publicNavigation :data="navigation"/>
    </div>
    <div class="bonuspoint_total">
      <div class="bounspoint_text_box">
        <div class="bonuspoint_numbers_text">
          <span>我的奖励点<i @click="ShowClick">?</i></span>
        </div>
        <div class="flex-c-b">
          <span class="reward">{{ reward }}</span>
          <div class="bonuspoint_button_total">
            <button @click="exchangeGift">兑换礼品</button>
            <button @click="exchangeCash">兑换现金</button>
          </div>
        </div>
      
      </div>
    </div>
    <div class="pris_detaljer">
      <div class="pris_detaljer_content_header_title flex-c-b">
        <span>奖励点明细</span>
        <div class="pris_detljer_more" @click="moreEventClick">
          <span>更多</span>
          <img src="@/assets/readright.png" alt=""/>
        </div>
      </div>
      
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <bonuspointList :detaljerData="detaljerData"/>
      </van-list>
    </div>
    
    <van-popup round v-model="show">
      <div class="exchange_popup">
        <div class="exchange_popup_content">
          <div class="exchange_popup_content_box">
            <div class="exchange_popup_content_header">
              <span>兑换现金</span>
            </div>
            <div class="exchange_popup_content_project_name">
              <div class="exchange_input">
                <!--<input type="number" min="1" v-model="moneys" placeholder="输入要兑换现金金额">-->
                
                <!-- 允许输入正整数，调起纯数字键盘 -->
                <van-field v-model="moneys" type="digit" placeholder="输入要兑换现金金额"/>
              
              </div>
              <div class="exchange_description_describe">
                <span>您目前的奖励点可现金：￥{{ result }}元</span>
                <span>(1元={{ cash_exchange }}奖励点)</span>
              </div>
              <div class="exhcange_button_classfiy">
                <button @click="exchangeCash">取消</button>
                <button @click="sureMoney">提交</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import publicNavigation from '@/publicNavigation/publicNavigation.vue';
// import {myBonuspoint} from './myBonuspoint.ts'
import bonuspointList from './compoments/bonuspointList.vue'
import * as API from '@/api.ts'
import {Toast} from "vant";

export default {
  components: {
    publicNavigation,
    bonuspointList
  },
  data() {
    return {
      navigation: {
        image: require('../assets/return.png'),
        name: '我的奖励点',
        close: ''
      },
      
      loading: false,
      finished: false,
      detaljerData: [],
      page: 1,
      limit: 20,
      
      show: false,
      moneys: '',
      cash_exchange: '',
      result: '',
      reward: ''
    }
  },
  mounted() {
    this.Request()
    this.admin()
  },
  methods: {
    //加载更多
    onLoad() {
      if (this.detaljerData.length > 0) {
        this.Request();
      } else {
        this.loading = false;
      }
    },
    ShowClick() {
      API.introduceList().then(async e => {
        console.log(e)
        if (e.code == 1 && e.data.reward_introduce) {
          this.$dialog({
            title: "奖励点",
            message: e.data.reward_introduce
          })
        }
      })
    },
    Request() {
      this.loading = true;
      let {page, limit} = this;
      //   this.recordData = [];
      const parms = {
        page,
        limit,
      };
      API.rewardlog(parms).then(async e => {
        if (e.code == 1) {
          var list = e.data.rows;
          if (page == 1) {
            if (e.data.rows.length > 0) {
              this.detaljerData = list;
              this.page++;
            } else {
              this.finished = true;
            }
          } else {
            if (e.data.rows.length > 0) {
              this.detaljerData.push(...list);
              this.page++;
              console.log(this.detaljerData.length);
            } else {
              this.finished = true;
            }
          }
          this.loading = false;
          Toast.clear();
        }
      })
      // API.myCp().then(async e => {
      //   if (e.code == 1) {
      //     console.log(e)
      //   }
      // })
      API.toMoney().then(async e => {
        console.log(e)
        if (e.code == 1) {
          this.cash_exchange = e.data.cash_exchange
          this.result = e.data.result
        }
      })
    },
    moreEventClick() {
      this.$router.push({
        name: 'optageList',
        path: '/optageList'
      })
    },
    exchangeGift() {
      this.$router.push({
        name: 'exchangeGift',
        path: '/exchangeGift'
      })
    },
    exchangeCash() {
      this.show = !this.show
    },
    sureMoney() {
      const parms = {
        money: this.moneys
      }
      const reg = /^[0-9]*$/
      if (reg.test(this.moneys)) {
        API.Rewardexchange(parms).then(async e => {
          this.admin()
          if (e.code == 1) {
            this.$toast({
              type: 'success',
              message: '兑换成功'
            })
            this.moneys = ''
            this.detaljerData = []
            this.Request()
          } else {
            this.$toast.fail(e.msg)
          }
          this.show = !this.show
        })
      } else {
        this.$toast.fail('不能有小数，请输入整数')
      }
      
    },
    admin() {
      API.Admin().then(async e => {
        if (e.code == 1) {
          console.log(e)
          this.reward = e.data.reward
        }
      })
    },
    Date(time) {
      var date = new Date(time * 1000),  // 参数需要毫秒数，所以这里将秒数乘于 1000
          Y = date.getFullYear(),
          M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1),
          D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      return Y + '-' + M + '-' + D;
    }
  }
}
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.bonuspoint {
  background: #F1F2F6;
  min-height: 100vh;
}

.bonuspoint_total {
  margin: 0 0.2rem;
  
  .bounspoint_text_box {
    background-image: url('@/assets/totalbackgroundimage.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 0.26rem 0.3rem 0.2rem;
    margin-top: 0.2rem;
    
    .bonuspoint_numbers_text {
      @extend .alignItemsCenter;
      flex-direction: column;
      align-items: flex-start;
      
      span {
        color: white;
        font-size: 0.24rem;
        display: flex;
        align-items: center;
        
        i {
          border: 1px solid white;
          width: 0.3rem;
          height: 0.3rem;
          font-size: 0.2rem;
          font-style: normal;
          text-align: center;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 5px;
        }
      }
      
    }
    
    .reward {
      font-size: 0.7rem;
      color: white;
      height: 1.1rem;
      display: flex;
      align-items: center;
    }
    
    .bonuspoint_button_total {
      button {
        width: 1.8rem;
        height: 0.6rem;
        background: white;
        border: none;
        box-shadow: 0px 3px 15px 0px rgba(255, 75, 51, 0.15);
        border-radius: 0.6rem;
        font-size: 0.28rem;
        color: #D71518;
        font-weight: bold;
      }
      
      button:nth-last-child(1) {
        margin-left: calcFunction(10);
      }
    }
  }
}

.pris_detaljer {
  margin-top: 0.4rem;
  
  .pris_detaljer_content_header_title {
    margin: 0 0.3rem 0.3rem;
    
    span {
      color: #111111;
      font-size: calcFunction(15);
      font-weight: bold;
    }
    
    .pris_detljer_more {
      @extend .alignItemsCenter;
      
      span {
        color: #999999;
        font-size: calcFunction(12);
      }
      
      img {
        margin-left: calcFunction(2);
        width: calcFunction(8);
        height: calcFunction(13);
      }
    }
  }
}

::-webkit-scrollbar {
  display: none;
}

.exchange_popup {
  width: 6rem;
  
  .exchange_popup_content {
    .exchange_popup_content_box {
      .exchange_popup_content_header {
        text-align: center;
        border-bottom: 1px solid #EEEEEE;
        padding: 0.4rem 0;
        
        span {
          font-weight: bold;
          font-size: 0.32rem;
        }
      }
      
      .exchange_popup_content_project_name {
        padding: 0.3rem 0.4rem 0.4rem;
        
        .exchange_project_title {
          span {
          }
        }
        
        .exchange_input {
          @extend .alignItemsCenter;
          height: 0.9rem;
          background: #F1F1F1;
          
          .van-field {
            flex: 1;
            background: transparent;
          }
        }
        
        .exchange_description_describe {
          margin-top: 0.3rem;
          @extend .alignItemsCenter;
          flex-direction: column;
          align-items: flex-start;
          
          span {
            color: #FF6769;
            font-size: 0.24rem;
          }
        }
        
        .exhcange_button_classfiy {
          margin-top: calcFunction(20);
          @extend .alignItemsCenter;
          justify-content: space-between;
          
          button {
            width: 2.4rem;
            height: 0.9rem;
            background: #D71518;
            color: white;
            font-size: 0.32rem;
            border-radius: 0.45rem;
            outline: none;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          
          button:nth-child(1) {
            background: transparent;
            color: #131313;
            border: 1px solid #E5E5E5;
          }
        }
      }
    }
  }
}

.bonuspoint_content_header {
  position: sticky;
  top: 0;
  z-index: 999;
  background: #F1F2F6;
}
</style>
