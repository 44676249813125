export class addPerson {
    init(){
        return{
            addPersonList:[{
                name:'工号',
                text:'请输入员工工号',
                sure:''
            },{
                name:'员工姓名',
                text:'请输入员工姓名',
                sure:''
            },{
                name:'所属部门',
                text:'请选择部门',
                deparment:'1',
                sure:''
            },{
                name:'岗位职能',
                text:'请选择岗位职能',
                responsibility:'1',
                sure:''
            },{
                name:'电子邮箱',
                text:'请输入邮箱',
                sure:''
            },{
                name:'联系电话',
                text:'请输入联系电话',
                sure:''
            }]
        }
    }
}