<template>
  <div class="review_description">
    <div class="review_description_content">
      <div class="review_description_content_data">
        <div class="review_description_task">
          <div class="review_task_header">
            <span>归属项目：</span>
            <span>{{ data.projects }}</span>
          </div>
          <div class="review_task_associate">
            <div class="reviw_associate_list">
              <div class="associate_task">
                <span>关联任务：</span>
                <span>{{ data.taskNumber ? data.taskNumber + "个" : "" }}</span>
              </div>
              <div class="associate_task">
                <span>指派时间：</span>
                <span style="white-space: nowrap">{{
                  SpliceTimes(createtime_text)
                }}</span>
              </div>
            </div>
            <div class="associate_task" style="margin-top: 0.625rem">
              <span style="font-size: 0.28rem; color: #666666">发布人：</span>
              <span style="font-size: 0.28rem">{{ data.release }}</span>
            </div>
            <div
              class="associate_task"
              style="display: flex; margin-top: 0.625rem"
            >
              <span
                style="white-space: nowrap; font-size: 0.28rem; color: #666666"
                >验收人：</span
              >
              <!-- <span v-if="data.acceptance">{{ data.acceptance }}<i v-if="buttonClassfiy !== '执行任务'">[更换]</i></span> -->
              <div
                v-if="details || data"
                class="personsData"
                style="display: flex"
              >
                <!-- {{details}}
									<div v-if="userinfoId == 4" style="display:flex;flex-direction: column;">
										<span v-for="item, index in data.PidInfo.inspect" :key="index">{{ item }}</span>
									</div> -->
                <!-- v-else -->
                <div>
                  <span style="font-size: 14px">{{
                    details.PidInfo.inspect.toString()
                  }}</span>
                </div>
                <span
                  style="
                    color: #375394;
                    white-space: nowrap;
                    font-size: 14px;
                    margin-left: 4px;
                  "
                  v-if="
                    (cardType == '审核任务' && userinfoId == 4) ||
                    (userinfoId == 2 && cardType == '审核任务')
                  "
                  @click="acceptorPerson"
                  >[更换]</span
                >
              </div>
            </div>
          </div>
          <div class="task_introduce">
            <div class="task_introduce_content">
              <div
                class="task_introduce_title"
                style="width: 100%; align-items: flex-start"
              >
                <div
                  class="task_intrduce_text_box"
                  style="width: 100%; display: flex; align-items: flex-start"
                >
                  <span style="white-space: nowrap">任务介绍：</span>
                  <span v-if="readonly == false">{{ data.introduce }}</span>
                  <input
                    style="width: 100%"
                    type="text"
                    v-if="readonly"
                    v-model="inputtask"
                    placeholder="请输入您的任务介绍"
                  />
                </div>
                <div class="task_intrduce_image">
                  <img
                    src="@/assets/edit.png"
                    @click="editTextFunctiuon(data.introduce)"
                    v-if="cardType == '审核任务' && userinfoId != 1"
                    alt=""
                  />
                  <!-- <img @click="newdescriptionclcik(data)" src="@/assets/bulearrow.png" class="bulearrow"
										v-else alt="" /> -->
                </div>
              </div>
            </div>
          </div>
          <div class="task_appendix">
            <div class="task_appendix_box">
              <div class="task_appendix_content">
                <div class="task_appendix_list">
                  <div class="task_appendix_title">
                    <span>任务附件：</span>
                    <div class="appendix_file">
                      <div
                        class="appendix_file_list"
                        v-for="(s, k) in data.appendix"
                        :key="k"
                      >
                        <span>{{ s.filename }}</span>
                        <img
                          src="@/assets/deletered.png"
                          v-if="
                            cardType == '审核任务' &&
                            userinfoId != 1 &&
                            cardType == '审核任务'
                          "
                          alt=""
                          @click="deleteImageClick(s, k)"
                        />
                      </div>
                      <div
                        class="add_appendix_file"
                        v-if="cardType == '审核任务'"
                      >
                        <div class="file_list_delete">
                          <div
                            class="file_delete_box"
                            v-for="(l, m) in fileList"
                            :style="
                              fileList.length > 0 ? 'margin-bottom:10px;' : ''
                            "
                            :key="m"
                          >
                            <span class="filelist">{{ l }}</span>
                            <img
                              v-if="
                                cardType == '审核任务' &&
                                userinfoId != 1 &&
                                cardType == '审核任务'
                              "
                              :src="require('@/assets/deletered.png')"
                              @click="deleteImageClick(l, m)"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="trin_button_uploding">
                          <div class="trin_button_uplodings">
                            <input
                              type="file"
                              name=""
                              id=""
                              @change="onafterprint"
                              value=""
                            />
                            <van-uploader :after-read="onafterprint">
                              <van-button icon="plus" type="primary"
                                >上传文件</van-button
                              >
                            </van-uploader>
                          </div>
                        </div>
                        <div
                          class="sure_uploading_image_text_box"
                          v-if="userinfoId != 1 && cardType == '审核任务'"
                        >
                          <img src="@/assets/appendix.png" alt="" />
                          <span>添加附件</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="task_appendix">
            <div class="task_appendix_box">
              <div class="task_appendix_content">
                <div class="task_appendix_list">
                  <div class="task_appendix_title">
                    <span>任务图片：</span>
                    <div class="appendix_file">
                      <div
                        class="appendix_file_list"
                        v-for="(s, k) in data.taskimg_info"
                        :key="k"
                      >
                        <span>{{ s.filename }}</span>
                        <img
                          src="@/assets/deletered.png"
                          v-if="
                            (cardType == '审核任务' && userinfoId == 4) ||
                            (userinfoId == 2 && cardType == '审核任务')
                          "
                          alt=""
                          @click="deleteImageClick(s, 2)"
                        />
                      </div>
                      <div
                        class="add_appendix_file"
                        v-if="cardType == '审核任务'"
                      >
                        <div class="file_list_delete">
                          <div
                            class="file_delete_box"
                            v-for="(l, m) in fileList"
                            :style="
                              fileList.length > 0 ? 'margin-bottom:10px;' : ''
                            "
                            :key="m"
                          >
                            <span class="filelist">{{ l }}</span>
                            <img
                              v-if="
                                (cardType == '审核任务' && userinfoId == 4) ||
                                (userinfoId == 2 && cardType == '审核任务')
                              "
                              :src="require('@/assets/deletered.png')"
                              @click="deleteImageClick(l, m)"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="trin_button_uploding">
                          <div class="trin_button_uplodings">
                            <input
                              type="file"
                              name=""
                              id=""
                              @change="onafterprintImage"
                              value=""
                            />
                            <van-uploader :after-read="onafterprintImage">
                              <van-button icon="plus" type="primary"
                                >上传文件</van-button
                              >
                            </van-uploader>
                          </div>
                        </div>
                        <div
                          class="sure_uploading_image_text_box"
                          v-if="userinfoId != 1 && cardType == '审核任务'"
                        >
                          <img src="@/assets/appendix.png" alt="" />
                          <span>添加图片</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="review_description_classfiy">
          <van-tabs
            v-model="active"
            @click="tabbarClick"
            title-active-color="#D71518"
            animated
            sticky
          >
            <van-tab
              v-for="(index, ks) in tarbbarData"
              :title="index"
              :key="ks"
            >
              <div class="review_description_content_list_classfiy">
                <div class="review_description_list">
                  <div
                    class="review_content_descriptions"
                    :class="buttonClassfiy == '执行任务' ? 'performTasks' : ''"
                  >
                    <div class="review_lists" v-if="ks == 0">
                      <!-- v-show="s.status_text == '未接单' || s.status_text == '等待拆解'" @click="descriptionClick(s,k)" v-show="s.process.length > 0 || s.status_text != '未开始'"  -->
                      <div
                        class="reveiw_for_content"
                        v-for="(s, k) in data.children"
                        :key="k"
                      >
                        <div
                          class="review_for_content_header"
                          :class="
                            buttonClassfiy == '执行任务' ? 'performFlex' : ''
                          "
                        >
                          <span>{{ s.projectName }}</span>
                          <div class="perform_tasks">
                            <!-- v-show="statusFunction(s.status) == '待拆解' || s.process.length <= 0 && statusFunction(s.status) == '未开始'"   v-if="statusFunction(s.status) != '验收中'" -->
                            <div
                              class="perform_tasks_data"
                              style="display: flex"
                            >
                              <button>
                                {{
                                  userinfoId == 4
                                    ? s.status_text
                                    : statusFunction(s.status, s.status_text, s)
                                }}
                                <span
                                  style="
                                    margin: 0 10px;
                                    font-size: 12px;
                                    color: #999;
                                  "
                                  v-if="
                                    buttonClassfiy == '发起任务' ||
                                    buttonClassfiy == ' 执行任务'
                                  "
                                  >{{
                                    (s.status_text == "奖励点审核" &&
                                      userinfoId != 4) ||
                                    (s.status_text == "审核中" &&
                                      userinfoId != 4) ||
                                    (s.status_text == "拆解未审核" &&
                                      userinfoId != 4)
                                      ? `(${s.review_person.toString()})`
                                      : ""
                                  }}</span
                                >
                              </button>
                              <!-- || s.status_text == '未开始' || s.status_text == '未接单' -->
                              <button
                                class="OrdersButton"
                                v-if="
                                  routers == 1 ||
                                  routers == 1 ||
                                  (s.status == 1 &&
                                    s.status_text == '未接单' &&
                                    userIds == s.user_id) ||
                                  (s.status == 1 &&
                                    s.status_text == '未接单' &&
                                    userIds == s.user_id)
                                "
                                @click="OrdersFunction(s.id)"
                              >
                                接单
                              </button>
                              <button
                                v-show="
                                  userinfoId != 2 &&
                                  s.status_text == '未开始' &&
                                  s.process.length <= 0 &&
                                  userinfoId != 4 &&
                                  s.status != 2 &&
                                  s.status != 3 &&
                                  s.status == 1
                                "
                                @click="receiving(s, s)"
                              >
                                {{ statusFunction(s.status, s.status_text, s)
                                }}<span
                                  style="
                                    margin: 0 10px;
                                    font-size: 12px;
                                    color: #999;
                                  "
                                  v-if="
                                    buttonClassfiy == '发起任务' ||
                                    buttonClassfiy == ' 执行任务'
                                  "
                                >
                                  {{
                                    (s.status_text == "奖励点审核" &&
                                      userinfoId != 4) ||
                                    (s.status_text == "审核中" &&
                                      userinfoId != 4) ||
                                    (s.status_text == "拆解未审核" &&
                                      userinfoId != 4)
                                      ? `(${s.review_person.toString()})`
                                      : ""
                                  }}</span
                                >
                              </button>
                              <!-- {{ s }} -->
                              <!-- 总审核 -->
                              <button
                                v-show="
                                  userinfoId != 1 &&
                                  s.status_text == '奖励点审核'
                                "
                                style="
                                  border: 1px solid red;
                                  color: red;
                                  padding: 2px 6px;
                                  border-radius: 4px;
                                "
                                @click="reviewEvent(s, s)"
                              >
                                审核
                              </button>
                              <!-- 任务审核 -->
                              <button
                                v-show="
                                  (userinfoId != 1 &&
                                    s.status_text == '审核中' &&
                                    cardType == '审核任务') ||
                                  (s.status_text == '拆解未审核' &&
                                    s.status == 3 &&
                                    cardType == '审核任务') ||
                                  (s.status == 6 &&
                                    s.status_text == '审核中' &&
                                    cardType == '审核任务') ||
                                  (s.status == 2 &&
                                    s.status_text == '审核中' &&
                                    cardType == '审核任务')
                                "
                                style="
                                  border: 1px solid red;
                                  color: red;
                                  padding: 2px 6px;
                                  border-radius: 4px;
                                "
                                @click="reviewEvent(s, s)"
                              >
                                审核
                              </button>
                              <button
                                @click="receiving(s, s)"
                                style="
                                  border: 1px solid red;
                                  color: red;
                                  padding: 2px 6px;
                                  border-radius: 4px;
                                "
                                v-if="
                                  s.status == 4 &&
                                  buttonClassfiy == ' 执行任务' &&
                                  userinfoId != 3 &&
                                  userIds == s.user_id &&
                                  s.process.length <= 0
                                "
                              >
                                开始
                              </button>
                              <!-- <button @click="closetask(s, 1)"
																style="border:1px solid red;color:red;padding:2px 6px;border-radius:4px;"
																v-show="userinfoId != 1 && buttonClassfiy == ' 执行任务' && s.process_type == 1 && s.status != 6 && s.status != 8 && s.status != 1 && s.status != 2 && s.status != 4 && s.is_suspend == 2 && s.status_text == '审核中' || userinfoId != 1 && s.status_text == '进行中' && s.status == 5 && data.children.length > 1">关闭</button> -->
                              <!-- <button @click="editreviewNumber(s, 1)"
																style="border:1px solid red;color:red;padding:2px 6px;border-radius:4px;"
																v-show="userinfoId != 1 && buttonClassfiy == ' 执行任务' && s.process_type == 1 && s.status != 6 && s.status != 8 && s.status != 1 && s.status != 2 && s.status != 4 && s.is_suspend == 2 && s.status_text == '审核中' || userinfoId != 1 && s.status_text == '进行中' && s.status == 5">编辑奖励点</button> -->
                              <button
                                @click="sureClickEvent(s, 1)"
                                style="
                                  border: 1px solid red;
                                  color: red;
                                  padding: 2px 6px;
                                  border-radius: 4px;
                                "
                                v-show="
                                  buttonClassfiy == ' 验收任务' &&
                                  s.status == 6 &&
                                  s.status_text == '验收中'
                                "
                              >
                                完成验收
                              </button>
                              <!-- s.is_suspend || s.status_text !='暂停中' -->
                              <!-- <button
																:style="cardType == '审核任务' && s.status == 3 ? 'border:1px solid red;color:red;padding:2px 6px;border-radius:4px;' : ''"
																@click="reviewEvent(s)"
																v-if="userinfoId == 3 || s.status == 3 && userinfoId == 4 || s.status_text == '奖励点审核' && userinfoId == 4 || userinfoId == 3 || cardType == '审核任务' && s.status == 3">审核</button> -->
                              <div
                                class="expand_setop_buttons expand_task_buttons"
                                style="margin-left: 4px"
                                v-show="s.status_text != '拆解未审核'"
                              >
                                <button
                                  @click="sureClickEvent(s, 1)"
                                  v-show="
                                    s.status == 5 &&
                                    s.process_type == 1 &&
                                    s.status_text != '审核中' &&
                                    userinfoId == 1
                                  "
                                  style="
                                    color: green;
                                    border: 1px solid green !important;
                                    border-radius: 4px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                  "
                                >
                                  完成
                                </button>
                                <!-- && n.status == 2 && n.status == 5 && n.status == 5 -->
                                <button
                                  @click="Processpause(s, 1)"
                                  v-show="
                                    buttonClassfiy == ' 执行任务' &&
                                    userIds == s.user_id &&
                                    s.process_type == 1 &&
                                    s.release_review == 2 &&
                                    s.is_suspend == 1 &&
                                    s.status != 6 &&
                                    s.status != 8 &&
                                    s.status != 1 &&
                                    s.status != 2 &&
                                    s.status != 4 &&
                                    s.status != 10
                                  "
                                >
                                  暂停
                                </button>
                                <button
                                  @click="pauseCancel(s, 1)"
                                  v-show="
                                    buttonClassfiy == ' 执行任务' &&
                                    userIds == s.user_id &&
                                    s.process_type == 1 &&
                                    s.release_review == 1 &&
                                    s.is_suspend == 2 &&
                                    s.status != 6 &&
                                    s.status != 8 &&
                                    s.status != 1 &&
                                    s.status != 2 &&
                                    s.status != 4
                                  "
                                >
                                  取消
                                </button>
                                <button
                                  @click="pauseStart(s, 1)"
                                  v-show="
                                    buttonClassfiy == ' 执行任务' &&
                                    userIds == s.user_id &&
                                    s.process_type == 1 &&
                                    s.release_review == 2 &&
                                    s.is_suspend == 3 &&
                                    s.status != 6 &&
                                    s.status != 8 &&
                                    s.status != 1 &&
                                    s.status != 2 &&
                                    s.status != 4
                                  "
                                >
                                  开始
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="review_content_person_list">
                          <div class="review_content_persons">
                            <div
                              class="text_for_box_person"
                              v-for="(i, l) in s.children"
                              :key="l"
                            >
                              <span>{{ i.name }}</span>
                              <!-- :style="((i?.text.split('')[7] == '提') && l == 1) ? 'color:green;' : ((i?.text.split('')[7] == '超') && l == 1) ? 'color:red' : ''" -->
                              <span v-if="i.text"
                                >{{ i.text || "" }}
                                <i
                                  v-if="i.timeout_text"
                                  :style="
                                    i.timeout_text
                                      ? i.timeout_text.split('')[0] == '提'
                                        ? 'color:green;'
                                        : ''
                                      : ''
                                  "
                                  >{{
                                    i.timeout_text
                                      ? "(" + i.timeout_text + ")"
                                      : i.timeout_text
                                  }}</i
                                >
                              </span>
                              <span
                                v-if="
                                  !i.text &&
                                  !i.suspendtime_starttime &&
                                  !i.suspendtime_endtime
                                "
                                >--</span
                              >
                              <div
                                v-if="
                                  i.suspendtime_starttime ||
                                  i.suspendtime_endtime
                                "
                              >
                                <span style="white-space: nowrap"
                                  >开始时间：{{ i.suspendtime_starttime }}</span
                                >
                                <span style="white-space: nowrap"
                                  >暂停时间：{{ i.suspendtime_endtime }}</span
                                >
                                <span style="white-space: nowrap"
                                  >暂停类型：暂停任务</span
                                >
                              </div>
                            </div>
                          </div>
                          <!-- v-show="expandShow && currentIndex == k" v-if="statusFunction(s.status) == '待审核' || statusFunction(s.status) == '未开始' || statusFunction(s.status) == '进行中' || statusFunction(s.status) == '已完成'" -->
                          <div class="expand" v-show="expandShow">
                            <div class="expand_description">
                              <div class="expand_description_content">
                                <div class="expand_description_content_for">
                                  <div
                                    class="expand_for"
                                    v-for="(n, m) in s.step"
                                    :key="m"
                                  >
                                    <div class="expand_step_number">
                                      <div class="expand_step_number_title">
                                        <span>{{ `第${m + 1} 步` }}</span>
                                      </div>
                                      <div
                                        class="expand_step_number_text"
                                        style="
                                          display: flex;
                                          flex-direction: column;
                                        "
                                        :class="
                                          statusFunction(s.status) == '已完成'
                                            ? 'complete'
                                            : ''
                                        "
                                      >
                                        <div
                                          class="expadns_setp"
                                          style="
                                            display: flex;
                                            justify-content: space-between;
                                            width: 100%;
                                          "
                                        >
                                          <div
                                            class="expand_step_text_title"
                                            :style="
                                              statusFunction(s.status) ==
                                              '已完成'
                                                ? 'width:100%;'
                                                : ''
                                            "
                                          >
                                            <span>{{ n.title }}</span>
                                            <span
                                              v-show="
                                                statusFunction(s.status) ==
                                                '已完成'
                                              "
                                              >已完成</span
                                            >
                                          </div>
                                          <div
                                            class="expand_setop_buttons"
                                            v-if="
                                              buttonClassfiy == ' 执行任务' &&
                                              userinfoId != 4
                                            "
                                          >
                                            <span
                                              style="
                                                color: #999;
                                                font-weight: 400 !important;
                                              "
                                              v-show="
                                                n.status_text == '验收中' ||
                                                n.status_text == '已完成'
                                              "
                                              >{{ n.status_text }}</span
                                            >
                                            <button
                                              @click="sureClickEvent(n, 0)"
                                              v-if="
                                                n.status == 1 &&
                                                statusFunction(s.status) !=
                                                  '已完成' &&
                                                userinfoId == 1 &&
                                                s.status != 3 &&
                                                n.status != 3
                                              "
                                            >
                                              开始
                                            </button>
                                            <button
                                              @click="sureClickEvent(n, 1)"
                                              v-else
                                              :class="
                                                n.status == 3 || n.status == 2
                                                  ? 'greend'
                                                  : ''
                                              "
                                              v-show="
                                                statusFunction(s.status) !=
                                                  '已完成' &&
                                                userinfoId != 2 &&
                                                s.status != 3 &&
                                                n.status != 3 &&
                                                n.status != 5 &&
                                                n.status != 4
                                              "
                                            >
                                              完成
                                            </button>
                                            <button
                                              v-show="
                                                n.status == 4 &&
                                                n.status_text == '已暂停'
                                              "
                                            >
                                              {{ n.status_text }}
                                            </button>
                                            <button
                                              @click="Processpause(n, 1)"
                                              v-show="
                                                userIds == s.user_id &&
                                                s.process_type == 2 &&
                                                n.status == 2
                                              "
                                            >
                                              暂停
                                            </button>
                                            <button
                                              @click="pauseCancel(n, 1)"
                                              v-show="
                                                userIds == s.user_id &&
                                                s.process_type == 2 &&
                                                n.status == 5 &&
                                                s.release_review == 1
                                              "
                                            >
                                              取消
                                            </button>
                                            <button
                                              @click="pauseStart(n, 1)"
                                              v-show="
                                                userIds == s.user_id &&
                                                s.process_type == 2 &&
                                                s.release_review == 2 &&
                                                n.status == 4 &&
                                                n.status_text == '已暂停'
                                              "
                                            >
                                              开始
                                            </button>
                                          </div>
                                        </div>
                                        <!-- v-if="n.status == 3 || s.status == 2" -->
                                        <div
                                          class="expadn_step_startTime expadns_step"
                                        >
                                          <div class="time_list">
                                            <span>开始时间：</span>
                                            <!-- timeFunction(n.createtime) -->
                                            <span>{{
                                              n.real_starttime_text
                                                ? n.real_starttime_text
                                                : n.starttime
                                                ? n.starttime + "预"
                                                : "-"
                                            }}</span>
                                          </div>
                                          <div class="time_list">
                                            <span>完成时间：</span>
                                            <span>
                                              {{
                                                n.real_endtime_text
                                                  ? n.real_endtime_text
                                                  : n.endtime
                                                  ? n.endtime + "预"
                                                  : "-"
                                              }}
                                              <!-- {{ timeFunction(n.endtime) }} -->
                                              <i
                                                v-if="n.real_endtime_text"
                                                :style="
                                                  n.ago
                                                    ? n.ago.split('')[0] == '提'
                                                      ? 'color:green;'
                                                      : 'color:red;'
                                                    : ''
                                                "
                                                >{{
                                                  n.ago
                                                    ? "(" + n.ago + ")"
                                                    : n.ago
                                                }}</i
                                              >
                                            </span>
                                          </div>
                                          <div class="time_list">
                                            <span
                                              >工作量：{{
                                                n.real_workday
                                                  ? n.real_workday
                                                  : "-"
                                              }}</span
                                            >
                                          </div>
                                        </div>
                                        <div
                                          class="time_list"
                                          v-if="n.suspend.length > 0"
                                          style="
                                            display: flex;
                                            align-items: center;
                                            justify-content: space-between;
                                            width: 100%;
                                          "
                                        >
                                          <div
                                            v-for="(v, m) in n.suspend"
                                            class="suspend_style"
                                            :key="m"
                                            style="
                                              display: flex;
                                              flex-direction: column;
                                            "
                                          >
                                            <span
                                              >开始时间:{{
                                                v.suspend_starttime
                                              }}</span
                                            >
                                            <span
                                              >暂停时间:{{
                                                v.suspend_endtime
                                              }}</span
                                            >
                                            <span>暂停类型：暂停步骤</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="expand_click"
                            v-if="
                              buttonClassfiy !== '执行任务' &&
                              s.process.length > 0
                            "
                          >
                            <div
                              class="expand_click_text"
                              @click="expandEvent(s, k)"
                            >
                              <span>{{
                                currentIndex == k && expandShow
                                  ? "收起步骤"
                                  : "展开步骤"
                              }}</span>
                              <van-icon
                                color="#375394"
                                :class="
                                  currentIndex == k && expandShow
                                    ? 'active'
                                    : 'orgin'
                                "
                                name="arrow-down"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="nulldata"
                      v-if="
                        (data.children.length <= 0 && currentIndex == 0) ||
                        (currentIndex == 3 && personcarddate.length <= 0)
                      "
                    >
                      <img src="@/assets/nulldata.png" alt="" />
                      <span>暂无数据</span>
                    </div>
                    <div
                      class="nulldata"
                      v-if="
                        currentIndex == 1 &&
                        acceptance_personnelList.length > 0 &&
                        statsus != 6 &&
                        acceptance_personnelList.length > 0 &&
                        statsus != 7 &&
                        acceptance_personnelList.length > 0 &&
                        statsus != 8
                      "
                    >
                      <img src="@/assets/nulldata.png" alt="" />
                      <span>暂无数据</span>
                    </div>
                    <!-- 任务验收  && ks == 1 && details.PidInfo.status == 6 || currentIndex == 1 && ks == 1 && details.PidInfo.status == 7-->
                    <accePtanceCompoments
                      :routers="routers"
                      :acceptance_personnel="acceptance_personnelList"
                      v-if="
                        ks == 1 &&
                        ((acceptance_personnelList.length > 0 &&
                          statsus == 6) ||
                          (acceptance_personnelList.length > 0 &&
                            statsus == 7) ||
                          (acceptance_personnelList.length > 0 && statsus == 8))
                      "
                    />
                    <!-- v-if="!data.children" v-if="ks != 0" -->
                    <div class="no_data" v-if="currentIndex == 2">
                      <div class="no_data_content">
                        <div class="data_content">
                          <div
                            class="input_log"
                            :style="
                              data.children.length <= 0 ? '' : 'height:10%;'
                            "
                          >
                            <div class="input_log_data">
                              <div class="input_lof_time">
                                <button @click="inputPersonClicks">
                                  <span>{{
                                    inputpersonSelectName || "填写人"
                                  }}</span>
                                  <img
                                    v-if="!inputpersonSelectName"
                                    src="@/assets/bottom.png"
                                    alt=""
                                  />
                                  <img
                                    v-if="inputpersonSelectName"
                                    style="width: 14px; height: 14px"
                                    src="@/assets/clear.png"
                                    alt=""
                                  />
                                </button>
                                <button class="flex-c" @click="timeSelectClick">
                                  <span>{{ timeText || "日期" }}</span>
                                  <img
                                    v-if="!timeText"
                                    src="@/assets/bottom.png"
                                    alt=""
                                  />
                                  <img
                                    v-if="timeText"
                                    style="width: 0.24rem; height: 0.24rem"
                                    src="@/assets/clear.png"
                                    alt=""
                                  />
                                </button>
                              </div>
                              <div class="right_input_button">
                                <button @click="inputLogEvent(data)">
                                  填写日志
                                </button>
                              </div>
                            </div>
                          </div>
                          <!-- 任务日志 -->
                          <userlog
                            :userLog="userLog"
                            :isSelect="isSelect"
                            :isTask="isTask"
                            :offsets="1"
                          />
                        </div>
                      </div>
                      <div
                        class="nulldata"
                        v-if="userLog.length <= 0 && currentIndex == 2"
                      >
                        <img src="@/assets/nulldata.png" alt="" />
                        <span>暂无数据</span>
                      </div>
                    </div>
                    <!-- 奖励点 -->
                    <personCard
                      :personCard="personcarddate"
                      @customDescription="customDescription"
                      @customCorrect="customCorrect"
                      v-if="
                        currentIndex == 3 ||
                        data.status == 6 ||
                        data.status == 7
                      "
                    />
                    <!--  -->
                    <!-- <div class="bottom_button"
											v-if="data.totalNumber > data.children && data.children.length > 0">
											<div class="bottom_more" @click="moreClickFunction">
												<span>点击加载更多</span>
											</div>
										</div> -->
                  </div>
                </div>
              </div>
            </van-tab>
          </van-tabs>
        </div>
        <!-- <div class="review_button">
					buttonClassfiy !== '执行任务' && && statsus != 1
					<div class="review_button_bnot"
						v-if="buttonClassfiy != '发起任务' && statusFunction(statsus) == '待接单' || cardType || buttonClassfiy == ' 验收任务' && userinfoId == 2 || userinfoId == 1">
						<button @click="reviewEvent" v-if="cardType == '发起任务' || cardType == '审核任务' || buttonClassfiy == ' 验收任务' || statusFunction(statsus) == '验收中'">审核</button>
						<button
							v-if="buttonClassfiy && cardType != '发起任务' && buttonClassfiy != ' 验收任务' && details.PidInfo.status_text == '未接单' || details.PidInfo.status == 1"
							@click="OrdersFunction">接单</button>
						<button @click="assign"
							v-if="buttonClassfiy && cardType != '发起任务' && buttonClassfiy != ' 验收任务' && userinfoId != 1 && userinfoId != 2">指派</button>
					</div>
				</div> -->
      </div>
    </div>
    <van-calendar
      v-model="timeShow"
      :min-date="minDate"
      @confirm="timeOnConfirm"
    />
    <van-popup
      v-model="inputPersonShow"
      position="center"
      :style="{ width: '70%', 'border-radius': 10 + 'px' }"
    >
      <!-- <van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="onCancel" /> -->
      <div class="show_toast">
        <div class="show_toast_content">
          <div class="show_toast_content_items">
            <div class="task_money">
              <div class="task_money_tit">
                <span>任务奖励</span>
              </div>
              <div class="task_information">
                <div class="infomation_description">
                  <div class="task_name">
                    <span>姓名</span>
                    <span>{{ difficultyData.user_nickname }}</span>
                  </div>
                  <div class="task_difficulty">
                    <span>难度</span>
                    <div class="difficulty_content">
                      <img
                        src="../../assets/start.png"
                        v-for="(c, l) in difficultyData.difficulty_text"
                        :key="l"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="task_name">
                    <span>工作量</span>
                    <span>{{ difficultyData.workload }}</span>
                  </div>
                  <div class="task_name">
                    <span>工期</span>
                    <span>{{ difficultyData.timeout_text }}</span>
                  </div>
                  <div class="task_name">
                    <span>奖励点</span>
                    <span>{{ difficultyData.add_reward }}</span>
                  </div>
                  <div class="task_name">
                    <span>额外奖励点</span>
                    <span>{{ difficultyData.other_reward }}</span>
                  </div>
                  <div class="task_name">
                    <span>合计</span>
                    <span>{{
                      Number(difficultyData.add_reward) +
                      Number(difficultyData.other_reward)
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="task_button_review">
                <button @click="reviewClick(difficultyData)">审核</button>
              </div>
            </div>
            <!-- <div class="show_title">
							<span>通过该任务通过审核</span>
						</div>
						<div class="show_button">
							<button @click="closeEvents">取消</button>
							<button @click="sureEvents">确定</button>
						</div> -->
          </div>
        </div>
      </div>
    </van-popup>
    <van-dialog
      v-model="ProcesspauseFalse"
      title="暂停原因"
      show-cancel-button
      @confirm="confirmButton"
    >
      <div
        class="process_pause_title"
        style="display: flex; flex-direction: column"
      >
        <span
          >暂停时间:{{
            new Date().getFullYear() +
            "-" +
            (new Date().getMonth() + 1) +
            "-" +
            new Date().getDate() +
            " " +
            new Date().getHours() +
            ":" +
            (new Date().getMinutes() < 10
              ? "0" + new Date().getMinutes()
              : new Date().getMinutes()) +
            ":" +
            new Date().getSeconds()
          }}</span
        >
        <input
          type="text"
          v-model="ProcesspauseInput"
          placeholder="请输入暂停原因"
        />
      </div>
    </van-dialog>
    <van-dialog
      v-model="ProcesspausepointsShow"
      title="提示"
      show-cancel-button
      @confirm="confirmProcesspausepoints"
    >
      <div class="process_pause_titles">
        <span
          >该任务已经执行了{{
            closeTaskDescription.ago
          }}个工作日，请确定是否提前关闭，如果关闭， 奖励点结算为：</span
        >
      </div>
      <div class="process_pause_title">
        <input
          type="text"
          v-model="Processpausepoints"
          placeholder="请输入奖励点"
        />
      </div>
    </van-dialog>
    <van-popup
      v-model="inputpersontotalShow"
      position="bottom"
      style="height: 40%"
      round
    >
      <!-- @change="onChange" -->
      <van-picker
        title="标题"
        show-toolbar
        :columns="inputPersonTotaslArray"
        @confirm="oninputpersonConfirm"
        @cancel="onCancel"
      />
    </van-popup>
    <van-dialog
      v-model="additionalShow"
      title="修改自定义奖励点"
      show-cancel-button
      @confirm="additionalConfirm"
    >
      <div class="process_pause_title">
        <input
          type="text"
          v-model="additional_number"
          placeholder="请输入自定义奖励点"
        />
      </div>
    </van-dialog>
    <van-dialog
      v-model="showInput"
      title="编辑介绍"
      show-cancel-button
      @confirm="blurchange"
    >
      <div class="process_pause_title">
        <!-- <input type="text" v-model="additional_number" placeholder="请输入自定义奖励点"> -->
        <textarea
          v-model="inputtask"
          style="
            width: 80%;
            height: 100px;
            border: 1px solid #eee;
            padding: 10px;
            font-size: 14px;
          "
          maxlength="50"
        ></textarea>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import accePtanceCompoments from "@/acceptance/accePtance.vue";
import userlog from "@/myTeam/mydescription/compoments/userLog.vue";
import personCard from "@/rewards/components/personCard/personCard.vue";
import * as API from "@/api.ts";
import axios from "axios";
export default {
  components: {
    accePtanceCompoments,
    userlog,
    personCard,
  },
  props: [
    "data",
    "buttonClassfiy",
    "HeadersDescription",
    "children",
    "personAccept",
    "statsus",
    "cardType",
    "details",
    "acceptance_personnelList",
    "createtime_text",
    "personcarddate",
    "userLog",
    "inputPersonTotaslArray",
    "isTask", //是否是执行任务进去的
    "isSelect", //是否禁用任务
    // currentIndex:Number
  ],
  data() {
    return {
      value1: 0,
      tarbbarData: ["任务/步骤", "任务验收", "任务日志", "任务奖励"],
      active: "",
      expandShow: false,
      currentIndex: 0,
      expandeArray: [],
      key: 1,
      // userLog: [],
      timeShow: false,
      timeText: "",
      minDate: this.minDataFunction(),
      page: 1,
      limit: 10,
      status: "",
      fileList: [],
      taskIntroduce: "",
      readonly: false,
      inputtask: "",
      inputPersonShow: false,
      columns: [],
      // personcarddate: [],
      projectIDS: "",
      routers: "",
      descriptionData: [],
      userinfoId: "",
      // 奖励点审核
      difficultyData: "",
      // 用户判断id
      userIds: "",
      // 暂停扩建
      ProcesspauseFalse: false,
      // 暂停原因
      ProcesspauseInput: "",
      // 提交信息
      processsDescription: "",
      // 关闭任务奖励点
      ProcesspausepointsShow: false,
      // 输入奖励点
      Processpausepoints: "",
      // 关闭任务信息
      closeTaskDescription: "",
      // 填写人隐藏
      inputpersontotalShow: false,
      // 筛选时间
      selectDate: "",
      // 填写人显示
      inputpersonSelectName: "",
      // 修改自定义奖励点
      additional_number: "",
      // 暂存内容
      additionalContent: "",
      // 自定义奖励点开关
      additionalShow: false,
      // 编辑文字
      showInput: false,
    };
  },
  mounted() {
    this.active = Number(sessionStorage.getItem("active"));
    this.page = 1;
    this.$emit("tabbarClick", this.active.toString());
    this.currentIndex = Number(this.active);

    // this.Requset()
    this.taskIntroduce = this.$props.data.introduce;
    this.personnel();
    this.routers = this.$route.query.buttonClassfiy;
    this.userinfoId = window.sessionStorage.getItem("userInfo")
      ? JSON.parse(window.sessionStorage.getItem("userInfo")).identity
      : "";
    this.userIds = window.sessionStorage.getItem("userInfo")
      ? JSON.parse(window.sessionStorage.getItem("userInfo")).id
      : "";
    // this.status = this.$props.data.children.length > 0 ? this.$props.data.children[0].status : ''

  },
  watch: {
    inputtask(e) {
      clearTimeout(this.timeFunctions);
      this.timeFunctions = setTimeout(() => {
        this.inputtask = e;
        // this.readonly = true
      }, 800);
    },
  },
  methods: {
    customDescription(s) {
      this.$router.push({
        path: "/bounsPointsDescription",
        name: "bounsPointsDescription",
        query: {
          id: s.id,
          buttonClassfiy: this.$route.query.buttonClassfiy,
          user_id: this.$props.data.user_id,
          verify_text: s.verify_text,
        },
      });
    },
    SpliceTimes(e) {
      return e.split(" ")[0];
    },
    personnel() {
      API.personnel().then(async (e) => {
        if (e.code == 1) {
          // e.data.rows.map(item=>{
          // 	// this.columns.push(item)
          // 	console.log(item)
          // })
        }
      });
    },
    // Requset(times) {
    // 	console.log(this.$props)
    // 	API.personDaily({
    // 		id: this.$props.id ? this.$props.id : this.$route.query.id,
    // 		search: times,
    // 		page: this.page,
    // 		limit: 10,
    // 	}).then(async e => {
    // 		if (e.code == 1) {
    // 			if (e.data.rows.length > 0) {
    // 				this.userLog = [...this.userLog, ...e.data.rows]
    // 			} else {
    // 				// this.$toast.fail('暂无数据')
    // 			}
    // 		}
    // 	})
    // },
    timeSelectClick() {
      if (!this.timeText) {
        this.timeShow = !this.timeShow;
      } else {
        this.timeText = "";
        this.$emit(
          "tabbarClick",
          2,
          "",
          "",
          this.inputpersonSelectName ? this.inputpersonSelectName : ""
        );
      }
    },
    timeOnConfirm(date) {
      const dates = Date.parse(date);
      let startTime = this.formatDate(dates);
      this.timeText = startTime;
      this.selectDate = new Date(
        new Date(dates).setHours(0, 0, 0, 0)
      ).getTime();
      this.$emit("Refresh");
      this.$emit(
        "tabbarClick",
        2,
        this.selectDate ? this.selectDate / 1000 : "",
        this.selectDate ? this.selectDate / 1000 + 86399 : "",
        this.inputpersonSelectName ? this.inputpersonSelectName : ""
      );
      this.timeShow = !this.timeShow;
    },
    formatDate(date) {
      const data = new Date(date);
      const year = data.getFullYear();
      const mont =
        data.getMonth() + 1 < 10
          ? "0" + (data.getMonth() + 1)
          : data.getMonth() + 1;
      const monteh =
        data.getDate() < 10 ? "0" + data.getDate() : data.getDate();
      return `${year + "-" + mont + "-" + monteh}`;
    },
    tabbarClick(e) {
      this.timeText = "";
      this.page = 1;
      this.inputpersonSelectName = "";
      this.$emit("tabbarClick", e);
      this.currentIndex = Number(e);
      sessionStorage.setItem("active", e);
      // if (this.$route.query.cardType == '审核任务') {
      // 	return true;
      // } else {
      // }
      // if (e == 3) {
      // 	this.personcarddate = []

      // }
    },
    expandEvent() {
      this.expandShow = !this.expandShow;
      // this.expandeArray = s.step
    },
    reviewEvent(s) {
      if (
        s.status == 5 ||
        (s.status == 3 && s.status_text == "拆解未审核") ||
        (s.status == 2 && s.status_text == "审核中") ||
        (s.status == 1 && s.status_text == "审核中")
      ) {
        const parms = {
          ids: s.id,
          verify: 2,
        };
        this.$toast.loading("审核中，请稍等");
        API.editrelease_review(parms).then(async (e) => {
          this.$toast({
            type: e.code == 0 ? "fail" : "success",
            message: e.code == 0 ? e.msg : "审核成功",
          });
          if (e.code == 1) {
            setTimeout(() => {
              this.$router.back(-2);
              // this.$emit('Refresh')
              // this.inputPersonShow = !this.inputPersonShow
            }, 1000);
          }
        });
      } else {
        if (s.status_text == "奖励点审核" && s.status == 10) {
          this.inputPersonShow = !this.inputPersonShow;
          this.difficultyData = s;
        }
      }
    },
    sureEvents() {
      // let rid = ''
      // if (this.$route.query.id) {
      // 	rid = this.$route.query.id
      // } else if (this.$route.query.taskID) {
      // 	rid = this.$route.query.taskID
      // } else {
      // 	console.log(this.$route.query.rid)
      // 	rid = this.$route.query.rid
      // }
      // // if(this.$route.query.task && this.$route.query.buttonClassfiy == '审核任务'){
      // // 	rid = this.$route.query.id
      // // }else
      // const parms = {
      // 	ids: rid,
      // 	verify: 2
      // }
      // if (this.$route.query.buttonClassfiy == '审核任务') {
      // 	API.editrelease_review(parms).then(async e => {
      // 		this.$toast({
      // 			type: e.code == 0 ? 'fail' : 'success',
      // 			message: e.code == 0 ? e.msg : '审核成功'
      // 		})
      // 		if (e.code == 1) {
      // 			setTimeout(() => {
      // 				this.$router.back(-1)
      // 				this.inputPersonShow = !this.inputPersonShow
      // 			}, 1000)
      // 		}
      // 	})
      // } else {
      // 	API.sureCheck(parms).then(async e => {
      // 		this.$toast({
      // 			type: e.code == 0 ? 'fail' : 'success',
      // 			message: e.code == 0 ? e.msg : '审核成功'
      // 		})
      // 		if (e.code == 1) {
      // 			setTimeout(() => {
      // 				this.$router.back(-1)
      // 				this.inputPersonShow = !this.inputPersonShow
      // 			}, 1000)
      // 		}
      // 	})
      // }
    },
    closeEvents() {
      this.inputPersonShow = !this.inputPersonShow;
    },
    assign() {
      this.$router.push({
        naem: "specfiy",
        path: "/specfiy",
      });
    },
    receiving(e, s) {
      if (e.status == 5) {
        this.sureClickEvent(
          {
            id: s.id,
            manner: s.manner,
          },
          1,
          e.status
        );
        // e.process.map((item, index) => {
        // 	if (e.process[index].status == 3) {
        // 		this.sureClickEvent({
        // 			id: s.id,
        // 			manner: s.manner
        // 		}, 1, e.status)
        // 	} else {
        // 		this.$toast.fail('任务/步骤未全部完成')
        // 	}
        // })
        if (e.process.length <= 0) {
          this.sureClickEvent(
            {
              id: s.id,
              manner: s.manner,
            },
            1,
            e.status
          );
        }
      }
      if (e.status == 6) {
        this.sureClickEvent(
          {
            id: s.id,
            manner: s.manner,
          },
          1,
          e.status
        );
      }
      if (e.status == 4) {
        this.sureClickEvent(s, 0);
      } else {
        this.statusFunction(e.status, e.status, s);
      }
    },
    inputLogEvent(s) {
      sessionStorage.setItem("active", this.active);
      this.$router.push({
        name: "inputLog",
        path: "/inputLog",
        query: {
          taskid: this.$route.query.id || this.$route.query.taskID,
          data: JSON.stringify(s),
          isSelect: this.isSelect,
        },
      });
    },
    onunload() {
      API.details({
        ids: this.$route.query.taskID || this.$route.query.rid,
      }).then(async (e) => {
        if (e.code == 1) {
          e.data.rows.map((item) => {
            this.descriptionData.push({
              projectName: item.title,
              ...item,
              children: [
                {
                  name: "执行人：",
                  text: item.user_nickname,
                },
                {
                  name: "工作量：",
                  text:
                    this.userinfoId <= 1
                      ? item.workload + "天"
                      : item.workload + "天(预)",
                },
                {
                  name: "开始时间：",
                  text:
                    item.starttime_text || item.starttime
                      ? item.starttime_text + "(预)" ||
                        this.TimeFunction(item.starttime) + "(预)"
                      : "",
                },
                {
                  name: "完成时间：",
                  text:
                    item.endtime_text || item.updatetime
                      ? item.endtime_text + "(预)" ||
                        this.TimeFunction(item.updatetime) + "(预)"
                      : "",
                },
              ],
              step: this.processFunction(item.process),
            });
          });
          this.self_task();
        }
      });
    },
    statusFunction(e, clicks, s) {
      let name;
      switch (e) {
        case 1:
          if (e && this.$props.HeadersDescription) {
            if (this.$props.HeadersDescription.type == 1) {
              name = "待审核";
            } else if (this.$props.HeadersDescription.type == 2) {
              name = "工期审核";
            }
          } else {
            if (s.status == 1 && s.status_text == "审核中") {
              name = "待审核";
            } else {
              name = "待接单";
            }
          }
          // if (clicks) {
          // 	API.OrderTaking({
          // 		ids: s.id
          // 	}).then(async e => {
          // 		this.$toast({
          // 			type: e.code == 1 ? 'success' : 'fail',
          // 			message: e.msg
          // 		})
          // 		if (e.code == 1) {
          // 			setTimeout(() => {
          // 				this.onunload()
          // 				location.reload()
          // 			}, 800);
          // 		}
          // 	})
          // }
          break;
        case 2:
          if (e == 2 && s.status_text == "审核中") {
            name = "审核中";
          } else {
            name = "待拆解";
          }
          break;
        case 3:
          name = "拆解未审核";
          this.onunload();
          break;
        case 4:
          name = "未开始";
          this.onunload();
          break;
        case 5:
          if (clicks == "审核中") {
            name = "审核中";
          } else if (clicks == "进行中") {
            name = "进行中";
          }
          this.onunload();
          break;
        case 6:
          name = "验收中";
          this.onunload();
          break;
        case 7:
          name = "已完成";
          break;
        case 8:
          if (clicks == "暂停中") {
            name = "暂停中";
          } else {
            name = "等待验收";
          }
          break;
        case 10:
          name = "奖励点审核";
          break;
      }
      return name;
    },
    moreClickFunction() {
      if (this.currentIndex == 2) {
        this.page += 1;
        this.$toast.loading("加载中...");
        setTimeout(() => {
          this.Requset();
        }, 1000);
      }

      if (this.currentIndex == 0) {
        this.$emit("moreClickFunction");
      }
    },
    self_task() {
      API.self_task({
        page: this.page,
      }).then(async (e) => {
        if (e.code == 1) {
          // if (e.data.rows.length <= 0) {
          // 	this.$toast({
          // 		type: 'fail',
          // 		message: '暂无数据'
          // 	})
          // }
          this.descriptionData.totalNumber = e.data.rows;
          //   console.log(e.data.rows);
          e.data.rows.map(() => {
            // console.log(item.status, this.HeadersDescription);
            // if (item.status == this.$props.HeadersDescription.status && item.id == this.$props.HeadersDescription.id) {
            // 	this.descriptionData.children.push({
            // 		projectName: item.project_title ? item.project_title : '',
            // 		...item,
            // 		children: [{
            // 			name: '执行人：',
            // 			text: item.nickname
            // 		}, {
            // 			name: '工作量：',
            // 			text: this.userinfoId != 4 && item
            // 				.status_text == '审核中' ? '-' : item
            // 					.workload + '天(预)'
            // 		}, {
            // 			name: '开始时间：',
            // 			text: this.userinfoId != 4 && item
            // 				.status_text == '审核中' ? '-' : item
            // 					.real_starttime != null || item
            // 						.real_starttime != '' ? this.TimeFunction(
            // 							item.real_starttime) + '(预)' : ''
            // 		}, {
            // 			name: '完成时间：',
            // 			text: this.userinfoId != 4 && item
            // 				.status_text == '审核中' ? '-' : item
            // 					.real_endtime != null || item
            // 						.real_endtime != '' ? this.TimeFunction(
            // 							item.real_endtime) + '(预)' : ''
            // 		}],
            // 		step: this.processFunction(item.process)
            // 	})
            // }
          });
        }
      });
    },
    sureClickEvent(s, k, status) {
      const parms = {
        ids: s ? s.id : "",
        manner: s ? s.manner : "",
        status: k == 0 ? 2 : 3,
      };
      if (
        (s.status_text == "已完成" &&
          this.buttonClassfiy == " 验收任务" &&
          s.status == 3) ||
        (s.status_text == "验收中" &&
          this.userinfoId != 1 &&
          s.status == 6 &&
          this.buttonClassfiy == " 验收任务")
      ) {
        API.sureCheck(parms).then(async (e) => {
          this.$toast({
            type: e.code == 1 ? "success" : "fail",
            message: e.code == 1 ? "验收成功" : "验收失败",
          });
          if (e.code == 1) {
            setTimeout(() => {
              this.$router.back(-2);
              this.$emit("Refresh");
              this.$emit("release_reviewDescription");
            }, 800);
          }
        });
      } else {
        if (s) {
          API.ProcessEdit(parms).then(async (e) => {
            this.$toast({
              type: e.code == 1 ? "success" : "fail",
              message: e.msg,
            });
            if (e.code == 1) {
              this.$emit("Refresh");
              // API.details({
              // 	ids: this.$route.query.taskID || this.$route.query.rid
              // }).then(async e => {
              // 	if (e.code == 1) {
              // 		// e.data.rows.map(item => {
              // 		// 	this.descriptionData.push({
              // 		// 		projectName: item.title,
              // 		// 		...item,
              // 		// 		children: [{
              // 		// 			name: '执行人：',
              // 		// 			text: item.user_nickname
              // 		// 		}, {
              // 		// 			name: '工作量：',
              // 		// 			text: item.workload +
              // 		// 				'天(预)'
              // 		// 		}, {
              // 		// 			name: '开始时间：',
              // 		// 			text: item
              // 		// 				.starttime_text || item
              // 		// 					.starttime ? item
              // 		// 						.starttime_text +
              // 		// 					'(预)' || this
              // 		// 						.TimeFunction(item
              // 		// 							.starttime) +
              // 		// 			'(预)' : ''
              // 		// 		}, {
              // 		// 			name: '完成时间：',
              // 		// 			text: item.endtime_text ||
              // 		// 				item.updatetime ? item
              // 		// 					.endtime_text +
              // 		// 				'(预)' || this
              // 		// 					.TimeFunction(item
              // 		// 						.updatetime) +
              // 		// 			'(预)' : ''
              // 		// 		}],
              // 		// 		step: this.processFunction(item
              // 		// 			.process)
              // 		// 	})
              // 		// })
              // 	}
              // })
            }
          });
        } else if (status == 6) {
          API.sureCheck(parms).then(async (e) => {
            this.$toast({
              type: e.code == 1 ? "success" : "fail",
              message: e.msg,
            });
            if (e.code == 1) {
              setTimeout(() => {
                this.$router.back(-1);
              }, 1000);
            }
          });
        }
      }
    },
    minDataFunction() {
      let now = new Date();
      return new Date(now.getFullYear(), now.getMonth(), 1);
    },
    timeFunction(e) {
      const date = new Date(e * 1000),
        Y = date.getFullYear(),
        M = date.getMonth(),
        R = date.getDate();
      return (
        Y +
        "-" +
        (M < 10 ? "0" + (M + 1) : M) +
        "-" +
        (R < 10 ? "0" + (R + 1) : R)
      );
    },
    newdescriptionclcik(e) {
      this.$router.push({
        name: "messagedescriptions",
        path: "/messagedescriptions",
        query: {
          introduce: e.introduce,
        },
      });
    },
    OrdersFunction(id) {
      API.OrderTaking({
        ids: id,
      }).then(async (e) => {
        this.$toast({
          type: e.code == 0 ? "fail" : "success",
          message: e.code == 1 ? "接单成功" : e.msg,
        });
        if (e.code == 1) {
          setTimeout(() => {
            this.$router.back(-1);
          }, 1000);
        }
      });
    },
    onafterprint(file) {
      // this.fileList.push(file.target.files[0].name)
      let data = new FormData();
      data.append("file", file.target.files[0]);
      let config = {
        //添加请求头
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios.post("/admin/ajax/upload", data, config).then(async (e) => {
        if (e.data.status == 203) {
          this.$notfiy
            .confirm({
              title: "请重新登录",
              message: "登录已失效，请重新登录",
            })
            .then(() => {
              setTimeout(() => {
                window.location.href = "/";
              }, 800);
            })
            .catch(() => {
              this.$toast({
                type: "fail",
                message: "取消登录",
              });
            });
        }
        if (e.data.status == 200) {
          this.$toast({
            type: "success",
            message: "上传成功",
          });
          let data = this.$props.data.appendix;
          let newImage = [];
          data.map((item) => {
            if (item.url) {
              newImage.push(item.url.split(".cn")[1]);
            }
          });
          API.addImage({
            id: this.$route.query.id,
            attachment:
              newImage.length > 0
                ? newImage.join(",") + "," + e.data.data.url
                : e.data.data.url,
            type: 1,
          }).then(async (s) => {
            if (s.code == 1) {
              this.$emit("release_reviewDescription");
            }
          });
        }
      });
    },
    onafterprintImage(file) {
      // this.fileList.push(file.target.files[0].name)
      let data = new FormData();
      data.append("file", file.target.files[0]);
      let config = {
        //添加请求头
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios.post("/admin/ajax/upload", data, config).then(async (e) => {
        if (e.data.status == 203) {
          this.$notfiy
            .confirm({
              title: "请重新登录",
              message: "登录已失效，请重新登录",
            })
            .then(() => {
              setTimeout(() => {
                window.location.href = "/";
              }, 800);
            })
            .catch(() => {
              this.$toast({
                type: "fail",
                message: "取消登录",
              });
            });
        }
        if (e.data.status == 200) {
          this.$toast({
            type: "success",
            message: "上传成功",
          });
          let data = this.$props.data.taskimg_info;
          let newImage = [];
          data.map((item) => {
            if (item.url) {
              newImage.push(item.url.split(".cn")[1]);
            }
          });
          API.addImage({
            id: this.$route.query.id,
            attachment:
              newImage.length > 0
                ? newImage.join(",") + "," + e.data.data.url
                : e.data.data.url,
            type: 2,
          }).then(async (s) => {
            if (s.code == 1) {
              this.$emit("release_reviewDescription");
            }
          });
        }
      });
    },
    deleteImageClick(s, k) {
      API.deleteImg({
        id: this.$route.query.id,
        img_id: s.id,
        type: k == 2 ? 2 : 1,
      }).then(async (e) => {
        this.$toast({
          type: e.code == 1 ? "success" : "fail",
          message: e.msg,
        });
        if (e.code == 1) {
          this.$emit("release_reviewDescription");
        }
      });
    },
    editTextFunctiuon(s) {
      this.showInput = !this.showInput;
      this.inputtask = s;
      // this.taskIntroduce = this.inputtask ? this.inputtask : this.$props.data.introduce
      // this.readonly = !this.readonly
    },
    // 奖励点审核
    inputPersonClick() {
      this.inputPersonShow = !this.inputPersonShow;
    },
    // 填写人
    inputPersonClicks() {
      if (!this.inputpersonSelectName) {
        this.inputpersontotalShow = !this.inputpersontotalShow;
      } else {
        this.inputpersonSelectName = "";
      }
      if (this.timeText) {
        this.$emit(
          "tabbarClick",
          2,
          this.selectDate ? this.selectDate / 1000 : "",
          this.selectDate ? this.selectDate / 1000 + 86399 : "",
          this.inputpersonSelectName ? this.inputpersonSelectName : ""
        );
      } else {
        this.$emit(
          "tabbarClick",
          2,
          "",
          "",
          this.inputpersonSelectName ? this.inputpersonSelectName : ""
        );
      }
    },
    onConfirm(value, index) {
      this.$toast(`当前值：${value}, 当前索引：${index}`);
    },
    onCancel() {
      this.inputpersontotalShow = false;
      //   this.$toast("取消");
    },
    customCorrect(e) {
      this.$router.push({
        name: "settingRewards",
        path: "/settingRewards",
        query: {
          e: e,
        },
      });
    },
    // j奖励点审核
    reviewClick(s) {
      this.$router.push({
        name: "reviewClick",
        path: "/reviewClick",
        query: {
          reviewDescriptionsClick: s,
        },
      });
    },
    Processpause(s) {
      this.ProcesspauseFalse = !this.ProcesspauseFalse;
      this.processsDescription = s;
    },
    // 暂停原因
    confirmButton() {
      if (this.ProcesspauseInput) {
        // this.processsDescription
        API.Processpause({
          id: this.processsDescription.id,
          type:
            (this.processsDescription?.process
              ? this.processsDescription.length > 0
              : this.processsDescriptio?.id != "" ||
                this.processsDescriptio?.id != null ||
                this.processsDescriptio?.id != undefined
              ? 1
              : this.processsDescription.length <= 0
              ? 2
              : 2) == false
              ? 2
              : 1,
          reason: this.ProcesspauseInput,
        }).then(async (e) => {
          this.$toast({
            type: e.code == 1 ? "success" : "fail",
            message: e.msg,
          });
          if (e.code == 1) {
            this.$emit("Refresh");
            this.ProcesspauseInput = "";
          }
        });
      } else {
        this.$toast.fail("请输入暂停原因必填");
      }
    },
    // 步骤
    processFunction(e) {
      let data = [];
      if (e.length > 0) {
        e.map((items) => {
          data.push({
            name: items.sequence,
            text: items.title,
            ...items,
          });
        });
      }
      return data;
    },
    // 取消
    pauseCancel(s) {
      API.pauseCancel({
        id: s.id,
        type: s.pid ? 2 : 1,
      }).then(async (e) => {
        this.$toast({
          type: e.code == 1 ? "success" : "fail",
          message: e.msg,
        });
        if (e.code == 1) {
          this.$emit("Refresh");
        }
      });
    },
    // 开始
    pauseStart(s) {
      API.pauseStart({
        id: s.id,
        type: s.pid ? 2 : 1,
      }).then(async (e) => {
        this.$toast({
          type: e.code == 1 ? "success" : "fail",
          message: e.msg,
        });
        if (e.code == 1) {
          this.$emit("Refresh");
        }
      });
    },
    // 关闭任务
    closetask(s) {
      this.ProcesspausepointsShow = !this.ProcesspausepointsShow;
      this.closeTaskDescription = s;
    },
    // 确认关闭
    confirmProcesspausepoints() {
      const parms = {
        ids: this.closeTaskDescription.id ? this.closeTaskDescription.id : "",
        reward: this.Processpausepoints ? this.Processpausepoints : "",
      };
      API.close_task(parms).then(async (e) => {
        this.$toast({
          type: e.code == 1 ? "success" : "fail",
          message: e.msg,
        });
        if (e.code == 1) {
          this.ProcesspausepointsShow = !this.ProcesspausepointsShow;
          this.$emit("Refresh");
        }
      });
    },
    // 填写人回传
    oninputpersonConfirm(e) {
      this.inputpersontotalShow = !this.inputpersontotalShow;
      this.inputpersonSelectName = e;
      if (this.timeText) {
        this.$emit(
          "tabbarClick",
          2,
          this.selectDate ? this.selectDate / 1000 : "",
          this.selectDate ? this.selectDate / 1000 + 86399 : "",
          e ? e : ""
        );
      } else {
        this.$emit("tabbarClick", 2, "", "", e ? e : "");
      }
    },
    // 更改验收人
    acceptorPerson() {
      this.$router.push({
        name: "acceptorPerson",
        path: "/acceptorPerson",
        query: {
          acceptorPerson: 1,
        },
      });
    },
    // 失焦保存
    blurchange() {
      this.readonly = false;
      API.editTask({
        ids: this.$route.query.id,
        introduce: this.inputtask,
      }).then(async (e) => {
        this.$toast({
          type: e.code == 1 ? "success" : "fail",
          message: e.msg,
        });
        if (e.code == 1) {
          this.inputtask = "";
          this.$emit("release_reviewDescription");
        }
      });
    },
    // 编辑奖励点
    editreviewNumber(s) {
      this.additionalShow = !this.additionalShow;
      this.additionalContent = s;
    },
    additionalConfirm() {
      API.editTask({
        ids: this.additionalContent.id,
        additional_number: this.additional_number,
      }).then(async (e) => {
        this.$toast({
          type: e.code == 1 ? "success" : "fail",
          message: e.msg,
        });
        if (e.code == 1) {
          this.$emit("Refresh");
          this.$emit("release_reviewDescription");
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.review_description {
  z-index: 200;
  .review_description_content {
    background: white;
    border-top-left-radius: calcFunction(16);
    border-top-right-radius: calcFunction(16);

    .review_description_content_data {
      .review_description_task {
        margin: calcFunction(20);
        padding-top: calcFunction(20);

        .review_task_header {
          span {
            color: #666666;
            font-size: calcFunction(14);
          }

          span:nth-last-child(1) {
            color: #375394;
            text-decoration: underline;
          }
        }

        .review_task_associate {
          .reviw_associate_list {
            @extend .alignItemsCenter;
            justify-content: space-between;
            flex-wrap: wrap;

            .associate_task {
              margin-top: calcFunction(10);
              width: 50%;

              span {
                font-size: calcFunction(14);
                color: #666666;

                i {
                  font-style: normal;
                }
              }

              span:nth-last-child(1) {
                color: #333333;
              }
            }
          }
        }

        .task_introduce {
          .task_introduce_content {
            .task_introduce_title {
              @extend .alignItemsCenter;
              margin-top: calcFunction(10);
              justify-content: space-between;

              .task_intrduce_text_box {
                @extend .alignItemsCenter;

                span {
                  font-size: calcFunction(14);
                  color: #666666;
                  // white-space: nowrap;
                }

                span:nth-last-child(1) {
                  // width: 220px;
                  color: #333333;
                  // white-space: nowrap;
                  // text-overflow: ellipsis;
                  // overflow: hidden;
                }

                input {
                  border: none;
                  font-size: calcFunction(14);
                }
              }

              .task_intrduce_image {
                @extend .alignItemsCenter;

                img {
                  width: calcFunction(15);
                  height: calcFunction(15);
                }
              }
            }
          }
        }

        .task_appendix {
          margin-top: calcFunction(10);

          .task_appendix_box {
            .task_appendix_content {
              .task_appendix_list {
                .task_appendix_title {
                  @extend .alignItemsCenter;
                  align-items: flex-start;

                  > span {
                    font-size: calcFunction(14);
                    color: #666666;
                  }

                  .appendix_file {
                    flex: 1;

                    .appendix_file_list {
                      @extend .alignItemsCenter;
                      justify-content: space-between;
                      margin-bottom: calcFunction(10);

                      span {
                        color: #375394;
                        text-decoration: underline;
                        font-size: calcFunction(14);
                        width: 260px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      }

                      img {
                        width: calcFunction(14);
                        height: calcFunction(17);
                      }
                    }

                    .add_appendix_file {
                      .sure_uploading_image_text_box {
                        @extend .alignItemsCenter;

                        img {
                          width: calcFunction(15);
                          height: calcFunction(15);
                        }

                        span {
                          color: #d71518;
                          font-size: calcFunction(12);
                          margin-left: calcFunction(4);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .review_description_classfiy {
        background: #f1f0f5;

        .review_description_content_list_classfiy {
          // height:50vh;
          .review_description_list {
            padding: calcFunction(10);

            .review_content_descriptions {
              .review_lists {
                .reveiw_for_content {
                  background-color: white;
                  border-radius: calcFunction(8);
                  padding: calcFunction(15);
                  margin-bottom: calcFunction(10);

                  .review_for_content_header {
                    border-bottom: 1px solid #eeeeee;
                    padding-bottom: calcFunction(15);
                    @extend .alignItemsCenter;
                    justify-content: space-between;

                    span {
                      font-size: calcFunction(14);
                      color: #111111;
                    }
                  }

                  .review_content_person_list {
                    margin-top: calcFunction(15);

                    .review_content_persons {
                      @extend .alignItemsCenter;
                      flex-wrap: wrap;

                      .text_for_box_person {
                        width: 50%;
                        margin-bottom: calcFunction(15);

                        span {
                          font-size: calcFunction(12);
                          color: #333333;

                          i {
                            font-style: normal;
                          }
                        }

                        span:nth-child(1) {
                          color: #999999;
                        }
                      }

                      .text_for_box_person:nth-last-child(1),
                      .text_for_box_person:nth-last-child(2) {
                        // margin-bottom: 0;
                      }
                    }
                  }

                  .expand_click {
                    // margin-top: calcFunction(10);

                    .expand_click_text {
                      margin-top: calcFunction(10);
                      @extend .alignItemsCenter;
                      justify-content: center;

                      span {
                        color: #375394;
                        font-size: calcFunction(14);
                      }
                    }
                  }

                  .expand_description {
                    .expand_description_content {
                      .expand_description_content_for {
                        .expand_for {
                          margin-top: calcFunction(15);

                          .expand_step_number {
                            .expand_step_number_title {
                              position: relative;
                              margin-bottom: calcFunction(10);

                              span {
                                font-size: calcFunction(14);
                                color: #666666;
                                padding-left: calcFunction(20);
                              }

                              &::after {
                                width: calcFunction(10);
                                height: calcFunction(10);
                                background: #d71518;
                                content: "";
                                position: absolute;
                                border-radius: calcFunction(100);
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                              }
                            }

                            .expand_step_number_text {
                              padding: calcFunction(14) calcFunction(15);
                              border: 1px solid #dddddd;
                              background: #fafafa;
                              border-radius: calcFunction(4);
                              @extend .alignItemsCenter;
                              justify-content: space-between;

                              span {
                                font-size: calcFunction(13);
                                font-weight: bold;
                                color: #111111;
                              }

                              button {
                                border: 1px solid #d71518;
                                padding: calcFunction(4) calcFunction(10);
                                font-size: calcFunction(13);
                                color: #d71518;
                                border-radius: calcFunction(4);
                                background: white;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.active {
  transform: rotate(0deg);
  transition-duration: 0.5s;
}

.orgin {
  transform: rotate(180deg);
  transition-duration: 0.5s;
}

.review_button {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;

  .review_button_bnot {
    padding: calcFunction(10) calcFunction(30);
    @extend .alignItemsCenter;

    button {
      border: 1px solid #d71518;
      outline: none;
      width: 100%;
      height: calcFunction(44);
      background: transparent;
      color: #d71518;
      border-radius: calcFunction(4);
      font-size: calcFunction(17);
    }

    button:nth-child(2) {
      margin-left: calcFunction(10);
      background: #ffe2e2;
      border: none;
    }
  }
}

.perform_tasks {
  .perform_tasks_data {
    button {
      border: none;
      background: transparent;
      outline: none;
      font-size: calcFunction(13);
    }

    button:nth-child(1) {
      margin-right: calcFunction(8);
    }

    button:nth-last-child(1) {
      background: #d71518;
      color: white;
      padding: calcFunction(3) calcFunction(10);
      border-radius: calcFunction(4);
    }
  }
}

// 暂无数据
.no_data {
  height: 100%;

  .no_data_content {
    height: 100%;

    .data_content {
      .input_log {
        margin-top: calcFunction(5);

        .input_log_data {
          @extend .alignItemsCenter;
          justify-content: space-between;
          margin-bottom: calcFunction(10);

          .input_lof_time {
            @extend .alignItemsCenter;

            button {
              padding: calcFunction(4) calcFunction(10);
              @extend .alignItemsCenter;
              border: none;
              background: white;
              border-radius: calcFunction(4);

              span {
                font-size: calcFunction(13);
                color: #333333;
              }

              img {
                width: calcFunction(12);
                height: calcFunction(6);
              }
            }

            button:nth-last-child(1) {
              margin-left: calcFunction(15);
            }
          }

          .right_input_button {
            @extend .alignItemsCenter;

            button {
              background: #d71518;
              color: white;
              border: none;
              outline: none;
              font-size: calcFunction(13);
              padding: calcFunction(6) calcFunction(10);
              border-radius: calcFunction(4);
            }
          }
        }
      }
    }
  }
}

.performFlex {
  @extend .alignItemsCenter;
  justify-content: space-between;
}

.performTasks {
  // height: 50vh !important;
}

.bulearrow {
  width: calcFunction(10) !important;
  height: calcFunction(17) !important;
}

.displayEach {
  display: none;
}

.bottom_button {
  background: transparent;
  position: relative;
  bottom: -15px;

  .bottom_more {
    padding: calcFunction(10) 0;
    justify-content: center;
    @extend .alignItemsCenter;

    span {
      font-size: calcFunction(14);
      color: #999;
    }
  }
}

.greend {
  border: 1px solid #00ba0c !important;
  color: #00ba0c !important;
}

.expand_step_text_title {
  @extend .alignItemsCenter;
  justify-content: space-between;

  span:nth-last-child(1) {
    color: #666666 !important;
    font-weight: 500 !important;
  }
}

.complete {
  flex-direction: column;
  align-items: flex-start !important;

  .expadn_step_startTime {
    @extend .alignItemsCenter;
    justify-content: space-between;
    width: 100%;
    margin-top: calcFunction(20);

    span {
      font-weight: 500 !important;
      color: #999999 !important;
    }

    span:nth-last-child(1) {
      color: black !important;
    }
  }
}

.filelist {
  color: #375394 !important;
  width: 100%;
  text-decoration: underline;
  font-size: calcFunction(14);
}

.file_list_delete {
  @extend .alignItemsCenter;
  flex-wrap: wrap;

  .file_delete_box {
    @extend .alignItemsCenter;
    justify-content: space-between;
    width: 100%;

    img {
      width: calcFunction(15);
      height: calcFunction(15);
    }
  }
}

.personsData {
  // span:nth-last-child(1) {
  // 	color: #375394;
  // }
}

.expadns_step {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
  align-items: flex-start !important;

  div {
    width: 50%;
    margin-bottom: 10px;

    span {
      font-weight: 400 !important;
      font-size: 14px;
    }
  }
}

.nulldata {
  @extend .alignItemsCenter;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  img {
    width: 50%;
  }

  span {
    margin-top: calcFunction(10);
    color: #999999;
    font-size: calcFunction(12);
  }
}

.time_list {
  i {
    font-style: normal;
  }
}
</style>
<style lang="scss">
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.review_description_classfiy {
  background: #f1f0f5;

  .van-tabs__wrap {
    border-top: 1px solid #ddd;
    padding-bottom: calcFunction(5);

    .van-tab__text {
      font-size: calcFunction(14);
      font-family: "PingFang SC";
    }
  }

  .van-tabs__line {
    width: 32px;
    bottom: 19px;
  }

  .van-uploader {
    height: 0;
    width: 0;
  }
}

.add_appendix_file {
  position: relative;

  img: {
    margin-top: 4px;
  }

  span {
    margin-top: 2px;
  }

  .trin_button_uploding {
    position: relative;

    .trin_button_uplodings {
      position: absolute;
      top: 0;
      opacity: 0;

      input {
        width: 40%;
      }
    }
  }

  .uploading {
    .uploadings {
      padding: calcFunction(10);
      background: #f1f0f5;
      @extend .alignItemsCenter;
      margin-top: calcFunction(10);
      justify-content: space-between;
      border-radius: calcFunction(4);

      span {
        font-size: calcFunction(14);
      }

      img {
        width: calcFunction(15);
        height: calcFunction(15);
      }
    }
  }
}

.show_toast {
  .show_toast_content {
    .show_toast_content_items {
      padding-bottom: 30px;

      .show_title {
        padding: 3rem 0;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          text-align: center;
        }
      }

      .show_button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 20px;

        button {
          width: 50%;
          padding: 8px 0;
          border: 1px solid rgba(0, 0, 0, 0.1);
          outline: none;
          font-size: 14px;
          background: transparent;
          border-radius: 5px;
        }

        button:nth-last-child(1) {
          margin-left: 20px;
          background: #d71518;
          color: white;
        }
      }
    }
  }
}

.task_money {
  margin: 20px;

  .task_money_tit {
    margin: 20px 0;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;

    span {
    }
  }

  .task_information {
    .infomation_description {
      .task_difficulty {
        img {
          height: 20px;
          width: 20px;
        }
      }

      .task_difficulty {
        margin-bottom: calcFunction(20);
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .task_name {
        margin-bottom: calcFunction(20);
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-size: calcFunction(15);
        }

        span:nth-last-child(1) {
          margin-top: calcFunction(4);
          font-size: calcFunction(13);
        }
      }
    }
  }
}

.difficulty_content {
  display: flex;
  align-items: center;

  img {
    margin-right: 4px;
  }

  img:nth-last-child(1) {
    margin-right: 0;
  }
}

.task_button_review button {
  width: 100%;
  padding: 8px 0;
  background: red;
  color: white;
  font-size: 14px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
}

.OrdersButton {
  border: 1px solid red !important;
  padding: 2px 10px;
  border-radius: 2px;
  font-size: 14px;
  color: red;
}

.process_pause_title {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    border: 1px solid #fafafa;
    padding: 5px;
    padding: 10px;
    font-size: 15px;
    text-align: center;
  }
}

.expand_setop_buttons {
  button {
    margin-right: 4px;
  }
}

.expand_task_buttons {
  display: flex;
  align-items: center;

  button {
    border: 1px solid red !important;
    padding: 2px 6px;
    color: red;
    font-size: 14px;
    border-radius: 2px;
  }
}

.process_pause_titles {
  font-size: 14px;
  margin: 0 10px;
  text-align: center;
  margin-top: 10px;
}

.suspend_style {
  span {
    font-weight: 400 !important;
    margin-bottom: 4px;
    font-size: 14px;
  }

  span:nth-last-child(1) {
    font-size: 12px;
  }
}
</style>
<style>
body {
  background: #f1f0f5;
  /* height: 100%; */
}
</style>
