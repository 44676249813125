import axios from "axios";
import { Toast, Dialog } from "vant";

const requestList = [];
const cancelToken = axios.CancelToken;
const sources = {};
const ContentType = {
  FROM: "application/x-www-form-urlencoded;charset=utf-8",
  JSON: "application/json;charset=utf-8",
};
axios.defaults.headers.get["Content-Type"] = ContentType.FROM;
axios.defaults.headers.post["Content-Type"] = ContentType.JSON;
axios.defaults.timeout = 50000;

// axios.defaults.baseURL = "https://zyfactorymanage.59156.cn";
axios.defaults.baseURL = "https://api.octopusworks.com";
// http://zyfactory.59156.cn
// 
axios.interceptors.request.use(
  (config) => {
    const request =
      JSON.stringify(config.url) + JSON.stringify(config.data || "");
    config.cancelToken = new cancelToken((cancel) => {
      sources[request] = cancel;
    });
    // config.headers['token'] = localStorage.getItem('token') || "";
    if (window.sessionStorage.getItem("userInfo")) {
      config.headers["token"] = JSON.parse(
        window.sessionStorage.getItem("usertoken")
      );
      // JSON.parse(window.sessionStorage.getItem('userInfo')).token
    }
    if (requestList.includes(request)) {
      sources[request]("取消请求");
    } else {
      requestList.push(request);
      if (config["loading"])
        Toast.loading({
          message: "加载中...",
          forbidClick: true,
        });
    }
    if (config.url == "admin/ajax/upload") {
      config.data = true;
      config.headers["Content-Type"] = "multipart/form-data";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (Response) => {
    const request =
      JSON.stringify(Response.config.url) +
      JSON.stringify(Response.config.data);
    requestList.splice(
      requestList.findIndex((item) => item === request),
      1
    );
    if (Response.status == 200 || 1) {
      switch (Response.data.code) {
        case -5:
          Toast.fail("认证失效，请重新登录！");
          break;
        case 102:
          Toast.fail("操作过快，请稍后再试！");
          break;
      }
    } else {
      Toast.fail(Response.data.msg);
      return Promise.reject(Response);
    }
    return Response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      requestList.length = 0;
      Toast.clear();
    } else {
      Toast.fail(error);
      return Promise.reject(error);
    }
  }
);
const ConfigType = {
  loading: Boolean,
  isPubParam: Boolean,
  ContentType: String,
};
const requset = async (url, params, config, method) =>
  new Promise(async (resolve, reject) => {
    axios[method](
      url,
      params,
      config ? config : "application/json;charset=utf-8"
    )
      .then((response) => {
        if (response) {
          if (response.data.status == 203) {
            Dialog.confirm({
              title: "请重新登录",
              message: "登录已失效，请重新登录",
            })
              .then(() => {
                setTimeout(() => {
                  window.location.href = "/";
                }, 800);
              })
              .catch(() => {
                Toast.fail({
                  message: "取消登录",
                });
              });
          } else {
            resolve(response.data);
          }
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
export const post = async (url, params, ConfigType) =>
  new Promise(async (resolve, reject) => {
    if (ConfigType.isPubParam) params = { ...params, ...params };
    return requset(url, params, ConfigType, "post").then(async (e) => {
      resolve(e);
    }).catch(err=>{
      reject(err)
    });
  });

export const get = async (url, params, ConfigType) =>
  new Promise(async (resolve, reject) => {
    if (ConfigType.isPubParam) params = { ...params, ...params };
    return requset(url, { params }, ConfigType, "get").then(async (e) => {
      resolve(e);
    });
  });
