<template>
	<div class="bounspoint_description_content">
		<div class="bounspoint_description_contents">
			<div class="bonuspoint_content_header">
				<publicNavigation style="background: linear-gradient(0deg, #FEEFEF 0%, #FFFFFF 33%);" :data="navigation" />
				<div class="bounspoint_background_pink_white">
					<div class="linear_gradient"></div>
				</div>
			</div>
			<!-- 任务信息包含奖励点 -->
			<taskDescription :userdescription="userdescription" :showTotal="showTotal" />
			<!-- 任务值详情列表 -->
			<taskdescriptionList :taskDescriptionData="taskDescriptionData" />
			<!-- 基础奖励点列表 -->
			<taskbounpointslist :userID="userID" :typeNumber="typeNumber" :basicReward="basicReward" />
		</div>
	</div>
</template>

<script>
	import publicNavigation from '@/publicNavigation/publicNavigation.vue';
	import taskDescription from './compoment/taskDescription.vue'
	import taskdescriptionList from './compoment/taskDescriptionList.vue'
	import taskbounpointslist from './compoment/taskBounpointslist.vue'
	import * as API from '@/api.ts'
	export default {
		components: {
			publicNavigation,
			taskDescription,
			taskdescriptionList,
			taskbounpointslist
		},
		data() {
			return {
				navigation: {},
				userdescription: {},
				taskDescriptionData: [{
					name: '任务基础值',
					text: ''
				}, {
					name: '奖励系数',
					text: ''
				}, {
					name: '任务时长',
					text: ''
				}, {
					name: '实际工期',
					text: ''
				}, {
					name: '工期差额',
					text: ''
				}, {
					name: '超时系数',
					text: ''
				}, {
					name: '提前奖励系数',
					text: ''
				}],
				basicReward: [{
					name: '基础奖励',
					text: '',
					children: [{
						name: '',
						text: '任务基础值'
					}, {
						name: '',
						text: '奖励系数'
					}, {
						name: '',
						text: '任务时长'
					}]
				}, {
					name: '额外奖励',
					text: '',
					children: [{
						name: '',
						text: '工期差额'
					}, {
						name: '',
						text: '提前奖励系数'
					}]
				}, {
					name: '修正奖励点',
					text: '',
					children: [{
						name: '修正奖励点'
					}]
				}, {
					name: '奖励点',
					text: '',
					children: [{
						name: '',
						text: '基础奖励'
					}, {
						name: '',
						text: '额外奖励'
					},{
						name: '',
						text: '修正奖励'
					}]
				}, {
					name: '额外奖励点',
					text: '',
					children: [{
						name: '总经理额外奖励或扣减值'
					}]
				}],
				showTotal: '',
				userID: '',
				typeNumber: 1,
				verify_text:''
			}
		},
		mounted() {
			this.navigation = {
				image: require('../assets/return.png'),
				name: '任务奖励详情',
				close: ''
			}
			// 用户ID
			this.userID = window.localStorage.getItem('taskDescription') ? JSON.parse(window.localStorage.getItem('taskDescription')).user_id : ''
			this.verify_text = this.$route.query.verify_text ? this.$route.query.verify_text : ''
			this.requset()
			// 区别id
			this.typeNumber = this.verify_text != '验收中' || !this.verify_text ? 1 : 2
			// 验收区分
			if (this.userID && this.typeNumber == 2) {
				this.basicReward = [{
					name: '奖励点',
					text: '',
					children: [{
						name: '',
						text: '奖励系数'
					}, {
						name: '',
						text: '任务执行时长'
					}, {
						name: '',
						text: '验收系数'
					}]
				}, {
					name: '修正奖励点',
					text: '',
					children: [{
						name: '总经理额外奖励或扣减值'
					}]
				}, {
					name: '额外奖励点',
					text: '',
					children: [{
						name: '总经理额外奖励或扣减值'
					}]
				}]
				this.taskDescriptionData = [{
					name: '奖励系数',
					text: ''
				}, {
					name: '任务时长',
					text: ''
				}, {
					name: '验收系数',
					text: ''
				}]
			}
			
		},
		methods: {
			requset() {
				API.rewardDetail({
					id: this.$route.query.id && this.verify_text != '验收中' ||!this.verify_text ? this.$route.query.id : window.localStorage.getItem('taskDescription') ? JSON.parse(window.localStorage.getItem('taskDescription')).pid : '',
					type: this.verify_text != '验收中' || !this.verify_text ? 1 : 2,
					user_id: this.userID && this.verify_text == '验收中' ? this.userID : ''
				}).then(async e => {
					if (e) {
						this.userdescription = e.data
						// 基础信息
						this.basicReward[2].text = e.data.edit_reward ? e.data.edit_reward : 0
						if (this.userID && this.typeNumber == 2) {
							this.taskDescriptionData[0].text = e.data.coefficient ? e.data.coefficient : 0,
							this.taskDescriptionData[1].text = e.data.duration ? e.data.duration : 0,
							this.taskDescriptionData[2].text = e.data.accept ? e.data.accept : 0
						} else {
							this.taskDescriptionData[0].text = e.data.basic ? e.data.basic : 0,
							this.taskDescriptionData[1].text = e.data.coefficient ? e.data.coefficient : 0,
							this.taskDescriptionData[5].text = e.data.timeout_basic ? e.data.timeout_basic : 0,
							this.taskDescriptionData[6].text = e.data.in_advance ? e.data.in_advance : 0,
							this.taskDescriptionData[4].text = e.data.difference ? e.data.difference : 0,
							this.taskDescriptionData[2].text = e.data.workload ? e.data.workload : 0,
							this.taskDescriptionData[3].text = e.data.real_workload ? e.data.real_workload : 0
						}
						// 奖励信息
						if (this.userID && this.typeNumber == 2) {
							this.basicReward[0].children[0].name = e.data.coefficient ? e.data.coefficient : 0,
							this.basicReward[0].children[1].name = e.data.duration ? e.data.duration : 0,
							this.basicReward[0].children[2].name = e.data.accept ? e.data.accept : 0
							this.basicReward[0].text = e.data.coefficient * e.data.duration * e.data.accept
						} else {
							this.basicReward[0].children[0].name = e.data.basic ? e.data.basic : 0,
							this.basicReward[0].children[1].name = e.data.coefficient ? e.data.coefficient : 0,
							this.basicReward[0].children[2].name = e.data.workload ? e.data.workload : 0
							// 总计
								// -初始计算
							this.basicReward[0].text = (this.basicReward[0].children[1].name * this.basicReward[0].children[2].name) + Number(this.basicReward[0].children[0].name)
						}
						
						// 额外信息
						// -当是授权时
						if (this.userID && this.typeNumber == 2) {
							this.basicReward[1].text = e.data.edit_reward ? e.data.edit_reward : 0,
							this.basicReward[1].children[0].name = '修正奖励点'
						} else {
							// 不是授权时
							if(e.data.coefficient == "--" && e.data.basic == "--"){
								this.basicReward[0].text = '--'
								this.basicReward[0].children = [{name:'--'}]
							}else{
								this.basicReward[1].children[0].name = e.data.difference ? e.data.difference : 0,
								this.basicReward[1].children[1].name = e.data.in_advance ? e.data.in_advance : 0,
								this.basicReward[1].text = e.data.difference * e.data.in_advance
							}
							// 修正
							this.basicReward[2].text = e.data.edit_reward ? e.data.edit_reward : 0,
							this.basicReward[2].children[0].name = '修正奖励点'
						}
						
						// 总计
						if (this.userID && this.typeNumber != 2) {
							// 额外奖励点计算
							if(this.basicReward[1].text == 0){
								this.basicReward[1].children = [{name:'--'}]
								this.basicReward[1].text = '--'
							}else{
								this.basicReward[1].text = this.basicReward[1].children[0].name * this.basicReward[1].children[1].name
							}
							// ((this.basicReward[1].children[0].name != '-' ? this.basicReward[1].children[0].name : 0 ) * this.basicReward[1].children[1].name) == 0 && this.basicReward[1].children[2].name == 0 ? 0 : ((this.basicReward[1].children[0].name != '-' ? this.basicReward[1].children[0].name : 0 ) * this.basicReward[1].children[1].name)
							// 奖励点信息
							if(this.basicReward[1].text == '--' && this.basicReward[0].text == '--' && e.data.reward > 0){
								this.basicReward[3].text = e.data.reward
								this.basicReward[3].children = [{name:e.data.reward,text:'奖励点'}]
								if(e.data.other_reward > 0 || this.basicReward[2].text > 0){
									this.showTotal = (e.data.reward + e.data.other_reward) + this.basicReward[2].text
								}else{
									if(e.data.other_reward < 0 && this.basicReward[2].text < 0){
										this.showTotal = (e.data.reward - e.data.other_reward) - this.basicReward[2].text
									}else if(this.basicReward[2].text < 0){
										this.showTotal = e.data.reward - this.basicReward[2].text
									}else{
										this.showTotal = e.data.reward - e.data.other_reward
									}
								}
								this.basicReward[4].text = e.data.other_reward
							}else{
								this.basicReward[3].children[0].name = this.basicReward[0].text
								this.basicReward[3].children[1].name = this.basicReward[1].text == '--' ? 0 : this.basicReward[1].text
								this.basicReward[3].children[2].name = this.basicReward[2].text
							}
							// 总合计
							this.basicReward[3].text = this.basicReward[3].children[0].name + (this.basicReward[3].children[1].name == '--' ? 0 : this.basicReward[3].children[1].name) + this.basicReward[3].children[2].name
						}
						
						// 显示总合计
						if (this.userID && this.typeNumber == 2) {
							// this.showTotal = this.basicReward[1].text
							// 奖励点-修正奖小于0-修正 大于0 + 修正
							if (this.basicReward[1].text < 0 && this.basicReward[2].text < 0) {
								this.showTotal = (this.basicReward[0].text - this.basicReward[2].text) - this.basicReward[1].text
							} else if (this.basicReward[1].text < 0) {
								this.showTotal = this.basicReward[0].text - this.basicReward[1].text
							} else {
								this.showTotal = this.basicReward[0].text - this.basicReward[2].text
							}
							if (this.basicReward[1].text > 0 && this.basicReward[2].text > 0) {
								this.showTotal = (this.basicReward[0].text + (this.basicReward[2].text == '--' ? 0 : this.basicReward[2].text)) + this.basicReward[1].text
							} else if (this.basicReward[1].text > 0) {
								this.showTotal = this.basicReward[0].text + this.basicReward[1].text
							} else {
								this.showTotal = this.basicReward[0].text + this.basicReward[2].text
							}
						} else {
							// 额外奖励点
							this.basicReward[this.basicReward.length - 1].text = e.data.other_reward ? e.data.other_reward : 0
							// 最终结算点
							if (e.data.other_reward <= 0) {
								this.showTotal = this.basicReward[3].text - e.data.other_reward
							} else {
								this.showTotal = this.basicReward[3].text + e.data.other_reward
							}
							// this.showTotal = this.basicReward[2].text
						}
					}
				})
			}
		}
	}
</script>

<style lang="scss" sepoed>
	.bounspoint_description_content {
		margin-bottom: .01rem;

		.bounspoint_description_contents {
			.bonuspoint_content_header {
				.bounspoint_background_pink_white {
					position: relative;

					.linear_gradient {
						position: absolute;
						width: 100%;
						height: .02rem;
						z-index: -1;
						background: linear-gradient(0deg, #FEEFEF 100%, #FFFFFF 5%);
					}
				}
			}
		}
	}

	.public_navigation_content {
		background: transparent !important;
	}
</style>
