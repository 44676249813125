<!--
 * @Author: Mr.Cheng
 * @Date: 2023-10-26 17:05:29
 * @LastEditTime: 2023-10-26 18:41:59
 * @FilePath: \octopus-factory-map\src\new\cpProject\cpProject.vue
-->
<template>
  <div>
    <!-- 顶部标题 -->
    <div class="flex-c-c task-navigate-box">
      <!-- 返回按钮 -->
      <img
        @click="$router.go(-1)"
        class="task-left-back"
        src="@/assets/return.png"
        alt=""
      />
      <div>cp值项目</div>
      <div
        @click="
          $router.push({
            path: '/addbonus',
          })
        "
        class="red nav-right"
      >
        添加项目
      </div>
    </div>
    <!-- 内容 -->
    <div class="content">
      <!-- CP值项目&分红金额 -->
      <div class="flex-c-b header">
        <div style="width: 50%">
          <div class="lable">CP值项目</div>
          <div class="value">{{ allData.total }}</div>
        </div>
        <div style="width: 50%">
          <div class="lable">分红金额</div>
          <div class="value">{{ allData.all_divvy }}</div>
        </div>
      </div>
      <!-- 列表 -->
      <div class="list">
        <div>列表</div>
        <van-list
          v-if="list.length > 0"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            @click="cpDescription(item)"
            v-for="item of list"
            :key="item.id"
            class="list-content"
          >
            <!-- 顶部 -->
            <div class="list-content-top flex-c-b">
              <!-- 项目标题 -->
              <div class="title">{{ item.name }}</div>
              <!-- 状态  -->
              <div :style="{ color: statusColor(item.status) }" class="status">
                <div v-if="item.status == 2">第{{ item.divvy_num }}期分红</div>
                <div v-else>{{ item.status_text }}</div>
              </div>
            </div>
            <!-- 内容 -->
            <div class="list-content-content">
              <!-- cp值兑换额&参与人 -->
              <div class="flex-c-b">
                <div class="flex-c" style="width: 50%">
                  <div class="list-label">cp值兑换额：</div>
                  <div class="list-value">{{ item.total }}</div>
                </div>
                <div class="flex-c" style="width: 50%">
                  <div class="list-label">参与人：</div>
                  <div class="list-value">{{ item.person_num }}</div>
                </div>
              </div>
              <!-- 分红期&已分红 -->
              <div class="flex-c-b mt10">
                <div class="flex-c" style="width: 50%">
                  <div class="list-label">分红期：</div>
                  <div class="list-value">
                    {{ item.new_divvy_time_text || "--" }}
                  </div>
                </div>
                <div class="flex-c" style="width: 50%">
                  <div class="list-label">已分红：</div>
                  <div class="list-value">{{ item.dividend_paid }}</div>
                </div>
              </div>
              <!-- 分红金额 -->
              <div class="flex-c mt10">
                <div class="list-label">分红金额（税前）：</div>
                <div class="list-value red">
                  {{ item.divvy_text || "未公布" }}
                </div>
              </div>
            </div>
          </div>
        </van-list>
        <!-- 空列表 -->
        <van-empty v-else description="暂无数据" />
      </div>
    </div>
  </div>
</template>

<script>
import * as API from "@/api.ts";

export default {
  data() {
    return {
      list: [],
      allData: {},
      page: 1,
      limit: 10,
      loading: false, //审核任务列表接口是否正在请求
      finished: false, //审核任务是否全部加载完
    };
  },
  computed: {
    statusColor() {
      let color;
      return (status) => {
        switch (status) {
          case 1:
            color = "#D71518";
            break;
          case 2:
            color = "#D71518";
            break;
          case 3:
            color = "#999999";
            break;

          default:
            break;
        }
        return color;
      };
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    cpDescription(item) {
      this.$router.push({
        path: "/cpProjectDetail",
        query: {
          id: JSON.stringify(item.id),
        },
      });
    },
    // 上拉加载更多
    onLoad() {
      if (!this.finished) {
        this.page++;
        this.getData();
      }
    },
    // 获取页面数据
    async getData() {
      let { page, limit, list } = this;
      let params = {
        page,
        limit,
      };
      let { code, data } = await API.listCp(params);
      if (code == 1) {
        this.allData = data;
        let _list = data.rows;
        if (page == 1) {
          list = _list;
        } else {
          list.push(..._list);
        }
        if (_list.length == 0) {
          this.finished = true;
        }
        this.list = list;
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.red {
  color: #d71518 !important;
}
.nav-right {
  position: absolute;
  right: 0.2rem;
  font-size: 0.24rem;
}
.task-navigate-box {
  position: sticky;
  top: 0;
}
.lable {
  font-size: 0.24rem;
  font-weight: 500;
  color: #000000;
}
.value {
  font-size: 0.5rem;
  font-weight: bold;
  color: #000000;
  margin-top: 0.3rem;
}
.list-value {
  font-size: 0.24rem;
  font-weight: 500;
  color: #999999;
}
.list-value {
  font-size: 0.24rem;
  font-weight: 500;
  color: #000000;
}
.content {
  padding: 0.2rem;
  box-sizing: border-box;
  //   CP值项目&分红金额
  .header {
    width: 100%;
    padding: 0.35rem 0.4rem;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 0.08rem;
  }
  //   列表
  .list {
    margin-top: 0.2rem;
    width: 100%;
    padding: 0.3rem 0.33rem;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 0.08rem;
    // 列表内容
    .list-content {
      margin-top: 0.2rem;
      width: 100%;
      background: #fafafa;
      border: 0.01rem solid #dddddd;
      border-radius: 0.08rem;

      //   列表顶部
      .list-content-top {
        padding: 0.2rem 0.3rem;
        box-sizing: border-box;
        align-items: flex-start;
        border-bottom: 0.01rem solid #dddddd;
        // 项目标题
        .title {
          font-size: 0.28rem;
          font-weight: bold;
          color: #111111;
          flex: 1;
          margin-right: 0.3rem;
        }
        //   状态
        .status {
          font-size: 0.24rem;
          font-weight: 500;
          white-space: nowrap;
          line-height: 0.4rem;
        }
      }
      //列表内容
      .list-content-content {
        padding: 0.2rem 0.3rem;
        box-sizing: border-box;
      }
    }
  }
}
</style>