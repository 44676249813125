<template>
  <div class="my_user_information">
    <div class="my_user_information_content">
      <div class="my_user_informations_content_header">
        <div class="my_user_navbigation_header">
          <div class="my_user_navigation_title">
            <span>我的</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 用户信息卡片 -->
    <newUserCard
        :content="content"
        @noTokenClick="noTokenClick"
        @routerclcik="routerclcik"
        :token="token"
        :mystatus="1"
    />
    <!-- 我的导航列表 -->
    <myToggleList
        @toggleCurrentIndexClick="toggleCurrentIndexClick"
        :toggleList="toggleList"
    />
    <!-- 底部导航 -->
    <div class="bottom_navigation">
      <div class="navigation_image">
        <img src="@/assets/bottomnavigation.png" class="background_toggle" alt=""/>
        <div class="background_white"></div>
    
        <div class="home_list">
          <ul>
            <li @click="currrentIndex(s, k)" v-for="(s, k) in navigationData" :key="k">
              <img :src="toggleCurrentIndex == k ? s.statusImage : s.image" alt=""/>
              <span :style=" toggleCurrentIndex == k? 'color:#D71518': 'color:#999999'">{{ s.name }}</span>
            </li>
          </ul>
          
          <!--发布任务-->
          <img src="@/assets/addbutton.png" @click="addTask" class="addbuttons" alt=""/>
        </div>
      </div>
    </div>
    <!-- 弹起任务 -->
    <div class="popup_task">
      <van-popup round position="bottom" v-model="show">
        <div class="offset_image">
          <img @click="addTask" src="@/assets/offset.png" alt=""/>
        </div>
        <div class="plan_list">
          <ul>
            <li @click="writeLogEvent(0)">
              <img src="@/assets/writelog.png" alt=""/><span>写日志</span>
            </li>
            <li @click="writeLogEvent(1)">
              <img src="@/assets/writeplan.png" alt=""/><span>写计划</span>
            </li>
            <li @click="writetask">
              <img src="@/assets/writetask.png" alt=""/><span>发布任务</span>
            </li>
          </ul>
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>
import newUserCard from "@/component/home/newCard.vue";
import myToggleList from "./compoments/myToggleList.vue";
// import {
// 	myUserInform
// } from './myUserInform.ts'
import * as API from "@/api.ts";

export default {
  components: {
    newUserCard,
    myToggleList,
  },
  data() {
    return {
      navigationData: [
        {
          image: require("@/assets/garyhome.png"),
          name: "首页",
          statusImage: require("@/assets/homered.png"),
        },
        {
          image: require("@/assets/garyuser.png"),
          name: "我的",
          statusImage: require("@/assets/userred.png"),
        },
      ],
      toggleCurrentIndex: 1,
      content: {
        name: "",
        image: "",
        id_card: "",
        score: "",
      },
      token: '',
      toggleList: [],
      show: false,
    };
  },
  mounted() {
    const userinfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    // const parms = new myUserInform().init()
    // this.toggleList = userinfo.identity == 1 ? ['我的奖励点','我的CP值','我的消息','个人信息','账号设置'] :  parms.toggleList
    this.toggleList = [
      "我的奖励点",
      "我的CP值",
      "我的消息",
      "个人信息",
      "账号设置",
    ];
    // 用户卡片
    if (
        window.sessionStorage.getItem("userInfo") &&
        window.sessionStorage.getItem("usertoken")
    ) {
      this.token = window.sessionStorage.getItem("usertoken") || '';
      this.content = {
        name: userinfo.nickname,
        image: userinfo.avatar,
        id_card: userinfo.id_card,
        score: userinfo.score,
      };
      this.permissions = 4;
      // userinfo.identity
    }
    this.Rquset();
  },
  methods: {
    Rquset() {
      API.userInfo().then(async (e) => {
        if (e.code == 1) {
          console.log(e);
          if (e.data) {
            this.content = {
              name: e.data.nickname,
              image: e.data.avatar,
              id_card: e.data.id_card,
              score: e.data.score,
            };
          }
        }
      });
    },
    currrentIndex(s, k) {
      this.toggleCurrentIndex = k;
      if (k != 1) {
        this.$router.push({
          name: "home",
          path: "/home",
        });
      }
    },
    toggleCurrentIndexClick(s, k) {
      if (k == 0) {
        this.$router.push({
          naem: "bonuspoint",
          path: "/bonuspoint",
        });
      } else if (k == 1) {
        this.$router.push({
          name: "myCpValue",
          path: "/myCpValue",
        });
      } else if (s == "我的消息") {
        this.$router.push({
          name: "Announce",
          path: "/Announce",
        });
      } else if (s == "个人信息") {
        this.$router.push({
          name: "userInfomation",
          path: "/userInfomation",
        });
      } else {
        this.$router.push({
          name: "settingacount",
          path: "/settingacount",
        });
      }
    },
    noTokenClick() {
      this.$dialog
      .confirm({
        title: "退出登录",
        message: "是否退出登录",
      })
      .then(() => {
        // on confirm
        window.sessionStorage.clear();
        window.location.href = "/";
      })
      .catch(() => {
        // on cancel
      });
    },
    addTask() {
      this.show = !this.show;
    },
    writetask() {
      this.$router.push({
        name: "lancering",
        path: "/lancering",
        query: {
          e: "创建任务",
        },
      });
    },
    //
    writeLogEvent(s) {
      this.$router.push({
        name: "inputLog",
        path: "/inputLog",
        query:
            s > 0
                ? {
                  planShow: 1,
                }
                : "",
      });
    },
    routerclcik() {
      this.$router.push({
        name: "userInfomation",
        path: "/userInfomation",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.my_user_information {
  height: 100vh;
  background: #f1f0f5;
  
  .my_user_information_content {
    .my_user_informations_content_header {
      .my_user_navbigation_header {
        background-image: url("@/assets/background.png");
        height: 2.12rem;
        background-size: cover;
        
        .my_user_navigation_title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0.15rem;
          padding-top: 0.4rem;
          
          span {
            color: white;
            font-size: 0.38rem;
            font-family: "PingFang SC";
          }
        }
      }
    }
  }
}

.bottom_navigation {
  position: fixed;
  bottom: 0;
  width: 100vw;
  
  .navigation_image {
    @extend .alignItemsCenter;
    position: relative;
    width: 100%;
    
    .background_toggle {
      position: absolute;
      width: 100%;
      height: 1.7rem;
      z-index: 2;
    }
    .background_white{
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 0.2rem;
      background: #fff;
      z-index: 2;
    }
    .home_list{
      position: relative;
      z-index: 99;
      width: 100%;
      ul{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        li {
          @extend .alignItemsCenter;
          flex-direction: column;
          justify-content: center;
          width: 40%;
          padding-top: 0.2rem;
          padding-bottom: 0.1rem;
    
          img {
            width: 0.479rem;
            height: 0.479rem;
          }
          span {
            font-size: 0.22rem;
            margin-top: calcFunction(2);
          }
        }
      }
    }
    
    .addbuttons {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1.39rem;
      height: 1.39rem;
      top: -0.40rem;
      z-index: 1;
    }
    
  }
}

.plan_list {
  @extend .alignItemsCenter;
  width: 100%;
  height: 70%;
  margin: 1rem 0;
  
  ul {
    @extend .alignItemsCenter;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    
    li {
      width: 33%;
      @extend .alignItemsCenter;
      flex-direction: column;
      
      img {
        width: 1.3rem;
        height: 1.3rem;
      }
      
      span {
        margin-top: 0.36rem;
        font-size: 0.32rem;
        font-weight: 500;
      }
    }
  }
}

.popup_task {
  .offset_image {
    @extend .alignItemsCenter;
    justify-content: flex-end;
    margin: 0.29rem;
    
    
    img {
      width: 0.29rem;
      height: 0.29rem;
    }
  }
}

.custom_flex_grid {
  justify-content: center !important;
  padding-bottom: calcFunction(20);
}
</style>
