<!--
 * @Author: Mr.Cheng
 * @Date: 2023-10-30 18:21:59
 * @LastEditTime: 2023-10-31 10:50:41
 * @FilePath: \octopus-factory-map\src\new\personnelManagement\components\person.vue
-->
<template>
  <div>
    <div class="top-box">
      <!-- 顶部标题 -->
      <div class="flex-c-c task-navigate-box">
        <!-- 返回按钮 -->
        <img
          @click="$router.go(-1)"
          class="task-left-back"
          src="@/assets/return.png"
          alt=""
        />
        <div>{{ topTitle }}</div>
        <div @click="submit" class="top-right">确定</div>
      </div>
    </div>
    <div class="content">
      <!-- 工号 -->
      <van-cell-group>
        <van-field
          type="number"
          v-model="username"
          label="工号"
          placeholder="请输入工号"
        />
      </van-cell-group>
      <!-- 员工姓名 -->
      <van-cell-group>
        <van-field
          v-model="nickname"
          label="员工姓名"
          placeholder="请输入员工姓名"
        />
      </van-cell-group>
      <!-- 所属部门 -->
      <van-cell-group>
        <van-field
          readonly
          @click="sectionShow = true"
          v-model="section_text"
          label="所属部门"
          placeholder="请选择部门"
        />
      </van-cell-group>
      <!-- 岗位职能 -->
      <van-cell-group>
        <van-field
          @click="setSectionStationShow"
          readonly
          v-model="role_ids_text"
          label="岗位职能"
          placeholder="请选择岗位职能"
        />
      </van-cell-group>
      <!-- 身份 -->
      <van-cell-group>
        <van-field
          @click="identityShow = true"
          readonly
          v-model="identity_text"
          label="身份"
          placeholder="请选择身份"
        />
      </van-cell-group>
      <!-- 电子邮箱 -->
      <van-cell-group>
        <van-field
          type="email"
          v-model="email"
          label="电子邮箱"
          placeholder="请输入邮箱"
        />
      </van-cell-group>
      <!-- 联系电话 -->
      <van-cell-group>
        <van-field
          type="number"
          v-model="mobile"
          label="联系电话"
          placeholder="请输入联系电话"
        />
      </van-cell-group>
    </div>

    <!-- 选择部门弹窗 -->
    <van-popup
      v-model="sectionShow"
      round
      position="bottom"
      :style="{ height: 'auto' }"
    >
      <van-picker
        title="请选择部门"
        show-toolbar
        :columns="sectionLists"
        value-key="title"
        @confirm="onConfirmSection"
        @cancel="sectionShow = false"
      />
    </van-popup>
    <!-- 选择身份弹窗 -->
    <van-popup
      v-model="identityShow"
      round
      position="bottom"
      :style="{ height: 'auto' }"
    >
      <van-picker
        title="请选择身份"
        show-toolbar
        :columns="identityList"
        value-key="title"
        @confirm="onConfirmIdentity"
        @cancel="identityShow = false"
      />
    </van-popup>
    <!-- 选择岗位弹窗 -->
    <van-popup
      v-model="sectionStationShow"
      round
      position="bottom"
      :style="{ height: 'auto' }"
    >
      <van-picker
        title="请选择岗位"
        show-toolbar
        :columns="sectionStation"
        value-key="title"
        @confirm="onConfirmStation"
        @cancel="sectionStationShow = false"
      />
    </van-popup>
  </div>
</template>

<script>
import * as API from "@/api.ts";

export default {
  data() {
    return {
      id: "", //员工id
      topTitle: "", //顶部标题
      username: "", //工号
      nickname: "", //员工姓名
      section_id: "", //所属部门id
      section_text: "", //所属部门
      role_ids: "", //岗位职能id
      role_ids_text: "", //岗位职能
      email: "", //电子邮箱
      identity: "", //身份
      mobile: "", //手机号
      sectionLists: [], //部门列表
      sectionStation: [], //岗位列表
      identity_text: "", //身份文字
      identityList: [
        { title: "员工", id: "1" },
        { title: "主管", id: "2" },
      ], //身份列表
      sectionShow: false, //选择部门弹窗
      sectionStationShow: false, //选择岗位弹窗
      identityShow: false, //选择身份弹窗
    };
  },
  mounted() {
    //部门列表
    this.getSectionLists();
    let { type, data: item } = this.$route.query;
    // 1修改，2新增
    if (type == 1) {
      this.topTitle = "修改员工";
      let data = JSON.parse(item);
      let {
        nickname,
        identity,
        identity_text,
        mobile,
        role_ids,
        role_title,
        section_id,
        section_title,
        email,
        user_name,
        id,
      } = data;
      this.nickname = nickname;
      this.identity = identity;
      this.identity_text = identity_text;
      this.mobile = mobile;
      this.role_ids = role_ids;
      this.section_id = section_id;
      this.email = email;
      this.role_ids_text = role_title;
      this.section_text = section_title;
      this.username = user_name;
      this.id = id;
      console.log(data);
    } else {
      this.topTitle = "添加员工";
    }
  },
  methods: {
    // 确定
    async submit() {
      let {
        username,
        nickname,
        section_id,
        role_ids,
        email,
        identity,
        mobile,
        id,
      } = this;
      if (
        username &&
        nickname &&
        section_id &&
        role_ids &&
        email &&
        identity &&
        mobile
      ) {
        if (this.$route.query.type == 1) {
          let params = {
            username,
            nickname,
            section_id,
            role_ids,
            email,
            identity,
            mobile,
            ids:id,
          };
          let { code, msg } = await API.adminEdit(params);
          this.$toast(msg);
          if (code == 1) {
            this.$router.go(-1);
          }
        } else {
          let params = {
            username,
            nickname,
            section_id,
            role_ids,
            email,
            identity,
            mobile,
          };
          let { code, msg } = await API.AdminAdd(params);
          this.$toast(msg);
          if (code == 1) {
            this.$router.go(-1);
          }
        }
      } else {
        this.$toast("请将信息填写完整");
      }
    },
    // 选择岗位弹窗显示
    setSectionStationShow() {
      if (!this.section_id) {
        this.$toast("请先选择部门");
        return;
      }
      this.sectionStationShow = true;
    },
    // 选择部门
    onConfirmSection(e) {
      let { id, title } = e;
      this.section_id = id;
      this.section_text = title;
      this.sectionShow = false;
      //获取岗位列表
      this.getSectionStation();
    },
    // 选择身份
    onConfirmIdentity(e) {
      let { id, title } = e;
      this.identity = id;
      this.identity_text = title;
      this.identityShow = false;
      //获取岗位列表
      this.getSectionStation();
    },
    // 选择岗位
    onConfirmStation(e) {
      let { id, title } = e;
      this.role_ids = id;
      this.role_ids_text = title;
      this.sectionStationShow = false;
    },
    //部门列表
    async getSectionLists() {
      let { code, data } = await API.section();
      if (code == 1) {
        this.sectionLists = data.rows;
      }
    },
    //获取岗位列表
    async getSectionStation() {
      // id为部门id
      let params = {
        id: this.section_id,
      };
      let { code, data } = await API.station(params);
      if (code == 1) {
        this.sectionStation = data.rows;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top-box {
  position: sticky;
  top: 0;
  z-index: 500;
}
.task-navigate-box {
  border-bottom: unset !important;
}
.top-right {
  position: absolute;
  right: 0.2rem;
  font-size: 0.28rem;
  font-family: PingFang SC;
  color: #d71518;
}
.content {
  background: #fff;
  width: 100%;
  padding: 0.2rem;
  box-sizing: border-box;
  height: 100vh;
  padding-top: 0.6rem;
}
// 选择部门弹窗
.section-popup {
  padding: 0.3rem;
  box-sizing: border-box;
  .section-list {
    width: 100%;
    height: 0.9rem;
  }
}
</style>