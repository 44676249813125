<template>
  <div class="user_login" style="background:white;height:100vh;">
    <div class="user_login_content">
      <div class="user_login_content_box">
        <div class="user_login_header_logo">
          <img src="@/assets/logo.png" alt="" />
          <span>账号密码登录</span>
        </div>
        <div class="uuser_login_password_moblie_number">
          <div class="moblie_number_password">
            <div class="moblie_number">
              <input type="text" v-model="mobileNuber" placeholder="请输入您的账号" />
              <img src="@/assets/errornumber.png" @click="deleteClick" alt="" />
            </div>
            <div class="moblie_number custom_bottom_style">
              <input :type="!openeyebloolen ? 'text' : 'password'" v-model="pawwrod" placeholder="请输入您的密码" />
              <img src="@/assets/offeye.png" v-if="openeyebloolen" @click="openeye" alt="" />
              <img src="@/assets/openeye.png" v-else @click="openeye" alt="" />
            </div>
            <div class="moblie_forget">
              <span @click="forgetPassword">忘记密码？</span>
            </div>
          </div>
        </div>
        <div class="user_login_sure_button">
          <div class="user_sure_button">
            <button @click="loginClick">登录</button>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom_fixed_choose">
      <div class="fixed_choose_text">
        <div class="fixed_choose_text_image">
          <div class="fixed_choose">
            <img v-if="!choose" src="@/assets/notchoose.png" @click="sureclcik" alt="" />
            <img v-if="choose" src="@/assets/okchoose.png" @click="sureclcik" alt="" />
            <span>已阅读并同意<i @click="checkclick">《用户服务协议》</i><i @click="checkclick(1)">《隐私政策》</i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as API from "@/api.ts";
export default {
  data() {
    return {
      openeyebloolen: true,
      choose: false,
      pawwrod: "",
      mobileNuber: "",
      bottomBtnHeight: "",
      docmHeight: "",
      // 长连接
      websocket: null | "",
      // 用户信息
      userinfo: "",
      // 定时
      timer: null,
    };
  },
  mounted() {
    // this.docmHeight =
    // 	document.documentElement.clientHeight || document.body.clientHeight;
    // this.bottomBtnHeight =
    // 	document.querySelectorAll(".fixed_choose")[0].offsetHeight;
    // window.addEventListener(" ", this.getShowHeight);
  },
  methods: {
    getShowHeight() {
      this.showHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      const diff = this.docmHeight - this.showHeight;
      if (this.showHeight < this.docmHeight && diff > 100) {
        // 隐藏
        document.querySelectorAll(".fixed_choose")[0].style.display = "none";
        document.querySelectorAll(".wrap")[0].style.bottom = 0;
      } else {
        // 显示
        document.querySelectorAll(".fixed_choose")[0].style.display = "block";
        document.querySelectorAll(
          ".wrap"
        )[0].style.bottom = `${this.bottomBtnHeight}px`;
      }
    },
    openeye() {
      this.openeyebloolen = !this.openeyebloolen;
    },
    sureclcik() {
      this.choose = !this.choose;
    },
    checkclick(e) {
      if (e == 1) {
        this.$router.push({
          name: "isPerson",
          path: "/isPerson",
        });
      } else {
        this.$router.push({
          name: "isPerson",
          path: "/isPerson",
          query: {
            numbers: 1,
          },
        });
      }
    },
    forgetPassword() {
      this.$router.push({
        name: "retrievepassword",
        path: "/retrievepassword",
      });
    },
    loginClick() {
      if (this.mobileNuber && this.pawwrod) {
        if (this.choose) {
          API.login({
            username: this.mobileNuber,
            password: this.pawwrod,
          }).then(async (e) => {
            if (e.code == 1) {
              this.$toast.success("登录成功"),
                window.sessionStorage.setItem(
                  "userInfo",
                  JSON.stringify(e.data)
                );
              window.sessionStorage.setItem(
                "usertoken",
                JSON.stringify(e.data.token)
              );
              this.userinfo = e.data;
              console.log(e.data);
              setTimeout(() => {
                this.$router.push({
                  name: "home",
                  path: "/home",
                });
              }, 1000);
            } else {
              this.$toast.fail(e.msg);
            }
          });
        } else {
          this.$toast.fail("请先同意用户协议");
        }
      } else {
        this.$toast.fail("账号或密码不能为空");
      }
    },
    deleteClick() {
      this.mobileNuber = "";
    },
    
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.user_login {
  height: 100vh;

  .user_login_content {
    margin: 0 calcFunction(30);
    padding-top: calcFunction(70);

    .user_login_content_box {
      .user_login_header_logo {
        @extend .alignItemsCenter;

        img {
          width: calcFunction(35);
          height: calcFunction(35);
        }

        span {
          margin-left: calcFunction(10);
          font-size: calcFunction(22);
          font-weight: bold;
          color: #111111;
          font-family: "PingFang SC";
          letter-spacing: 1px;
        }
      }

      .uuser_login_password_moblie_number {
        margin-top: calcFunction(50);

        .moblie_number_password {
          .moblie_number {
            margin-bottom: calcFunction(20);
            border-bottom: 1px solid #dddddd;
            padding-bottom: calcFunction(20);
            @extend .alignItemsCenter;
            align-items: flex-end;

            input {
              width: 100%;
              padding: calcFunction(10) 0;
              padding-bottom: 0;
              border: none;
              font-size: calcFunction(14);
              color: #111111;
            }

            img {
              flex: 1;
              width: calcFunction(16);
              height: calcFunction(10);
            }
          }

          .moblie_number:nth-child(1) {
            img {
              width: calcFunction(14);
              height: calcFunction(14);
            }
          }

          .custom_bottom_style {
            margin-bottom: calcFunction(10);
          }
        }

        .moblie_forget {
          text-align: right;

          span {
            color: #666666;
            font-size: calcFunction(12);
          }
        }
      }

      .user_login_sure_button {
        .user_sure_button {
          button {
            background: linear-gradient(-31deg, #e80000 0%, #ff585b 100%);
            box-shadow: 0px 10px 20px 0px rgba(255, 0, 0, 0.2);
            width: 100%;
            border: none;
            outline: none;
            padding: calcFunction(10) 0;
            color: white;
            border-radius: calcFunction(4);
            margin-top: calcFunction(70);
            font-size: calcFunction(17);
            letter-spacing: 1px;
          }
        }
      }
    }
  }
}

.bottom_fixed_choose {
  margin-top: 32vh;
  width: 100%;

  .fixed_choose_text {
    .fixed_choose_text_image {
      .fixed_choose {
        @extend .alignItemsCenter;
        justify-content: center;
        width: 100%;

        img {
          width: calcFunction(14);
          height: calcFunction(14);
          margin-right: calcFunction(5);
        }

        span {
          font-size: calcFunction(12);

          i {
            color: #0074c0;
            font-style: normal;
          }
        }
      }
    }
  }
}

.fixed_choose {
  text-align: center;
}
</style>
