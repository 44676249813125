<!--
 * @Author: Mr.Cheng
 * @Date: 2023-10-30 10:32:40
 * @LastEditTime: 2023-10-31 10:33:50
 * @FilePath: \octopus-factory-map\src\new\personnelManagement\components\staff.vue
-->
<template>
  <div>
    <van-search
      @search="search"
      v-model="title"
      shape="round"
      background="#FFF"
      placeholder="请输入员工姓名或工号"
    />
    <!-- 筛选框 -->
    <van-dropdown-menu>
      <!-- 部门 -->
      <van-dropdown-item ref="sectionDom">
        <template #title>
          <div>{{ section_text || "部门" }}</div>
        </template>
        <div>
          <div
            @click="onConfirmSection(item)"
            :class="['list', 'flex-c-c', item.id == section ? 'highlight' : '']"
            v-for="item of sectionLists"
            :key="item.id"
          >
            {{ item.title }}
          </div>
        </div>
      </van-dropdown-item>
      <!-- 岗位 -->
      <van-dropdown-item ref="stationDom">
        <template #title>
          <div>{{ station_text || "岗位" }}</div>
        </template>
        <div>
          <div
            @click="onConfirmStation(item)"
            :class="['list', 'flex-c-c', item.id == station ? 'highlight' : '']"
            v-for="item of sectionStationSearch"
            :key="item.id"
          >
            {{ item.title }}
          </div>
        </div>
      </van-dropdown-item>
    </van-dropdown-menu>
    <!-- 员工列表 -->

    <div style="padding: 0 0.2rem; box-sizing: border-box">
      <van-list
        v-if="list.length > 0"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div v-for="item of list" :key="item.id" class="person-list mb10">
          <!-- 员工列表顶部 -->
          <div class="person-list-top flex-c-b">
            <div class="name">{{ item.nickname }}</div>
            <div class="flex-c">
              <div @click="personAdd(1, item)" class="gray-btn mr5 flex-c-c">
                编辑
              </div>
              <div
                @click="adminDisable(item.id, 2)"
                v-if="item.status == 'normal'"
                class="gray-btn flex-c-c"
              >
                关闭
              </div>
              <div
                @click="adminDisable(item.id, 1)"
                v-else
                class="gray-btn flex-c-c"
              >
                启用
              </div>
            </div>
          </div>
          <!-- 员工列表内容 -->
          <div class="flex-c-b mt5">
            <div class="flex-c">
              <div class="label">工号：</div>
              <div class="value">{{ item.user_name }}</div>
            </div>
            <div class="flex-c">
              <div class="label">部门：</div>
              <div class="value">{{ item.section_title }}</div>
            </div>
          </div>
          <div class="flex-c-b mt5">
            <div class="flex-c">
              <div class="label">电话：</div>
              <div class="value">{{ item.mobile }}</div>
            </div>
            <div class="flex-c">
              <div class="label">岗位职能：</div>
              <div class="value">{{ item.identity_text }}</div>
            </div>
          </div>
          <div class="label mt5">
            邮箱：<span class="value">{{ item.email }}</span>
          </div>
        </div>
      </van-list>

      <!-- 空列表 -->
      <van-empty v-else description="暂无数据" />
      <!-- 占位 -->
      <div style="width: 100%; height: 1.3rem"></div>
      <!-- 底部添加员工按钮 -->
      <div class="bottom-box">
        <div @click="personAdd(2)" class="btn flex-c-c">添加员工</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as API from "@/api.ts";

export default {
  data() {
    return {
      title: "", //搜索关键词
      section_text: "", //选择的部门文字
      station_text: "", //选择的岗位文字
      sectionLists: [], //部门筛选列表
      section: "", //筛选时的部门id
      station: "", //筛选时的岗位id
      sectionStationSearch: [{ title: "全部", id: "" }], //岗位搜索列表
      list: [], //列表
      page: 1,
      limit: 10,
      loading: false, //列表接口是否正在请求
      finished: false, //是否全部加载完
    };
  },
  mounted() {
    //部门列表筛选
    this.getSectionLists();
    // 获取员工列表
    this.getList();
  },
  methods: {
    // 搜索
    search() {
      this.page = 1;
      this.list = [];
      this.finished = false;
      // 获取员工列表
      this.getList();
    },
    //关闭或启用员工
    async adminDisable(id, status) {
      let params = {
        id,
        status,
      };
      let { code, msg } = await API.disable(params);
      if (code == 1) {
        this.$toast(msg);
        this.page = 1;
        this.list = [];
        this.finished = false;

        // 获取员工列表
        this.getList();
      }
    },
    // 添加|修改员工
    personAdd(type, item) {
      // type:1,修改2，添加
      this.$router.push({
        path: "/administrationPerson",
        query: {
          type,
          data: JSON.stringify(item),
        },
      });
    },
    // 上拉加载更多
    onLoad() {
      this.page++;
      this.getList();
    },
    // 部门筛选
    onConfirmSection(item) {
      this.section = item.id;
      this.section_text = item.title;
      this.station = "";
      this.station_text = "";
      if (item.id != 0) {
        //获取岗位列表
        this.getSectionStation(item.id);
      } else {
        this.sectionStationSearch = [{ title: "全部", id: "" }];
      }
      this.page = 1;
      this.list = [];
      this.finished = false;
      this.getList();
      // 关闭该弹窗
      this.$refs.sectionDom.toggle();
    },
    // 岗位筛选
    onConfirmStation(item) {
      this.station = item.id;
      this.station_text = item.title;
      this.page = 1;
      this.list = [];

      this.finished = false;
      this.getList();
      // 关闭该弹窗
      this.$refs.stationDom.toggle();
    },
    //部门列表筛选
    async getSectionLists() {
      let { code, data } = await API.section();
      if (code == 1) {
        this.sectionLists = [{ title: "全部", id: "" }, ...data.rows];
      }
    },
    //获取岗位列表
    async getSectionStation(id) {
      // id为部门id
      let params = {
        id,
      };
      let { code, data } = await API.station(params);
      if (code == 1) {
        this.sectionStationSearch = [{ title: "全部", id: "" }, ...data.rows];
      }
    },
    // 获取员工列表
    async getList() {
      this.loading = true;
      let { page, limit, list, section, station, title } = this;
      let params = {
        page,
        limit,
        section,
        station,
        title,
      };
      let { code, data } = await API.AdminList(params);
      if (code == 1) {
        let _list = data.rows;
        if (page == 1) {
          list = _list;
        } else {
          list.push(..._list);
        }
        if (_list.length == 0) {
          this.finished = true;
        }
        this.list = list;
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-dropdown-menu__item {
  max-width: unset !important;
}
.label {
  font-size: 0.24rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
}
.value {
  font-size: 0.24rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #111111;
}
// 筛选选中高亮
.highlight {
  color: #d71518;
}
// 筛选框列表
.list {
  height: 0.8rem;
  border-bottom: 0.01rem solid #f1f1f1;
}
// 员工管理列表
.person-list {
  width: 100%;
  background: #fafafa;
  border: 0.01rem solid #dddddd;
  border-radius: 0.08rem;
  padding: 0.3rem;
  box-sizing: border-box;
  .person-list-top {
    padding-bottom: 0.3rem;
    border-bottom: 0.01rem solid #dddddd;
    //   员工姓名
    .name {
      font-size: 0.3rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
    }
    .gray-btn {
      width: 1rem !important;
      height: 0.46rem !important;
      background: rgba(215, 21, 24, 0) !important;
      border: 0.01rem solid #666666 !important;
      border-radius: 0.04rem !important;
      white-space: nowrap !important;
      font-size: 0.26rem !important;
      font-family: PingFang SC !important;
      font-weight: 500 !important;
      color: #000000 !important;
    }
  }
}
.bottom-box {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0.2rem;
  box-sizing: border-box;
  background: #fff;
  left: 0;
  .btn {
    width: 100%;
    height: 0.9rem;
    background: #d71518;
    border-radius: 0.16rem;
    font-size: 0.34rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
}
</style>