var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"overflow":"hidden","height":"100vh"}},[_c('div',{staticClass:"top-bg"},[_c('div',{staticClass:"flex-c-c task-navigate-box",staticStyle:{"background":"unset","border-bottom":"unset"}},[_c('img',{staticClass:"task-left-back",attrs:{"src":require("@/assets/return.png"),"alt":""},on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('div',[_vm._v("项目分红详情")]),(_vm.detail.status != 3)?_c('div',{staticClass:"red nav-right",on:{"click":_vm.sureEvenet}},[_vm._v(" 结束分红 ")]):_vm._e()]),_c('div',{staticClass:"bic-content"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.detail.name))]),_c('div',{staticClass:"flex-c-b"},[_c('div',{staticClass:"flex-c",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"list-label"},[_vm._v("兑换CP值：")]),_c('div',{staticClass:"list-value"},[_vm._v(_vm._s(_vm.detail.total))])]),_c('div',{staticClass:"flex-c",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"list-label"},[_vm._v("参与人：")]),_c('div',{staticClass:"list-value"},[_vm._v(_vm._s(_vm.detail.play_count))])])]),_c('div',{staticClass:"flex-c-b mt10"},[_c('div',{staticClass:"flex-c",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"list-label"},[_vm._v("已分红：")]),_c('div',{staticClass:"list-value"},[_vm._v(" "+_vm._s(_vm.detail.dividend_paid)+" ")])])])]),_c('div',{staticClass:"list"},[(_vm.detail.divvy.length > 0)?_vm._l((_vm.detail.divvy),function(item){return _c('div',{key:item.id,staticClass:"list-content",on:{"click":function($event){return _vm.$router.push({
              path: '/cpDetail',
              query: { id: item.id, num: item.periodization },
            })}}},[_c('div',{staticClass:"list-content-top flex-c-b"},[_c('div',{staticClass:"list-name"},[_vm._v("第"+_vm._s(item.periodization)+"期分红")]),_c('div',{staticClass:"status",style:({ color: item.status == 1 ? '#D71518' : '' })},[_vm._v(" "+_vm._s(item.status_text)+" ")])]),_c('div',{staticClass:"list-content-content"},[_c('div',{staticClass:"flex-c-b"},[_c('div',{staticClass:"flex-c",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"label"},[_vm._v("分红人数：")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(item.play_count))])]),_c('div',{staticClass:"flex-c",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"label"},[_vm._v("分红金额：")]),_c('div',{staticClass:"value mr20 red"},[_vm._v(_vm._s(item.divvy || "-"))]),(!item.divvy)?_c('img',{staticStyle:{"width":"0.26rem"},attrs:{"src":require("@/assets/edit.png"),"alt":""},on:{"click":function($event){$event.stopPropagation();_vm.moneyShow = true;
                    _vm.title = item.periodization;
                    _vm.id = item.id;}}}):_vm._e()])]),_c('div',{staticClass:"flex-c mt10"},[_c('div',{staticClass:"label"},[_vm._v("分红日期：")]),_c('div',{staticClass:"value mr20"},[_vm._v(" "+_vm._s(item.divvytime_text || "-")+" ")]),(!item.divvytime_text)?_c('img',{staticStyle:{"width":"0.26rem"},attrs:{"src":require("@/assets/edit.png"),"alt":""},on:{"click":function($event){$event.stopPropagation();_vm.show = true;
                  _vm.id = item.id;}}}):_vm._e()])])])}):_c('van-empty',{attrs:{"description":"暂无数据"}}),(_vm.detail.status != 3)?_c('div',{staticClass:"style"}):_vm._e()],2)]),(_vm.detail.status != 3)?_c('div',{staticClass:"bottom-button"},[_c('div',{staticClass:"create-btn flex-c-c",on:{"click":_vm.createBonus}},[_vm._v("新建分红")])]):_vm._e(),_c('van-calendar',{attrs:{"title":"分红日期"},on:{"confirm":_vm.onConfirm},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}}),_c('van-dialog',{attrs:{"title":'第' + _vm.title + '期分红',"show-cancel-button":""},on:{"confirm":_vm.bonusMoney},model:{value:(_vm.moneyShow),callback:function ($$v) {_vm.moneyShow=$$v},expression:"moneyShow"}},[_c('van-field',{attrs:{"type":"number","placeholder":"请输入分红金额"},model:{value:(_vm.money),callback:function ($$v) {_vm.money=$$v},expression:"money"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }