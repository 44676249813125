<template>
  <div class="record_list_card">
    <div class="record_item" v-for="(s, k) in recordData" :key="k">
      <div class="record_title flex-c-b" @click="recordDescription(s, k)" v-if="!off">
        <span>兑换号：{{ s.order_no }}</span>
        <span :style="s.status == 3 ? 'color:#999999' : ''">{{s.status_text}}</span>
      </div>
      <div class="record_gift" @click="recordDescription(s, k)" :style="off ? 'padding-top:20px;' : ''">
        <div class="record_gift_image">
          <van-image :src="s.img[0]" fit="cover"></van-image>
        </div>
        <div class="record_gift_right_text_description">
          <div class="record_gift_text">
            <span>{{ s.content }}</span>
          </div>
          <div class="record_gift_bottom_text">
            <div class="record_gift_left_text">
              <span>{{ s.reward }}</span>
              <span>奖励点</span>
            </div>
            <div class="record_gift_right_number">
              <span>x1</span>
            </div>
          </div>
        </div>
      </div>
      <div class="record_button" @click="Cancelexchange(s,k)" v-if="s.status == 1">
        <div class="record_button_event">
          <button @click="submit(s.id)">撤销兑换</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    recordData: Array,
    off: Number,
  },
  methods: {
    recordDescription(s, k) {
      this.$emit("recordDescription", s, k);
    },
    submit(id) {
      this.$emit("submit", id);
    },
    Cancelexchange(s,k){
      this.$emit('Cancelexchange',s,k)
    }
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}

.record_list_card {
  .record_item {
    margin-top: 0.2rem;
    padding: 0 0.3rem 0.01rem;
    height: 100%;
    background: white;
    
    .record_title {
      justify-content: space-between;
      padding-bottom: 0.4rem;
      padding-top: 0.36rem;
      
      span {
        color: #222222;
        font-size: 0.3rem;
        font-weight: 500;
      }
      
      span:nth-last-child(1) {
        color: #d71518;
      }
    }
    
    .record_gift {
      display: flex;
      width: 100%;
      height: 1.6rem;
      margin-bottom: 0.35rem;
      .record_gift_image {
        width: 1.6rem;
        height: 1.6rem;
        border: 1px solid #dddddd;
        justify-content: center;
        .van-image{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          img {
            display: block;
            width: 80%;
            height: 80%;
          }
        }
        
      }
      
      .record_gift_right_text_description {
        margin-left: 0.2rem;
        height: 100%;
        justify-content: space-between;
        @extend .alignItemsCenter;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
        .record_gift_text {
          span {
            font-size: 0.26rem;
            font-weight: bold;
          }
        }
        
        .record_gift_bottom_text {
          @extend .alignItemsCenter;
          justify-content: space-between;
          width: 100%;
          
          .record_gift_left_text {
            span {
              color: #d71518;
              font-size: 0.36rem;
              font-weight: bold;
            }
            span:nth-last-child(1) {
              font-weight: 400;
              color: black;
              font-size: 0.22rem;
              margin-left: 0.10rem;
            }
          }
          
          .record_gift_right_number {
            span {
              color: #666666;
              font-size: 0.28rem;
            }
          }
        }
      }
    }
  }
}

.record_button {
  border-top: 1px solid #ededed;
  margin-top: 0.4rem;

  .record_button_event {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 1.2rem;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0.3rem;
      font-size: 0.22rem;
      outline: none;
      border-radius: 0.5rem;
      border: 1px solid #cccccc;
      background: transparent;
      height: 0.6rem;
    }
  }
}
</style>
