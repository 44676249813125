<!--
 * @Author: Mr.Cheng
 * @Date: 2023-10-09 16:51:17
 * @LastEditTime: 2023-10-24 14:59:08
 * @FilePath: \octopus-factory-map\src\new\initTask\initTask.vue
-->
<template>
  <div>
    <div class="top-box">
      <!-- 顶部标题 -->
      <div class="flex-c-c task-navigate-box">
        <!-- 返回按钮 -->
        <img
          @click="$router.go(-1)"
          class="task-left-back"
          src="@/assets/return.png"
          alt=""
        />
        <div>发起任务</div>
      </div>

      <!-- 搜索框-->

      <van-search
        v-model="project_title"
        @search="onSearch"
        @clear="clear"
        shape="round"
        background="#FFF"
        placeholder="输入项目关键字查询"
      >
        <!-- <template #action>
        <van-dropdown-menu>
          <van-dropdown-item get-container="body">
            <template #title>
              <img
                class="more"
                @click="show = true"
                src="@/assets/more.png"
                alt=""
              />
            </template>
            <template #default>
              <div class="screenContent">123</div>
            </template>
          </van-dropdown-item>
        </van-dropdown-menu>
      </template> -->
      </van-search>

      <!-- 筛选框 -->
      <van-dropdown-menu>
        <!-- 任务状态 -->
        <van-dropdown-item ref="statusDom">
          <template #title>
            <div>{{ status_text || "任务状态" }}</div>
          </template>
          <div>
            <div
              @click="onConfirmStatus(item)"
              :class="[
                'list',
                'flex-c-c',
                item.status == status ? 'highlight' : '',
              ]"
              v-for="item of statusList"
              :key="item.status"
            >
              {{ item.title }}
            </div>
          </div>
        </van-dropdown-item>
        <!-- 时间 -->
        <van-dropdown-item ref="dateDom">
          <template #title>
            <div v-if="start_time && start_time != end_time">
              <div>{{ start_time }} ~</div>
              <div>{{ end_time }}</div>
            </div>
            <div v-else-if="start_time && start_time == end_time">
              <div>{{ start_time }}</div>
            </div>
            <div v-else>选择时间</div>
          </template>
          <div>
            <van-calendar
              @confirm="confirmDate"
              :show-title="false"
              type="range"
              :poppable="false"
              :min-date="new Date(2023, 0, 1)"
              :max-date="new Date()"
              :allow-same-day="true"
              :show-confirm="false"
              :style="{ height: '8rem' }"
            >
              <template #footer>
                <div @click="clearDate" class="clear flex-c-c">清除</div>
              </template>
            </van-calendar>
          </div>
        </van-dropdown-item>
        <!-- 发布人 -->
        <van-dropdown-item ref="createDom">
          <template #title>
            <div>{{ create_text || "发布人" }}</div>
          </template>
          <div>
            <div
              @click="onConfirmCreate(item)"
              :class="[
                'list',
                'flex-c-c',
                item.id == create_id ? 'highlight' : '',
              ]"
              v-for="item of createList"
              :key="item.id"
            >
              {{ item.nickname }}
            </div>
          </div>
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>

    <!-- 列表 -->
    <van-list
      v-if="list.length > 0"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="task-list-box">
        <div
          @click="
            $router.push({ path: '/initTaskDetail', query: { id: item.id } })
          "
          class="task-list mb10"
          v-for="item of list"
          :key="item.id"
        >
          <!-- 任务顶部 -->
          <div class="task-list-top">
            <!-- 任务名称&状态 -->
            <div class="flex-c-b" style="align-items: flex-start">
              <div class="task-name mr20">
                <div v-if="item.pid_title">
                  {{ item.pid_title }}——{{ item.title }}
                </div>
                <div v-else>
                  {{ item.title }}
                </div>
              </div>
              <div
                class="status_text"
                :style="{ color: statusColor(item.status) }"
              >
                {{ item.status_text }}
              </div>
            </div>
            <!-- 难度指数 -->
            <van-rate
              class="mt5"
              size="15px"
              color="#D71518"
              void-color="#FFF"
              :value="Number(item.difficulty_text)"
              readonly
            />
          </div>
          <!-- 任务内容 -->
          <div :class="['task-list-content', item.status == 7 ? 'gray' : '']">
            <div class="flex-c-b">
              <div class="label">归属项目：</div>
              <div
                @click.stop="
                  $router.push({
                    path: '/projectDetail',
                    query: { id: item.project_id },
                  })
                "
                :class="['value', 'line', item.status == 7 ? 'gray' : 'blue']"
              >
                <div>{{ item.project_title }}</div>
              </div>
            </div>
            <div class="flex-c-b mt10">
              <div class="label">发布人：</div>
              <div class="value">{{ item.create_nickname }}</div>
            </div>
            <div class="flex-c-b mt10">
              <div class="label">开始时间：</div>
              <div class="value">{{ item.starttime_text || "--" }}</div>
            </div>
            <div class="flex-c-b mt10">
              <div class="label">完成时间：</div>
              <div class="value">
                {{ item.real_endtime_text || item.endtime_text || "--"
                }}<span
                  v-if="item.timeout_text && item.endtime_text"
                  :class="[item.status == 7 ? 'gray' : '']"
                  :style="item.status == 7 ? '' : 'color: #d71518'"
                  >({{ item.timeout_text }})</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-list>

    <!-- 空列表 -->
    <van-empty v-else description="暂无数据" />
  </div>
</template>

<script>
import * as API from "@/api.ts";
export default {
  name: "initTask",
  data() {
    return {
      show: true,
      status: "", //状态筛选
      status_text: "", //状态筛选文字
      start_time: "", //日期筛选(开始)
      end_time: "", //日期筛选(结束)
      create_id: "", //发布人筛选
      create_text: "", //发布人筛选文字
      project_title: "", //项目搜索关键字

      statusList: [], //状态筛选列表
      createList: [], //发布人列表
      list: [], //发起任务列表
      page: 1,
      limit: 10,
      loading: false, //发起任务列表接口是否正在请求
      finished: false, //发起任务是否全部加载完
    };
  },
  computed: {
    // 任务状态文字颜色
    statusColor() {
      return (e) => {
        let color;
        switch (e) {
          case 0:
            break;
          case 1:
            color = "#D71518";
            break;
          case 2:
            break;
          case 3:
            break;
          case 4:
            color = "#D71518";
            break;
          case 5:
            color = "#00A2FF";
            break;
          case 6:
            color = "#FF4200";
            break;
          case 7:
            color = "#666666";
            break;
          case 8:
            color = "#FF4200";
            break;

          default:
            break;
        }
        return color;
      };
    },
  },

  mounted() {
    // 获取任务状态列表
    this.getStatusList();
    // 获取发布人列表
    this.getCreateUserList();
    // 获取发起任务列表
    this.getList();
  },
  methods: {
    // 获取任务状态列表
    async getStatusList() {
      let { code, data } = await API.status();
      if (code == 1) {
        this.statusList = [{ status: "", title: "全部" }, ...data.rows];
      }
    },
    // 选择状态
    onConfirmStatus(e) {
      this.status = e.status;
      this.status_text = e.title;
      // 初始化发起任务
      this.init();
      // 获取发起任务列表
      this.getList();
      // 关闭该弹窗
      this.$refs.statusDom.toggle();
    },

    // 获取发布人列表
    async getCreateUserList() {
      let { code, data } = await API.userList();
      if (code == 1) {
        this.createList = [{ id: "", nickname: "全部" }, ...data.rows];
      }
    },
    // 选择发布人
    onConfirmCreate(e) {
      this.create_id = e.id;
      this.create_text = e.nickname;
      // 初始化发起任务
      this.init();
      // 获取发起任务列表
      this.getList();
      // 关闭该弹窗
      this.$refs.createDom.toggle();
    },

    // 选择时间
    confirmDate(e) {
      let [start, end] = e;
      this.start_time = this.$utils.getDate(start);
      this.end_time = this.$utils.getDate(end);
      // 初始化发起任务
      this.init();
      // 获取发起任务列表
      this.getList();
      // 关闭该弹窗
      this.$refs.dateDom.toggle();
    },

    // 清除时间选择
    clearDate() {
      this.start_time = "";
      this.end_time = "";
      // 初始化发起任务
      this.init();
      // 获取发起任务列表
      this.getList();
      // 关闭该弹窗
      this.$refs.dateDom.toggle();
    },

    // 清除搜索框
    clear() {
      this.project_title = "";
      // 初始化执行任务
      this.init();
      // 获取执行任务列表
      this.getList();
    },

    // 搜索
    onSearch(e) {
      this.init();
      this.getList();
      console.log(e);
    },

    // 初始化发起任务
    init() {
      this.page = 1;
      this.list = [];
      this.loading = false;
      this.finished = false;
    },

    // 获取发起任务列表
    async getList() {
      this.loading = true;
      let {
        page,
        limit,
        list,
        create_id,
        status,
        start_time,
        end_time,
        project_title,
      } = this;
      let params = {
        page,
        limit,
        user_id: create_id,
        status,
        start_time,
        end_time,
        project_title,
      };
      let { code, data } = await API.initiate_list(params);
      if (code == 1) {
        let _list = data.rows;
        if (page == 1) {
          list = _list;
        } else {
          list.push(..._list);
        }
        if (_list.length == 0) {
          this.finished = true;
        }
        this.list = list;
        this.loading = false;
      }
    },

    // 上拉加载更多
    onLoad() {
      if (!this.finished) {
        this.page++;
        this.getList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// .more {
//   width: 0.4rem;
//   height: 0.4rem;
// }

.top-box {
  position: sticky;
  top: 0;
  z-index: 500;
}

// 日期清除按钮
.clear {
  color: #fff;
  background-color: #ee0a24;
  border: 1px solid #ee0a24;
  border-radius: 50px;
  height: 0.7rem;
  margin: 0.3rem 0;
}

// 筛选框列表
.list {
  height: 0.8rem;
  border-bottom: 0.01rem solid #f1f1f1;
}
// 筛选选中高亮
.highlight {
  color: #d71518;
}
// 下划线
.line {
  text-decoration: underline;
}
.blue {
  color: #375394;
}
.gray {
  color: #999999 !important;
}
// 发起任务列表
.task-list-box {
  padding: 0.2rem;
  box-sizing: border-box;
  .task-list {
    width: 100%;
    background: #ffffff;
    border-radius: 0.16rem;

    // 任务顶部
    .task-list-top {
      padding: 0.2rem 0.3rem;
      box-sizing: border-box;
      border-bottom: 0.01rem solid #dddddd;
      // 任务名称
      .task-name {
        font-size: 0.28rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #111111;
        flex: 1;
      }
      // 任务状态文字
      .status_text {
        font-size: 0.26rem;
        font-family: PingFang SC;
        font-weight: 500;
        white-space: nowrap;
      }
    }

    //任务内容
    .task-list-content {
      padding: 0.2rem 0.3rem;
      box-sizing: border-box;
      .label {
        font-size: 0.26rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
      }
      .value {
        font-size: 0.26rem;
        font-family: PingFang SC;
        font-weight: 500;
        white-space: nowrap;
      }
    }
  }
}

::v-deep .van-dropdown-menu__bar {
  // background: unset !important;
  box-shadow: unset !important;
  // height: 1rem !important;
}
</style>