import { get, post } from "./axios.ts";
export const login = (parms = {}) => post("admin/index/login", parms, "");
export const userInfo = (parms = {}) =>
  post("admin/auth/Admin/index", parms, "");
export const menubar = (parms = {}) => post("admin/index/menubar", parms, "");
export const notice = (parms = {}) => post("admin/message/notice", parms, "");
export const todo = (parms = {}) => post("admin/index/todo", parms, "");
export const finish = (parms = {}) => post("admin/index/finish", parms, "");
export const reward = (parms = {}) => post("admin/index/reward", parms, "");
export const Task = (parms = {}) => post("admin/project/Task/index", parms, "");
export const projectadd = (parms = {}) =>
  post("admin/project/project/add", parms, "");
export const projectIndex = (parms = {}) =>
  post("admin/project/project/index", parms, "");
export const oprette = (parms = {}) =>
  post("admin/project/Task/add", parms, "");
export const checkoprette = (parms = {}) =>
  get("admin/project/Task/add", parms, "");
export const userList = (parms = {}) =>
  post("admin/project/Project/userList", parms, "");
export const section = (parms = {}) =>
  post("admin/section/section/lists", parms, "");
export const file = (parms = {}) => post("admin/ajax/upload", parms, "");
export const rewardlog = (parms = {}) =>
  post("admin/project/Reward/rewardlog", parms, "");
export const add_subtask = (parms = {}) =>
  post("admin/project/Task/add_subtask", parms, "");
export const OrderTaking = (parms = {}) =>
  post("admin/project/Task/OrderTaking", parms, "");
export const self_task = (parms = {}) =>
  post("admin/project/Task/self_task", parms, "");
export const status = (parms = {}) =>
  post("admin/project/Project/status", parms, "");
export const myCp = (parms = {}) =>
  post("admin/project/Reward/myCp", parms, "");
export const Rewardexchange = (parms = {}) =>
  post("admin/project/Reward/exchange", parms, "");
export const toMoney = (parms = {}) =>
  post("admin/project/Reward/toMoney", parms, "");
export const Admin = (parms = {}) => post("admin/auth/Admin/index", parms, "");
export const Product = (parms = {}) => post("admin/Product/index", parms, "");
export const exchangeGift = (parms = {}) =>
  post("admin/project/Reward/exchangeGift", parms, "");
export const exchangeGiftLog = (parms = {}) =>
  post("admin/project/Reward/exchangeGiftLog", parms, "");
export const GiftRevoke = (parms = {}) =>
  post("admin/project/Reward/GiftRevoke", parms, "");
export const listCp = (parms = {}) =>
  post("admin/project/project/listCp", parms, "");
export const exchangeCp = (parms = {}) =>
  post("admin/project/Reward/exchangeCp", parms, "");
export const cpDetail = (parms = {}) =>
  post("admin/project/reward/cpDetail", parms, "");
export const editPassword = (parms = {}) =>
  post("admin/auth/admin/editPassword", parms, "");
export const allteam = (parms = {}) =>
  post("admin/auth/Admin/allteam", parms, "");
export const rankingList = (parms = {}) =>
  post("admin/auth/Admin/rankingList", parms, "");
export const TAperson = (parms = {}) =>
  post("admin/auth/Admin/person", parms, "");
export const personDaily = (parms = {}) =>
  post("admin/project/daily/Dailyplan/personDaily", parms, "");
export const taskbug = (parms = {}) =>
  post("admin/project/taskbug/lists", parms, "");
export const taskbugDesc = (parms = {}) =>
  post("admin/project/taskbug/details", parms, "");
export const appoint = (parms = {}) =>
  post("admin/project/taskbug/appoint", parms, "");
export const TaskLists = (parms = {}) =>
  post("admin/project/Task/lists", parms, "");
export const solution = (parms = {}) =>
  post("admin/project/taskbug/solution", parms, "");
export const solutionList = (parms = {}) =>
  post("admin/project/taskbug/solutionList", parms, "");
export const solve = (parms = {}) =>
  post("admin/project/taskbug/solve", parms, "");
export const close = (parms = {}) =>
  post("admin/project/taskbug/close", parms, "");
export const process = (parms = {}) =>
  post("admin/project/Task/process", parms, "");
export const exchangeLog = (parms = {}) =>
  post("admin/project/Reward/exchangeLog", parms, "");
export const AdminList = (parms = {}) =>
  post("admin/auth/Admin/lists", parms, "");
export const giftList = (parms = {}) =>
  post("admin/project/Reward/giftList", parms, "");
export const exchangeCpLog = (parms = {}) =>
  post("admin/project/Reward/exchangeCpLog", parms, "");
export const sectionList = (parms = {}) =>
  post("admin/section/section/lists", parms, "");
export const sectionEdit = (parms = {}) =>
  post("admin/section/section/edit", parms, "");
export const sectionAdd = (parms = {}) =>
  post("admin/section/section/add", parms, "");
export const sectionDel = (parms = {}) =>
  post("admin/section/section/del", parms, "");
export const sectionIndex = (parms = {}) =>
  post("admin/section/section/index", parms, "");
export const adminEdit = (parms = {}) =>
  post("admin/auth/Admin/edit", parms, "");
export const AdminAdd = (parms = {}) => post("admin/auth/Admin/add", parms, "");
export const typeList = (parms = {}) =>
  post("admin/project/project/typeList", parms, "");
export const myInfo = (parms = {}) =>
  post("admin/project/Reward/myInfo", parms, "");
export const staffDetail = (parms = {}) =>
  post("admin/project/Reward/staffDetail", parms, "");
export const addCp = (parms = {}) =>
  post("admin/project/project/addCp", parms, "");
export const divvyAdd = (parms = {}) =>
  post("admin/project/Divvy/divvyAdd", parms, "");
export const ProcessEdit = (parms = {}) =>
  post("admin/project/Process/edit", parms, "");
export const Dailyplan = (parms = {}) =>
  post("admin/project/daily/Dailyplan/add", parms, "");
export const initiate_list = (parms = {}) =>
  post("admin/project/Task/initiate_list", parms, "");
export const self_check = (parms = {}) =>
  post("admin/project/task/self_check", parms, "");
export const sureCheck = (parms = {}) =>
  post("admin/project/check/check", parms, "");
export const historyRankingList = (parms = {}) =>
  post("admin/auth/Admin/historyRankingList", parms, "");
export const personnel = (parms = {}) =>
  post("admin/project/daily/dailyplan/personnel", parms, "");
export const release_review = (parms = {}) =>
  post("admin/review/release_review/index", parms, "");
export const release_review_Type = (parms = {}) =>
  post("admin/review/release_review/typeList", parms, "");
export const single_reward = (parms = {}) =>
  post("admin/project/Task/single_reward", parms, "");
export const release_reviewDescription = (parms = {}) =>
  post("admin/review/release_review/details", parms, "");
export const detail = (parms = {}) =>
  post("admin/project/project/detail", parms, "");
export const CpDetail = (parms = {}) =>
  post("admin/project/project/CpDetail", parms, "");
export const Adminorder = (parms = {}) =>
  post("admin/Product/order", parms, "");
export const productend = (parms = {}) => post("admin/Product/end", parms, "");
export const taskbugAdd = (parms = {}) =>
  post("admin/project/taskbug/add", parms, "");
export const divvyDetail = (parms = {}) =>
  post("admin/project/Divvy/divvyDetail", parms, "");
export const correct_reward = (parms = {}) =>
  post("admin/project/Task/correct_reward", parms, "");
export const score_record = (parms = {}) =>
  post("admin/auth/admin/score_record", parms, "");
export const divvyEnd = (parms = {}) =>
  post("admin/project/Divvy/divvyEnd", parms, "");
export const DailyplanList = (pamrs = {}) =>
  post("admin/project/daily/Dailyplan/lists", pamrs, "");
export const all_read = (parms = {}) =>
  post("admin/message/all_read", parms, "");
export const editrelease_review = (parms = {}) =>
  post("admin/review/release_review/edit", parms, "");
export const projectTask = (parms = {}) =>
  post("admin/project/project/task", parms, "");
export const projectdaily = (parms = {}) =>
  post("admin/project/project/daily", parms, "");
export const projectreward = (parms = {}) =>
  post("admin/project/project/reward", parms, "");
export const send = (parms = {}) => post("admin/sms/send", parms, "");
export const smsCode = (parms = {}) => post("admin/sms/check", parms, "");
export const resetpwd = (parms = {}) => post("admin/index/resetpwd", parms, "");
export const changemobile = (parms = {}) =>
  post("admin/index/changemobile", parms, "");
export const adminedit = (parms = {}) =>
  post("admin/auth/Admin/edit", parms, "");
export const exchangeLogHandle = (parms = {}) =>
  post("admin/project/Reward/exchangeLogHandle", parms, "");
export const RewardgiftList = (parms = {}) =>
  post("admin/project/Reward/giftList", parms, "");
export const details = (parms = {}) =>
  post("admin/project/Task/details", parms, "");
export const gifLogHandle = (parms = {}) =>
  post("admin/project/Reward/gifLogHandle", parms, "");
export const projectlists = (parms = {}) =>
  post("admin/project/check/lists", parms, "");
export const mobile_phone = (parms = {}) =>
  post("admin/project/check/mobile_phone", parms, "");
export const disable = (pamrs = {}) =>
  post("admin/auth/Admin/disable", pamrs, "");
export const task_type = (parms = {}) =>
  post("admin/project/task/task_type", parms, "");
export const acceptance_personnel = (parms = {}) =>
  post("admin/project/Task/acceptance_personnel", parms, "");
export const team = (parms = {}) => post("admin/auth/Admin/team", parms, "");
export const bulletinList = (parms = {}) =>
  post("admin/message/bulletinList", parms, "");
export const bulletinDetails = (parms = {}) =>
  post("admin/message/details", parms, "");
export const delBulletin = (parms = {}) =>
  post("admin/message/delBulletin", parms, "");
export const bulletin = (parms = {}) =>
  post("admin/message/bulletin", parms, "");
export const PersonnelList = (parms = {}) =>
  post("admin/project/project/PersonnelList", parms, "");
export const taskLog = (parms = {}) =>
  post("admin/project/Task/taskLog", parms, "");
export const rewardDetail = (parms = {}) =>
  post("admin/project/Task/rewardDetail", parms, "");
export const memo = (parms = {}) =>
  post("admin/project/Reward/memo", parms, ""); //获取奖励点记录事项筛选列表
export const release_review_reward = (parms = {}) =>
  post("admin/review/release_review/reward", parms, "");
export const getexchangeGift = (parms = {}) =>
  get("admin/project/Reward/exchangeGift", parms, "");
export const productDetail = (parms = {}) =>
  post("admin/Product/detail", parms, ""); //商品详情
export const divvyEdit = (parms = {}) =>
  post("admin/project/Divvy/divvyEdit", parms, ""); //修改项目分红
export const exchangeGiftInfo = (parms = {}) =>
  post("admin/project/Reward/exchangeGiftInfo", parms, ""); //兑换详情
export const Processpause = (parms = {}) =>
  post("admin/project/Process/pause", parms, ""); //暂停
export const pauseCancel = (parms = {}) =>
  post("admin/project/Process/pauseCancel", parms, ""); //取消
export const pauseStart = (parms = {}) =>
  post("admin/project/Process/pauseStart", parms, ""); //开始
export const close_task = (parms = {}) =>
  post("admin/project/Task/close_task", parms, ""); //关闭
export const introduceList = (parms = {}) =>
  post("admin/project/task/introduceList", parms, ""); //用户协议，隐私政策，员工奖励点提示，员工CP值 (参与项目)提示
export const taskLogUser = (parms = {}) =>
  post("admin/project/Task/taskLogUser", parms, ""); //日志人员列表
export const ranking = (parms = {}) =>
  post("admin/auth/Admin/ranking", parms, ""); //排行榜
export const addImage = (parms = {}) =>
  post("admin/project/task/addImg", parms, ""); //添加图片
export const modified = (parms = {}) => post('admin/project/task/modified_by', parms, '')//更改验证人
export const deleteImg = (parms = {}) => post('admin/project/Task/deleteImg', parms, '')//删除图片
export const editTask = (parms = {}) => post('admin/project/task/edit', parms, '')//修改内容
export const plann = (parms = {}) => post("admin/project/daily/Dailyplan/lists", parms, ""); //今日计划
export const planall = (parms = {}) => post('admin/Index/planall', parms, '')//全部计划
export const exchangeCpBack = (parms = {}) => post('admin/project/Reward/exchangeCpBack', parms, '')//cp值兑换记录，退回
export const bugTask = (parms = {}) => post('admin/project/Task/bugTask', parms, '')//解决bug任务列表
export const bugType = (parms = {}) => post('admin/project/taskbug/bugType', parms, '')//bug类型
export const bugLevel = (parms = {}) => post('admin/project/taskbug/bugLevel', parms, '')//bug等级
export const getStatus = (parms = {}) => post('admin/project/taskbug/getStatus', parms, '')//bug状态
export const projectAddImg = (parms = {}) => post('admin/project/project/addImg', parms, '')//项目添加图片或附件
export const projectdeleteImg = (parms = {}) => post('admin/project/project/deleteImg', parms, '')//项目删除图片或附件
export const projectdEdit = (parms = {}) => post('admin/project/project/edit', parms, '')//项目简介修改
export const task_total = (parms = {}) => post('admin/index/task_total', parms, '')//进行中任务统计
export const finishall = (parms = {}) => post('admin/index/finishall', parms, '')//完成的统计
export const rewardAll = (parms = {}) => post('admin/index/rewardAll', parms, '')//首页的奖励点
export const taskList = (parms = {}) => post('admin/project/task/taskList', parms, '')//首页的任务池
export const station = (parms = {}) => post('admin/section/section/station', parms, '')//岗位搜索列表
