<template>
    <div class="cpprojekt">
        <div class="cpprojekt_content">
            <div class="cpprojekt_header">
                <publicNavigation @sureEvenet="sureEvenet" :data="navigation"/>
            </div>
            <div class="cpprojekt_content">
                <cpprojektHeader :userOrder="userOrder"/>
            </div>
            <div class="cpprojektCard">
                <div class="cpprojektCard_content">
                    <div class="cpprojektCard_header">
                        <div class="cpprojektCard_title">
                            <span>列表</span>
                        </div>
                    </div>
                    <cpprojektCard 	distinguishProject="1" @sureEvent="cpDescription" @editEvent="editEvent" @moreEventClick="moreEventClick" :cardData="cardData" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import publicNavigation from '@/publicNavigation/publicNavigation.vue';
import cpprojektHeader from '@/cpprojekt/compoments/cpprojektHeader.vue'
import cpprojektCard from '@/cpprojekt/compoments/cpprojektCard.vue'
import * as API from '@/api.ts'
export default {
    components:{
        publicNavigation,
        cpprojektHeader,
        cpprojektCard
    },
    data(){
        return{
            navigation:{},
            cardData:[],
            page:1
        }
    },
    mounted(){
        this.navigation = {
            image: require("../assets/return.png"),
            name: "cp值项目",
            sure:'添加项目',
			close: ''
        };
		this.Requset()
    },
    methods:{
		Requset(){
			API.listCp({
                page:this.page,
                limit:10
            }).then(async e=>{
				if(e.code == 1){
                    // if(e.data.rows.length <= 0){
                    //     this.$toast.fail('暂无数据')
                    // }
					e.data.rows.map(item=>{
						this.cardData.push({
							title:item.name,
							...item,
							children:[{
								name:'cp值兑换额：',
								text:item.exchange ? item.exchange : '--'
							},{
								name:'参与人：',
								text:item.person_num ? item.person_num + '人' : '--'
							},{
								name:'已分红：',
								text:item.dividend_paid ? item.dividend_paid : '--'
							},{
								name:'分红期：', 
								text:item.divvy_time_text ? item.divvy_time_text : '--'
							},{
								name:'分红金额：',
								text:item.divvy_text ? item.divvy_text : '--'
							}]
						})
					})
                    this.userOrder = e.data
				}
			})
		},
        sureEvenet(){
            this.$router.push({
                name:'addbonus',
                path:'/addbonus'
            })
        },
		cpDescription(s){
			this.$router.push({
				name:'cpDescription',
				path:'/cpDescription',
				query:{
					description:JSON.stringify(s)
				}
			})
		},
        editEvent(s){
            this.$router.push({
                name:'addbonus',
                path:'/addbonus',
                query:{
                    s:s
                }
            })
        },
        moreEventClick(){
            this.page ++;
            this.$toast.loading('加载中...')
            setTimeout(()=>{
                this.Requset()
            },1000)
        }
    }
}
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter{
    display: flex;
    align-items: center;
}
  
// 计算函数
@function calcFunction($number,$default:16){
  @return calc($number * 2 / 100) + rem;
}
.cpprojekt{
    background: #F1F0F5;
    padding-bottom: calcFunction(2);
    .cpprojekt_header{
        position: sticky;
        top: 0;
        background: white;
    }
}

.cpprojektCard{
    margin: calcFunction(10);
    .cpprojektCard_content{
        background: white;
        border-radius: calcFunction(4);
        padding: calcFunction(15);
        .cpprojektCard_header{
            .cpprojektCard_title{
                padding-bottom: calcFunction(15);
                span{
                    font-size: calcFunction(14);
                }
            }
        }
    }
}
</style>