<!--
 * @Author: Mr.Cheng
 * @Date: 2023-10-09 16:51:17
 * @LastEditTime: 2023-10-20 10:51:33
 * @FilePath: \octopus-factory-map\src\new\solveBug\solveBug.vue
-->
<template>
  <div>
    <div class="top-box">
      <!-- 顶部标题 -->
      <div class="flex-c-c task-navigate-box">
        <!-- 返回按钮 -->
        <img
          @click="$router.go(-1)"
          class="task-left-back"
          src="@/assets/return.png"
          alt=""
        />
        <van-dropdown-menu>
          <!-- 解决bug -->
          <van-dropdown-item ref="taskDom">
            <template #title>
              <div>{{ taskTitle || "解决bug" }}</div>
            </template>
            <div>
              <div
                @click="onConfirmTask(item)"
                :class="[
                  'list',
                  'flex-c-c',
                  item.id == task ? 'highlight' : '',
                ]"
                v-for="item of acceptanceTask"
                :key="item.id"
              >
                {{ item.title }}
              </div>
            </div>
          </van-dropdown-item>
        </van-dropdown-menu>
      </div>

      <!-- 搜索框-->

      <van-search
        v-model="bug_name"
        @search="onSearch"
        show-action
        shape="round"
        background="#FFF"
        placeholder="请输入bug名称"
      >
        <template #action>
          <van-dropdown-menu>
            <van-dropdown-item
              ref="navClassDom"
              get-container="body"
              @open="showNav = false"
              @close="showNav = true"
            >
              <template #title>
                <img
                  class="more"
                  @click="show = true"
                  src="@/assets/more.png"
                  alt=""
                />
              </template>
              <template #default>
                <!-- 筛选框 -->
                <van-dropdown-menu>
                  <!-- bug类型 -->
                  <van-dropdown-item ref="bugTypeDom">
                    <template #title>
                      <div>{{ bug_type || "bug类型" }}</div>
                    </template>
                    <div>
                      <div
                        @click="onConfirmBugType(item)"
                        :class="[
                          'list',
                          'flex-c-c',
                          item == bug_type ? 'highlight' : '',
                        ]"
                        v-for="item of bugList"
                        :key="item"
                      >
                        {{ item }}
                      </div>
                    </div>
                  </van-dropdown-item>
                  <!-- 解决方案 -->
                  <van-dropdown-item ref="bugSolveDom">
                    <template #title>
                      <div>{{ bug_solve || "解决方案" }}</div>
                    </template>
                    <div>
                      <div
                        @click="onConfirmBugSolve(item)"
                        :class="[
                          'list',
                          'flex-c-c',
                          item.status == bug_solve ? 'highlight' : '',
                        ]"
                        v-for="item of solutionList"
                        :key="item.status"
                      >
                        {{ item.title }}
                      </div>
                    </div>
                  </van-dropdown-item>

                  <!-- bug等级 -->
                  <van-dropdown-item ref="bugLevelDom">
                    <template #title>
                      <div>
                        {{
                          bug_level
                            ? bug_level != "全部"
                              ? bug_level + "级"
                              : "全部"
                            : "bug等级"
                        }}
                      </div>
                    </template>
                    <div>
                      <div
                        @click="onConfirmBugLevel(item)"
                        :class="[
                          'list',
                          'flex-c-c',
                          item == bug_level ? 'highlight' : '',
                        ]"
                        v-for="item of bugLevelList"
                        :key="item"
                      >
                        {{ item == "全部" ? item : item + "级" }}
                      </div>
                    </div>
                  </van-dropdown-item>
                </van-dropdown-menu>
                <!-- 筛选框 -->
                <van-dropdown-menu>
                  <!-- bug状态 -->
                  <van-dropdown-item ref="statusDom">
                    <template #title>
                      <div>{{ status_text || "bug状态" }}</div>
                    </template>
                    <div>
                      <div
                        @click="onConfirmStatus(item)"
                        :class="[
                          'list',
                          'flex-c-c',
                          item.status == bug_status ? 'highlight' : '',
                        ]"
                        v-for="item of bugStatusList"
                        :key="item.status"
                      >
                        {{ item.title }}
                      </div>
                    </div>
                  </van-dropdown-item>
                  <!-- 创建时间 -->
                  <van-dropdown-item ref="dateDom">
                    <template #title>
                      <div v-if="start_time && start_time != end_time">
                        <div>{{ start_time }} ~</div>
                        <div>{{ end_time }}</div>
                      </div>
                      <div v-else-if="start_time && start_time == end_time">
                        <div>{{ start_time }}</div>
                      </div>
                      <div v-else>创建时间</div>
                    </template>
                    <div>
                      <van-calendar
                        @confirm="confirmDate"
                        title="选择时间"
                        type="range"
                        :poppable="false"
                        :min-date="new Date(2023, 0, 1)"
                        :max-date="new Date()"
                        :allow-same-day="true"
                        :show-confirm="false"
                        :style="{ height: '500px' }"
                      >
                        <template #footer>
                          <div @click="clearDate" class="clear flex-c-c">
                            清除
                          </div>
                        </template>
                      </van-calendar>
                    </div>
                  </van-dropdown-item>
                </van-dropdown-menu>
              </template>
            </van-dropdown-item>
          </van-dropdown-menu>
        </template>
      </van-search>
      <!-- 筛选框 bug状态筛选 -->
      <div v-show="showNav" class="flex-c-b statusNav">
        <div
          v-for="(item, index) of navList"
          :key="index"
          @click="selectNav(index)"
          :class="['nav', status == index ? 'activeNav' : '']"
        >
          {{ item }}<span v-if="status == index">({{ total }})</span>
        </div>
      </div>
    </div>

    <!-- 列表 -->
    <van-list
      v-if="list.length > 0"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="task-list-box">
        <div
          @click="
            $router.push({
              path: '/solveBugDescription',
              query: { id: item.id, bugs: 1 },
            })
          "
          class="task-list mb10"
          v-for="item of list"
          :key="item.id"
        >
          <!-- 任务顶部 -->
          <div class="task-list-top">
            <!-- 任务名称&状态 -->
            <div class="flex-c-b">
              <div class="task-name blue line mr20">{{ item.title }}</div>
              <div
                class="status_text"
                :style="{ color: statusColor(item.status) }"
              >
                {{ item.status_text }}
              </div>
            </div>
          </div>
          <!-- bug内容 -->
          <div class="task-list-content">
            <div class="flex-c">
              <div style="width: 100%" class="flex-c mt10">
                <div class="label">bug类型：</div>
                <div class="value">{{ item.type_text }}</div>
              </div>
              <div style="width: 100%" class="flex-c mt10">
                <div class="label">指派给：</div>
                <div class="value">{{ item.solve_name || "--" }}</div>
              </div>
            </div>
            <div class="flex-c">
              <div style="width: 100%" class="flex-c mt10">
                <div class="label">bug等级：</div>
                <div
                  :class="[
                    'flex-c-c',
                    item.level_text == 1
                      ? 'levelGray'
                      : item.level_text == 2
                      ? 'levelYellow'
                      : 'levelRed',
                  ]"
                >
                  {{ item.level_text }}
                </div>
              </div>
              <div style="width: 100%" class="flex-c mt10">
                <div class="label">创建日期：</div>
                <div class="value">{{ item.createtime_text || "--" }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-list>

    <!-- 空列表 -->
    <van-empty v-else description="暂无数据" />
  </div>
</template>
    
    <script>
import * as API from "@/api.ts";
export default {
  name: "acceptanceTask",
  data() {
    return {
      show: true,
      showNav: true, //是否显示导航栏
      bugList: [], //bug类型列表
      status: "", //状态筛选
      status_text: "", //状态筛选文字
      start_time: "", //日期筛选(开始)
      end_time: "", //日期筛选(结束)
      create_id: "", //发布人筛选
      create_text: "", //发布人筛选文字

      bug_end: "", //结束时间
      bug_level: "", //bug等级
      bug_name: "", //bug名
      bug_solve: "", //解决方案
      bug_start: "", //开始时间
      bug_status: "", //bug状态
      bug_type: "", //bug类型
      task: "", //顶部选中的验收人id

      acceptanceTask: [], //任务列表
      taskTitle: "", //顶部选中的验收人

      statusList: [], //状态筛选列表
      createList: [], //发布人列表
      total: 0, //列表当前数量
      navList: ["所有", "未关闭", "未解决", "已解决"],
      bugLevelList: [], //bug等级列表
      solutionList: [], //解决方案列表
      bugStatusList: [], //bug状态列表
      list: [], //验收bug列表
      status: 0, //选中的bug状态
      page: 1,
      limit: 10,
      loading: false, //验收bug列表接口是否正在请求
      finished: false, //验收bug是否全部加载完
    };
  },
  computed: {
    // bug状态文字颜色
    statusColor() {
      return (e) => {
        let color;
        switch (e) {
          case 0:
            break;
          case 1:
            color = "#D71518";
            break;
          case 2:
            break;
          case 3:
            break;
          case 4:
            color = "#666666";
            break;

          default:
            break;
        }
        return color;
      };
    },
  },

  mounted() {
    // 获取验收任务列表
    this.getAcceptanceTask();
    //获取bug类型列表
    this.getBugType();
    //获取解决方案列表
    this.getSolutionList();
    //获取bug等级列表
    this.getBugLevel();
    //获取bug状态列表
    this.getBugStatus();
  },
  methods: {
    //获取bug类型列表
    async getBugType() {
      let { code, data } = await API.bugType();
      if (code == 1) {
        this.bugList = ["全部", ...data];
      }
    },
    //获取bug等级列表
    async getBugLevel() {
      let { code, data } = await API.bugLevel();
      if (code == 1) {
        this.bugLevelList = ["全部", ...data];
      }
    },
    //获取解决方案列表
    async getSolutionList() {
      let { code, data } = await API.solutionList();
      if (code == 1) {
        this.solutionList = [{ status: "", title: "全部" }, ...data];
      }
    },
    //获取bug状态列表
    async getBugStatus() {
      let { code, data } = await API.getStatus();
      if (code == 1) {
        this.bugStatusList = [{ status: "", title: "全部" }, ...data];
      }
    },
    // 选择任务
    onConfirmTask(item) {
      this.taskTitle = item.title;
      this.task = item.id;
      // 初始化当前bug数
      this.total = 0;
      // 初始化验收bug
      this.init();
      // 获取验收bug列表
      this.getList();
      // 关闭该弹窗
      this.$refs.taskDom.toggle();
    },
    // 获取验收任务列表
    async getAcceptanceTask() {
      let { code, data } = await API.bugTask();
      if (code == 1) {
        this.acceptanceTask = data.rows;
        this.taskTitle = data.rows[0].title;
        this.task = data.rows[0].id;
        // 获取验收bug列表
        this.getList();
      }
    },
    // 选择筛选栏状态（非展开）
    selectNav(index) {
      this.status = index;
      // 初始化当前bug数
      this.total = 0;
      // 初始化验收bug
      this.init();
      // 获取验收bug列表
      this.getList();
    },
    // bug类型确认
    onConfirmBugType(item) {
      this.bug_type = item;
      // 初始化当前bug数
      this.total = 0;
      // 初始化验收bug
      this.init();
      // 获取验收bug列表
      this.getList();
      // 关闭更多筛选框
      this.$refs.navClassDom.toggle();
      // 关闭bug类型弹窗
      this.$refs.bugTypeDom.toggle();
    },
    // bug等级确认
    onConfirmBugLevel(item) {
      this.bug_level = item;
      // 初始化当前bug数
      this.total = 0;
      // 初始化验收bug
      this.init();
      // 获取验收bug列表
      this.getList();
      // 关闭更多筛选框
      this.$refs.navClassDom.toggle();
      // 关闭bug等级弹窗
      this.$refs.bugLevelDom.toggle();
    },
    // 解决方案
    onConfirmBugSolve(item) {
      this.bug_solve = item.title;
      // 初始化当前bug数
      this.total = 0;
      // 初始化验收bug
      this.init();
      // 获取验收bug列表
      this.getList();
      // 关闭更多筛选框
      this.$refs.navClassDom.toggle();
      // 关闭解决方案弹窗
      this.$refs.bugSolveDom.toggle();
    },
    // 选择bug状态
    onConfirmStatus(e) {
      this.bug_status = e.status;
      this.status_text = e.title;
      // 初始化验收bug
      this.init();
      // 获取验收bug列表
      this.getList();
      // 关闭更多筛选框
      this.$refs.navClassDom.toggle();
      // 关闭该弹窗
      this.$refs.statusDom.toggle();
    },

    // 选择发布人
    onConfirmCreate(e) {
      this.create_id = e.id;
      this.create_text = e.nickname;
      // 初始化验收bug
      this.init();
      // 获取验收bug列表
      this.getList();
      // 关闭该弹窗
      this.$refs.createDom.toggle();
    },

    // 选择时间
    confirmDate(e) {
      let [start, end] = e;
      this.start_time = this.$utils.getDate(start);
      this.end_time = this.$utils.getDate(end);
      this.bug_start = Date.parse(start) / 1000;
      this.bug_end = Date.parse(end) / 1000 + 86399;
      // 初始化验收bug
      this.init();
      // 获取验收bug列表
      this.getList();
      // 关闭更多筛选框
      this.$refs.navClassDom.toggle();
      // 关闭该弹窗
      this.$refs.dateDom.toggle();
    },

    // 清除时间选择
    clearDate() {
      this.start_time = "";
      this.end_time = "";
      this.bug_start = "";
      this.bug_end = "";
      // 初始化验收bug
      this.init();
      // 获取验收bug列表
      this.getList();
      // 关闭更多筛选框
      this.$refs.navClassDom.toggle();
      // 关闭该弹窗
      this.$refs.dateDom.toggle();
    },

    // 搜索
    onSearch(e) {
      this.init();
      this.getList();
      console.log(e);
    },

    // 初始化验收bug
    init() {
      this.page = 1;
      this.list = [];
      this.loading = false;
      this.finished = false;
    },

    // 获取验收bug列表
    async getList() {
      this.loading = true;
      let {
        status,
        bug_end,
        bug_level,
        bug_name,
        bug_solve,
        bug_start,
        bug_status,
        bug_type,
        task,
        page,
        limit,
        list,
      } = this;
      let params = {
        status: status + 1,
        bug_end,
        bug_level: bug_level == "全部" ? "" : bug_level,
        bug_name,
        bug_start,
        bug_status,
        bug_type: bug_type == "全部" ? "" : bug_type,
        bug_solve: bug_solve == "全部" ? "" : bug_solve,
        task,
        page,
        limit,
      };
      let { code, data } = await API.taskbug(params);
      if (code == 1) {
        let _list = data.rows;
        if (page == 1) {
          list = _list;
        } else {
          list.push(..._list);
        }
        if (_list.length == 0) {
          this.finished = true;
        }
        this.list = list;
        this.total = data.total;
        this.loading = false;
      }
    },

    // 上拉加载更多
    onLoad() {
      if (!this.finished) {
        this.page++;
        this.getList();
      }
    },
  },
};
</script>
    
    <style lang="scss" scoped>
.more {
  width: 0.4rem;
  height: 0.4rem;
}

.top-box {
  position: sticky;
  top: 0;
  z-index: 500;
}
// 状态 筛选框
.statusNav {
  padding: 0.3rem;
  box-sizing: border-box;
  background: #fff;
  .nav {
    font-size: 0.3rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  .activeNav {
    font-size: 0.3rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #d71518;
  }
}
// 日期清除按钮
.clear {
  color: #fff;
  background-color: #ee0a24;
  border: 1px solid #ee0a24;
  border-radius: 50px;
  height: 0.7rem;
  margin: 0.3rem 0;
}

.levelGray {
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
  border: 0.01rem solid #666666;
  color: #666666;
  font-size: 0.2rem;
  line-height: 0.3rem;
}
.levelRed {
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
  border: 0.01rem solid #d71518;
  color: #d71518;
  font-size: 0.2rem;
  line-height: 0.3rem;
}
.levelYellow {
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
  border: 0.01rem solid #ff7200;
  color: #ff7200;
  font-size: 0.2rem;
  line-height: 0.3rem;
}

// 筛选框列表
.list {
  height: 0.8rem;
  border-bottom: 0.01rem solid #f1f1f1;
}
// 筛选选中高亮
.highlight {
  color: #d71518;
}
// 下划线
.line {
  text-decoration: underline;
}
.blue {
  color: #375394;
}
.gray {
  color: #999999;
}
// 验收bug列表
.task-list-box {
  padding: 0.2rem;
  box-sizing: border-box;
  .task-list {
    width: 100%;
    background: #ffffff;
    border-radius: 0.16rem;

    // 任务顶部
    .task-list-top {
      padding: 0.2rem 0.3rem;
      box-sizing: border-box;
      border-bottom: 0.01rem solid #dddddd;
      // 任务名称
      .task-name {
        font-size: 0.28rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #111111;
        flex: 1;
      }
      // 任务状态文字
      .status_text {
        font-size: 0.26rem;
        font-family: PingFang SC;
        font-weight: 500;
        white-space: nowrap;
      }
    }

    //任务内容
    .task-list-content {
      padding: 0.2rem 0.3rem;
      box-sizing: border-box;
      .label {
        font-size: 0.26rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
      }
      .value {
        font-size: 0.26rem;
        font-family: PingFang SC;
        font-weight: 500;
        white-space: nowrap;
      }
    }
  }
}

::v-deep .van-dropdown-menu__bar {
  // background: unset !important;
  box-shadow: unset !important;
  // height: 1rem !important;
}
</style>