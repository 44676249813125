<template>
  <div ref="homeUl" class="optageList">
    <div class="optageList_content">
      <div class="optageList_content_data">
        <div style="position: sticky; top: 0; z-index: 9">
          <div class="optageList_content_header">
            <publicNavigationVue :data="navigation" />
          </div>
          <div class="oprageList_classfiy">
            <!-- <van-dropdown-item
              @open="change"
              @close="closeChange"
              v-model="value1"
              :options="option1"
            /> -->
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <div
                @click="change"
                style="margin-right: 0.1rem; margin-left: 0.1rem"
              >
                <span style="white-space: nowrap; font-size: 14px">
                  {{ dateSearch || "时间选择" }}</span
                >
              </div>
              <img
                v-if="dateSearch"
                @click="clearSeach"
                style="width: 0.3rem; height: 0.3rem"
                src="@/assets/clear.png"
                alt=""
              />
            </div>
            <van-dropdown-menu>
              <van-dropdown-item
                @open="matterList = list"
                @close="matterClose()"
                @change="
                  page = 1;
                  detaljerData = [];
                  finished = false;
                  Request();
                "
                v-model="matterSearch"
                :options="matterList"
              />
            </van-dropdown-menu>
          </div>
        </div>
        <div class="pt15">
          <van-list
            v-if="detaljerData.length > 0"
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <!-- :height="80" -->
            <bonuspointList :detaljerData="detaljerData" />
          </van-list>
          <van-empty v-else description="暂无数据" />
        </div>
      </div>
    </div>
    <van-calendar
      :allow-same-day="true"
      :min-date="new Date(2023, 6, 1)"
      :max-date="new Date()"
      v-model="show"
      type="range"
      @confirm="onConfirm"
    />
  </div>
</template>
<script>
import publicNavigationVue from "@/publicNavigation/publicNavigation.vue";
import bonuspointList from "@/bonuspoint/compoments/bonuspointList.vue";
import * as API from "@/api.ts";
export default {
  components: {
    publicNavigationVue,
    bonuspointList,
  },
  data() {
    return {
      loading: false,
      finished: false,
      navigation: {},
      value1: 0,
      value2: "事项",
      dateSearch: "", //搜索日期
      list: [], //选择事项列表接口数据
      matterList: [{ text: "事项", value: "事项" }], //选择事项列表
      matterSearch: "事项", //选择事项
      option1: [{ text: "选择时间", value: 0 }],
      option2: [{ text: "全部", value: "a" }],
      detaljerData: [],
      show: false,
      starts: "", //开始日期
      ends: "", //结束日期
      page: 1,
      limit: 15,
    };
  },
  mounted() {
    // 监听如果页面发生滚动时

    this.navigation = {
      image: require("@/assets/return.png"),
      name: "奖励点记录",
    };

    //获取事项筛选列表
    this.getList();
  },
  methods: {
    //加载更多
    onLoad() {
      if (this.detaljerData.length > 0) {
        this.page++;
        this.Request();
      } else {
        this.loading = false;
      }
    },

    //关闭事项筛选
    matterClose() {
      if (this.matterSearch == "事项") {
        setTimeout(() => {
          this.matterList = [{ text: "事项", value: "事项" }];
        }, 100);
      }
    },
    //获取事项筛选列表
    getList() {
      API.memo().then(async (res) => {
        if (res.code == 1) {
          let list = JSON.parse(JSON.stringify(res.data));
          let _list = [];
          list.forEach((item) => {
            _list.push({ text: item, value: item });
          });
          this.list = [{ text: "全部", value: "全部" }, ..._list];
          this.Request();
        }
      });
    },
    //清楚日期筛选
    clearSeach() {
      this.page = 1;
      this.starts = "";
      this.ends = "";
      this.dateSearch = "";
      this.detaljerData = [];
      this.finished = false;
      this.Request();
    },
    Request() {
      this.loading = true;

      let { starts, ends, matterSearch, page, limit } = this;
      const parms = {
        start_time: starts,
        user_id:this.$route.query.id,
        end_time: ends,
        memo:
          matterSearch == "全部" || matterSearch == "事项" ? "" : matterSearch,
        page,
        limit,
      };
      API.rewardlog(parms).then(async (e) => {
        if (e.code == 1) {
          var list = e.data.rows;
          if (page == 1) {
            if (e.data.rows.length > 0) {
              this.detaljerData = list;
              this.page++;
            } else {
              this.finished = true;
            }
          } else {
            if (e.data.rows.length > 0) {
              this.detaljerData.push(...list);
              this.page++;
            } else {
              this.finished = true;
            }
          }
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    change() {
      // if (this.option1[0]) {
      //   this.option1.splice(0, 1);
      // }
     
      this.show = !this.show;
    },
    Date(time) {
      var date = new Date(time * 1000), // 参数需要毫秒数，所以这里将秒数乘于 1000
        Y = date.getFullYear(),
        M =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1,
        D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return Y + "-" + M + "-" + D;
    },
    closeChange() {
      this.option1.unshift({
        text: "时间选择",
        value: 0,
      });
    },
    onConfirm(date) {
      this.page = 1;
      this.finished = false;
      this.detaljerData = [];
      const [start, end] = date;
      const starts = this.getTime(start);
      const ends = this.getTime(end) + 86399;
      this.dateSearch = this.formatDate(start) + "~" + this.formatDate(end);
      this.starts = starts;
      this.ends = ends;
      this.Request();
      this.show = !this.show;
    },
    getTime(date) {
      return date.getTime() / 1000;
    },
    formatDate(date) {
      const year = date.getFullYear();
      const mont =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      const monteh =
        date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return `${year + "-" + mont + "-" + monteh}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.optageList {
  min-height: 100vh;
  background-color: #f1f2f6;
}

.oprageList_classfiy {
  display: grid;
  align-items: center;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  box-shadow: 0px 4px 6px 1px rgb(200 200 200 / 51%);
}

.oprageList_classfiy,
.optageList_content_header {
  background: white;
}
</style>
<style lang="scss">
.optageList {
  .van-dropdown-menu__bar {
    box-shadow: none;
  }
}
</style>
