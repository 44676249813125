<!--
 * @Author: Mr.Cheng
 * @Date: 2023-09-25 16:42:15
 * @LastEditTime: 2023-10-07 20:22:34
 * @FilePath: \octopus-factory-map\src\allPlans\index.vue
-->
<template>
  <div>
    <!-- 顶部 -->
    <header class="flex-c-c header-box">
      <div class="back-box flex-c-c">
        <img
          @click="$router.go(-1)"
          class="back-logo"
          src="../assets/return.png"
          alt=""
        />
      </div>
      <div>全部计划</div>
    </header>

    <!-- 列表内容 -->
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      offset="150"
      @load="onLoad"
    >
      <div class="list-box">
        <div class="list mb10" v-for="item of list" :key="item.id">
          <!-- 日期 -->
          <div class="flex-c">
            <div class="label">日期：</div>
            <div class="value">{{ item.dailyplantime_text }}</div>
          </div>

          <!-- 计划内容 -->
          <div class="flex-c" style="align-items: flex-start">
            <div class="label">计划：</div>
            <div class="value">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "allPlans",
  data() {
    return {
      page: 1,
      limit: 10,
      list: [], //列表数据
      loading: false,
      finished: false,
    };
  },
  mounted() {
    // 初始化
    this.init();
    //获取计划列表
    this.getList();
  },
  methods: {
    // 初始化
    init() {
      this.page = 1;
      this.list = [];
      this.loading = false;
      this.finished = false;
    },
    //加载更多
    onLoad() {
      if (!this.finished) {
        this.page++;
        // 获取计划列表
        this.getList();
      } else {
        this.loading = false;
      }
    },
    // 获取计划列表
    async getList() {
      this.loading = true;
      let { page, limit, list } = this;
      let params = {
        page,
        limit,
      };
      let { code, data } = await this.$request.planall(params);
      if (code == 1) {
        let _list = data.rows;
        if (page == 1) {
          list = _list;
          this.loading = false;
        } else {
          list.push(..._list);
          this.loading = false;
        }
        this.list = list;
        if (_list.length == 0) {
          this.finished = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-box {
  width: 100vw;
  height: 0.88rem;
  background: #ffffff;
  font-size: 0.34rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #111111;
  position: sticky;
  top: 0;

  //   返回图标
  .back-box {
    position: absolute;
    height: 0.88rem;
    left: 0.3rem;
    .back-logo {
      width: 0.19rem;
      height: 0.34rem;
    }
  }
}
.list-box {
  padding: 0.2rem;
  box-sizing: border-box;
  .list {
    padding: 0.2rem;
    box-sizing: border-box;
    background: #fff;
    border-radius: 0.2rem;
    .label {
      font-size: 0.26rem;
      font-weight: bold;
      white-space: nowrap;
    }
  }
}
</style>