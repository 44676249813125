<template>
    <div>
        <div class="bouns_box" style="background: #fff;">
            <publicNavigation :data="navigation" @sureEvenet="sureEvenet" />
        </div>
        <!-- 公告列表 -->
        <div class="announce_list">
            <van-swipe-cell v-for="(s, l) in cellList" :key="l">
                <van-cell :border="false" :title="s.title" :value="s.content" />
                <template #right>
                    <van-button square type="danger" text="删除" @click="deleteCell(s, l)" />
                </template>
            </van-swipe-cell>
        </div>
        <!-- 发布弹窗 -->
        <van-popup v-model="show" round :style="{ width: '90%' }">
            <div class="submit_Announce">
                <div class="submit_Announce_content">
                    <div class="submit_Announce_header_title">
                        <span>公告标题</span>
                        <input v-model="announceTitle" placeholder="请输入公告标题" />
                    </div>
                    <div class="submikt_announce_file_content">
                        <div class="submikt_announce_file_content_textarea">
                            <span>公告内容</span>
                            <textarea value="" v-model="announceContent" placeholder="请输入公告内容"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 底部提交 -->
            <div class="bottom_sbumit">
                <div class="bottom_sbumit_button">
                    <button @click="closeShow">取消</button>
                    <button @click="sureClick">发布</button>
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>
import publicNavigation from "@/publicNavigation/publicNavigation.vue";
import * as API from '@/api.ts'
export default {
    components: {
        publicNavigation
    },
    data() {
        return {
            navigation: {},
            show: false,
            cellList: [],
            announceTitle: '',
            announceContent: ''
        }
    },
    mounted() {
        const userInfoId = window.sessionStorage.getItem('userInfo') ? JSON.parse(window.sessionStorage.getItem('userInfo')).identity : ''
        if (userInfoId == 4) {
            this.navigation = {
                image: require("../assets/return.png"),
                name: '发布公告',
                sure: '添加公告'
            }
        } else {
            this.navigation = {
                image: require("../assets/return.png"),
                name: '发布公告',
            }
        }

        this.requset()
    },
    methods: {
        requset() {
            API.bulletinList().then(async e => {
                if (e.code == 1) {
                    this.cellList = e.data.rows
                }
            })
        },
        sureEvenet() {
            this.show = !this.show
        },
        closeShow() {
            this.show = !this.show
        },
        deleteCell(e, l) {
            console.log(e, l)
            API.delBulletin({ id: e.id }).then(async e => {
                if (e.code == 1) {
                    this.$toast.success(e.msg)
                    setTimeout(() => {
                        this.requset()
                        this.announceTitle = ''
                        this.announceContent = ''
                    }, 300)
                }
            })
        },
        sureClick() {
            API.bulletin({
                title: this.announceTitle,
                content: this.announceContent
            }).then(async e => {
                this.$toast(e.msg)
                if (e.code == 1) {
                    setTimeout(() => {
                        this.show = false
                    }, 800);
                    this.requset()
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.submit_Announce {
    .submit_Announce_content {
        .submit_Announce_header_title {
            padding-top: 1rem;
            display: flex;
            flex-direction: column;

            span {
                font-size: 16px;
                font-weight: bold;
                // border-top: 1px solid rgba(0, 0, 0, 0.05);
                margin-bottom: 10px;
                padding: 0 1.25rem;
                padding-top: 0.2rem;
                color: rgba(0, 0, 0, 0.8)
            }

            input {
                font-size: 14px;
                margin: 0 1.25rem;
                padding: 0.1rem;
                background: transparent;
                border-radius: 4px;
                border: none;
                border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                padding-left: 0;
                padding-top: 0.05rem;
                color: #333;
            }
        }
    }
}

// 公告内容
.submikt_announce_file_content {
    margin: 0 1.25rem;
    margin-top: 1.25rem;

    .submikt_announce_file_content_textarea {
        display: flex;
        flex-direction: column;

        span {
            font-size: 16px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.8)
        }

        textarea {
            margin-top: .825rem;
            font-size: 14px;
            border: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.01);
            background: rgba(0, 0, 0, 0.01);
            padding-left: 10px;
            padding-top: 10px;
            color: #333;
        }
    }
}

// 底部发布
.bottom_sbumit {
    padding: 1.25rem 0;
    margin: 0 1.25rem;

    .bottom_sbumit_button {
        display: flex;
        justify-content: space-between;

        button {
            width: 48%;
            padding: 8px 0;
            border: none;
            border-radius: 4px;
        }

        button:nth-last-child(1) {
            background: #D71518;
            color: white;
            font-size: 14px;
        }
    }
}

// 分割线
.van-swipe-cell {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
</style>
<style type="text/css">
.announce_list .van-cell--borderless {
    display: flex;
    flex-direction: column;
}

.announce_list .van-cell--borderless .van-cell__value {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 10px;
}

.announce_list .van-cell--borderless .van-cell__value span {
    text-align: left;
    padding-right: 10px;
}

.announce_list .van-button--square {
    height: 100%;
}
</style>
