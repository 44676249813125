/*
 * @Author: Mr.Cheng
 * @Date: 2023-09-01 14:31:55
 * @LastEditTime: 2023-10-30 18:58:12
 * @FilePath: \octopus-factory-map\src\router\router.ts
 */
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)
// 权限
// import personInlet from '@/person/personInlet.vue';//人事管理 旧
import personInlet from '@/new/personnelManagement/index.vue';//人事管理 新
import homeHeader from '@/components/homeHeader.vue';
// 添加员工
import addperson from '@/person/component/addPerson/addPerson.vue'
// 排行榜
import leaderboard from '@/leaderboard/newLeaderboard.vue'
// 项目管理
// import projectModel from '@/projectList/projectModel.vue' //旧
import projectModel from '@/new/project/project.vue'//新
// 项目详情
import projectDetail from '@/new/project/projectDetail.vue'//新
// 项目详情
import description from '@/projectList/compoment/newDescription/newDescription.vue'
// 兑换管理
import udveksle from '@/udveksle/udvekSlingssTyring.vue'
// cp值
import priscpVrdi from '@/priscpVrdi/priscpVrdi.vue'
// cp项目
import cpprojekt from '@/cpprojekt/cpProjekt.vue'
// cp详情
import cpDescription from '@/cpdescription/cpDescription.vue'
// 新建项目
import addbonus from '@/addbonus/addBouns.vue'
// 分红详情
import bounsdescription from '@/bonusDescription/bonusDescription.vue'
// 任务奖励
import rewardsContent from '@/rewards/rewardsContent.vue'
// 设置奖励点
import settingRewards from '@/setting/settingRewards.vue'
// 我的团队
import team from '@/myTeam/myTeam.vue'//旧
import myTeam from '@/new/myTeam/myTeam.vue'//新
// 团队详情
import mydesc from '@/myTeam/mydescription/myDescription.vue'
// cp值
import cpValue from '@/myTeam/cpValue/cpValue.vue'
// cp详情
import cpdesc from '@/myTeam/cpDescription/cpDesc.vue'
// 执行任务
// import perfrom from '@/perfrom/perfromTask.vue'//旧
import perfrom from '@/new/perfrom/perfrom.vue'//新 (列表)
import perFromDetail from '@/new/perfrom/perFromDetail.vue'//新 (详情)
import writeLog from '@/new/components/writeLog.vue'//填写日期（新）
import singleRewardDetail from '@/new/components/singleRewardDetail.vue'//奖励点详情

// 审核列表
import auditList from '@/new/auditList/auditList.vue'//新 (列表)
import auditListDetail from '@/new/auditList/auditListDetail.vue'//新 (详情)

// 发起任务列表 initTask
import initTask from '@/new/initTask/initTask.vue'//新 (列表)
import initTaskDetail from '@/new/initTask/initTaskDetail.vue'//新 (详情)

import cpProjectDetail from '@/new/cpProject/cpProjectDetail.vue'//cp值项目详情 新
// 解决bug
import solveBug from '@/new/solveBug/solveBug.vue' //列表（新）

// 发布任务
import task from "@/new/task/task.vue"//新 (发布任务)

// 发布项目//新
import createProject from "@/new/createProject/createProject.vue"

import cpProject from "@/new/cpProject/cpProject.vue"//cp值项目 新
import cpDetail from "@/new/cpProject/cpDetail.vue"//cp值项目详情 新
import administrationPerson from "@/new/personnelManagement/components/person.vue"//新增|修改员工信息



//发布任务
// import initTask from '@/init/initTask.vue'
// 任务池
// import taskPool from '@/pool/taskPool.vue'//旧
import taskPool from '@/new/taskPool/taskPool.vue'//新  任务池列表
import taskPoolDetail from "@/new/taskPool/taskPoolDetail.vue"//新 任务池详情
// 指派任务
import specfiy from '@/pool/compoments/taskSpecify.vue'
// 审核任务
import review from '@/review/reviewTask.vue'
// 审核任务详情
import reviewDescriptionVue from '@/review/compoments/reviewDescription.vue'
// 消息列表
import message from '@/message/messageList.vue'
// 填写日志
import inputLog from '@/inputlog/inputLog.vue'
// 积分等级详情
import integral from '@/integral/integralGrade.vue'
// 用户排行榜
import userleaderboard from '@/userleaderboard/userLeaderboard.vue'
// 解决bug
// import solveBug from '@/solve/solveBug.vue'
// 解决页面
import solveBugInput from '@/solve/compoments/solveBugInput.vue'
// 解决bug详情页面
import solveBugDescription from '@/solve/compoments/solveBugDescription.vue'
// 个人中心
import myUserInform from '@/myuserinform/myUserInform.vue'
// 我的奖励点
import bonuspoint from '@/bonuspoint/myBonuspoint.vue'
// 奖励点纪录
import optageList from '@/optage/optageList.vue'
// 个人信息
import userInfomation from '@/myuserinform/compoment/userInfomation.vue'
// 个性签名
import userSkilt from '@/myuserinform/compoment/userSkilt.vue'
// 设置账户
import settingacount from '@/myuserinform/compoment/settingAccount.vue'
// 验证手机
import verifyMobile from '@/myuserinform/compoment/verifyMobile.vue'
// 更换手机号
import updataMobile from '@/myuserinform/compoment/updataMobile.vue'
// 修改密码
import tooglePassword from '@/myuserinform/compoment/tooglePassword.vue'
// 兑换礼品
import exchangeGift from '@/bonuspoint/compoment/exchangeGift.vue'
// 礼品详情
import giftDescription from '@/bonuspoint/compoment/giftDescription.vue'
// 礼品纪录
import recordList from '@/bonuspoint/compoment/recordList.vue'
// 纪录详情
import recordDescription from '@/bonuspoint/compoment/recordDescription.vue'
// 我的cp值
import myCpValue from '@/myuserinform/compoment/myCpValue.vue'
// cp值详情
import myCpdescription from '@/myuserinform/compoment/cpDescritpions.vue'
// 兑换
import cpexchange from '@/myuserinform/compoment/cpExchange.vue'
// 登录
import userLogin from '@/login/userLogin.vue'
// 找回密码
import retrievepassword from '@/login/compoment/retrievetPassword.vue'
// 发布任务
import lancering from '@/lancering/lanceringTask.vue'
// 发布任务项目
import publishProject from '@/lancering/cmopoment/publishProject.vue'
// 奖励点
import rewardPoints from '@/lancering/cmopoment/rewardPoints.vue'
// 指派人
import overdragetPerson from '@/lancering/cmopoment/overdragetPerson.vue'
// 难度
import vanskelighedSelect from '@/lancering/cmopoment/vanskelighedSelect.vue'
// 验收人
import acceptorPerson from '@/lancering/cmopoment/acceptorPerson.vue'
// 拆解任务
import taskdisassembly from '@/Disassembly/taskDisassembly.vue'
// 任务介绍
import messagedescriptions from '@/messageDescriptions/messageDescriptions.vue'
// 礼品管理
import giftManage from '@/giftmanage/giftManage.vue'
// 礼品管理查看订单列表
import checkorderlist from '@/giftmanage/compoment/checkOrderList.vue'
// 提出bug
import propose from '@/propose/proposeBug.vue'
// 发布公告
import Announce from '@/Announce/AnnounceCompoments.vue'
// 隐私政策
import isPerson from '@/isPerson/isPerson.vue'
// 奖励点修正详情
import bounsPointsDescription from '@/bounsPointsDescription/bounsPointsDescription.vue'
// 奖励点审核
import reviewClick from '@/reviewClick/reviewClick'
// 查看全部（今日计划）
import allPlans from '@/allPlans/index.vue'
//验收任务列表
// import acceptanceTask from "@/acceptanceTask/index.vue"
import acceptanceTask from "@/new/acceptanceTask/acceptanceTask.vue" //验收任务列表 (新)
const router = new VueRouter({
    routes: [{
        name: '',
        path: '/',
        component: userLogin
    }, {
        name: 'home',
        path: '/home',
        component: homeHeader
    }, {
        name: 'acceptanceTask',
        path: '/acceptanceTask',
        component: acceptanceTask
    }, {
        name: 'person',
        path: '/person',
        component: personInlet
    }, {
        name: 'addperson',
        path: '/addperson',
        component: addperson
    }, {
        name: 'leaderboard',
        path: '/leaderboard',
        component: leaderboard
    }, {
        name: 'projectDetail',
        path: '/projectDetail',
        component: projectDetail
    }, {
        name: 'project',
        path: '/project',
        component: projectModel
    }, {
        name: 'description',
        path: '/description',
        component: description
    }, {
        name: 'udveksle',
        path: '/udveksle',
        component: udveksle
    }, {
        name: 'priscpVrdi',
        path: '/priscpVrdi',
        component: priscpVrdi
    }, {
        name: 'cpprojekt',
        path: '/cpprojekt',
        component: cpprojekt
    }, {
        name: 'cpDescription',
        path: '/cpDescription',
        component: cpDescription
    }, {
        name: 'addbonus',
        path: '/addbonus',
        component: addbonus
    }, {
        name: 'bounsdescription',
        path: '/bounsdescription',
        component: bounsdescription
    }, {
        name: 'rewardsContent',
        path: '/rewardsContent',
        component: rewardsContent
    }, {
        name: 'settingRewards',
        path: '/settingRewards',
        component: settingRewards
    }, {
        name: 'team',
        path: '/team',
        component: team
    }, {
        name: 'mydesc',
        path: '/mydesc',
        component: mydesc
    }, {
        name: 'cpValue',
        path: '/cpValue',
        component: cpValue
    }, {
        name: 'cpdesc',
        path: '/cpdesc',
        component: cpdesc
    }, {
        name: 'perfrom',
        path: '/perfrom',
        component: perfrom
    }, {
        name: 'taskPool',
        path: '/taskPool',
        component: taskPool
    }, {
        name: 'specfiy',
        path: '/specfiy',
        component: specfiy
    }, {
        name: 'review',
        path: '/review',
        component: review
    }, {
        name: 'reviewDescriptionVue',
        path: '/reviewDescriptionVue',
        component: reviewDescriptionVue
    }, {
        name: 'message',
        path: '/message',
        component: message,
        meta: {
            keepAlive: true
        }
    }, {
        name: 'inputLog',
        path: '/inputLog',
        component: inputLog
    }, {
        name: 'integral',
        path: '/integral',
        component: integral
    }, {
        name: 'solveBugInput',
        path: '/solveBugInput',
        component: solveBugInput
    }, {
        name: 'solveBugDescription',
        path: '/solveBugDescription',
        component: solveBugDescription
    }, {
        name: 'myUserInform',
        path: '/myUserInform',
        component: myUserInform
    }, {
        name: 'bonuspoint',
        path: '/bonuspoint',
        component: bonuspoint
    }, {
        name: 'optageList',
        path: '/optageList',
        component: optageList
    }, {
        name: 'userInfomation',
        path: '/userInfomation',
        component: userInfomation
    }, {
        name: 'userSkilt',
        path: '/userSkilt',
        component: userSkilt
    }, {
        name: 'settingacount',
        path: '/settingacount',
        component: settingacount
    }, {
        name: 'verifyMobile',
        path: '/verifyMobile',
        component: verifyMobile
    }, {
        name: 'updataMobile',
        path: '/updataMobile',
        component: updataMobile
    }, {
        name: 'tooglePassword',
        path: '/tooglePassword',
        component: tooglePassword
    }, {
        name: 'exchangeGift',
        path: '/exchangeGift',
        component: exchangeGift
    }, {
        name: 'giftDescription',
        path: '/giftDescription',
        component: giftDescription
    }, {
        name: 'recordList',
        path: '/recordList',
        component: recordList
    }, {
        name: 'recordDescription',
        path: '/recordDescription',
        component: recordDescription
    }, {
        name: 'myCpValue',
        path: '/myCpValue',
        component: myCpValue
    }, {
        name: 'myCpdescription',
        path: '/myCpdescription',
        component: myCpdescription
    }, {
        name: 'cpexchange',
        path: '/cpexchange',
        component: cpexchange
    }, {
        name: 'retrievepassword',
        path: '/retrievepassword',
        component: retrievepassword
    }, {
        name: 'lancering',
        path: '/lancering',
        component: lancering
    }, {
        name: 'publishProject',
        path: '/publishProject',
        component: publishProject
    }, {
        name: 'rewardPoints',
        path: '/rewardPoints',
        component: rewardPoints
    }, {
        name: 'overdragetPerson',
        path: '/overdragetPerson',
        component: overdragetPerson
    }, {
        name: 'vanskelighedSelect',
        path: '/vanskelighedSelect',
        component: vanskelighedSelect
    }, {
        name: 'acceptorPerson',
        path: '/acceptorPerson',
        component: acceptorPerson
    }, {
        name: 'userleaderboard',
        path: '/userleaderboard',
        component: userleaderboard
    }, {
        name: 'taskdisassembly',
        path: '/taskdisassembly',
        component: taskdisassembly
    }, {
        name: 'messagedescriptions',
        path: '/messagedescriptions',
        component: messagedescriptions
    }, {
        name: 'giftManage',
        path: '/giftManage',
        component: giftManage
    }, {
        name: 'checkorderlist',
        path: '/checkorderlist',
        component: checkorderlist
    }, {
        name: 'propose',
        path: '/propose',
        component: propose

    }, {
        name: 'initTask',
        path: '/initTask',
        component: initTask,//发起任务列表
    }, {
        name: 'initTaskDetail',
        path: '/initTaskDetail',
        component: initTaskDetail,//发起任务（详情）
    }, {
        name: 'Announce',
        path: '/Announce',
        component: Announce
    }, {
        name: 'isPerson',
        path: '/isPerson',
        component: isPerson
    }, {
        name: 'bounsPointsDescription',
        path: '/bounsPointsDescription',
        component: bounsPointsDescription
    }, {
        name: 'reviewClick',
        path: '/reviewClick',
        component: reviewClick
    }, {
        name: 'allPlans',
        path: '/allPlans',
        component: allPlans,//查看全部（今日计划）
    }, {
        name: 'perFromDetail',
        path: '/perFromDetail',
        component: perFromDetail,//执行任务详情
    }, {
        name: 'writeLog',
        path: '/writeLog',
        component: writeLog,//填写日志
    }, {
        name: 'singleRewardDetail',
        path: '/singleRewardDetail',
        component: singleRewardDetail,//奖励点详情
    }, {
        name: 'auditList',
        path: '/auditList',
        component: auditList,//审核任务列表
    }, {
        name: 'auditListDetail',
        path: '/auditListDetail',
        component: auditListDetail,//审核任务详情
    }, {
        name: 'task',
        path: '/task',
        component: task,//审核任务详情
    }, {
        name: 'solveBug',
        path: '/solveBug',
        component: solveBug,//审核任务详情
    }, {
        name: 'createProject',
        path: '/createProject',
        component: createProject,//发布项目
    }, {
        name: 'taskPoolDetail',
        path: '/taskPoolDetail',
        component: taskPoolDetail,//任务池详情
    }, {
        name: 'cpProject',
        path: '/cpProject',
        component: cpProject,//cp值项目
    }, {
        name: 'cpProjectDetail',
        path: '/cpProjectDetail',
        component: cpProjectDetail,//cp值项目详情
    }, {
        name: 'cpDetail',
        path: '/cpDetail',
        component: cpDetail,//cp值详情

    }, {
        name: 'myTeam',
        path: '/myTeam',
        component: myTeam,//我的团队
    }, {
        name: 'administrationPerson',
        path: '/administrationPerson',
        component: administrationPerson,//新增|修改员工信息
    }]
})

export default router
