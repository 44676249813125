<template>
    <div class="settingCard">
        <div class="settingCard_box">
            <div class="settingCard_list">
                <div class="settingcard_for" v-for="s,k in cardListData" :key="k">
                    <span>{{ s.name }}</span>
                    <span>{{ s.text }}</span>
                    <div class="settingcard_image" v-if="s.number">
                        <img src="@/assets/start.png" alt="" v-for="l,k in s.number" :key="k" />
                    </div>
                </div>
            </div>
            <div class="eidt_gary">
                <div class="edit_gary_white">
                    <div class="edit_input">
                        <div class="edit_name_input">
                            <div class="edit_name">
                                <span>绩效奖励点 修正</span>
                                <div class="edit_input_box">
                                    <input type="number" v-model="editModel" name="" placeholder="输入奖励点" />
                                    <span>-</span>
                                    <input type="text" v-model="reason" name="" placeholder="填写修正原因" />
                                </div>
                            </div>
                            <div class="edit_name">
                                <span>额外奖励点</span>
                                <div class="edit_input_box">
                                    <input type="number" v-model="reward" name="" placeholder="输入奖励点" />
                                    <span>-</span>
                                    <input class="custom_input" v-model="additional" type="text" name="" placeholder="填写额外奖励点原因" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="save_buttom">
                <div class="save_buttom_sure">
                    <div class="save_buttom_left_text">
                        <span>修正后合计：</span>
                        <span>{{Number(editModel) + Number(reward)}}个奖励点</span>
                    </div>
                    <div class="right_button">
                        <button @click="submitOnchange">保存</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        cardListData:Array
    },
    data(){
        return{
            editModel:'',
            reason:'',
            reward:'',
            additional:''
        }
    },
    methods:{
		submitOnchange(){
			const parms = {
				reward:this.editModel,
				reason:this.reason,
				additional_reward:this.reward,
				additional:this.additional
			}
			this.$emit('submitOnchange',parms)
		}
    }
}

</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter{
    display: flex;
    align-items: center;
}
  
// 计算函数
@function calcFunction($number,$default:16){
  @return calc($number * 2 / 100) + rem;
}


.settingCard{
    position: absolute;
    z-index: 1;
    width: 100%;
    .settingCard_box{
        margin: calcFunction(10);
        margin-top: calcFunction(83);
        .settingCard_list{
            background: white;
            border-radius: calcFunction(8);
            padding: calcFunction(20);
            @extend .alignItemsCenter;
            flex-wrap: wrap;
            .settingcard_for{
                @extend .alignItemsCenter;
                margin-bottom: calcFunction(10);
                width: 50%;
                span{
                    color: #666666;
                    font-size: calcFunction(14);
                }
                span:nth-last-child(1){
                    color: #111111;
                }
                .settingcard_image{
                    img{
                        width: calcFunction(14);
                        height: calcFunction(14);
                    }
                }
            }
            .settingcard_for:nth-child(2n){
                span{
                    text-align: right;
                }
            }
        }
        
    }
}

.custom_input{
    width: 56%!important;
}

.eidt_gary{
    .edit_gary_white{
        background: white;
        padding-bottom: calcFunction(15);
        border-radius: calcFunction(8);
        .edit_input{
            margin-top: calcFunction(10);
            .edit_name_input{
                .edit_name{
                    @extend .alignItemsCenter;
                    border-bottom: 1px solid #DCDCDC;
                    padding: calcFunction(15);
                    span{
                        width: 30%;
                        font-size: calcFunction(14);
                    }
                    .edit_input_box{
                        @extend .alignItemsCenter;
                        input{
                            outline: none;
                            border: none;
                            width: 40%;
                            font-size: calcFunction(14);
                            height: 100%;
                            text-align: center;
                        }
                        span{
                            width: 5%;
                        }
                    }
                }
                .edit_name:nth-last-child(1){
                    border-bottom: none;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }
    }
}

.save_buttom{
    position: fixed;
    bottom: 0;
    width: 100%;
    background: white;
    left: 0;
    .save_buttom_sure{
        margin: 0 calcFunction(10);
        @extend .alignItemsCenter;
        justify-content: space-between;
        padding: calcFunction(10) 0;
        padding-bottom: calcFunction(20);
        .save_buttom_left_text{
            @extend .alignItemsCenter;
            flex-direction: column;
            align-items: flex-start;
            span{
                color: #D71518;
                font-size: calcFunction(14);
            }
            span:nth-child(1){
                color: #111111;
            }
            span:nth-last-child(1){
                margin-top: calcFunction(4);
            }
        }
        .right_button{
            button{
                width: calcFunction(140);
                height: calcFunction(45);
                background: #D71518;
                color: white;
                border: none;
                outline: none;
                border-radius: calcFunction(8);
            }
        }
    }
}


</style>