<template>
	<div class="message_descriptions">
		<div class="message_descriptions_content">
			<div class="message_description_content_box">
				<div class="message_description_navigation">
					<publicNavigation :data="navigation" />
				</div>
				<div class="message_content_data">
					{{introduce}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import publicNavigation from '@/publicNavigation/publicNavigation.vue';
	export default {
		components:{
			publicNavigation
		},
		data(){
			return{
				navigation:{},
				introduce:''
			}
		},
		mounted() {
			this.navigation = {
				image:require('@/assets/return.png'),
				name:'任务介绍'
			}
			this.introduce = this.$route.query.introduce
		}
	}
</script>

<style lang="scss" scoped>
	.message_descriptions{
		height:100vh;
		background:#fafafa;
	}
	.message_description_navigation{
		background: white;
	}
	.message_content_data{
		margin: 20px;
	}
</style>