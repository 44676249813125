<!--
 * @Author: Mr.Cheng
 * @Date: 2023-09-01 14:31:55
 * @LastEditTime: 2023-09-04 17:29:07
 * @FilePath: \octopus-factory-map\src\bonuspoint\compoment\exchangeGift.vue
-->
<template>
  <div class="exchange_gift" style="background:white;">
    <div class="exchange_gift_content_header">
      <publicNavigation @sureEvenet="sureEvenet" :data="navigation" />
    </div>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
  
      <giftCard @giftCardClick="giftCardClick" :giftCardData="giftCardData" />

    </van-list>
    
  </div>
</template>
<script>
import publicNavigation from "@/publicNavigation/publicNavigation.vue";
import giftCard from "../compoments/giftCard.vue";
// import {exchangeGiftData} from './exchangeGiftData.ts'
import * as API from "@/api.ts";
import {Toast} from "vant";
export default {
  components: {
    publicNavigation,
    giftCard,
  },
  data() {
    return {
      navigation: {
        image: require("@/assets/return.png"),
        name: "兑换礼品",
        sure: "兑换记录",
        close: "",
      },
  
      loading: false,
      finished: false,
      page: 1,
      giftCardData: [],
    };
  },
  mounted() {
    // const parms = new exchangeGiftData().init()
    // this.giftCardData = parms.exchangeGiftData
    
    this.Request();
  },
  methods: {
    sureEvenet() {
      this.$router.push({
        name: "recordList",
        path: "/recordList",
      });
    },
    giftCardClick(s) {
      window.localStorage.setItem("product", JSON.stringify(s));
      this.$router.push({
        name: "giftDescription",
        path: "/giftDescription",
        query: {
          parms: s.id,
        },
      });
    },
    //加载更多
    onLoad() {
      if (this.giftCardData.length > 0) {
        this.Request();
      } else {
        this.loading = false;
      }
    },
    Request() {
      var params = {
        page: this.page,
        limit: 20,
      }
      API.RewardgiftList(params).then(async (e) => {
        if (e.code == 1) {
          var list = e.data.rows;
          if(list.length > 0){
            var newArr = [];
            list.map((item) => {
              newArr.push({
                image: item.img[0],
                title: item.name,
                number: item.reward,
                ...item,
              });
            });
            if(this.page == 1){
              this.giftCardData = newArr;
            }else{
              this.giftCardData = this.giftCardData.concat(newArr);
            }
            this.loading = false;
            this.page++;
          }else{
            if(this.page == 1){
              this.giftCardData = [];
              this.loading = false;
            }else{
              this.finished = true;
            }
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>

.exchange_gift_content_header{
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 999;
  background: #fff;
  border-bottom: 1px #f5f5f5 solid;
}
</style>
