<template>
  <div class="classfiy">
    <div class="classfiy_content">
      <div class="classfiy_list">
        <div class="calssfiy_list_one">
          <img src="../../assets/onebcakgroundimage.png" alt="" />
          <div class="classfiy_list_one_content">
            <div class="one_content">
              <img src="../../assets/yellowpris.png" alt="" />
              <span>奖励点余额</span>
            </div>
            <div class="content_text">
              <span>{{ myinfoDesc.reward || "" }}</span>
            </div>
          </div>
        </div>
        <div class="calssfiy_list_one">
          <img src="../../assets/pinkpris.png" alt="" />
          <div class="classfiy_list_one_content">
            <div class="one_content">
              <img src="../../assets/morestart.png" alt="" />
              <span>cp值项目</span>
            </div>
            <div class="content_text custom_content_text">
              <span>{{ userNumbers }}</span>
              <span @click="eventClick(myinfoDesc.project_num)">查看详情</span>
            </div>
          </div>
        </div>
      </div>
      <div class="fremstille">
        <div class="fremstille_content">
          <div class="fremstille_content_background">
            <div class="fremstille_background_image">
              <img src="../../assets/maxpinkpris.png" alt="" />
              <div class="fremstille_contents">
                <div class="fremstille_top">
                  <div class="fremstille_top_line">
                    <img src="../../assets/konstruktionen.png" alt="" />
                    <span>产生奖励点</span>
                  </div>
                  <div class="fremstille_year_mount">
                    <button
                      v-for="(s, k) in yearData"
                      :class="currentIndex == k ? 'active' : ''"
                      @click="currentEvent(s, k)"
                      :key="k"
                    >
                      {{ s }}
                    </button>
                  </div>
                </div>
                <div class="fremstille_bottom">
                  <span>{{ updata || Statistikker[0] }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["myinfoDesc", "Statistikker", "userNumbers"],
  data() {
    return {
      yearData: ["月", "季", "年"],
      currentIndex: 0,
      updata: 0,
    };
  },
  methods: {
    currentEvent(s, k) {
      this.currentIndex = k;
      this.updata
        ? (this.updata = this.$props.Statistikker[k])
        : (this.updata = this.$props.Statistikker[0]);
    },
    eventClick(e) {
      this.$emit("eventClick", e);
    },
  },
};
</script>
<style lang="scss" scoped>
// 继承变量
.alignItemsCenter {
  display: flex;
  align-items: center;
}

// 计算函数
@function calcFunction($number, $default: 16) {
  @return calc($number * 2 / 100) + rem;
}
.classfiy {
  margin: calcFunction(10);
  .classfiy_content {
    .classfiy_list {
      @extend .alignItemsCenter;
      width: 100%;
      .calssfiy_list_one {
        position: relative;
        width: 100%;
        img {
          width: 100%;
          height: calcFunction(90);
        }
        .classfiy_list_one_content {
          position: absolute;
          z-index: 1;
          top: calcFunction(15);
          left: calcFunction(15);
          width: 85%;
          .one_content {
            @extend .alignItemsCenter;
            img {
              width: calcFunction(14);
              height: calcFunction(18);
            }
            span {
              font-size: calcFunction(12);
              margin-left: calcFunction(5);
            }
          }
          .content_text {
            margin-top: calcFunction(15);
            margin-left: calcFunction(1);
            span {
              font-size: calcFunction(25);
              font-weight: bold;
            }
          }
          .custom_content_text {
            @extend .alignItemsCenter;
            justify-content: space-between;
            width: 100%;
            span:nth-last-child(1) {
              font-size: calcFunction(11);
              color: #d87f80;
              font-weight: 400;
              text-decoration: underline;
            }
          }
        }
      }
      .calssfiy_list_one:nth-last-child(1) {
        margin-left: calcFunction(10);
      }
    }
  }
}

.fremstille {
  margin-top: calcFunction(10);
  .fremstille_content {
    .fremstille_content_background {
      .fremstille_background_image {
        position: relative;
        width: 100%;
        img {
          width: 100%;
          height: calcFunction(90);
        }
        .fremstille_contents {
          position: absolute;
          top: calcFunction(15);
          left: calcFunction(15);
          width: 90%;
          .fremstille_top {
            @extend .alignItemsCenter;
            justify-content: space-between;
            .fremstille_top_line {
              @extend .alignItemsCenter;
              img {
                width: calcFunction(16);
                height: calcFunction(16);
              }
              span {
                margin-left: calcFunction(5);
                font-size: calcFunction(12);
              }
            }
            .fremstille_year_mount {
              width: calcFunction(100);
              height: calcFunction(24);
              @extend .alignItemsCenter;
              justify-content: space-between;
              border: 1px solid #999999;
              border-radius: calcFunction(4);
              button {
                border: none;
                width: 34%;
                height: 100%;
                font-size: calcFunction(11);
                background: transparent;
              }
              button:nth-child(2) {
                border-left: 1px solid #999999;
                border-right: 1px solid #999999;
              }
            }
          }
          .fremstille_bottom {
            margin-top: calcFunction(5);
            span {
              font-size: calcFunction(25);
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

.active {
  background: #d71518 !important;
  color: white !important;
}
</style>