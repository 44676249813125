export class leaderboardData{
    init(){
        return{
            position:['按岗位','按排行'],
            deparmentData:[{
                name:'设计部',
                children:[{
                    image:'https://images.unsplash.com/photo-1686129839916-e3b91637a7ba?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80',
                    name:'张三',
                    icon:'UI设计',
                    intergral:'9',
                    totalNumber:'30',
                    number:'58'
                },{
                    image:'https://images.unsplash.com/photo-1685987300287-6c1dc4d0508e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80',
                    name:'张淑君',
                    icon:'平面设计',
                    intergral:'9',
                    totalNumber:'30',
                    number:'58'
                }]
            },{
                name:'开发部',
                children:[{
                    image:'https://images.unsplash.com/photo-1686214715439-44ad1b59e0ab?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=695&q=80',
                    name:'张三',
                    icon:'UI设计',
                    intergral:'9',
                    totalNumber:'30',
                    number:'58'
                },{
                    image:'https://images.unsplash.com/photo-1686092854995-b735b32187a2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=735&q=80',
                    name:'张淑君',
                    icon:'平面设计',
                    intergral:'9',
                    totalNumber:'30',
                    number:'58'
                }]
            }]
        }
    }
}