<template>
  <div class="pieCharts" style="width: 100%">
    <div
      :id="wrapId ? wrapId : elId"
      :style="`width: ${width}; height: ${height}`"
    ></div>
  </div>
</template>
    
    <script>
import * as echarts from "echarts";
export default {
  name: "Histogram",
  /**
   * width 图表容器宽度
   * height 图表容器高度
   * seriesData 图表展示数据
   * title 图表标题
   * xTitle 标题左边距离
   * yTitle 标题上边距离
   * legendLeft 图例位置左边距离
   * legendTop 图例位置上边距离
   * legendRight 图例位置右边距离
   * legendBottom 图例位置下边距离
   * wrapId 自定义容器id
   */
  props: [
    "width",
    "height",
    "wrapId",
    "seriesData",
    "title",
    "xTitle",
    "yTitle",
    "legendLeft",
    "legendTop",
    "legendRight",
    "legendBottom",
    "color",
  ],
  data() {
    return {
      elId: "pie-charts-" + Date.now(),
      series: [],
      date: [],
      index: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initCharts();
    });
  },

  computed: {
    option() {
      // 指定图表的配置项和数据
      var option = {
        // 这个里面指定图标的样式和数据
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        legend: {
          icon: "rect", //形状  类型包括 circle，rect,line，roundRect，triangle，diamond，pin，arrow，none
          itemWidth: 10, // 设置宽度
          itemHeight: 4, // 设置高度
          itemGap: 24, // 设置间距
          data: ["产生的奖励点", "兑换CP值的奖励点"],
          textStyle: {
            //文字样式
            color: "#000",
            fontSize: "13",
          },
          top: "10%",
          right: "5%",
        },
        xAxis: [
          {
            type: "category",
            data: this.date,
            axisLabel: {
              interval: 0, //横轴信息全部显示
              rotate: this.seriesData.length > 3 ? -70 : 0, //倾斜度 -90 至 90 默认为0
              margin: 5, //刻度标签与轴线之间的距离
              textStyle: {
                fontSize: 9, //横轴字体大小
                color: "#000000", //颜色
              },
            },
          },
        ],

        yAxis: {
          show: true,
          alignTicks: true,
        },
        grid: {
          //表格位置
          top: 70, //根据canvas所在div
          left: 0, //位置设置
          width: "100%",
          containLabel: true,
          height: "70%", //宽高根据所有包含网格标题的大小所以要算进去
        },
        series: this.series,

        color: ["#FF7155", "#818DFF"],
      };
      return option;
    },
  },
  methods: {
    initCharts() {
      var _id = this.wrapId || this.elId;
      var myChart = echarts.init(document.getElementById(_id));

      let _seriesData = [],
        cp = [],
        reward = [];

      if (this.seriesData.cp && this.seriesData.cp.length > 0) {
        this.seriesData.cp.forEach((item) => {
          cp.push(item.cp);
          this.date.push(item.month);
        });
      }
      if (this.seriesData.reward && this.seriesData.reward.length > 0) {
        this.seriesData.reward.forEach((item) => {
          reward.push(item.reward);
        });
      }
      _seriesData[0] = {
        name: "产生的奖励点",
        type: "bar",
        barWidth: "15%",
        data: reward,
      };
      _seriesData[1] = {
        name: "兑换CP值的奖励点",
        type: "bar",
        barWidth: "15%",
        data: cp,
      };
      this.index++;
      if (this.index == 1) {
        this.series = _seriesData;
        myChart.setOption(JSON.parse(JSON.stringify(this.option)), true);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
    },
  },
};
</script>
    
<style scoped>
.pieCharts {
  position: relative;
  z-index: 0;
}
</style>