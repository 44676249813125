<template>
	<div class="task_description_list">
		<div class="task_description_list_data">
			<div class="task_description_list_data_content">
				<div class="task_descritpion_content_for">
					<div class="task_description_content" v-for="s,k in taskDescriptionData" :key="k">
						<div class="task_description_title">
							<span>{{s.name}}</span>
						</div>
						<div class="task_description_text">
							<span>{{s.text}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			taskDescriptionData:Array
		}
	}
</script>

<style lang="scss" scoped>
	.task_description_list{
		border-top: 1px solid #DDDDDD;
		.task_description_list_data{
			margin: 0 1rem;
			margin-top: 1.25rem;
			.task_description_list_data_content{
				.task_descritpion_content_for{
					.task_description_content{
						display: flex;
						align-items: center;
						border-bottom: 1px solid #ddd;
						border-right: 1px solid #ddd;
						border-left:1px solid #ddd;
						.task_description_title{
							border-right: 1px solid #ddd;
							height:3.215rem;
							background: #EEEEEE;
							width: 26%;
							display: flex;
							align-items:center;
							justify-content: center;
							span{
								font-size: .875rem;
								color: #111;
								font-family: PingFang SC;
								font-weight: 800;
								white-space: nowrap;
							}
						}
						.task_description_text{
							span{
								margin-left: 1.5625rem;
								font-size: .875rem;
							}
						}
					}
					.task_description_content:nth-child(1){
						border-top: 1px solid #ddd;
					}
				}
			}
		}
	}
</style>